import React, { useState } from 'react';

const CardsGuiasDicas = ({ site }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const buttonText = isExpanded ? 'Recolher' : 'Ver mais';

    const corpo = (isExpanded) => {
        if (site === 'politurtransfer') {
            return templatePolitur(isExpanded)
        }


        return (
            <>
                 <h1>Passagem de ônibus online:</h1>

                {site === 'amarelinho' &&
                    <p>
                        Não importa se você vai curtir as férias, reencontrar a família ou fazer negócios. Quando o assunto é viagem o mais importante é chegar com conforto e segurança ao destino. Por essa razão, nosso maior propósito é fazer de tudo para que a sua jornada seja inesquecível a bordo de um de nossos amarelinhos.
                    </p>
                }

                <p>
                    {!isExpanded ? '' : null}

                    Novo jeito de efetuar compra e vendas online de <strong>passagens rodoviárias</strong>, compras para você cliente que viaja e busca <strong>passagens mais baratas</strong> e empresas parceiras que fornecem os melhores serviços para facilitar a conexão entre pessoas e seus destinos. Nossas soluções vêm para contribuir e para alavancar a modernização do setor rodoviário no Brasil, trazendo <strong>tecnologia, segurança e inclusão</strong>. Comprar passagem de ônibus pela internet? É aqui!

                </p>
                <h2>Compre sua passagem de ônibus:</h2>

                <p>Em nossa plataforma, você pode comprar passagem de ônibus pela internet, através do <strong>site ou aplicativo</strong>, gratuito e disponível tanto para Android quanto iOS. Atuamos com <strong>várias empresas e destinos</strong> em todo o Brasil. Venha descobrir os <strong>melhores preços de passagens</strong> de Ônibus para todo o Brasil, a <strong>rodoviária online</strong> na palma de sua mão!</p>

                {isExpanded && (
                    <>
                        <p>É muito simples e em poucos cliques <strong>você compara e pesquisa preços</strong>, horários, classes de serviços e ainda <strong>garante sua passagem online</strong> com muita facilidade. E para oferecer ainda mais conforto e segurança na compra de sua passagem de ônibus, você pode efetuar sua compra com <strong>diferentes métodos de pagamento</strong>. Compre sua passagem de ônibus e pague com cartão de crédito (com possibilidade de <strong>parcelamento em até 12 vezes</strong>), débito ou pix através PagSeguro. É simples, rápido e seguro.</p>

                        <h2>Viagem de ônibus é aqui:</h2>

                        <p>Além de garantir sua viagem ônibus com muita segurança e facilidade, você também embarca com muito mais tranquilidade. O seu bilhete de passagem fica disponível para embarcar com a <strong>leitura do QR Code no seu celular</strong>! A viagem de ônibus nunca foi tão fácil!</p>

                        <p>Caso precisar de qualquer ajuda, estamos <strong>disponíveis com o nosso suporte</strong> de segunda a sexta das 9h às 18h (exceto feriados). Juntos vamos mais longe!</p>

                        <p>Quer, precisa ou ainda está planejando sua viagem de ônibus? Conte conosco, <strong>buscamos sempre melhor atender</strong>!</p>

                        <h2>Tenha uma ótima compra e uma <strong>excelente viagem</strong>!</h2>
                    </>
                )}

            </>
        )
    };

    const templatePolitur = (isExpanded) => {
        return (
            <>
                <h1>Passagem de ônibus e serviços de traslados online:</h1>
                <p>Descubra um novo jeito de comprar passagens rodoviárias e agendar serviços de traslados. Nossa plataforma é ideal para você, que busca passagens mais baratas ou precisa de serviços de transporte prático e eficiente. Trabalhamos com empresas parceiras que oferecem os melhores serviços para conectar você aos seus destinos com conforto e segurança. Nossas soluções contribuem para modernizar o setor rodoviário e de traslados no Brasil, trazendo mais tecnologia, segurança e inclusão. Comprar passagem de ônibus ou agendar seu traslado pela internet? Aqui é o lugar certo!
                </p>
                <h2>Compre sua passagem de ônibus e agende seu traslado:</h2>
                <p>Na nossa plataforma, você pode facilmente comprar passagens de ônibus e agendar serviços de traslados, seja pelo site ou pelo aplicativo gratuito, disponível para Android e iOS. Atendemos diversas empresas e destinos em todo o Brasil. Explore as melhores opções de passagens de ônibus e traslados, tudo na palma da sua mão!</p>

                {!isExpanded ? '' : null}
                
                {isExpanded && (
                    <>
                    <p>Com poucos cliques, você compara preços, horários, classes de serviço e garante sua passagem ou traslado com facilidade. Além disso, oferecemos várias opções de pagamento: compre sua passagem de ônibus ou agende seu traslado e pague com cartão de crédito (parcelado em até 12 vezes), débito ou Pix, via PagSeguro. É simples, rápido e seguro.
                    </p>
                    <h2>Viagem de ônibus e traslados é aqui:</h2>
                    <p>Além de garantir sua viagem de ônibus com segurança e praticidade, você também conta com a conveniência do bilhete eletrônico, acessível pelo QR Code no seu celular. E se agendou um traslado, viaje com ainda mais tranquilidade, sabendo que todos os detalhes estão organizados para você!</p>
                    <p>Se precisar de qualquer assistência, nosso suporte está disponível de segunda a sexta, das 9h às 18h (exceto feriados). Conte conosco para garantir uma viagem segura e confortável.</p>
                    <p>Está planejando sua viagem de ônibus ou precisa de um traslado? Conte com a gente, estamos sempre prontos para oferecer o melhor atendimento!</p>
                    <p>Tenha uma ótima compra e uma excelente viagem!</p>
                    </>
                )}
            </>
        )
    }

    return (
        <main className="cards-dicas" id="cards-dicas">
            <article className='about-us' id='about-us'>
                <header className='text-about-us' id='text-about-us'>
                   {corpo(isExpanded)}
                    {isExpanded && <button id='recolherButton' onClick={toggleExpand}>{buttonText}</button>}
                    {!isExpanded && <button id='verMaisButton' onClick={toggleExpand}>{buttonText}</button>}
                </header>

            </article>

        </main>
    );
};

export default CardsGuiasDicas;
