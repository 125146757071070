import React from 'react'
import {Field, reduxForm} from 'redux-form';
import qs from 'qs'
import {connect} from 'react-redux';
import {logoutUser, trocarSenhaUsuario} from '../../actions/usuarioActions';
import {showSuccessMsg} from '../../util/messagesUtil';

export const validate = (values) => {
  const errors = {};
  if (!values.senha) {
    errors.senha = 'Campo obrigatório'
  }
  if (values.senha && values.senha.length < 8) {
    errors.senha = 'Senha deve ter no mínimo 8 caracteres'
  }
  if (!values.confirmarSenha) {
    errors.confirmarSenha = 'Campo obrigatório'
  }
  if (values.senha && values.confirmarSenha) {
    if (values.senha !== values.confirmarSenha) {
      errors.confirmarSenha = 'As senhas são diferentes'
    }
  }


  return errors
};

const renderField = ({
  input,
  label,
  type,
  css,
  max,
  cod,
  readOnly,
  meta: { touched, error }
}) => (
  <div>
    <label className='mt-2'>{label}</label>
    <div>
      <input {...input} type={type}
             className={css}
             maxLength={max}
             style={{ borderColor: touched && error ? 'red' : '' }}
             disabled={readOnly || cod}
             readOnly={readOnly}/>
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>
);

export class PaginaTrocaSenha extends React.Component {
  constructor (props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount () {
    this.props.logoutUser();
  }

  submit (values) {
    const parametros = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
    if (parametros.email != null && parametros.codigo != null && values.senha != null) {
      this.props.trocarSenhaUsuario(parametros.email, parametros.codigo, values.senha)
        .then(() => {
            showSuccessMsg('Senha alterada com sucesso');
            this.props.history.push('/login')
            this.props.reset('formularioTrocaSenha');
        })
    }
  }

  render () {
    const {
      handleSubmit, submitting, invalid, pristine
    } = this.props;
    const parametros = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
    if (!parametros.email || !parametros.codigo) {
      return '';
    }
    return (
      <div>
        <form className="container form-login" onSubmit={handleSubmit(this.submit)}>
          <div className="form-group">
            <Field name="senha" type="password" component={renderField} label="Nova Senha:"
                   css="form-control col-md-12"/>
          </div>
          <div className="form-group">
            <Field name="confirmarSenha" type="password" component={renderField} label="Confirmar Senha:"
                   css="form-control col-md-12"/>
          </div>
          <div className="modal-footer">
            <button type="submit" disabled={invalid || submitting || pristine}
                    className="btn btn-laranja">Salvar
            </button>
          </div>
        </form>
      </div>)
  }
}

export default connect(null, { logoutUser, trocarSenhaUsuario })(reduxForm({
  form: 'formularioTrocaSenha',
  validate
})(PaginaTrocaSenha))
