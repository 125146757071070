export const BUSCAR_OPEN_GRAPH = '@openGraphReducer/BUSCAR_OPEN_GRAPH';

const initialState = undefined

export default function reducerOpenGraph(state = [], action = initialState) {
    switch (action.type) {
        case BUSCAR_OPEN_GRAPH: {
            const openGraph = action.payload.data;
            if(openGraph) {
                return openGraph;
            }
            return [];
        }

        default: {
            return state
        }
    }

}