import React from 'react';
import logoAgencia from '../../../estilo/comum/img/logo-geral-agencia.png';
import ConteudoQuemSomosAG from '../../../componentes/quem-somos/agencias/ConteudoQuemSomosAG';
import iconAgencia from '../../../estilo/comum/img/icon/logo-geral-agencia.png';
import bannerAgencia from '../../../estilo/comum/img/banner-site-agencias.png';
import bannerAgenciaMobile from '../../../estilo/comum/img/banner-site-agencias-mobile.png';
import bannerAgenciaMobile2 from '../../../estilo/comum/img/banner-site-agencias-mobile-2.png';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/12.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/17.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoAgencias.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/18.webp';

const logoAgenciaRodape = <img className="agencia" src={logoAgencia} alt="Logo Agência" style={{width: '70px', quality: 'auto'}}/>;
const logoAgenciaCabecalho = <img className="logo-site agencia" src={logoAgencia} alt='Logo Agência' style={{quality: 'auto'}}/>;

const dadosAgenciaComum = {
    pastaEstilo: 'agencia',
    nomePrefixo: 'a Agência',
    telefone: '(61) 99870-0861',
    wtpp: '(61) 99870-0861',
    lktelefone: 'tel:5561998700861',
    lkemail: 'mailto:contato@vexado.com.br',
    lkemailSuporte: 'mailto:suporte@vexado.com.br',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561998700861&text=Olá. Preciso de atendimento.',
    logo: logoAgencia,
    logoCabecacho: logoAgenciaCabecalho,
    logoRodape: logoAgenciaRodape,
    OgImage: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/agencias/sites+agencias.webp',
    icone: iconAgencia,
    quemSomos: ConteudoQuemSomosAG,
    videoInicio: bannerAgencia,
    imgMobile: bannerAgenciaMobile,
    imgMobile2: bannerAgenciaMobile2,
    idsTagManager: ['GTM-PHGZ336'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+desconto+agencias.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/5.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/5.mobile.webp',
    instagram: 'https://www.instagram.com',
    tiktok: 'https://www.tiktok.com/@vexadoo',
    facebook: 'https://www.facebook.com',
    youtube: 'https://www.youtube.com',
    distribusion: true,
    exibePopUpPromocao: true,
    urlBase: 'https://vexado.com.br'
};

export default dadosAgenciaComum
