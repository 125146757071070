import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import {setBrowserHistory} from '../util/applicationContext'
import Login from '../componentes/usuario/PaginaLogin';
import Logout from '../componentes/usuario/Logout';
import PaginaInicial from '../componentes/inicio/PaginaInicial';
import PaginaBuscarPassagens from '../componentes/passagem/PaginaBuscarPassagens';
import PaginaErro from '../componentes/PaginaErro';
import PaginaValidacaoCadastro from '../componentes/usuario/PaginaValidacaoCadastro';
import PaginaNovoUsuario from '../componentes/usuario/PaginaNovoUsuario';
import PaginaTrocaSenha from '../componentes/usuario/PaginaTrocaSenha';
import AuthenticatedRoute from './AuthenticatedRoute';
import PaginaMinhasReservas from '../componentes/minhas-reservas/PaginaMinhasReservas';
import PaginaMeusPedidos from '../componentes/meus-pedidos/PaginaMeusPedidos';
import PaginaReciboReserva from '../componentes/recibo/PaginaReciboReserva';
import PaginaPerfil from '../componentes/usuario/PaginaPerfil';
import PaginaCadastroPromocao from '../componentes/promocao/PaginaCadastroPromocao';
import Atendimento from '../componentes/Atendimento';
import TermosUso from '../componentes/TermosUso';
import PoliticaPrivacidade from '../componentes/PoliticaPrivacidade';
import QuemSomos from '../componentes/quem-somos/QueSomos';
import PaginaPrincipaisTrechos from '../componentes/trechos/PaginaPrincipaisTrechos';
import PoliticaCookies from '../componentes/PoliticaCookies';
import ReciboPagamento from '../componentes/passagem/ReciboPagamento';
import AlterarFormaPagamento from '../componentes/passagem/AlterarFormaPagamento';
import TermosCompra from '../componentes/TermosCompra';
import NossaFrota from '../componentes/NossaFrota';
import { ConteudoQueroUmaAgencia } from '../componentes/layout/queroUmaAgencia/ConteudoQueroUmaAgencia';
import { VexadoEncomendas } from '../componentes/layout/vexadoEncomendas/VexadoEncomendas';
import { SitemaVexado } from '../componentes/layout/sistemaVexado/SitemaVexado';
import PaginaEmpresas from '../componentes/empresas/PaginaEmpresas';
import PaginaTrechosEmpresa from '../componentes/trechos/PaginaTrechosEmpresa';
import BilheteNaMao from '../componentes/layout/bilhetenamao/BilheteNaMao';
import PaginaReciboBilheteNaMao from '../componentes/layout/bilhetenamao/PaginaReciboBilheteNaMao';
import PaginaLinksPassagens from '../componentes/layout/TopTrechosPassagens/PaginaLinksPassagens';
import CidadeRota from '../componentes/cidade/CidadeRota';
import PaginaDestinos from '../componentes/layout/pagina-destinos/PaginaDestinos';
import GaleriaImagensVexado from '../componentes/layout/GaleriaImagensVexado/GaleriaImagensVexado';
import PagSolicitarGratuidade from '../componentes/PagSolicitarGratuidade';

export class AppRoutes extends React.Component {
    constructor(props) {
        super(props);
        const {history} = this.props;
        setBrowserHistory(history)
    }

    render() {
        return (
            <Switch>
                <Route path='/login' component={Login}/>
                <Route path='/logout' component={Logout}/>
                <Route path='/passagem-de-onibus/:origem?/:destino?/:ida?/:volta?' component={PaginaBuscarPassagens}/>
                <Route path='/passagem-de-onibus/:destino?' component={CidadeRota}/>
                <Route path='/pagina-destinos/:destino?' component={PaginaDestinos}/>
                <Route path='/cadastro' component={PaginaNovoUsuario}/>
                <Route path='/cadastro-promocao' component={PaginaCadastroPromocao}/>
                <Route path='/troca-senha' component={PaginaTrocaSenha}/>
                <Route path='/validar-cadastro' component={PaginaValidacaoCadastro}/>
                <Route path='/atendimento' component={Atendimento}/>
                <Route path='/quem-somos' component={QuemSomos}/>
                <Route path='/quero-uma-agencia' component={ConteudoQueroUmaAgencia}/>
                <Route path='/sistema-vexado' component={SitemaVexado}/>                
                <Route path='/vexado-encomendas' component={VexadoEncomendas}/>
                <Route path='/bilhete-na-mao' component={BilheteNaMao} />
                <Route path='/recibo-bilhete-na-mao' component={PaginaReciboBilheteNaMao}/>
                <Route path='/termos-uso' component={TermosUso}/>
                <Route path='/termos-compra' component={TermosCompra}/>
                <Route path='/principais-trechos' component={PaginaPrincipaisTrechos}/>
                <Route path='/pesquisar-empresas' component={PaginaEmpresas}/>
                <Route path='/trechos-empresa/:empresaId?' component={PaginaTrechosEmpresa}/>
                <Route path='/politica-privacidade' component={PoliticaPrivacidade}/>
                <Route path='/politica-cookies' component={PoliticaCookies}/>
                <Route path='/erro' component={PaginaErro}/>
                <Route path='/nossa-frota' component={NossaFrota}/>
                <Route path='/recibo-pagamento' component={ReciboPagamento}/>
                <Route path='/alterar-forma-pagamento' component={AlterarFormaPagamento}/>
                <Route path={'/top-trechos-passagens'} component={PaginaLinksPassagens}/>
                <Route path={'/galeria-imagens-vexado'} component={GaleriaImagensVexado}/>
                <Route path={'/solicitar-gratuidade'} component={PagSolicitarGratuidade}/>
                <AuthenticatedRoute path='/minhas-reservas' component={PaginaMinhasReservas}/>
                <AuthenticatedRoute path='/meus-pedidos' component={PaginaMeusPedidos}/>
                <AuthenticatedRoute path='/recibo-reserva' component={PaginaReciboReserva}/>
                <AuthenticatedRoute path='/perfil' component={PaginaPerfil}/>                
                <Route path='/' component={PaginaInicial}/>
            </Switch>
        )
    }
}

export default withRouter(AppRoutes)
