import React from 'react'
import { change as changeFieldValue } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import qs from 'qs';
import { formatarReal } from '../../util/arraysUtil';
import moment from 'moment';
import { getBrowserHistory } from '../../util/applicationContext';

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');


export const validate = (values) => {
    const errors = {};
    if (!values.nome) {
        errors.nome = 'Campo obrigatório'
    }
    if (!values.email) {
        errors.email = 'Campo obrigatório'
    }

    return errors
};

export class ReciboPagamento extends React.Component {
    copyLink(event) {
        const { pedido } = this.props.venda;
        event.preventDefault();
        navigator.clipboard.writeText(pedido.formasPagamentoDto[0].linkPagamento);

    }

    exibirPagamentoPix() {
        const { pedido } = this.props.venda;
        if (!pedido) return '';
        return (
            <div className='modal-body'>
                <h2 className="form-group col-12 col-sm-12" style={{ textAlign: 'center', fontSize: 20 }}>
                    <strong>Pedido realizado com sucesso. Siga as instruções para pagamento.</strong>
                </h2>
                <div className="form-row mt-4">
                    <div className="col-12 col-sm-12 col-md-5" style={{ textAlign: 'center' }}>
                        <img className='form-group w-75'
                            src={pedido.formasPagamentoDto[0].qrcode}
                        />
                    </div>
                    <div className="col-12 col-sm-5 col-md-6">
                        <p className='form-group'>
                            <strong>Pedido: </strong> {pedido.id}
                        </p>
                        <p className='form-group'>
                            <strong>Valor do pagamento: </strong> {formatarReal(pedido.formasPagamentoDto[0].valor.toString())}
                        </p>
                        <p>
                            <button type='button' id="copy-past-qr-code"
                                onClick={(e) => this.copyLink(e)}
                                style={{ cursor: 'pointer' }}
                                className="btn btn-laranja p-3 col-sm-12">
                                Copiar link para pagamento
                            </button>
                        </p>
                        <p className='form-group col-12 col-sm-12 mt-2'>
                            <strong>- Se o pagamento não for feito em até 30 minutos, o seu pedido será cancelado.</strong>
                        </p>
                    </div>
                </div>
                <div className="form-row"
                    onClick={(e) => this.copyLink(e)}>
                    <div className='row mt-2 p-2'
                        style={{ backgroundColor: '#e1e1e1', cursor: 'pointer' }}
                        title='Copiar'>
                        <span style={{ fontSize: 11.5, textAlign: 'center' }}>
                            {pedido.formasPagamentoDto[0].linkPagamento}
                        </span>
                    </div>
                </div>
                {this.exibirInstrucaoPix()}
            </div>
        );
    }

    exibirInstrucaoPix() {
        return (<div><h3 className='m-3' style={{ fontSize: 18 }}><strong> Instruções</strong></h3>
            <div className="col m-2">
                <p style={{ fontSize: 14, marginBottom: 8 }}><strong>Pagamento com o PIX escaneando o QRcode</strong></p>
                <p>
                    - Abra o aplicativo de pagamento do seu celular e <b>escaneie o QR Code</b> (código QR). Após, no aplicativo de pagamento, é só confirmar.
                    <br />
                    - Com o pagamento confirmardo, você receberá as informações da viagem por e-mail
                </p>
            </div>
            <div className='col m-2'>
                <p style={{ fontSize: 14, marginBottom: 8 }}><strong>Pagamento com o PIX copiando o link</strong></p>
                <p>
                    - Clique no botão <strong>Copiar link para pagamento</strong><br />
                    - Entre no site de pagamento de sua preferência e insira o código onde precisar.<br />
                    - Com o pagamento confirmado, você receberá as informações da viagem por e-mail.<br />
                </p>
            </div>
        </div>)
    }

    exibirPagamentoCartao() {
        const { pedido } = this.props.venda;
        const venda = this.props.venda;
        if (!pedido || !venda) return getBrowserHistory().push('/');
        const dataIda = moment(venda.itinerarioIda.dataHoraPartida).format('DD/MM/YYYY HH:mm');
        const dataVolta = venda.itinerarioRetorno ? moment(venda.itinerarioRetorno.dataHoraPartida).format('DD/MM/YYYY HH:mm') : null;
        return (
            <div className='modal-body'>
                <h1 className="form-group col-12 col-sm-12" style={{ textAlign: 'center', fontSize: 20 }}>
                    <strong>PARABÉNS POR SUA COMPRA!</strong>
                </h1>
                <h2 className="form-group col-12 col-sm-12" style={{ textAlign: 'center', fontSize: 20 }}>
                    <strong>Seu pedido foi realizado com sucesso. <br />Logo abaixo estão algumas informações sobre seu pedido:</strong>
                </h2>
                <div className="card-group mt-2 mb-4">
                    <div className='card col-sm-12 row align-content-center item-passagem' style={{ margin: 0 }}>
                        <div className="card-body align-content-center card-reserva col-sm-12 info">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Data da compra:</label>
                                    <p>{moment(dataIda, 'DD/MM/YYYY').format('DD/MM/YYYY')}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label>Número do pedido:</label>
                                    <p>{pedido.id}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-6">
                                    <label>Data da partida:</label>
                                    <p>{moment(dataIda, 'DD/MM/YYYY').format('DD/MM/YYYY')}</p>
                                </div>
                                {dataVolta ?
                                    <div className="col-sm-6">
                                        <label>Data do retorno:</label>
                                        <p>{moment(dataIda, 'DD/MM/YYYY').format('DD/MM/YYYY')}</p>
                                    </div> : ''}
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Origem:</label>
                                    <p>{venda.origem.nomeComUf}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label>Destino:</label>
                                    <p>{venda.destino.nomeComUf}</p>
                                </div>
                            </div>
                            <div className="row valor-situacao-reserva">
                                <div className="col-sm-6">
                                    <label>Valor:</label>
                                    <p>{formatarReal(Number(venda.valorTotalGeral).toString())}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label>Empresa Parceira:</label>
                                    {venda.itinerarioRetorno === null ?
                                        <p>{venda.itinerarioIda.nomeEmpresa}</p>
                                        : <p>{venda.itinerarioIda.nomeEmpresa} / {venda.itinerarioRetorno.nomeEmpresa}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.exibirInstrucaoCartao()}
                <div className='col-md-12 d-flex justify-content-center mb-2'>
                    <button className="btn btn-laranja ml-4" onClick={() => this.props.history.push('/')}>Voltar
                    </button>
                </div>
            </div>);
    }

    exibirInstrucaoCartao() {
        return (<div><h3 className='m-3' style={{ fontSize: 18 }}><strong> Instruções</strong></h3>
            <div className="col m-2">
                <p style={{ fontSize: 14, marginBottom: 8 }}><strong>Pagamento utilizando Cartão de Crédito</strong></p>
                <p>
                    - Você concluiu sua compra em nosso site e estamos aguardando a operadora do seu cartão confirmar sua compra.
                    <br />
                    - Com o pagamento confirmardo, você receberá as informações da viagem por e-mail. <br />
                    - Caso haja algum problema com sua compra, você receberá as informações pelo seu e-mail relatando o problema.
                </p>
            </div>
            <div className='col m-2'>
                <p style={{ fontSize: 14, marginBottom: 8 }}><strong>Para acompanhar sua compra</strong></p>
                <p>
                    - Retorne até a página inicial, clicando no botão <b>VOLTAR</b> abaixo. <br />
                    - Vá até o ícone do seu perfil e clique em <b>Meus Pedidos</b>.<br />
                    - Dentro do menu <b>Meus Pedidos</b>, você vai poder ver detalhes do seu pedido e acessar o bilhete da sua reserva. <br />
                </p>
            </div>
        </div>)
    }

    ehPagamentoPix(parametros) {
        return parametros && parametros.formaPagamento === 'PIX'
    }

    ehPagamentoCartao(parametros) {
        return parametros && parametros.formaPagamento === 'Cartão'
    }

    render() {
        const parametros = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
        return (
            <div className='container'>
                <div>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            {this.ehPagamentoPix(parametros) ? this.exibirPagamentoPix() :
                                this.ehPagamentoCartao(parametros) ? this.exibirPagamentoCartao() :
                                    getBrowserHistory().push('/')
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        venda: state.venda
    }
}

export const mapDispatchToProps = dispatch => (
    bindActionCreators({
        changeFieldValue,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ReciboPagamento);
