import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosAguiaTurismo = {
    ...dadosAgenciaComum,
    id: 'aguiaturismo',
    nome: 'Águia Turismo',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    email: 'suportevexado@gmail.com',
    idsTagManager: ['GTM-KVFXTHH']
};
