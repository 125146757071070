import pt from 'date-fns/locale/pt';
import React, {Component} from 'react'
import 'moment/locale/pt-br'


import '../../estilo/comum/css/react-datepicker.css';
import {connect} from 'react-redux';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import moment from 'moment';
import SelecaoCidadeAutocomplete from '../comum/SelecaoCidadeAutocomplete';

import {
    selecionarDataRetorno,
    selecionarDataIda,
    limparDataRetorno,
} from '../../actions/vendasActions';

import {buscarCidadesPorNome} from '../../actions/cidadeActions'

import {buscarPassagens, buscarPassagensDistribusion, limparPassagens, selecionarOrigem, selecionarDestino} from '../../actions/vendasActions';
import {withRouter} from 'react-router-dom';
import { recuperarConfEmpresa } from '../../util/applicationContext';

registerLocale('pt', pt)
setDefaultLocale('pt');

export class BuscaPassagem extends Component {
    selecionarOrigem(origem) {
        this.props.selecionarOrigem(origem);
    }

    selecionarDestino(destino) {
        this.props.selecionarDestino(destino);
    }

    exibirCalendario(ida) {
        let data;

        let minDate = new Date();
        if (ida) {
            if (this.props.dataIdaSelecionada) {
                data = moment(this.props.dataIdaSelecionada).toDate()
            }
        } else {
            minDate = moment(this.props.dataIdaSelecionada).add(1, 'days').toDate();
            if (this.props.dataRetornoSelecionada) {
                data = moment(this.props.dataRetornoSelecionada).toDate()
            }
        }
        return (
            <div aria-label="campo-busca-data" >
                <i className="material-icons nav-icon mt-2 ml-2"
                   style={{position: 'absolute', zIndex: 9998}}>date_range</i>
                <DatePicker
                    className='form-control form-control-lg campo-busca-data'
                    value={data}
                    selected={data}
                    startDate={moment(this.props.dataIdaSelecionada).toDate()}
                    endDate={moment(this.props.dataRetornoSelecionada).toDate()}
                    minDate={minDate}
                    disabled={!ida && !this.props.dataIdaSelecionada}
                    onChange={date => this.selecionarData(date, ida)}
                    dateFormat="dd/MM/yyyy"
                    withPortal
                    aria-label="campo-busca-data"
                />
            </div>
        )
    }

    selecionarData(data, ida) {
        this.props.limparPassagens();
        if (ida) {
            this.props.selecionarDataIda(data);
            this.props.limparDataRetorno();
        } else {
            this.props.selecionarDataRetorno(data);
        }
    }

    async buscar() {
        this.props.limparPassagens();
        const {venda: {origem, destino}, avancar, empresaSelecionada} = this.props;
        if (avancar) {
            avancar(1);
        }

        let url = `/passagem-de-onibus/${origem.nomeComUfNormalizado}/${destino.nomeComUfNormalizado}/${moment(this.props.dataIdaSelecionada).format('YYYY-MM-DD')}`;
        if (this.props.dataRetornoSelecionada) {
            url = `${url}/${moment(this.props.dataRetornoSelecionada).format('YYYY-MM-DD')}`
        }

        const empresaId = empresaSelecionada? empresaSelecionada.empresaId: null

        this.props.history.push(url);
        await this.props.buscarPassagens(origem.id, destino.id, this.props.dataIdaSelecionada, this.props.dataRetornoSelecionada, empresaId);
        if (recuperarConfEmpresa().distribusion) {
            this.props.buscarPassagensDistribusion(origem.id, destino.id, this.props.dataIdaSelecionada, this.props.dataRetornoSelecionada, empresaId);
        }
    }

    limpar() {
        this.props.limparDataRetorno();
        this.props.limparPassagens()
    }

    exibirIconeLimparRetorno() {
        if (this.props.dataRetornoSelecionada) {
            return (
                <div>
                    <i className="material-icons mt-4" style={{
                        marginLeft: '-40px',
                        position: 'absolute',
                        cursor: 'pointer',
                    }} onClick={() => this.limpar()}>close</i>
                </div>)
        }
        return '';
    }

    render() {
        const { origem, destino } = this.props.venda || '';
        return (
            <div className='border border-bottom-2 border-box'>
                <div className="input-group mb-3 mt-3 container">
                    <div className="col-12 col-sm-12 col-md-3">
                        <strong>Origem</strong>
                        <SelecaoCidadeAutocomplete selecionarCidade={this.selecionarOrigem.bind(this)}
                                                   icone='near_me'
                                                   value={origem}/>
                    </div>
                    <div>
                        <i className="material-icons mt-4 d-sm-none d-none"
                        >arrow_forward</i>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3">
                        <strong>Destino</strong>
                        <SelecaoCidadeAutocomplete
                            icone='place'
                            selecionarCidade={this.selecionarDestino.bind(this)}
                            value={destino}/>
                    </div>
                    <div className="col-12 col-sm-12 col-md-2">
                        <strong>Partida</strong>
                        {this.exibirCalendario(true)}
                    </div>
                    <div className="col-12 col-sm-12 col-md-2 mt-sm-2 mt-2 mt-lg-0 mt-md-0">
                        <strong>Retorno</strong>
                        {this.exibirCalendario()}
                    </div>
                    {this.exibirIconeLimparRetorno()}
                    <div className="col-12 col-sm-12 col-md-1" style={{padding: 0}}>
                        <button onClick={() => this.buscar()}
                                disabled={!origem || !destino || !this.props.dataIdaSelecionada}
                                className='btn botaoLogar btn-buscaInterna btn-buscar-todo-trechos'>Buscar
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


export const mapStateToProps = state => (
    {
        dataIdaSelecionada: state.venda.dataIdaSelecionada,
        dataRetornoSelecionada: state.venda.dataRetornoSelecionada,
        venda: state.venda,
        empresaSelecionada: state.empresa.selecionada,
    });


export default withRouter(connect(mapStateToProps,
    {
        buscarCidadesPorNome,
        selecionarOrigem,
        selecionarDestino,
        selecionarDataIda,
        selecionarDataRetorno,
        buscarPassagens,
        buscarPassagensDistribusion,
        limparDataRetorno,
        limparPassagens
    })(BuscaPassagem))
