import React from 'react';
import logoAgencia from '../../../estilo/comum/img/logo-geral-agencia-rosa.png'
import iconAgencia from '../../../estilo/comum/img/logo-geral-agencia.png'
import ConteudoQuemSomosAG from '../../../componentes/quem-somos/agencias/ConteudoQuemSomosAG';
import dadosSiteComum from './dadosSiteComum';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/9.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/23.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoExpressoDiamanteTransbrasilPassagensAdamantina.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/24.webp';

const logoAgenciaRodape = <img className="agencia" src={logoAgencia} alt="Logo Agência" style={{width: '70px'}}/>;
const logoAgenciaCabecalho = <img className="logo-site agencia" src={logoAgencia} alt='Logo Agência'/>;

export const dadosAdamantina = {
    ...dadosSiteComum,
    id: 'expressoadamantina',
    pastaEstilo: 'expressoadamantinanovo',
    nome: 'Passagens Adamantina',
    nomePrefixo: 'a Passagens Adamantina',
    razaoSocial: 'PASSAGENS ADAMANTINA LTDA, VAT – VIACAO ADAMANTINA DE TRANSPORTES LTDA E VIACAO ESMERALDA TRANSPORTES LTDA',
    cnpj: 'Passagens Adamantina - 43.004.159/0001-97, VAT - 43.759.885/0001-10 e Esmeralda - 04.229.706/0001-80',
    endereco:'Passagens Adamantina - R WASHINGTON LUIS, 368. CEP: 17.900-000. METROPOLE. DRACENA-SP, VAT - R EDGAR JADIM BASTOS, 292 - SALA 3. CEP: 16.018-410. JD. NOVA YORK. ARACATUBA-SP e Esmeralda - R SANTA CATARINA, 50. SALA 56, L3. CEP: 86.010-470. CENTRO. LONDRINA-PR.',
    telefone: '(61) 99846-3885',
    wtpp: '(61) 99846-3885',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    twitter: '@Adamantina',
    email: 'contato@passagensadamantina.com.br',
    emailSuporte: 'suporte@passagensadamantina.com.br',
    lkemailSuporte: 'mailto:suporte@passagensadamantina.com.br',
    lktelefone: 'tel:5561998463885',
    lkemail: 'mailto:contato@passagensadamantina.com.br',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561998463885&text=Ol%C3%A1!%20Gostaria%20de%20uma%20comprar%20passagem.',
    logo: logoAgencia,
    logoCabecacho: logoAgenciaCabecalho,
    logoRodape: logoAgenciaRodape,
    icone: iconAgencia,
    quemSomos: ConteudoQuemSomosAG,
    videoInicio: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/video-backbp2.mp4',
    idsTagManager: ['GTM-PHGZ336'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+desconto+expresso+diamante+-+transbrasil++-+passagens+adamantina.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/6.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/6.mobile.webp'

};
