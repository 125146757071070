export const BUSCAR_MEUS_PEDIDOS = '@pedidosReducer/BUSCAR_MEUS_PEDIDOS';
export const SELECIONAR_PEDIDO = '@pedidosReducer/SELECIONAR_PEDIDO';
export const BUSCAR_VOUCHER = '@pedidosReducer/BUSCAR_VOUCHER';


const initialState = {
    meusPedidos: [],
    pedidoSelecionado: {},
    voucher:{}
};

export default function reducerPedidos(state = initialState, action) {
    switch (action.type) {
        case BUSCAR_MEUS_PEDIDOS: {
            return {
                ...state,
                meusPedidos: action.payload.data,
            }
        }
        case BUSCAR_VOUCHER: {
            return {
                ...state,
                voucher: action.payload.data,
            }
        }

        case SELECIONAR_PEDIDO: {
            return {
                ...state,
                pedidoSelecionado: action.payload,
            }
        }

        default: {
            return state
        }
    }

}
