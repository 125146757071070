const expressoGuanabara = [

        {link: '/passagem-de-onibus/caucaia-ce/pentecoste-ce'},
        {link: '/passagem-de-onibus/itapecuru-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/jardim-ce-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/ocara-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/itapecuru-ma/brejo-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/santarita-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/caxias-ma/natal-rn'},
        {link: '/passagem-de-onibus/caxias-ma/mossoro-rn'},
        {link: '/passagem-de-onibus/chaval-ce/saoluis-ma'},
        {link: '/passagem-de-onibus/coreau-ce/parnaiba-pi'},
        {link: '/passagem-de-onibus/codo-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/pentecoste-ce/apuiares-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/caxias-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/caxias-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/milha-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/quixada-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/quixelo-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/pentecoste-ce'},
        {link: '/passagem-de-onibus/crateus-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/acarau-ce/cruz-ce'},
        {link: '/passagem-de-onibus/bacabeira-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/timon-ma/maceio-al'},
        {link: '/passagem-de-onibus/altoalegredomaranhao-ma/caxias-ma'},
        {link: '/passagem-de-onibus/codo-ma/caxias-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/itapecuru-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/pentecoste-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/granja-ce'},
        {link: '/passagem-de-onibus/campomaior-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/pentecoste-ce'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/iguatu-ce/quixelo-ce'},
        {link: '/passagem-de-onibus/pombal-pb/aracati-ce'},
        {link: '/passagem-de-onibus/pombal-pb/mossoro-rn'},
        {link: '/passagem-de-onibus/banabuiu-ce/quixelo-ce'},
        {link: '/passagem-de-onibus/madalena-ce/caninde-ce'},
        {link: '/passagem-de-onibus/madalena-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/madalena-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/madalena-ce/sobral-ce'},
        {link: '/passagem-de-onibus/crato-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/granja-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/ipu-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/ilhagrande-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/quixelo-ce'},
        {link: '/passagem-de-onibus/ipu-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/ipu-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/oros-ce/russas-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/pentecoste-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/pentecoste-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/pentecoste-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/quixelo-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/sobral-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/crateus-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/crateus-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/araripina-pe/bacabal-ma'},
        {link: '/passagem-de-onibus/araripina-pe/crato-ce'},
        {link: '/passagem-de-onibus/araripina-pe/imperatriz-ma'},
        {link: '/passagem-de-onibus/araripina-pe/maraba-pa'},
        {link: '/passagem-de-onibus/araripina-pe/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/araripina-pe/teresina-pi'},
        {link: '/passagem-de-onibus/araripina-pe/picos-pi'},
        {link: '/passagem-de-onibus/araripina-pe/caxias-ma'},
        {link: '/passagem-de-onibus/araripina-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/aracati-ce/teresina-pi'},
        {link: '/passagem-de-onibus/aracati-ce/piripiri-pi'},
        {link: '/passagem-de-onibus/aracati-ce/pombal-pb'},
        {link: '/passagem-de-onibus/aracati-ce/santamariadopara-pa'},
        {link: '/passagem-de-onibus/aracati-ce/aracaju-se'},
        {link: '/passagem-de-onibus/aracati-ce/campinagrande-pb'},
        {link: '/passagem-de-onibus/aracati-ce/patos-pb'},
        {link: '/passagem-de-onibus/aracati-ce/timon-ma'},
        {link: '/passagem-de-onibus/araripe-ce/maraba-pa'},
        {link: '/passagem-de-onibus/aracati-ce/belem-pa'},
        {link: '/passagem-de-onibus/aracati-ce/castanhal-pa'},
        {link: '/passagem-de-onibus/aracati-ce/capanema,pa-pa'},
        {link: '/passagem-de-onibus/aracati-ce/catoledorocha-pb'},
        {link: '/passagem-de-onibus/aracati-ce/palmeiradosindios-al'},
        {link: '/passagem-de-onibus/aracati-ce/recife-pe'},
        {link: '/passagem-de-onibus/goiania-go/coloniadogurgueia-pi'},
        {link: '/passagem-de-onibus/goiania-go/capimgrosso-ba'},
        {link: '/passagem-de-onibus/goiania-go/campomaior-pi'},
        {link: '/passagem-de-onibus/goiania-go/eliseumartins-pi'},
        {link: '/passagem-de-onibus/goiania-go/missaovelha-ce'},
        {link: '/passagem-de-onibus/goiania-go/fortaleza-ce'},
        {link: '/passagem-de-onibus/goiania-go/joaopessoa-pb'},
        {link: '/passagem-de-onibus/goiania-go/aguabranca-pi'},
        {link: '/passagem-de-onibus/goiania-go/gilbues-pi'},
        {link: '/passagem-de-onibus/goiania-go/jaguaribe-ce'},
        {link: '/passagem-de-onibus/goiania-go/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/vitoriadesantoantao-pe/teresina-pi'},
        {link: '/passagem-de-onibus/vitoriadesantoantao-pe/picos-pi'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/santaines-ma'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/arari-ma'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/pindare-mirim-ma'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/santarita-ma'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/caruaru-pe/feiradesantana-ba'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/barro-ce'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/salgueiro-pe'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/capimgrosso-ba'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/fortaleza-ce'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/jaguaribe-ce'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/petrolina-pe'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/pombal-pb'},
        {link: '/passagem-de-onibus/vitoriadomearim-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/goiania-go/altos-pi'},
        {link: '/passagem-de-onibus/goiania-go/amarante-pi'},
        {link: '/passagem-de-onibus/goiania-go/barro-ce'},
        {link: '/passagem-de-onibus/goiania-go/salgueiro-pe'},
        {link: '/passagem-de-onibus/goiania-go/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/goiania-go/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/goiania-go/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/goiania-go/cristinocastro-pi'},
        {link: '/passagem-de-onibus/goiania-go/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/russas-ce'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/campinagrande-pb'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/patos-pb'},
        {link: '/passagem-de-onibus/alvoradadonorte-go/brejosanto-ce'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/condado-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/araripina-pe/timon-ma'},
        {link: '/passagem-de-onibus/caruaru-pe/belem-pa'},
        {link: '/passagem-de-onibus/caruaru-pe/bacabal-ma'},
        {link: '/passagem-de-onibus/caruaru-pe/crato-ce'},
        {link: '/passagem-de-onibus/caruaru-pe/missaovelha-ce'},
        {link: '/passagem-de-onibus/caruaru-pe/fortaleza-ce'},
        {link: '/passagem-de-onibus/caruaru-pe/santaines-ma'},
        {link: '/passagem-de-onibus/caruaru-pe/ribeiradopombal-ba'},
        {link: '/passagem-de-onibus/caruaru-pe/imperatriz-ma'},
        {link: '/passagem-de-onibus/caruaru-pe/maraba-pa'},
        {link: '/passagem-de-onibus/goiania-go/natal-rn'},
        {link: '/passagem-de-onibus/goiania-go/luiscorreia-pi'},
        {link: '/passagem-de-onibus/goiania-go/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/goiania-go/ipu-ce'},
        {link: '/passagem-de-onibus/goiania-go/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/goiania-go/montealegredopiaui-pi'},
        {link: '/passagem-de-onibus/goiania-go/milagres,ce-ce'},
        {link: '/passagem-de-onibus/goiania-go/teresina-pi'},
        {link: '/passagem-de-onibus/caruaru-pe/jati-ce'},
        {link: '/passagem-de-onibus/caruaru-pe/jeremoabo-ba'},
        {link: '/passagem-de-onibus/caruaru-pe/cicerodantas-ba'},
        {link: '/passagem-de-onibus/caruaru-pe/picos-pi'},
        {link: '/passagem-de-onibus/caruaru-pe/caxias-ma'},
        {link: '/passagem-de-onibus/caruaru-pe/salvador-ba'},
        {link: '/passagem-de-onibus/caruaru-pe/serrinha-ba'},
        {link: '/passagem-de-onibus/caruaru-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/caruaru-pe/brejosanto-ce'},
        {link: '/passagem-de-onibus/caruaru-pe/timon-ma'},
        {link: '/passagem-de-onibus/amontada-ce/granja-ce'},
        {link: '/passagem-de-onibus/amontada-ce/itarema-ce'},
        {link: '/passagem-de-onibus/amontada-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/amontada-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/amontada-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/amontada-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/amontada-ce/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/amontada-ce/morrinhosce-ce'},
        {link: '/passagem-de-onibus/amontada-ce/tururu-ce'},
        {link: '/passagem-de-onibus/amontada-ce/sobral-ce'},
        {link: '/passagem-de-onibus/amontada-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/amontada-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/amontada-ce/umirim-ce'},
        {link: '/passagem-de-onibus/amontada-ce/acarau-ce'},
        {link: '/passagem-de-onibus/amontada-ce/acarau-ce'},
        {link: '/passagem-de-onibus/angicos-/recife-pe'},
        {link: '/passagem-de-onibus/anapurus-ma/brejo-ma'},
        {link: '/passagem-de-onibus/anapurus-ma/camocim-ce'},
        {link: '/passagem-de-onibus/anapurus-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/goiania-go/patos-pb'},
        {link: '/passagem-de-onibus/goiania-go/floriano-pi'},
        {link: '/passagem-de-onibus/goiania-go/crateus-ce'},
        {link: '/passagem-de-onibus/goiania-go/curraisnovos-rn'},
        {link: '/passagem-de-onibus/goiania-go/ico-ce'},
        {link: '/passagem-de-onibus/goiania-go/brejosanto-ce'},
        {link: '/passagem-de-onibus/altos-pi/goiania-go'},
        {link: '/passagem-de-onibus/altos-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/altos-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/altos-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/goiania-go/sobral-ce'},
        {link: '/passagem-de-onibus/goiania-go/redencaodogurgueia-pi'},
        {link: '/passagem-de-onibus/goiania-go/russas-ce'},
        {link: '/passagem-de-onibus/goiania-go/caico-rn'},
        {link: '/passagem-de-onibus/goiania-go/soledade-pb-pb'},
        {link: '/passagem-de-onibus/goiania-go/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/goiania-go/oeiras-pi'},
        {link: '/passagem-de-onibus/goiania-go/campinagrande-pb'},
        {link: '/passagem-de-onibus/goiania-go/jacobina-ba'},
        {link: '/passagem-de-onibus/amarante-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/amontada-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/amontada-ce/camocim-ce'},
        {link: '/passagem-de-onibus/amontada-ce/cruz-ce'},
        {link: '/passagem-de-onibus/amontada-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/amontada-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/amontada-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/amontada-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/amontada-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/altos-pi/brasilia-df'},
        {link: '/passagem-de-onibus/altos-pi/brasilia-df'},
        {link: '/passagem-de-onibus/altos-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/altos-pi/cocal-pi'},
        {link: '/passagem-de-onibus/altos-pi/timon-ma'},
        {link: '/passagem-de-onibus/amarante-pi/goiania-go'},
        {link: '/passagem-de-onibus/amarante-pi/anapolis-go'},
        {link: '/passagem-de-onibus/amarante-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/amarante-pi/brasilia-df'},
        {link: '/passagem-de-onibus/amarante-pi/brasilia-df'},
        {link: '/passagem-de-onibus/goiania-go/barbalha-ce'},
        {link: '/passagem-de-onibus/goiania-go/petrolina-pe'},
        {link: '/passagem-de-onibus/goiania-go/taua-ce'},
        {link: '/passagem-de-onibus/goiania-go/sousa-pb'},
        {link: '/passagem-de-onibus/goiania-go/ouricuri-pe'},
        {link: '/passagem-de-onibus/goiania-go/piripiri-pi'},
        {link: '/passagem-de-onibus/goiania-go/picos-pi'},
        {link: '/passagem-de-onibus/goiania-go/pombal-pb'},
        {link: '/passagem-de-onibus/goiania-go/parnaiba-pi'},
        {link: '/passagem-de-onibus/goiania-go/piracuruca-pi'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/sousa-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/pombal-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/aparecida-pb-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/altos-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/altos-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/altos-pi/teresina-pi'},
        {link: '/passagem-de-onibus/altos-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/altos-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/altos-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/altos-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/altos-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/altos-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/altos-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/altos-pi/fortaleza-ce'},
        {link: '/passagem-de-onibus/altos-pi/anapolis-go'},
        {link: '/passagem-de-onibus/altos-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/apuiares-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/aurora-ce/barro-ce'},
        {link: '/passagem-de-onibus/aurora-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/aurora-ce/crato-ce'},
        {link: '/passagem-de-onibus/aurora-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/aurora-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/aurora-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/aurora-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/aurora-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/aurora-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/aurora-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/anapurus-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/saobento-pb-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/saobento-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/saobento-pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/saobento-pb-pb/pombal-pb'},
        {link: '/passagem-de-onibus/saobento-pb-pb/brejodocruz-pb'},
        {link: '/passagem-de-onibus/saobento-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/saobento-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/saobento-pb-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/assu-/belem-pa'},
        {link: '/passagem-de-onibus/barreiras-ba/campinagrande-pb'},
        {link: '/passagem-de-onibus/barreiras-ba/patos-pb'},
        {link: '/passagem-de-onibus/barreiras-ba/crateus-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/ico-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/brejosanto-ce'},
        {link: '/passagem-de-onibus/barreirinhas-ma/paulinoneves-ma'},
        {link: '/passagem-de-onibus/barreirinhas-ma/tutoia-ma'},
        {link: '/passagem-de-onibus/barreirinhas-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/barreirinhas-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/barro-ce/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/barro-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/barro-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/barro-ce/anapolis-go'},
        {link: '/passagem-de-onibus/barro-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/barro-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/barro-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/barro-ce/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/barro-ce/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/barro-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/barro-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/aurora-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/aurora-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/aurora-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/aurora-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/aurora-ce/russas-ce'},
        {link: '/passagem-de-onibus/aurora-ce/umari-ce'},
        {link: '/passagem-de-onibus/aurora-ce/ico-ce'},
        {link: '/passagem-de-onibus/aurora-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/barro-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/salgueiro-pe'},
        {link: '/passagem-de-onibus/anapurus-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/anapurus-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/anapurus-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/anapurus-ma/parnaiba-pi'},
        {link: '/passagem-de-onibus/anapurus-ma/sobral-ce'},
        {link: '/passagem-de-onibus/anapurus-ma/saobernardo-'},
        {link: '/passagem-de-onibus/anapurus-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/anapurus-ma/santarita-ma'},
        {link: '/passagem-de-onibus/anapurus-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/anapurus-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/barro-ce/goiania-go'},
        {link: '/passagem-de-onibus/barro-ce/aurora-ce'},
        {link: '/passagem-de-onibus/barro-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/barro-ce/salgueiro-pe'},
        {link: '/passagem-de-onibus/barro-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/barro-ce/capimgrosso-ba'},
        {link: '/passagem-de-onibus/barro-ce/cabrobo-pe'},
        {link: '/passagem-de-onibus/barro-ce/crato-ce'},
        {link: '/passagem-de-onibus/barro-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/fortaleza-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/jaguaribe-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/barreiras-ba/ipu-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/taua-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/picos-pi'},
        {link: '/passagem-de-onibus/barreiras-ba/pombal-pb'},
        {link: '/passagem-de-onibus/barreiras-ba/russas-ce'},
        {link: '/passagem-de-onibus/barreiras-ba/oeiras-pi'},
        {link: '/passagem-de-onibus/assu-/castanhal-pa'},
        {link: '/passagem-de-onibus/assu-/capanema,pa-pa'},
        {link: '/passagem-de-onibus/assu-/fortaleza-ce'},
        {link: '/passagem-de-onibus/assu-/goiana-pe'},
        {link: '/passagem-de-onibus/assu-/recife-pe'},
        {link: '/passagem-de-onibus/assu-/teresina-pi'},
        {link: '/passagem-de-onibus/assu-/piripiri-pi'},
        {link: '/passagem-de-onibus/assu-/santamariadopara-pa'},
        {link: '/passagem-de-onibus/apuiares-ce/pentecoste-ce'},
        {link: '/passagem-de-onibus/belem-pa/aracati-ce'},
        {link: '/passagem-de-onibus/belem-pa/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/belem-pa/caruaru-pe'},
        {link: '/passagem-de-onibus/belem-pa/assu-'},
        {link: '/passagem-de-onibus/belem-pa/salgueiro-pe'},
        {link: '/passagem-de-onibus/belem-pa/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/belem-pa/codo-ma'},
        {link: '/passagem-de-onibus/belem-pa/fortaleza-ce'},
        {link: '/passagem-de-onibus/belem-pa/joaopessoa-pb'},
        {link: '/passagem-de-onibus/belem-pa/governadornunesfreire-ma'},
        {link: '/passagem-de-onibus/belacruz-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/belacruz-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/umirim-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/acarau-ce'},
        {link: '/passagem-de-onibus/bezerros-pe/teresina-pi'},
        {link: '/passagem-de-onibus/cajazeirinhas-pb/condado-pb'},
        {link: '/passagem-de-onibus/cajazeirinhas-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/cajazeirinhas-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/cajazeirinhas-pb/patos-pb'},
        {link: '/passagem-de-onibus/belem-pa/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/belem-pa/recife-pe'},
        {link: '/passagem-de-onibus/belem-pa/natal-rn'},
        {link: '/passagem-de-onibus/belem-pa/maceio-al'},
        {link: '/passagem-de-onibus/belem-pa/maracacume-ma'},
        {link: '/passagem-de-onibus/belem-pa/teresina-pi'},
        {link: '/passagem-de-onibus/belem-pa/novaolindadomaranhao-ma'},
        {link: '/passagem-de-onibus/belem-pa/arcoverde-pe'},
        {link: '/passagem-de-onibus/belem-pa/piripiri-pi'},
        {link: '/passagem-de-onibus/belem-pa/caxias-ma'},
        {link: '/passagem-de-onibus/barro-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/barro-ce/petrolina-pe'},
        {link: '/passagem-de-onibus/barro-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/barro-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/barro-ce/pombal-pb'},
        {link: '/passagem-de-onibus/barro-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/barro-ce/russas-ce'},
        {link: '/passagem-de-onibus/barro-ce/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/barro-ce/brasilia-df'},
        {link: '/passagem-de-onibus/barro-ce/brasilia-df'},
        {link: '/passagem-de-onibus/carnaubal-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/sobral-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/coreau-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/carnaubal-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/umirim-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/amontada-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/camocim-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/cruz-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/cruz-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/belacruz-ce/tururu-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/carnaubal-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/barro-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/barro-ce/campinagrande-pb'},
        {link: '/passagem-de-onibus/barro-ce/ibotirama-ba'},
        {link: '/passagem-de-onibus/barro-ce/umari-ce'},
        {link: '/passagem-de-onibus/barro-ce/patos-pb'},
        {link: '/passagem-de-onibus/barro-ce/ico-ce'},
        {link: '/passagem-de-onibus/barro-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/barro-ce/seabra-ba'},
        {link: '/passagem-de-onibus/brasileira-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/teresina-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/cocal-pi'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/condado-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/diamante-pb'},
        {link: '/passagem-de-onibus/salgueiro-pe/ico-ce'},
        {link: '/passagem-de-onibus/salgueiro-pe/brejosanto-ce'},
        {link: '/passagem-de-onibus/salgueiro-pe/timon-ma'},
        {link: '/passagem-de-onibus/salgueiro-pe/seabra-ba'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/santoantoniodoslopes-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/colinas-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/capinzaldonorte-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/dompedro-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/pastosbons-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/presidentedutra-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/paraibano-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/saodomingosdomaranhao-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/saojoaodospatos-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/santarita-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/baraodegrajau-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/batalha-pi/batalha-pi'},
        {link: '/passagem-de-onibus/batalha-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/batalha-pi/barras-pi'},
        {link: '/passagem-de-onibus/batalha-pi/cabeceirasdopiaui-pi'},
        {link: '/passagem-de-onibus/batalha-pi/josedefreitas-pi'},
        {link: '/passagem-de-onibus/batalha-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/batalha-pi/teresina-pi'},
        {link: '/passagem-de-onibus/batalha-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/belem-pa/sobral-ce'},
        {link: '/passagem-de-onibus/belem-pa/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/belem-pa/mossoro-rn'},
        {link: '/passagem-de-onibus/belem-pa/serratalhada-pe'},
        {link: '/passagem-de-onibus/belem-pa/campinagrande-pb'},
        {link: '/passagem-de-onibus/belem-pa/floriano-pi'},
        {link: '/passagem-de-onibus/belem-pa/peritoro-ma'},
        {link: '/passagem-de-onibus/belem-pa/timon-ma'},
        {link: '/passagem-de-onibus/belem-pa/zedoca-ma'},
        {link: '/passagem-de-onibus/belem-pa/tiangua-ce'},
        {link: '/passagem-de-onibus/salgueiro-pe/fortaleza-ce'},
        {link: '/passagem-de-onibus/salgueiro-pe/santaines-ma'},
        {link: '/passagem-de-onibus/salgueiro-pe/anapolis-go'},
        {link: '/passagem-de-onibus/salgueiro-pe/imperatriz-ma'},
        {link: '/passagem-de-onibus/salgueiro-pe/maraba-pa'},
        {link: '/passagem-de-onibus/salgueiro-pe/jaguaribe-ce'},
        {link: '/passagem-de-onibus/salgueiro-pe/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/salgueiro-pe/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/salgueiro-pe/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/salgueiro-pe/milagres,ce-ce'},
        {link: '/passagem-de-onibus/salgueiro-pe/planaltina,df-df'},
        {link: '/passagem-de-onibus/salgueiro-pe/caxias-ma'},
        {link: '/passagem-de-onibus/salgueiro-pe/russas-ce'},
        {link: '/passagem-de-onibus/salgueiro-pe/rodavelha-ba'},
        {link: '/passagem-de-onibus/salgueiro-pe/brasilia-df'},
        {link: '/passagem-de-onibus/salgueiro-pe/brasilia-df'},
        {link: '/passagem-de-onibus/salgueiro-pe/sobradinho-df-df'},
        {link: '/passagem-de-onibus/salgueiro-pe/ibotirama-ba'},
        {link: '/passagem-de-onibus/salgueiro-pe/patos-pb'},
        {link: '/passagem-de-onibus/salgueiro-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/garanhuns-pe/fortaleza-ce'},
        {link: '/passagem-de-onibus/garanhuns-pe/mossoro-rn'},
        {link: '/passagem-de-onibus/salgueiro-pe/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/salgueiro-pe/goiania-go'},
        {link: '/passagem-de-onibus/salgueiro-pe/barreiras-ba'},
        {link: '/passagem-de-onibus/salgueiro-pe/barro-ce'},
        {link: '/passagem-de-onibus/salgueiro-pe/belem-pa'},
        {link: '/passagem-de-onibus/salgueiro-pe/capimgrosso-ba'},
        {link: '/passagem-de-onibus/salgueiro-pe/bacabal-ma'},
        {link: '/passagem-de-onibus/batalha-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/altos-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/brasileira-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/belojardim-pe/salvador-ba'},
        {link: '/passagem-de-onibus/belojardim-pe/serrinha-ba'},
        {link: '/passagem-de-onibus/belojardim-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/belojardim-pe/feiradesantana-ba'},
        {link: '/passagem-de-onibus/bomjesusdasselvas-ma/maraba-pa'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/goiania-go'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/altos-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/batalha-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/barras-pi'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/pianco-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/patos-pb'},
        {link: '/passagem-de-onibus/belojardim-pe/ribeiradopombal-ba'},
        {link: '/passagem-de-onibus/belojardim-pe/jeremoabo-ba'},
        {link: '/passagem-de-onibus/belojardim-pe/teresina-pi'},
        {link: '/passagem-de-onibus/belojardim-pe/cicerodantas-ba'},
        {link: '/passagem-de-onibus/belojardim-pe/picos-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/teresina-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/brasilia-df'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/brasilia-df'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/timon-ma'},
        {link: '/passagem-de-onibus/banabuiu-ce/oros-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/ico-ce'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/goiania-go'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/anapolis-go'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/osasco-sp'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/uberaba-mg'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/campinas-sp'},
        {link: '/passagem-de-onibus/banabuiu-ce/cedro-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/crato-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/banabuiu-ce/quixada-ce'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/sousa-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/pombal-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/fortaleza-ce'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/anapolis-go'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/buritidoslopes-pi/josedefreitas-pi'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/bonitodesantafe-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/cachoeiradopiria-pa'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/natal-rn'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/maceio-al'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/teresina-pi'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/mossoro-rn'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/serratalhada-pe'},
        {link: '/passagem-de-onibus/boaventura-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/diamante-pb'},
        {link: '/passagem-de-onibus/barras-pi/timon-ma'},
        {link: '/passagem-de-onibus/buriticupu-ma/campomaior-pi'},
        {link: '/passagem-de-onibus/buriticupu-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/buriticupu-ma/maraba-pa'},
        {link: '/passagem-de-onibus/buriticupu-ma/teresina-pi'},
        {link: '/passagem-de-onibus/buriticupu-ma/piripiri-pi'},
        {link: '/passagem-de-onibus/buriticupu-ma/sobral-ce'},
        {link: '/passagem-de-onibus/buriticupu-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/belem-pa'},
        {link: '/passagem-de-onibus/boavistadogurupi-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/barroquinha-ce/coreau-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/barroquinha-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/umirim-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/brejo-ma/anapurus-ma'},
        {link: '/passagem-de-onibus/brejo-ma/camocim-ce'},
        {link: '/passagem-de-onibus/brejo-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/brejo-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/granja-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/massape-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/sobral-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/sobral-ce'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/ribeiraopreto-sp'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/brasilia-df'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/brasilia-df'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/bomjesus,pi-pi/santos-sp'},
        {link: '/passagem-de-onibus/barroquinha-ce/camocim-ce'},
        {link: '/passagem-de-onibus/barroquinha-ce/chaval-ce'},
        {link: '/passagem-de-onibus/boaventura-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/brejo-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/brejo-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/brejo-ma/parnaiba-pi'},
        {link: '/passagem-de-onibus/brejo-ma/sobral-ce'},
        {link: '/passagem-de-onibus/brejo-ma/saobernardo-'},
        {link: '/passagem-de-onibus/brejo-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/brejo-ma/santarita-ma'},
        {link: '/passagem-de-onibus/brejo-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/brejo-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/brejo-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/barras-pi/batalha-pi'},
        {link: '/passagem-de-onibus/barras-pi/batalha-pi'},
        {link: '/passagem-de-onibus/barras-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/barras-pi/cabeceirasdopiaui-pi'},
        {link: '/passagem-de-onibus/barras-pi/josedefreitas-pi'},
        {link: '/passagem-de-onibus/barras-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/barras-pi/teresina-pi'},
        {link: '/passagem-de-onibus/barras-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/barras-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/boaventura-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/castanhal-pa/serratalhada-pe'},
        {link: '/passagem-de-onibus/castanhal-pa/peritoro-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/timon-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/zedoca-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/tiangua-ce'},
        {link: '/passagem-de-onibus/canabrava-ma/sobral-ce'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/goiania-go'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/altos-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/fortaleza-ce'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/anapolis-go'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/itapaje-ce'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/teresina-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/castanhal-pa/maceio-al'},
        {link: '/passagem-de-onibus/castanhal-pa/maracacume-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/teresina-pi'},
        {link: '/passagem-de-onibus/castanhal-pa/novaolindadomaranhao-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/piripiri-pi'},
        {link: '/passagem-de-onibus/castanhal-pa/picos-pi'},
        {link: '/passagem-de-onibus/castanhal-pa/caxias-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/sobral-ce'},
        {link: '/passagem-de-onibus/castanhal-pa/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/castanhal-pa/mossoro-rn'},
        {link: '/passagem-de-onibus/baixio-ce-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/crato-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/codo-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/codo-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/codo-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/codo-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/codo-ma/maraba-pa'},
        {link: '/passagem-de-onibus/codo-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/codo-ma/teresina-pi'},
        {link: '/passagem-de-onibus/codo-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/codo-ma/santarita-ma'},
        {link: '/passagem-de-onibus/codo-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/assu-'},
        {link: '/passagem-de-onibus/castanhal-pa/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/codo-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/bacabal-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/fortaleza-ce'},
        {link: '/passagem-de-onibus/castanhal-pa/governadornunesfreire-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/santaines-ma'},
        {link: '/passagem-de-onibus/castanhal-pa/natal-rn'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/brasilia-df'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/brasilia-df'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/capitaodecampos-pi/cocal-pi'},
        {link: '/passagem-de-onibus/codo-ma/teresina-pi'},
        {link: '/passagem-de-onibus/codo-ma/belem-pa'},
        {link: '/passagem-de-onibus/baixio-ce-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/russas-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/umari-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/ico-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/castanhal-pa/aracati-ce'},
        {link: '/passagem-de-onibus/boaventura-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/pianco-pb'},
        {link: '/passagem-de-onibus/boaventura-pb/patos-pb'},
        {link: '/passagem-de-onibus/baixio-ce-ce/aurora-ce'},
        {link: '/passagem-de-onibus/baixio-ce-ce/barro-ce'},
        {link: '/passagem-de-onibus/cristinocastro-pi/brasilia-df'},
        {link: '/passagem-de-onibus/cristinocastro-pi/brasilia-df'},
        {link: '/passagem-de-onibus/cristinocastro-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/cristinocastro-pi/santos-sp'},
        {link: '/passagem-de-onibus/cachoeiradopiria-pa/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/goiania-go'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/osasco-sp'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/uberaba-mg'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/campinas-sp'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/ribeiraopreto-sp'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/brasilia-df'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/brasilia-df'},
        {link: '/passagem-de-onibus/cantodoburiti-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/coloniadogurgueia-pi/goiania-go'},
        {link: '/passagem-de-onibus/coloniadogurgueia-pi/anapolis-go'},
        {link: '/passagem-de-onibus/coloniadogurgueia-pi/uberaba-mg'},
        {link: '/passagem-de-onibus/coloniadogurgueia-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/coloniadogurgueia-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/codo-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/codo-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/codo-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/camocim-ce/amontada-ce'},
        {link: '/passagem-de-onibus/camocim-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/camocim-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/camocim-ce/chaval-ce'},
        {link: '/passagem-de-onibus/camocim-ce/cruz-ce'},
        {link: '/passagem-de-onibus/camocim-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/camocim-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/camocim-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/camocim-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/camocim-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/camocim-ce/granja-ce'},
        {link: '/passagem-de-onibus/camocim-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/camocim-ce/itarema-ce'},
        {link: '/passagem-de-onibus/camocim-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/camocim-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/camocim-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/camocim-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/camocim-ce/umirim-ce'},
        {link: '/passagem-de-onibus/camocim-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/camocim-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/camocim-ce/acarau-ce'},
        {link: '/passagem-de-onibus/camocim-ce/acarau-ce'},
        {link: '/passagem-de-onibus/camocim-ce/saoluis-ma'},
        {link: '/passagem-de-onibus/cristinocastro-pi/goiania-go'},
        {link: '/passagem-de-onibus/cristinocastro-pi/anapolis-go'},
        {link: '/passagem-de-onibus/cristinocastro-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/cristinocastro-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/cedro-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/cedro-ce/crato-ce'},
        {link: '/passagem-de-onibus/cedro-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/cedro-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/cedro-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/cedro-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/cedro-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/cedro-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/cedro-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/camocim-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/camocim-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/camocim-ce/massape-ce'},
        {link: '/passagem-de-onibus/camocim-ce/tururu-ce'},
        {link: '/passagem-de-onibus/camocim-ce/parnaiba-pi'},
        {link: '/passagem-de-onibus/camocim-ce/sobral-ce'},
        {link: '/passagem-de-onibus/camocim-ce/sobral-ce'},
        {link: '/passagem-de-onibus/camocim-ce/coreau-ce'},
        {link: '/passagem-de-onibus/camocim-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/camocim-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/coloniadogurgueia-pi/brasilia-df'},
        {link: '/passagem-de-onibus/coloniadogurgueia-pi/brasilia-df'},
        {link: '/passagem-de-onibus/coloniadogurgueia-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/altos-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/teresina-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/cocaldetelha-pi/cocal-pi'},
        {link: '/passagem-de-onibus/cedro-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/bacabal-ma/araripina-pe'},
        {link: '/passagem-de-onibus/bacabal-ma/caruaru-pe'},
        {link: '/passagem-de-onibus/bacabal-ma/salgueiro-pe'},
        {link: '/passagem-de-onibus/bacabal-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/bacabal-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/bacabal-ma/custodia-pe'},
        {link: '/passagem-de-onibus/bacabal-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/saobernardo-'},
        {link: '/passagem-de-onibus/chapadinha-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/santarita-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/chaval-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/chaval-ce/camocim-ce'},
        {link: '/passagem-de-onibus/chaval-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/chaval-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/capimgrosso-ba/patos-pb'},
        {link: '/passagem-de-onibus/capimgrosso-ba/brejosanto-ce'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/altos-pi'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/cajueirodapraia-pi/teresina-pi'},
        {link: '/passagem-de-onibus/cedro-ce/ico-ce'},
        {link: '/passagem-de-onibus/chapadinha-ma/anapurus-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/brejo-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/camocim-ce'},
        {link: '/passagem-de-onibus/chapadinha-ma/chaval-ce'},
        {link: '/passagem-de-onibus/chapadinha-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/chapadinha-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/chapadinha-ma/parnaiba-pi'},
        {link: '/passagem-de-onibus/chapadinha-ma/sobral-ce'},
        {link: '/passagem-de-onibus/chaval-ce/sobral-ce'},
        {link: '/passagem-de-onibus/chaval-ce/coreau-ce'},
        {link: '/passagem-de-onibus/chaval-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/chaval-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/chaval-ce/umirim-ce'},
        {link: '/passagem-de-onibus/chaval-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/chaval-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/capimgrosso-ba/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/capimgrosso-ba/goiania-go'},
        {link: '/passagem-de-onibus/capimgrosso-ba/barro-ce'},
        {link: '/passagem-de-onibus/capimgrosso-ba/salgueiro-pe'},
        {link: '/passagem-de-onibus/capimgrosso-ba/joaopessoa-pb'},
        {link: '/passagem-de-onibus/capimgrosso-ba/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/capimgrosso-ba/sousa-pb'},
        {link: '/passagem-de-onibus/capimgrosso-ba/planaltina,df-df'},
        {link: '/passagem-de-onibus/capimgrosso-ba/pombal-pb'},
        {link: '/passagem-de-onibus/capimgrosso-ba/brasilia-df'},
        {link: '/passagem-de-onibus/capimgrosso-ba/brasilia-df'},
        {link: '/passagem-de-onibus/capimgrosso-ba/sobradinho-df-df'},
        {link: '/passagem-de-onibus/capimgrosso-ba/campinagrande-pb'},
        {link: '/passagem-de-onibus/bacabal-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/bacabal-ma/itapaje-ce'},
        {link: '/passagem-de-onibus/bacabal-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/bacabal-ma/maraba-pa'},
        {link: '/passagem-de-onibus/bacabal-ma/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/bacabal-ma/recife-pe'},
        {link: '/passagem-de-onibus/bacabal-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/bacabal-ma/natal-rn'},
        {link: '/passagem-de-onibus/bacabal-ma/maceio-al'},
        {link: '/passagem-de-onibus/bacabal-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/cedro-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/cedro-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/cedro-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/cedro-ce/quixada-ce'},
        {link: '/passagem-de-onibus/cedro-ce/oros-ce'},
        {link: '/passagem-de-onibus/cedro-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/cedro-ce/russas-ce'},
        {link: '/passagem-de-onibus/cedro-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/cedro-ce/ico-ce'},
        {link: '/passagem-de-onibus/chaval-ce/granja-ce'},
        {link: '/passagem-de-onibus/chaval-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/chaval-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/chaval-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/chaval-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/chaval-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/chaval-ce/massape-ce'},
        {link: '/passagem-de-onibus/chaval-ce/parnaiba-pi'},
        {link: '/passagem-de-onibus/chaval-ce/sobral-ce'},
        {link: '/passagem-de-onibus/bacabal-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/bacabal-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/bacabal-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/acailandia-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/acailandia-ma/maraba-pa'},
        {link: '/passagem-de-onibus/acailandia-ma/teresina-pi'},
        {link: '/passagem-de-onibus/acailandia-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/colinas-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/colinas-ma/santoantoniodoslopes-ma'},
        {link: '/passagem-de-onibus/colinas-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/colinas-ma/santarita-ma'},
        {link: '/passagem-de-onibus/colinas-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/colinas-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/colinas-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/goiania-go'},
        {link: '/passagem-de-onibus/campomaior-pi/altos-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/buriticupu-ma'},
        {link: '/passagem-de-onibus/saobenedito-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/groairas-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/graca-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/carire-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/carire-ce'},
        {link: '/passagem-de-onibus/bacabal-ma/teresina-pi'},
        {link: '/passagem-de-onibus/bacabal-ma/ouricuri-pe'},
        {link: '/passagem-de-onibus/bacabal-ma/arcoverde-pe'},
        {link: '/passagem-de-onibus/bacabal-ma/sobral-ce'},
        {link: '/passagem-de-onibus/bacabal-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/bacabal-ma/mossoro-rn'},
        {link: '/passagem-de-onibus/bacabal-ma/santarita-ma'},
        {link: '/passagem-de-onibus/bacabal-ma/serratalhada-pe'},
        {link: '/passagem-de-onibus/bacabal-ma/floriano-pi'},
        {link: '/passagem-de-onibus/bacabal-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/colinas-ma/dompedro-ma'},
        {link: '/passagem-de-onibus/colinas-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/colinas-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/colinas-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/colinas-ma/pastosbons-ma'},
        {link: '/passagem-de-onibus/colinas-ma/presidentedutra-ma'},
        {link: '/passagem-de-onibus/colinas-ma/paraibano-ma'},
        {link: '/passagem-de-onibus/colinas-ma/saodomingosdomaranhao-ma'},
        {link: '/passagem-de-onibus/colinas-ma/saojoaodospatos-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/campomaior-pi/brasilia-df'},
        {link: '/passagem-de-onibus/campomaior-pi/brasilia-df'},
        {link: '/passagem-de-onibus/campomaior-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/campomaior-pi/cocal-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/peritoro-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/timon-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/saoluis-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/tiangua-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/sobral-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/saobenedito-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/campomaior-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/teresina-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/campomaior-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/caxias-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/sobral-ce'},
        {link: '/passagem-de-onibus/campomaior-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/campomaior-pi/fortaleza-ce'},
        {link: '/passagem-de-onibus/campomaior-pi/santaluzia,ma-ma'},
        {link: '/passagem-de-onibus/campomaior-pi/anapolis-go'},
        {link: '/passagem-de-onibus/campomaior-pi/imperatriz-ma'},
        {link: '/passagem-de-onibus/saobenedito-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/capanema,pa-pa/aracati-ce'},
        {link: '/passagem-de-onibus/capanema,pa-pa/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/assu-'},
        {link: '/passagem-de-onibus/capanema,pa-pa/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/codo-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/bacabal-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/fortaleza-ce'},
        {link: '/passagem-de-onibus/capanema,pa-pa/joaopessoa-pb'},
        {link: '/passagem-de-onibus/capanema,pa-pa/sobral-ce'},
        {link: '/passagem-de-onibus/capanema,pa-pa/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/capanema,pa-pa/mossoro-rn'},
        {link: '/passagem-de-onibus/capanema,pa-pa/serratalhada-pe'},
        {link: '/passagem-de-onibus/capanema,pa-pa/peritoro-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/timon-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/zedoca-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/tiangua-ce'},
        {link: '/passagem-de-onibus/madalena-ce/crato-ce'},
        {link: '/passagem-de-onibus/madalena-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/capanema,pa-pa/governadornunesfreire-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/santaines-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/natal-rn'},
        {link: '/passagem-de-onibus/capanema,pa-pa/maceio-al'},
        {link: '/passagem-de-onibus/capanema,pa-pa/maracacume-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/teresina-pi'},
        {link: '/passagem-de-onibus/capanema,pa-pa/novaolindadomaranhao-ma'},
        {link: '/passagem-de-onibus/capanema,pa-pa/piripiri-pi'},
        {link: '/passagem-de-onibus/capanema,pa-pa/picos-pi'},
        {link: '/passagem-de-onibus/capanema,pa-pa/caxias-ma'},
        {link: '/passagem-de-onibus/madalena-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/madalena-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/madalena-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/madalena-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/madalena-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/madalena-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/madalena-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/madalena-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/corrente-pi/ipu-ce'},
        {link: '/passagem-de-onibus/corrente-pi/taua-ce'},
        {link: '/passagem-de-onibus/corrente-pi/sobral-ce'},
        {link: '/passagem-de-onibus/corrente-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/corrente-pi/crateus-ce'},
        {link: '/passagem-de-onibus/corrente-pi/santos-sp'},
        {link: '/passagem-de-onibus/cabrobo-pe/barro-ce'},
        {link: '/passagem-de-onibus/cabrobo-pe/jaguaribe-ce'},
        {link: '/passagem-de-onibus/cabrobo-pe/brejosanto-ce'},
        {link: '/passagem-de-onibus/cruz-ce/amontada-ce'},
        {link: '/passagem-de-onibus/cruz-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/cruz-ce/camocim-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/ipu-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/saobenedito-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/sobral-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/sobral-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/sobral-ce'},
        {link: '/passagem-de-onibus/cruz-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/cruz-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/cruz-ce/trairi-ce'},
        {link: '/passagem-de-onibus/cruz-ce/umirim-ce'},
        {link: '/passagem-de-onibus/cruz-ce/acarau-ce'},
        {link: '/passagem-de-onibus/cruz-ce/acarau-ce'},
        {link: '/passagem-de-onibus/cruz-ce/amontada-ce'},
        {link: '/passagem-de-onibus/cruz-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/cruz-ce/cruz-ce'},
        {link: '/passagem-de-onibus/cruz-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/coreau-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/saobenedito-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/brasilia-df'},
        {link: '/passagem-de-onibus/saobenedito-ce/brasilia-df'},
        {link: '/passagem-de-onibus/saobenedito-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/saobenedito-ce/umirim-ce'},
        {link: '/passagem-de-onibus/saobenedito-ce/varjota-ce'},
        {link: '/passagem-de-onibus/cruz-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/cruz-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/cruz-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/cruz-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/cruz-ce/granja-ce'},
        {link: '/passagem-de-onibus/cruz-ce/itarema-ce'},
        {link: '/passagem-de-onibus/cruz-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/cruz-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/cruz-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/cruz-ce/tururu-ce'},
        {link: '/passagem-de-onibus/cruz-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/cruz-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/cruz-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/cruz-ce/tururu-ce'},
        {link: '/passagem-de-onibus/cruz-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/cruz-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/cruz-ce/umirim-ce'},
        {link: '/passagem-de-onibus/cruz-ce/acarau-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/fronteiras-pi'},
        {link: '/passagem-de-onibus/custodia-pe/timon-ma'},
        {link: '/passagem-de-onibus/caucaia-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/caucaia-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/caucaia-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/caucaia-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/caucaia-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/caucaia-ce/sobral-ce'},
        {link: '/passagem-de-onibus/caucaia-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/caucaia-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/caucaia-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/carius-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/maraba-pa'},
        {link: '/passagem-de-onibus/campossales,ce-ce/jucas-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/teresina-pi'},
        {link: '/passagem-de-onibus/campossales,ce-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/quixada-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/picos-pi'},
        {link: '/passagem-de-onibus/campossales,ce-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/custodia-pe/bacabal-ma'},
        {link: '/passagem-de-onibus/custodia-pe/riodejaneiro-rj'},
        {link: '/passagem-de-onibus/custodia-pe/picos-pi'},
        {link: '/passagem-de-onibus/custodia-pe/caxias-ma'},
        {link: '/passagem-de-onibus/custodia-pe/saopaulo-sp'},
        {link: '/passagem-de-onibus/custodia-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/catoledorocha-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/jerico-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/planaltina,df-df'},
        {link: '/passagem-de-onibus/catoledorocha-pb/pombal-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/paulista-pb-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/brejodocruz-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/brasilia-df'},
        {link: '/passagem-de-onibus/catoledorocha-pb/brasilia-df'},
        {link: '/passagem-de-onibus/catoledorocha-pb/sobradinho-df-df'},
        {link: '/passagem-de-onibus/catoledorocha-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/patos-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/catarina-ce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/quixada-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/catarina-ce-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/campossales,ce-ce/valencadopiaui-pi'},
        {link: '/passagem-de-onibus/catoledorocha-pb/aracati-ce'},
        {link: '/passagem-de-onibus/catoledorocha-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/catoledorocha-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/diamante-pb/pianco-pb'},
        {link: '/passagem-de-onibus/diamante-pb/patos-pb'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/milha-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/quixada-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/coremas-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/coremas-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/coremas-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/coremas-pb/patos-pb'},
        {link: '/passagem-de-onibus/coremas-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/governadorvaladares-mg/joaopessoa-pb'},
        {link: '/passagem-de-onibus/diamante-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/diamante-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/diamante-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/diamante-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/diamante-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/sousa-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/pombal-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/caucaia-ce/apuiares-ce'},
        {link: '/passagem-de-onibus/condado-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/condado-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/condado-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/condado-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/condado-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/condado-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/condado-pb/sousa-pb'},
        {link: '/passagem-de-onibus/condado-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/condado-pb/pombal-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/diamante-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/coremas-pb/condado-pb'},
        {link: '/passagem-de-onibus/coremas-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/coremas-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/coremas-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/coremas-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/coremas-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/coremas-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/coremas-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/condado-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/condado-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/condado-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/condado-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/condado-pb/patos-pb'},
        {link: '/passagem-de-onibus/condado-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/sousa-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/pombal-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/pianco-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/conceicao-pb-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/diamante-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/diamante-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/diamante-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/diamante-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/diamante-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/diamante-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/diamante-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/diamante-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/diamante-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/crato-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/crato-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/crato-ce/ico-ce'},
        {link: '/passagem-de-onibus/crato-ce/ico-ce'},
        {link: '/passagem-de-onibus/crato-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/crato-ce/peritoro-ma'},
        {link: '/passagem-de-onibus/crato-ce/timon-ma'},
        {link: '/passagem-de-onibus/marco-ce-ce/amontada-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/camocim-ce'},
        {link: '/passagem-de-onibus/crato-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/crato-ce/teresina-pi'},
        {link: '/passagem-de-onibus/crato-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/crato-ce/quixada-ce'},
        {link: '/passagem-de-onibus/crato-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/crato-ce/oros-ce'},
        {link: '/passagem-de-onibus/crato-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/crato-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/crato-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/crato-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/crato-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/crato-ce/picos-pi'},
        {link: '/passagem-de-onibus/crato-ce/pombal-pb'},
        {link: '/passagem-de-onibus/crato-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/crato-ce/caxias-ma'},
        {link: '/passagem-de-onibus/crato-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/crato-ce/sobral-ce'},
        {link: '/passagem-de-onibus/crato-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/crato-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/crato-ce/russas-ce'},
        {link: '/passagem-de-onibus/crato-ce/madalena-ce'},
        {link: '/passagem-de-onibus/crato-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/crato-ce/caninde-ce'},
        {link: '/passagem-de-onibus/crato-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/crato-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/crato-ce/joaopessoa-pb'},
        {link: '/passagem-de-onibus/crato-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/crato-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/crato-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/crato-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/crato-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/crato-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/crato-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/crato-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/crato-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/crato-ce/ipu-ce'},
        {link: '/passagem-de-onibus/crato-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/crato-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/crato-ce/soledade-pb-pb'},
        {link: '/passagem-de-onibus/crato-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/crato-ce/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/crato-ce/serratalhada-pe'},
        {link: '/passagem-de-onibus/crato-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/crato-ce/campinagrande-pb'},
        {link: '/passagem-de-onibus/crato-ce/umari-ce'},
        {link: '/passagem-de-onibus/crato-ce/patos-pb'},
        {link: '/passagem-de-onibus/crato-ce/crateus-ce'},
        {link: '/passagem-de-onibus/crato-ce/araripina-pe'},
        {link: '/passagem-de-onibus/crato-ce/caruaru-pe'},
        {link: '/passagem-de-onibus/crato-ce/aurora-ce'},
        {link: '/passagem-de-onibus/crato-ce/barro-ce'},
        {link: '/passagem-de-onibus/crato-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/crato-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/crato-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/crato-ce/cedro-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/milha-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/quixada-ce'},
        {link: '/passagem-de-onibus/deputadoirapuanpinheiro-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/goiania-go'},
        {link: '/passagem-de-onibus/missaovelha-ce/aurora-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/barro-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/anapolis-go'},
        {link: '/passagem-de-onibus/missaovelha-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/tururu-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/marco-ce-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/umirim-ce'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/codo-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/bacabal-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/teresina-pi'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/caxias-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/santarita-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/timon-ma'},
        {link: '/passagem-de-onibus/saomateusdomaranhao-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/eliseumartins-pi/goiania-go'},
        {link: '/passagem-de-onibus/marco-ce-ce/cruz-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/cruz-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/granja-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/tururu-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/eliseumartins-pi/anapolis-go'},
        {link: '/passagem-de-onibus/eliseumartins-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/eliseumartins-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/eliseumartins-pi/brasilia-df'},
        {link: '/passagem-de-onibus/eliseumartins-pi/brasilia-df'},
        {link: '/passagem-de-onibus/eliseumartins-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/ubajara-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/petrolina-pe'},
        {link: '/passagem-de-onibus/missaovelha-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/pombal-pb'},
        {link: '/passagem-de-onibus/missaovelha-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/russas-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/missaovelha-ce/campinagrande-pb'},
        {link: '/passagem-de-onibus/ubajara-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/sobral-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/coreau-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/ubajara-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/ubajara-ce/umirim-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/umirim-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/acarau-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/amontada-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/camocim-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/cruz-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/cruz-ce'},
        {link: '/passagem-de-onibus/marco-ce-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/umari-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/patos-pb'},
        {link: '/passagem-de-onibus/caninde-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/caninde-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/caninde-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/caninde-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/caninde-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/caninde-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/caninde-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/caninde-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/caruaru-pe'},
        {link: '/passagem-de-onibus/fariasbrito-ce/russas-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/crateus-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/ico-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/ico-ce'},
        {link: '/passagem-de-onibus/caninde-ce/madalena-ce'},
        {link: '/passagem-de-onibus/caninde-ce/crato-ce'},
        {link: '/passagem-de-onibus/caninde-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/buriticupu-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/castanhal-pa'},
        {link: '/passagem-de-onibus/fortaleza-ce/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/camocim-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/cedro-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/chapadinha-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/chaval-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/bacabal-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/acailandia-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/fortaleza-ce/goiania-go'},
        {link: '/passagem-de-onibus/fortaleza-ce/altos-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/amontada-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/anapurus-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/assu-'},
        {link: '/passagem-de-onibus/fortaleza-ce/aurora-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/fortaleza-ce/barro-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/quixada-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/oros-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/belem-pa'},
        {link: '/passagem-de-onibus/fortaleza-ce/garanhuns-pe'},
        {link: '/passagem-de-onibus/fortaleza-ce/salgueiro-pe'},
        {link: '/passagem-de-onibus/fortaleza-ce/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/brejo-ma'},
        {link: '/passagem-de-onibus/fariasbrito-ce/cedro-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/crato-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/sobral-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/ico-ce'},
        {link: '/passagem-de-onibus/missaovelha-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/fariasbrito-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/recife-pe'},
        {link: '/passagem-de-onibus/fortaleza-ce/jardim-ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/jati-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/natal-rn'},
        {link: '/passagem-de-onibus/fortaleza-ce/saojoaodoriodopeixe-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/fortaleza-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/maceio-al'},
        {link: '/passagem-de-onibus/fortaleza-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/porteiras-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/ipu-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/riodejaneiro-rj'},
        {link: '/passagem-de-onibus/fortaleza-ce/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/fortaleza-ce/milha-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/joaopessoa-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/granja-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/groairas-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/graca-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/santaines-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/santaluzia,ma-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/carire-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/carire-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/anapolis-go'},
        {link: '/passagem-de-onibus/fortaleza-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/itarema-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/palmeiradosindios-al'},
        {link: '/passagem-de-onibus/fortaleza-ce/itapecuru-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/imperatriz-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/crato-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/carius-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/maraba-pa'},
        {link: '/passagem-de-onibus/fortaleza-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/jucas-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/campomaior-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/capanema,pa-pa'},
        {link: '/passagem-de-onibus/fortaleza-ce/cruz-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/cruz-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/catoledorocha-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/quixada-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/vitoriadaconquista-ba'},
        {link: '/passagem-de-onibus/fortaleza-ce/novaolinda-ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/ocara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/oros-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/oros-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/teresina-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/massape-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/santamariadopara-pa'},
        {link: '/passagem-de-onibus/fortaleza-ce/brasilia-df'},
        {link: '/passagem-de-onibus/fortaleza-ce/brasilia-df'},
        {link: '/passagem-de-onibus/fortaleza-ce/santanadocariri-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/fortaleza-ce/mossoro-rn'},
        {link: '/passagem-de-onibus/fortaleza-ce/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/serratalhada-pe'},
        {link: '/passagem-de-onibus/fortaleza-ce/seabra-ba'},
        {link: '/passagem-de-onibus/fortaleza-ce/amontada-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/cruz-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/itarema-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/acarau-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/peritoro-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/santos-sp'},
        {link: '/passagem-de-onibus/fortaleza-ce/timon-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/feiradesantana-ba'},
        {link: '/passagem-de-onibus/fortaleza-ce/saoluis-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/zedoca-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/petrolina-pe'},
        {link: '/passagem-de-onibus/fortaleza-ce/morrinhosce-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/sousa-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/arcoverde-pe'},
        {link: '/passagem-de-onibus/fortaleza-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/penaforte-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/tururu-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/russas-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/coreau-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/coreau-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/saopaulo-sp'},
        {link: '/passagem-de-onibus/fortaleza-ce/saobernardo-'},
        {link: '/passagem-de-onibus/fortaleza-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/fortaleza-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/fortaleza-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/caxias-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/sobral-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/sobral-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/sobral-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/quixelo-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/salvador-ba'},
        {link: '/passagem-de-onibus/fortaleza-ce/uirauna-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/acarau-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/acarau-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/acarau-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/varjota-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/vargemgrande-ma'},
        {link: '/passagem-de-onibus/fortaleza-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/ico-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/ico-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/piripiri-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/fortaleza-ce/pombal-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/parnaiba-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/piracuruca-pi'},
        {link: '/passagem-de-onibus/fortaleza-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/aracaju-se'},
        {link: '/passagem-de-onibus/fortaleza-ce/campinagrande-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/ibotirama-ba'},
        {link: '/passagem-de-onibus/fortaleza-ce/umari-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/patos-pb'},
        {link: '/passagem-de-onibus/fortaleza-ce/umirim-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/fortaleza-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/sobral-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/sobral-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/coreau-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/frecheirinha-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/umirim-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/fronteiras-pi/campossales,ce-ce'},
        {link: '/passagem-de-onibus/joaopessoa-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/goiania-go'},
        {link: '/passagem-de-onibus/joaopessoa-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/cajazeirinhas-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/belem-pa'},
        {link: '/passagem-de-onibus/joaopessoa-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/forquilha-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/massape-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/joaopessoa-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/capimgrosso-ba'},
        {link: '/passagem-de-onibus/joaopessoa-pb/capanema,pa-pa'},
        {link: '/passagem-de-onibus/joaopessoa-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/condado-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/coremas-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/governadorvaladares-mg'},
        {link: '/passagem-de-onibus/joaopessoa-pb/diamante-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/crato-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/sobral-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/sobral-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/coreau-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/coreau-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/forquilha-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/forquilha-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/caninde-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/granja-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/graca-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/carire-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/camocim-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/chaval-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/madalena-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/crato-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/umirim-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/forquilha-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/frecheirinha-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/joaopessoa-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/saojoaodoriodopeixe-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/irece-ba'},
        {link: '/passagem-de-onibus/joaopessoa-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/joaopessoa-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/saojosedoscampos-sp'},
        {link: '/passagem-de-onibus/joaopessoa-pb/milagres,ce-ce'},
        {link: '/passagem-de-onibus/joaopessoa-pb/teresina-pi'},
        {link: '/passagem-de-onibus/joaopessoa-pb/taubate-sp'},
        {link: '/passagem-de-onibus/joaopessoa-pb/peritoro-ma'},
        {link: '/passagem-de-onibus/joaopessoa-pb/santos-sp'},
        {link: '/passagem-de-onibus/joaopessoa-pb/timon-ma'},
        {link: '/passagem-de-onibus/joaopessoa-pb/feiradesantana-ba'},
        {link: '/passagem-de-onibus/joaopessoa-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/guarabira-pb/planaltina,df-df'},
        {link: '/passagem-de-onibus/guarabira-pb/brasilia-df'},
        {link: '/passagem-de-onibus/guarabira-pb/brasilia-df'},
        {link: '/passagem-de-onibus/granja-ce/amontada-ce'},
        {link: '/passagem-de-onibus/joaopessoa-pb/planaltina,df-df'},
        {link: '/passagem-de-onibus/joaopessoa-pb/pombal-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/paulista-pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/caxias-ma'},
        {link: '/passagem-de-onibus/joaopessoa-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/resende-rj'},
        {link: '/passagem-de-onibus/joaopessoa-pb/saopaulo-sp'},
        {link: '/passagem-de-onibus/joaopessoa-pb/brejodocruz-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/granja-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/granja-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/granja-ce/camocim-ce'},
        {link: '/passagem-de-onibus/granja-ce/chaval-ce'},
        {link: '/passagem-de-onibus/granja-ce/cruz-ce'},
        {link: '/passagem-de-onibus/granja-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/granja-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/granja-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/granja-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/granja-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/granja-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/granja-ce/sobral-ce'},
        {link: '/passagem-de-onibus/granja-ce/sobral-ce'},
        {link: '/passagem-de-onibus/granja-ce/coreau-ce'},
        {link: '/passagem-de-onibus/granja-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/granja-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/granja-ce/umirim-ce'},
        {link: '/passagem-de-onibus/granja-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/granja-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/granja-ce/acarau-ce'},
        {link: '/passagem-de-onibus/gravata-pe/teresina-pi'},
        {link: '/passagem-de-onibus/joaopessoa-pb/muriae-mg'},
        {link: '/passagem-de-onibus/joaopessoa-pb/barbalha-ce'},
        {link: '/passagem-de-onibus/joaopessoa-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/vitoriadaconquista-ba'},
        {link: '/passagem-de-onibus/joaopessoa-pb/petrolina-pe'},
        {link: '/passagem-de-onibus/joaopessoa-pb/sousa-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/picos-pi'},
        {link: '/passagem-de-onibus/granja-ce/itarema-ce'},
        {link: '/passagem-de-onibus/granja-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/granja-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/granja-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/granja-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/granja-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/granja-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/granja-ce/massape-ce'},
        {link: '/passagem-de-onibus/granja-ce/tururu-ce'},
        {link: '/passagem-de-onibus/granja-ce/parnaiba-pi'},
        {link: '/passagem-de-onibus/joaopessoa-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/joaopessoa-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/anapolis-go'},
        {link: '/passagem-de-onibus/joaopessoa-pb/imperatriz-ma'},
        {link: '/passagem-de-onibus/joaopessoa-pb/maraba-pa'},
        {link: '/passagem-de-onibus/joaopessoa-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/joaopessoa-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/jerico-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/brasilia-df'},
        {link: '/passagem-de-onibus/joaopessoa-pb/brasilia-df'},
        {link: '/passagem-de-onibus/joaopessoa-pb/sobradinho-df-df'},
        {link: '/passagem-de-onibus/joaopessoa-pb/mossoro-rn'},
        {link: '/passagem-de-onibus/joaopessoa-pb/pianco-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/patos-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/patos-pb'},
        {link: '/passagem-de-onibus/joaopessoa-pb/floriano-pi'},
        {link: '/passagem-de-onibus/goiana-pe/assu-'},
        {link: '/passagem-de-onibus/goiana-pe/lajes-rn'},
        {link: '/passagem-de-onibus/goiana-pe/mossoro-rn'},
        {link: '/passagem-de-onibus/jequie-ba/campinagrande-pb'},
        {link: '/passagem-de-onibus/jequie-ba/campinagrande-pb'},
        {link: '/passagem-de-onibus/governadornunesfreire-ma/belem-pa'},
        {link: '/passagem-de-onibus/governadornunesfreire-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/governadornunesfreire-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/governadornunesfreire-ma/teresina-pi'},
        {link: '/passagem-de-onibus/governadornunesfreire-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/umirim-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/varjota-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/crateus-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/gilbues-pi/goiania-go'},
        {link: '/passagem-de-onibus/gilbues-pi/anapolis-go'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/sobral-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/sobral-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/sobral-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/coreau-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/crato-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/groairas-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/graca-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/carire-ce'},
        {link: '/passagem-de-onibus/aguabranca-pi/goiania-go'},
        {link: '/passagem-de-onibus/aguabranca-pi/anapolis-go'},
        {link: '/passagem-de-onibus/aguabranca-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/aguabranca-pi/brasilia-df'},
        {link: '/passagem-de-onibus/aguabranca-pi/brasilia-df'},
        {link: '/passagem-de-onibus/aguabranca-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/gilbues-pi/uberaba-mg'},
        {link: '/passagem-de-onibus/gilbues-pi/campinas-sp'},
        {link: '/passagem-de-onibus/gilbues-pi/ribeiraopreto-sp'},
        {link: '/passagem-de-onibus/gilbues-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/gilbues-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/gilbues-pi/brasilia-df'},
        {link: '/passagem-de-onibus/gilbues-pi/brasilia-df'},
        {link: '/passagem-de-onibus/gilbues-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/goiana-pe/angicos-'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/ipu-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/carire-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/guaraciabadonorte-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/santaines-ma/floriano-pi'},
        {link: '/passagem-de-onibus/santaines-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/santaines-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/santaines-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/santaines-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/catingueira-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/pianco-pb'},
        {link: '/passagem-de-onibus/catingueira-pb/patos-pb'},
        {link: '/passagem-de-onibus/saojoaodocariri-pb/sertania-pe'},
        {link: '/passagem-de-onibus/arari-ma/vitoriadomearim-ma'},
        {link: '/passagem-de-onibus/arari-ma/santaines-ma'},
        {link: '/passagem-de-onibus/arari-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/arari-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/groairas-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/groairas-ce/sobral-ce'},
        {link: '/passagem-de-onibus/groairas-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/groairas-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/groairas-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/groairas-ce/umirim-ce'},
        {link: '/passagem-de-onibus/groairas-ce/varjota-ce'},
        {link: '/passagem-de-onibus/graca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/graca-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/graca-ce/carire-ce'},
        {link: '/passagem-de-onibus/graca-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/santaluzia,ma-ma/sobral-ce'},
        {link: '/passagem-de-onibus/santaluzia,ma-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/ribeiradopombal-ba/caruaru-pe'},
        {link: '/passagem-de-onibus/ribeiradopombal-ba/belojardim-pe'},
        {link: '/passagem-de-onibus/ribeiradopombal-ba/recife-pe'},
        {link: '/passagem-de-onibus/ribeiradopombal-ba/petrolandia-pe'},
        {link: '/passagem-de-onibus/saboeiro-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/saboeiro-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/saboeiro-ce/carius-ce'},
        {link: '/passagem-de-onibus/saboeiro-ce/jucas-ce'},
        {link: '/passagem-de-onibus/arari-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/arari-ma/pindare-mirim-ma'},
        {link: '/passagem-de-onibus/arari-ma/santarita-ma'},
        {link: '/passagem-de-onibus/arari-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/arari-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/santaluzia,ma-ma/campomaior-pi'},
        {link: '/passagem-de-onibus/santaluzia,ma-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/santaluzia,ma-ma/itapaje-ce'},
        {link: '/passagem-de-onibus/santaluzia,ma-ma/maraba-pa'},
        {link: '/passagem-de-onibus/santaluzia,ma-ma/piripiri-pi'},
        {link: '/passagem-de-onibus/santaines-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/santaines-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/santaines-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/santaines-ma/arari-ma'},
        {link: '/passagem-de-onibus/santaines-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/santaines-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/santaines-ma/maraba-pa'},
        {link: '/passagem-de-onibus/santaines-ma/recife-pe'},
        {link: '/passagem-de-onibus/santaines-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/santaines-ma/natal-rn'},
        {link: '/passagem-de-onibus/santaines-ma/maceio-al'},
        {link: '/passagem-de-onibus/santaines-ma/teresina-pi'},
        {link: '/passagem-de-onibus/santaines-ma/arcoverde-pe'},
        {link: '/passagem-de-onibus/santaines-ma/picos-pi'},
        {link: '/passagem-de-onibus/santaines-ma/pindare-mirim-ma'},
        {link: '/passagem-de-onibus/santaines-ma/sobral-ce'},
        {link: '/passagem-de-onibus/santaines-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/santaines-ma/mossoro-rn'},
        {link: '/passagem-de-onibus/santaines-ma/santarita-ma'},
        {link: '/passagem-de-onibus/santaines-ma/serratalhada-pe'},
        {link: '/passagem-de-onibus/governadornunesfreire-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/groairas-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/groairas-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/groairas-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/groairas-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/groairas-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/groairas-ce/carire-ce'},
        {link: '/passagem-de-onibus/groairas-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/groairas-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/groairas-ce/ipu-ce'},
        {link: '/passagem-de-onibus/graca-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/graca-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/graca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/graca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/graca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/graca-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/santaines-ma/caruaru-pe'},
        {link: '/passagem-de-onibus/santaines-ma/vitoriadomearim-ma'},
        {link: '/passagem-de-onibus/santaines-ma/salgueiro-pe'},
        {link: '/passagem-de-onibus/saboeiro-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/saboeiro-ce/quixada-ce'},
        {link: '/passagem-de-onibus/saboeiro-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/saboeiro-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/saboeiro-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/saboeiro-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/carire-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/carire-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/carire-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/carire-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/russas-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/ico-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/ico-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/anapolis-go/altos-pi'},
        {link: '/passagem-de-onibus/anapolis-go/amarante-pi'},
        {link: '/passagem-de-onibus/carire-ce/varjota-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/aurora-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/barro-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/cedro-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/crato-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/quixada-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/oros-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/quixelo-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/horizonte-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/carire-ce/sobral-ce'},
        {link: '/passagem-de-onibus/carire-ce/sobral-ce'},
        {link: '/passagem-de-onibus/carire-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/carire-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/carire-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/carire-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/carire-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/carire-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/carire-ce/groairas-ce'},
        {link: '/passagem-de-onibus/carire-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/carire-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/carire-ce/ipu-ce'},
        {link: '/passagem-de-onibus/carire-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/carire-ce/sobral-ce'},
        {link: '/passagem-de-onibus/carire-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/carire-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/carire-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/carire-ce/umirim-ce'},
        {link: '/passagem-de-onibus/carire-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/carire-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/carire-ce/graca-ce'},
        {link: '/passagem-de-onibus/anapolis-go/missaovelha-ce'},
        {link: '/passagem-de-onibus/anapolis-go/fortaleza-ce'},
        {link: '/passagem-de-onibus/anapolis-go/joaopessoa-pb'},
        {link: '/passagem-de-onibus/anapolis-go/aguabranca-pi'},
        {link: '/passagem-de-onibus/anapolis-go/gilbues-pi'},
        {link: '/passagem-de-onibus/anapolis-go/jaguaribe-ce'},
        {link: '/passagem-de-onibus/anapolis-go/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/anapolis-go/luiscorreia-pi'},
        {link: '/passagem-de-onibus/anapolis-go/montealegredopiaui-pi'},
        {link: '/passagem-de-onibus/anapolis-go/barro-ce'},
        {link: '/passagem-de-onibus/anapolis-go/salgueiro-pe'},
        {link: '/passagem-de-onibus/anapolis-go/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/anapolis-go/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/anapolis-go/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/anapolis-go/cristinocastro-pi'},
        {link: '/passagem-de-onibus/anapolis-go/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/anapolis-go/coloniadogurgueia-pi'},
        {link: '/passagem-de-onibus/anapolis-go/campomaior-pi'},
        {link: '/passagem-de-onibus/anapolis-go/eliseumartins-pi'},
        {link: '/passagem-de-onibus/itapaje-ce/carire-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/ipu-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/massape-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/granja-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/groairas-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/graca-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/santaluzia,ma-ma'},
        {link: '/passagem-de-onibus/itapaje-ce/carire-ce'},
        {link: '/passagem-de-onibus/anapolis-go/redencaodogurgueia-pi'},
        {link: '/passagem-de-onibus/anapolis-go/russas-ce'},
        {link: '/passagem-de-onibus/anapolis-go/soledade-pb-pb'},
        {link: '/passagem-de-onibus/anapolis-go/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/anapolis-go/campinagrande-pb'},
        {link: '/passagem-de-onibus/anapolis-go/jacobina-ba'},
        {link: '/passagem-de-onibus/anapolis-go/patos-pb'},
        {link: '/passagem-de-onibus/anapolis-go/floriano-pi'},
        {link: '/passagem-de-onibus/anapolis-go/ico-ce'},
        {link: '/passagem-de-onibus/anapolis-go/brejosanto-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/sobral-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/sobral-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/sobral-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/coreau-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/coreau-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/itapaje-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/itapaje-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/anapolis-go/milagres,ce-ce'},
        {link: '/passagem-de-onibus/anapolis-go/teresina-pi'},
        {link: '/passagem-de-onibus/anapolis-go/barbalha-ce'},
        {link: '/passagem-de-onibus/anapolis-go/petrolina-pe'},
        {link: '/passagem-de-onibus/anapolis-go/sousa-pb'},
        {link: '/passagem-de-onibus/anapolis-go/ouricuri-pe'},
        {link: '/passagem-de-onibus/anapolis-go/piripiri-pi'},
        {link: '/passagem-de-onibus/anapolis-go/pombal-pb'},
        {link: '/passagem-de-onibus/anapolis-go/parnaiba-pi'},
        {link: '/passagem-de-onibus/anapolis-go/piracuruca-pi'},
        {link: '/passagem-de-onibus/itapaje-ce/umirim-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/varjota-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/camocim-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/chaval-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/bacabal-ma'},
        {link: '/passagem-de-onibus/itapaje-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/ilhagrande-pi/altos-pi'},
        {link: '/passagem-de-onibus/ilhagrande-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/ilhagrande-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/ilhagrande-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/ilhagrande-pi/teresina-pi'},
        {link: '/passagem-de-onibus/ilhagrande-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/ilhagrande-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/palmeiradosindios-al/aracati-ce'},
        {link: '/passagem-de-onibus/palmeiradosindios-al/fortaleza-ce'},
        {link: '/passagem-de-onibus/palmeiradosindios-al/mossoro-rn'},
        {link: '/passagem-de-onibus/itapecuru-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/vitoriadomearim-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/anapurus-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/brejo-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/codo-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/camocim-ce'},
        {link: '/passagem-de-onibus/itapecuru-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/bacabal-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/itaueira-pi/brasilia-df'},
        {link: '/passagem-de-onibus/itaueira-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/itapipoca-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/itapipoca-ce/umirim-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/amontada-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/camocim-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/cruz-ce'},
        {link: '/passagem-de-onibus/independencia-ce-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/itarema-ce/amontada-ce'},
        {link: '/passagem-de-onibus/itarema-ce/camocim-ce'},
        {link: '/passagem-de-onibus/itarema-ce/cruz-ce'},
        {link: '/passagem-de-onibus/itarema-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/itarema-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/itarema-ce/granja-ce'},
        {link: '/passagem-de-onibus/itarema-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/itarema-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/itarema-ce/tururu-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/cruz-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/granja-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/itarema-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/morrinhosce-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/tururu-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/itapipoca-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/umirim-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/acarau-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/acarau-ce'},
        {link: '/passagem-de-onibus/itapipoca-ce/acarau-ce'},
        {link: '/passagem-de-onibus/itarema-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/itarema-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/itarema-ce/trairi-ce'},
        {link: '/passagem-de-onibus/itarema-ce/umirim-ce'},
        {link: '/passagem-de-onibus/itarema-ce/acarau-ce'},
        {link: '/passagem-de-onibus/itarema-ce/acarau-ce'},
        {link: '/passagem-de-onibus/itaueira-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/itaueira-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/itaueira-pi/brasilia-df'},
        {link: '/passagem-de-onibus/itapaje-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/umirim-ce'},
        {link: '/passagem-de-onibus/independencia-ce-ce/crato-ce'},
        {link: '/passagem-de-onibus/independencia-ce-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/independencia-ce-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/independencia-ce-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/independencia-ce-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/independencia-ce-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/independencia-ce-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/sobral-ce'},
        {link: '/passagem-de-onibus/itapaje-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/itapaje-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/itapecuru-ma/santaines-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/arari-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/pindare-mirim-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/parnaiba-pi'},
        {link: '/passagem-de-onibus/itapecuru-ma/caxias-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/sobral-ce'},
        {link: '/passagem-de-onibus/itapecuru-ma/saobernardo-'},
        {link: '/passagem-de-onibus/itapecuru-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/santarita-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/timon-ma'},
        {link: '/passagem-de-onibus/itapecuru-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/osasco-sp/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/osasco-sp/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/osasco-sp/teresina-pi'},
        {link: '/passagem-de-onibus/imperatriz-ma/joaopessoa-pb'},
        {link: '/passagem-de-onibus/imperatriz-ma/maraba-pa'},
        {link: '/passagem-de-onibus/imperatriz-ma/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/imperatriz-ma/recife-pe'},
        {link: '/passagem-de-onibus/imperatriz-ma/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/imperatriz-ma/teresina-pi'},
        {link: '/passagem-de-onibus/imperatriz-ma/sousa-pb'},
        {link: '/passagem-de-onibus/imperatriz-ma/ouricuri-pe'},
        {link: '/passagem-de-onibus/imperatriz-ma/arcoverde-pe'},
        {link: '/passagem-de-onibus/imperatriz-ma/piripiri-pi'},
        {link: '/passagem-de-onibus/osasco-sp/piripiri-pi'},
        {link: '/passagem-de-onibus/osasco-sp/sobral-ce'},
        {link: '/passagem-de-onibus/osasco-sp/floriano-pi'},
        {link: '/passagem-de-onibus/osasco-sp/tiangua-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/camocim-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/carire-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/carire-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/ipu-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/massape-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/granja-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/groairas-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/graca-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/sobral-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/sobral-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/sobral-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/coreau-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/coreau-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/iraucuba-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/iraucuba-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/umirim-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/varjota-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/iraucuba-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/imperatriz-ma/araripina-pe'},
        {link: '/passagem-de-onibus/imperatriz-ma/caruaru-pe'},
        {link: '/passagem-de-onibus/imperatriz-ma/salgueiro-pe'},
        {link: '/passagem-de-onibus/imperatriz-ma/campomaior-pi'},
        {link: '/passagem-de-onibus/imperatriz-ma/crato-ce'},
        {link: '/passagem-de-onibus/imperatriz-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/maraba-pa/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/maraba-pa/campinagrande-pb'},
        {link: '/passagem-de-onibus/maraba-pa/patos-pb'},
        {link: '/passagem-de-onibus/maraba-pa/valencadopiaui-pi'},
        {link: '/passagem-de-onibus/maraba-pa/peritoro-ma'},
        {link: '/passagem-de-onibus/maraba-pa/timon-ma'},
        {link: '/passagem-de-onibus/maraba-pa/tiangua-ce'},
        {link: '/passagem-de-onibus/ibiara-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/maraba-pa/campossales,ce-ce'},
        {link: '/passagem-de-onibus/maraba-pa/crato-ce'},
        {link: '/passagem-de-onibus/maraba-pa/fortaleza-ce'},
        {link: '/passagem-de-onibus/maraba-pa/joaopessoa-pb'},
        {link: '/passagem-de-onibus/maraba-pa/santaines-ma'},
        {link: '/passagem-de-onibus/maraba-pa/santaluzia,ma-ma'},
        {link: '/passagem-de-onibus/maraba-pa/imperatriz-ma'},
        {link: '/passagem-de-onibus/maraba-pa/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/maraba-pa/recife-pe'},
        {link: '/passagem-de-onibus/maraba-pa/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/pianco-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/patos-pb'},
        {link: '/passagem-de-onibus/jaguaribe-ce/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/jaguaribe-ce/goiania-go'},
        {link: '/passagem-de-onibus/jaguaribe-ce/aurora-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/jaguaribe-ce/barro-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/salgueiro-pe'},
        {link: '/passagem-de-onibus/jaguaribe-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/cedro-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/cabrobo-pe'},
        {link: '/passagem-de-onibus/jaguaribe-ce/crato-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/anapolis-go'},
        {link: '/passagem-de-onibus/jaguaribe-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/jardim-ce-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/jati-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/jaguaribe-ce/porteiras-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/jaguaribe-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/maraba-pa/milagres,ce-ce'},
        {link: '/passagem-de-onibus/maraba-pa/teresina-pi'},
        {link: '/passagem-de-onibus/maraba-pa/sousa-pb'},
        {link: '/passagem-de-onibus/maraba-pa/ouricuri-pe'},
        {link: '/passagem-de-onibus/maraba-pa/arcoverde-pe'},
        {link: '/passagem-de-onibus/maraba-pa/picos-pi'},
        {link: '/passagem-de-onibus/maraba-pa/pombal-pb'},
        {link: '/passagem-de-onibus/maraba-pa/caxias-ma'},
        {link: '/passagem-de-onibus/maraba-pa/sobral-ce'},
        {link: '/passagem-de-onibus/maraba-pa/soledade-pb-pb'},
        {link: '/passagem-de-onibus/maraba-pa/araripe-ce'},
        {link: '/passagem-de-onibus/maraba-pa/araripina-pe'},
        {link: '/passagem-de-onibus/maraba-pa/caruaru-pe'},
        {link: '/passagem-de-onibus/maraba-pa/barro-ce'},
        {link: '/passagem-de-onibus/maraba-pa/salgueiro-pe'},
        {link: '/passagem-de-onibus/maraba-pa/bomjesusdasselvas-ma'},
        {link: '/passagem-de-onibus/maraba-pa/buriticupu-ma'},
        {link: '/passagem-de-onibus/maraba-pa/codo-ma'},
        {link: '/passagem-de-onibus/maraba-pa/bacabal-ma'},
        {link: '/passagem-de-onibus/maraba-pa/acailandia-ma'},
        {link: '/passagem-de-onibus/ibiara-pb/diamante-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/ibiara-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/imperatriz-ma/picos-pi'},
        {link: '/passagem-de-onibus/imperatriz-ma/pombal-pb'},
        {link: '/passagem-de-onibus/imperatriz-ma/sobral-ce'},
        {link: '/passagem-de-onibus/imperatriz-ma/serratalhada-pe'},
        {link: '/passagem-de-onibus/imperatriz-ma/campinagrande-pb'},
        {link: '/passagem-de-onibus/imperatriz-ma/patos-pb'},
        {link: '/passagem-de-onibus/imperatriz-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/carius-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/carius-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/seabra-ba'},
        {link: '/passagem-de-onibus/jaguaribe-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/cedro-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/crato-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/quixada-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/granja-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/itarema-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/tururu-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/trairi-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/umirim-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/acarau-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/novaolinda-ce-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/oros-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/oros-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/petrolina-pe'},
        {link: '/passagem-de-onibus/jaguaribe-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/penaforte-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/jaguaribe-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/oros-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/ico-ce'},
        {link: '/passagem-de-onibus/jucas-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/jucas-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jucas-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/jucas-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/jucas-ce/carius-ce'},
        {link: '/passagem-de-onibus/jucas-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/josedefreitas-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/josedefreitas-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/amontada-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/camocim-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/cruz-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/russas-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/brasilia-df'},
        {link: '/passagem-de-onibus/jaguaribe-ce/brasilia-df'},
        {link: '/passagem-de-onibus/jaguaribe-ce/santanadocariri-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/jaguaribe-ce/umari-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/ico-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/ico-ce'},
        {link: '/passagem-de-onibus/jaguaribe-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/acarau-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/amontada-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/cruz-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/itarema-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/josedefreitas-pi/batalha-pi'},
        {link: '/passagem-de-onibus/josedefreitas-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/josedefreitas-pi/barras-pi'},
        {link: '/passagem-de-onibus/josedefreitas-pi/cabeceirasdopiaui-pi'},
        {link: '/passagem-de-onibus/josedefreitas-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/josedefreitas-pi/teresina-pi'},
        {link: '/passagem-de-onibus/jucas-ce/quixada-ce'},
        {link: '/passagem-de-onibus/jucas-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/jucas-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/jucas-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/jucas-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/jijocadejericoacoara-ce/acarau-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/araripina-pe'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/goiania-go'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/aurora-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/barro-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/belem-pa'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/cedro-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/bacabal-ma'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/ico-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/ico-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/valencadopiaui-pi'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/peritoro-ma'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/timon-ma'},
        {link: '/passagem-de-onibus/juncodoserido-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/condado-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/diamante-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/madalena-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/caninde-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/joaopessoa-pb'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/anapolis-go'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/brasilia-df'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/santanadocariri-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/campinagrande-pb'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/umari-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/patos-pb'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/floriano-pi'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/crateus-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/caxias-ma'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/sobral-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/russas-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/soledade-pb-pb'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/brasilia-df'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/maceio-al'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/porteiras-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/ipu-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/teresina-pi'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/quixada-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/imperatriz-ma'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/maraba-pa'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/jardim-ce-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/jati-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/oros-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/penaforte-ce'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/picos-pi'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/juazeirodonorte-ce/pombal-pb'},
        {link: '/passagem-de-onibus/jati-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/jati-ce/porteiras-ce'},
        {link: '/passagem-de-onibus/jati-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/jati-ce/russas-ce'},
        {link: '/passagem-de-onibus/jati-ce/ico-ce'},
        {link: '/passagem-de-onibus/jati-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/vitoriadomearim-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/codo-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/bacabal-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/juncodoserido-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/sousa-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/pombal-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/pianco-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/patos-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/patos-pb'},
        {link: '/passagem-de-onibus/juncodoserido-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/jerico-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/jerico-pb/condado-pb'},
        {link: '/passagem-de-onibus/jerico-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/jerico-pb/pombal-pb'},
        {link: '/passagem-de-onibus/jerico-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/jerico-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/jerico-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/recife-pe/serrinha-ba'},
        {link: '/passagem-de-onibus/recife-pe/tucano-ba'},
        {link: '/passagem-de-onibus/recife-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/recife-pe/peritoro-ma'},
        {link: '/passagem-de-onibus/recife-pe/timon-ma'},
        {link: '/passagem-de-onibus/recife-pe/feiradesantana-ba'},
        {link: '/passagem-de-onibus/jardim-ce-ce/barro-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/jati-ce'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/santaines-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/arari-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/teresina-pi'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/pindare-mirim-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/caxias-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/santarita-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/recife-pe/lajes-rn'},
        {link: '/passagem-de-onibus/recife-pe/jeremoabo-ba'},
        {link: '/passagem-de-onibus/recife-pe/cicerodantas-ba'},
        {link: '/passagem-de-onibus/recife-pe/parnamirim-rn'},
        {link: '/passagem-de-onibus/recife-pe/caxias-ma'},
        {link: '/passagem-de-onibus/recife-pe/salvador-ba'},
        {link: '/passagem-de-onibus/recife-pe/soledade-pb-pb'},
        {link: '/passagem-de-onibus/recife-pe/mossoro-rn'},
        {link: '/passagem-de-onibus/recife-pe/patos-pb'},
        {link: '/passagem-de-onibus/recife-pe/assu-'},
        {link: '/passagem-de-onibus/recife-pe/belem-pa'},
        {link: '/passagem-de-onibus/recife-pe/bacabal-ma'},
        {link: '/passagem-de-onibus/recife-pe/demervallobao-pi'},
        {link: '/passagem-de-onibus/recife-pe/fortaleza-ce'},
        {link: '/passagem-de-onibus/recife-pe/santaines-ma'},
        {link: '/passagem-de-onibus/recife-pe/ribeiradopombal-ba'},
        {link: '/passagem-de-onibus/recife-pe/imperatriz-ma'},
        {link: '/passagem-de-onibus/recife-pe/maraba-pa'},
        {link: '/passagem-de-onibus/jardim-ce-ce/porteiras-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/penaforte-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/russas-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/ico-ce'},
        {link: '/passagem-de-onibus/jardim-ce-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/jati-ce/caruaru-pe'},
        {link: '/passagem-de-onibus/jati-ce/barro-ce'},
        {link: '/passagem-de-onibus/jati-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/jerico-pb/patos-pb'},
        {link: '/passagem-de-onibus/recife-pe/aracati-ce'},
        {link: '/passagem-de-onibus/recife-pe/angicos-'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/sousa-pb'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/pombal-pb'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/patos-pb'},
        {link: '/passagem-de-onibus/lajes-rn/goiana-pe'},
        {link: '/passagem-de-onibus/lajes-rn/recife-pe'},
        {link: '/passagem-de-onibus/luiscorreia-pi/goiania-go'},
        {link: '/passagem-de-onibus/luiscorreia-pi/altos-pi'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/pianco-pb'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/saojoaodoriodopeixe-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/timon-ma'},
        {link: '/passagem-de-onibus/mirandadonorte-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/juazeirinho-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/condado-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/coremas-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/patos-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/patos-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/natal-rn/goiania-go'},
        {link: '/passagem-de-onibus/natal-rn/belem-pa'},
        {link: '/passagem-de-onibus/natal-rn/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/natal-rn/castanhal-pa'},
        {link: '/passagem-de-onibus/natal-rn/bacabal-ma'},
        {link: '/passagem-de-onibus/natal-rn/capanema,pa-pa'},
        {link: '/passagem-de-onibus/natal-rn/fortaleza-ce'},
        {link: '/passagem-de-onibus/juazeirinho-pb/diamante-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/jerico-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/sousa-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/pombal-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/juazeirinho-pb/pianco-pb'},
        {link: '/passagem-de-onibus/natal-rn/santaines-ma'},
        {link: '/passagem-de-onibus/natal-rn/teresina-pi'},
        {link: '/passagem-de-onibus/natal-rn/piripiri-pi'},
        {link: '/passagem-de-onibus/natal-rn/planaltina,df-df'},
        {link: '/passagem-de-onibus/natal-rn/caxias-ma'},
        {link: '/passagem-de-onibus/natal-rn/sobral-ce'},
        {link: '/passagem-de-onibus/natal-rn/santamariadopara-pa'},
        {link: '/passagem-de-onibus/natal-rn/brasilia-df'},
        {link: '/passagem-de-onibus/natal-rn/brasilia-df'},
        {link: '/passagem-de-onibus/natal-rn/sobradinho-df-df'},
        {link: '/passagem-de-onibus/natal-rn/peritoro-ma'},
        {link: '/passagem-de-onibus/natal-rn/timon-ma'},
        {link: '/passagem-de-onibus/natal-rn/tiangua-ce'},
        {link: '/passagem-de-onibus/santateresinha-pb-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/ico-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/aurora-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/barro-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/cedro-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/crato-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/cedro-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/crato-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/quixada-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/oros-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/russas-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/ico-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/ico-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/cedro-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/crato-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/russas-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/oros-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/oros-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/luiscorreia-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/luiscorreia-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/brasilia-df'},
        {link: '/passagem-de-onibus/luiscorreia-pi/brasilia-df'},
        {link: '/passagem-de-onibus/luiscorreia-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/luiscorreia-pi/timon-ma'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/lavrasdamangabeira-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/luiscorreia-pi/barras-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/cabeceirasdopiaui-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/josedefreitas-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/teresina-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/luiscorreia-pi/batalha-pi'},
        {link: '/passagem-de-onibus/maceio-al/patos-pb'},
        {link: '/passagem-de-onibus/maceio-al/peritoro-ma'},
        {link: '/passagem-de-onibus/maceio-al/timon-ma'},
        {link: '/passagem-de-onibus/uberaba-mg/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/uberaba-mg/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/uberaba-mg/gilbues-pi'},
        {link: '/passagem-de-onibus/uberaba-mg/teresina-pi'},
        {link: '/passagem-de-onibus/uberaba-mg/floriano-pi'},
        {link: '/passagem-de-onibus/senadorsa-ce/camocim-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/russas-ce'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/floriano-pi'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/ico-ce'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/brejosanto-ce'},
        {link: '/passagem-de-onibus/serrabranca-pb/riodejaneiro-rj'},
        {link: '/passagem-de-onibus/serrabranca-pb/saopaulo-sp'},
        {link: '/passagem-de-onibus/solonopole-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/cedro-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/crato-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/umari-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/ico-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/ico-ce'},
        {link: '/passagem-de-onibus/limoeirodonorte-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/barro-ce'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/salgueiro-pe'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/fortaleza-ce'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/jaguaribe-ce'},
        {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/petrolina-pe'},
        {link: '/passagem-de-onibus/solonopole-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/ico-ce'},
        {link: '/passagem-de-onibus/maceio-al/belem-pa'},
        {link: '/passagem-de-onibus/maceio-al/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/maceio-al/castanhal-pa'},
        {link: '/passagem-de-onibus/maceio-al/bacabal-ma'},
        {link: '/passagem-de-onibus/maceio-al/capanema,pa-pa'},
        {link: '/passagem-de-onibus/maceio-al/fortaleza-ce'},
        {link: '/passagem-de-onibus/maceio-al/santaines-ma'},
        {link: '/passagem-de-onibus/maceio-al/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/maceio-al/teresina-pi'},
        {link: '/passagem-de-onibus/maceio-al/arcoverde-pe'},
        {link: '/passagem-de-onibus/maceio-al/picos-pi'},
        {link: '/passagem-de-onibus/maceio-al/caxias-ma'},
        {link: '/passagem-de-onibus/maceio-al/santamariadopara-pa'},
        {link: '/passagem-de-onibus/maceio-al/mossoro-rn'},
        {link: '/passagem-de-onibus/maceio-al/serratalhada-pe'},
        {link: '/passagem-de-onibus/senadorsa-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/granja-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/massape-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/sobral-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/senadorsa-ce/umirim-ce'},
        {link: '/passagem-de-onibus/senadorsa-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/irece-ba/joaopessoa-pb'},
        {link: '/passagem-de-onibus/irece-ba/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/irece-ba/campinagrande-pb'},
        {link: '/passagem-de-onibus/irece-ba/patos-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/solonopole-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/milha-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/quixada-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/oros-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/solonopole-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/diamante-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/porteiras-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/goiania-go'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/barreiras-ba'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/salgueiro-pe'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/capimgrosso-ba'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/condado-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/saojoaodoriodopeixe-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/irece-ba'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/teresina-pi'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/rodavelha-ba'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/santamariadaboavista-pe'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/brasilia-df'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/brasilia-df'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/sobradinho-df-df'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/ipu-ce/goiania-go'},
        {link: '/passagem-de-onibus/ipu-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/ipu-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/ipu-ce/corrente-pi'},
        {link: '/passagem-de-onibus/ipu-ce/crato-ce'},
        {link: '/passagem-de-onibus/ipu-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/ipu-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/ipu-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/ipu-ce/groairas-ce'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/pianco-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/porteiras-ce/barro-ce'},
        {link: '/passagem-de-onibus/porteiras-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/porteiras-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/porteiras-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/porteiras-ce/russas-ce'},
        {link: '/passagem-de-onibus/porteiras-ce/ico-ce'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/petrolina-pe'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/sousa-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/picos-pi'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/planaltina,df-df'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/pombal-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/caxias-ma'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/coremas-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/diamante-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/imperatriz-ma'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/maraba-pa'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/ibotirama-ba'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/pianco-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/floriano-pi'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/brejosanto-ce'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/peritoro-ma'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/timon-ma'},
        {link: '/passagem-de-onibus/cajazeiras,pb-pb/seabra-ba'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/itaporanga-pb-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/senhordobonfim-ba/salgueiro-pe'},
        {link: '/passagem-de-onibus/senhordobonfim-ba/fortaleza-ce'},
        {link: '/passagem-de-onibus/senhordobonfim-ba/jaguaribe-ce'},
        {link: '/passagem-de-onibus/senhordobonfim-ba/petrolina-pe'},
        {link: '/passagem-de-onibus/senhordobonfim-ba/russas-ce'},
        {link: '/passagem-de-onibus/senhordobonfim-ba/ico-ce'},
        {link: '/passagem-de-onibus/senhordobonfim-ba/brejosanto-ce'},
        {link: '/passagem-de-onibus/montealegredopiaui-pi/goiania-go'},
        {link: '/passagem-de-onibus/riodejaneiro-rj/sume-pb'},
        {link: '/passagem-de-onibus/riodejaneiro-rj/campinagrande-pb'},
        {link: '/passagem-de-onibus/riodejaneiro-rj/sertania-pe'},
        {link: '/passagem-de-onibus/maracacume-ma/belem-pa'},
        {link: '/passagem-de-onibus/maracacume-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/maracacume-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/maracacume-ma/teresina-pi'},
        {link: '/passagem-de-onibus/maracacume-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/maracacume-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/senhordobonfim-ba/barro-ce'},
        {link: '/passagem-de-onibus/ipu-ce/umirim-ce'},
        {link: '/passagem-de-onibus/ipu-ce/floriano-pi'},
        {link: '/passagem-de-onibus/ipu-ce/varjota-ce'},
        {link: '/passagem-de-onibus/ipu-ce/crateus-ce'},
        {link: '/passagem-de-onibus/ipu-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/riodejaneiro-rj/custodia-pe'},
        {link: '/passagem-de-onibus/riodejaneiro-rj/fortaleza-ce'},
        {link: '/passagem-de-onibus/riodejaneiro-rj/serrabranca-pb'},
        {link: '/passagem-de-onibus/riodejaneiro-rj/monteiro-pb'},
        {link: '/passagem-de-onibus/riodejaneiro-rj/sobral-ce'},
        {link: '/passagem-de-onibus/ipu-ce/carire-ce'},
        {link: '/passagem-de-onibus/ipu-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/ipu-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/ipu-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/ipu-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/ipu-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/ipu-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/ipu-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/ipu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/ipu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/ipu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/montehorebe-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/sousa-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/pombal-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/pianco-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/patos-pb'},
        {link: '/passagem-de-onibus/ipu-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/ipu-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/ipu-ce/sobral-ce'},
        {link: '/passagem-de-onibus/ipu-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/ipu-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/ipu-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/ipu-ce/brasilia-df'},
        {link: '/passagem-de-onibus/ipu-ce/brasilia-df'},
        {link: '/passagem-de-onibus/ipu-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/ipu-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/montealegredopiaui-pi/anapolis-go'},
        {link: '/passagem-de-onibus/montealegredopiaui-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/montealegredopiaui-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/montealegredopiaui-pi/brasilia-df'},
        {link: '/passagem-de-onibus/montealegredopiaui-pi/brasilia-df'},
        {link: '/passagem-de-onibus/montealegredopiaui-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/montehorebe-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/milha-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/milha-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/milha-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/milha-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/milha-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/milha-ce/quixada-ce'},
        {link: '/passagem-de-onibus/milha-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/milha-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/milha-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/montehorebe-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/montehorebe-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/santaquiteria-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/saojosedoscampos-sp/joaopessoa-pb'},
        {link: '/passagem-de-onibus/milagres,ce-ce/goiania-go'},
        {link: '/passagem-de-onibus/milagres,ce-ce/aurora-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/barro-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/salgueiro-pe'},
        {link: '/passagem-de-onibus/milagres,ce-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/crato-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/russas-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/umari-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/patos-pb'},
        {link: '/passagem-de-onibus/milagres,ce-ce/ico-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/teresina-pi/aracati-ce'},
        {link: '/passagem-de-onibus/teresina-pi/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/teresina-pi/araripina-pe'},
        {link: '/passagem-de-onibus/teresina-pi/vitoriadesantoantao-pe'},
        {link: '/passagem-de-onibus/teresina-pi/goiania-go'},
        {link: '/passagem-de-onibus/milha-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/milha-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/milha-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/amontada-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/morrinhosce-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/tururu-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/sobral-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/milagres,ce-ce/joaopessoa-pb'},
        {link: '/passagem-de-onibus/milagres,ce-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/anapolis-go'},
        {link: '/passagem-de-onibus/milagres,ce-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/milagres,ce-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/santanadoacarau-ce/umirim-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/aurora-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/barro-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/caninde-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/sobral-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/sobral-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/sobral-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/coreau-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/moraujo-ce/umirim-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/madalena-ce'},
        {link: '/passagem-de-onibus/santaquiteria-ce/crato-ce'},
        {link: '/passagem-de-onibus/teresina-pi/altos-pi'},
        {link: '/passagem-de-onibus/teresina-pi/assu-'},
        {link: '/passagem-de-onibus/teresina-pi/bezerros-pe'},
        {link: '/passagem-de-onibus/teresina-pi/belem-pa'},
        {link: '/passagem-de-onibus/teresina-pi/batalha-pi'},
        {link: '/passagem-de-onibus/teresina-pi/batalha-pi'},
        {link: '/passagem-de-onibus/teresina-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/teresina-pi/belojardim-pe'},
        {link: '/passagem-de-onibus/teresina-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/teresina-pi/barras-pi'},
        {link: '/passagem-de-onibus/mauriti-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/russas-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/umari-ce'},
        {link: '/passagem-de-onibus/mauriti-ce/ico-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/camocim-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/chaval-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/moraujo-ce/granja-ce'},
        {link: '/passagem-de-onibus/teresina-pi/acailandia-ma'},
        {link: '/passagem-de-onibus/teresina-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/teresina-pi/capanema,pa-pa'},
        {link: '/passagem-de-onibus/teresina-pi/campossales,ce-ce'},
        {link: '/passagem-de-onibus/teresina-pi/crato-ce'},
        {link: '/passagem-de-onibus/teresina-pi/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/teresina-pi/fortaleza-ce'},
        {link: '/passagem-de-onibus/teresina-pi/joaopessoa-pb'},
        {link: '/passagem-de-onibus/teresina-pi/gravata-pe'},
        {link: '/passagem-de-onibus/teresina-pi/governadornunesfreire-ma'},
        {link: '/passagem-de-onibus/teresina-pi/buriticupu-ma'},
        {link: '/passagem-de-onibus/teresina-pi/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/teresina-pi/castanhal-pa'},
        {link: '/passagem-de-onibus/teresina-pi/cabeceirasdopiaui-pi'},
        {link: '/passagem-de-onibus/teresina-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/teresina-pi/codo-ma'},
        {link: '/passagem-de-onibus/teresina-pi/codo-ma'},
        {link: '/passagem-de-onibus/teresina-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/teresina-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/teresina-pi/bacabal-ma'},
        {link: '/passagem-de-onibus/teresina-pi/santaines-ma'},
        {link: '/passagem-de-onibus/teresina-pi/anapolis-go'},
        {link: '/passagem-de-onibus/teresina-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/teresina-pi/osasco-sp'},
        {link: '/passagem-de-onibus/teresina-pi/imperatriz-ma'},
        {link: '/passagem-de-onibus/teresina-pi/maraba-pa'},
        {link: '/passagem-de-onibus/teresina-pi/josedefreitas-pi'},
        {link: '/passagem-de-onibus/teresina-pi/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/teresina-pi/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/teresina-pi/natal-rn'},
        {link: '/passagem-de-onibus/teresina-pi/santos-sp'},
        {link: '/passagem-de-onibus/teresina-pi/ananindeua-pa'},
        {link: '/passagem-de-onibus/teresina-pi/saoluis-ma'},
        {link: '/passagem-de-onibus/teresina-pi/zedoca-ma'},
        {link: '/passagem-de-onibus/teresina-pi/tiangua-ce'},
        {link: '/passagem-de-onibus/teresina-pi/altos-pi'},
        {link: '/passagem-de-onibus/teresina-pi/batalha-pi'},
        {link: '/passagem-de-onibus/teresina-pi/batalha-pi'},
        {link: '/passagem-de-onibus/teresina-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/teresina-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/teresina-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/teresina-pi/pombal-pb'},
        {link: '/passagem-de-onibus/teresina-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/teresina-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/teresina-pi/caxias-ma'},
        {link: '/passagem-de-onibus/teresina-pi/sobral-ce'},
        {link: '/passagem-de-onibus/teresina-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/teresina-pi/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/teresina-pi/santamariadopara-pa'},
        {link: '/passagem-de-onibus/teresina-pi/brasilia-df'},
        {link: '/passagem-de-onibus/teresina-pi/barras-pi'},
        {link: '/passagem-de-onibus/teresina-pi/cabeceirasdopiaui-pi'},
        {link: '/passagem-de-onibus/teresina-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/teresina-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/teresina-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/teresina-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/teresina-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/teresina-pi/josedefreitas-pi'},
        {link: '/passagem-de-onibus/teresina-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/teresina-pi/brasilia-df'},
        {link: '/passagem-de-onibus/teresina-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/teresina-pi/cocal-pi'},
        {link: '/passagem-de-onibus/teresina-pi/mossoro-rn'},
        {link: '/passagem-de-onibus/teresina-pi/santarita-ma'},
        {link: '/passagem-de-onibus/teresina-pi/campinagrande-pb'},
        {link: '/passagem-de-onibus/teresina-pi/timbiras-ma'},
        {link: '/passagem-de-onibus/teresina-pi/patos-pb'},
        {link: '/passagem-de-onibus/teresina-pi/peritoro-ma'},
        {link: '/passagem-de-onibus/teresina-pi/maracacume-ma'},
        {link: '/passagem-de-onibus/teresina-pi/campinas-sp'},
        {link: '/passagem-de-onibus/teresina-pi/novaolindadomaranhao-ma'},
        {link: '/passagem-de-onibus/teresina-pi/sousa-pb'},
        {link: '/passagem-de-onibus/teresina-pi/ribeiraopreto-sp'},
        {link: '/passagem-de-onibus/teresina-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/teresina-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/teresina-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/teresina-pi/maceio-al'},
        {link: '/passagem-de-onibus/teresina-pi/uberaba-mg'},
        {link: '/passagem-de-onibus/teresina-pi/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/martinopole-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/martinopole-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/martinopole-ce/umirim-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/massape-ce/camocim-ce'},
        {link: '/passagem-de-onibus/massape-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/massape-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/massape-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/massape-ce/granja-ce'},
        {link: '/passagem-de-onibus/massape-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/massape-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/crateus-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/monteiro-pb/riodejaneiro-rj'},
        {link: '/passagem-de-onibus/monteiro-pb/saopaulo-sp'},
        {link: '/passagem-de-onibus/martinopole-ce/camocim-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/granja-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/massape-ce'},
        {link: '/passagem-de-onibus/martinopole-ce/sobral-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/mucambo-ce/umirim-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/goiania-go'},
        {link: '/passagem-de-onibus/barbalha-ce/aurora-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/barro-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/joaopessoa-pb'},
        {link: '/passagem-de-onibus/barbalha-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/anapolis-go'},
        {link: '/passagem-de-onibus/massape-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/massape-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/massape-ce/sobral-ce'},
        {link: '/passagem-de-onibus/massape-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/massape-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/massape-ce/umirim-ce'},
        {link: '/passagem-de-onibus/massape-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/teresina-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/teresina-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/teresina-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/teresina-pi/cocal-pi'},
        {link: '/passagem-de-onibus/mombaca-ce/madalena-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/crato-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/caninde-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/carius-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/jucas-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/ipu-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/quixada-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/mombaca-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/graca-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/carire-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/sobral-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/sobral-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/sobral-ce'},
        {link: '/passagem-de-onibus/mucambo-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/vitoriadaconquista-ba/joaopessoa-pb'},
        {link: '/passagem-de-onibus/campinas-sp/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/campinas-sp/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/campinas-sp/gilbues-pi'},
        {link: '/passagem-de-onibus/campinas-sp/teresina-pi'},
        {link: '/passagem-de-onibus/campinas-sp/floriano-pi'},
        {link: '/passagem-de-onibus/novaolindadomaranhao-ma/belem-pa'},
        {link: '/passagem-de-onibus/novaolindadomaranhao-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/novaolindadomaranhao-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/novaolindadomaranhao-ma/teresina-pi'},
        {link: '/passagem-de-onibus/marizopolis-pb/pombal-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/patos-pb'},
        {link: '/passagem-de-onibus/novarussas-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/novarussas-ce/brasilia-df'},
        {link: '/passagem-de-onibus/novarussas-ce/brasilia-df'},
        {link: '/passagem-de-onibus/novarussas-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/vitoriadaconquista-ba/fortaleza-ce'},
        {link: '/passagem-de-onibus/quixada-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/quixada-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/quixada-ce/ico-ce'},
        {link: '/passagem-de-onibus/marizopolis-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/condado-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/sousa-pb'},
        {link: '/passagem-de-onibus/marizopolis-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/quixada-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/quixada-ce/milha-ce'},
        {link: '/passagem-de-onibus/quixada-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/quixada-ce/ocara-ce'},
        {link: '/passagem-de-onibus/quixada-ce/oros-ce'},
        {link: '/passagem-de-onibus/quixada-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/quixada-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/quixada-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/quixada-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/quixada-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/quixada-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/quixada-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/quixada-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/quixada-ce/quixelo-ce'},
        {link: '/passagem-de-onibus/quixada-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/quixada-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/quixada-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/quixada-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/quixada-ce/brasilia-df'},
        {link: '/passagem-de-onibus/quixada-ce/brasilia-df'},
        {link: '/passagem-de-onibus/barbalha-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/jardim-ce-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/jati-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/porteiras-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/penaforte-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/quixada-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/quixada-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/quixada-ce/carius-ce'},
        {link: '/passagem-de-onibus/quixada-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/quixada-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/quixada-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/quixada-ce/jucas-ce'},
        {link: '/passagem-de-onibus/quixada-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/quixada-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/quixada-ce/cedro-ce'},
        {link: '/passagem-de-onibus/quixada-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/quixada-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/quixada-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/quixada-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/quixada-ce/crato-ce'},
        {link: '/passagem-de-onibus/quixada-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/quixada-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/russas-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/umari-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/ico-ce'},
        {link: '/passagem-de-onibus/barbalha-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/quixada-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/novaolindadomaranhao-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/novaolindadomaranhao-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/cicerodantas-ba/caruaru-pe'},
        {link: '/passagem-de-onibus/cicerodantas-ba/belojardim-pe'},
        {link: '/passagem-de-onibus/cicerodantas-ba/recife-pe'},
        {link: '/passagem-de-onibus/cicerodantas-ba/arcoverde-pe'},
        {link: '/passagem-de-onibus/cicerodantas-ba/petrolandia-pe'},
        {link: '/passagem-de-onibus/oros-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/oros-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/oros-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/oros-ce/quixada-ce'},
        {link: '/passagem-de-onibus/oros-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/oros-ce/russas-ce'},
        {link: '/passagem-de-onibus/oros-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/oros-ce/ico-ce'},
        {link: '/passagem-de-onibus/oros-ce/ico-ce'},
        {link: '/passagem-de-onibus/oros-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/oros-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/oros-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/oros-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/oros-ce/oros-ce'},
        {link: '/passagem-de-onibus/oros-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/oros-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/oros-ce/ico-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/madalena-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/crato-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/caninde-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/carius-ce'},
        {link: '/passagem-de-onibus/oros-ce/cedro-ce'},
        {link: '/passagem-de-onibus/oros-ce/crato-ce'},
        {link: '/passagem-de-onibus/oros-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/oros-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/oros-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/oros-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/oros-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/oros-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/oros-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/oros-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/jucas-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/ipu-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/milha-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/quixada-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/sobral-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/russas-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/brasilia-df'},
        {link: '/passagem-de-onibus/iguatu-ce/brasilia-df'},
        {link: '/passagem-de-onibus/iguatu-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/iguatu-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/oros-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/oros-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/iguatu-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/crateus-ce'},
        {link: '/passagem-de-onibus/morrinhosce-ce/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/morrinhosce-ce/tururu-ce'},
        {link: '/passagem-de-onibus/morrinhosce-ce/sobral-ce'},
        {link: '/passagem-de-onibus/morrinhosce-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/morrinhosce-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/morrinhosce-ce/umirim-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/crato-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/taua-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/taua-ce/brasilia-df'},
        {link: '/passagem-de-onibus/taua-ce/brasilia-df'},
        {link: '/passagem-de-onibus/taua-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/sousa-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/sousa-pb/goiania-go'},
        {link: '/passagem-de-onibus/sousa-pb/salgueiro-pe'},
        {link: '/passagem-de-onibus/sousa-pb/capimgrosso-ba'},
        {link: '/passagem-de-onibus/sousa-pb/condado-pb'},
        {link: '/passagem-de-onibus/sousa-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/petrolina-pe/brasilia-df'},
        {link: '/passagem-de-onibus/petrolina-pe/brasilia-df'},
        {link: '/passagem-de-onibus/petrolina-pe/sobradinho-df-df'},
        {link: '/passagem-de-onibus/petrolina-pe/ibotirama-ba'},
        {link: '/passagem-de-onibus/petrolina-pe/patos-pb'},
        {link: '/passagem-de-onibus/petrolina-pe/brejosanto-ce'},
        {link: '/passagem-de-onibus/petrolina-pe/seabra-ba'},
        {link: '/passagem-de-onibus/morrinhosce-ce/amontada-ce'},
        {link: '/passagem-de-onibus/morrinhosce-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/morrinhosce-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/crateus-ce'},
        {link: '/passagem-de-onibus/ipueiras-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/taua-ce/goiania-go'},
        {link: '/passagem-de-onibus/taua-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/taua-ce/corrente-pi'},
        {link: '/passagem-de-onibus/sousa-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/sousa-pb/anapolis-go'},
        {link: '/passagem-de-onibus/sousa-pb/imperatriz-ma'},
        {link: '/passagem-de-onibus/sousa-pb/maraba-pa'},
        {link: '/passagem-de-onibus/sousa-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/sousa-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/sousa-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/sousa-pb/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/sousa-pb/teresina-pi'},
        {link: '/passagem-de-onibus/sousa-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/sousa-pb/petrolina-pe'},
        {link: '/passagem-de-onibus/sousa-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/sousa-pb/picos-pi'},
        {link: '/passagem-de-onibus/sousa-pb/planaltina,df-df'},
        {link: '/passagem-de-onibus/sousa-pb/pombal-pb'},
        {link: '/passagem-de-onibus/sousa-pb/caxias-ma'},
        {link: '/passagem-de-onibus/sousa-pb/rodavelha-ba'},
        {link: '/passagem-de-onibus/sousa-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/sousa-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/sousa-pb/santamariadaboavista-pe'},
        {link: '/passagem-de-onibus/iguatu-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/ico-ce'},
        {link: '/passagem-de-onibus/iguatu-ce/ico-ce'},
        {link: '/passagem-de-onibus/petrolina-pe/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/petrolina-pe/goiania-go'},
        {link: '/passagem-de-onibus/petrolina-pe/barro-ce'},
        {link: '/passagem-de-onibus/petrolina-pe/missaovelha-ce'},
        {link: '/passagem-de-onibus/petrolina-pe/fortaleza-ce'},
        {link: '/passagem-de-onibus/petrolina-pe/joaopessoa-pb'},
        {link: '/passagem-de-onibus/petrolina-pe/anapolis-go'},
        {link: '/passagem-de-onibus/petrolina-pe/jaguaribe-ce'},
        {link: '/passagem-de-onibus/petrolina-pe/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/petrolina-pe/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/petrolina-pe/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/petrolina-pe/sousa-pb'},
        {link: '/passagem-de-onibus/petrolina-pe/planaltina,df-df'},
        {link: '/passagem-de-onibus/petrolina-pe/pombal-pb'},
        {link: '/passagem-de-onibus/petrolina-pe/rodavelha-ba'},
        {link: '/passagem-de-onibus/iguatu-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/ouricuri-pe/picos-pi'},
        {link: '/passagem-de-onibus/ouricuri-pe/caxias-ma'},
        {link: '/passagem-de-onibus/ouricuri-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/ouricuri-pe/timon-ma'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/condado-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/coremas-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/saojosedalagoatapada-pb/patos-pb'},
        {link: '/passagem-de-onibus/arcoverde-pe/belem-pa'},
        {link: '/passagem-de-onibus/arcoverde-pe/bacabal-ma'},
        {link: '/passagem-de-onibus/arcoverde-pe/fortaleza-ce'},
        {link: '/passagem-de-onibus/arcoverde-pe/santaines-ma'},
        {link: '/passagem-de-onibus/arcoverde-pe/imperatriz-ma'},
        {link: '/passagem-de-onibus/arcoverde-pe/maraba-pa'},
        {link: '/passagem-de-onibus/arcoverde-pe/timon-ma'},
        {link: '/passagem-de-onibus/arcoverde-pe/feiradesantana-ba'},
        {link: '/passagem-de-onibus/pacajus-ce/aurora-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/barro-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/cedro-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/sousa-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/sousa-pb/brasilia-df'},
        {link: '/passagem-de-onibus/sousa-pb/brasilia-df'},
        {link: '/passagem-de-onibus/sousa-pb/sobradinho-df-df'},
        {link: '/passagem-de-onibus/sousa-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/sousa-pb/ibotirama-ba'},
        {link: '/passagem-de-onibus/sousa-pb/patos-pb'},
        {link: '/passagem-de-onibus/sousa-pb/patos-pb'},
        {link: '/passagem-de-onibus/sousa-pb/brejosanto-ce'},
        {link: '/passagem-de-onibus/sousa-pb/peritoro-ma'},
        {link: '/passagem-de-onibus/sousa-pb/timon-ma'},
        {link: '/passagem-de-onibus/ribeiraopreto-sp/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/ribeiraopreto-sp/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/ribeiraopreto-sp/gilbues-pi'},
        {link: '/passagem-de-onibus/ribeiraopreto-sp/teresina-pi'},
        {link: '/passagem-de-onibus/ribeiraopreto-sp/floriano-pi'},
        {link: '/passagem-de-onibus/ouricuri-pe/goiania-go'},
        {link: '/passagem-de-onibus/ouricuri-pe/bacabal-ma'},
        {link: '/passagem-de-onibus/ouricuri-pe/anapolis-go'},
        {link: '/passagem-de-onibus/ouricuri-pe/imperatriz-ma'},
        {link: '/passagem-de-onibus/ouricuri-pe/maraba-pa'},
        {link: '/passagem-de-onibus/arcoverde-pe/jeremoabo-ba'},
        {link: '/passagem-de-onibus/arcoverde-pe/maceio-al'},
        {link: '/passagem-de-onibus/arcoverde-pe/cicerodantas-ba'},
        {link: '/passagem-de-onibus/arcoverde-pe/picos-pi'},
        {link: '/passagem-de-onibus/arcoverde-pe/caxias-ma'},
        {link: '/passagem-de-onibus/arcoverde-pe/salvador-ba'},
        {link: '/passagem-de-onibus/arcoverde-pe/mossoro-rn'},
        {link: '/passagem-de-onibus/arcoverde-pe/serrinha-ba'},
        {link: '/passagem-de-onibus/arcoverde-pe/tucano-ba'},
        {link: '/passagem-de-onibus/arcoverde-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/pacajus-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/crato-ce'},
        {link: '/passagem-de-onibus/sousa-pb/seabra-ba'},
        {link: '/passagem-de-onibus/sousa-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/pedrabranca-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/crato-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/carius-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/quixada-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/malta-pb-pb/pombal-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/pastosbons-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/pacajus-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/umari-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/ico-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/ico-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/malta-pb-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/cajazeirinhas-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/condado-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/coremas-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/sousa-pb'},
        {link: '/passagem-de-onibus/malta-pb-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/pedrabranca-ce/crato-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/ipu-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/crateus-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/crato-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/milha-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/quixada-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/oros-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/quixada-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/ocara-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/quixelo-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/russas-ce'},
        {link: '/passagem-de-onibus/pacajus-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/pedrabranca-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/presidentedutra-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/santoantoniodoslopes-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/colinas-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/capinzaldonorte-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/dompedro-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/pastosbons-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/paraibano-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/saodomingosdomaranhao-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/saojoaodospatos-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/santarita-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/presidentedutra-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/penaforte-ce/barro-ce'},
        {link: '/passagem-de-onibus/penaforte-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/penaforte-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/penaforte-ce/jati-ce'},
        {link: '/passagem-de-onibus/penaforte-ce/porteiras-ce'},
        {link: '/passagem-de-onibus/penaforte-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/penaforte-ce/russas-ce'},
        {link: '/passagem-de-onibus/penaforte-ce/ico-ce'},
        {link: '/passagem-de-onibus/penaforte-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/pesqueira-pe/jeremoabo-ba'},
        {link: '/passagem-de-onibus/pesqueira-pe/picos-pi'},
        {link: '/passagem-de-onibus/pesqueira-pe/salvador-ba'},
        {link: '/passagem-de-onibus/pesqueira-pe/feiradesantana-ba'},
        {link: '/passagem-de-onibus/tururu-ce/amontada-ce'},
        {link: '/passagem-de-onibus/tururu-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/tururu-ce/camocim-ce'},
        {link: '/passagem-de-onibus/tururu-ce/cruz-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/carire-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/ipu-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/sobral-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/umirim-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/varjota-ce'},
        {link: '/passagem-de-onibus/piripiri-pi/aracati-ce'},
        {link: '/passagem-de-onibus/piripiri-pi/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/piripiri-pi/goiania-go'},
        {link: '/passagem-de-onibus/piripiri-pi/altos-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/assu-'},
        {link: '/passagem-de-onibus/piripiri-pi/belem-pa'},
        {link: '/passagem-de-onibus/piripiri-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/buriticupu-ma'},
        {link: '/passagem-de-onibus/piripiri-pi/castanhal-pa'},
        {link: '/passagem-de-onibus/piripiri-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/tururu-ce/umirim-ce'},
        {link: '/passagem-de-onibus/tururu-ce/acarau-ce'},
        {link: '/passagem-de-onibus/tururu-ce/acarau-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/piresferreira-ce/groairas-ce'},
        {link: '/passagem-de-onibus/tururu-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/tururu-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/tururu-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/tururu-ce/granja-ce'},
        {link: '/passagem-de-onibus/tururu-ce/itarema-ce'},
        {link: '/passagem-de-onibus/tururu-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/tururu-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/tururu-ce/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/tururu-ce/morrinhosce-ce'},
        {link: '/passagem-de-onibus/tururu-ce/sobral-ce'},
        {link: '/passagem-de-onibus/tururu-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/tururu-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/cristinocastro-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/coloniadogurgueia-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/capimgrosso-ba'},
        {link: '/passagem-de-onibus/planaltina,df-df/campomaior-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/saobenedito-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/catoledorocha-pb'},
        {link: '/passagem-de-onibus/piripiri-pi/saoluis-ma'},
        {link: '/passagem-de-onibus/piripiri-pi/tiangua-ce'},
        {link: '/passagem-de-onibus/picos-pi/araripina-pe'},
        {link: '/passagem-de-onibus/picos-pi/caruaru-pe'},
        {link: '/passagem-de-onibus/picos-pi/vitoriadesantoantao-pe'},
        {link: '/passagem-de-onibus/picos-pi/goiania-go'},
        {link: '/passagem-de-onibus/picos-pi/barreiras-ba'},
        {link: '/passagem-de-onibus/picos-pi/belojardim-pe'},
        {link: '/passagem-de-onibus/picos-pi/castanhal-pa'},
        {link: '/passagem-de-onibus/picos-pi/capanema,pa-pa'},
        {link: '/passagem-de-onibus/piripiri-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/capanema,pa-pa'},
        {link: '/passagem-de-onibus/piripiri-pi/fortaleza-ce'},
        {link: '/passagem-de-onibus/piripiri-pi/santaluzia,ma-ma'},
        {link: '/passagem-de-onibus/piripiri-pi/anapolis-go'},
        {link: '/passagem-de-onibus/piripiri-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/osasco-sp'},
        {link: '/passagem-de-onibus/piripiri-pi/imperatriz-ma'},
        {link: '/passagem-de-onibus/piripiri-pi/natal-rn'},
        {link: '/passagem-de-onibus/planaltina,df-df/eliseumartins-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/fortaleza-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/joaopessoa-pb'},
        {link: '/passagem-de-onibus/planaltina,df-df/guarabira-pb'},
        {link: '/passagem-de-onibus/planaltina,df-df/aguabranca-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/gilbues-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/itaueira-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/jaguaribe-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/natal-rn'},
        {link: '/passagem-de-onibus/piripiri-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/piripiri-pi/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/piripiri-pi/santamariadopara-pa'},
        {link: '/passagem-de-onibus/piripiri-pi/brasilia-df'},
        {link: '/passagem-de-onibus/piripiri-pi/brasilia-df'},
        {link: '/passagem-de-onibus/piripiri-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/piripiri-pi/cocal-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/mossoro-rn'},
        {link: '/passagem-de-onibus/piripiri-pi/peritoro-ma'},
        {link: '/passagem-de-onibus/piripiri-pi/timon-ma'},
        {link: '/passagem-de-onibus/picos-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/picos-pi/campinagrande-pb'},
        {link: '/passagem-de-onibus/picos-pi/patos-pb'},
        {link: '/passagem-de-onibus/picos-pi/peritoro-ma'},
        {link: '/passagem-de-onibus/picos-pi/timon-ma'},
        {link: '/passagem-de-onibus/picos-pi/zedoca-ma'},
        {link: '/passagem-de-onibus/planaltina,df-df/altos-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/amarante-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/barro-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/salgueiro-pe'},
        {link: '/passagem-de-onibus/picos-pi/campossales,ce-ce'},
        {link: '/passagem-de-onibus/picos-pi/custodia-pe'},
        {link: '/passagem-de-onibus/picos-pi/crato-ce'},
        {link: '/passagem-de-onibus/picos-pi/joaopessoa-pb'},
        {link: '/passagem-de-onibus/picos-pi/santaines-ma'},
        {link: '/passagem-de-onibus/picos-pi/imperatriz-ma'},
        {link: '/passagem-de-onibus/picos-pi/maraba-pa'},
        {link: '/passagem-de-onibus/picos-pi/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/picos-pi/maceio-al'},
        {link: '/passagem-de-onibus/picos-pi/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/piripiri-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/teresina-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/campinas-sp'},
        {link: '/passagem-de-onibus/piripiri-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/piripiri-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/piripiri-pi/caxias-ma'},
        {link: '/passagem-de-onibus/piripiri-pi/sobral-ce'},
        {link: '/passagem-de-onibus/picos-pi/sousa-pb'},
        {link: '/passagem-de-onibus/picos-pi/ouricuri-pe'},
        {link: '/passagem-de-onibus/picos-pi/arcoverde-pe'},
        {link: '/passagem-de-onibus/picos-pi/pesqueira-pe'},
        {link: '/passagem-de-onibus/picos-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/picos-pi/pombal-pb'},
        {link: '/passagem-de-onibus/picos-pi/caxias-ma'},
        {link: '/passagem-de-onibus/picos-pi/santamariadopara-pa'},
        {link: '/passagem-de-onibus/picos-pi/brasilia-df'},
        {link: '/passagem-de-onibus/picos-pi/brasilia-df'},
        {link: '/passagem-de-onibus/pombal-pb/ibotirama-ba'},
        {link: '/passagem-de-onibus/pombal-pb/patos-pb'},
        {link: '/passagem-de-onibus/pombal-pb/patos-pb'},
        {link: '/passagem-de-onibus/pombal-pb/brejosanto-ce'},
        {link: '/passagem-de-onibus/pombal-pb/seabra-ba'},
        {link: '/passagem-de-onibus/pombal-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/vitoriadomearim-ma'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/santaines-ma'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/arari-ma'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/pombal-pb/rodavelha-ba'},
        {link: '/passagem-de-onibus/pombal-pb/brejodocruz-pb'},
        {link: '/passagem-de-onibus/pombal-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/pombal-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/pombal-pb/santamariadaboavista-pe'},
        {link: '/passagem-de-onibus/pombal-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/pombal-pb/brasilia-df'},
        {link: '/passagem-de-onibus/pombal-pb/brasilia-df'},
        {link: '/passagem-de-onibus/pombal-pb/sobradinho-df-df'},
        {link: '/passagem-de-onibus/pombal-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/pombal-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/pombal-pb/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/pombal-pb/teresina-pi'},
        {link: '/passagem-de-onibus/pombal-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/pombal-pb/petrolina-pe'},
        {link: '/passagem-de-onibus/pombal-pb/sousa-pb'},
        {link: '/passagem-de-onibus/pombal-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/pombal-pb/picos-pi'},
        {link: '/passagem-de-onibus/pombal-pb/planaltina,df-df'},
        {link: '/passagem-de-onibus/pombal-pb/paulista-pb-pb'},
        {link: '/passagem-de-onibus/pombal-pb/missaovelha-ce'},
        {link: '/passagem-de-onibus/pombal-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/pombal-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/pombal-pb/anapolis-go'},
        {link: '/passagem-de-onibus/pombal-pb/imperatriz-ma'},
        {link: '/passagem-de-onibus/pombal-pb/maraba-pa'},
        {link: '/passagem-de-onibus/pombal-pb/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/pombal-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/pombal-pb/jerico-pb'},
        {link: '/passagem-de-onibus/pombal-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/planaltina,df-df/sousa-pb'},
        {link: '/passagem-de-onibus/planaltina,df-df/piripiri-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/picos-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/pombal-pb'},
        {link: '/passagem-de-onibus/planaltina,df-df/parnaiba-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/piracuruca-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/sobral-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/redencaodogurgueia-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/caraubas-rn-rn'},
        {link: '/passagem-de-onibus/planaltina,df-df/luiscorreia-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/planaltina,df-df/ipu-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/montealegredopiaui-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/teresina-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/quixada-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/novarussas-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/iguatu-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/petrolina-pe'},
        {link: '/passagem-de-onibus/planaltina,df-df/taua-ce'},
        {link: '/passagem-de-onibus/pombal-pb/goiania-go'},
        {link: '/passagem-de-onibus/pombal-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/pombal-pb/barreiras-ba'},
        {link: '/passagem-de-onibus/pombal-pb/barro-ce'},
        {link: '/passagem-de-onibus/pombal-pb/salgueiro-pe'},
        {link: '/passagem-de-onibus/pombal-pb/capimgrosso-ba'},
        {link: '/passagem-de-onibus/pombal-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/pombal-pb/condado-pb'},
        {link: '/passagem-de-onibus/pombal-pb/crato-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/caico-rn'},
        {link: '/passagem-de-onibus/planaltina,df-df/mossoro-rn'},
        {link: '/passagem-de-onibus/planaltina,df-df/oeiras-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/campinagrande-pb'},
        {link: '/passagem-de-onibus/planaltina,df-df/patos-pb'},
        {link: '/passagem-de-onibus/planaltina,df-df/floriano-pi'},
        {link: '/passagem-de-onibus/planaltina,df-df/crateus-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/curraisnovos-rn'},
        {link: '/passagem-de-onibus/planaltina,df-df/ico-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/brejosanto-ce'},
        {link: '/passagem-de-onibus/planaltina,df-df/tiangua-ce'},
        {link: '/passagem-de-onibus/pombal-pb/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/pombal-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/paraibano-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/colinas-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/capinzaldonorte-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/dompedro-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/presidentedutra-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/saodomingosdomaranhao-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/saojoaodospatos-ma'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/quixada-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/paraibano-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/santoantoniodoslopes-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/brasilia-df'},
        {link: '/passagem-de-onibus/parnaiba-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/parnaiba-pi/cocal-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/santarita-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/vargemgrande-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/timon-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/saoluis-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/tiangua-ce'},
        {link: '/passagem-de-onibus/piquetcarneiro-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/piracuruca-pi/barras-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/cabeceirasdopiaui-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/fortaleza-ce'},
        {link: '/passagem-de-onibus/piracuruca-pi/anapolis-go'},
        {link: '/passagem-de-onibus/piracuruca-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/josedefreitas-pi'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/santarita-ma'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/pindare-mirim-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/goiania-go'},
        {link: '/passagem-de-onibus/parnaiba-pi/altos-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/anapurus-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/batalha-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/batalha-pi'},
        {link: '/passagem-de-onibus/paraibano-ma/santarita-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/paraibano-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/piracuruca-pi/goiania-go'},
        {link: '/passagem-de-onibus/piracuruca-pi/altos-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/batalha-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/batalha-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/brejo-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/barras-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/cabeceirasdopiaui-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/camocim-ce'},
        {link: '/passagem-de-onibus/parnaiba-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/chapadinha-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/chaval-ce'},
        {link: '/passagem-de-onibus/parnaiba-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/teresina-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/parnaiba-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/sobral-ce'},
        {link: '/passagem-de-onibus/parnaiba-pi/coreau-ce'},
        {link: '/passagem-de-onibus/parnaiba-pi/coreau-ce'},
        {link: '/passagem-de-onibus/parnaiba-pi/saobernardo-'},
        {link: '/passagem-de-onibus/parnaiba-pi/brasilia-df'},
        {link: '/passagem-de-onibus/parnaiba-pi/cajueirodapraia-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/fortaleza-ce'},
        {link: '/passagem-de-onibus/parnaiba-pi/granja-ce'},
        {link: '/passagem-de-onibus/parnaiba-pi/anapolis-go'},
        {link: '/passagem-de-onibus/parnaiba-pi/ilhagrande-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/itapecuru-ma'},
        {link: '/passagem-de-onibus/parnaiba-pi/josedefreitas-pi'},
        {link: '/passagem-de-onibus/parnaiba-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/petrolandia-pe/ribeiradopombal-ba'},
        {link: '/passagem-de-onibus/petrolandia-pe/jeremoabo-ba'},
        {link: '/passagem-de-onibus/petrolandia-pe/cicerodantas-ba'},
        {link: '/passagem-de-onibus/petrolandia-pe/salvador-ba'},
        {link: '/passagem-de-onibus/petrolandia-pe/serrinha-ba'},
        {link: '/passagem-de-onibus/petrolandia-pe/tucano-ba'},
        {link: '/passagem-de-onibus/petrolandia-pe/feiradesantana-ba'},
        {link: '/passagem-de-onibus/pacuja-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/graca-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/milha-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/quixada-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/paulista-pb-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/paulista-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/paulista-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/caxias-ma/araripina-pe'},
        {link: '/passagem-de-onibus/caxias-ma/caruaru-pe'},
        {link: '/passagem-de-onibus/caxias-ma/belem-pa'},
        {link: '/passagem-de-onibus/caxias-ma/salgueiro-pe'},
        {link: '/passagem-de-onibus/caxias-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/caxias-ma/codo-ma'},
        {link: '/passagem-de-onibus/caxias-ma/campomaior-pi'},
        {link: '/passagem-de-onibus/pacuja-ce/sobral-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/sobral-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/sobral-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/pacuja-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/pacuja-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/paulinoneves-ma/barreirinhas-ma'},
        {link: '/passagem-de-onibus/paulinoneves-ma/tutoia-ma'},
        {link: '/passagem-de-onibus/paulinoneves-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/paulinoneves-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/caxias-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/caxias-ma/custodia-pe'},
        {link: '/passagem-de-onibus/caxias-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/caxias-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/caxias-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/caxias-ma/maraba-pa'},
        {link: '/passagem-de-onibus/caxias-ma/recife-pe'},
        {link: '/passagem-de-onibus/caxias-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/caxias-ma/teresina-pi'},
        {link: '/passagem-de-onibus/caxias-ma/ouricuri-pe'},
        {link: '/passagem-de-onibus/piracuruca-pi/tiangua-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/crato-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/senadorpompeu-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/piracuruca-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/piracuruca-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/brasilia-df'},
        {link: '/passagem-de-onibus/piracuruca-pi/brasilia-df'},
        {link: '/passagem-de-onibus/piracuruca-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/piracuruca-pi/cocal-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/timon-ma'},
        {link: '/passagem-de-onibus/piracuruca-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/luiscorreia-pi'},
        {link: '/passagem-de-onibus/piracuruca-pi/teresina-pi'},
        {link: '/passagem-de-onibus/sobral-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/sobral-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/sobral-ce/sobral-ce'},
        {link: '/passagem-de-onibus/sobral-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/sobral-ce/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/sobral-ce/goiania-go'},
        {link: '/passagem-de-onibus/sobral-ce/amontada-ce'},
        {link: '/passagem-de-onibus/sobral-ce/anapurus-ma'},
        {link: '/passagem-de-onibus/sobral-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/sobral-ce/belem-pa'},
        {link: '/passagem-de-onibus/sobral-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/caxias-ma/timon-ma'},
        {link: '/passagem-de-onibus/caxias-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/caxias-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/aurora-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/barro-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/crato-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/caxias-ma/arcoverde-pe'},
        {link: '/passagem-de-onibus/caxias-ma/piripiri-pi'},
        {link: '/passagem-de-onibus/caxias-ma/picos-pi'},
        {link: '/passagem-de-onibus/caxias-ma/sobral-ce'},
        {link: '/passagem-de-onibus/caxias-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/caxias-ma/santarita-ma'},
        {link: '/passagem-de-onibus/caxias-ma/serratalhada-pe'},
        {link: '/passagem-de-onibus/caxias-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/caxias-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/caxias-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/ipaumirim-ce/ico-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/sobral-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/sobral-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/sobral-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/sobral-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/sobral-ce/graca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/carire-ce'},
        {link: '/passagem-de-onibus/sobral-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/sobral-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/russas-ce'},
        {link: '/passagem-de-onibus/ipaumirim-ce/umari-ce'},
        {link: '/passagem-de-onibus/sobral-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/sobral-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/sobral-ce/capanema,pa-pa'},
        {link: '/passagem-de-onibus/sobral-ce/madalena-ce'},
        {link: '/passagem-de-onibus/sobral-ce/corrente-pi'},
        {link: '/passagem-de-onibus/sobral-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/sobral-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/sobral-ce/crato-ce'},
        {link: '/passagem-de-onibus/sobral-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/sobral-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/sobral-ce/brejo-ma'},
        {link: '/passagem-de-onibus/sobral-ce/buriticupu-ma'},
        {link: '/passagem-de-onibus/sobral-ce/castanhal-pa'},
        {link: '/passagem-de-onibus/sobral-ce/canabrava-ma'},
        {link: '/passagem-de-onibus/sobral-ce/camocim-ce'},
        {link: '/passagem-de-onibus/sobral-ce/chapadinha-ma'},
        {link: '/passagem-de-onibus/sobral-ce/chaval-ce'},
        {link: '/passagem-de-onibus/sobral-ce/bacabal-ma'},
        {link: '/passagem-de-onibus/sobral-ce/campomaior-pi'},
        {link: '/passagem-de-onibus/sobral-ce/caninde-ce'},
        {link: '/passagem-de-onibus/sobral-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/sobral-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/sobral-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/quixelo-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/quixelo-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/quixelo-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/quixelo-ce/quixada-ce'},
        {link: '/passagem-de-onibus/quixelo-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/deputadoirapuanpinheiro-ce'},
        {link: '/passagem-de-onibus/sobral-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/sobral-ce/natal-rn'},
        {link: '/passagem-de-onibus/sobral-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/sobral-ce/ipu-ce'},
        {link: '/passagem-de-onibus/sobral-ce/riodejaneiro-rj'},
        {link: '/passagem-de-onibus/sobral-ce/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/sobral-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/sobral-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/sobral-ce/teresina-pi'},
        {link: '/passagem-de-onibus/sobral-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/sobral-ce/vargemgrande-ma'},
        {link: '/passagem-de-onibus/sobral-ce/crateus-ce'},
        {link: '/passagem-de-onibus/sobral-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/sobral-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/sobral-ce/peritoro-ma'},
        {link: '/passagem-de-onibus/sobral-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/sobral-ce/santos-sp'},
        {link: '/passagem-de-onibus/sobral-ce/timon-ma'},
        {link: '/passagem-de-onibus/sobral-ce/saoluis-ma'},
        {link: '/passagem-de-onibus/sobral-ce/coreau-ce'},
        {link: '/passagem-de-onibus/sobral-ce/coreau-ce'},
        {link: '/passagem-de-onibus/sobral-ce/saopaulo-sp'},
        {link: '/passagem-de-onibus/sobral-ce/saobernardo-'},
        {link: '/passagem-de-onibus/sobral-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/sobral-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/sobral-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/sobral-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/sobral-ce/santamariadopara-pa'},
        {link: '/passagem-de-onibus/sobral-ce/brasilia-df'},
        {link: '/passagem-de-onibus/sobral-ce/brasilia-df'},
        {link: '/passagem-de-onibus/sobral-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/sobral-ce/mossoro-rn'},
        {link: '/passagem-de-onibus/sobral-ce/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/sobral-ce/oeiras-pi'},
        {link: '/passagem-de-onibus/sobral-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/sobral-ce/umirim-ce'},
        {link: '/passagem-de-onibus/sobral-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/varjota-ce'},
        {link: '/passagem-de-onibus/sobral-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/sobral-ce/granja-ce'},
        {link: '/passagem-de-onibus/sobral-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/sobral-ce/groairas-ce'},
        {link: '/passagem-de-onibus/sobral-ce/graca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/santaines-ma'},
        {link: '/passagem-de-onibus/sobral-ce/santaluzia,ma-ma'},
        {link: '/passagem-de-onibus/sobral-ce/carire-ce'},
        {link: '/passagem-de-onibus/sobral-ce/carire-ce'},
        {link: '/passagem-de-onibus/sobral-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/sobral-ce/massape-ce'},
        {link: '/passagem-de-onibus/sobral-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/sobral-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/sobral-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/sobral-ce/morrinhosce-ce'},
        {link: '/passagem-de-onibus/sobral-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/sobral-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/tururu-ce'},
        {link: '/passagem-de-onibus/sobral-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/sobral-ce/piripiri-pi'},
        {link: '/passagem-de-onibus/sobral-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/sobral-ce/parnaiba-pi'},
        {link: '/passagem-de-onibus/sobral-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/sobral-ce/caxias-ma'},
        {link: '/passagem-de-onibus/sobral-ce/sobral-ce'},
        {link: '/passagem-de-onibus/sobral-ce/sobral-ce'},
        {link: '/passagem-de-onibus/sobral-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/sobral-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/sobral-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/sobral-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/sobral-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/sobral-ce/itapecuru-ma'},
        {link: '/passagem-de-onibus/sobral-ce/osasco-sp'},
        {link: '/passagem-de-onibus/sobral-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/sobral-ce/imperatriz-ma'},
        {link: '/passagem-de-onibus/sobral-ce/maraba-pa'},
        {link: '/passagem-de-onibus/quixeramobim-ce/quixada-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/salvador-ba/caruaru-pe'},
        {link: '/passagem-de-onibus/salvador-ba/salgueiro-pe'},
        {link: '/passagem-de-onibus/salvador-ba/belojardim-pe'},
        {link: '/passagem-de-onibus/salvador-ba/fortaleza-ce'},
        {link: '/passagem-de-onibus/salvador-ba/recife-pe'},
        {link: '/passagem-de-onibus/uirauna-pb/pombal-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/patos-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/redencaodogurgueia-pi/goiania-go'},
        {link: '/passagem-de-onibus/redencaodogurgueia-pi/anapolis-go'},
        {link: '/passagem-de-onibus/redencaodogurgueia-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/salvador-ba/arcoverde-pe'},
        {link: '/passagem-de-onibus/salvador-ba/pesqueira-pe'},
        {link: '/passagem-de-onibus/salvador-ba/petrolandia-pe'},
        {link: '/passagem-de-onibus/salvador-ba/ico-ce'},
        {link: '/passagem-de-onibus/uirauna-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/uirauna-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/uirauna-pb/sousa-pb'},
        {link: '/passagem-de-onibus/quixeramobim-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/milha-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/crato-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/carius-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/ibaretama-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/jucas-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/groairas-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/graca-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/carire-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/ipu-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/milha-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/quixada-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/ocara-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/quixeramobim-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/redencaodogurgueia-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/redencaodogurgueia-pi/santos-sp'},
        {link: '/passagem-de-onibus/resende-rj/joaopessoa-pb'},
        {link: '/passagem-de-onibus/reriutaba-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/crato-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/redencaodogurgueia-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/redencaodogurgueia-pi/brasilia-df'},
        {link: '/passagem-de-onibus/redencaodogurgueia-pi/brasilia-df'},
        {link: '/passagem-de-onibus/russas-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/russas-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/russas-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/russas-ce/anapolis-go'},
        {link: '/passagem-de-onibus/russas-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/russas-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/russas-ce/jardim-ce-ce'},
        {link: '/passagem-de-onibus/russas-ce/jati-ce'},
        {link: '/passagem-de-onibus/russas-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/russas-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/russas-ce/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/reriutaba-ce/umirim-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/varjota-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/crateus-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/caraubas-rn-rn/planaltina,df-df'},
        {link: '/passagem-de-onibus/caraubas-rn-rn/brasilia-df'},
        {link: '/passagem-de-onibus/caraubas-rn-rn/brasilia-df'},
        {link: '/passagem-de-onibus/caraubas-rn-rn/sobradinho-df-df'},
        {link: '/passagem-de-onibus/russas-ce/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/russas-ce/goiania-go'},
        {link: '/passagem-de-onibus/rodavelha-ba/pombal-pb'},
        {link: '/passagem-de-onibus/rodavelha-ba/campinagrande-pb'},
        {link: '/passagem-de-onibus/rodavelha-ba/patos-pb'},
        {link: '/passagem-de-onibus/rodavelha-ba/brejosanto-ce'},
        {link: '/passagem-de-onibus/coreau-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/coreau-ce/camocim-ce'},
        {link: '/passagem-de-onibus/russas-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/russas-ce/ico-ce'},
        {link: '/passagem-de-onibus/russas-ce/ico-ce'},
        {link: '/passagem-de-onibus/russas-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/russas-ce/seabra-ba'},
        {link: '/passagem-de-onibus/rodavelha-ba/salgueiro-pe'},
        {link: '/passagem-de-onibus/rodavelha-ba/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/rodavelha-ba/petrolina-pe'},
        {link: '/passagem-de-onibus/rodavelha-ba/sousa-pb'},
        {link: '/passagem-de-onibus/reriutaba-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/sobral-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/sobral-ce'},
        {link: '/passagem-de-onibus/reriutaba-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/russas-ce/porteiras-ce'},
        {link: '/passagem-de-onibus/russas-ce/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/russas-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/russas-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/russas-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/russas-ce/novaolinda-ce-ce'},
        {link: '/passagem-de-onibus/russas-ce/oros-ce'},
        {link: '/passagem-de-onibus/russas-ce/oros-ce'},
        {link: '/passagem-de-onibus/russas-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/russas-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/coreau-ce/chaval-ce'},
        {link: '/passagem-de-onibus/coreau-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/coreau-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/coreau-ce/granja-ce'},
        {link: '/passagem-de-onibus/coreau-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/coreau-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/coreau-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/coreau-ce/sobral-ce'},
        {link: '/passagem-de-onibus/coreau-ce/sobral-ce'},
        {link: '/passagem-de-onibus/coreau-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/russas-ce/penaforte-ce'},
        {link: '/passagem-de-onibus/russas-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/russas-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/russas-ce/brasilia-df'},
        {link: '/passagem-de-onibus/russas-ce/brasilia-df'},
        {link: '/passagem-de-onibus/russas-ce/santanadocariri-ce'},
        {link: '/passagem-de-onibus/russas-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/russas-ce/serratalhada-pe'},
        {link: '/passagem-de-onibus/russas-ce/ibotirama-ba'},
        {link: '/passagem-de-onibus/russas-ce/umari-ce'},
        {link: '/passagem-de-onibus/russas-ce/aurora-ce'},
        {link: '/passagem-de-onibus/russas-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/russas-ce/barro-ce'},
        {link: '/passagem-de-onibus/russas-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/russas-ce/salgueiro-pe'},
        {link: '/passagem-de-onibus/russas-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/russas-ce/cedro-ce'},
        {link: '/passagem-de-onibus/russas-ce/crato-ce'},
        {link: '/passagem-de-onibus/russas-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/caico-rn/goiania-go'},
        {link: '/passagem-de-onibus/caico-rn/planaltina,df-df'},
        {link: '/passagem-de-onibus/caico-rn/brasilia-df'},
        {link: '/passagem-de-onibus/caico-rn/brasilia-df'},
        {link: '/passagem-de-onibus/caico-rn/sobradinho-df-df'},
        {link: '/passagem-de-onibus/brejodocruz-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/brejodocruz-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/brejodocruz-pb/pombal-pb'},
        {link: '/passagem-de-onibus/brejodocruz-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/saobernardo-/sobral-ce'},
        {link: '/passagem-de-onibus/saobernardo-/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/saobernardo-/santarita-ma'},
        {link: '/passagem-de-onibus/saobernardo-/vargemgrande-ma'},
        {link: '/passagem-de-onibus/saobernardo-/bacabeira-ma'},
        {link: '/passagem-de-onibus/saobernardo-/saoluis-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/colinas-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/capinzaldonorte-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/dompedro-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/pastosbons-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/presidentedutra-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/paraibano-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/saojoaodospatos-ma'},
        {link: '/passagem-de-onibus/saodomingosdomaranhao-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/coreau-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/coreau-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/coreau-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/coreau-ce/parnaiba-pi'},
        {link: '/passagem-de-onibus/coreau-ce/sobral-ce'},
        {link: '/passagem-de-onibus/coreau-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/coreau-ce/umirim-ce'},
        {link: '/passagem-de-onibus/coreau-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/coreau-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/coreau-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/saopaulo-sp/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/cristinocastro-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/coloniadogurgueia-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/campomaior-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/corrente-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/custodia-pe'},
        {link: '/passagem-de-onibus/saopaulo-sp/eliseumartins-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/fortaleza-ce'},
        {link: '/passagem-de-onibus/saopaulo-sp/joaopessoa-pb'},
        {link: '/passagem-de-onibus/coreau-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/coreau-ce/umirim-ce'},
        {link: '/passagem-de-onibus/coreau-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/coreau-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/coreau-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/coreau-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/coreau-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/coreau-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/coreau-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/saopaulo-sp/gilbues-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/itaueira-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/serrabranca-pb'},
        {link: '/passagem-de-onibus/saopaulo-sp/montealegredopiaui-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/teresina-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/monteiro-pb'},
        {link: '/passagem-de-onibus/saopaulo-sp/piripiri-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/sobral-ce'},
        {link: '/passagem-de-onibus/saopaulo-sp/redencaodogurgueia-pi'},
        {link: '/passagem-de-onibus/saobernardo-/anapurus-ma'},
        {link: '/passagem-de-onibus/saobernardo-/brejo-ma'},
        {link: '/passagem-de-onibus/saobernardo-/camocim-ce'},
        {link: '/passagem-de-onibus/saobernardo-/chapadinha-ma'},
        {link: '/passagem-de-onibus/saobernardo-/chaval-ce'},
        {link: '/passagem-de-onibus/saobernardo-/fortaleza-ce'},
        {link: '/passagem-de-onibus/saobernardo-/itapecuru-ma'},
        {link: '/passagem-de-onibus/saobernardo-/itapecuru-ma'},
        {link: '/passagem-de-onibus/saobernardo-/parnaiba-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/sume-pb'},
        {link: '/passagem-de-onibus/saopaulo-sp/campinagrande-pb'},
        {link: '/passagem-de-onibus/saopaulo-sp/floriano-pi'},
        {link: '/passagem-de-onibus/saopaulo-sp/sertania-pe'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/itapaje-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/itarema-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/itapipoca-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/iraucuba-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/senadorsa-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/ipu-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/moraujo-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/teresina-pi'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/condado-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/diamante-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/acarau-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/acarau-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/acarau-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/varjota-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/ibiapina-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/timon-ma'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/tiangua-ce'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/sousa-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/pombal-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/fortaleza-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/forquilha-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/frecheirinha-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/granja-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/groairas-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/graca-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/carire-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/carire-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/itapaje-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/martinopole-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/massape-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/mucambo-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/morrinhosce-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/tururu-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/piresferreira-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/piripiri-pi'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/pacuja-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/sobral-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/sobral-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/sobral-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/reriutaba-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/coreau-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/coreau-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/santamariadopara-pa'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/umirim-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/uruoca-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/uruoca-ce'},
        {link: '/passagem-de-onibus/brejodocruz-pb/patos-pb'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/amontada-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/carnaubal-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/belacruz-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/belem-pa'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/barroquinha-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/castanhal-pa'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/camocim-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/chaval-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/saobenedito-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/saobenedito-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/capanema,pa-pa'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/cruz-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/cruz-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/caucaia-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/caucaia-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/marco-ce-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/marco-ce-ce'},
        {link: '/passagem-de-onibus/saogoncalodoamarante-rn/ubajara-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/caxias-ma'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/sobral-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/sobral-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/sobral-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/coreau-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/umirim-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/acarau-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/acarau-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/varjota-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/soledade-pb-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/goiania-go'},
        {link: '/passagem-de-onibus/soledade-pb-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/pianco-pb'},
        {link: '/passagem-de-onibus/saojosedepiranhas-pb/patos-pb'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/santoantoniodoslopes-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/colinas-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/dompedro-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/ipu-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/massape-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/morrinhosce-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/tururu-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/piripiri-pi'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/groairas-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/graca-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/carire-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/carire-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/itarema-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/camocim-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/chaval-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/campomaior-pi'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/cruz-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/cruz-ce'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/pastosbons-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/presidentedutra-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/paraibano-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/saodomingosdomaranhao-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/santarita-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/saojoaodospatos-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/amontada-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/saoluisdocuru-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/soledade-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/pianco-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/acopiara-ce/madalena-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/crato-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/caninde-ce'},
        {link: '/passagem-de-onibus/soledade-pb-pb/maraba-pa'},
        {link: '/passagem-de-onibus/soledade-pb-pb/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/soledade-pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/jerico-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/recife-pe'},
        {link: '/passagem-de-onibus/soledade-pb-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/saojoaodoriodopeixe-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/sousa-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/pombal-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/condado-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/coremas-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/diamante-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/crato-ce'},
        {link: '/passagem-de-onibus/soledade-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/soledade-pb-pb/anapolis-go'},
        {link: '/passagem-de-onibus/acopiara-ce/sobral-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/tamboril-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/antoninadonorte-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/crateus-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/catarina-ce-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/quixada-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/goiania-go'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/acopiara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/jucas-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/ipu-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/quixada-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/piquetcarneiro-ce'},
        {link: '/passagem-de-onibus/acopiara-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/barro-ce'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/cajazeirinhas-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/santamariadopara-pa/bacabal-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/fortaleza-ce'},
        {link: '/passagem-de-onibus/santamariadopara-pa/governadornunesfreire-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/santaines-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/natal-rn'},
        {link: '/passagem-de-onibus/santamariadopara-pa/maceio-al'},
        {link: '/passagem-de-onibus/santamariadopara-pa/maracacume-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/teresina-pi'},
        {link: '/passagem-de-onibus/santamariadopara-pa/novaolindadomaranhao-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/piripiri-pi'},
        {link: '/passagem-de-onibus/santamariadopara-pa/picos-pi'},
        {link: '/passagem-de-onibus/saomamede-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/condado-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/coremas-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/diamante-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/santamariadopara-pa/caxias-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/sobral-ce'},
        {link: '/passagem-de-onibus/santamariadopara-pa/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/santamariadopara-pa/mossoro-rn'},
        {link: '/passagem-de-onibus/santamariadopara-pa/serratalhada-pe'},
        {link: '/passagem-de-onibus/santamariadopara-pa/peritoro-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/timon-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/zedoca-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/tiangua-ce'},
        {link: '/passagem-de-onibus/brasilia-df/altos-pi'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/anapolis-go'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/maraba-pa'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/saojoaodoriodopeixe-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/pianco-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/patos-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/patos-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/santamariadopara-pa/aracati-ce'},
        {link: '/passagem-de-onibus/santamariadopara-pa/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/assu-'},
        {link: '/passagem-de-onibus/santamariadopara-pa/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/santamariadopara-pa/codo-ma'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/sousa-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/pombal-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/brejodocruz-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/pianco-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/patos-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/cajazeirinhas-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/sousa-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/pombal-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/saomamede-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/condado-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/coremas-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/diamante-pb'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/crato-ce'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/missaovelha-ce'},
        {link: '/passagem-de-onibus/santaluzia-pb-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/brasilia-df/amarante-pi'},
        {link: '/passagem-de-onibus/brasilia-df/barro-ce'},
        {link: '/passagem-de-onibus/brasilia-df/salgueiro-pe'},
        {link: '/passagem-de-onibus/brasilia-df/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/brasilia-df/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/brasilia-df/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/brasilia-df/cristinocastro-pi'},
        {link: '/passagem-de-onibus/brasilia-df/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/brasilia-df/coloniadogurgueia-pi'},
        {link: '/passagem-de-onibus/brasilia-df/capimgrosso-ba'},
        {link: '/passagem-de-onibus/brasilia-df/crateus-ce'},
        {link: '/passagem-de-onibus/brasilia-df/curraisnovos-rn'},
        {link: '/passagem-de-onibus/brasilia-df/ico-ce'},
        {link: '/passagem-de-onibus/brasilia-df/brejosanto-ce'},
        {link: '/passagem-de-onibus/brasilia-df/tiangua-ce'},
        {link: '/passagem-de-onibus/brasilia-df/altos-pi'},
        {link: '/passagem-de-onibus/brasilia-df/amarante-pi'},
        {link: '/passagem-de-onibus/brasilia-df/barro-ce'},
        {link: '/passagem-de-onibus/brasilia-df/salgueiro-pe'},
        {link: '/passagem-de-onibus/brasilia-df/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/brasilia-df/jaguaribe-ce'},
        {link: '/passagem-de-onibus/brasilia-df/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/brasilia-df/natal-rn'},
        {link: '/passagem-de-onibus/brasilia-df/luiscorreia-pi'},
        {link: '/passagem-de-onibus/brasilia-df/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/brasilia-df/ipu-ce'},
        {link: '/passagem-de-onibus/brasilia-df/montealegredopiaui-pi'},
        {link: '/passagem-de-onibus/brasilia-df/teresina-pi'},
        {link: '/passagem-de-onibus/brasilia-df/quixada-ce'},
        {link: '/passagem-de-onibus/brasilia-df/novarussas-ce'},
        {link: '/passagem-de-onibus/brasilia-df/campomaior-pi'},
        {link: '/passagem-de-onibus/brasilia-df/saobenedito-ce'},
        {link: '/passagem-de-onibus/brasilia-df/catoledorocha-pb'},
        {link: '/passagem-de-onibus/brasilia-df/eliseumartins-pi'},
        {link: '/passagem-de-onibus/brasilia-df/fortaleza-ce'},
        {link: '/passagem-de-onibus/brasilia-df/joaopessoa-pb'},
        {link: '/passagem-de-onibus/brasilia-df/guarabira-pb'},
        {link: '/passagem-de-onibus/brasilia-df/aguabranca-pi'},
        {link: '/passagem-de-onibus/brasilia-df/gilbues-pi'},
        {link: '/passagem-de-onibus/brasilia-df/itaueira-pi'},
        {link: '/passagem-de-onibus/brasilia-df/redencaodogurgueia-pi'},
        {link: '/passagem-de-onibus/brasilia-df/caraubas-rn-rn'},
        {link: '/passagem-de-onibus/brasilia-df/russas-ce'},
        {link: '/passagem-de-onibus/brasilia-df/caico-rn'},
        {link: '/passagem-de-onibus/brasilia-df/mossoro-rn'},
        {link: '/passagem-de-onibus/brasilia-df/oeiras-pi'},
        {link: '/passagem-de-onibus/brasilia-df/campinagrande-pb'},
        {link: '/passagem-de-onibus/brasilia-df/patos-pb'},
        {link: '/passagem-de-onibus/brasilia-df/floriano-pi'},
        {link: '/passagem-de-onibus/brasilia-df/iguatu-ce'},
        {link: '/passagem-de-onibus/brasilia-df/petrolina-pe'},
        {link: '/passagem-de-onibus/brasilia-df/taua-ce'},
        {link: '/passagem-de-onibus/brasilia-df/sousa-pb'},
        {link: '/passagem-de-onibus/brasilia-df/piripiri-pi'},
        {link: '/passagem-de-onibus/brasilia-df/picos-pi'},
        {link: '/passagem-de-onibus/brasilia-df/pombal-pb'},
        {link: '/passagem-de-onibus/brasilia-df/parnaiba-pi'},
        {link: '/passagem-de-onibus/brasilia-df/piracuruca-pi'},
        {link: '/passagem-de-onibus/brasilia-df/sobral-ce'},
        {link: '/passagem-de-onibus/brasilia-df/coloniadogurgueia-pi'},
        {link: '/passagem-de-onibus/brasilia-df/capimgrosso-ba'},
        {link: '/passagem-de-onibus/brasilia-df/campomaior-pi'},
        {link: '/passagem-de-onibus/brasilia-df/saobenedito-ce'},
        {link: '/passagem-de-onibus/brasilia-df/catoledorocha-pb'},
        {link: '/passagem-de-onibus/brasilia-df/eliseumartins-pi'},
        {link: '/passagem-de-onibus/brasilia-df/fortaleza-ce'},
        {link: '/passagem-de-onibus/brasilia-df/joaopessoa-pb'},
        {link: '/passagem-de-onibus/brasilia-df/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/brasilia-df/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/brasilia-df/cristinocastro-pi'},
        {link: '/passagem-de-onibus/brasilia-df/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/barro-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/salgueiro-pe'},
        {link: '/passagem-de-onibus/sobradinho-df-df/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/bomjesus,pi-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/cristinocastro-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/cantodoburiti-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/coloniadogurgueia-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/capimgrosso-ba'},
        {link: '/passagem-de-onibus/sobradinho-df-df/campomaior-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/saobenedito-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/catoledorocha-pb'},
        {link: '/passagem-de-onibus/sobradinho-df-df/eliseumartins-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/fortaleza-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/joaopessoa-pb'},
        {link: '/passagem-de-onibus/sobradinho-df-df/aguabranca-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/gilbues-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/itaueira-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/jaguaribe-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/natal-rn'},
        {link: '/passagem-de-onibus/santanadocariri-ce/crato-ce'},
        {link: '/passagem-de-onibus/santanadocariri-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/santanadocariri-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/santanadocariri-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/santanadocariri-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/santanadocariri-ce/novaolinda-ce-ce'},
        {link: '/passagem-de-onibus/santanadocariri-ce/russas-ce'},
        {link: '/passagem-de-onibus/santanadocariri-ce/ico-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/altos-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/amarante-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/luiscorreia-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/sobradinho-df-df/ipu-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/montealegredopiaui-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/teresina-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/quixada-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/novarussas-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/iguatu-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/petrolina-pe'},
        {link: '/passagem-de-onibus/sobradinho-df-df/taua-ce'},
        {link: '/passagem-de-onibus/brasilia-df/guarabira-pb'},
        {link: '/passagem-de-onibus/brasilia-df/aguabranca-pi'},
        {link: '/passagem-de-onibus/brasilia-df/gilbues-pi'},
        {link: '/passagem-de-onibus/brasilia-df/itaueira-pi'},
        {link: '/passagem-de-onibus/brasilia-df/jaguaribe-ce'},
        {link: '/passagem-de-onibus/brasilia-df/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/brasilia-df/natal-rn'},
        {link: '/passagem-de-onibus/brasilia-df/luiscorreia-pi'},
        {link: '/passagem-de-onibus/brasilia-df/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/brasilia-df/ipu-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/sousa-pb'},
        {link: '/passagem-de-onibus/sobradinho-df-df/piripiri-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/picos-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/pombal-pb'},
        {link: '/passagem-de-onibus/sobradinho-df-df/parnaiba-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/piracuruca-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/sobral-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/redencaodogurgueia-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/caraubas-rn-rn'},
        {link: '/passagem-de-onibus/sobradinho-df-df/russas-ce'},
        {link: '/passagem-de-onibus/brasilia-df/montealegredopiaui-pi'},
        {link: '/passagem-de-onibus/brasilia-df/teresina-pi'},
        {link: '/passagem-de-onibus/brasilia-df/quixada-ce'},
        {link: '/passagem-de-onibus/brasilia-df/novarussas-ce'},
        {link: '/passagem-de-onibus/brasilia-df/iguatu-ce'},
        {link: '/passagem-de-onibus/brasilia-df/petrolina-pe'},
        {link: '/passagem-de-onibus/brasilia-df/taua-ce'},
        {link: '/passagem-de-onibus/brasilia-df/sousa-pb'},
        {link: '/passagem-de-onibus/brasilia-df/piripiri-pi'},
        {link: '/passagem-de-onibus/brasilia-df/picos-pi'},
        {link: '/passagem-de-onibus/brasilia-df/mossoro-rn'},
        {link: '/passagem-de-onibus/brasilia-df/oeiras-pi'},
        {link: '/passagem-de-onibus/brasilia-df/campinagrande-pb'},
        {link: '/passagem-de-onibus/brasilia-df/patos-pb'},
        {link: '/passagem-de-onibus/brasilia-df/floriano-pi'},
        {link: '/passagem-de-onibus/brasilia-df/crateus-ce'},
        {link: '/passagem-de-onibus/brasilia-df/curraisnovos-rn'},
        {link: '/passagem-de-onibus/brasilia-df/ico-ce'},
        {link: '/passagem-de-onibus/brasilia-df/brejosanto-ce'},
        {link: '/passagem-de-onibus/brasilia-df/tiangua-ce'},
        {link: '/passagem-de-onibus/brasilia-df/pombal-pb'},
        {link: '/passagem-de-onibus/brasilia-df/parnaiba-pi'},
        {link: '/passagem-de-onibus/brasilia-df/piracuruca-pi'},
        {link: '/passagem-de-onibus/brasilia-df/sobral-ce'},
        {link: '/passagem-de-onibus/brasilia-df/redencaodogurgueia-pi'},
        {link: '/passagem-de-onibus/brasilia-df/caraubas-rn-rn'},
        {link: '/passagem-de-onibus/brasilia-df/russas-ce'},
        {link: '/passagem-de-onibus/brasilia-df/caico-rn'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/sobral-ce'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/saobernardo-'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/santarita-ma'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/santarita-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/santarita-ma/vitoriadomearim-ma'},
        {link: '/passagem-de-onibus/santarita-ma/anapurus-ma'},
        {link: '/passagem-de-onibus/santarita-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/cocal-pi/piripiri-pi'},
        {link: '/passagem-de-onibus/cocal-pi/parnaiba-pi'},
        {link: '/passagem-de-onibus/cocal-pi/piracuruca-pi'},
        {link: '/passagem-de-onibus/cocal-pi/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/mossoro-rn/belem-pa'},
        {link: '/passagem-de-onibus/mossoro-rn/garanhuns-pe'},
        {link: '/passagem-de-onibus/mossoro-rn/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/mossoro-rn/castanhal-pa'},
        {link: '/passagem-de-onibus/mossoro-rn/bacabal-ma'},
        {link: '/passagem-de-onibus/mossoro-rn/capanema,pa-pa'},
        {link: '/passagem-de-onibus/sobradinho-df-df/caico-rn'},
        {link: '/passagem-de-onibus/sobradinho-df-df/mossoro-rn'},
        {link: '/passagem-de-onibus/sobradinho-df-df/oeiras-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/campinagrande-pb'},
        {link: '/passagem-de-onibus/sobradinho-df-df/patos-pb'},
        {link: '/passagem-de-onibus/sobradinho-df-df/floriano-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/crateus-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/curraisnovos-rn'},
        {link: '/passagem-de-onibus/sobradinho-df-df/ico-ce'},
        {link: '/passagem-de-onibus/mossoro-rn/fortaleza-ce'},
        {link: '/passagem-de-onibus/mossoro-rn/joaopessoa-pb'},
        {link: '/passagem-de-onibus/mossoro-rn/goiana-pe'},
        {link: '/passagem-de-onibus/mossoro-rn/santaines-ma'},
        {link: '/passagem-de-onibus/mossoro-rn/palmeiradosindios-al'},
        {link: '/passagem-de-onibus/mossoro-rn/recife-pe'},
        {link: '/passagem-de-onibus/mossoro-rn/maceio-al'},
        {link: '/passagem-de-onibus/mossoro-rn/teresina-pi'},
        {link: '/passagem-de-onibus/mossoro-rn/arcoverde-pe'},
        {link: '/passagem-de-onibus/mossoro-rn/piripiri-pi'},
        {link: '/passagem-de-onibus/sobradinho-df-df/brejosanto-ce'},
        {link: '/passagem-de-onibus/sobradinho-df-df/tiangua-ce'},
        {link: '/passagem-de-onibus/cocal-pi/altos-pi'},
        {link: '/passagem-de-onibus/cocal-pi/brasileira-pi'},
        {link: '/passagem-de-onibus/cocal-pi/buritidoslopes-pi'},
        {link: '/passagem-de-onibus/cocal-pi/capitaodecampos-pi'},
        {link: '/passagem-de-onibus/cocal-pi/cocaldetelha-pi'},
        {link: '/passagem-de-onibus/cocal-pi/campomaior-pi'},
        {link: '/passagem-de-onibus/cocal-pi/teresina-pi'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/anapurus-ma'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/brejo-ma'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/camocim-ce'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/santaquiteriadomaranhao-ma/parnaiba-pi'},
        {link: '/passagem-de-onibus/mossoro-rn/planaltina,df-df'},
        {link: '/passagem-de-onibus/mossoro-rn/pombal-pb'},
        {link: '/passagem-de-onibus/mossoro-rn/caxias-ma'},
        {link: '/passagem-de-onibus/mossoro-rn/sobral-ce'},
        {link: '/passagem-de-onibus/mossoro-rn/santamariadopara-pa'},
        {link: '/passagem-de-onibus/mossoro-rn/brasilia-df'},
        {link: '/passagem-de-onibus/mossoro-rn/brasilia-df'},
        {link: '/passagem-de-onibus/mossoro-rn/sobradinho-df-df'},
        {link: '/passagem-de-onibus/mossoro-rn/aracaju-se'},
        {link: '/passagem-de-onibus/mossoro-rn/campinagrande-pb'},
        {link: '/passagem-de-onibus/mossoro-rn/patos-pb'},
        {link: '/passagem-de-onibus/mossoro-rn/peritoro-ma'},
        {link: '/passagem-de-onibus/mossoro-rn/timon-ma'},
        {link: '/passagem-de-onibus/mossoro-rn/tiangua-ce'},
        {link: '/passagem-de-onibus/serratalhada-pe/bacabal-ma'},
        {link: '/passagem-de-onibus/serratalhada-pe/capanema,pa-pa'},
        {link: '/passagem-de-onibus/serratalhada-pe/crato-ce'},
        {link: '/passagem-de-onibus/serratalhada-pe/fortaleza-ce'},
        {link: '/passagem-de-onibus/serratalhada-pe/santaines-ma'},
        {link: '/passagem-de-onibus/serratalhada-pe/imperatriz-ma'},
        {link: '/passagem-de-onibus/serratalhada-pe/maceio-al'},
        {link: '/passagem-de-onibus/serratalhada-pe/caxias-ma'},
        {link: '/passagem-de-onibus/serratalhada-pe/russas-ce'},
        {link: '/passagem-de-onibus/serratalhada-pe/santamariadopara-pa'},
        {link: '/passagem-de-onibus/serratalhada-pe/marcolandia-pi'},
        {link: '/passagem-de-onibus/aracaju-se/patos-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/sousa-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/pombal-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/oeiras-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/tamboril-ce/crato-ce'},
        {link: '/passagem-de-onibus/tamboril-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/tamboril-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/tamboril-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/tamboril-ce/crateus-ce'},
        {link: '/passagem-de-onibus/tamboril-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/aracaju-se/aracati-ce'},
        {link: '/passagem-de-onibus/aracaju-se/fortaleza-ce'},
        {link: '/passagem-de-onibus/aracaju-se/mossoro-rn'},
        {link: '/passagem-de-onibus/serratalhada-pe/ico-ce'},
        {link: '/passagem-de-onibus/serratalhada-pe/brejosanto-ce'},
        {link: '/passagem-de-onibus/serratalhada-pe/timon-ma'},
        {link: '/passagem-de-onibus/sume-pb/riodejaneiro-rj'},
        {link: '/passagem-de-onibus/sume-pb/saopaulo-sp'},
        {link: '/passagem-de-onibus/oeiras-pi/goiania-go'},
        {link: '/passagem-de-onibus/oeiras-pi/barreiras-ba'},
        {link: '/passagem-de-onibus/oeiras-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/oeiras-pi/brasilia-df'},
        {link: '/passagem-de-onibus/oeiras-pi/brasilia-df'},
        {link: '/passagem-de-onibus/campinagrande-pb/patos-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/aracati-ce'},
        {link: '/passagem-de-onibus/campinagrande-pb/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/campinagrande-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/goiania-go'},
        {link: '/passagem-de-onibus/campinagrande-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/barreiras-ba'},
        {link: '/passagem-de-onibus/campinagrande-pb/barro-ce'},
        {link: '/passagem-de-onibus/campinagrande-pb/cajazeirinhas-pb'},
        {link: '/passagem-de-onibus/santarita-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/santarita-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/santarita-ma/teresina-pi'},
        {link: '/passagem-de-onibus/santarita-ma/presidentedutra-ma'},
        {link: '/passagem-de-onibus/santarita-ma/pindare-mirim-ma'},
        {link: '/passagem-de-onibus/santarita-ma/parnaiba-pi'},
        {link: '/passagem-de-onibus/santarita-ma/paraibano-ma'},
        {link: '/passagem-de-onibus/santarita-ma/caxias-ma'},
        {link: '/passagem-de-onibus/santarita-ma/saobernardo-'},
        {link: '/passagem-de-onibus/santarita-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/santarita-ma/brejo-ma'},
        {link: '/passagem-de-onibus/santarita-ma/codo-ma'},
        {link: '/passagem-de-onibus/santarita-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/santarita-ma/bacabal-ma'},
        {link: '/passagem-de-onibus/santarita-ma/capinzaldonorte-ma'},
        {link: '/passagem-de-onibus/santarita-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/santarita-ma/santaines-ma'},
        {link: '/passagem-de-onibus/santarita-ma/arari-ma'},
        {link: '/passagem-de-onibus/santarita-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/santarita-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/campinagrande-pb/belem-pa'},
        {link: '/passagem-de-onibus/campinagrande-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/capimgrosso-ba'},
        {link: '/passagem-de-onibus/campinagrande-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/condado-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/coremas-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/diamante-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/crato-ce'},
        {link: '/passagem-de-onibus/santarita-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/santarita-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/santarita-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/santarita-ma/timon-ma'},
        {link: '/passagem-de-onibus/santarita-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/serratalhada-pe/barro-ce'},
        {link: '/passagem-de-onibus/serratalhada-pe/belem-pa'},
        {link: '/passagem-de-onibus/serratalhada-pe/boavistadogurupi-ma'},
        {link: '/passagem-de-onibus/serratalhada-pe/castanhal-pa'},
        {link: '/passagem-de-onibus/campinagrande-pb/jerico-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/saojoaodoriodopeixe-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/irece-ba'},
        {link: '/passagem-de-onibus/campinagrande-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/riodejaneiro-rj'},
        {link: '/passagem-de-onibus/campinagrande-pb/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/campinagrande-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/teresina-pi'},
        {link: '/passagem-de-onibus/campinagrande-pb/timon-ma'},
        {link: '/passagem-de-onibus/campinagrande-pb/seabra-ba'},
        {link: '/passagem-de-onibus/campinagrande-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/trairi-ce/camocim-ce'},
        {link: '/passagem-de-onibus/trairi-ce/cruz-ce'},
        {link: '/passagem-de-onibus/trairi-ce/granja-ce'},
        {link: '/passagem-de-onibus/trairi-ce/itarema-ce'},
        {link: '/passagem-de-onibus/trairi-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/timbiras-ma/teresina-pi'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/campossales,ce-ce'},
        {link: '/passagem-de-onibus/campinagrande-pb/missaovelha-ce'},
        {link: '/passagem-de-onibus/campinagrande-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/campinagrande-pb/jequie-ba'},
        {link: '/passagem-de-onibus/campinagrande-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/anapolis-go'},
        {link: '/passagem-de-onibus/campinagrande-pb/imperatriz-ma'},
        {link: '/passagem-de-onibus/campinagrande-pb/maraba-pa'},
        {link: '/passagem-de-onibus/campinagrande-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/campinagrande-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/saboeiro-ce'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/carius-ce'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/jucas-ce'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/quixada-ce'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/antoninadonorte-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/ibotirama-ba/barro-ce'},
        {link: '/passagem-de-onibus/ibotirama-ba/salgueiro-pe'},
        {link: '/passagem-de-onibus/ibotirama-ba/fortaleza-ce'},
        {link: '/passagem-de-onibus/ibotirama-ba/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/ibotirama-ba/petrolina-pe'},
        {link: '/passagem-de-onibus/ibotirama-ba/sousa-pb'},
        {link: '/passagem-de-onibus/ibotirama-ba/pombal-pb'},
        {link: '/passagem-de-onibus/ibotirama-ba/russas-ce'},
        {link: '/passagem-de-onibus/ibotirama-ba/campinagrande-pb'},
        {link: '/passagem-de-onibus/ibotirama-ba/patos-pb'},
        {link: '/passagem-de-onibus/ibotirama-ba/ico-ce'},
        {link: '/passagem-de-onibus/campinagrande-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/sousa-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/picos-pi'},
        {link: '/passagem-de-onibus/campinagrande-pb/planaltina,df-df'},
        {link: '/passagem-de-onibus/campinagrande-pb/pombal-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/paulista-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/caxias-ma'},
        {link: '/passagem-de-onibus/campinagrande-pb/ibotirama-ba'},
        {link: '/passagem-de-onibus/campinagrande-pb/pianco-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/patos-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/patos-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/floriano-pi'},
        {link: '/passagem-de-onibus/campinagrande-pb/brejosanto-ce'},
        {link: '/passagem-de-onibus/campinagrande-pb/teofilootoni-mg'},
        {link: '/passagem-de-onibus/campinagrande-pb/peritoro-ma'},
        {link: '/passagem-de-onibus/campinagrande-pb/santos-sp'},
        {link: '/passagem-de-onibus/campinagrande-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/rodavelha-ba'},
        {link: '/passagem-de-onibus/campinagrande-pb/saopaulo-sp'},
        {link: '/passagem-de-onibus/campinagrande-pb/brejodocruz-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/campinagrande-pb/brasilia-df'},
        {link: '/passagem-de-onibus/campinagrande-pb/brasilia-df'},
        {link: '/passagem-de-onibus/campinagrande-pb/sobradinho-df-df'},
        {link: '/passagem-de-onibus/campinagrande-pb/mossoro-rn'},
        {link: '/passagem-de-onibus/umari-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/umari-ce/crato-ce'},
        {link: '/passagem-de-onibus/umari-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/umari-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/umari-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/umari-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/umari-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/umari-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/umari-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/umari-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/umari-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/patos-pb/jerico-pb'},
        {link: '/passagem-de-onibus/patos-pb/recife-pe'},
        {link: '/passagem-de-onibus/patos-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/patos-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/saojoaodoriodopeixe-pb'},
        {link: '/passagem-de-onibus/patos-pb/maceio-al'},
        {link: '/passagem-de-onibus/patos-pb/irece-ba'},
        {link: '/passagem-de-onibus/patos-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/pianco-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/pianco-pb/patos-pb'},
        {link: '/passagem-de-onibus/patos-pb/aracati-ce'},
        {link: '/passagem-de-onibus/patos-pb/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/patos-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/goiania-go'},
        {link: '/passagem-de-onibus/patos-pb/saobento-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/barreiras-ba'},
        {link: '/passagem-de-onibus/patos-pb/barro-ce'},
        {link: '/passagem-de-onibus/patos-pb/cajazeirinhas-pb'},
        {link: '/passagem-de-onibus/umari-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/umari-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/umari-ce/russas-ce'},
        {link: '/passagem-de-onibus/umari-ce/ico-ce'},
        {link: '/passagem-de-onibus/umari-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/pianco-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/pianco-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/pianco-pb/diamante-pb'},
        {link: '/passagem-de-onibus/pianco-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/pianco-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/patos-pb/salgueiro-pe'},
        {link: '/passagem-de-onibus/patos-pb/bonitodesantafe-pb'},
        {link: '/passagem-de-onibus/patos-pb/boaventura-pb'},
        {link: '/passagem-de-onibus/patos-pb/capimgrosso-ba'},
        {link: '/passagem-de-onibus/patos-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/patos-pb/condado-pb'},
        {link: '/passagem-de-onibus/patos-pb/conceicao-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/coremas-pb'},
        {link: '/passagem-de-onibus/patos-pb/diamante-pb'},
        {link: '/passagem-de-onibus/patos-pb/crato-ce'},
        {link: '/passagem-de-onibus/patos-pb/missaovelha-ce'},
        {link: '/passagem-de-onibus/patos-pb/fortaleza-ce'},
        {link: '/passagem-de-onibus/patos-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/patos-pb/catingueira-pb'},
        {link: '/passagem-de-onibus/patos-pb/anapolis-go'},
        {link: '/passagem-de-onibus/patos-pb/imperatriz-ma'},
        {link: '/passagem-de-onibus/patos-pb/maraba-pa'},
        {link: '/passagem-de-onibus/patos-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/patos-pb/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/patos-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/patos-pb/montehorebe-pb'},
        {link: '/passagem-de-onibus/patos-pb/milagres,ce-ce'},
        {link: '/passagem-de-onibus/patos-pb/teresina-pi'},
        {link: '/passagem-de-onibus/patos-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/patos-pb/petrolina-pe'},
        {link: '/passagem-de-onibus/patos-pb/sousa-pb'},
        {link: '/passagem-de-onibus/patos-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/patos-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/picos-pi'},
        {link: '/passagem-de-onibus/patos-pb/planaltina,df-df'},
        {link: '/passagem-de-onibus/ibotirama-ba/brejosanto-ce'},
        {link: '/passagem-de-onibus/tutoia-ma/barreirinhas-ma'},
        {link: '/passagem-de-onibus/tutoia-ma/paulinoneves-ma'},
        {link: '/passagem-de-onibus/tutoia-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/tutoia-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/jacobina-ba/goiania-go'},
        {link: '/passagem-de-onibus/jacobina-ba/anapolis-go'},
        {link: '/passagem-de-onibus/umari-ce/aurora-ce'},
        {link: '/passagem-de-onibus/umari-ce/barro-ce'},
        {link: '/passagem-de-onibus/pianco-pb/ibiara-pb'},
        {link: '/passagem-de-onibus/pianco-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/pianco-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/pianco-pb/santateresinha-pb-pb'},
        {link: '/passagem-de-onibus/pianco-pb/itaporanga-pb-pb'},
        {link: '/passagem-de-onibus/pianco-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/pianco-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/pianco-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/pianco-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/patos-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/patos-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/patos-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/patos-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/patos-pb/sousa-pb'},
        {link: '/passagem-de-onibus/patos-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/pombal-pb'},
        {link: '/passagem-de-onibus/patos-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/patos-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/patos-pb/patos-pb'},
        {link: '/passagem-de-onibus/patos-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/umirim-ce/amontada-ce'},
        {link: '/passagem-de-onibus/umirim-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/umirim-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/umirim-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/umirim-ce/camocim-ce'},
        {link: '/passagem-de-onibus/umirim-ce/chaval-ce'},
        {link: '/passagem-de-onibus/patos-pb/timon-ma'},
        {link: '/passagem-de-onibus/patos-pb/seabra-ba'},
        {link: '/passagem-de-onibus/patos-pb/saobentinho-pb'},
        {link: '/passagem-de-onibus/patos-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/patos-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/patos-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/patos-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/condado-pb'},
        {link: '/passagem-de-onibus/patos-pb/pombal-pb'},
        {link: '/passagem-de-onibus/patos-pb/paulista-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/caxias-ma'},
        {link: '/passagem-de-onibus/patos-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/patos-pb/rodavelha-ba'},
        {link: '/passagem-de-onibus/patos-pb/brejodocruz-pb'},
        {link: '/passagem-de-onibus/patos-pb/saojosedepiranhas-pb'},
        {link: '/passagem-de-onibus/patos-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/patos-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/umirim-ce/carire-ce'},
        {link: '/passagem-de-onibus/umirim-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/umirim-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/umirim-ce/itarema-ce'},
        {link: '/passagem-de-onibus/umirim-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/umirim-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/umirim-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/umirim-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/umirim-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/umirim-ce/ipu-ce'},
        {link: '/passagem-de-onibus/umirim-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/umirim-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/umirim-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/umirim-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/umirim-ce/granja-ce'},
        {link: '/passagem-de-onibus/umirim-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/umirim-ce/groairas-ce'},
        {link: '/passagem-de-onibus/umirim-ce/graca-ce'},
        {link: '/passagem-de-onibus/umirim-ce/carire-ce'},
        {link: '/passagem-de-onibus/umirim-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/umirim-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/umirim-ce/cruz-ce'},
        {link: '/passagem-de-onibus/umirim-ce/cruz-ce'},
        {link: '/passagem-de-onibus/umirim-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/umirim-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/umirim-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/umirim-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/umirim-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/patos-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/patos-pb/brasilia-df'},
        {link: '/passagem-de-onibus/patos-pb/brasilia-df'},
        {link: '/passagem-de-onibus/patos-pb/sobradinho-df-df'},
        {link: '/passagem-de-onibus/patos-pb/mossoro-rn'},
        {link: '/passagem-de-onibus/patos-pb/aracaju-se'},
        {link: '/passagem-de-onibus/patos-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/patos-pb/ibotirama-ba'},
        {link: '/passagem-de-onibus/patos-pb/pianco-pb'},
        {link: '/passagem-de-onibus/patos-pb/patos-pb'},
        {link: '/passagem-de-onibus/patos-pb/floriano-pi'},
        {link: '/passagem-de-onibus/patos-pb/brejosanto-ce'},
        {link: '/passagem-de-onibus/patos-pb/peritoro-ma'},
        {link: '/passagem-de-onibus/uruoca-ce/camocim-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/granja-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/senadorsa-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/massape-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/floriano-pi/sobradinho-df-df'},
        {link: '/passagem-de-onibus/floriano-pi/campinagrande-pb'},
        {link: '/passagem-de-onibus/floriano-pi/patos-pb'},
        {link: '/passagem-de-onibus/floriano-pi/crateus-ce'},
        {link: '/passagem-de-onibus/floriano-pi/santos-sp'},
        {link: '/passagem-de-onibus/uruoca-ce/barroquinha-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/camocim-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/chaval-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/umirim-ce'},
        {link: '/passagem-de-onibus/acarau-ce/amontada-ce'},
        {link: '/passagem-de-onibus/acarau-ce/camocim-ce'},
        {link: '/passagem-de-onibus/acarau-ce/cruz-ce'},
        {link: '/passagem-de-onibus/acarau-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/acarau-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/acarau-ce/granja-ce'},
        {link: '/passagem-de-onibus/acarau-ce/itarema-ce'},
        {link: '/passagem-de-onibus/acarau-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/umirim-ce/acarau-ce'},
        {link: '/passagem-de-onibus/umirim-ce/varjota-ce'},
        {link: '/passagem-de-onibus/umirim-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/umirim-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/umirim-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/floriano-pi/goiania-go'},
        {link: '/passagem-de-onibus/floriano-pi/joaopessoa-pb'},
        {link: '/passagem-de-onibus/floriano-pi/anapolis-go'},
        {link: '/passagem-de-onibus/floriano-pi/osasco-sp'},
        {link: '/passagem-de-onibus/floriano-pi/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/umirim-ce/sobral-ce'},
        {link: '/passagem-de-onibus/umirim-ce/sobral-ce'},
        {link: '/passagem-de-onibus/umirim-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/umirim-ce/coreau-ce'},
        {link: '/passagem-de-onibus/umirim-ce/coreau-ce'},
        {link: '/passagem-de-onibus/umirim-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/umirim-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/umirim-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/umirim-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/umirim-ce/uruoca-ce'},
        {link: '/passagem-de-onibus/umirim-ce/acarau-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/granja-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/sobral-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/coreau-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/uruoca-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/uruoca-ce/umirim-ce'},
        {link: '/passagem-de-onibus/floriano-pi/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/floriano-pi/uberaba-mg'},
        {link: '/passagem-de-onibus/floriano-pi/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/floriano-pi/ipu-ce'},
        {link: '/passagem-de-onibus/floriano-pi/campinas-sp'},
        {link: '/passagem-de-onibus/floriano-pi/ribeiraopreto-sp'},
        {link: '/passagem-de-onibus/floriano-pi/planaltina,df-df'},
        {link: '/passagem-de-onibus/floriano-pi/saopaulo-sp'},
        {link: '/passagem-de-onibus/floriano-pi/brasilia-df'},
        {link: '/passagem-de-onibus/floriano-pi/brasilia-df'},
        {link: '/passagem-de-onibus/umirim-ce/santanadoacarau-ce'},
        {link: '/passagem-de-onibus/umirim-ce/moraujo-ce'},
        {link: '/passagem-de-onibus/umirim-ce/martinopole-ce'},
        {link: '/passagem-de-onibus/umirim-ce/massape-ce'},
        {link: '/passagem-de-onibus/umirim-ce/mucambo-ce'},
        {link: '/passagem-de-onibus/umirim-ce/morrinhosce-ce'},
        {link: '/passagem-de-onibus/umirim-ce/tururu-ce'},
        {link: '/passagem-de-onibus/umirim-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/umirim-ce/pacuja-ce'},
        {link: '/passagem-de-onibus/umirim-ce/sobral-ce'},
        {link: '/passagem-de-onibus/acarau-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/acarau-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/acarau-ce/tururu-ce'},
        {link: '/passagem-de-onibus/acarau-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/vargemgrande-ma/santarita-ma'},
        {link: '/passagem-de-onibus/vargemgrande-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/vargemgrande-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/crateus-ce/goiania-go'},
        {link: '/passagem-de-onibus/crateus-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/crateus-ce/corrente-pi'},
        {link: '/passagem-de-onibus/crateus-ce/crato-ce'},
        {link: '/passagem-de-onibus/crateus-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/crateus-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/crateus-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/varjota-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/varjota-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/varjota-ce/umirim-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/sobral-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/coreau-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/umirim-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/serrinha-ba/caruaru-pe'},
        {link: '/passagem-de-onibus/serrinha-ba/belojardim-pe'},
        {link: '/passagem-de-onibus/serrinha-ba/recife-pe'},
        {link: '/passagem-de-onibus/serrinha-ba/arcoverde-pe'},
        {link: '/passagem-de-onibus/serrinha-ba/petrolandia-pe'},
        {link: '/passagem-de-onibus/vargemgrande-ma/anapurus-ma'},
        {link: '/passagem-de-onibus/vargemgrande-ma/brejo-ma'},
        {link: '/passagem-de-onibus/acarau-ce/acarau-ce'},
        {link: '/passagem-de-onibus/acarau-ce/belacruz-ce'},
        {link: '/passagem-de-onibus/acarau-ce/cruz-ce'},
        {link: '/passagem-de-onibus/acarau-ce/marco-ce-ce'},
        {link: '/passagem-de-onibus/acarau-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/acarau-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/acarau-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/varjota-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/varjota-ce/caucaia-ce'},
        {link: '/passagem-de-onibus/varjota-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/sobral-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/coreau-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/vicosadoceara-ce/cocal-pi'},
        {link: '/passagem-de-onibus/acarau-ce/camocim-ce'},
        {link: '/passagem-de-onibus/acarau-ce/cruz-ce'},
        {link: '/passagem-de-onibus/acarau-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/acarau-ce/granja-ce'},
        {link: '/passagem-de-onibus/acarau-ce/itarema-ce'},
        {link: '/passagem-de-onibus/acarau-ce/itapipoca-ce'},
        {link: '/passagem-de-onibus/acarau-ce/jijocadejericoacoara-ce'},
        {link: '/passagem-de-onibus/acarau-ce/tururu-ce'},
        {link: '/passagem-de-onibus/acarau-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/acarau-ce/umirim-ce'},
        {link: '/passagem-de-onibus/varjota-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/varjota-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/varjota-ce/groairas-ce'},
        {link: '/passagem-de-onibus/varjota-ce/carire-ce'},
        {link: '/passagem-de-onibus/varjota-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/varjota-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/varjota-ce/ipu-ce'},
        {link: '/passagem-de-onibus/varjota-ce/piresferreira-ce'},
        {link: '/passagem-de-onibus/varjota-ce/sobral-ce'},
        {link: '/passagem-de-onibus/varjota-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/vargemgrande-ma/camocim-ce'},
        {link: '/passagem-de-onibus/vargemgrande-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/vargemgrande-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/vargemgrande-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/vargemgrande-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/vargemgrande-ma/parnaiba-pi'},
        {link: '/passagem-de-onibus/vargemgrande-ma/sobral-ce'},
        {link: '/passagem-de-onibus/vargemgrande-ma/saobernardo-'},
        {link: '/passagem-de-onibus/vargemgrande-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/acarau-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/acarau-ce/trairi-ce'},
        {link: '/passagem-de-onibus/acarau-ce/umirim-ce'},
        {link: '/passagem-de-onibus/acarau-ce/acarau-ce'},
        {link: '/passagem-de-onibus/acarau-ce/amontada-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/cedro-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/madalena-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/crato-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/caninde-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/independencia-ce-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/sobral-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/quixeramobim-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/reriutaba-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/russas-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/crateus-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/boaviagem-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/ico-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/ico-ce'},
        {link: '/passagem-de-onibus/bacabeira-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/crateus-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/crateus-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/crateus-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/crateus-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/crateus-ce/acopiara-ce'},
        {link: '/passagem-de-onibus/crateus-ce/brasilia-df'},
        {link: '/passagem-de-onibus/crateus-ce/brasilia-df'},
        {link: '/passagem-de-onibus/crateus-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/crateus-ce/floriano-pi'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/ipu-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/santaquiteria-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/mombaca-ce'},
        {link: '/passagem-de-onibus/bacabeira-ma/vitoriadomearim-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/anapurus-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/barreirinhas-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/brejo-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/codo-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/bacabal-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/santaines-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/arari-ma'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/quixada-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/novarussas-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/oros-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/iguatu-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/ipueiras-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/pedrabranca-ce'},
        {link: '/passagem-de-onibus/varzeaalegre-ce/senadorpompeu-ce'},
        {link: '/passagem-de-onibus/bacabeira-ma/paulinoneves-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/caxias-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/saobernardo-'},
        {link: '/passagem-de-onibus/bacabeira-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/santarita-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/tutoia-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/pindare-mirim-ma'},
        {link: '/passagem-de-onibus/taubate-sp/joaopessoa-pb'},
        {link: '/passagem-de-onibus/valencadopiaui-pi/araripina-pe'},
        {link: '/passagem-de-onibus/valencadopiaui-pi/campossales,ce-ce'},
        {link: '/passagem-de-onibus/valencadopiaui-pi/maraba-pa'},
        {link: '/passagem-de-onibus/valencadopiaui-pi/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/caruaru-pe'},
        {link: '/passagem-de-onibus/brejosanto-ce/alvoradadonorte-go'},
        {link: '/passagem-de-onibus/brejosanto-ce/goiania-go'},
        {link: '/passagem-de-onibus/brejosanto-ce/aurora-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/brejosanto-ce/barro-ce'},
        {link: '/passagem-de-onibus/bacabeira-ma/timon-ma'},
        {link: '/passagem-de-onibus/bacabeira-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/sertania-pe/riodejaneiro-rj'},
        {link: '/passagem-de-onibus/sertania-pe/saopaulo-sp'},
        {link: '/passagem-de-onibus/sertania-pe/feiradesantana-ba'},
        {link: '/passagem-de-onibus/tucano-ba/recife-pe'},
        {link: '/passagem-de-onibus/tucano-ba/arcoverde-pe'},
        {link: '/passagem-de-onibus/tucano-ba/petrolandia-pe'},
        {link: '/passagem-de-onibus/curraisnovos-rn/goiania-go'},
        {link: '/passagem-de-onibus/curraisnovos-rn/planaltina,df-df'},
        {link: '/passagem-de-onibus/ico-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/ico-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/ico-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/ico-ce/solonopole-ce'},
        {link: '/passagem-de-onibus/ico-ce/quixada-ce'},
        {link: '/passagem-de-onibus/ico-ce/oros-ce'},
        {link: '/passagem-de-onibus/ico-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/ico-ce/russas-ce'},
        {link: '/passagem-de-onibus/ico-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/ico-ce/ico-ce'},
        {link: '/passagem-de-onibus/marcolandia-pi/arcoverde-pe'},
        {link: '/passagem-de-onibus/marcolandia-pi/pesqueira-pe'},
        {link: '/passagem-de-onibus/marcolandia-pi/serratalhada-pe'},
        {link: '/passagem-de-onibus/ico-ce/goiania-go'},
        {link: '/passagem-de-onibus/ico-ce/aurora-ce'},
        {link: '/passagem-de-onibus/ico-ce/barreiras-ba'},
        {link: '/passagem-de-onibus/ico-ce/barro-ce'},
        {link: '/passagem-de-onibus/ico-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/ico-ce/salgueiro-pe'},
        {link: '/passagem-de-onibus/ico-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/ico-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/ico-ce/salvador-ba'},
        {link: '/passagem-de-onibus/ico-ce/russas-ce'},
        {link: '/passagem-de-onibus/ico-ce/brasilia-df'},
        {link: '/passagem-de-onibus/ico-ce/brasilia-df'},
        {link: '/passagem-de-onibus/ico-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/ico-ce/ibotirama-ba'},
        {link: '/passagem-de-onibus/ico-ce/umari-ce'},
        {link: '/passagem-de-onibus/ico-ce/varzeaalegre-ce'},
        {link: '/passagem-de-onibus/ico-ce/brejosanto-ce'},
        {link: '/passagem-de-onibus/ico-ce/seabra-ba'},
        {link: '/passagem-de-onibus/ico-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/ico-ce/lavrasdamangabeira-ce'},
        {link: '/passagem-de-onibus/ico-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/ico-ce/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/ico-ce/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/ico-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/ico-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/ico-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/ico-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/ico-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/curraisnovos-rn/brasilia-df'},
        {link: '/passagem-de-onibus/curraisnovos-rn/brasilia-df'},
        {link: '/passagem-de-onibus/curraisnovos-rn/sobradinho-df-df'},
        {link: '/passagem-de-onibus/marcolandia-pi/araripina-pe'},
        {link: '/passagem-de-onibus/marcolandia-pi/caruaru-pe'},
        {link: '/passagem-de-onibus/marcolandia-pi/salgueiro-pe'},
        {link: '/passagem-de-onibus/marcolandia-pi/belojardim-pe'},
        {link: '/passagem-de-onibus/marcolandia-pi/custodia-pe'},
        {link: '/passagem-de-onibus/marcolandia-pi/ouricuri-pe'},
        {link: '/passagem-de-onibus/ico-ce/boqueiraodocesario-ce'},
        {link: '/passagem-de-onibus/ico-ce/banabuiu-ce'},
        {link: '/passagem-de-onibus/ico-ce/cedro-ce'},
        {link: '/passagem-de-onibus/ico-ce/crato-ce'},
        {link: '/passagem-de-onibus/ico-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/ico-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/ico-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/ico-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/ico-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/ico-ce/cedro-ce'},
        {link: '/passagem-de-onibus/ico-ce/crato-ce'},
        {link: '/passagem-de-onibus/ico-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/ico-ce/fariasbrito-ce'},
        {link: '/passagem-de-onibus/ico-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/ico-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/ico-ce/anapolis-go'},
        {link: '/passagem-de-onibus/ico-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/ico-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/ico-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/seabra-ba'},
        {link: '/passagem-de-onibus/teofilootoni-mg/campinagrande-pb'},
        {link: '/passagem-de-onibus/peritoro-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/santoantoniodoslopes-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/belem-pa'},
        {link: '/passagem-de-onibus/peritoro-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/peritoro-ma/codo-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/colinas-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/campomaior-pi'},
        {link: '/passagem-de-onibus/peritoro-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/peritoro-ma/capinzaldonorte-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/dompedro-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/crato-ce'},
        {link: '/passagem-de-onibus/peritoro-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/peritoro-ma/joaopessoa-pb'},
        {link: '/passagem-de-onibus/peritoro-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/maraba-pa'},
        {link: '/passagem-de-onibus/peritoro-ma/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/limoeirodonorte-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/luiseduardomagalhaes-ba'},
        {link: '/passagem-de-onibus/brejosanto-ce/senhordobonfim-ba'},
        {link: '/passagem-de-onibus/brejosanto-ce/mauriti-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/milagres,ce-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/barbalha-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/petrolina-pe'},
        {link: '/passagem-de-onibus/brejosanto-ce/pacajus-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/peritoro-ma/caxias-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/sobral-ce'},
        {link: '/passagem-de-onibus/peritoro-ma/saodomingosdomaranhao-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/saojoaodospatos-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/peritoro-ma/mossoro-rn'},
        {link: '/passagem-de-onibus/peritoro-ma/santarita-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/campinagrande-pb'},
        {link: '/passagem-de-onibus/peritoro-ma/patos-pb'},
        {link: '/passagem-de-onibus/peritoro-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/brejosanto-ce/ipaumirim-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/russas-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/rodavelha-ba'},
        {link: '/passagem-de-onibus/brejosanto-ce/santamariadaboavista-pe'},
        {link: '/passagem-de-onibus/brejosanto-ce/brasilia-df'},
        {link: '/passagem-de-onibus/brejosanto-ce/brasilia-df'},
        {link: '/passagem-de-onibus/brejosanto-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/brejosanto-ce/serratalhada-pe'},
        {link: '/passagem-de-onibus/brejosanto-ce/ibotirama-ba'},
        {link: '/passagem-de-onibus/brejosanto-ce/umari-ce'},
        {link: '/passagem-de-onibus/peritoro-ma/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/peritoro-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/teresina-pi'},
        {link: '/passagem-de-onibus/peritoro-ma/sousa-pb'},
        {link: '/passagem-de-onibus/peritoro-ma/pastosbons-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/presidentedutra-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/piripiri-pi'},
        {link: '/passagem-de-onibus/peritoro-ma/picos-pi'},
        {link: '/passagem-de-onibus/peritoro-ma/paraibano-ma'},
        {link: '/passagem-de-onibus/brejosanto-ce/salgueiro-pe'},
        {link: '/passagem-de-onibus/brejosanto-ce/baixio-ce-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/capimgrosso-ba'},
        {link: '/passagem-de-onibus/brejosanto-ce/cabrobo-pe'},
        {link: '/passagem-de-onibus/brejosanto-ce/missaovelha-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/horizonte-ce'},
        {link: '/passagem-de-onibus/brejosanto-ce/anapolis-go'},
        {link: '/passagem-de-onibus/brejosanto-ce/jaguaribe-ce'},
        {link: '/passagem-de-onibus/peritoro-ma/recife-pe'},
        {link: '/passagem-de-onibus/peritoro-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/natal-rn'},
        {link: '/passagem-de-onibus/peritoro-ma/maceio-al'},
        {link: '/passagem-de-onibus/santos-sp/campinagrande-pb'},
        {link: '/passagem-de-onibus/santos-sp/floriano-pi'},
        {link: '/passagem-de-onibus/ananindeua-pa/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/codo-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/bacabal-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/governadornunesfreire-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/santaines-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/maracacume-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/teresina-pi'},
        {link: '/passagem-de-onibus/ananindeua-pa/novaolindadomaranhao-ma'},
        {link: '/passagem-de-onibus/ibiapina-ce/tiangua-ce'},
        {link: '/passagem-de-onibus/santos-sp/coloniadogurgueia-pi'},
        {link: '/passagem-de-onibus/santos-sp/campomaior-pi'},
        {link: '/passagem-de-onibus/santos-sp/corrente-pi'},
        {link: '/passagem-de-onibus/santos-sp/eliseumartins-pi'},
        {link: '/passagem-de-onibus/santos-sp/fortaleza-ce'},
        {link: '/passagem-de-onibus/santos-sp/joaopessoa-pb'},
        {link: '/passagem-de-onibus/santos-sp/montealegredopiaui-pi'},
        {link: '/passagem-de-onibus/santos-sp/teresina-pi'},
        {link: '/passagem-de-onibus/santos-sp/sobral-ce'},
        {link: '/passagem-de-onibus/timon-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/timon-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/feiradesantana-ba/caruaru-pe'},
        {link: '/passagem-de-onibus/feiradesantana-ba/belojardim-pe'},
        {link: '/passagem-de-onibus/feiradesantana-ba/fortaleza-ce'},
        {link: '/passagem-de-onibus/feiradesantana-ba/joaopessoa-pb'},
        {link: '/passagem-de-onibus/feiradesantana-ba/recife-pe'},
        {link: '/passagem-de-onibus/feiradesantana-ba/arcoverde-pe'},
        {link: '/passagem-de-onibus/feiradesantana-ba/pesqueira-pe'},
        {link: '/passagem-de-onibus/feiradesantana-ba/petrolandia-pe'},
        {link: '/passagem-de-onibus/ibiapina-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/sobral-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/coreau-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/ibiapina-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/umirim-ce'},
        {link: '/passagem-de-onibus/timon-ma/codo-ma'},
        {link: '/passagem-de-onibus/timon-ma/campomaior-pi'},
        {link: '/passagem-de-onibus/timon-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/timon-ma/custodia-pe'},
        {link: '/passagem-de-onibus/timon-ma/crato-ce'},
        {link: '/passagem-de-onibus/timon-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/timon-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/timon-ma/joaopessoa-pb'},
        {link: '/passagem-de-onibus/timon-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/timon-ma/maraba-pa'},
        {link: '/passagem-de-onibus/timon-ma/caxias-ma'},
        {link: '/passagem-de-onibus/timon-ma/sobral-ce'},
        {link: '/passagem-de-onibus/timon-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/timon-ma/santarita-ma'},
        {link: '/passagem-de-onibus/timon-ma/serratalhada-pe'},
        {link: '/passagem-de-onibus/timon-ma/campinagrande-pb'},
        {link: '/passagem-de-onibus/timon-ma/patos-pb'},
        {link: '/passagem-de-onibus/timon-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/timon-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/timon-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/timon-ma/juazeirodonorte-ce'},
        {link: '/passagem-de-onibus/timon-ma/recife-pe'},
        {link: '/passagem-de-onibus/timon-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/timon-ma/natal-rn'},
        {link: '/passagem-de-onibus/timon-ma/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/timon-ma/sousa-pb'},
        {link: '/passagem-de-onibus/timon-ma/ouricuri-pe'},
        {link: '/passagem-de-onibus/timon-ma/arcoverde-pe'},
        {link: '/passagem-de-onibus/timon-ma/piripiri-pi'},
        {link: '/passagem-de-onibus/timon-ma/picos-pi'},
        {link: '/passagem-de-onibus/ananindeua-pa/caxias-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/peritoro-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/timon-ma'},
        {link: '/passagem-de-onibus/ananindeua-pa/zedoca-ma'},
        {link: '/passagem-de-onibus/timon-ma/araripina-pe'},
        {link: '/passagem-de-onibus/timon-ma/caruaru-pe'},
        {link: '/passagem-de-onibus/timon-ma/belem-pa'},
        {link: '/passagem-de-onibus/timon-ma/salgueiro-pe'},
        {link: '/passagem-de-onibus/timon-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/peritoro-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/peritoro-ma/timon-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/saoluis-ma'},
        {link: '/passagem-de-onibus/peritoro-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/ibiapina-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/zedoca-ma/teresina-pi'},
        {link: '/passagem-de-onibus/zedoca-ma/picos-pi'},
        {link: '/passagem-de-onibus/zedoca-ma/santamariadopara-pa'},
        {link: '/passagem-de-onibus/zedoca-ma/ananindeua-pa'},
        {link: '/passagem-de-onibus/tiangua-ce/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/carnaubal-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/belem-pa'},
        {link: '/passagem-de-onibus/tiangua-ce/buriticupu-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/castanhal-pa'},
        {link: '/passagem-de-onibus/tiangua-ce/bacabal-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/camocim-ce'},
        {link: '/passagem-de-onibus/saoluis-ma/chapadinha-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/chaval-ce'},
        {link: '/passagem-de-onibus/saoluis-ma/bacabal-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/bacabal-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/colinas-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/campomaior-pi'},
        {link: '/passagem-de-onibus/saoluis-ma/capinzaldonorte-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/dompedro-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/saomateusdomaranhao-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/feiradesantana-ba/sertania-pe'},
        {link: '/passagem-de-onibus/saoluis-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/altoalegredomaranhao-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/vitoriadomearim-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/anapurus-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/santoantoniodoslopes-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/barreirinhas-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/baraodegrajau-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/brejo-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/codo-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/piripiri-pi'},
        {link: '/passagem-de-onibus/saoluis-ma/pindare-mirim-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/parnaiba-pi'},
        {link: '/passagem-de-onibus/saoluis-ma/paraibano-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/paulinoneves-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/caxias-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/sobral-ce'},
        {link: '/passagem-de-onibus/saoluis-ma/saobernardo-'},
        {link: '/passagem-de-onibus/saoluis-ma/saodomingosdomaranhao-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/saojoaodospatos-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/santaines-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/arari-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/igarapedomeio-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/itapecuru-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/mirandadonorte-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/matoesdonorte-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/teresina-pi'},
        {link: '/passagem-de-onibus/saoluis-ma/pastosbons-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/presidentedutra-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/acailandia-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/campomaior-pi'},
        {link: '/passagem-de-onibus/tiangua-ce/saobenedito-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/capanema,pa-pa'},
        {link: '/passagem-de-onibus/tiangua-ce/ubajara-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/fortaleza-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/forquilha-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/frecheirinha-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/guaraciabadonorte-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/santaines-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/santaluzia,ma-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/itapaje-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/osasco-sp'},
        {link: '/passagem-de-onibus/tiangua-ce/iraucuba-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/imperatriz-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/maraba-pa'},
        {link: '/passagem-de-onibus/tiangua-ce/natal-rn'},
        {link: '/passagem-de-onibus/tiangua-ce/teresina-pi'},
        {link: '/passagem-de-onibus/saoluis-ma/bacabeira-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/peritoro-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/timon-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/tiangua-ce'},
        {link: '/passagem-de-onibus/zedoca-ma/belem-pa'},
        {link: '/passagem-de-onibus/zedoca-ma/castanhal-pa'},
        {link: '/passagem-de-onibus/zedoca-ma/capanema,pa-pa'},
        {link: '/passagem-de-onibus/zedoca-ma/fortaleza-ce'},
        {link: '/passagem-de-onibus/saoluis-ma/santaquiteriadomaranhao-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/santarita-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/tutoia-ma'},
        {link: '/passagem-de-onibus/saoluis-ma/vargemgrande-ma'},
        {link: '/passagem-de-onibus/seabra-ba/pombal-pb'},
        {link: '/passagem-de-onibus/seabra-ba/russas-ce'},
        {link: '/passagem-de-onibus/seabra-ba/campinagrande-pb'},
        {link: '/passagem-de-onibus/seabra-ba/patos-pb'},
        {link: '/passagem-de-onibus/seabra-ba/ico-ce'},
        {link: '/passagem-de-onibus/seabra-ba/brejosanto-ce'},
        {link: '/passagem-de-onibus/saobentinho-pb/aparecida-pb-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/cajazeirinhas-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/catoledorocha-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/condado-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/coremas-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/joaopessoa-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/juncodoserido-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/juazeirinho-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/marizopolis-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/sousa-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/saojosedalagoatapada-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/malta-pb-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/pombal-pb'},
        {link: '/passagem-de-onibus/tiangua-ce/santos-sp'},
        {link: '/passagem-de-onibus/tiangua-ce/timon-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/saoluis-ma'},
        {link: '/passagem-de-onibus/seabra-ba/barro-ce'},
        {link: '/passagem-de-onibus/seabra-ba/salgueiro-pe'},
        {link: '/passagem-de-onibus/seabra-ba/fortaleza-ce'},
        {link: '/passagem-de-onibus/seabra-ba/jaguaribe-ce'},
        {link: '/passagem-de-onibus/seabra-ba/cajazeiras,pb-pb'},
        {link: '/passagem-de-onibus/seabra-ba/petrolina-pe'},
        {link: '/passagem-de-onibus/seabra-ba/sousa-pb'},
        {link: '/passagem-de-onibus/tiangua-ce/santamariadopara-pa'},
        {link: '/passagem-de-onibus/tiangua-ce/brasilia-df'},
        {link: '/passagem-de-onibus/tiangua-ce/brasilia-df'},
        {link: '/passagem-de-onibus/tiangua-ce/sobradinho-df-df'},
        {link: '/passagem-de-onibus/tiangua-ce/mossoro-rn'},
        {link: '/passagem-de-onibus/tiangua-ce/umirim-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/vicosadoceara-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/peritoro-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/ibiapina-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/piripiri-pi'},
        {link: '/passagem-de-onibus/tiangua-ce/planaltina,df-df'},
        {link: '/passagem-de-onibus/tiangua-ce/parnaiba-pi'},
        {link: '/passagem-de-onibus/tiangua-ce/piracuruca-pi'},
        {link: '/passagem-de-onibus/tiangua-ce/caxias-ma'},
        {link: '/passagem-de-onibus/tiangua-ce/sobral-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/sobral-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/coreau-ce'},
        {link: '/passagem-de-onibus/tiangua-ce/saogoncalodoamarante-rn'},
        {link: '/passagem-de-onibus/tiangua-ce/saoluisdocuru-ce'},
        {link: '/passagem-de-onibus/saobentinho-pb/uirauna-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/soledade-pb-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/santaluzia-pb-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/saomamede-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/campinagrande-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/patos-pb'},
        {link: '/passagem-de-onibus/saobentinho-pb/patos-pb'}
];
export default expressoGuanabara;