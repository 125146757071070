import {Button, Modal, Table} from 'react-bootstrap';
import executivo from '../../estilo/comum/img/descricaoPoltronas/poltrona-executivo.svg';
import semiLeito from '../../estilo/comum/img/descricaoPoltronas/semi-leito.svg';
import leito from '../../estilo/comum/img/descricaoPoltronas/leito.svg';
import leitoIndividual from '../../estilo/comum/img/descricaoPoltronas/leito.svg';
import cama from '../../estilo/comum/img/descricaoPoltronas/leito-cama.svg';
import camaPremium from '../../estilo/comum/img/descricaoPoltronas/cama-premium.svg';
import React from 'react';

export class ModalDescricaoPoltronas extends React.Component {
    render() {
        return <Modal show={this.props.showModal} onHide={this.props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Descrição das Poltronas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <tbody>
                    <tr>
                        <td className='text-center align-middle'><img src={executivo}></img>
                        </td>
                        <td className='text-center align-middle'>Executivo</td>
                        <td className='text-justify align-middle'>As poltronas reclinam até
                            135º, possuem apoio para os pés e o apoio para os braços é
                            compartilhado.
                        </td>
                    </tr>
                    <tr>
                        <td className='text-center align-middle'><img src={semiLeito}></img>
                        </td>
                        <td className='text-center align-middle'>Semi-leito</td>
                        <td className='text-justify align-middle'>As poltronas reclinam até
                            145°, possuem descanso para as pernas e o apoio para os braços é
                            compartilhado.
                        </td>
                    </tr>
                    <tr>
                        <td className='text-center align-middle'><img src={leito}></img></td>
                        <td className='text-center align-middle'>Leito</td>
                        <td className='text-justify align-middle'>Outro nível de conforto.
                            Poltronas mais largas que reclinam até 150°, possuem descanso para
                            as pernas e o apoio para os braços é individual.
                        </td>
                    </tr>
                    <tr>
                        <td className='text-center align-middle'><img
                            src={leitoIndividual}></img></td>
                        <td className='text-center align-middle'>Leito Individual</td>
                        <td className='text-justify align-middle'>Aqui a sua viagem é muito mais
                            confortável. Poltronas particulares, mais longas e largas e que
                            reclinam até 150º. Possuem descanso para as pernas e apoio
                            individual para os braços.
                        </td>
                    </tr>
                    <tr>
                        <td className='text-center align-middle'><img src={cama}></img></td>
                        <td className='text-center align-middle'>Cama</td>
                        <td className='text-justify align-middle'>Poltronas reclinam até 180° e
                            o apoio para as pernas fica na mesma altura da poltrona, tornando-se
                            uma verdadeira cama sobre rodas.
                        </td>
                    </tr>
                    <tr>
                        <td className='text-center align-middle'><img src={camaPremium}></img>
                        </td>
                        <td className='text-center align-middle'>Cama Premium</td>
                        <td className='text-justify align-middle'>Nível máximo de conforto
                            possível. Além de toda a comodidade que a Cama oferece, a Premium
                            também possui poltronas com massageador e tela individual com
                            streaming.
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button variant='secondary' onClick={this.props.handleClose}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    }

}


export default ModalDescricaoPoltronas
