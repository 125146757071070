import React, {Component} from 'react';
import {BuscaPassagem} from './passagem/BuscaPassagem';
import {withRouter} from 'react-router-dom';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import Button from '@material-ui/core/Button';

export class PagSolicitarGratuidade extends Component {

    render() {
        return (
            <div className='conteudo'>
                <div>
                    <BuscaPassagem/>
                </div>
                <div className='col-12 conteudo-internas'>
                    <div className='card border-0'>
                        <div className='card-body'>
                            <div className='loginHeader'>
                                <h1 id='quem-somos'>Solicitar Gratuidade</h1>
                                <div className="divider"/>
                            </div>
                            <div className="page-contato" style={{fontSize: 16, backgroundColor: '#ffffff'}}>
                                <section className="gv-section">
                                    <div className="section-content">
                                        <h2 className="gv-title">Agendamento de Passagem com Benefícios</h2>
                                        <p className="txt-item">
                                            Se você é portador de algum dos Benefícios previstos por lei e
                                            quer adquirir um bilhete de ônibus conosco,
                                            acompanharemos você através do processo!
                                        </p>
                                        <p className="txt-item">
                                            Conforme a determinação ANTT Nº 6.033, datada de 21.12.2023,
                                            iniciamos a emissão de passagens que oferecem isenções para <b>idosos,
                                            detentores do Passe Livre e portadores da ID Jovem.</b>
                                        </p>
                                        <p className="txt-item">
                                            Estas isenções aplicam-se exclusivamente a:
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            • Percursos INTERESTADUAIS;
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            • Categoria de assento CONVENCIONAL;
                                        </p>

                                        <h2 className="gv-title" style={{marginTop: 24}}>Benefícios disponíveis por
                                            ônibus::</h2>
                                        <p className="txt-item">
                                            <br/>
                                            • 2 assentos com 100% de desconto para pessoas idosas.
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            • 2 assentos com 100% de desconto para pessoas portadoras de ID Jovem.
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            • Assentos Ilimitados com 100% de desconto para pessoas portadoras do Passe
                                            livre e acompanhantes.
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            • Assentos ilimitados com 50% de desconto para pessoas idosas.
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            • 2 Assentos com 50% de desconto para pessoas portadoras de ID Jovem.
                                        </p>

                                        <h2 className="gv-title" style={{marginTop: 24}}>Quanto aos assentos:</h2>
                                        <p className="txt-item">
                                            Atualmente, é possível requisitar apenas um assento por trajeto,
                                            e a atribuição será baseada na disponibilidade no momento em que
                                            seu pedido for processado. Fique tranquilo, providenciaremos a
                                            reserva conforme a disponibilidade em cada itinerário.
                                        </p>

                                        <h2 className="gv-title" style={{marginTop: 24}}>Quanto a viagem:</h2>
                                        <p className="txt-item">
                                            • Cada pedido corresponde a um segmento da viagem. Portanto, para
                                            itinerários
                                            de ida e volta, serão necessários dois pedidos distintos: um para a ida e
                                            outro para a volta, garantindo assim nossa capacidade de atender de acordo
                                            com a disponibilidade de assentos para cada segmento.
                                        </p>
                                        <p className="txt-item">
                                            • Você deve solicitar a passagem minimamente 3 dias úteis antes da viagem.
                                        </p>

                                        <h2 className="gv-title" style={{marginTop: 24}}>Categorias de Benefícios:</h2>
                                        <p className="txt-item">
                                            <br/>
                                            <b>
                                                Pessoas Idosas:
                                            </b>
                                            <br/>
                                        </p>
                                        <p className="txt-item">
                                            Elegível para maiores de 60 anos com uma renda mensal de até dois
                                            salários mínimos.
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            <b>
                                                É exigido enviar:
                                            </b>
                                            <br/>
                                        </p>
                                        <p className="txt-item">
                                            • Um documento oficial com foto e CPF (RG ou CNH).
                                        </p>
                                        <p className="txt-item">
                                            • Um dos comprovantes de renda a seguir:
                                        </p>
                                        <p className="txt-item">
                                            ◦ Última atualização da Carteira de Trabalho e Previdência Social;
                                        </p>
                                        <p className="txt-item">
                                            ◦ Um contracheque ou documento fornecido pelo empregador;
                                        </p>
                                        <p className="txt-item">
                                            ◦ Carnê do INSS;
                                        </p>
                                        <p className="txt-item">
                                            ◦ Demonstrativo de recebimento do INSS ou outra previdência;
                                        </p>
                                        <p className="txt-item">
                                            ◦ Documento ou carteira de assistência social de âmbito estadual ou
                                            municipal.
                                        </p>

                                        <p className="txt-item">
                                            <br/>
                                            <b>
                                                PESSOAS PORTADORAS de ID Jovem:
                                            </b>
                                            <br/>
                                        </p>
                                        <p className="txt-item">
                                            Exclusivo para detentores da identificação ID Jovem vigente.
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            <b>
                                                É exigido enviar:
                                            </b>
                                            <br/>
                                        </p>
                                        <p className="txt-item">
                                            • Um documento oficial com foto e CPF (RG ou CNH).
                                        </p>
                                        <p className="txt-item">
                                            • Cartão ID Jovem válido (frente e verso).
                                        </p>

                                        <p className="txt-item">
                                            <br/>
                                            <b>
                                                PESSOAS PORTADORAS de PASSE LIVRE e ACOMPANHANTES:
                                            </b>
                                            <br/>
                                        </p>
                                        <p className="txt-item">
                                            Exclusivo para quem possui o documento Passe Livre em vigor.
                                        </p>
                                        <p className="txt-item">
                                            <br/>
                                            <b>
                                                É exigido enviar:
                                            </b>
                                            <br/>
                                        </p>
                                        <p className="txt-item">
                                            • Um documento oficial com foto e CPF (RG ou CNH).
                                        </p>
                                        <p className="txt-item">
                                            • Documento Passe Livre válido (frente e verso).
                                        </p>

                                        <p className="txt-item">
                                            Após o envio do formulário com todos os campos preenchidos e
                                            envio documentos necessários, faremos a análise do pedido e
                                            entraremos em contato via e-mail em até <b>3 dias úteis</b> com a
                                            confirmação ou recusa de sua solicitação de viagem.
                                        </p>

                                        <div className='card-body'>
                                            <Button id='buttonSolicitarGratuidade' variant="primary"
                                                    href="https://docs.google.com/forms/d/e/1FAIpQLSe5Vz_3VcHnFRAPokthDw7Kbb259mSxqFbfsyHNlMxr_FNchw/viewform"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <span id='SolicitarGratuidade'>
                                                        Solicite sua gratuidade aqui!
                                                    </span>
                                            </Button>
                                        </div>

                                        <section className="gv-cards" style={{marginTop: 50}}>
                                            <div className="gv-cards-wrapper">
                                                <div className="gv-card gv-card-qs">
                                                    <div className="nome-card">
                                                        <span className="box-item-card-title">
                                                            <DevicesOutlinedIcon
                                                                className="box-item-icon"></DevicesOutlinedIcon>
                                                            <p>Tecnologia</p>
                                                            <div className="divider"/>
                                                        </span>
                                                        <span className="box-item-card-info">
                                                            <p>Descomplicamos o jeito antigo e complicado de comprar uma passagem!</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="gv-card gv-card-qs">
                                                    <div className="nome-card">
                                                        <span className="box-item-card-title">
                                                            <EmojiObjectsOutlinedIcon
                                                                className="box-item-icon"></EmojiObjectsOutlinedIcon>
                                                                <p>Inovação</p>
                                                                <div className="divider"/>
                                                        </span>
                                                        <span className="box-item-card-info">
                                                            <p>Inovamos em um mercado promissor. Tecnologia simples, aplicada e segura para você, sua família e amigos.</p>
                                                         </span>
                                                    </div>
                                                </div>
                                                <div className="gv-card gv-card-qs">
                                                    <div className="nome-card">
                                                        <span className="box-item-card-title">
                                                          <PeopleAltOutlinedIcon
                                                              className="box-item-icon"></PeopleAltOutlinedIcon>
                                                          <p>Relacionamento</p>
                                                          <div className="divider"/>
                                                        </span>
                                                        <span className="box-item-card-info">
                                                          <p>Pensamos em você muito mais que um simples cliente, temos você como parte de nossa empresa. Sem você não teríamos motivos para estar aqui!</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="gv-card gv-card-qs">
                                                    <div className="nome-card">
                                                        <span className="box-item-card-title">
                                                          <TrendingUpOutlinedIcon
                                                              className="box-item-icon"></TrendingUpOutlinedIcon>
                                                          <p>Agilidade</p>
                                                          <div className="divider"/>
                                                        </span>
                                                        <span className="box-item-card-info">
                                                          <p>Rápido e descomplicado. Clicou, comprou, viajou!</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(PagSolicitarGratuidade);