import React from 'react'
import {buscarMeusPedidos} from '../../actions/pedidosActions';
import {connect} from 'react-redux';
import moment from 'moment';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import MeuPedido from './MeuPedido';
registerLocale('pt', pt);
setDefaultLocale('pt');

export class PaginaMeusPedidos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {abaSelecionada: 'proximas'};
    }

    componentDidMount() {
        this.props.buscarMeusPedidos();
    }

    exibirTabelaPedidos() {
        const viagens = this.props.meusPedidos.filter(pedido => {
            let dataPedido = moment(pedido.dataViagem).format('DD/MM/YYYY HH:mm');
            if (this.state.abaSelecionada === 'proximas') {
                return moment(dataPedido,'DD/MM/YYYY HH:mm').isAfter(moment()) && (pedido.situacaoPedido === 'APROVADO' || pedido.situacaoPedido === 'PENDENTE')
            }
            if(this.state.abaSelecionada === 'outras') {
                return pedido.situacaoPedido === 'CANCELADO' || pedido.situacaoPedido === 'EM_ABERTO'
            }
            return moment(dataPedido,'DD/MM/YYYY HH:mm').isBefore(moment()) && (pedido.situacaoPedido === 'APROVADO' || pedido.situacaoPedido === 'PENDENTE')
        }).map((pedido) => <MeuPedido pedido={pedido} key={pedido.id}/>);
        if (viagens.length) {
            return viagens
        }
        return <div className="alert alert-warning text-center mt-4" role="alert">
            {this.state.abaSelecionada === 'proximas' ? 'Você não possui nenhum pedido ativo' : 
                this.state.abaSelecionada === 'outras' ? 'Você não possui nenhuma outra viagem' :
                    'Você não possui nenhum pedido realizado'}
        </div>;
    }

    abaSelecionada(aba) {
        if (aba === this.state.abaSelecionada) {
            return 'nav-link active';
        }
        return 'nav-link';
    }

    render() {
        return (

            <div className="container">
                <h2 className='text-center titulo-reservas'>Meus pedidos</h2>
                <div>
                    <ul className="nav nav-tabs viagens-reservas">
                        <li className="nav-item" onClick={() => this.setState({abaSelecionada: 'proximas'})}>
                            <a className={this.abaSelecionada('proximas')} href="#">Viagens Futuras</a>
                        </li>
                        <li className="nav-item" onClick={() => this.setState({abaSelecionada: 'realizadas'})}>
                            <a className={this.abaSelecionada('realizadas')} href="#">Viagens realizadas</a>
                        </li>
                        <li className="nav-item" onClick={() => this.setState({abaSelecionada: 'outras'})}>
                            <a className={this.abaSelecionada('outras')} href="#">Outras viagens</a>
                        </li>
                    </ul>
                    {this.exibirTabelaPedidos()}
                </div>
            </div>
        )
    }
}


export const mapStateToProps = state => {
    return {
        meusPedidos: state.pedido.meusPedidos,
    }};


export default connect(mapStateToProps,
    {
        buscarMeusPedidos
    })(PaginaMeusPedidos)
