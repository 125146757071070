import React, {Component} from 'react'
import {recuperarConfEmpresa} from '../util/applicationContext';
const url = window.location.protocol + '//' + window.location.host;
class TermosUso extends Component {
    render() {
        let confEmpresa = recuperarConfEmpresa();
        let nomeEmpresa = confEmpresa.nome.toUpperCase();
        return (
            <div className='conteudo'>
                <div className='col-12 conteudo-internas'>
                    <div className='card border-0'>
                        <div className='card-body'>
                            <div className='loginHeader'>
                                <h1 id='secao-termos'>Termos de uso</h1>
                                <div className="divider"/>
                            </div>
                            <div className='card'>
                                <div className='card-body informacoes'>
                                    <strong><h1>TERMOS E CONDIÇÕES DE USO DOS SERVIÇOS OFERECIDOS PELA {nomeEmpresa}</h1></strong><br/>
                                    <p>Estes Termos e Condições de Uso apresentam as “Condições Gerais” aplicáveis ao
                                        uso dos serviços oferecidos pela {nomeEmpresa}, uma marca <strong>{confEmpresa.razaoSocial}</strong>, inscrita
                                        no CNPJ sob n.º {confEmpresa.cnpj}, com sede na {confEmpresa.endereco}, doravante denominada “{nomeEmpresa}”, o qual inclui o agendamento de passagens de ônibus e de trem e
                                        outros serviços relacionados a viagens, por meio do site
                                        <a href={url} target="_blank"
                                           rel="noreferrer"> {url} </a> e de seus aplicativos para plataformas móveis, em
                                        conjunto denominados simplesmente “WEBSITE”.</p>
                                    <p>Este termo está estritamente validado conforme o Código de Defesa do Consumidor
                                        (Lei 8.078/90) e o Decreto 7.962/2013. Qualquer pessoa, legalmente capaz,
                                        denominada “CLIENTE”, que utilize os serviços do “{nomeEmpresa}”, deve
                                        aceitar este Termo de Utilização e todas as políticas e princípios que o
                                        regem.</p>
                                    <p>O TERMO DE UTILIZAÇÃO É INDISPENSÁVEL, sendo obrigatório para a UTILIZAÇÃO dos
                                        serviços deste Website. A utilização dos serviços oferecidos pelo “{nomeEmpresa}” implica na imediata aquiescência/anuência deste Termo e seu conteúdo.
                                        Este acordo constitui-se em documento exclusivo entre “{nomeEmpresa}” e o
                                        “CLIENTE”, substituindo-se, deste modo, todos os acordos, representações,
                                        garantias e entendimentos anteriores com relação ao “{nomeEmpresa}”, seus
                                        conteúdos e serviços fornecidos por ou através deste Website.</p>
                                    <p>As medidas de segurança no que diz respeito e utilização, deste termos estão em
                                        conformidade com o artigo 46 da Lei 13.709/2018</p>
                                    <p>Caso deseje se cadastrar, acessar e utilizar as demais páginas ou recursos deste
                                        website, leia atentamente as condições abaixo e confirme sua anuência clicando
                                        no campo “Concordo”, localizado ao final.</p>
                                    <p><strong>ATENÇÃO:</strong>O “CLIENTE” MENOR DE 18 ANOS DE IDADE SOMENTE PODERÁ
                                        EFETUAR O REGISTRO OU CADASTRO NESTE WEBSITE SE ESTIVER DEVIDAMENTE REPRESENTADO
                                        OU ASSISTIDO, CONFORME PREVISTO NOS ARTS. 1.634 E 1.690 DO CÓDIGO CIVIL, POR
                                        SEUS REPRESENTANTES OU ASSISTENTES LEGAIS, DEVENDO ESSES SER RESPONSÁVEIS NA
                                        ESFERA CÍVEL E ADMINISTRATIVAMENTE POR TODO E QUALQUER ATO PRATICADO PELO MENOR
                                        QUANDO DA UTILIZAÇÃO DO WEBSITE, BEM COMO NÃO SE ISENTA DAS EVENTUAIS PUNIÇÕES
                                        PENAIS AS QUAIS OS MENORES POSSAM PRATICAR PELO USO INDEVIDO</p>
                                    <p>O PASSAGEIRO MENOR DE 16 ANOS SÓ PODERÁ VIAJAR ACOMPANHADO DOS PAIS OU
                                        RESPONSÁVEL LEGAL. CASO NÃO EXISTA GRAU DE PARENTESCO ENTRE O MENOR E O
                                        ACOMPANHANTE, SERÁ NECESSÁRIA UMA AUTORIZAÇÃO JUDICIAL EXPRESSA EMITIDA PELO
                                        JUIZADO DE MENORES COM FIRMA RECONHECIDA EM CARTÓRIO. O MESMO VALE PARA AS
                                        VIAGENS DE MENORES DESACOMPANHADOS.</p>
                                    <p>A AUTORIZAÇÃO NÃO SERÁ NECESSÁRIA CASO O ACOMPANHANTE DO MENOR SEJA PARENTE
                                        ASCENDENTE OU COLATERAL, ATÉ TERCEIRO GRAU, MAIOR DE IDADE E HAJA COMPROVAÇÃO DO
                                        PARENTESCO POR MEIO DE DOCUMENTAÇÃO APTA A COMPROVAR A SITUAÇÃO COM VALIDADE
                                        VIGENTE.</p>
                                    <p>O PASSAGEIRO COM MAIS DE 16 ANOS PODERÁ VIAJAR SOZINHO, DESDE QUE TENHA EM MÃOS O
                                        DOCUMENTO DE IDENTIFICAÇÃO COM FOTO ORIGINAL OU CÓPIA AUTENTICADA.</p>
                                    <p><strong>1. CONSIDERANDO QUE:</strong></p>
                                    <p>1.1. A “{nomeEmpresa}” é especializada na venda online de passagens para
                                        milhares de destinos de viagens espalhados pelo Brasil, oferecendo conforto e
                                        comodidade para quem viaja com as principais empresas de viagem do Brasil e da
                                        América do Sul. A “{nomeEmpresa}” presta os serviços apenas de facilitação na
                                        compra de passagens, não realiza viagens e/ou não tem relação direta com as
                                        “EMPRESAS DE TRANSPORTE DE PASSAGEIROS”, cadastradas em nosso website; </p>
                                    <p>1.2. “CLIENTE” é quem irá adquirir as passagens para as cidades as quais a
                                        “{nomeEmpresa}” oferece rotas; </p>
                                    <p>1.3. O “CLIENTE” possui total conhecimento que a “{nomeEmpresa}” não tem
                                        qualquer responsabilidade a partir da emissão do bilhete nos balcões de
                                        atendimento dos terminais rodoviários. Sejam estas, mas não se limitando à
                                        incorreção na emissão do bilhete, danos/extravios de bagagens, acidentes de
                                        percurso, desvios de rota e/ou qualquer outro dano que a “{nomeEmpresa}” não
                                        atue diretamente, bem como não tem qualquer responsabilidade sobre eventuais
                                        perdas e danos que o cliente possa ter junto a empresa;</p>
                                    <p>1.4. “EMPRESAS DE TRANSPORTE DE PASSAGEIROS” são companhias que realizam serviços
                                        de transportes de passageiros, existentes na base da “{nomeEmpresa}”,
                                        definidas na página principal do domínio de propriedade da “{nomeEmpresa}”,
                                        website. As “EMPRESAS DE TRANSPORTE DE PASSAGEIROS” assumem toda a
                                        responsabilidade por qualquer dano a partir da emissão do bilhete;</p>
                                    <p>1.5. “ANTT”: A Agência Nacional de Transportes Terrestres (ANTT) é uma autarquia
                                        federal brasileira responsável pela regulação das atividades de exploração da
                                        infraestrutura ferroviária e rodoviária federal e de prestação de serviços de
                                        transporte terrestre. A ANTT possui regulamento próprio e pela especificidade
                                        substitui obrigatoriedades de outros dispositivos;</p>
                                    <p>1.6. “ANTAQ”: A Agência Nacional de Transportes Aquaviários (ANTAQ) é uma
                                        autarquia federal brasileira responsável pela regulação das atividades de
                                        exploração da infraestrutura aquaviária federal e de prestação de serviços de
                                        transporte aquaviários. A ANTAQ possui regulamento próprio e pela especificidade
                                        substitui obrigatoriedades de outros dispositivos;</p>
                                    <p><strong>2. TERMOS E CONDIÇÕES DE UTILIZAÇÃO:</strong></p>
                                    <p>2.1. Ao efetuar compra na “{nomeEmpresa}” o “CLIENTE” acorda automaticamente
                                        com os Termos e Condições de Uso aqui explícitos. Caso o “CLIENTE” não esteja de
                                        acordo com os Termos e Condições de Uso, não deverá efetuar nenhum tipo de
                                        compra no website;</p>
                                    <p>2.2. A “{nomeEmpresa}” não garante que seu conteúdo seja apropriado ou esteja
                                        disponível para uso em outras localidades fora do território brasileiro; também
                                        se isenta de responsabilidades pelo acesso ao website em territórios onde seu
                                        conteúdo seja ilegal, recaindo inteiramente o ônus de utilização sobre o
                                        “CLIENTE”;</p>
                                    <p>2.3. Destarte, ao optar pelo acesso ao “{nomeEmpresa}”, o “CLIENTE”
                                        compreende, reconhece e concorda que sua utilização é regida pelo ordenamento
                                        jurídico da República Federativa do Brasil em relação à conduta online e
                                        conteúdo disponibilizado, bem como aos termos de cancelamento;</p>
                                    <p>2.4. As informações sobre os serviços estarão organizadas no website de forma
                                        clara, precisa e em português, fazendo constar todas as informações referentes
                                        aos tipos de passagens e reservas disponíveis;</p>
                                    <p>2.5. O uso deste website restringe-se ao privado, sendo vedada sua utilização
                                        comercial com fins lucrativos por terceiros não autorizados; bem como também é
                                        proibido e protegido pela Lei nº. 13/709/2018, o uso de qualquer software ou
                                        sistema automatizado para extrair dados deste website para a exibição em
                                        qualquer outro lugar do mundo sem o consentimento expresso de seus
                                        proprietários;</p>
                                    <p>2.6. A “ {nomeEmpresa}” compromete-se em manter seu website no ar, durante 24
                                        (vinte e quatro) horas por dia sem interrupção, salvo a impossibilidade de
                                        funcionamento integral e ininterrupto do sistema de telecomunicação ou de
                                        informática, por eventos causados nos casos fortuitos ou de força maior, nesta
                                        situação de fragilidade também se incluindo, dado sua complexidade, a
                                        dependência de serviços de telecomunicações prestados por terceiros, razão pelo
                                        qual não se garante, de forma nenhuma, a prestação do serviço de forma
                                        ininterrupta e/ou isenta de erros;</p>
                                    <p>2.7. Conforme legislação protetiva do Código de Defesa do Consumidor e a
                                        legislação específica da ANTT, é assegurado ao “CLIENTE” o direito de
                                        arrependimento, desde que este ocorra no prazo de 48 horas (quarenta e oito
                                        horas) antes da realização da viagem, ressalvados os casos em que o serviço
                                        apresente alguma informação incorreta de culpa exclusiva da “{nomeEmpresa}”,
                                        o que autoriza o “CLIENTE” a desfazer o negócio com a “{nomeEmpresa}”;</p>
                                    <p>2.8. A “{nomeEmpresa}” isenta-se da responsabilidade por qualquer erro ou
                                        atraso na remoção de conteúdo impreciso, ilícito ou censurável proveniente ou
                                        fornecido por terceiros por meio de nosso website. A equipe da “{nomeEmpresa}” pode mudar, a qualquer tempo e sem aviso prévio, o conteúdo e
                                        funcionalidades do website;</p>
                                    <p>2.9. A aquisição do(s) serviços(s) pelo “CLIENTE” será finalizada e concluída,
                                        após o devido pagamento que se dará por meio da prestação de serviço de empresa
                                        responsável por gestão de pagamentos. A “{nomeEmpresa}” não poderá intervir
                                        nos resultados da relação do “CLIENTE” com a empresa responsável pela gestão de
                                        pagamentos, uma vez que esta administra suas operações de forma
                                        independente;</p>
                                    <p>2.10. A “{nomeEmpresa}” não pode garantir de forma alguma que os serviços
                                        prestados pela empresa responsável pela gestão de pagamentos funcionarão livres
                                        de erros, interrupções, mau funcionamento, atrasos ou outras imperfeições.
                                        Ressalte-se que não será responsável pela disponibilidade ou não dos serviços
                                        prestados pela empresa responsável pela gestão de pagamentos ou pela
                                        impossibilidade do uso do serviço;</p>
                                    <p>2.11. A “{nomeEmpresa}” poderá, a qualquer tempo, alterar o presente
                                        instrumento visando o aprimoramento, bem como a melhoria dos serviços prestados.
                                        O novo instrumento contratual entrará em vigor, sem prejuízo dos serviços
                                        veiculados na vigência do contrato anterior. Assim, a “{nomeEmpresa}”
                                        recomenda que o “CLIENTE” tenha o costume de reler atentamente os termos e
                                        condições de uso sempre que acessar o website ou realizar qualquer operação por
                                        meio deste;</p>
                                    <p>2.12. A “{nomeEmpresa}” tomará todas as medidas possíveis para manter a
                                        confidencialidade, segurança e sigilo atinentes às informações dos “CLIENTES”,
                                        porém não responderá por prejuízo que possa ser derivado da violação dessas
                                        medidas por parte de terceiros que utilizem as redes públicas ou a internet,
                                        subvertendo os sistemas de segurança para acessar as informações de
                                        “CLIENTES”;</p>
                                    <p>2.13. Com efeito, preocupado com a segurança e privacidade dos dados que trafegam
                                        no website, a “{nomeEmpresa}” declara que a senha de acesso e todos os dados
                                        que o “CLIENTE” digitar na página do website são protegidos, nos termos da Lei
                                        nº. 13.709/2018. A “{nomeEmpresa}” recomenda que o “CLIENTE” nunca forneça
                                        sua senha a terceiros e caso seja perdida deverá efetuar a comunicação a empresa
                                        de forma imediata. O “CLIENTE” é integralmente responsável pelo sigilo e
                                        utilização de sua senha e identidade conforme a Política de Privacidade;</p>
                                    <p>2.14. A “{nomeEmpresa}” não será responsável por qualquer dano, prejuízo ou
                                        perda no equipamento do “CLIENTE” causado por falhas no sistema, no servidor ou
                                        na internet decorrentes de condutas de terceiros, ressalvado que, eventualmente,
                                        o sistema poderá não estar disponível por motivos técnicos ou falhas da
                                        internet, ou por qualquer outro evento fortuito ou de força maior alheio ao seu
                                        controle.</p>
                                    <p><strong>3. OBJETO DO CONTRATO:</strong></p>
                                    <p>3.1. Constitui objeto deste contrato a facilitação, por meio de ferramenta
                                        própria da “{nomeEmpresa}”, das compras de passagens rodoviárias,
                                        hidroviárias e ferroviárias, oferecidas por EMPRESAS DE TRANSPORTE DE
                                        PASSAGEIROS de terceiros e disponibilizadas no website do “{nomeEmpresa}”,
                                        pelos “CLIENTES”.</p>
                                    <p><strong>4. DAS INFORMAÇÕES PARA A UTILIZAÇÃO DOS SERVIÇOS:</strong></p>
                                    <p>4.1. DO CADASTRO DO “CLIENTE”</p>
                                    <p>4.1.1. Não é condição essencial o cadastro do “CLIENTE” para a compra da passagem
                                        desejada, basta que siga o passo-a-passo constante no website.</p>
                                    <p>4.1.2. Quando disponível pela “{nomeEmpresa}”, o “CLIENTE” poderá se cadastrar
                                        e aproveitar as condições diferenciadas para cadastrados neste website. O
                                        cadastro será confirmado após o correto e completo preenchimento de todos os
                                        dados solicitados.</p>
                                    <p>4.1.3. Não será permitido ao “CLIENTE” ter mais de um cadastro e, caso isso
                                        ocorra, o “{nomeEmpresa}” desabilitará, automaticamente, após verificação dos
                                        dados, definitivamente todos os cadastros.</p>
                                    <p>4.1.4. Todo e qualquer “CLIENTE” menor de 18 anos deverá obter o consentimento
                                        expresso de seus pais ou representante legal antes de fornecer os seus endereços
                                        de e-mail ou quaisquer dados ao “{nomeEmpresa}” por meio deste website. Todas
                                        as informações fornecidas pelo “CLIENTE” devem ser exatas, precisas e
                                        verdadeiras e devidamente atualizadas, caso ocorra qualquer alteração,
                                        reconhecendo o “CLIENTE”, ainda, que a “{nomeEmpresa}” não tem a obrigação de
                                        verificar a precisão dos dados transmitidos pelo “CLIENTE” ou qualquer outra
                                        pessoa.</p>
                                    <p>4.1.5. Diante disso, a “{nomeEmpresa}” não se responsabilizará pela correção
                                        de dados pessoais inseridos pelo “CLIENTE”, sendo certo que o “CLIENTE” ou seus
                                        pais ou representantes legais, quando for o caso, garante e responde, em
                                        qualquer caso, pela veracidade, precisão e autenticidade dos dados pessoais
                                        cadastrados e ainda por qualquer cometimento de ato ilícito</p>
                                    <p>4.1.6. A “{nomeEmpresa}” não se responsabilizará em nenhuma hipótese por
                                        prejuízos de qualquer espécie, inclusive, mas sem limitação, àqueles decorrentes
                                        do uso ou extravio de informações individuais do “CLIENTE” por terceiros,
                                        decorrentes direta ou indiretamente do seu acesso ou navegação no website.</p>
                                    <p>4.1.7. A “{nomeEmpresa}”, a seu critério exclusivo e absoluto, pode alterar os
                                        critérios de qualificação e/ou exigências para a manutenção do cadastrado no
                                        website. Deste modo, o “{nomeEmpresa}” poderá imediatamente encerrar o
                                        registro do cadastrado no website, independentemente do pagamento de multa e/ou
                                        indenização, a qualquer título, ao “CLIENTE”, externando posteriormente a
                                        justificativa ou motivos.</p>
                                    <p>4.1.8. Fica ressalvado o direito do “CLIENTE” em retificar quaisquer dados
                                        enviados ao “{nomeEmpresa}”. Entretanto, o direito de retificação do
                                        “CLIENTE” não obstará ou substituirá o direito do “{nomeEmpresa}” em pleitear
                                        as indenizações cabíveis, no caso de informações errôneas ensejarem quaisquer
                                        danos e/ou prejuízos o “{nomeEmpresa}” ou a terceiros, no período anterior à
                                        sua retificação pelo “CLIENTE”.</p>
                                    <p>4.2. DO CANCELAMENTO DO CADASTRO DO “CLIENTE”</p>
                                    <p>4.2.1. Quando o “CLIENTE” estiver cadastrado e, consequentemente, recebendo as
                                        novidades, novos trechos e descontos do “{nomeEmpresa}”, este poderá
                                        requerer, a qualquer momento, o cancelamento do cadastro, desde que envie um
                                        e-mail formalizando o pedido para <a href={confEmpresa.lkemail} target="_blank"
                                                                             rel="noreferrer"> {confEmpresa.email} </a> e
                                        <a href={confEmpresa.lkemailSuporte} target="_blank"
                                           rel="noreferrer"> {confEmpresa.emailSuporte} </a>, de segunda à sexta-feira, entre 9h e 21h, exceto
                                        feriados.</p>
                                    <p><strong>5. DOS PASSOS PARA A COMPRA DA PASSAGEM</strong></p>
                                    <p>Para efetuar a compra de sua viagem através da “{nomeEmpresa}”, basta seguir
                                        os passos abaixo:</p>
                                    <p><strong>I. Das informações preliminares:</strong></p>
                                    <p>a) Inicialmente basta preencher os campos origem, destino e data de viagem, sem a
                                        necessidade de cadastro prévio;</p>
                                    <p>b) Após o preenchimento dos dados iniciais, o “CLIENTE” deverá optar por uma das
                                        empresas de transporte de passageiros disponíveis para o trecho, conforme
                                        horários e valores disponíveis no momento da compra;</p>
                                    <p><strong>II. Da reserva de poltronas:</strong></p>
                                    <p>a) O “CLIENTE” poderá reservar sua poltrona através do website da “{nomeEmpresa}”, porém a “{nomeEmpresa}” não se responsabiliza pela incorreção dos
                                        dados inseridos na reserva de poltronas. Os “CLIENTES” estão cientes da
                                        veracidade, exatidão e autenticidade dos dados pessoais cadastrados.</p>
                                    <p>b) As poltronas somente são reservadas quando o pagamento é concluído. Caso o
                                        pagamento não seja efetuado em até 15 minutos, a passagem será liberada para
                                        venda novamente e a poltrona poderá ser reservada por outro “CLIENTE”.</p>
                                    <p>c) A passagem deve estar em nome do passageiro que irá viajar para que este, em
                                        posse de seu documento, possa retirar no balcão de atendimento da empresa
                                        responsável pela viagem.</p>
                                    <p>d) A “{nomeEmpresa}” não é responsável pela disponibilização e confirmação de
                                        passagem &quot;meia entrada&quot;. Este benefício é de inteira responsabilidade
                                        da “EMPRESA RESPONSÁVEL PELA VIAGEM”, que disponibilizará as informações
                                        necessárias ao “{nomeEmpresa}” para a venda no site e aplicativo.</p>
                                    <p><strong>III. Do preenchimento dos dados do passageiro</strong></p>
                                    <p>a) O “CLIENTE” deverá preencher seus dados corretamente para emissão da passagem
                                        e bilhete de embarque, para validação da compra pela operadora de cartão, pela
                                        equipe interna dA “{nomeEmpresa}” e pela equipe antifraude.</p>
                                    <p>b) A “{nomeEmpresa}” não se responsabiliza pelo preenchimento incorreto das
                                        informações requeridas, bem como pela não aprovação do “CLIENTE” após avaliação
                                        das documentações solicitadas.</p>
                                    <p>c) Ao adquirir passagens disponibilizadas como &quot;meia entrada&quot;, o
                                        “CLIENTE” entende e concorda que tem direito a este benefício. Sendo assim,
                                        concorda que deverá apresentar documento de comprovação de tais características
                                        no momento do embarque diretamente para a empresa responsável pela viagem. Para
                                        comprovação, poderão ser usados certidão de nascimento e RG para crianças e
                                        idosos e carteira de estudante para estudantes e universitários. Caso não seja
                                        comprovado o direito ao benefício, o “CLIENTE” não poderá embarcar utilizando a
                                        meia entrada, sendo necessária a compra de uma passagem inteira. A
                                        disponibilização das passagens &quot;meia entrada&quot; e a comprovação para o
                                        uso do benefício são de responsabilidade da “EMPRESA RESPONSÁVEL PELO
                                        TRANSPORTE”.</p>
                                    <p><strong>IV. Do pagamento:</strong></p>
                                    <p>a) Da cobrança da taxa de conveniência</p>
                                    <p>1. Para a prestação de serviço de facilitação na compra de passagens de
                                        transporte de passageiros, a “{nomeEmpresa}” cobrará uma taxa de
                                        conveniência. A taxa de conveniência estará discriminada no ato da compra, no
                                        campo “Impostos e taxas”, de forma clara.</p>
                                    <p>2. Todas as transações realizadas possuem a adição de taxas cobradas, tanto pela
                                        empresa responsável pela viagem, quanto pela “{nomeEmpresa}”.</p>
                                    <p>3. As taxas cobradas pelas empresas de transporte de passageiros são: pedágio,
                                        impostos, seguro viagem e, em alguns casos, a taxa de embarque. Estas variam de
                                        acordo com cada companhia e trajeto.</p>
                                    <p>4. A taxa de conveniência cobrada pela “{nomeEmpresa}”, no momento da compra
                                        da passagem, pela prestação do serviço, não será devolvida ao “CLIENTE” por se
                                        tratar de prestação de serviço já realizada ao “CLIENTE”.</p>
                                    <p>5. <strong>IMPORTANTE:</strong> TODAS AS TAXAS SERÃO INFORMADAS E DISCRIMINADAS
                                        NA TELA DE CONCLUSÃO DE COMPRA. AO CLICAR NO BOTÃO DE CONCLUSÃO, O CLIENTE
                                        DECLARA-SE CIENTE DOS VALORES COBRADOS E NÃO PODERÁ QUESTIONÁ-LOS.</p>
                                    <p>6. As taxas e impostos cobrados pela “{nomeEmpresa}”, exclusivamente em seu
                                        aplicativo, estão isentas temporariamente. O benefício da isenção ficará
                                        disponível apenas caso o “CLIENTE” tenha efetuado uma compra nos últimos 30
                                        dias, por meio da conta cadastrada na “{nomeEmpresa}”. O aplicativo está
                                        disponível nas lojas Apple Store e Play Store.</p>
                                    <p>7. O benefício de taxa grátis pelo aplicativo pode ser encerrado a qualquer
                                        momento pela “{nomeEmpresa}”, sem aviso prévio.</p>
                                    <p>b) Das formas de pagamento</p>
                                    <p>1. <strong>Cartão de crédito:</strong> O “{nomeEmpresa}” aceita as principais
                                        bandeiras de cartão de crédito: VISA, MASTERCARD e HIPER. A “{nomeEmpresa}”
                                        cobrará a fatura no seu cartão de crédito imediatamente após o recebimento do
                                        pedido.</p>
                                    <p>2. <strong>Cartão de débito:</strong> O “{nomeEmpresa}” aceita cartões de
                                        débito Visa Electron dos principais bancos brasileiros (Banco do Brasil,
                                        Bradesco e Santander). Compras feitas com cartões da bandeira Elo do Banco
                                        Bradesco não serão confirmadas(VERIFICAR ESSA INFORMAÇÃO E QUAL MOTIVO POIS PODE
                                        SER OBJETO DE TRATAMENTO NÃO IGUALITÁRIO). O cliente deve tomar as medidas de
                                        segurança adequadas para acessar o site do seu banco para aprovar o pagamento em
                                        débito.</p>
                                    <p>3. <strong>Transferência Eletrônica:</strong> A “{nomeEmpresa}” aceita o
                                        pagamento por transferência eletrônica. Este pagamento será transferido para a
                                        conta de PayMee Brasil Serviços de Pagamentos SA, conforme dados bancários
                                        apresentados na tela de pagamento e, após a confirmação da compra, a “{nomeEmpresa}” enviará o voucher comprovante de compra para o e-mail do “CLIENTE”,
                                        cadastrado no início do processo de compra.</p>
                                    <p>4. <strong>Pix:</strong> A “{nomeEmpresa}” aceita o pagamento com o Pix. Esse
                                        processo será feito por meio de QRCode nas compras pelo site e aplicativos nas
                                        versões Android e iOS. Cabe ao “CLIENTE” o processo de pagamento na instituição
                                        financeira. Após a confirmação do pagamento, a compra será comprovada e o
                                        voucher enviado por e-mail.</p>
                                    <p><strong>- IMPORTANTE:</strong> Em casos de cancelamento ou empresa fora do ar na
                                        hora da confirmação do pagamento, o estorno do valor será feito em até 5 dias
                                        úteis após o cliente informar os dados de sua conta bancária. O prazo para
                                        estorno permanece o mesmo em casos de pagamento com o Pix.</p>
                                    <p>- Caso seja realizado erroneamente um depósito, ao invés de transferência
                                        bancária, o “CLIENTE” deverá solicitar o estorno do valor pago, por e-mail, para
                                        a “PAYMEE”, no endereço eletrônico falecom@paymee.com.br, com seus dados
                                        bancários e seguirá o mesmo prazo de 5 dias úteis.</p>
                                    <p><strong>V. Processo de aprovação</strong></p>
                                    <p>a) Todas as transações efetuadas passarão por análise e aprovação interna. Após o
                                        processo de análise, o “CLIENTE” receberá em seu e-mail a informação de
                                        conclusão do processo e, caso a aprovação seja confirmada, receberá
                                        automaticamente o comprovante de sua viagem no e-mail cadastrado ao iniciar a
                                        compra.</p>
                                    <p>b) A “{nomeEmpresa}” não pode garantir de forma nenhuma que os serviços
                                        prestados pela empresa responsável pela gestão de pagamentos funcionarão livres
                                        de erros, interrupções, mau funcionamento, atrasos ou outras imperfeições.</p>
                                    <p>c) <strong>IMPORTANTE:</strong> Compras realizadas através de cartão de crédito
                                        poderão ter tempo de análise de até oito horas.</p>
                                    <p>d) O “CLIENTE”, ao acessar o status de sua compra, terá as seguintes
                                        informações:</p>
                                    <p><strong>1. COMPRA CONFIRMADA:</strong></p>
                                    <p>- Para toda transação confirmada será enviado um e-mail de aprovação contendo o
                                        comprovante de transação realizada. Caso não encontre, verifique sua caixa de
                                        SPAM. É de responsabilidade do “CLIENTE” inserir um e-mail válido para o
                                        recebimento do voucher comprovante de compra. Caso o “CLIENTE” insira um e-mail
                                        inexistente ou inválido, a compra será cancelada automaticamente e o estorno
                                        será realizado conforme política de prazo (conforme detalhado no item 9 deste
                                        termo).</p>
                                    <p>Para mais informações sobre sua compra, acesse o link <a
                                        href={`${url}/login`} target="_blank"
                                        rel="noreferrer">{`${url}/login`}</a> e informe o e-mail
                                        cadastrado no ato da compra.</p>
                                    <p>- Mesmo após a confirmação da compra, a “{nomeEmpresa}” poderá entrar em
                                        contato com o “CLIENTE” para critérios de confirmação de dados de pagamento.
                                        Caso não haja êxito no contato, a compra poderá ser cancelada.</p>
                                    <p><strong>2. COMPRAS PENDENTES DE ANÁLISE:</strong></p>
                                    <p>- Dependendo do histórico do cartão de crédito e seu titular ou a inserção de
                                        dados de compra divergentes, a transação poderá entrar em um processo de análise
                                        mais minucioso. Quando o processo for finalizado, o “CLIENTE” receberá um e-mail
                                        de confirmação com o localizador ou a informação de não aprovação da
                                        transação.</p>
                                    <p>- Para visualizar o status de compra, acesse <a
                                        href={`${url}/login`} target="_blank"
                                        rel="noreferrer">{`${url}/login`}</a></p>
                                    <p><strong>3. COMPRAS NÃO APROVADAS:</strong></p>
                                    <p>- A compra não aprovada pelo sistema interno da “{nomeEmpresa}”, por meio de
                                        informações ou processos cedidos pela operadora de cartão de crédito (por
                                        políticas internas que a “{nomeEmpresa}” não tem acesso), será
                                        automaticamente cancelada e a informação de não aprovação estará disponibilizada
                                        na página de pagamento.</p>
                                    <p><strong>4. COMPRAS EXPIRADAS.</strong></p>
                                    <p>- Caso o “CLIENTE” não finalize o processo de compra em até 15 minutos, esta não
                                        será concretizada, os valores da transação não serão debitados/creditados e as
                                        poltronas selecionadas não serão reservadas.</p>
                                    <p>6. DA IMPRESSÃO DO BILHETE DE EMBARQUE.</p>
                                    <p>6.1. Concluindo a compra, o “CLIENTE” receberá um e-mail com a confirmação do
                                        pagamento e o resumo do pedido, contendo os detalhes sobre a viagem.</p>
                                    <p>6.2. Para impressão do seu bilhete de embarque compareça ao balcão de atendimento
                                        da empresa de transporte de passageiros com 1 (uma) hora de antecedência ao
                                        horário de embarque, com o documento original com foto em mãos.</p>
                                    <p><strong>IMPORTANTE:</strong> Nem todas as empresas de transportes de passageiros
                                        aceitam cópias autenticadas para o embarque, cabe ao “CLIENTE” verificar
                                        diretamente com a empresa responsável pela viagem essa informação.</p>
                                    <p><strong>7. DO USO DO BILHETE DIGITAL (EMBARQUE FÁCIL).</strong></p>
                                    <p>7.1. O Bilhete Digital consiste em um código representado digitalmente que tem a
                                        validade de uma passagem impressa, que, se disponível para a compra realizada,
                                        poderá ser acessado por meio do voucher enviado por e-mail;</p>
                                    <p>7.2. Concluindo a compra, o “CLIENTE” receberá um e-mail com a confirmação do
                                        pagamento e o resumo do pedido, contendo os detalhes sobre a viagem e link para
                                        acessar o DAPB-E, que representa o bilhete digital.</p>
                                    <p>7.3. O bilhete digital também ficará disponível no aplicativo da “{nomeEmpresa}” no dispositivo móvel do “CLIENTE”.</p>
                                    <p>7.4. O “CLIENTE” pode usar seu bilhete digital para entrar diretamente no ônibus,
                                        apresentando ao motorista o código digital contido no aplicativo da “{nomeEmpresa}” ou em seu e-mail.</p>
                                    <p>7.5. O não carregamento do bilhete digital por problemas de conexão, falta de
                                        memória no dispositivo ou quaisquer problemas desta natureza é de
                                        responsabilidade do “CLIENTE”, devendo este retirar o bilhete de embarque
                                        impresso no balcão de atendimento da empresa responsável pela viagem.</p>
                                    <p>7.6. A disponibilidade do bilhete digital varia de acordo com a empresa
                                        responsável pela viagem. Nem todas oferecem ou são compatíveis com tal serviço,
                                        devendo o “CLIENTE”, neste caso, retirar o bilhete de embarque impresso no
                                        balcão de atendimento da empresa de transporte de passageiros.</p>
                                    <p>7.7. Para impressão do bilhete de embarque, compareça ao balcão de atendimento da
                                        empresa responsável pela viagem 1 (uma) hora antes do horário marcado para o
                                        embarque. Apenas o passageiro pode retirar o bilhete de embarque, apresentando o
                                        documento original com foto preenchido na reserva da poltrona.</p>
                                    <p><strong>IMPORTANTE:</strong> Nem todas as empresas de viagem aceitam cópias
                                        autenticadas para a retirada do bilhete de embarque.</p>
                                    <p><strong>8. ALTERAÇÕES E CANCELAMENTOS DAS PASSAGENS.</strong></p>
                                    <p><strong>8.1. DAS PASSAGENS NÃO RETIRADAS NO BALCÃO DE ATENDIMENTO DA EMPRESA
                                        RESPONSÁVEL PELA VIAGEM</strong></p>
                                    <p>a) Fica assegurado ao “CLIENTE” solicitar alterações da data, horário de embarque
                                        e passageiro ao “{nomeEmpresa}” por meio dos canais de atendimento, conforme
                                        horário disponível para suporte, em até 3 (três) horas antes do horário de
                                        embarque da passagem atual, desde que respeite os termos e condições
                                        estabelecidas pela empresa responsável pela viagem, bem como os decretos e
                                        regulamentações de seus respectivos estados e agências de transportes
                                        reguladoras.</p>
                                    <p>b) A “{nomeEmpresa}” se resguarda ao direito de realizar o suporte ao
                                        “CLIENTE” que necessite solicitar alterações na passagem conforme descrito no
                                        item 8.1.a, dentro dos termos e condições estabelecidas pela empresa responsável
                                        pela viagem, bem como os decretos e regulamentações de seus respectivos estados
                                        e agências de transportes reguladoras, sendo de responsabilidade do “CLIENTE” a
                                        verificação dos prazos específicos válidos para alterações e cancelamento da
                                        passagem no próprio voucher adquirido.</p>
                                    <p>c) Não é possível realizar alterações de passagens com a “{nomeEmpresa}” caso
                                        o valor da nova passagem seja maior que o da atual.</p>
                                    <p>d) Só é possível realizar o procedimento de alterações de passagens para viagens
                                        com valor maior que o da atual, diretamente com a empresa de transporte
                                        responsável pela viagem, sendo de responsabilidade do “CLIENTE” realizar o
                                        pagamento da diferença do valor pago.</p>
                                    <p>e) Caso o “CLIENTE” entre em contato nos canais de atendimento fora do horário
                                        disponível ou após o prazo estimado para a realização de trocas ou
                                        cancelamentos, é de responsabilidade do “CLIENTE” procurar assistência da
                                        empresa responsável pela viagem.</p>
                                    <p>f) A “{nomeEmpresa}” não se responsabiliza por alterações e cancelamentos após
                                        a data da viagem e/ou após o término do prazo mínimo estabelecido pela empresa
                                        de transporte e seus termos e condições. Cabe ao “CLIENTE” solicitar remarcação
                                        do bilhete de embarque diretamente com a empresa responsável pela viagem, no
                                        balcão de atendimento. Os prazos específicos válidos para alterações e
                                        cancelamento da passagem podem ser conferidos no próprio voucher adquirido.</p>
                                    <p>g) O “CLIENTE” tem um prazo de 7 (sete) dias, a partir da data da compra, para
                                        entrar em contato com a “{nomeEmpresa}” e utilizar o direito de
                                        arrependimento previsto no Artigo 49 da Lei 8.078/90. Porém, a solicitação de
                                        cancelamento do pedido deverá ser feita até 48 (quarenta e oito) horas antes do
                                        horário marcado para a viagem.</p>
                                    <p>h) No caso de passagens compradas na modalidade “ida e volta” com algum tipo de
                                        desconto, o cancelamento só poderá ser realizado no pedido integralmente, não se
                                        aplicando cancelamento de parte do pedido, como apenas uma poltrona ou apenas
                                        uma passagem.</p>
                                    <p><strong>8.2. DAS PASSAGENS RETIRADAS NO BALCÃO DE ATENDIMENTO DA EMPRESA
                                        RESPONSÁVEL PELA VIAGEM</strong></p>
                                    <p>a) Fica assegurado ao “CLIENTE” solicitar alteração de data ou horário de
                                        embarque, diretamente no balcão de atendimento da empresa responsável pela
                                        viagem presencialmente pelo menos 3 (três) horas antes do horário estabelecido
                                        para embarque, apresentando documento original do passageiro, desde que respeite
                                        os termos e condições estabelecidas pela empresa responsável pela viagem, bem
                                        como os decretos e regulamentações de seus respectivos estados e agências de
                                        transportes reguladoras, sendo de responsabilidade do “CLIENTE” a verificação
                                        dos prazos específicos válidos para alterações e cancelamento da passagem no
                                        próprio voucher adquirido.</p>
                                    <p>b) A transferência de horário ficará condicionada à disponibilidade de passagens
                                        na data e horário desejado pelo “CLIENTE”, ficando ainda assegurada ao “CLIENTE”
                                        a opção pela passagem com data e horário em aberto, com validade máxima de 12
                                        (doze) meses, contados da data de compra do bilhete original, ficando sujeita a
                                        reajuste de preço. Com menos de três horas antes da viagem, a empresa de ônibus
                                        pode cobrar uma taxa de remarcação de até 20% do valor da passagem.</p>
                                    <p><strong>Exceções:</strong> Algumas empresas com que trabalhamos operam somente em
                                        rotas intermunicipais e, portanto, não precisam aderir às normas da ANTT. Nesses
                                        casos, os termos de troca após a data da viagem citados acima não se aplicam.
                                    </p>
                                    <p><strong>8.3 DAS FACILIDADES DISPONIBILIZADAS PARA CANCELAMENTO</strong></p>
                                    <p>a) O “{nomeEmpresa}” disponibiliza ao “CLIENTE” opção de cancelamento total da
                                        sua compra, através do aplicativo instalado em seu celular ou página de
                                        cancelamento do site ({`${url}/pagamento/cancel`}) e
                                        ainda nos e-mails <a href={confEmpresa.lkemail} target="_blank"
                                                             rel="noreferrer"> {confEmpresa.email} </a> e
                                        <a href={confEmpresa.lkemailSuporte} target="_blank"
                                           rel="noreferrer"> {confEmpresa.emailSuporte} </a>, no período de 24 (vinte e quatro) horas por
                                        dia, nos 7 (sete) dias da semana.</p>
                                    <p>b) No caso de cancelamento nas plataformas disponibilizadas pela “{nomeEmpresa}”, o “CLIENTE” deverá seguir as seguintes condições para
                                        viabilidade:</p>
                                    <p>- Não ter retirado sua passagem com a empresa de transporte responsável pela
                                        viagem; - Realizar o cancelamento da compra integral, não podendo efetuar o
                                        procedimento apenas para um(alguns) passareiro(s) ou ter realizado uma das
                                        viagens no caso de compra na modalidade &quot;ida e volta&quot;;</p>
                                    <p>- Realizar o procedimento em até 3 (três) horas antes do horário de embarque da
                                        passagem atual, cabendo ao “CLIENTE” a verificação dos prazos específicos da
                                        empresa de transporte válidos para cancelamento da passagem no próprio voucher
                                        adquirido</p>
                                    <p>c) Caso o “CLIENTE” não atenda um ou mais critérios para viabilidade da
                                        solicitação, deverá entrar em contato com a “{nomeEmpresa}” ou com a “EMPRESA
                                        DE TRANSPORTES” responsável pela sua viagem, em seus canais de relacionamento,
                                        respeitando o horário de atendimento e prazos.</p>
                                    <p>d) O reembolso do valor pago seguirá os mesmos critérios apresentados neste
                                        documento.</p>
                                    <p><strong>9. REEMBOLSO DA PASSAGEM</strong></p>
                                    <p><strong>9.1. DO REEMBOLSO REFERENTE A FORMA DE PAGAMENTO UTILIZADA</strong></p>
                                    <p><strong>a) Cartão de crédito:</strong></p>
                                    <p>1. A “{nomeEmpresa}” possui o prazo de até 5 (cinco) dias úteis para
                                        disponibilizar o valor a ser reembolsado ao “CLIENTE” para a administradora da
                                        bandeira do cartão.</p>
                                    <p>2. A devolução do valor será de acordo com o repasse da administradora da
                                        bandeira.</p>
                                    <p>3. O valor será creditado no próximo vencimento da fatura do “CLIENTE”, podendo
                                        ocorrer de 30 (trinta) a 60 (sessenta) dias a contar do cancelamento.</p>
                                    <p><strong>b) Cartão de débito:</strong></p>
                                    <p>1. A devolução do valor será de acordo com o repasse da administradora da
                                        bandeira e/ou banco.</p>
                                    <p>2. A devolução do valor ocorrerá para conta corrente de pessoa física.</p>
                                    <p>3. O “CLIENTE” deverá solicitar o reembolso para o “{nomeEmpresa}”, através do
                                        e-mail <a href={confEmpresa.lkemail} target="_blank"
                                                  rel="noreferrer"> {confEmpresa.email} </a>
                                        ou <a href={confEmpresa.lkemailSuporte} target="_blank"
                                              rel="noreferrer"> {confEmpresa.emailSuporte} </a> e
                                        informar seus dados bancários, são eles:</p>
                                    <p>- Nome completo do titular da conta bancária;</p>
                                    <p>- CPF do titular da conta bancária;</p>
                                    <p>- Banco/Agência/Número da Conta Corrente;</p>
                                    <p>- Número do pedido, localizado no canto superior direito do voucher.</p>
                                    <p>4. O valor será creditado em até 5 (cinco) dias úteis na conta do “CLIENTE”,
                                        podendo ocorrer atrasos por parte do banco emissor.</p>
                                    <p><strong>c) Transferência Bancária:</strong></p>
                                    <p>1. A devolução do valor será de acordo com repasse da administradora da bandeira
                                        e/ou banco, intermediado pela empresa parceira responsável pelo pagamento.</p>
                                    <p>2. O valor será creditado em até 5 (cinco) dias úteis na conta do “CLIENTE”,
                                        podendo ocorrer atrasos por parte do banco emissor.</p>
                                    <p>9.2. Nos termos do art.8º, §2º da Resolução n.º 978 da ANTT, fica a
                                        transportadora autorizada a reter até 5% (cinco por cento) do valor pago pela
                                        passagem rodoviária, a título de multa compensatória, no caso de o passageiro
                                        realizar o cancelamento ou a transferência, dentro do prazo previsto no caput
                                        desta cláusula.</p>
                                    <p>9.3. A taxa de conveniência cobrada pela “{nomeEmpresa}” para a realização dos
                                        serviços não será devolvida, por se tratar de prestação de serviço já realizada
                                        ao “CLIENTE”.</p>
                                    <p><strong>d) Pix:</strong></p>
                                    <p>1. A devolução do valor será de acordo com repasse da administradora da bandeira
                                        e/ou banco, intermediado pela empresa parceira responsável pelo pagamento.</p>
                                    <p>2. O valor será creditado em até 5 (cinco) dias úteis na conta do “CLIENTE”,
                                        podendo ocorrer atrasos por parte do banco emissor.</p>
                                    <p>9.2. Nos termos do art.8º, §2º da Resolução n.º 978 da ANTT, fica a
                                        transportadora autorizada a reter até 5% (cinco por cento) do valor pago pela
                                        passagem rodoviária, a título de multa compensatória, no caso de o passageiro
                                        realizar o cancelamento ou a transferência, dentro do prazo previsto no caput
                                        desta cláusula.</p>
                                    <p>9.3. A taxa de conveniência cobrada pela “{nomeEmpresa}” para a realização dos
                                        serviços não será devolvida, por se tratar de prestação de serviço já realizada
                                        ao “CLIENTE”.</p>
                                    <p><strong>10. DO TRANSPORTE DE BAGAGENS</strong></p>
                                    <p>10.1. A vista do disposto no artigo 70 e seguintes do Decreto 2521/98, o preço da
                                        passagem abrange, a título de franquia, o transporte obrigatório e gratuito de
                                        bagagem, observando os seguintes limites máximos de peso e dimensão:</p>
                                    <p>a) bagageiro com trinta quilos de peso total e volume máximo de trezentos
                                        centímetros cúbicos, limita-se à maior dimensão de qualquer volume a um metro e
                                        no porta-embrulhos, cinco quilos de peso total, com dimensões que se adaptem ao
                                        local, desde que o conforto, a segurança e a higiene dos passageiros não sejam
                                        prejudicados;</p>
                                    <p>b) excedida a franquia prevista, o passageiro pagará até 0,5% (meio por cento) do
                                        preço da passagem correspondente ao serviço convencional, pelo transporte de
                                        cada quilograma de excesso;</p>
                                    <p>c) é vedado o transporte de produtos considerados perigosos, indicados na
                                        legislação específica, bem como aqueles que, por sua forma ou natureza,
                                        comprometam a segurança do veículo, de seus ocupantes ou de terceiros, nos
                                        termos do art.72 do Decreto 2521/98.</p>
                                    <p><strong>11. DA PROPRIEDADE INTELECTUAL</strong></p>
                                    <p>11.1. O “CLIENTE” acorda que o website, assim como os logotipos, marcas,
                                        insígnias, fotos, imagens, descrições, textos, &quot;layout&quot;, símbolos,
                                        sinais distintivos, manual(ais) e quaisquer outros materiais correlatos ao
                                        website, constituem, conforme o caso, direitos autorais, segredos de negócio,
                                        e/ou direitos de propriedade da “{nomeEmpresa}” ou seus licenciadores,
                                        conforme o caso, sendo tais direitos protegidos pela legislação nacional e
                                        internacional aplicável à propriedade intelectual, especialmente quanto aos
                                        termos e condições das Leis nºs 9.279/96, 9.609/98 e 9.610/98 e que não
                                        pleitearão ou reclamarão, a qualquer tempo, tais direitos de propriedade como se
                                        seus fossem.</p>
                                    <p>11.2. É expressamente proibido ao “CLIENTE” a reprodução, a distribuição, a
                                        modificação, a exibição, a criação de trabalhos derivados ou qualquer outra
                                        forma de utilização do conteúdo deste website e dos materiais veiculados no ou
                                        pelo website.</p>
                                    <p><strong>12. DISPOSIÇÕES GERAIS</strong></p>
                                    <p>12.1. A passagem devidamente comprada é irrevogável, nos termos dos Artigos 427 e
                                        429 do Código Civil de 2002, ressalvados as circunstâncias excepcionais.</p>
                                    <p>12.2. Ao adquirir a passagem e efetuar o pagamento por meio do website, o
                                        “CLIENTE” declara estar ciente de todos os Termos, condições de recebimento e
                                        todas as informações atinentes ao produto adquirido, de acordo com o
                                        disposto.</p>
                                    <p>12.3. O “{nomeEmpresa}” não será responsável pelas despesas com o frete caso
                                        haja necessidade.</p>
                                    <p>12.4. O “CLIENTE” tem um prazo de 7 (sete) dias para entrar em contato explicando
                                        o motivo da troca ou cancelamento da reserva da passagem. Porém, 48 (quarenta e
                                        oito) horas antes da realização da viagem não será mais permitida a troca ou o
                                        cancelamento com o “{nomeEmpresa}”, podendo o “CLIENTE” realizar a
                                        solicitação para a empresa de transporte responsável pela viagem.</p>
                                    <p>12.5. Quando no website existirem “links” para outros sites, a “{nomeEmpresa}”
                                        recomenda ao “CLIENTE” consultar as respectivas “Políticas de Uso” e “Termos de
                                        Privacidade” de tais sites, bem como quaisquer outros regulamentos específicos
                                        de cada um deles, caso acesse tais sites. A “{nomeEmpresa}” não presta aval,
                                        nem garante ou representa quaisquer terceiros cujos links constem do site, sendo
                                        certo que sua disponibilização no site tenciona proporcionar maior comodidade e
                                        opções de entretenimento ao “CLIENTE”. O “CLIENTE” reconhece que todo e qualquer
                                        problema gerado em tais sites devem ser resolvidos com a empresa titular do
                                        respectivo site, sem a participação da “{nomeEmpresa}”.</p>
                                    <p>12.6. Determinados atos, incluindo, mas se não limitado, a usos com propósitos
                                        ilegais, corrupção de informações ou dados, violação de direitos de propriedade
                                        industrial ou autoral, violação de direitos de terceiros (inclusive crianças),
                                        envio de mensagens de cunho ameaçador, racista ou pornográfico, interferir ou
                                        interromper o serviço prestado pelo Site e/ou qualquer tentativa de propagação
                                        de vírus ou programa não desejado, poderão imediatamente resultar no
                                        encerramento de qualquer relação entre a “{nomeEmpresa}” e o “CLIENTE”.</p>
                                    <p>12.7. Em razão do disposto na Cláusula acima, o “CLIENTE” acorda que qualquer
                                        informação que tenha prestado ou transmitido, inclusive qualquer resposta a uma
                                        pergunta, a qualquer tempo, e sem necessidade de prévia notificação, pode ser
                                        deletada do Website, a critério exclusivo da “{nomeEmpresa}”, que ainda,
                                        poderá manter cópia em seus arquivos para as demais providências cabíveis, sem
                                        prejuízo de qualquer medida legal que se faça necessária. Tal faculdade visa
                                        proteger interesses dos demais “CLIENTES”, bem como da sociedade como um todo,
                                        visando mitigar riscos inerentes a tais práticas ilegais ou contrárias aos usos
                                        e costumes locais.</p>
                                    <p><strong>13. PASSAGENS COM DESCONTOS E PROMOCIONAIS</strong></p>
                                    <p><strong>13.1. DA PARTICIPAÇÃO DAS CAMPANHAS DE DESCONTO DA {nomeEmpresa}</strong></p>
                                    <p>13.1.1. Para participar das campanhas de desconto, o “CLIENTE” precisa fazer a
                                        compra de passagens de ônibus pelo site do “{nomeEmpresa}” ({url}) ou pelo aplicativo em seu smartphone.</p>
                                    <p><strong>13.2. DAS RESTRIÇÕES E CONDIÇÕES</strong></p>
                                    <p>13.2.1. O “CLIENTE” deve se atentar ao horário e data de término das promoções
                                        oferecidas pela “{nomeEmpresa}”, após o prazo especificado por cada empresa
                                        de ônibus não é possível adquirir o desconto.</p>
                                    <p>13.2.2. Somente os trechos especificados no site serão validados, qualquer trecho
                                        com outra origem ou destino não será considerado.</p>
                                    <p>13.2.3. Cada trecho terá regras de uso de descontos especificados no site de
                                        acordo com o detalhamento.</p>
                                    <p>13.2.4. Existe um número limitado de poltronas com descontos. Dessa forma, as
                                        campanhas de desconto podem ser esgotadas a qualquer hora sem que, por isto,
                                        possa ser imputado qualquer tipo de responsabilidade a “{nomeEmpresa}”,
                                        incluindo responsabilidade por perdas e danos ou qualquer obrigação de
                                        ressarcimento.</p>
                                    <p>13.2.5. As passagens adquiridas com desconto pelo “CLIENTE” não garante
                                        possibilidade de troca para outras passagens com valores maiores. Neste caso,
                                        havendo a necessidade, o cliente poderá solicitar o cancelamento e reembolso,
                                        conforme políticas descritas nos termos e condições de uso do “{nomeEmpresa}”.</p>
                                    <p><strong>13.3. SUSPENSÃO OU CANCELAMENTO DO DESCONTO</strong></p>
                                    <p>13.3.1. A “{nomeEmpresa}” tem o direito de suspender, cancelar e/ou retirar
                                        imediatamente o benefício dos clientes que:</p>
                                    <p>a) Cometerem infrações ao Regulamento das Campanhas de Desconto, aos Termos de
                                        Uso e/ou às políticas do “{nomeEmpresa}”;</p>
                                    <p>b) Estiverem suspensos de comprar pelo site do “{nomeEmpresa}”;</p>
                                    <p>c) Estiverem inabilitados temporária ou definitivamente dentro do site do “{nomeEmpresa}”;</p>
                                    <p>d) Tiverem débitos com o “{nomeEmpresa}”, por qualquer conteúdo relacionado
                                        direta ou indiretamente com sua qualidade de usuário.</p>
                                    <p><strong>13.4. ACEITAÇÃO</strong></p>
                                    <p>13.4.1. A participação nas campanhas de desconto do “{nomeEmpresa}” implica a
                                        aceitação integral de cada uma das disposições descritas e contidas neste
                                        Regulamento, bem como aos termos e condições de uso do “{nomeEmpresa}”.</p>
                                    <p>13.4.2. Os clientes da “{nomeEmpresa}” que cumprirem todas as condições
                                        descritas acima serão beneficiados e aptos a participar das nossas campanhas de
                                        desconto.</p>
                                    <p><strong>14. ATENDIMENTO E SUPORTE AO CLIENTE</strong></p>
                                    <p><strong>14.1. DOS CANAIS DE ATENDIMENTO E SUPORTE AO CLIENTE</strong></p>
                                    <p>14.1.1. O “{nomeEmpresa}” disponibilizará atendimento e suporte para o
                                        “CLIENTE” nos seguintes canais:</p>
                                    <p>a) E-mail, por meio do endereço <a href={confEmpresa.lkemail} target="_blank"
                                                                          rel="noreferrer"> {confEmpresa.email} </a> e <a href={confEmpresa.lkemailSuporte} target="_blank"
                                                                                                                                   rel="noreferrer"> {confEmpresa.emailSuporte} </a>.</p>
                                    <p>b) Chat, por meio do seu site <a href={url}
                                                                        target="_blank"
                                                                        rel="noreferrer">{url}</a>.
                                    </p>
                                    <p>c) Redes sociais (Facebook, Instagram e Twitter).</p>
                                    <p><strong>14.2. DA DISPONIBILIDADE DE ATENDIMENTO E SUPORTE AO CLIENTE NOS CANAIS
                                        DE RELACIONAMENTO</strong></p>
                                    <p>a) Por telefone, das 09h às 18h, exceto domingos e feriados, com prazo para
                                        atendimento conforme disponibilidade dos atendentes, onde o cliente aguardará em
                                        fila de espera.</p>
                                    <p>b) Por e-mail, das 09h às 21h, exceto sábados, domingos e feriados, com prazo
                                        médio de resposta de 48 horas úteis.</p>
                                    <p>c) Por chat, das 09h às 20h, exceto sábados, domingos e feriados, com prazo para
                                        atendimento conforme disponibilidade dos atendentes, onde o cliente aguardará em
                                        fila de espera.</p>
                                    <p>d) Por redes sociais, das 09h às 20h, exceto sábados, domingos e feriados, com
                                        prazo médio de resposta de 48 horas úteis.</p>
                                    <p><strong>14.3. A “{nomeEmpresa}” poderá interromper seu atendimento em casos
                                        especiais ou de força maior nos dias e horários citados anteriormente sem
                                        comunicação prévia.</strong></p>
                                    <p><strong>14.4. Fora do horário de atendimento do “{nomeEmpresa}” é de
                                        responsabilidade do “CLIENTE” entrar em contato com a empresa de transporte de
                                        passageiros para solicitar auxílio com trocas, cancelamentos ou demais dúvidas
                                        ou solicitações referentes a sua compra.</strong></p>
                                    <p>Caso reste qualquer dúvida a respeito do conteúdo do presente instrumento, por
                                        favor, contate:</p>
                                    <p><a href={confEmpresa.lkemail} target="_blank"
                                          rel="noreferrer"> {confEmpresa.email} </a></p>
                                    <p><a href={confEmpresa.lkemailSuporte} target="_blank"
                                          rel="noreferrer"> {confEmpresa.emailSuporte} </a></p>

                                    <p>Este contrato supera quaisquer acordos, verbais ou escritos, anteriormente
                                        mantidos entre as partes, especialmente com relação ao uso do Website pelo
                                        “CLIENTE”.</p>
                                    <p>O “CLIENTE”, ou, se for o caso, seu pai/mãe ou representante legal, reconhece
                                        expressamente ter lido, analisado e aceito integralmente as condições acima
                                        estabelecidas, comprometendo-se cumpri-las integralmente.</p>
                                    <p><strong>REGULAMENTO DA VIAGEM</strong></p>
                                    <p><strong>a) São direitos e obrigações do usuário:</strong></p>
                                    <p>I.Receber serviço adequado;</p>
                                    <p>II.Receber dos órgãos reguladores e da transportadora informações para defesa de
                                        interesses individuais ou coletivos;</p>
                                    <p>III.Obter e utilizar o serviço com liberdade de escolha;</p>
                                    <p>IV.Levar ao conhecimento do órgão de fiscalização as irregularidades de que tenha
                                        conhecimento, referentes ao serviço delegado;</p>
                                    <p>V.Zelar pela conservação dos bens e equipamentos por meio dos quais lhes são
                                        prestados os serviços;</p>
                                    <p>VI.Ser transportado com pontualidade, segurança, higiene e conforto, do início ao
                                        término da viagem;</p>
                                    <p>VII.Ter garantida sua poltrona no ônibus, nas condições especificadas no bilhete
                                        de passagem;</p>
                                    <p>VIII.Ser atendido com urbanidade pelos prepostos da transportadora e pelos
                                        agentes de fiscalização;</p>
                                    <p>IX.Ser auxiliado no embarque e desembarque, em se tratando de crianças, pessoas
                                        idosas ou com dificuldades de locomoção;</p>
                                    <p>X.Receber da transportadora informações acerca das características dos serviços,
                                        tais como horários, tempo de viagem, localidades atendidas, preço de passagem e
                                        outras relacionadas com os serviços;</p>
                                    <p>XI.Transportar, gratuitamente, bagagem no bagageiro observada os limites de:</p>
                                    <p>A. Em caso de passagem <strong>rodoviária:</strong> Peso total de trinta
                                        quilogramas, de volume máximo de trezentos decímetros cúbicos e de maior
                                        dimensão de um metro, bem como volume no porta-embrulhos limitado a cinco
                                        quilogramas e dimensões compatíveis;</p>
                                    <p>B. Em caso de passagem <strong>hidroviária:</strong> Peso total de quarenta
                                        quilogramas, de volume máximo de duzentos decímetros cúbicos e limitada a maior
                                        dimensão de um metro, bem como vinte quilogramas de peso total para bagagem de
                                        mão, com dimensões que não afetem o conforto e a segurança;</p>
                                    <p>XII.Receber os comprovantes dos volumes transportados no bagageiro;</p>
                                    <p>XIII.Ser indenizado por extravio ou dano da bagagem transportada no
                                        bagageiro;</p>
                                    <p>XIV.Receber a diferença do preço da passagem, quando a viagem se faça, total ou
                                        parcialmente, em veículos de características inferiores às daquele
                                        contratado;</p>
                                    <p>XV.Receber, às expensas da transportadora, enquanto perdurar a situação,
                                        alimentação e pousada, nos casos de venda de mais de um bilhete de passagem para
                                        a mesma poltrona, ou interrupção ou retardamento da viagem, quando tais fatos
                                        forem imputados à transportadora;</p>
                                    <p>XVI.Receber da transportadora, em caso de acidente, imediata e adequada
                                        assistência;</p>
                                    <p>XVII.Transportar, sem pagamento, crianças de até seis anos incompletos, desde que
                                        não ocupem poltronas, observadas as disposições legais e regulamentares
                                        aplicáveis ao transporte de menores;</p>
                                    <p>XVIII.Efetuar a compra de passagem rodoviária com data de utilização em aberto,
                                        sujeita a reajuste de preço se não utilizada dentro de um ano da data da
                                        emissão;</p>
                                    <p>XIX.Receber a importância paga, no caso de desistência da viagem rodoviária,
                                        hipótese em que o transportador terá o direito de reter até cinco por cento da
                                        importância a ser restituída ao passageiro, ou revalidar o bilhete de passagem
                                        para outro dia ou horário, desde que, em ambos os casos, se manifeste com
                                        antecedência mínima de três horas em relação ao horário de partida;</p>
                                    <p>XX.Receber a importância paga, no caso de desistência da viagem hidroviária,
                                        hipótese em que o transportador terá o direito de reter até vinte por cento da
                                        importância a ser restituída ao passageiro;</p>
                                    <p>XXI.Estar garantido pelo Seguro de Responsabilidade Civil contratado pela
                                        transportadora, sem prejuízo da cobertura do seguro obrigatório de danos
                                        pessoais.</p>
                                    <p><strong>b) O usuário terá recusado o embarque ou determinado seu desembarque,
                                        quando:</strong></p>
                                    <p>I.Não se identificar quando exigido;</p>
                                    <p>II.Em estado de embriaguez;</p>
                                    <p>III.Portar arma, sem autorização da autoridade competente;</p>
                                    <p>IV.Transportar ou pretender embarcar produtos considerados perigosos pela
                                        legislação específica;</p>
                                    <p>V.Transportar ou pretender embarcar consigo animais domésticos ou silvestres, sem
                                        o devido acondicionamento ou em desacordo com disposições legais ou
                                        regulamentares;</p>
                                    <p> VI.Pretender embarcar objeto de dimensões e acondicionamento incompatíveis com o
                                        porta-embrulhos;</p>
                                    <p>VII.Comprometer a segurança, o conforto ou a tranqüilidade dos demais
                                        passageiros;</p>
                                    <p>VIII.Fizer uso de aparelho sonoro, depois de advertido pela tripulação do
                                        ônibus;</p>
                                    <p>IX.Demonstrar incontinência no comportamento;</p>
                                    <p> X.Recusar-se ao pagamento da tarifa;</p>
                                    <p> XI.Fizer uso de produtos fumígenos no interior do ônibus, em desacordo com a
                                        legislação pertinente.</p>
                                    <p><strong>Referências adicionais:</strong></p>
                                    <p> Para referência de viagens rodoviárias ou ferroviárias visite <a
                                        href='http://www.antt.gov.br' target="_blank" rel="noreferrer">ANTT</a>, para
                                        viagens hidroviárias visite <a href='https://www.gov.br/antaq' target="_blank"
                                                                       rel="noreferrer">ANTAQ</a>, órgãos
                                        regulamentadores federais e visite também as agências estaduais (links abaixo).
                                    </p>
                                    <p><a href='http://www.artesp.sp.gov.br/' target="_blank" rel="noreferrer">ARTESP -
                                        SP</a></p>
                                    <p><a href='http://www.arcon.pa.gov.br/' target="_blank" rel="noreferrer">ARCON -
                                        PA</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default TermosUso
