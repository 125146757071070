const reunidasPaulista = [

    {link: '/passagem-de-onibus/guararapes-sp/lavinia-sp'},
    {link: '/passagem-de-onibus/lavinia-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/lavinia-sp/pereirabarreto-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/lavinia-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/lavinia-sp'},
    {link: '/passagem-de-onibus/lavinia-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/lavinia-sp/guararapes-sp'},
    {link: '/passagem-de-onibus/lavinia-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/pereirabarreto-sp/lavinia-sp'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/ubatuba-sp'},
    {link: '/passagem-de-onibus/lavinia-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/ubatuba-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/bauru-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/barragemlages-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/guaicara-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/lins-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/bauru-sp'},
    {link: '/passagem-de-onibus/coroados-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/coroados-sp/birigui-sp'},
    {link: '/passagem-de-onibus/limeira-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/limeira-sp/lins-sp'},
    {link: '/passagem-de-onibus/limeira-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/limeira-sp/andradina-sp'},
    {link: '/passagem-de-onibus/dourado-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/dourado-sp/jau-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/promissao-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/santaterezinha,sp-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/birigui-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/andradina-sp'},
    {link: '/passagem-de-onibus/cafelandia,sp-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/lins-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/birigui-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/boaesperancadosul-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/dourado-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/jau-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/araraquara-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/saocarlos-sp'},
    {link: '/passagem-de-onibus/avanhandava-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/avanhandava-sp/birigui-sp'},
    {link: '/passagem-de-onibus/avanhandava-sp/bauru-sp'},
    {link: '/passagem-de-onibus/boaesperancadosul-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/boaesperancadosul-sp/jau-sp'},
    {link: '/passagem-de-onibus/boaesperancadosul-sp/araraquara-sp'},
    {link: '/passagem-de-onibus/boaesperancadosul-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/boaesperancadosul-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/boaesperancadosul-sp/bauru-sp'},
    {link: '/passagem-de-onibus/angradosreis-rj/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/angradosreis-rj/saopaulo-sp'},
    {link: '/passagem-de-onibus/avanhandava-sp/lins-sp'},
    {link: '/passagem-de-onibus/avanhandava-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/avanhandava-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/lins-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/promissao-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/birigui-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/andradina-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/bauru-sp'},
    {link: '/passagem-de-onibus/guaracai-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/guaracai-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/guaracai-sp/andradina-sp'},
    {link: '/passagem-de-onibus/guaracai-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/getulina-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/limeira-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/guaracai-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/guararapes-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/birigui-sp'},
    {link: '/passagem-de-onibus/glicerio-sp/coroados-sp'},
    {link: '/passagem-de-onibus/glicerio-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/glicerio-sp/birigui-sp'},
    {link: '/passagem-de-onibus/guaimbe-sp/guaranta-sp'},
    {link: '/passagem-de-onibus/guaimbe-sp/lins-sp'},
    {link: '/passagem-de-onibus/guaimbe-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/guaranta-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/guararapes-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/guararapes-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/dourado-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/dourado-sp/saocarlos-sp'},
    {link: '/passagem-de-onibus/dourado-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/dourado-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/dourado-sp/bauru-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/lins-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/andradina-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/bauru-sp'},
    {link: '/passagem-de-onibus/guaracai-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/birigui-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/andradina-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/bauru-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/guaicara-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/guaicara-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/guararapes-sp/pereirabarreto-sp'},
    {link: '/passagem-de-onibus/guararapes-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/guararapes-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/guararapes-sp/andradina-sp'},
    {link: '/passagem-de-onibus/guararapes-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/coroados-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/guaicara-sp'},
    {link: '/passagem-de-onibus/josebonifacio-sp/glicerio-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/lins-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/birigui-sp'},
    {link: '/passagem-de-onibus/itaguai-rj/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/itaguai-rj/saopaulo-sp'},
    {link: '/passagem-de-onibus/lins-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/lins-sp/santoandre-sp'},
    {link: '/passagem-de-onibus/lins-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/lins-sp/limeira-sp'},
    {link: '/passagem-de-onibus/guaicara-sp/lins-sp'},
    {link: '/passagem-de-onibus/guaicara-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/guaicara-sp/promissao-sp'},
    {link: '/passagem-de-onibus/guaicara-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/guaicara-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/guaicara-sp/bauru-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/lins-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/promissao-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/lins-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/campinas-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/pereirabarreto-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/americana-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/mirandopolis-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/jau-sp/boaesperancadosul-sp'},
    {link: '/passagem-de-onibus/jau-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/jau-sp/dourado-sp'},
    {link: '/passagem-de-onibus/jau-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/jau-sp/araraquara-sp'},
    {link: '/passagem-de-onibus/jau-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/jau-sp/saocarlos-sp'},
    {link: '/passagem-de-onibus/jau-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/jau-sp/resende-rj'},
    {link: '/passagem-de-onibus/jau-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/jau-sp/bauru-sp'},
    {link: '/passagem-de-onibus/lavinia-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/lavinia-sp/andradina-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/santoandre-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/coroados-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/glicerio-sp'},
    {link: '/passagem-de-onibus/lins-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/lins-sp/andradina-sp'},
    {link: '/passagem-de-onibus/lins-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/lins-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/lins-sp/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/lins-sp/bauru-sp'},
    {link: '/passagem-de-onibus/lins-sp/santos-sp'},
    {link: '/passagem-de-onibus/lins-sp/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/osasco-sp/lins-sp'},
    {link: '/passagem-de-onibus/osasco-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/osasco-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/osasco-sp/birigui-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/lins-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/birigui-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/jaboticabal-sp/andradina-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/lins-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/osasco-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/campinas-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/americana-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/birigui-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/andradina-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/bauru-sp'},
    {link: '/passagem-de-onibus/lins-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/lins-sp/americana-sp'},
    {link: '/passagem-de-onibus/lins-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/lins-sp/promissao-sp'},
    {link: '/passagem-de-onibus/lins-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/lins-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/lins-sp/birigui-sp'},
    {link: '/passagem-de-onibus/lins-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/lins-sp/treslagoas-ms'},
    {link: '/passagem-de-onibus/lins-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/lins-sp/osasco-sp'},
    {link: '/passagem-de-onibus/lins-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/lins-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/lins-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/lins-sp/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/lins-sp/campinas-sp'},
    {link: '/passagem-de-onibus/lins-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/lins-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/lins-sp/guaicara-sp'},
    {link: '/passagem-de-onibus/lins-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/lins-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/jau-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/araraquara-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/saocarlos-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/bauru-sp'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/angradosreis-rj'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/itaguai-rj'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/paraty-rj'},
    {link: '/passagem-de-onibus/araraquara-sp/boaesperancadosul-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/andradina-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/paraty-rj/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/paraty-rj/saopaulo-sp'},
    {link: '/passagem-de-onibus/pereirabarreto-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/pereirabarreto-sp/guararapes-sp'},
    {link: '/passagem-de-onibus/pereirabarreto-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/pereirabarreto-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/pereirabarreto-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/pereirabarreto-sp/andradina-sp'},
    {link: '/passagem-de-onibus/pereirabarreto-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/boaesperancadosul-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/dourado-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/jau-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/araraquara-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/saocarlos-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/pederneiras-sp/bauru-sp'},
    {link: '/passagem-de-onibus/penapolis-sp/santos-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/guaracai-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/guararapes-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/lins-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/lavinia-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/pereirabarreto-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/birigui-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/andradina-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/ilhasolteira-sp/bauru-sp'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/lins-sp'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/birigui-sp'},
    {link: '/passagem-de-onibus/campinas-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/campinas-sp/birigui-sp'},
    {link: '/passagem-de-onibus/campinas-sp/andradina-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/lins-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/birigui-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/jau-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/araraquara-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/bauru-sp'},
    {link: '/passagem-de-onibus/campinas-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/campinas-sp/lins-sp'},
    {link: '/passagem-de-onibus/campinas-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/campinas-sp/promissao-sp'},
    {link: '/passagem-de-onibus/americana-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/americana-sp/lins-sp'},
    {link: '/passagem-de-onibus/americana-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/americana-sp/promissao-sp'},
    {link: '/passagem-de-onibus/saocarlos-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/saocarlos-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/saocarlos-sp/bauru-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/guaicara-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/lins-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/promissao-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/promissao-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/promissao-sp/birigui-sp'},
    {link: '/passagem-de-onibus/promissao-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/promissao-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/promissao-sp/bauru-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/dourado-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/jau-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/saocarlos-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/bauru-sp'},
    {link: '/passagem-de-onibus/resende-rj/jau-sp'},
    {link: '/passagem-de-onibus/resende-rj/bauru-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/boaesperancadosul-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/dourado-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/jau-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/araraquara-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/birigui-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/treslagoas-ms'},
    {link: '/passagem-de-onibus/saopaulo-sp/andradina-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/bauru-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/angradosreis-rj'},
    {link: '/passagem-de-onibus/saopaulo-sp/itaguai-rj'},
    {link: '/passagem-de-onibus/saopaulo-sp/paraty-rj'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/santoandre-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/saocarlos-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/ribeiraogrande-sp/bauru-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/getulina-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/guaimbe-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/guaranta-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/promissao-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/birigui-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/andradina-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/pirajui-sp/bauru-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/guararapes-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/lins-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/lavinia-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/pereirabarreto-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/promissao-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/promissao-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/promissao-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/promissao-sp/guaicara-sp'},
    {link: '/passagem-de-onibus/promissao-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/promissao-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/promissao-sp/lins-sp'},
    {link: '/passagem-de-onibus/promissao-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/promissao-sp/campinas-sp'},
    {link: '/passagem-de-onibus/promissao-sp/americana-sp'},
    {link: '/passagem-de-onibus/promissao-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/americana-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/americana-sp/birigui-sp'},
    {link: '/passagem-de-onibus/americana-sp/andradina-sp'},
    {link: '/passagem-de-onibus/saocarlos-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/saocarlos-sp/dourado-sp'},
    {link: '/passagem-de-onibus/saocarlos-sp/jau-sp'},
    {link: '/passagem-de-onibus/saocarlos-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saocarlos-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/americana-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/birigui-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/treslagoas-ms'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/andradina-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/bauru-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/coroados-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/guaicara-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/glicerio-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/lins-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/promissao-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/osasco-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/lavinia-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/campinas-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/pereirabarreto-sp'},
    {link: '/passagem-de-onibus/birigui-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/birigui-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/birigui-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/birigui-sp/andradina-sp'},
    {link: '/passagem-de-onibus/birigui-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/birigui-sp/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/birigui-sp/bauru-sp'},
    {link: '/passagem-de-onibus/birigui-sp/santos-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/lins-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/santos-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/birigui-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/birigui-sp/santoandre-sp'},
    {link: '/passagem-de-onibus/birigui-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/birigui-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/birigui-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/birigui-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/birigui-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/birigui-sp/lins-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/limeira-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/guaracai-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/guararapes-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/lins-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/birigui-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/andradina-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/treslagoas-ms/lins-sp'},
    {link: '/passagem-de-onibus/treslagoas-ms/saopaulo-sp'},
    {link: '/passagem-de-onibus/treslagoas-ms/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/treslagoas-ms/bauru-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/birigui-sp/osasco-sp'},
    {link: '/passagem-de-onibus/birigui-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/birigui-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/birigui-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/birigui-sp/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/birigui-sp/campinas-sp'},
    {link: '/passagem-de-onibus/birigui-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/birigui-sp/americana-sp'},
    {link: '/passagem-de-onibus/birigui-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/birigui-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/taquaritinga-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/birigui-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/andradina-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/bauru-sp'},
    {link: '/passagem-de-onibus/andradina-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/andradina-sp/limeira-sp'},
    {link: '/passagem-de-onibus/andradina-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/andradina-sp/guaracai-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/lins-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/promissao-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/bauru-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/lins-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/birigui-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/guaracai-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/guararapes-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/lins-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/pereirabarreto-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/birigui-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/andradina-sp'},
    {link: '/passagem-de-onibus/valparaiso-sp/bauru-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/guaicara-sp'},
    {link: '/passagem-de-onibus/andradina-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/andradina-sp/guararapes-sp'},
    {link: '/passagem-de-onibus/andradina-sp/lins-sp'},
    {link: '/passagem-de-onibus/andradina-sp/jaboticabal-sp'},
    {link: '/passagem-de-onibus/andradina-sp/lavinia-sp'},
    {link: '/passagem-de-onibus/andradina-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/andradina-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/andradina-sp/campinas-sp'},
    {link: '/passagem-de-onibus/andradina-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/andradina-sp/pereirabarreto-sp'},
    {link: '/passagem-de-onibus/andradina-sp/americana-sp'},
    {link: '/passagem-de-onibus/andradina-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/andradina-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/andradina-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/andradina-sp/birigui-sp'},
    {link: '/passagem-de-onibus/andradina-sp/taquaritinga-sp'},
    {link: '/passagem-de-onibus/andradina-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/andradina-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/andradina-sp/bauru-sp'},
    {link: '/passagem-de-onibus/andradina-sp/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/bauru-sp/resende-rj'},
    {link: '/passagem-de-onibus/bauru-sp/ribeiraogrande-sp'},
    {link: '/passagem-de-onibus/bauru-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/bauru-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/bauru-sp/birigui-sp'},
    {link: '/passagem-de-onibus/bauru-sp/treslagoas-ms'},
    {link: '/passagem-de-onibus/bauru-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/bauru-sp/andradina-sp'},
    {link: '/passagem-de-onibus/bauru-sp/valparaiso-sp'},
    {link: '/passagem-de-onibus/bauru-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/bauru-sp/jau-sp'},
    {link: '/passagem-de-onibus/bauru-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/bauru-sp/ilhasolteira-sp'},
    {link: '/passagem-de-onibus/bauru-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/bauru-sp/araraquara-sp'},
    {link: '/passagem-de-onibus/bauru-sp/pederneiras-sp'},
    {link: '/passagem-de-onibus/bauru-sp/saocarlos-sp'},
    {link: '/passagem-de-onibus/bauru-sp/pirajui-sp'},
    {link: '/passagem-de-onibus/bauru-sp/promissao-sp'},
    {link: '/passagem-de-onibus/bauru-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/bauru-sp/avanhandava-sp'},
    {link: '/passagem-de-onibus/bauru-sp/boaesperancadosul-sp'},
    {link: '/passagem-de-onibus/bauru-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/bauru-sp/cafelandia,sp-sp'},
    {link: '/passagem-de-onibus/bauru-sp/dourado-sp'},
    {link: '/passagem-de-onibus/bauru-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/bauru-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/bauru-sp/guaicara-sp'},
    {link: '/passagem-de-onibus/bauru-sp/josebonifacio-sp'},
    {link: '/passagem-de-onibus/bauru-sp/lins-sp'},
    {link: '/passagem-de-onibus/santos-sp/lins-sp'},
    {link: '/passagem-de-onibus/santos-sp/penapolis-sp'},
    {link: '/passagem-de-onibus/santos-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/santos-sp/birigui-sp'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/mirandopolis-sp'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/lins-sp'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/andradina-sp'},

    {link: '/passagem-de-onibus/anapolis-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/anapolis-go'},
    {link: '/passagem-de-onibus/anapolis-go/ituiutaba-mg'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/americana-sp'},
    {link: '/passagem-de-onibus/centralina-mg/brasilia-df'},
    {link: '/passagem-de-onibus/anapolis-go/vianopolis-go'},
    {link: '/passagem-de-onibus/anapolis-go/silvania-go'},
    {link: '/passagem-de-onibus/anapolis-go/morrinhos-go'},
    {link: '/passagem-de-onibus/vianopolis-go/anapolis-go'},
    {link: '/passagem-de-onibus/brasilia-df/ituiutaba-mg'},
    {link: '/passagem-de-onibus/brasilia-df/canapolis-mg'},
    {link: '/passagem-de-onibus/brasilia-df/centralina-mg'},
    {link: '/passagem-de-onibus/silvania-go/anapolis-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/limeira-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/anapolis-go'},
    {link: '/passagem-de-onibus/ituiutaba-mg/brasilia-df'},
    {link: '/passagem-de-onibus/ituiutaba-mg/brasilia-df'},
    {link: '/passagem-de-onibus/capinopolis-mg/brasilia-df'},
    {link: '/passagem-de-onibus/canapolis-mg/anapolis-go'},
    {link: '/passagem-de-onibus/canapolis-mg/brasilia-df'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/jundiai-sp'},
    {link: '/passagem-de-onibus/orizona-go/frutal-mg'},
    {link: '/passagem-de-onibus/centralina-mg/anapolis-go'},
    {link: '/passagem-de-onibus/anapolis-go/canapolis-mg'},
    {link: '/passagem-de-onibus/anapolis-go/centralina-mg'},
    {link: '/passagem-de-onibus/caldasnovas-go/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/caldasnovas-go/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/frutal-mg'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/brasilia-df/barretos-sp'},
    {link: '/passagem-de-onibus/prata-mg/brasilia-df'},
    {link: '/passagem-de-onibus/jundiai-sp/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/jundiai-sp/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/barretos-sp/araguari-mg'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/jundiai-sp'},
    {link: '/passagem-de-onibus/vianopolis-go/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/barretos-sp'},
    {link: '/passagem-de-onibus/brasilia-df/prata-mg'},
    {link: '/passagem-de-onibus/brasilia-df/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/morrinhos-go/capinopolis-mg'},
    {link: '/passagem-de-onibus/morrinhos-go/anapolis-go'},
    {link: '/passagem-de-onibus/orizona-go/barretos-sp'},
    {link: '/passagem-de-onibus/frutal-mg/araguari-mg'},
    {link: '/passagem-de-onibus/orizona-go/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/frutal-mg/caldasnovas-go'},
    {link: '/passagem-de-onibus/frutal-mg/barretos-sp'},
    {link: '/passagem-de-onibus/piresdorio-go/frutal-mg'},
    {link: '/passagem-de-onibus/araguari-mg/frutal-mg'},
    {link: '/passagem-de-onibus/piresdorio-go/barretos-sp'},
    {link: '/passagem-de-onibus/prata-mg/orizona-go'},
    {link: '/passagem-de-onibus/prata-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/prata-mg/araguari-mg'},
    {link: '/passagem-de-onibus/prata-mg/vianopolis-go'},
    {link: '/passagem-de-onibus/prata-mg/caldasnovas-go'},
    {link: '/passagem-de-onibus/prata-mg/luziania-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/barretos-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/frutal-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/barretos-sp'},
    {link: '/passagem-de-onibus/vianopolis-go/frutal-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/orizona-go'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/vianopolis-go'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/caldasnovas-go'},
    {link: '/passagem-de-onibus/brasilia-df/capinopolis-mg'},
    {link: '/passagem-de-onibus/barretos-sp/orizona-go'},
    {link: '/passagem-de-onibus/barretos-sp/frutal-mg'},
    {link: '/passagem-de-onibus/barretos-sp/vianopolis-go'},
    {link: '/passagem-de-onibus/luziania-go/prata-mg'},
    {link: '/passagem-de-onibus/barretos-sp/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/barretos-sp/luziania-go'},
    {link: '/passagem-de-onibus/araguari-mg/prata-mg'},
    {link: '/passagem-de-onibus/araguari-mg/barretos-sp'},
    {link: '/passagem-de-onibus/piresdorio-go/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/frutal-mg/orizona-go'},
    {link: '/passagem-de-onibus/frutal-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/frutal-mg/vianopolis-go'},
    {link: '/passagem-de-onibus/frutal-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/frutal-mg/brasilia-df'},
    {link: '/passagem-de-onibus/caldasnovas-go/prata-mg'},
    {link: '/passagem-de-onibus/luziania-go/barretos-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/piresdorio-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/brasilia-df/frutal-mg'},
    {link: '/passagem-de-onibus/barretos-sp/caldasnovas-go'},
    {link: '/passagem-de-onibus/barretos-sp/brasilia-df'},
    {link: '/passagem-de-onibus/orizona-go/prata-mg'},
    {link: '/passagem-de-onibus/piresdorio-go/prata-mg'},
    {link: '/passagem-de-onibus/vianopolis-go/prata-mg'},
    {link: '/passagem-de-onibus/vianopolis-go/barretos-sp'},
    {link: '/passagem-de-onibus/barretos-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/barretos-sp/piresdorio-go'},
    {link: '/passagem-de-onibus/goiania-go/goiatuba-go'},
    {link: '/passagem-de-onibus/goiania-go/vianopolis-go'},
    {link: '/passagem-de-onibus/goiania-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/goiania-go/ipameri-go'},
    {link: '/passagem-de-onibus/goiania-go/belavistadegoias-go'},
    {link: '/passagem-de-onibus/goiania-go/urutai-go'},
    {link: '/passagem-de-onibus/goiania-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/goiania-go/orizona-go'},
    {link: '/passagem-de-onibus/goiania-go/capinopolis-mg'},
    {link: '/passagem-de-onibus/goiania-go/catalao-go'},
    {link: '/passagem-de-onibus/goiania-go/piresdorio-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/orizona-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/catalao-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/piresdorio-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/vianopolis-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/ipameri-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/urutai-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/luziania-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/silvania-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/maurilandia-go'},
    {link: '/passagem-de-onibus/catalao-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/edealina-go/pontalina-go'},
    {link: '/passagem-de-onibus/edealina-go/goiatuba-go'},
    {link: '/passagem-de-onibus/edealina-go/edeia-go'},
    {link: '/passagem-de-onibus/edealina-go/joviania-go'},
    {link: '/passagem-de-onibus/edealina-go/vicentinopolis-go'},
    {link: '/passagem-de-onibus/edealina-go/panama-go'},
    {link: '/passagem-de-onibus/edealina-go/itumbiara-go'},
    {link: '/passagem-de-onibus/piresdorio-go/goiania-go'},
    {link: '/passagem-de-onibus/piresdorio-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/orizona-go/silvania-go'},
    {link: '/passagem-de-onibus/orizona-go/morrinhos-go'},
    {link: '/passagem-de-onibus/orizona-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/orizona-go/itumbiara-go'},
    {link: '/passagem-de-onibus/capinopolis-mg/goiania-go'},
    {link: '/passagem-de-onibus/capinopolis-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/canapolis-mg/capinopolis-mg'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/rioverde-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/sarandi-go-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/castelandia-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/itumbiara-go'},
    {link: '/passagem-de-onibus/orizona-go/goiania-go'},
    {link: '/passagem-de-onibus/orizona-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/orizona-go/catalao-go'},
    {link: '/passagem-de-onibus/pontalina-go/edeia-go'},
    {link: '/passagem-de-onibus/pontalina-go/joviania-go'},
    {link: '/passagem-de-onibus/pontalina-go/vicentinopolis-go'},
    {link: '/passagem-de-onibus/pontalina-go/panama-go'},
    {link: '/passagem-de-onibus/pontalina-go/itumbiara-go'},
    {link: '/passagem-de-onibus/bonfinopolis-go/goiania-go'},
    {link: '/passagem-de-onibus/piresdorio-go/catalao-go'},
    {link: '/passagem-de-onibus/piresdorio-go/goiatuba-go'},
    {link: '/passagem-de-onibus/piresdorio-go/ipameri-go'},
    {link: '/passagem-de-onibus/piresdorio-go/brasilia-df'},
    {link: '/passagem-de-onibus/piresdorio-go/urutai-go'},
    {link: '/passagem-de-onibus/goiania-go/luziania-go'},
    {link: '/passagem-de-onibus/goiania-go/silvania-go'},
    {link: '/passagem-de-onibus/goiania-go/morrinhos-go'},
    {link: '/passagem-de-onibus/goiania-go/morrinhos-go'},
    {link: '/passagem-de-onibus/goiania-go/hidrolandia-go'},
    {link: '/passagem-de-onibus/goiania-go/panama-go'},
    {link: '/passagem-de-onibus/goiania-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/goiania-go/itumbiara-go'},
    {link: '/passagem-de-onibus/pontalina-go/edealina-go'},
    {link: '/passagem-de-onibus/pontalina-go/goiatuba-go'},
    {link: '/passagem-de-onibus/catalao-go/goiania-go'},
    {link: '/passagem-de-onibus/catalao-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/catalao-go/orizona-go'},
    {link: '/passagem-de-onibus/catalao-go/piresdorio-go'},
    {link: '/passagem-de-onibus/catalao-go/vianopolis-go'},
    {link: '/passagem-de-onibus/catalao-go/ipameri-go'},
    {link: '/passagem-de-onibus/catalao-go/urutai-go'},
    {link: '/passagem-de-onibus/catalao-go/silvania-go'},
    {link: '/passagem-de-onibus/orizona-go/goiatuba-go'},
    {link: '/passagem-de-onibus/orizona-go/ipameri-go'},
    {link: '/passagem-de-onibus/orizona-go/brasilia-df'},
    {link: '/passagem-de-onibus/orizona-go/urutai-go'},
    {link: '/passagem-de-onibus/goiatuba-go/luziania-go'},
    {link: '/passagem-de-onibus/goiatuba-go/morrinhos-go'},
    {link: '/passagem-de-onibus/goiatuba-go/hidrolandia-go'},
    {link: '/passagem-de-onibus/goiatuba-go/panama-go'},
    {link: '/passagem-de-onibus/goiatuba-go/itumbiara-go'},
    {link: '/passagem-de-onibus/maurilandia-go/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/maurilandia-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/maurilandia-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/maurilandia-go/rioverde-go'},
    {link: '/passagem-de-onibus/maurilandia-go/sarandi-go-go'},
    {link: '/passagem-de-onibus/maurilandia-go/castelandia-go'},
    {link: '/passagem-de-onibus/maurilandia-go/itumbiara-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/maurilandia-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/rioverde-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/sarandi-go-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/castelandia-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/itumbiara-go'},
    {link: '/passagem-de-onibus/piresdorio-go/silvania-go'},
    {link: '/passagem-de-onibus/piresdorio-go/morrinhos-go'},
    {link: '/passagem-de-onibus/piresdorio-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/piresdorio-go/itumbiara-go'},
    {link: '/passagem-de-onibus/goiatuba-go/goiania-go'},
    {link: '/passagem-de-onibus/goiatuba-go/pontalina-go'},
    {link: '/passagem-de-onibus/goiatuba-go/orizona-go'},
    {link: '/passagem-de-onibus/goiatuba-go/edealina-go'},
    {link: '/passagem-de-onibus/goiatuba-go/piresdorio-go'},
    {link: '/passagem-de-onibus/edeia-go/panama-go'},
    {link: '/passagem-de-onibus/edeia-go/itumbiara-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/goiania-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/goiatuba-go'},
    {link: '/passagem-de-onibus/goiatuba-go/vianopolis-go'},
    {link: '/passagem-de-onibus/goiatuba-go/edeia-go'},
    {link: '/passagem-de-onibus/goiatuba-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/goiatuba-go/brasilia-df'},
    {link: '/passagem-de-onibus/goiatuba-go/brasilia-df'},
    {link: '/passagem-de-onibus/goiatuba-go/professorjamil-go'},
    {link: '/passagem-de-onibus/goiatuba-go/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/goiatuba-go/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/goiatuba-go/joviania-go'},
    {link: '/passagem-de-onibus/goiatuba-go/vicentinopolis-go'},
    {link: '/passagem-de-onibus/vianopolis-go/silvania-go'},
    {link: '/passagem-de-onibus/vianopolis-go/morrinhos-go'},
    {link: '/passagem-de-onibus/vianopolis-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/vianopolis-go/itumbiara-go'},
    {link: '/passagem-de-onibus/edeia-go/pontalina-go'},
    {link: '/passagem-de-onibus/edeia-go/edealina-go'},
    {link: '/passagem-de-onibus/edeia-go/goiatuba-go'},
    {link: '/passagem-de-onibus/edeia-go/joviania-go'},
    {link: '/passagem-de-onibus/edeia-go/vicentinopolis-go'},
    {link: '/passagem-de-onibus/vianopolis-go/goiatuba-go'},
    {link: '/passagem-de-onibus/vianopolis-go/ipameri-go'},
    {link: '/passagem-de-onibus/vianopolis-go/brasilia-df'},
    {link: '/passagem-de-onibus/vianopolis-go/urutai-go'},
    {link: '/passagem-de-onibus/centralina-mg/capinopolis-mg'},
    {link: '/passagem-de-onibus/vianopolis-go/goiania-go'},
    {link: '/passagem-de-onibus/vianopolis-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/vianopolis-go/catalao-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/maurilandia-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/rioverde-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/sarandi-go-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/castelandia-go'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/itumbiara-go'},
    {link: '/passagem-de-onibus/joviania-go/panama-go'},
    {link: '/passagem-de-onibus/joviania-go/itumbiara-go'},
    {link: '/passagem-de-onibus/vicentinopolis-go/pontalina-go'},
    {link: '/passagem-de-onibus/vicentinopolis-go/edealina-go'},
    {link: '/passagem-de-onibus/vicentinopolis-go/goiatuba-go'},
    {link: '/passagem-de-onibus/vicentinopolis-go/edeia-go'},
    {link: '/passagem-de-onibus/vicentinopolis-go/joviania-go'},
    {link: '/passagem-de-onibus/vicentinopolis-go/panama-go'},
    {link: '/passagem-de-onibus/vicentinopolis-go/itumbiara-go'},
    {link: '/passagem-de-onibus/luziania-go/goiania-go'},
    {link: '/passagem-de-onibus/luziania-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/morrinhos-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/itumbiara-go'},
    {link: '/passagem-de-onibus/joviania-go/pontalina-go'},
    {link: '/passagem-de-onibus/joviania-go/edealina-go'},
    {link: '/passagem-de-onibus/joviania-go/goiatuba-go'},
    {link: '/passagem-de-onibus/joviania-go/edeia-go'},
    {link: '/passagem-de-onibus/joviania-go/vicentinopolis-go'},
    {link: '/passagem-de-onibus/brasilia-df/morrinhos-go'},
    {link: '/passagem-de-onibus/brasilia-df/itumbiara-go'},
    {link: '/passagem-de-onibus/sarandi-go-go/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/sarandi-go-go/maurilandia-go'},
    {link: '/passagem-de-onibus/sarandi-go-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/sarandi-go-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/sarandi-go-go/rioverde-go'},
    {link: '/passagem-de-onibus/sarandi-go-go/castelandia-go'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/goiatuba-go'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/professorjamil-go'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/morrinhos-go'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/hidrolandia-go'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/panama-go'},
    {link: '/passagem-de-onibus/urutai-go/goiania-go'},
    {link: '/passagem-de-onibus/urutai-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/sarandi-go-go/itumbiara-go'},
    {link: '/passagem-de-onibus/professorjamil-go/goiania-go'},
    {link: '/passagem-de-onibus/professorjamil-go/goiatuba-go'},
    {link: '/passagem-de-onibus/professorjamil-go/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/professorjamil-go/morrinhos-go'},
    {link: '/passagem-de-onibus/professorjamil-go/morrinhos-go'},
    {link: '/passagem-de-onibus/professorjamil-go/hidrolandia-go'},
    {link: '/passagem-de-onibus/professorjamil-go/panama-go'},
    {link: '/passagem-de-onibus/professorjamil-go/itumbiara-go'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/goiania-go'},
    {link: '/passagem-de-onibus/urutai-go/orizona-go'},
    {link: '/passagem-de-onibus/urutai-go/catalao-go'},
    {link: '/passagem-de-onibus/urutai-go/piresdorio-go'},
    {link: '/passagem-de-onibus/urutai-go/vianopolis-go'},
    {link: '/passagem-de-onibus/urutai-go/ipameri-go'},
    {link: '/passagem-de-onibus/urutai-go/silvania-go'},
    {link: '/passagem-de-onibus/urutai-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/goiatuba-go'},
    {link: '/passagem-de-onibus/ipameri-go/vianopolis-go'},
    {link: '/passagem-de-onibus/ipameri-go/urutai-go'},
    {link: '/passagem-de-onibus/ipameri-go/silvania-go'},
    {link: '/passagem-de-onibus/ipameri-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/rioverde-go/maurilandia-go'},
    {link: '/passagem-de-onibus/rioverde-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/rioverde-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/rioverde-go/sarandi-go-go'},
    {link: '/passagem-de-onibus/rioverde-go/castelandia-go'},
    {link: '/passagem-de-onibus/rioverde-go/itumbiara-go'},
    {link: '/passagem-de-onibus/brasilia-df/goiatuba-go'},
    {link: '/passagem-de-onibus/brasilia-df/goiatuba-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/morrinhos-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/itumbiara-go'},
    {link: '/passagem-de-onibus/ipameri-go/goiania-go'},
    {link: '/passagem-de-onibus/ipameri-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/ipameri-go/orizona-go'},
    {link: '/passagem-de-onibus/ipameri-go/catalao-go'},
    {link: '/passagem-de-onibus/ipameri-go/piresdorio-go'},
    {link: '/passagem-de-onibus/silvania-go/goiania-go'},
    {link: '/passagem-de-onibus/silvania-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/silvania-go/orizona-go'},
    {link: '/passagem-de-onibus/silvania-go/catalao-go'},
    {link: '/passagem-de-onibus/silvania-go/piresdorio-go'},
    {link: '/passagem-de-onibus/silvania-go/vianopolis-go'},
    {link: '/passagem-de-onibus/silvania-go/ipameri-go'},
    {link: '/passagem-de-onibus/silvania-go/urutai-go'},
    {link: '/passagem-de-onibus/silvania-go/luziania-go'},
    {link: '/passagem-de-onibus/silvania-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/morrinhos-go/goiania-go'},
    {link: '/passagem-de-onibus/panama-go/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/panama-go/joviania-go'},
    {link: '/passagem-de-onibus/panama-go/vicentinopolis-go'},
    {link: '/passagem-de-onibus/panama-go/morrinhos-go'},
    {link: '/passagem-de-onibus/panama-go/hidrolandia-go'},
    {link: '/passagem-de-onibus/panama-go/itumbiara-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/goiania-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/bonfinopolis-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/orizona-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/catalao-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/piresdorio-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/vianopolis-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/ipameri-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/urutai-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/luziania-go'},
    {link: '/passagem-de-onibus/leopoldodebulhoes-go/silvania-go'},
    {link: '/passagem-de-onibus/castelandia-go/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/castelandia-go/maurilandia-go'},
    {link: '/passagem-de-onibus/castelandia-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/morrinhos-go/panama-go'},
    {link: '/passagem-de-onibus/morrinhos-go/itumbiara-go'},
    {link: '/passagem-de-onibus/morrinhos-go/goiatuba-go'},
    {link: '/passagem-de-onibus/morrinhos-go/morrinhos-go'},
    {link: '/passagem-de-onibus/morrinhos-go/panama-go'},
    {link: '/passagem-de-onibus/morrinhos-go/itumbiara-go'},
    {link: '/passagem-de-onibus/hidrolandia-go/goiatuba-go'},
    {link: '/passagem-de-onibus/hidrolandia-go/professorjamil-go'},
    {link: '/passagem-de-onibus/hidrolandia-go/morrinhos-go'},
    {link: '/passagem-de-onibus/hidrolandia-go/morrinhos-go'},
    {link: '/passagem-de-onibus/hidrolandia-go/panama-go'},
    {link: '/passagem-de-onibus/hidrolandia-go/itumbiara-go'},
    {link: '/passagem-de-onibus/panama-go/goiania-go'},
    {link: '/passagem-de-onibus/panama-go/pontalina-go'},
    {link: '/passagem-de-onibus/panama-go/edealina-go'},
    {link: '/passagem-de-onibus/panama-go/goiatuba-go'},
    {link: '/passagem-de-onibus/panama-go/edeia-go'},
    {link: '/passagem-de-onibus/panama-go/professorjamil-go'},
    {link: '/passagem-de-onibus/luziania-go/goiatuba-go'},
    {link: '/passagem-de-onibus/luziania-go/silvania-go'},
    {link: '/passagem-de-onibus/luziania-go/morrinhos-go'},
    {link: '/passagem-de-onibus/luziania-go/leopoldodebulhoes-go'},
    {link: '/passagem-de-onibus/luziania-go/itumbiara-go'},
    {link: '/passagem-de-onibus/morrinhos-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/morrinhos-go/brasilia-df'},
    {link: '/passagem-de-onibus/morrinhos-go/professorjamil-go'},
    {link: '/passagem-de-onibus/morrinhos-go/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/morrinhos-go/luziania-go'},
    {link: '/passagem-de-onibus/morrinhos-go/hidrolandia-go'},
    {link: '/passagem-de-onibus/morrinhos-go/orizona-go'},
    {link: '/passagem-de-onibus/morrinhos-go/piresdorio-go'},
    {link: '/passagem-de-onibus/morrinhos-go/goiatuba-go'},
    {link: '/passagem-de-onibus/morrinhos-go/vianopolis-go'},
    {link: '/passagem-de-onibus/castelandia-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/castelandia-go/rioverde-go'},
    {link: '/passagem-de-onibus/castelandia-go/sarandi-go-go'},
    {link: '/passagem-de-onibus/castelandia-go/itumbiara-go'},
    {link: '/passagem-de-onibus/itumbiara-go/goiania-go'},
    {link: '/passagem-de-onibus/itumbiara-go/pontalina-go'},
    {link: '/passagem-de-onibus/itumbiara-go/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/itumbiara-go/orizona-go'},
    {link: '/passagem-de-onibus/itumbiara-go/capinopolis-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/itumbiara-go/rioverde-go'},
    {link: '/passagem-de-onibus/itumbiara-go/brasilia-df'},
    {link: '/passagem-de-onibus/itumbiara-go/sarandi-go-go'},
    {link: '/passagem-de-onibus/itumbiara-go/professorjamil-go'},
    {link: '/passagem-de-onibus/itumbiara-go/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/itumbiara-go/joviania-go'},
    {link: '/passagem-de-onibus/itumbiara-go/vicentinopolis-go'},
    {link: '/passagem-de-onibus/itumbiara-go/edealina-go'},
    {link: '/passagem-de-onibus/itumbiara-go/piresdorio-go'},
    {link: '/passagem-de-onibus/itumbiara-go/goiatuba-go'},
    {link: '/passagem-de-onibus/itumbiara-go/maurilandia-go'},
    {link: '/passagem-de-onibus/itumbiara-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/itumbiara-go/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/itumbiara-go/vianopolis-go'},
    {link: '/passagem-de-onibus/itumbiara-go/edeia-go'},
    {link: '/passagem-de-onibus/itumbiara-go/luziania-go'},
    {link: '/passagem-de-onibus/itumbiara-go/morrinhos-go'},
    {link: '/passagem-de-onibus/itumbiara-go/hidrolandia-go'},
    {link: '/passagem-de-onibus/itumbiara-go/panama-go'},
    {link: '/passagem-de-onibus/itumbiara-go/castelandia-go'},

    {link: '/passagem-de-onibus/jequie-ba/mairipora-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/mairipora-sp'},
    {link: '/passagem-de-onibus/milagres,ba-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/santoestevao-ba/mairipora-sp'},
    {link: '/passagem-de-onibus/milagres,ba-ba/mairipora-sp'},
    {link: '/passagem-de-onibus/pousoalegre-mg/novaminacao-ba'},
    {link: '/passagem-de-onibus/trescoracoes-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/trescoracoes-mg/olindina-ba'},
    {link: '/passagem-de-onibus/trescoracoes-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/acajutiba-ba/trescoracoes-mg'},
    {link: '/passagem-de-onibus/acajutiba-ba/mairipora-sp'},
    {link: '/passagem-de-onibus/acajutiba-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/trescoracoes-mg/acajutiba-ba'},
    {link: '/passagem-de-onibus/trescoracoes-mg/osasco-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/novaminacao-ba'},
    {link: '/passagem-de-onibus/divisaalegre-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/crisopolis-ba/mairipora-sp'},
    {link: '/passagem-de-onibus/entrerios-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/entrerios-ba'},
    {link: '/passagem-de-onibus/entrerios-ba/salinas-mg'},
    {link: '/passagem-de-onibus/jequie-ba/trescoracoes-mg'},
    {link: '/passagem-de-onibus/novaminacao-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/novaminacao-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/novaminacao-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/betim-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/pocoes-ba/trescoracoes-mg'},
    {link: '/passagem-de-onibus/mairipora-sp/acajutiba-ba'},
    {link: '/passagem-de-onibus/mairipora-sp/crisopolis-ba'},
    {link: '/passagem-de-onibus/mairipora-sp/novaminacao-ba'},
    {link: '/passagem-de-onibus/mairipora-sp/apora-ba'},
    {link: '/passagem-de-onibus/novaminacao-ba/mairipora-sp'},
    {link: '/passagem-de-onibus/novaminacao-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/novaminacao-ba/salinas-mg'},
    {link: '/passagem-de-onibus/olindina-ba/trescoracoes-mg'},
    {link: '/passagem-de-onibus/guarulhos-sp/novaminacao-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/trescoracoes-mg'},
    {link: '/passagem-de-onibus/osasco-sp/trescoracoes-mg'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/acajutiba-ba'},
    {link: '/passagem-de-onibus/apora-ba/mairipora-sp'},
    {link: '/passagem-de-onibus/salinas-mg/entrerios-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/trescoracoes-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/divisaalegre-mg'},
    {link: '/passagem-de-onibus/trescoracoes-mg/jequie-ba'},
    {link: '/passagem-de-onibus/trescoracoes-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/novaminacao-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/betim-mg'},
    {link: '/passagem-de-onibus/salinas-mg/novaminacao-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/mairipora-sp'},
    {link: '/passagem-de-onibus/pousoalegre-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/salinas-mg'},
    {link: '/passagem-de-onibus/mairipora-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/milagres,ba-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/salinas-mg'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/candidosales-ba'},
    {link: '/passagem-de-onibus/salinas-mg/esplanada-ba'},
    {link: '/passagem-de-onibus/salinas-mg/itapicuru-ba'},
    {link: '/passagem-de-onibus/salinas-mg/olindina-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/alagoinhas-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/candidosales-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/belohorizonte-mg/acajutiba-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/crisopolis-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/esplanada-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/entrerios-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itapicuru-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/santoestevao-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/novaminacao-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/olindina-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/apora-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/acajutiba-ba/salinas-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/acajutiba-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/crisopolis-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/esplanada-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/entrerios-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/itapicuru-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/olindina-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/apora-ba'},
    {link: '/passagem-de-onibus/atibaia-sp/salinas-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/acajutiba-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/acajutiba-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/acajutiba-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/acajutiba-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/acajutiba-ba/osasco-sp'},
    {link: '/passagem-de-onibus/acajutiba-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/acajutiba-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/acajutiba-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/crisopolis-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/crisopolis-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/crisopolis-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/crisopolis-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/crisopolis-ba/osasco-sp'},
    {link: '/passagem-de-onibus/crisopolis-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/crisopolis-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/crisopolis-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/crisopolis-ba/salinas-mg'},
    {link: '/passagem-de-onibus/jequie-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/jequie-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/jequie-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/jequie-ba/salinas-mg'},
    {link: '/passagem-de-onibus/jequie-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/itapicuru-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/itapicuru-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itapicuru-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/itapicuru-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/pousoalegre-mg/acajutiba-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/crisopolis-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/esplanada-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/entrerios-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/jequie-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/itapicuru-ba'},
    {link: '/passagem-de-onibus/esplanada-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/entrerios-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/entrerios-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/entrerios-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/entrerios-ba/osasco-sp'},
    {link: '/passagem-de-onibus/entrerios-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/entrerios-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/jequie-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/pousoalegre-mg/osasco-sp'},
    {link: '/passagem-de-onibus/pousoalegre-mg/santoestevao-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/olindina-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/guarulhos-sp'},
    {link: '/passagem-de-onibus/pousoalegre-mg/apora-ba'},
    {link: '/passagem-de-onibus/pousoalegre-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/pousoalegre-mg/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/pousoalegre-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/olindina-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/guarulhos-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/apora-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/montesclaros-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/esplanada-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/esplanada-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/esplanada-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/esplanada-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/esplanada-ba/osasco-sp'},
    {link: '/passagem-de-onibus/esplanada-ba/betim-mg'},
    {link: '/passagem-de-onibus/esplanada-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/esplanada-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/esplanada-ba/salinas-mg'},
    {link: '/passagem-de-onibus/pousoalegre-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/acajutiba-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/crisopolis-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/esplanada-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/jequie-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/itapicuru-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/santoestevao-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/osasco-sp'},
    {link: '/passagem-de-onibus/itapicuru-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/itapicuru-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/itapicuru-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/itapicuru-ba/salinas-mg'},
    {link: '/passagem-de-onibus/osasco-sp/acajutiba-ba'},
    {link: '/passagem-de-onibus/osasco-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/osasco-sp/pousoalegre-mg'},
    {link: '/passagem-de-onibus/osasco-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/osasco-sp/crisopolis-ba'},
    {link: '/passagem-de-onibus/osasco-sp/esplanada-ba'},
    {link: '/passagem-de-onibus/osasco-sp/entrerios-ba'},
    {link: '/passagem-de-onibus/osasco-sp/jequie-ba'},
    {link: '/passagem-de-onibus/osasco-sp/itapicuru-ba'},
    {link: '/passagem-de-onibus/osasco-sp/santoestevao-ba'},
    {link: '/passagem-de-onibus/osasco-sp/milagres,ba-ba'},
    {link: '/passagem-de-onibus/osasco-sp/pocoes-ba'},
    {link: '/passagem-de-onibus/osasco-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/osasco-sp/novaminacao-ba'},
    {link: '/passagem-de-onibus/osasco-sp/olindina-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/novaminacao-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/novaminacao-ba/osasco-sp'},
    {link: '/passagem-de-onibus/novaminacao-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/olindina-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/olindina-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/olindina-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/olindina-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/olindina-ba/osasco-sp'},
    {link: '/passagem-de-onibus/olindina-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/olindina-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/olindina-ba/salinas-mg'},
    {link: '/passagem-de-onibus/betim-mg/esplanada-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/acajutiba-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/pousoalegre-mg'},
    {link: '/passagem-de-onibus/guarulhos-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/guarulhos-sp/crisopolis-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/esplanada-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/entrerios-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/milagres,ba-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/osasco-sp'},
    {link: '/passagem-de-onibus/milagres,ba-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/milagres,ba-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/milagres,ba-ba/salinas-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/pocoes-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/pocoes-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pocoes-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/pocoes-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/pocoes-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/pocoes-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/pocoes-ba/salinas-mg'},
    {link: '/passagem-de-onibus/santoestevao-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/santoestevao-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/santoestevao-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/santoestevao-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/santoestevao-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/santoestevao-ba/salinas-mg'},
    {link: '/passagem-de-onibus/osasco-sp/apora-ba'},
    {link: '/passagem-de-onibus/osasco-sp/salinas-mg'},
    {link: '/passagem-de-onibus/osasco-sp/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/osasco-sp/alagoinhas-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/acajutiba-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/pousoalegre-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/crisopolis-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/esplanada-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/jequie-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/itapicuru-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/santoestevao-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/pocoes-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/guarulhos-sp'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/apora-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/saopaulo-sp'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/salinas-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/apora-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/saopaulo-sp/acajutiba-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/crisopolis-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/entrerios-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/itapicuru-ba'},
    {link: '/passagem-de-onibus/salinas-mg/guarulhos-sp'},
    {link: '/passagem-de-onibus/salinas-mg/apora-ba'},
    {link: '/passagem-de-onibus/salinas-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/salinas-mg/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/salinas-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/atibaia-sp'},
    {link: '/passagem-de-onibus/guarulhos-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/apora-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/apora-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/apora-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/apora-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/apora-ba/osasco-sp'},
    {link: '/passagem-de-onibus/apora-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/apora-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/apora-ba/salinas-mg'},
    {link: '/passagem-de-onibus/guarulhos-sp/jequie-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/itapicuru-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/santoestevao-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/milagres,ba-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/pocoes-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/olindina-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/apora-ba'},
    {link: '/passagem-de-onibus/guarulhos-sp/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/guarulhos-sp/alagoinhas-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/apora-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/saopaulo-sp/alagoinhas-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/salinas-mg/acajutiba-ba'},
    {link: '/passagem-de-onibus/salinas-mg/atibaia-sp'},
    {link: '/passagem-de-onibus/salinas-mg/crisopolis-ba'},
    {link: '/passagem-de-onibus/salinas-mg/jequie-ba'},
    {link: '/passagem-de-onibus/salinas-mg/osasco-sp'},
    {link: '/passagem-de-onibus/salinas-mg/santoestevao-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/novaminacao-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/olindina-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/belohorizonte-mg'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/pousoalegre-mg'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/montesclaros-mg'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/esplanada-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/jequie-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/osasco-sp'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/santoestevao-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/milagres,ba-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/pocoes-ba'},
    {link: '/passagem-de-onibus/osasco-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/atibaia-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/osasco-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/alagoinhas-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/alagoinhas-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/alagoinhas-ba/salinas-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/alagoinhas-ba/pousoalegre-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/osasco-sp'},

    {link: '/passagem-de-onibus/paranaita-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/apiacas-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/apiacas-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/sinop-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/jangada-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/nobres-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/matupa-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/itauba-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/guarantadonorte-mt/sinop-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/matupa-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/itauba-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/sinop-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/itauba-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/sinop-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/colider-mt'},
    {link: '/passagem-de-onibus/diamantino-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/itauba-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/sinop-mt'},
    {link: '/passagem-de-onibus/jangada-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/jangada-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/jangada-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/jangada-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/jangada-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/jangada-mt/colider-mt'},
    {link: '/passagem-de-onibus/jangada-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/jangada-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/jangada-mt/matupa-mt'},
    {link: '/passagem-de-onibus/jangada-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/jangada-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/jangada-mt/itauba-mt'},
    {link: '/passagem-de-onibus/jangada-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/jangada-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/colider-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/matupa-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/cuiaba-mt/itauba-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/jangada-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/colider-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/matupa-mt'},
    {link: '/passagem-de-onibus/novamutum-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/jangada-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/colider-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/nobres-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/carlinda-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/jangada-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/colider-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/nobres-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/sinop-mt'},
    {link: '/passagem-de-onibus/novabandeirantes-mt/novamonteverde-mt'},
    {link: '/passagem-de-onibus/novabandeirantes-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/jangada-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/matupa-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/itauba-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/lucasdorioverde-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/colider-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/colider-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/jangada-mt/sinop-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/jangada-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/colider-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/nobres-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/matupa-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/itauba-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/sorriso-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/nobres-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/matupa-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/colider-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/colider-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/colider-mt/jangada-mt'},
    {link: '/passagem-de-onibus/colider-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/colider-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/colider-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/colider-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/colider-mt/nobres-mt'},
    {link: '/passagem-de-onibus/colider-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/jangada-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/colider-mt'},
    {link: '/passagem-de-onibus/colider-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/colider-mt/itauba-mt'},
    {link: '/passagem-de-onibus/colider-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/colider-mt/sinop-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/novasantahelena-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/matupa-mt/jangada-mt'},
    {link: '/passagem-de-onibus/matupa-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/matupa-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/matupa-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/matupa-mt/nobres-mt'},
    {link: '/passagem-de-onibus/matupa-mt/itauba-mt'},
    {link: '/passagem-de-onibus/matupa-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/matupa-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/matupa-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/matupa-mt/sinop-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/apiacas-mt'},
    {link: '/passagem-de-onibus/itauba-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/itauba-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/itauba-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/itauba-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/itauba-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/nobres-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/nobres-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/nobres-mt/itauba-mt'},
    {link: '/passagem-de-onibus/nobres-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/nobres-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/nobres-mt/sinop-mt'},
    {link: '/passagem-de-onibus/matupa-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/matupa-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/matupa-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/matupa-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/novamonteverde-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/nobres-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/nobres-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/nobres-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/nobres-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/nobres-mt/colider-mt'},
    {link: '/passagem-de-onibus/nobres-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/nobres-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/nobres-mt/matupa-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/novamonteverde-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/nobres-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/itauba-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/sinop-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/apiacas-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/colider-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/paranaita-mt/sinop-mt'},
    {link: '/passagem-de-onibus/itauba-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/itauba-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/itauba-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/jangada-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/colider-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/altafloresta-mt/novabandeirantes-mt'},
    {link: '/passagem-de-onibus/itauba-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/itauba-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/itauba-mt/jangada-mt'},
    {link: '/passagem-de-onibus/itauba-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/itauba-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/itauba-mt/colider-mt'},
    {link: '/passagem-de-onibus/itauba-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/itauba-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/itauba-mt/nobres-mt'},
    {link: '/passagem-de-onibus/itauba-mt/matupa-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/colider-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/nobres-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/itauba-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/novacanaadonorte-mt/sinop-mt'},
    {link: '/passagem-de-onibus/novamonteverde-mt/novabandeirantes-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/sinop-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/jangada-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/nobres-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/matupa-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/itauba-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/terranovadonorte-mt/sinop-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/jangada-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/sorriso-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/colider-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/matupa-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/itauba-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/rosariooeste-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/sinop-mt/guarantadonorte-mt'},
    {link: '/passagem-de-onibus/sinop-mt/novamutum-mt'},
    {link: '/passagem-de-onibus/sinop-mt/jangada-mt'},
    {link: '/passagem-de-onibus/sinop-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/sinop-mt/colider-mt'},
    {link: '/passagem-de-onibus/sinop-mt/novacanaadonorte-mt'},
    {link: '/passagem-de-onibus/sinop-mt/nobres-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/matupa-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/itauba-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/sinop-mt'},
    {link: '/passagem-de-onibus/sinop-mt/carlinda-mt'},
    {link: '/passagem-de-onibus/sinop-mt/diamantino-mt'},
    {link: '/passagem-de-onibus/sinop-mt/cuiaba-mt'},
    {link: '/passagem-de-onibus/sinop-mt/matupa-mt'},
    {link: '/passagem-de-onibus/sinop-mt/altafloresta-mt'},
    {link: '/passagem-de-onibus/sinop-mt/paranaita-mt'},
    {link: '/passagem-de-onibus/sinop-mt/itauba-mt'},
    {link: '/passagem-de-onibus/sinop-mt/rosariooeste-mt'},
    {link: '/passagem-de-onibus/sinop-mt/terranovadonorte-mt'},
    {link: '/passagem-de-onibus/sinop-mt/peixotodeazevedo-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/lucasdorioverde-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/novasantahelena-mt'},
    {link: '/passagem-de-onibus/peixotodeazevedo-mt/nobres-mt'},

    {link: '/passagem-de-onibus/cristalino-es/saofrancisco,es-es'},
    {link: '/passagem-de-onibus/cristalino-es/novavenecia-es'},
    {link: '/passagem-de-onibus/cristalino-es/novavenecia-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/novavenecia-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/novavenecia-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/vilapavao-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/cristalino-es/guararema-es-es'},
    {link: '/passagem-de-onibus/cristalino-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/cristalino-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/maquina-es/vilapavao-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/cristalino-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/guararema-es-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/saofrancisco,es-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/novavenecia-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/novavenecia-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/cristalino-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/guararema-es-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/maquina-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/saofrancisco,es-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/novavenecia-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/novavenecia-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/vilapaulista-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/vilapavao-es'},
    {link: '/passagem-de-onibus/barradesaofrancisco-es/vilapavao-es'},
    {link: '/passagem-de-onibus/saofrancisco,es-es/cristalino-es'},
    {link: '/passagem-de-onibus/saofrancisco,es-es/guararema-es-es'},
    {link: '/passagem-de-onibus/saofrancisco,es-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/saofrancisco,es-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/saofrancisco,es-es/novavenecia-es'},
    {link: '/passagem-de-onibus/saofrancisco,es-es/novavenecia-es'},
    {link: '/passagem-de-onibus/novavenecia-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/novavenecia-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/novavenecia-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/novavenecia-es/novavenecia-es'},
    {link: '/passagem-de-onibus/novavenecia-es/vilapavao-es'},
    {link: '/passagem-de-onibus/novavenecia-es/cristalino-es'},
    {link: '/passagem-de-onibus/guararema-es-es/cristalino-es'},
    {link: '/passagem-de-onibus/guararema-es-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/guararema-es-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/guararema-es-es/saofrancisco,es-es'},
    {link: '/passagem-de-onibus/guararema-es-es/novavenecia-es'},
    {link: '/passagem-de-onibus/guararema-es-es/novavenecia-es'},
    {link: '/passagem-de-onibus/maquina-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/maquina-es/novavenecia-es'},
    {link: '/passagem-de-onibus/maquina-es/vilapaulista-es'},
    {link: '/passagem-de-onibus/maquina-es/vilapavao-es'},
    {link: '/passagem-de-onibus/vilapavao-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/vilapavao-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/vilapavao-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/vilapavao-es/novavenecia-es'},
    {link: '/passagem-de-onibus/vilapavao-es/novavenecia-es'},
    {link: '/passagem-de-onibus/vilapavao-es/maquina-es'},
    {link: '/passagem-de-onibus/vilapavao-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/vilapavao-es/novavenecia-es'},
    {link: '/passagem-de-onibus/vilapavao-es/vilapaulista-es'},
    {link: '/passagem-de-onibus/vilapavao-es/vilapavao-es'},
    {link: '/passagem-de-onibus/vilapaulista-es/maquina-es'},
    {link: '/passagem-de-onibus/vilapaulista-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/vilapaulista-es/novavenecia-es'},
    {link: '/passagem-de-onibus/vilapaulista-es/vilapavao-es'},
    {link: '/passagem-de-onibus/vilapaulista-es/vilapavao-es'},
    {link: '/passagem-de-onibus/vilapavao-es/maquina-es'},
    {link: '/passagem-de-onibus/vilapavao-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/vilapavao-es/novavenecia-es'},
    {link: '/passagem-de-onibus/vilapavao-es/vilapaulista-es'},
    {link: '/passagem-de-onibus/vilapavao-es/vilapavao-es'},
    {link: '/passagem-de-onibus/novavenecia-es/vilapaulista-es'},
    {link: '/passagem-de-onibus/novavenecia-es/vilapavao-es'},
    {link: '/passagem-de-onibus/novavenecia-es/vilapavao-es'},
    {link: '/passagem-de-onibus/novavenecia-es/vilapavao-es'},
    {link: '/passagem-de-onibus/novavenecia-es/cristalino-es'},
    {link: '/passagem-de-onibus/novavenecia-es/guararema-es-es'},
    {link: '/passagem-de-onibus/novavenecia-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/novavenecia-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/novavenecia-es/saofrancisco,es-es'},
    {link: '/passagem-de-onibus/novavenecia-es/novavenecia-es'},
    {link: '/passagem-de-onibus/novavenecia-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/novavenecia-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/novavenecia-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/novavenecia-es/guararema-es-es'},
    {link: '/passagem-de-onibus/novavenecia-es/maquina-es'},
    {link: '/passagem-de-onibus/novavenecia-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/novavenecia-es/barradesaofrancisco-es'},
    {link: '/passagem-de-onibus/novavenecia-es/saofrancisco,es-es'},
    {link: '/passagem-de-onibus/novavenecia-es/novavenecia-es'},
    {link: '/passagem-de-onibus/novavenecia-es/novavenecia-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/novavenecia-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/novavenecia-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/vilapavao-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/ecoporanga-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/novavenecia-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/novavenecia-es'},
    {link: '/passagem-de-onibus/ecoporanga-es/vilapavao-es'},

];
export default reunidasPaulista;