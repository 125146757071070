import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosAltransportes = {
    ...dadosAgenciaComum,
    id: 'altransportes',
    nome: 'AL Transportes',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    email: 'suportevexado@gmail.com',
    idsTagManager: ['GTM-KXDZW66']
};
