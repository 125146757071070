const rapidoFederal = [

    {link: '/passagem-de-onibus/bomjesusdalapa-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/rioverde-go/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/correntina-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/corrente-pi/brasilia-df'},
    {link: '/passagem-de-onibus/curimata-pi/brasilia-df'},
    {link: '/passagem-de-onibus/itaberaba-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/curimata-pi'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/gama-df'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/julioborges-pi'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/parnagua-pi'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/salvador-ba'},
    {link: '/passagem-de-onibus/goiania-go/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/goiania-go/brasilia-df'},
    {link: '/passagem-de-onibus/goiania-go/brasilia-df'},
    {link: '/passagem-de-onibus/barreiras-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/boavistadotupim-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/corrente-pi'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/feiradesantana-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/curimata-pi'},
    {link: '/passagem-de-onibus/barreiras-ba/gama-df'},
    {link: '/passagem-de-onibus/barreiras-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/goiania-go/parnagua-pi'},
    {link: '/passagem-de-onibus/goiania-go/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/goiania-go/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/goiania-go/brasilia-df'},
    {link: '/passagem-de-onibus/goiania-go/brasilia-df'},
    {link: '/passagem-de-onibus/goiania-go/santaritadecassia-ba'},
    {link: '/passagem-de-onibus/goiania-go/alexania-go'},
    {link: '/passagem-de-onibus/goiania-go/correntina-ba'},
    {link: '/passagem-de-onibus/goiania-go/corrente-pi'},
    {link: '/passagem-de-onibus/goiania-go/curimata-pi'},
    {link: '/passagem-de-onibus/goiania-go/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/goiania-go/gama-df'},
    {link: '/passagem-de-onibus/goiania-go/julioborges-pi'},
    {link: '/passagem-de-onibus/goiania-go/abadiania-go'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/itaberaba-ba'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/corrente-pi'},
    {link: '/passagem-de-onibus/barreiras-ba/parnagua-pi'},
    {link: '/passagem-de-onibus/barreiras-ba/salvador-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/itaberaba-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/formosa-go'},
    {link: '/passagem-de-onibus/itaberaba-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/brasilia-df'},
    {link: '/passagem-de-onibus/itaberaba-ba/sobradinho-df-df'},
    {link: '/passagem-de-onibus/itaberaba-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/seabra-ba'},
    {link: '/passagem-de-onibus/boquira-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/boquira-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/boquira-ba/boavistadotupim-ba'},
    {link: '/passagem-de-onibus/boquira-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/lencois-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/lencois-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/palmeiras-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/posse-go'},
    {link: '/passagem-de-onibus/itaberaba-ba/brasilia-df'},
    {link: '/passagem-de-onibus/itaberaba-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/seabra-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/itaberaba-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/boquira-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/boavistadotupim-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/correntina-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/formosa-go'},
    {link: '/passagem-de-onibus/itaberaba-ba/lencois-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/lencois-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/macaubas-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/planaltina,df-df'},
    {link: '/passagem-de-onibus/itaberaba-ba/palmeiras-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/posse-go'},
    {link: '/passagem-de-onibus/boquira-ba/macaubas-ba'},
    {link: '/passagem-de-onibus/boquira-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/boquira-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/boquira-ba/salvador-ba'},
    {link: '/passagem-de-onibus/boquira-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/boquira-ba/seabra-ba'},
    {link: '/passagem-de-onibus/boavistadotupim-ba/salvador-ba'},
    {link: '/passagem-de-onibus/boavistadotupim-ba/seabra-ba'},
    {link: '/passagem-de-onibus/alexania-go/goiania-go'},
    {link: '/passagem-de-onibus/alexania-go/gama-df'},
    {link: '/passagem-de-onibus/barreiras-ba/julioborges-pi'},
    {link: '/passagem-de-onibus/barreiras-ba/lencois-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/alexania-go/anapolis-go'},
    {link: '/passagem-de-onibus/alexania-go/abadiania-go'},
    {link: '/passagem-de-onibus/alexania-go/brasilia-df'},
    {link: '/passagem-de-onibus/alexania-go/brasilia-df'},
    {link: '/passagem-de-onibus/capimgrosso-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/catalao-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/correntina-ba'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/seabra-ba'},
    {link: '/passagem-de-onibus/curimata-pi/barreiras-ba'},
    {link: '/passagem-de-onibus/curimata-pi/corrente-pi'},
    {link: '/passagem-de-onibus/divinopolisdegoias-go/brasilia-df'},
    {link: '/passagem-de-onibus/catalao-go/araguari-mg'},
    {link: '/passagem-de-onibus/catalao-go/uberaba-mg'},
    {link: '/passagem-de-onibus/corrente-pi/barreiras-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/corrente-pi/goiania-go'},
    {link: '/passagem-de-onibus/corrente-pi/barreiras-ba'},
    {link: '/passagem-de-onibus/corrente-pi/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/corrente-pi/anapolis-go'},
    {link: '/passagem-de-onibus/corrente-pi/planaltina,df-df'},
    {link: '/passagem-de-onibus/corrente-pi/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/corrente-pi/brasilia-df'},
    {link: '/passagem-de-onibus/corrente-pi/brasilia-df'},
    {link: '/passagem-de-onibus/corrente-pi/brasilia-df'},
    {link: '/passagem-de-onibus/corrente-pi/sobradinho-df-df'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/lencois-ba'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/planaltina,df-df'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/rioverde-go'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/salvador-ba'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/brasilia-df'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/brasilia-df'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/sobradinho-df-df'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/lencois-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/salvador-ba'},
    {link: '/passagem-de-onibus/correntina-ba/brasilia-df'},
    {link: '/passagem-de-onibus/correntina-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/corrente-pi/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/cristopolis-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/cristopolis-ba/rioverde-go'},
    {link: '/passagem-de-onibus/cristopolis-ba/salvador-ba'},
    {link: '/passagem-de-onibus/correntina-ba/goiania-go'},
    {link: '/passagem-de-onibus/correntina-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/correntina-ba/anapolis-go'},
    {link: '/passagem-de-onibus/correntina-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/correntina-ba/rioverde-go'},
    {link: '/passagem-de-onibus/correntina-ba/salvador-ba'},
    {link: '/passagem-de-onibus/anapolis-go/brasilia-df'},
    {link: '/passagem-de-onibus/anapolis-go/santaritadecassia-ba'},
    {link: '/passagem-de-onibus/anapolis-go/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/anapolis-go/feiradesantana-ba'},
    {link: '/passagem-de-onibus/iaciara-go/brasilia-df'},
    {link: '/passagem-de-onibus/cristalina-go/araguari-mg'},
    {link: '/passagem-de-onibus/cristalina-go/paracatu-mg'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/brasilia-df'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/sobradinho-df-df'},
    {link: '/passagem-de-onibus/formosa-go/itaberaba-ba'},
    {link: '/passagem-de-onibus/formosa-go/salvador-ba'},
    {link: '/passagem-de-onibus/formosa-go/feiradesantana-ba'},
    {link: '/passagem-de-onibus/araguari-mg/catalao-go'},
    {link: '/passagem-de-onibus/araguari-mg/cristalina-go'},
    {link: '/passagem-de-onibus/araguari-mg/planaltina,df-df'},
    {link: '/passagem-de-onibus/gama-df/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/gama-df/goiania-go'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/seabra-ba'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/goiania-go'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/corrente-pi'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/anapolis-go'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/planaltina,df-df'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/brasilia-df'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/brasilia-df'},
    {link: '/passagem-de-onibus/anapolis-go/corrente-pi'},
    {link: '/passagem-de-onibus/anapolis-go/curimata-pi'},
    {link: '/passagem-de-onibus/anapolis-go/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/anapolis-go/abadiania-go'},
    {link: '/passagem-de-onibus/anapolis-go/parnagua-pi'},
    {link: '/passagem-de-onibus/anapolis-go/salvador-ba'},
    {link: '/passagem-de-onibus/anapolis-go/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/anapolis-go/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/gama-df/barreiras-ba'},
    {link: '/passagem-de-onibus/gama-df/alexania-go'},
    {link: '/passagem-de-onibus/gama-df/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/gama-df/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/gama-df/santaritadecassia-ba'},
    {link: '/passagem-de-onibus/anapolis-go/itaberaba-ba'},
    {link: '/passagem-de-onibus/anapolis-go/alexania-go'},
    {link: '/passagem-de-onibus/anapolis-go/correntina-ba'},
    {link: '/passagem-de-onibus/cristalina-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ibitiara-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/cristalina-go/uberaba-mg'},
    {link: '/passagem-de-onibus/cristalina-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/salvador-ba'},
    {link: '/passagem-de-onibus/lencois-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/lencois-ba/palmeiras-ba'},
    {link: '/passagem-de-onibus/lencois-ba/salvador-ba'},
    {link: '/passagem-de-onibus/lencois-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/lencois-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/lencois-ba/seabra-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/boavistadotupim-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/gama-df'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/lencois-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/catalao-go'},
    {link: '/passagem-de-onibus/uberaba-mg/cristalina-go'},
    {link: '/passagem-de-onibus/uberaba-mg/planaltina,df-df'},
    {link: '/passagem-de-onibus/ibitiara-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/macaubas-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/salvador-ba'},
    {link: '/passagem-de-onibus/ibitiara-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/julioborges-pi/barreiras-ba'},
    {link: '/passagem-de-onibus/julioborges-pi/corrente-pi'},
    {link: '/passagem-de-onibus/abadiania-go/goiania-go'},
    {link: '/passagem-de-onibus/abadiania-go/alexania-go'},
    {link: '/passagem-de-onibus/abadiania-go/anapolis-go'},
    {link: '/passagem-de-onibus/lencois-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/lencois-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/lencois-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/lencois-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/lencois-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/lencois-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/lencois-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/lencois-ba/lencois-ba'},
    {link: '/passagem-de-onibus/lencois-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/abadiania-go/brasilia-df'},
    {link: '/passagem-de-onibus/abadiania-go/brasilia-df'},
    {link: '/passagem-de-onibus/paracatu-mg/cristalina-go'},
    {link: '/passagem-de-onibus/paracatu-mg/brasilia-df'},
    {link: '/passagem-de-onibus/paracatu-mg/luziania-go'},
    {link: '/passagem-de-onibus/lencois-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/lencois-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/lencois-ba/lencois-ba'},
    {link: '/passagem-de-onibus/lencois-ba/salvador-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/boquira-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/macaubas-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/salvador-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/seabra-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/salvador-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/seabra-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/salvador-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/seabra-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/capimgrosso-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/salvador-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/feiradesantana-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/boquira-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/salvador-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/macaubas-ba/seabra-ba'},
    {link: '/passagem-de-onibus/muquemdesaofrancisco-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/catalao-go'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/macaubas-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/salvador-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/seabra-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/oliveiradosbrejinhos-ba/boquira-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/salvador-ba/lencois-ba'},
    {link: '/passagem-de-onibus/salvador-ba/lencois-ba'},
    {link: '/passagem-de-onibus/salvador-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/salvador-ba/macaubas-ba'},
    {link: '/passagem-de-onibus/salvador-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/salvador-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/salvador-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/salvador-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/salvador-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/salvador-ba/planaltina,df-df'},
    {link: '/passagem-de-onibus/planaltina,df-df/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/planaltina,df-df/feiradesantana-ba'},
    {link: '/passagem-de-onibus/palmeiras-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/rioverde-go/cristopolis-ba'},
    {link: '/passagem-de-onibus/rioverde-go/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/rioverde-go/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/salvador-ba/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/paratinga-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/paratinga-ba/salvador-ba'},
    {link: '/passagem-de-onibus/paratinga-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/paratinga-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/paratinga-ba/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/paratinga-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/paratinga-ba/seabra-ba'},
    {link: '/passagem-de-onibus/rioverde-go/correntina-ba'},
    {link: '/passagem-de-onibus/planaltina,df-df/julioborges-pi'},
    {link: '/passagem-de-onibus/planaltina,df-df/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/planaltina,df-df/parnagua-pi'},
    {link: '/passagem-de-onibus/planaltina,df-df/salvador-ba'},
    {link: '/passagem-de-onibus/planaltina,df-df/itaberaba-ba'},
    {link: '/passagem-de-onibus/planaltina,df-df/corrente-pi'},
    {link: '/passagem-de-onibus/planaltina,df-df/curimata-pi'},
    {link: '/passagem-de-onibus/planaltina,df-df/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/planaltina,df-df/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/planaltina,df-df/araguari-mg'},
    {link: '/passagem-de-onibus/palmeiras-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/palmeiras-ba/lencois-ba'},
    {link: '/passagem-de-onibus/palmeiras-ba/salvador-ba'},
    {link: '/passagem-de-onibus/palmeiras-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/parnagua-pi/barreiras-ba'},
    {link: '/passagem-de-onibus/parnagua-pi/corrente-pi'},
    {link: '/passagem-de-onibus/paratinga-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/paratinga-ba/correntina-ba'},
    {link: '/passagem-de-onibus/salvador-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/salvador-ba/boquira-ba'},
    {link: '/passagem-de-onibus/salvador-ba/boavistadotupim-ba'},
    {link: '/passagem-de-onibus/salvador-ba/correntina-ba'},
    {link: '/passagem-de-onibus/salvador-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/salvador-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/salvador-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/salvador-ba/formosa-go'},
    {link: '/passagem-de-onibus/salvador-ba/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/salvador-ba/seabra-ba'},
    {link: '/passagem-de-onibus/posse-go/itaberaba-ba'},
    {link: '/passagem-de-onibus/posse-go/salvador-ba'},
    {link: '/passagem-de-onibus/posse-go/feiradesantana-ba'},
    {link: '/passagem-de-onibus/riachaodasneves-ba/goiania-go'},
    {link: '/passagem-de-onibus/riachaodasneves-ba/corrente-pi'},
    {link: '/passagem-de-onibus/riachaodasneves-ba/gama-df'},
    {link: '/passagem-de-onibus/riachaodasneves-ba/anapolis-go'},
    {link: '/passagem-de-onibus/neropolis-go/brasilia-df'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/goiania-go'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/correntina-ba'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/anapolis-go'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/planaltina,df-df'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/rioverde-go'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/salvador-ba'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/brasilia-df'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/brasilia-df'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/sobradinho-df-df'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/saofelixdocoribe-ba/seabra-ba'},
    {link: '/passagem-de-onibus/brasilia-df/goiania-go'},
    {link: '/passagem-de-onibus/brasilia-df/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/brasilia-df/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/brasilia-df/iaciara-go'},
    {link: '/passagem-de-onibus/brasilia-df/julioborges-pi'},
    {link: '/passagem-de-onibus/brasilia-df/abadiania-go'},
    {link: '/passagem-de-onibus/brasilia-df/paracatu-mg'},
    {link: '/passagem-de-onibus/brasilia-df/alexania-go'},
    {link: '/passagem-de-onibus/brasilia-df/corrente-pi'},
    {link: '/passagem-de-onibus/brasilia-df/corrente-pi'},
    {link: '/passagem-de-onibus/brasilia-df/curimata-pi'},
    {link: '/passagem-de-onibus/brasilia-df/divinopolisdegoias-go'},
    {link: '/passagem-de-onibus/riachaodasneves-ba/brasilia-df'},
    {link: '/passagem-de-onibus/riachaodasneves-ba/brasilia-df'},
    {link: '/passagem-de-onibus/salvador-ba/palmeiras-ba'},
    {link: '/passagem-de-onibus/salvador-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/salvador-ba/posse-go'},
    {link: '/passagem-de-onibus/salvador-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/salvador-ba/brasilia-df'},
    {link: '/passagem-de-onibus/salvador-ba/sobradinho-df-df'},
    {link: '/passagem-de-onibus/salvador-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/brasilia-df/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/brasilia-df/julioborges-pi'},
    {link: '/passagem-de-onibus/brasilia-df/abadiania-go'},
    {link: '/passagem-de-onibus/brasilia-df/parnagua-pi'},
    {link: '/passagem-de-onibus/brasilia-df/salvador-ba'},
    {link: '/passagem-de-onibus/brasilia-df/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/brasilia-df/neropolis-go'},
    {link: '/passagem-de-onibus/brasilia-df/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/sobradinho-df-df/feiradesantana-ba'},
    {link: '/passagem-de-onibus/santaritadecassia-ba/goiania-go'},
    {link: '/passagem-de-onibus/santaritadecassia-ba/gama-df'},
    {link: '/passagem-de-onibus/sobradinho-df-df/curimata-pi'},
    {link: '/passagem-de-onibus/sobradinho-df-df/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/sobradinho-df-df/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/sobradinho-df-df/julioborges-pi'},
    {link: '/passagem-de-onibus/sobradinho-df-df/uberaba-mg'},
    {link: '/passagem-de-onibus/brasilia-df/santaritadecassia-ba'},
    {link: '/passagem-de-onibus/brasilia-df/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/brasilia-df/feiradesantana-ba'},
    {link: '/passagem-de-onibus/sobradinho-df-df/itaberaba-ba'},
    {link: '/passagem-de-onibus/sobradinho-df-df/corrente-pi'},
    {link: '/passagem-de-onibus/sobradinho-df-df/uberlandia-mg'},
    {link: '/passagem-de-onibus/sobradinho-df-df/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/sobradinho-df-df/parnagua-pi'},
    {link: '/passagem-de-onibus/sobradinho-df-df/salvador-ba'},
    {link: '/passagem-de-onibus/sobradinho-df-df/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/brasilia-df/neropolis-go'},
    {link: '/passagem-de-onibus/brasilia-df/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/brasilia-df/saodomingos-go-go'},
    {link: '/passagem-de-onibus/brasilia-df/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/brasilia-df/corrente-pi'},
    {link: '/passagem-de-onibus/brasilia-df/curimata-pi'},
    {link: '/passagem-de-onibus/brasilia-df/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/brasilia-df/julioborges-pi'},
    {link: '/passagem-de-onibus/brasilia-df/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/brasilia-df/santaritadecassia-ba'},
    {link: '/passagem-de-onibus/brasilia-df/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/brasilia-df/goiania-go'},
    {link: '/passagem-de-onibus/brasilia-df/itaberaba-ba'},
    {link: '/passagem-de-onibus/brasilia-df/alexania-go'},
    {link: '/passagem-de-onibus/brasilia-df/correntina-ba'},
    {link: '/passagem-de-onibus/brasilia-df/corrente-pi'},
    {link: '/passagem-de-onibus/brasilia-df/curimata-pi'},
    {link: '/passagem-de-onibus/brasilia-df/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/brasilia-df/parnagua-pi'},
    {link: '/passagem-de-onibus/ibotirama-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/luziania-go/paracatu-mg'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/goiania-go'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/anapolis-go'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/lencois-ba'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/rioverde-go'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/salvador-ba'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/santaritadecassia-ba/brasilia-df'},
    {link: '/passagem-de-onibus/santaritadecassia-ba/brasilia-df'},
    {link: '/passagem-de-onibus/saodomingos-go-go/brasilia-df'},
    {link: '/passagem-de-onibus/ibotirama-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/boavistadotupim-ba'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/brasilia-df'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/feiradesantana-ba/barreiras-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/lencois-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/lencois-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/macaubas-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/feiradesantana-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/boquira-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/boavistadotupim-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/correntina-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/formosa-go'},
    {link: '/passagem-de-onibus/ibotirama-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/salvador-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/lencois-ba'},
    {link: '/passagem-de-onibus/ibotirama-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/seabra-ba'},
    {link: '/passagem-de-onibus/seabra-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/seabra-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/seabra-ba/boquira-ba'},
    {link: '/passagem-de-onibus/seabra-ba/boavistadotupim-ba'},
    {link: '/passagem-de-onibus/seabra-ba/cristopolis-ba'},
    {link: '/passagem-de-onibus/seabra-ba/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/seabra-ba/ibitiara-ba'},
    {link: '/passagem-de-onibus/seabra-ba/lencois-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/planaltina,df-df'},
    {link: '/passagem-de-onibus/feiradesantana-ba/palmeiras-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/posse-go'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/brasilia-df'},
    {link: '/passagem-de-onibus/feiradesantana-ba/sobradinho-df-df'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ibotirama-ba'},
    {link: '/passagem-de-onibus/seabra-ba/macaubas-ba'},
    {link: '/passagem-de-onibus/seabra-ba/muquemdesaofrancisco-ba'},
    {link: '/passagem-de-onibus/seabra-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/seabra-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/seabra-ba/oliveiradosbrejinhos-ba'},
    {link: '/passagem-de-onibus/seabra-ba/paratinga-ba'},
    {link: '/passagem-de-onibus/seabra-ba/salvador-ba'},
    {link: '/passagem-de-onibus/seabra-ba/saofelixdocoribe-ba'},
    {link: '/passagem-de-onibus/seabra-ba/feiradesantana-ba'},
];
export default rapidoFederal;