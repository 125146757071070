import axios from 'axios'
import {URL} from '../util/ambienteActions';
import {BUSCAR_MEUS_PEDIDOS, SELECIONAR_PEDIDO, BUSCAR_VOUCHER} from '../reducers/pedidosReducer';
import {SLOW_REQUEST_CONFIG} from '../util/loadingUtil';


export function buscarMeusPedidos () {
  const url = `${URL}/pedido/cliente/reservas`;
  return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
      response => dispatch({
        type: BUSCAR_MEUS_PEDIDOS,
        payload: response
      })
  );
}
export function buscarVoucher (idReserva) {
    const url =  `${URL}/voucher/image/${idReserva}`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: BUSCAR_VOUCHER,
            payload: response
        })
    );
}

export function selecionarPedido (pedido) {
    return {
        type: SELECIONAR_PEDIDO,
        payload: pedido
    }
}
