import axios from 'axios'

import {
  SLOW_REQUEST_HEADER,
  SLOW_REQUEST_HEADER_INT_DIST,
  iniciarLoading,
  finalizarLoading,
  iniciarLoadingInteg,
  finalizarLoadingInteg
} from '../util/loadingUtil'

const onRejected = (err) => {
  if (err.config && err.config.headers[SLOW_REQUEST_HEADER]) {
    finalizarLoading()
  }
  if (err.config && err.config.headers[SLOW_REQUEST_HEADER_INT_DIST]) {
    finalizarLoadingInteg()
  }
  return Promise.reject(err)
}

function iniciarLoadingAndInteg(fechar) {
  return new Promise((resolve) => {
    iniciarLoading();

    setTimeout(() => {
      iniciarLoadingInteg(fechar);
      resolve();
    }, 1000);
  });
}

axios.interceptors.request.use(
  (config) => {
    if (config.headers[SLOW_REQUEST_HEADER] || config.headers[SLOW_REQUEST_HEADER_INT_DIST]) {
      const url = config.url;
      const fechar = url.includes('passagem');
      iniciarLoadingAndInteg(fechar);
    }

    return config;
  },
  onRejected
);

function finalizarLoadingAndInteg() {
  return new Promise((resolve) => {
    finalizarLoading();

    setTimeout(() => {
      finalizarLoadingInteg();
      resolve();
    }, 1000);
  });
}

axios.interceptors.response.use(
  (response) => {
    if (response.config.headers[SLOW_REQUEST_HEADER] || response.config.headers[SLOW_REQUEST_HEADER_INT_DIST]) {
      finalizarLoadingAndInteg();
    }

    return response;
  },
  onRejected
);

