import axios from 'axios'


import { SLOW_REQUEST_CONFIG } from '../util/loadingUtil'
import { URL } from '../util/ambienteActions';
import { MARCAR_PROMOCAO_VISUALIZADO } from '../reducers/promocaoReducer';

export const CADASTRAR_PROMOCAO = '@promocoesActions/CADASTRAR_PROMOCAO';

export function cadastrarPromocao(values, captchar) {
    const novoUsuario = {
        nome: values.nome.trim(),
        email: values.email,
        telefone: values.telefone,
        captchar: captchar,
        cidadeOrigem: values.cidadeOrigem,
        cidadeDestino: values.cidadeDestino
    };

    const url = `${URL}/promocoes/public/recebimento/cadastrar`;

    return dispatch => axios.post(url, novoUsuario, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: CADASTRAR_PROMOCAO,
            payload: response,
        }),
    );

}

export function marcarPromocaoComoVisualizado() {
    return {
        type: MARCAR_PROMOCAO_VISUALIZADO,
    }
}