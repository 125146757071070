import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { recuperarConfEmpresa } from '../../../util/applicationContext';
import expressoGuanabara from './linksEmpresas/expressoGuanabara';
import guanabaraUtil from './linksEmpresas/guanabaraUtil';
import realExpresso from './linksEmpresas/realExpresso';
import realTransportes from './linksEmpresas/realTransportes';
import rapidoFederal from './linksEmpresas/rapidoFederal';
import catedral from './linksEmpresas/catedral';
import cantelle from './linksEmpresas/cantelle';
import gontijo from './linksEmpresas/gontijo';
import eucatur from './linksEmpresas/eucatur';
import amatur from './linksEmpresas/amatur';
import emtram from './linksEmpresas/emtram';
import itapemirim from './linksEmpresas/itapemirim';
import util from './linksEmpresas/util';
import viacaoAdamantina from './linksEmpresas/viacaoAdamantina';
import aguiaBranca from './linksEmpresas/aguiaBranca';
import luxor from './linksEmpresas/luxor';
import ouroEprata from './linksEmpresas/ouroEprata';
import andorinha from './linksEmpresas/andorinha';
import brasileiro from './linksEmpresas/brasileiro';
import cidadeDoSol from './linksEmpresas/cidadeDoSol';
import gadotti from './linksEmpresas/gadotti';
import expressoItamarati from './linksEmpresas/expressoItamarati';
import expressoDePrata from './linksEmpresas/expressoDePrata';
import reunidasPaulista from './linksEmpresas/reunidasPaulista';
import expressoFloriano from './linksEmpresas/expressoFloriano';
import viacaoPretti from './linksEmpresas/viacaoPretti';
import expressoSaoLuiz from './linksEmpresas/expressoSaoLuiz';
import expressoJoia from './linksEmpresas/expressoJoia';
import sataliteNorte from './linksEmpresas/sateliteNorte';
import viacaoRioDoce from './linksEmpresas/viacaoRioDoce';
import reunidasTransportes from './linksEmpresas/reunidasTransportes';
import rota from './linksEmpresas/rota';
import rodeRotas from './linksEmpresas/rodeRotas';
import uneSul from './linksEmpresas/uneSul';
import reunidasSa from './linksEmpresas/reunidasSa';
import princesaDoNorte from './linksEmpresas/princesaDoNorte';
import lopesTur from './linksEmpresas/lopesTur';
import guerino from './linksEmpresas/guerino';
import juntos from './linksEmpresas/juntos';
import expressoGardenia from './linksEmpresas/expressoGardenia';

export class PaginaLinksPassagens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [
                ...guanabaraUtil, ...expressoGuanabara, ...realExpresso, ...realTransportes, ...rapidoFederal, ...catedral, ...cantelle, ...gontijo, ...eucatur, ...amatur, ...emtram, ...itapemirim,
                    ...util, ...viacaoAdamantina, ...aguiaBranca, ...luxor, ...ouroEprata, ...andorinha, ...brasileiro, ...cidadeDoSol, ...gadotti, ...expressoItamarati, ...expressoDePrata, ...reunidasPaulista,
                ...expressoFloriano, ...viacaoPretti, ...expressoSaoLuiz, ...expressoJoia, ...sataliteNorte, ...viacaoRioDoce, ...reunidasTransportes, ...rota, ...rodeRotas, ...uneSul, ...reunidasSa, ...princesaDoNorte,
                ...lopesTur, ...guerino, ...juntos, ...expressoGardenia
            ],
            currentPage: 1,
            linksPerPage: 1000,
            filterOrigem: '',
            filterDestino: '',
            maxPageNumbers: 10
        };
    }

    componentDidMount() {
        const baseURL = recuperarConfEmpresa().urlBase;
        const links = this.state.links.map(item => ({
            ...item,
            link: `${baseURL}${item.link}`
        }));
        this.setState({ links });
    }

    extrairCidades(link) {
        const parts = link.split('/');
        const origemDestino = parts.slice(-2);

        const formatarCidade = (cidadeEstado) => {
            const [cidade, estado] = cidadeEstado.split('-');
            const cidadeFormatada = cidade
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/\b\w/g, l => l.toUpperCase());
            const estadoFormatado = estado.toUpperCase();
            return `${cidadeFormatada}-${estadoFormatado}`;
        };

        const origem = formatarCidade(origemDestino[0]);
        const destino = formatarCidade(origemDestino[1]);

        return { origem, destino };
    }

    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    handleFilterChange = (event) => {
        this.setState({
            [event.target.name]: this.removerAcentos(event.target.value),
            currentPage: 1
        });
    }

    removerAcentos(texto) {
        return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '');
    }

    handleNext = () => {
        this.setState((prevState) => ({
            currentPage: Math.min(prevState.currentPage + 1, Math.ceil(this.state.links.length / this.state.linksPerPage))
        }));
    }

    handlePrev = () => {
        this.setState((prevState) => ({
            currentPage: Math.max(prevState.currentPage - 1, 1)
        }));
    }

    handleFirst = () => {
        this.setState({
            currentPage: 1
        });
    }

    handleLast = () => {
        this.setState({
            currentPage: Math.ceil(this.state.links.length / this.state.linksPerPage)
        });
    }

    render() {
        const { links, currentPage, linksPerPage, filterOrigem, filterDestino, maxPageNumbers } = this.state;

        const filteredLinks = links.filter(linkObj => {
            const { origem, destino } = this.extrairCidades(linkObj.link);
            return origem.toLowerCase().includes(filterOrigem.toLowerCase()) &&
                destino.toLowerCase().includes(filterDestino.toLowerCase());
        });

        const indexOfLastLink = currentPage * linksPerPage;
        const indexOfFirstLink = indexOfLastLink - linksPerPage;
        const currentLinks = filteredLinks.slice(indexOfFirstLink, indexOfLastLink);

        const totalPages = Math.ceil(filteredLinks.length / linksPerPage);

        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
        let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

        if (endPage - startPage < maxPageNumbers - 1) {
            startPage = Math.max(1, endPage - maxPageNumbers + 1);
        }

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={this.handleClick}
                    className={number === currentPage ? 'active' : ''}
                    style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', padding: '0.5rem' }}
                >
                    {number}
                </li>
            );
        });

        return (
            <div id='container-topTrechos1' className='container-toptrechos'>
                <div id='containerTituloTrechos' className='titulo-topTrechos'>
                    <span>
                        <b id='tituloTrechos'> Top Trechos {recuperarConfEmpresa().nome} </b>
                    </span>
                    <span id='subTituloTopTrechos'>
                        <b id='subTituloTrechos'> Os trechos mais procurados em nossa central de passagens </b>
                    </span>
                    <div className="filtro-container">
                        <div className="filtro-item">
                            <label htmlFor="filterOrigem">Buscar Origem</label>
                            <input
                                type="text"
                                id="filterOrigem"
                                name="filterOrigem"
                                value={filterOrigem}
                                onChange={this.handleFilterChange}
                                placeholder="Filtrar por origem"
                                className="filtro-input"
                            />
                        </div>
                        <div className="filtro-item">
                            <label htmlFor="filterDestino">Buscar Destino</label>
                            <input
                                type="text"
                                id="filterDestino"
                                name="filterDestino"
                                value={filterDestino}
                                onChange={this.handleFilterChange}
                                placeholder="Filtrar por destino"
                                className="filtro-input"
                            />
                        </div>
                    </div>
                </div>
                <div className="links-e-paginacao">
                    <ul id="page-numbers-top" className="page-numbers">
                        <li onClick={this.handleFirst} style={{ cursor: 'pointer', padding: '0.5rem' }}>Primeira</li>
                        <li onClick={this.handlePrev} style={{ cursor: 'pointer', padding: '0.5rem' }}>Anterior</li>
                        {renderPageNumbers}
                        <li onClick={this.handleNext} style={{ cursor: 'pointer', padding: '0.5rem' }}>Próxima</li>
                        <li onClick={this.handleLast} style={{ cursor: 'pointer', padding: '0.5rem' }}>Última</li>
                    </ul>
                    <div className='container-groupedPagTrechos'>
                        {currentLinks.map((linkObj, index) => {
                            const { origem, destino } = this.extrairCidades(linkObj.link);
                            return (
                                <div key={index} id={`containerPagtopTrechos${index}`}
                                     className='containerPagtopTrechos'>
                                    <div id='container-link-passagem' className='col-md-4 mb-4'>
                                        <div className='card-topTrechos'>
                                            <a id='PagtopTrechosLink' href={linkObj.link} target='_blank'
                                               rel='noopener noreferrer'>
                                                <div className='PagLinkcardTrecho'>
                                                    <div className='container-origemDestino'>
                                                        <div className='icon-topOrigem'>
                                                            <span id='destinoIcons' className="fa fa-map-marker"></span>
                                                            <h3 className='trechoOrigem'>Passagens de ônibus de {origem}
                                                                <b
                                                                    style={{ color: 'black' }}>&nbsp;&nbsp;Para</b>
                                                            </h3>
                                                        </div>
                                                        <div className='icon-topDestino'>
                                                            <span id='arrowTrechos'
                                                                  className='fas fa-arrow-right'></span>
                                                            <h3 className='trechoDestino'>{destino}</h3>
                                                            <span id='trechoDestinoIcons'
                                                                  className="fas fa-map-pin"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <ul id="page-numbers-bottom" className="page-numbers">
                        <li onClick={this.handleFirst} style={{ cursor: 'pointer', padding: '0.5rem' }}>Primeira</li>
                        <li onClick={this.handlePrev} style={{ cursor: 'pointer', padding: '0.5rem' }}>Anterior</li>
                        {renderPageNumbers}
                        <li onClick={this.handleNext} style={{ cursor: 'pointer', padding: '0.5rem' }}>Próxima</li>
                        <li onClick={this.handleLast} style={{ cursor: 'pointer', padding: '0.5rem' }}>Última</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(PaginaLinksPassagens);
