import { connect } from 'react-redux';
import React, { Component } from 'react'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';

import { buscarCidadesPorNome } from '../../actions/cidadeActions'
import SelecaoCidadeAutocomplete from '../comum/SelecaoCidadeAutocomplete';
import {
    buscarPassagens,
    limparDataRetorno,
    limparPassagens,
    selecionarDataIda,
    selecionarDataRetorno,
    selecionarDestino,
    selecionarOrigem,
} from '../../actions/vendasActions';
import { recuperarConfEmpresa } from '../../util/applicationContext';


export class CarouselAgencia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sugestoes: [],
        }
    }

    selecionarOrigem(origem) {
        this.props.selecionarOrigem(origem);
    }

    selecionarDestino(destino) {
        this.props.selecionarDestino(destino);
    }

    buscar() {
        const { origem, destino } = this.props.venda
        let url = `/passagem-de-onibus/${origem.nomeComUfNormalizado}/${destino.nomeComUfNormalizado}/${moment(this.props.dataIdaSelecionada).format('YYYY-MM-DD')}`;
        if (this.props.dataRetornoSelecionada) {
            url = `${url}/${moment(this.props.dataRetornoSelecionada).format('YYYY-MM-DD')}`
        }
        this.props.history.push(url);
    }

    exibirCalendario(ida) {
        let data;

        let minDate = new Date();
        if (ida) {
            if (this.props.dataIdaSelecionada) {
                data = moment(this.props.dataIdaSelecionada).toDate()
            }
        } else {
            minDate = moment(this.props.dataIdaSelecionada).add(1, 'days').toDate();
            if (this.props.dataRetornoSelecionada) {
                data = moment(this.props.dataRetornoSelecionada).toDate()
            }
        }
        return (
            <div style={{ width: '100%' }}>
                <i className="material-icons nav-icon mt-2 ml-2"
                    style={{ position: 'absolute', zIndex: 9998 }}>date_range</i>
                <DatePicker
                    className='form-control form-control-lg campo-busca-data'
                    value={data}
                    selected={data}
                    startDate={moment(this.props.dataIdaSelecionada).toDate()}
                    endDate={moment(this.props.dataRetornoSelecionada).toDate()}
                    minDate={minDate}
                    disabled={!ida && !this.props.dataIdaSelecionada}
                    onChange={date => this.selecionarData(date, ida)}
                    dateFormat="dd/MM/yyyy"
                    withPortal
                    placeholderText='Selecione a data'
                    aria-label='data'
                />
            </div>
        )
    }

    selecionarData(data, ida) {
        this.props.limparPassagens();
        if (ida) {
            this.props.selecionarDataIda(data);
            this.props.limparDataRetorno();
        } else {
            this.props.selecionarDataRetorno(data);
        }
    }

    limpar() {
        this.props.limparDataRetorno();
        this.props.limparPassagens()
    }

    exibirIconeLimparRetorno() {
        if (this.props.dataRetornoSelecionada) {
            return (
                <div>
                    <i className="material-icons mt-4" style={{
                        marginLeft: '-40px',
                        position: 'absolute',
                        cursor: 'pointer',
                    }} onClick={() => this.limpar()}>close</i>
                </div>)
        }
        return '';
    }

    render() {
        const { origem, destino } = this.props.venda
        if (window.location.pathname === '/') {
            return (
                <section id="topo">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                        <div className="carousel-indicators" style={{ height: 150 }}>
                            <div className='pesquisa-tela-inicial col-12' aria-label='pesquisa-tela-inicial'>
                                <div className="input-busca">
                                    <div className="row">
                                        <div className="col-md-3 origemAG">
                                            <label id='OrigemAG' aria-label='Origem' htmlFor="origem" className="form-label col-12">Origem</label>
                                            <SelecaoCidadeAutocomplete aria-hidden='true' icone='near_me' placeholder='Selecione a cidade'
                                                selecionarCidade={this.selecionarOrigem.bind(this)}
                                                value={origem} />
                                        </div>
                                        <div className="col-md-3">
                                            <div className="input-group destinoAG">
                                                <label id='DestinoAG' aria-label='Destino' htmlFor="destino" className="form-label col-12">Destino</label>
                                                <SelecaoCidadeAutocomplete aria-hidden='true' icone='place' placeholder='Selecione a cidade'
                                                    selecionarCidade={this.selecionarDestino.bind(this)}
                                                    value={destino} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="input-group">
                                                <label id='Data-idaAG' aria-label='Data-ida' htmlFor="dt-ida" className="form-label col-12">Data de Ida</label>
                                                {this.exibirCalendario(true)}
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="input-group">
                                                <label id='Data-voltaAG' aria-label='Data-volta' htmlFor="dt-volta" className="form-label col-12">Data de Volta</label>
                                                {this.exibirCalendario()}
                                            </div>
                                        </div>
                                        {this.exibirIconeLimparRetorno()}
                                        <div className="col-md-2 line-btn">
                                            <button disabled={!origem || !destino || !this.props.dataIdaSelecionada}
                                                onClick={() => this.buscar()} className="btn btn-primary">Buscar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img className='banner-inicio desktop' src={recuperarConfEmpresa().videoInicio} alt="banner-inicio"/>
                                <h1>
                                    <p>PASSAGENS <span>DE</span> ÔNIBUS</p>
                                    <p><span>OS </span>MELHORES TRECHOS<span>,</span></p>
                                    <p><span>OS </span>MENORES PREÇOS<span>,</span></p>
                                    <p><span>E A </span>COMODIDADE <span>QUE</span></p>
                                    <p>VOCÊ MERECE!</p>
                                    <p id='espaco'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                    <p id='linha-icon'>&nbsp;<i className='bx bx-check-shield'>&nbsp;&nbsp;&nbsp;</i>Compra 100% Segura!</p>
                                </h1>
                                <img className='banner-inicio mobile' src={recuperarConfEmpresa().imgMobile} alt="banner-inicio"/>
                                <img className='banner-inicio mobile2' src={recuperarConfEmpresa().imgMobile2} alt="banner-inicio"/>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        return '';
    }
}

export const mapStateToProps = state => (
    {
        dataIdaSelecionada: state.venda.dataIdaSelecionada,
        dataRetornoSelecionada: state.venda.dataRetornoSelecionada,
        venda: state.venda,
    });


export default withRouter(connect(mapStateToProps,
    {
        buscarCidadesPorNome,
        selecionarOrigem,
        selecionarDestino,
        selecionarDataRetorno,
        selecionarDataIda,
        limparPassagens,
        limparDataRetorno,
        buscarPassagens,
    })(CarouselAgencia))
