import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {cadastrarCliente} from '../../actions/usuarioActions'
import ModalEsqueciMinhaSenha from './ModalEsqueciMinhaSenha';
import ReCaptchaV2 from 'react-google-recaptcha'
import {renderField, required, requiredCPF, validarIgualdadeSenhas, validarNomeCompleto} from '../../util/inputs';
import {showSuccessMsg} from '../../util/messagesUtil';

class PaginaNovoUsuario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chaveCaptcha: null,
        };
        this.recaptchaRef = React.createRef();
    }

    changeRecaptcha(value) {
        if (value) {
            this.setState({chaveCaptcha: value})
        }
    }

    onSubmit(values) {
        this.props.cadastrarCliente(values).then(() => {
            showSuccessMsg('Cadastro realizado com sucesso! Acesse o link enviado no e-mail informado para ativar seu cadastro.',true);
            this.props.history.push('/login')
        })
    }

    render() {
        const {from} = this.props.location.state || {from: {pathname: '/'}};

        if (this.props.dadosAutenticacao.isAuthenticated) {
            return (<Redirect to={from}/>)
        }

        const {
            handleSubmit, submitting, invalid, pristine,
        } = this.props;

        return (
            <div className="back-login">
                <form className="container form-login" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div className='loginHeader'>
                        <h1>Cadastro</h1>
                        <div className="divider"/>
                    </div>
                    <Field
                        label='Nome completo'
                        name='nome'
                        type='text'
                        id='nome'
                        placeholder=''
                        validate={[required, validarNomeCompleto]}
                        component={renderField}
                    />
                    <Field
                        label='E-mail'
                        name='email'
                        type='email'
                        id='nome'
                        placeholder=''
                        validate={[required]}
                        component={renderField}
                    />
                    <Field
                        name='cpf'
                        id='cpf'
                        placeholder="___.___.___-__"
                        css="form-control"
                        label='CPF'
                        type='cpf'
                        validate={[requiredCPF]}
                        component={renderField}/>
                    <Field
                        label='Telefone'
                        name='telefone'
                        id='telefone'
                        type='celular'
                        placeholder=''
                        validate={[required]}
                        component={renderField}
                    />
                    <Field
                        label='Senha'
                        name='password'
                        id='password'
                        type='password'
                        placeholder=''
                        validate={[required]}
                        component={renderField}
                    />
                    <Field
                        label='Confirmar senha'
                        name='confirmPassword'
                        id='confirmPassword'
                        type='password'
                        placeholder=''
                        validate={[required, validarIgualdadeSenhas]}
                        component={renderField}
                    />
                    <button type='submit' className='btn btn-laranja btn-sistema'
                            disabled={invalid || submitting || pristine || !this.state.chaveCaptcha}>CADASTRAR
                    </button>
                    <div className='col-12 text-center'>
                        <ReCaptchaV2
                            ref={this.recaptchaRef}
                            style={{display: 'inline-block'}}
                            sitekey={'6LfNrvkdAAAAAL2wVMaGIuOwJ0STFzIOavTKhNvw'}
                            onChange={(value) => this.changeRecaptcha(value)}
                            onExpired={() => this.setState({chaveCaptcha: null})}
                            onErrored={() => this.setState({chaveCaptcha: null})}/>
                    </div>
                </form>
                <ModalEsqueciMinhaSenha/>
            </div>

        )
    }
}

export const mapStateToProps = state => (
    {
        dadosAutenticacao: state.usuario.dadosAutenticacao,
    }
)

const reduxConnectEnhance = connect(mapStateToProps, {cadastrarCliente})(PaginaNovoUsuario);

export default reduxForm({
    form: 'NovoUsuarioForm',
})(reduxConnectEnhance)
