import {getAuthentication} from '../seguranca/securityContext'

export const USER_LOGOUT = '@ausuariosReducer/USER_LOGOUT';
export const AUTHENTICATION_FAILED = '@ausuariosReducer/AUTHENTICATION_FAILED';
export const CADASTRAR_CLIENTE = '@ausuariosReducer/CADASTRAR_CLIENTE';
export const USER_AUTHENTICATED = '@ausuariosReducer/USER_AUTHENTICATED';
export const TROCAR_SENHA_USUARIO = '@ausuariosReducer/TROCAR_SENHA_USUARIO';
export const BUSCAR_MEUS_DADOS = '@ausuariosReducer/BUSCAR_MEUS_DADOS';
export const ALTERAR_DADOS_CLIENTE = '@ausuariosReducer/ALTERAR_DADOS_CLIENTE';
export const EXCLUIR_CLIENTE = '@ausuariosReducer/EXCLUIR_CLIENTE';

const initialState = {
    dadosAutenticacao: getAuthentication() || {isAuthenticated: false},
    dadosUsuario: {}
};


export default function (state = initialState, action) {
    switch (action.type) {
        case AUTHENTICATION_FAILED:
        case USER_AUTHENTICATED:
        case ALTERAR_DADOS_CLIENTE:
        case USER_LOGOUT: {
            return {
                ...state,
                dadosAutenticacao: action.payload,
            }
        }
        case BUSCAR_MEUS_DADOS: {
            return {
                ...state,
                dadosUsuario: action.payload,
            }
        }
        default:
            return state
    }
}
