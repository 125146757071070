import React from 'react'
import qs from 'qs'
import axios from 'axios';
import {SLOW_REQUEST_CONFIG} from '../../util/loadingUtil';
import {URL} from '../../util/ambienteActions';
import {showSuccessMsg} from '../../util/messagesUtil';

export class PaginaValidacaoCadastro extends React.Component {
    componentDidMount() {
        const parametros = qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true});
        if (parametros.email != null && parametros.codigo != null) {
            const url = `${URL}/cliente/public/validar-cadastro`;
            const data = {
                email: parametros.email,
                codigo: parametros.codigo,
            };
            axios.post(url, data, SLOW_REQUEST_CONFIG).then(() => {
                showSuccessMsg('Cadastro ativado com sucesso!');
                this.props.history.push('/login');
            })
        }
    }

    render() {
        return ''
    }
}


export default PaginaValidacaoCadastro
