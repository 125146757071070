import React from 'react';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

const ConteudoQuemSomosPolitur = () => (
  <div className="page-contato" style={{fontSize: 16, backgroundColor: '#ffffff'}}>
    <section className="gv-section">
      <div className="section-content">
        <h2 className="gv-title">Quem Somos - Politur Turismo</h2>
        
        {/* <p className="txt-item">A Amarelinho nasceu para ser a sua melhor forma de viajar pelo Brasil. Não queremos ser apenas uma viação, desejamos ser a sua família na estrada.</p>
        <p className="txt-item">Nossas cores são o verde e o amarelo porque acreditamos no nosso país e nos seus valores. Hospitalidade, alegria, honestidade, respeito e alta qualidade são as nossas bandeiras. Até o nosso mascote é uma ave tipicamente brasileira, a arara-canindé, que vai sempre voar com você pelos melhores destinos do Brasil.</p>
        <p className="txt-item">Nos nossos ônibus você sempre vai encontrar mais conforto, segurança, pontualidade e inovação. Esse é o nosso compromisso com você e sua família. São vocês que nos movem a ser cada dia melhores. Após comprar passagens com a Amarelinho, o passageiro irá usufruir dos melhores serviços a bordo.</p>
        <p className="txt-item">Para a Viação Amarelinho segurança é fundamental e temos todo o tipo de cuidado. Por isso, todos os itens do ônibus são controlados numericamente. Os ônibus, ao concluirem a sua viagem, passam por uma revisão. Fora isso, a Amarelinho também tem os seus ônibus rastreados.</p>
        <p className="txt-item">Venha viajar com a gente!</p>
        <h2 className="gv-title" style={{ marginTop: 24 }}>Como funciona?</h2>
        <p className="txt-item">Do jeito mais simples e seguro possível, selecione a sua cidade de partida e, depois, a cidade de chegada. Selecione a data em que irá viajar, e em seguida, aperte em buscar. Prontinho! Aparecerá os melhores horários e valores. Selecione o que mais lhe agrada e siga para o pagamento. Prontinho!</p>        
        <p className="txt-item">Contudo, a nossa empresa quem faz é VOCÊ, fazendo parte de nossos momentos e histórias. Contamos com VOCÊS para continuarmos escrevendo novos capítulos.</p> */}

        

        <p className="txt-item">Há 40 anos, a Politur Turismo tem a missão de levar você ao seu destino com segurança, conforto e excelência. Somos uma empresa referência no setor de transportes, dedicada a oferecer experiências de viagem tranquilas e prazerosas, sempre focando na satisfação de nossos clientes.</p>

        <p className="txt-item">Nosso compromisso com a segurança e o bem-estar é prioridade em cada jornada, garantindo que você desfrute de todo o conforto, desde o embarque até a chegada. Viajar com a Politur é sinônimo de confiança e qualidade, resultado de quatro décadas de dedicação e expertise no transporte de passageiros.</p>

        <p className="txt-item">Na Politur Turismo, cada viagem é uma oportunidade de tornar o seu trajeto parte da sua melhor experiência.</p>

        <section className="gv-cards" style={{ marginTop: 50 }}>
          <div className="gv-cards-wrapper">
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <DevicesOutlinedIcon className="box-item-icon"></DevicesOutlinedIcon>
                  <p>Tecnologia</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Descomplicamos o jeito antigo complicado de comprar uma passagem!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <EmojiObjectsOutlinedIcon className="box-item-icon"></EmojiObjectsOutlinedIcon>
                  <p>Inovação</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Inovamos em um mercado promissor, tecnologia simples, aplicada e segura para você, sua família e amigos.</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <PeopleAltOutlinedIcon className="box-item-icon"></PeopleAltOutlinedIcon>
                  <p>Relacionamento</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Pensamos em você muito mais que um simples cliente, temos você como parte de nossa empresa, sem você não teríamos motivos de estar aqui!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <TrendingUpOutlinedIcon className="box-item-icon"></TrendingUpOutlinedIcon>
                  <p>Agilidade</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Rápido e descomplicado. Clicou, comprou, viajou!</p>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
)

export default ConteudoQuemSomosPolitur

