const realTransportes = [

    {link: '/passagem-de-onibus/galvao-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/campoere-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/guaraciaba-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/galvao-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/guarujadosul-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/lages-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/palmasola-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/campoere-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/campoere-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/campoere-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/campoere-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/campoere-sc/galvao-sc'},
    {link: '/passagem-de-onibus/campoere-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/campoere-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/campoere-sc/lages-sc'},
    {link: '/passagem-de-onibus/campoere-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/campoere-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/anchieta,sc-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/campoere-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/galvao-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/dionisiocerqueira-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/pinhalzinho,sc-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/pinhalzinho,sc-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/pinhalzinho,sc-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/pinhalzinho,sc-sc/lages-sc'},
    {link: '/passagem-de-onibus/pinhalzinho,sc-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/pinhalzinho,sc-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/guarujadosul-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/guarujadosul-sc/lages-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/saomigueldooeste-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/saomigueldooeste-sc/lages-sc'},
    {link: '/passagem-de-onibus/saomigueldooeste-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/saomigueldooeste-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/campoere-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/galvao-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/lages-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/xaxim-sc'},
    {link: '/passagem-de-onibus/maravilha-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/maravilha-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/maravilha-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/maravilha-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/lages-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/camposnovos-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/saomigueldooeste-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/saomigueldooeste-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/saomigueldooeste-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/galvao-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/campoere-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/guaraciaba-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/galvao-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/guarujadosul-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/lages-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/joacaba-sc/xaxim-sc'},
    {link: '/passagem-de-onibus/pinhalzinho,sc-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/guaraciaba-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/guaraciaba-sc/lages-sc'},
    {link: '/passagem-de-onibus/galvao-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/galvao-sc/campoere-sc'},
    {link: '/passagem-de-onibus/galvao-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/galvao-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/galvao-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/galvao-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/galvao-sc/lages-sc'},
    {link: '/passagem-de-onibus/galvao-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/guarujadosul-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/lages-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/xanxere-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/campoere-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/guaraciaba-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/galvao-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/guarujadosul-sc'},
    {link: '/passagem-de-onibus/florianopolis-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/campoere-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/galvao-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/chapeco-sc/lages-sc'},
    {link: '/passagem-de-onibus/chapeco-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/saojosedocedro-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/saojosedocedro-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/saojosedocedro-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/saojosedocedro-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/saojosedocedro-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/saojosedocedro-sc/lages-sc'},
    {link: '/passagem-de-onibus/saojosedocedro-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/maravilha-sc/lages-sc'},
    {link: '/passagem-de-onibus/maravilha-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/maravilha-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/lages-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/lages-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/lages-sc/campoere-sc'},
    {link: '/passagem-de-onibus/lages-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/lages-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/lages-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/lages-sc/guaraciaba-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/anchieta,sc-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/campoere-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/camposnovos-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/xanxere-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/galvao-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/saodomingos-sc-sc/lages-sc'},
    {link: '/passagem-de-onibus/lages-sc/galvao-sc'},
    {link: '/passagem-de-onibus/lages-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/lages-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/lages-sc/guarujadosul-sc'},
    {link: '/passagem-de-onibus/lages-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/lages-sc/maravilha-sc'},
    {link: '/passagem-de-onibus/lages-sc/chapeco-sc'},
    {link: '/passagem-de-onibus/lages-sc/saojosedocedro-sc'},
    {link: '/passagem-de-onibus/lages-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/lages-sc/saolourencodooeste-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/lages-sc'},
    {link: '/passagem-de-onibus/saolourencodooeste-sc/saodomingos-sc-sc'},
    {link: '/passagem-de-onibus/ponteserrada-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/ponteserrada-sc/lages-sc'},
    {link: '/passagem-de-onibus/xaxim-sc/joacaba-sc'},
    {link: '/passagem-de-onibus/xaxim-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/xaxim-sc/lages-sc'},
    {link: '/passagem-de-onibus/lages-sc/xaxim-sc'},
    {link: '/passagem-de-onibus/chapeco-sc/dionisiocerqueira-sc'},
    {link: '/passagem-de-onibus/chapeco-sc/saomigueldooeste-sc'},
    {link: '/passagem-de-onibus/chapeco-sc/pinhalzinho,sc-sc'},
    {link: '/passagem-de-onibus/chapeco-sc/florianopolis-sc'},
    {link: '/passagem-de-onibus/chapeco-sc/maravilha-sc'},
];
export default realTransportes;