import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { recuperarConfEmpresa } from '../../util/applicationContext';

const topTrechos = [
    {
        idCidadeOrigem: 2498,
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        nomeCidadeOrigemComUFNormalizado: 'belohorizonte-mg',
        idCidadeDestino: 1,
        nomeCidadeDestinoComUF: 'Brasília-DF',
        nomeCidadeDestinoComUFNormalizado: 'brasilia-df',
        ordem: 1
    },    
    {
        idCidadeOrigem: 5848,
        nomeCidadeOrigemComUF: 'São Paulo-SP',
        nomeCidadeOrigemComUFNormalizado: 'saopaulo-sp',
        idCidadeDestino: 1,
        nomeCidadeDestinoComUF: 'Brasília-DF',
        nomeCidadeDestinoComUFNormalizado: 'brasilia-df',
        ordem: 2
    },    
    {
        idCidadeOrigem: 5848,
        nomeCidadeOrigemComUF: 'São Paulo-SP',
        nomeCidadeOrigemComUFNormalizado: 'saopaulo-sp',
        idCidadeDestino: 2498,
        nomeCidadeDestinoComUF: 'Belo Horizonte-MG',
        nomeCidadeDestinoComUFNormalizado: 'belohorizonte-mg',
        ordem: 3
    },
    {
        idCidadeOrigem: 4160,
        nomeCidadeOrigemComUF: 'Rio de Janeiro-RJ',
        nomeCidadeOrigemComUFNormalizado: 'riodejaneiro-rj',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 4
    },
    {
        idCidadeOrigem: 1,
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 5
    },    
    {
        idCidadeOrigem: 3788,
        nomeCidadeOrigemComUF: 'Curitiba-PR',
        nomeCidadeOrigemComUFNormalizado: 'curitiba-pr',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 6
    },    
    {
        idCidadeOrigem: 6036,
        nomeCidadeOrigemComUF: 'Salvador-BA',
        nomeCidadeOrigemComUFNormalizado: 'salvador-ba',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 7
    },
    {
        idCidadeOrigem: 1023,
        nomeCidadeOrigemComUF: 'Fortaleza-CE',
        nomeCidadeOrigemComUFNormalizado: 'fortaleza-ce',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 8
    },
    {
        idCidadeOrigem: 2498,
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        nomeCidadeOrigemComUFNormalizado: 'belohorizonte-mg',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 9
    },
    {
        idCidadeOrigem: 3417,
        nomeCidadeOrigemComUF: 'Recife-PE',
        nomeCidadeOrigemComUFNormalizado: 'recife-pe',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 10
    },
    {
        idCidadeOrigem: 4742,
        nomeCidadeOrigemComUF: 'Porto Alegre-RS',
        nomeCidadeOrigemComUFNormalizado: 'portoalegre-rs',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 11
    },
    {
        idCidadeOrigem: 794,
        nomeCidadeOrigemComUF: 'Manaus-AM',
        nomeCidadeOrigemComUFNormalizado: 'manaus-am',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 12
    },
    {
        idCidadeOrigem: 126,
        nomeCidadeOrigemComUF: 'Goiânia-GO',
        nomeCidadeOrigemComUFNormalizado: 'goiania-go',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 13
    },
    {
        idCidadeOrigem: 5002,
        nomeCidadeOrigemComUF: 'Florianópolis-SC',
        nomeCidadeOrigemComUFNormalizado: 'florianopolis-sc',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 14
    },
    {
        idCidadeOrigem: 2085,
        nomeCidadeOrigemComUF: 'Belém-PA',
        nomeCidadeOrigemComUFNormalizado: 'belem-pa',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 15
    }
];

export class CardsTopTrechos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupedTrechos: [],
        };
    }

    retornarGrupoDe5(valores) {
        const grupo = [];    
        for (let i = 0; i < valores.length; i += 5) {
            const array = valores.slice(i, i + 5);
            grupo.push(array);
        }    
        return grupo;
    }

    render() {        
        const groupedTrechos = this.retornarGrupoDe5(topTrechos);

        return (
            <div id='container-topTrechos1' className='container-toptrechos'>
                <div id='containerTituloTrechos' className='titulo-topTrechos'>
                    <span>
                        <b id='tituloTrechos'> Top Redes de Pesquisa </b>
                    </span>
                </div>
                <div className='container-groupedTrechos'>
                    {groupedTrechos.map((group, groupIndex) => (
                        <div key={groupIndex} id={`containertopTrechos${groupIndex}`} className='containertopTrechos'>
                            {group.map((trecho, index) => {
                                const baseURL = recuperarConfEmpresa().urlBase;
                                const hrefLink = `${baseURL}/passagem-de-onibus/${trecho.nomeCidadeOrigemComUFNormalizado}/${trecho.nomeCidadeDestinoComUFNormalizado}`;
                                return (
                                    <div className='col-md-4 mb-4' key={index}>
                                        <div className='card-topTrechos'>
                                            <a href={hrefLink} id='topTrechosLink'>
                                                <div className='cardTrecho'>
                                                    <div className='container-origemDestino'>
                                                        <div className='icon-topOrigem'>
                                                            <span id='destinoIcons' className="fa fa-map-marker"></span>
                                                            <h3 className='trechoOrigem'>{trecho.nomeCidadeOrigemComUF}</h3>
                                                        </div>
                                                        <div className='icon-topDestino'>
                                                            <span id='arrowTrechos'
                                                                  className='fas fa-arrow-right'></span>
                                                            <h3 className='trechoDestino'>{trecho.nomeCidadeDestinoComUF}</h3>
                                                            <span id='trechoDestinoIcons'
                                                                  className="fas fa-map-pin"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default withRouter(CardsTopTrechos);
