const topDestinos = [
    {
        nomeCidadeDestinoComUF: 'Brasília-DF',
        nomeCidadeDestinoComUFNormalizado: 'brasilia-df',
        ordem: 1
    },
    {
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 2
    },
    {
        nomeCidadeDestinoComUF: 'Belo Horizonte-MG',
        nomeCidadeDestinoComUFNormalizado: 'belohorizonte-mg',
        ordem: 3
    },
    {
        nomeCidadeDestinoComUF: 'Rio de Janeiro-RJ',
        nomeCidadeDestinoComUFNormalizado: 'riodejaneiro-rj',
        ordem: 4
    },
    {
        nomeCidadeDestinoComUF: 'Curitiba-PR',
        nomeCidadeDestinoComUFNormalizado: 'curitiba-pr',
        ordem: 5
    },
    {
        nomeCidadeDestinoComUF: 'Florianópolis-SC',
        nomeCidadeDestinoComUFNormalizado: 'florianopolis-sc',
        ordem: 6
    },
    {
        nomeCidadeDestinoComUF: 'Salvador-BA',
        nomeCidadeDestinoComUFNormalizado: 'salvador-ba',
        ordem: 7
    },
    {
        nomeCidadeDestinoComUF: 'Fortaleza-CE',
        nomeCidadeDestinoComUFNormalizado: 'fortaleza-ce',
        ordem: 8
    },
    {
        nomeCidadeDestinoComUF: 'Manaus-AM',
        nomeCidadeDestinoComUFNormalizado: 'manaus-am',
        ordem: 9
    },
    {
        nomeCidadeDestinoComUF: 'Recife-PE',
        nomeCidadeDestinoComUFNormalizado: 'recife-pe',
        ordem: 10
    },
    {
        nomeCidadeDestinoComUF: 'Porto Alegre-RS',
        nomeCidadeDestinoComUFNormalizado: 'portoalegre-rs',
        ordem: 11
    },
    {
        nomeCidadeDestinoComUF: 'Belo Horizonte-MG',
        nomeCidadeDestinoComUFNormalizado: 'belohorizonte-mg',
        ordem: 12
    },
    {
        nomeCidadeDestinoComUF: 'Goiânia-GO',
        nomeCidadeDestinoComUFNormalizado: 'goiania-go',
        ordem: 13
    },
    {
        nomeCidadeDestinoComUF: 'Florianópolis-SC',
        nomeCidadeDestinoComUFNormalizado: 'florianopolis-sc',
        ordem: 14
    },
    {
        nomeCidadeDestinoComUF: 'Belém-PA',
        nomeCidadeDestinoComUFNormalizado: 'belem-pa',
        ordem: 15
    }
]

export default topDestinos;