import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux'
import ReCaptchaV2 from 'react-google-recaptcha'
import TextoInicialLogin from './TextoInicialLogin'
import { renderField, required, requiredCPF, validarNomeCompleto } from '../../../util/inputs';
import { buscar } from '../../../actions/reservasActions';
import CarouselBlog from '../CarouselBlog';

class PaginaGeracaoBilhete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chaveCaptcha: null,
        };
        this.recaptchaRef = React.createRef();
    }

    changeRecaptcha(value) {
        if (value) {
            this.setState({ chaveCaptcha: value })
        }
    }

    onSubmit(values) {
        this.props.buscar(values, this.state.chaveCaptcha).then(() => {
            this.props.history.push('/recibo-bilhete-na-mao')
        })
    }

    render() {
        const {
            handleSubmit, submitting, invalid, pristine,
        } = this.props;

        return (
            <div className='container-pagInicial'>
                <div className='container-texto-login'>
                    <TextoInicialLogin />
                    <form className="container form-login" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <div className='loginHeader'>
                            <h1>Gere seu bilhete de passagem</h1>
                            <div className="divider" />
                        </div>
                        <label htmlFor='id'>Número do bilhete:</label>
                        <Field
                            name='id'
                            type='text'
                            id='id'
                            placeholder=''
                            max={10}
                            validate={[required]}
                            component={renderField}
                        />
                        <label htmlFor='cpf'>CPF do passageiro:</label>
                        <Field
                            name='cpf'
                            id='cpf'
                            placeholder="___.___.___-__"
                            css="form-control"
                            type='cpf'
                            validate={[requiredCPF]}
                            component={renderField} 
                        />
                        <label htmlFor='nome'>Nome completo:</label>
                        <Field
                            name='nome'
                            type='text'
                            id='nome'
                            placeholder=''
                            validate={[required, validarNomeCompleto]}
                            component={renderField}
                        />
                        <label htmlFor='email'>E-mail:</label>
                        <Field
                            name='email'
                            type='email'
                            id='nome'
                            placeholder=''
                            validate={[required]}
                            component={renderField}
                        />
                        <label htmlFor='telefone'>Telefone:</label>
                        <Field
                            name='telefone'
                            id='telefone'
                            type='celular'
                            placeholder=''
                            validate={[required]}
                            component={renderField}
                        />
                        <button type='submit' className='btn btn-laranja btn-sistema'
                            disabled={invalid || submitting || pristine || !this.state.chaveCaptcha}>Gerar bilhete
                        </button>
                        <div className='col-12 text-center'>
                            <ReCaptchaV2
                                ref={this.recaptchaRef}
                                style={{ display: 'inline-block' }}
                                sitekey={'6LfNrvkdAAAAAL2wVMaGIuOwJ0STFzIOavTKhNvw'}
                                onChange={(value) => this.changeRecaptcha(value)}
                                onExpired={() => this.setState({ chaveCaptcha: null })}
                                onErrored={() => this.setState({ chaveCaptcha: null })} />
                        </div>
                    </form>
                </div>
                <div className='container-blogs'>
                    <CarouselBlog />
                </div>
            </div>

        )
    }
}

const reduxConnectEnhance = connect(null, { buscar })(PaginaGeracaoBilhete);

export default withRouter(reduxForm({
    form: 'buscarReservaBilheteNaMao',
})(reduxConnectEnhance));
