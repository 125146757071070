import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../util/loadingUtil'
import { URL } from '../util/ambienteActions'
import { ALTERAR_FORMA_PAGAMANTO } from '../reducers/vendasReducer'

export function alterarFormaPagamento(idPedido, data) {
    const url = `${URL}/pagamento/pedido/${idPedido}/alterar-forma-pagamento`
    return dispatch => axios.post(url, data , SLOW_REQUEST_CONFIG).then(
        response => dispatch({
          type: ALTERAR_FORMA_PAGAMANTO,
          payload: response.data
        })
    );
}