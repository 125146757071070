import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosViagensGuma = {
    ...dadosAgenciaComum,
    id: 'viagensguma',
    nome: 'Viagens Guma',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    email: 'suportevexado@gmail.com',
    idsTagManager: ['GTM-W5K3GCV']
};
