const rodeRotas = [

    {link: '/passagem-de-onibus/jaru-ro/santoandre-sp'},
    {link: '/passagem-de-onibus/jaru-ro/campogrande-ms'},
    {link: '/passagem-de-onibus/jaru-ro/aguaclara-ms'},
    {link: '/passagem-de-onibus/jaru-ro/penapolis-sp'},
    {link: '/passagem-de-onibus/jaru-ro/americana-sp'},
    {link: '/passagem-de-onibus/jaru-ro/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/corumbaiba-go/americana-sp'},
    {link: '/passagem-de-onibus/corumbaiba-go/jundiai-sp'},
    {link: '/passagem-de-onibus/limeira-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/ji-parana-ro/andradina-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/jundiai-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/ji-parana-ro/sonora-ms'},
    {link: '/passagem-de-onibus/ji-parana-ro/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/santoandre-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/santoandre-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/limeira-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/aguaclara-ms'},
    {link: '/passagem-de-onibus/ji-parana-ro/penapolis-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/americana-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/ji-parana-ro/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/birigui-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/treslagoas-ms'},
    {link: '/passagem-de-onibus/lins-sp/campomourao-pr'},
    {link: '/passagem-de-onibus/coxim-ms/santoandre-sp'},
    {link: '/passagem-de-onibus/coxim-ms/araraquara-sp'},
    {link: '/passagem-de-onibus/coxim-ms/saocarlos-sp'},
    {link: '/passagem-de-onibus/coxim-ms/barretos-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/treslagoas-ms'},
    {link: '/passagem-de-onibus/ariquemes-ro/andradina-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/ariquemes-ro/sonora-ms'},
    {link: '/passagem-de-onibus/ariquemes-ro/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/americana-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/birigui-sp'},
    {link: '/passagem-de-onibus/marzagao-go/americana-sp'},
    {link: '/passagem-de-onibus/marzagao-go/jundiai-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/santoandre-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/limeira-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/aguaclara-ms'},
    {link: '/passagem-de-onibus/ariquemes-ro/penapolis-sp'},
    {link: '/passagem-de-onibus/jaciara-mt/santoandre-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/ribasdoriopardo-ms/barretos-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/santoandre-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/limeira-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/campogrande-ms'},
    {link: '/passagem-de-onibus/presidentemedici-ro/aguaclara-ms'},
    {link: '/passagem-de-onibus/presidentemedici-ro/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/birigui-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/treslagoas-ms'},
    {link: '/passagem-de-onibus/presidentemedici-ro/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/andradina-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/jundiai-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/presidentemedici-ro/barretos-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/sonora-ms'},
    {link: '/passagem-de-onibus/caceres-mt/andradina-sp'},
    {link: '/passagem-de-onibus/caceres-mt/jundiai-sp'},
    {link: '/passagem-de-onibus/caceres-mt/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/caceres-mt/saogabrieldooeste-ms'},
    {link: '/passagem-de-onibus/presidentemedici-ro/penapolis-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/araraquara-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/americana-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/saocarlos-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/ribasdoriopardo-ms/santoandre-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/santoandre-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/birigui-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/treslagoas-ms'},
    {link: '/passagem-de-onibus/cacoal-ro/andradina-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/jundiai-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/cacoal-ro/aguaclara-ms'},
    {link: '/passagem-de-onibus/cacoal-ro/penapolis-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/americana-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/cacoal-ro/sonora-ms'},
    {link: '/passagem-de-onibus/cacoal-ro/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/andradina-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/jundiai-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/sonora-ms'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/santoandre-sp'},
    {link: '/passagem-de-onibus/igarapava-sp/goiania-go'},
    {link: '/passagem-de-onibus/igarapava-sp/itumbiara-go'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/santoandre-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/aguaclara-ms'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/penapolis-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/americana-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/saocarlos-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/treslagoas-ms'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/prata-mg'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/santos-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/santoandre-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/aguaclara-ms'},
    {link: '/passagem-de-onibus/pimentabueno-ro/penapolis-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/andradina-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/pimentabueno-ro/sonora-ms'},
    {link: '/passagem-de-onibus/sonora-ms/santoandre-sp'},
    {link: '/passagem-de-onibus/sonora-ms/araraquara-sp'},
    {link: '/passagem-de-onibus/sonora-ms/saocarlos-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/pimentabueno-ro/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/birigui-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/treslagoas-ms'},
    {link: '/passagem-de-onibus/sonora-ms/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/sonora-ms/barretos-sp'},
    {link: '/passagem-de-onibus/itumbiara-go/igarapava-sp'},
    {link: '/passagem-de-onibus/jaru-ro/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/jaru-ro/birigui-sp'},
    {link: '/passagem-de-onibus/jaru-ro/treslagoas-ms'},
    {link: '/passagem-de-onibus/jaru-ro/andradina-sp'},
    {link: '/passagem-de-onibus/jaru-ro/jundiai-sp'},
    {link: '/passagem-de-onibus/jaru-ro/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/jaru-ro/sonora-ms'},
    {link: '/passagem-de-onibus/jaru-ro/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/limeira-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/limeira-sp/presidentemedici-ro'},
    {link: '/passagem-de-onibus/aguaclara-ms/barretos-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/aguaclara-ms/santoandre-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/treslagoas-ms'},
    {link: '/passagem-de-onibus/vilhena-ro/andradina-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/vilhena-ro/sonora-ms'},
    {link: '/passagem-de-onibus/vilhena-ro/santoandre-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/limeira-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/aguaclara-ms'},
    {link: '/passagem-de-onibus/vilhena-ro/penapolis-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/vilhena-ro/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/birigui-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/estreito-ma'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/guarai-to'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/paragominas-pa'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/portofranco-ma'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/ulianopolis-pa'},
    {link: '/passagem-de-onibus/uberlandia-mg/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/uberlandia-mg/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/uberlandia-mg/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/uberlandia-mg/domeliseu-pa'},
    {link: '/passagem-de-onibus/uberlandia-mg/estreito-ma'},
    {link: '/passagem-de-onibus/jatai-go/osasco-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/treslagoas-ms'},
    {link: '/passagem-de-onibus/portovelho-ro/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/jundiai-sp'},
    {link: '/passagem-de-onibus/americana-sp/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/americana-sp/ariquemes-ro'},
    {link: '/passagem-de-onibus/americana-sp/cacoal-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/santoandre-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/aguaclara-ms'},
    {link: '/passagem-de-onibus/caceres-mt/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/caceres-mt/birigui-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/penapolis-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/americana-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/birigui-sp'},
    {link: '/passagem-de-onibus/caceres-mt/santoandre-sp'},
    {link: '/passagem-de-onibus/caceres-mt/aguaclara-ms'},
    {link: '/passagem-de-onibus/caceres-mt/penapolis-sp'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/piracanjuba-go'},
    {link: '/passagem-de-onibus/prata-mg/assis-sp'},
    {link: '/passagem-de-onibus/prata-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/treslagoas-ms/santoandre-sp'},
    {link: '/passagem-de-onibus/rioverdedematogrosso-ms/saocarlos-sp'},
    {link: '/passagem-de-onibus/rioverdedematogrosso-ms/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/rioverdedematogrosso-ms/barretos-sp'},
    {link: '/passagem-de-onibus/morrinhos-go/santoandre-sp'},
    {link: '/passagem-de-onibus/morrinhos-go/santos-sp'},
    {link: '/passagem-de-onibus/rioverdedematogrosso-ms/santoandre-sp'},
    {link: '/passagem-de-onibus/rioverdedematogrosso-ms/araraquara-sp'},
    {link: '/passagem-de-onibus/morrinhos-go/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/saogabrieldooeste-ms/santoandre-sp'},
    {link: '/passagem-de-onibus/saogabrieldooeste-ms/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/saogabrieldooeste-ms/barretos-sp'},
    {link: '/passagem-de-onibus/saogabrieldooeste-ms/araraquara-sp'},
    {link: '/passagem-de-onibus/saogabrieldooeste-ms/saocarlos-sp'},
    {link: '/passagem-de-onibus/piranhas-go/americana-sp'},
    {link: '/passagem-de-onibus/piranhas-go/piracanjuba-go'},
    {link: '/passagem-de-onibus/piranhas-go/jundiai-sp'},
    {link: '/passagem-de-onibus/piranhas-go/limeira-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/saogabrieldooeste-ms'},
    {link: '/passagem-de-onibus/portovelho-ro/rioverdedematogrosso-ms'},
    {link: '/passagem-de-onibus/curitiba-pr/frutal-mg'},
    {link: '/passagem-de-onibus/barradogarcas-mt/saopaulo-sp'},
    {link: '/passagem-de-onibus/barradogarcas-mt/saopaulo-sp'},
    {link: '/passagem-de-onibus/barradogarcas-mt/jundiai-sp'},
    {link: '/passagem-de-onibus/barradogarcas-mt/limeira-sp'},
    {link: '/passagem-de-onibus/barradogarcas-mt/caldasnovas-go'},
    {link: '/passagem-de-onibus/belem-pa/americana-sp'},
    {link: '/passagem-de-onibus/belem-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/belem-pa/alvorada-to'},
    {link: '/passagem-de-onibus/castanhal-pa/limeira-sp'},
    {link: '/passagem-de-onibus/castanhal-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/castanhal-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/castanhal-pa/campinas-sp'},
    {link: '/passagem-de-onibus/castanhal-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/castanhal-pa/americana-sp'},
    {link: '/passagem-de-onibus/castanhal-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/castanhal-pa/porangatu-go'},
    {link: '/passagem-de-onibus/castanhal-pa/alvorada-to'},
    {link: '/passagem-de-onibus/acailandia-ma/alvorada-to'},
    {link: '/passagem-de-onibus/domeliseu-pa/campinas-sp'},
    {link: '/passagem-de-onibus/domeliseu-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/domeliseu-pa/americana-sp'},
    {link: '/passagem-de-onibus/domeliseu-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/domeliseu-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/domeliseu-pa/porangatu-go'},
    {link: '/passagem-de-onibus/domeliseu-pa/alvorada-to'},
    {link: '/passagem-de-onibus/domeliseu-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/domeliseu-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/piranhas-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/piranhas-go/uberaba-mg'},
    {link: '/passagem-de-onibus/piranhas-go/campinas-sp'},
    {link: '/passagem-de-onibus/piranhas-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/piranhas-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/anapolis-go/assis-sp'},
    {link: '/passagem-de-onibus/ipora-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/ipora-go/piracanjuba-go'},
    {link: '/passagem-de-onibus/ipora-go/itumbiara-go'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/porangatu-go'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/alvorada-to'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/miranorte-to'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/limeira-sp'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/estreito-ma'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/gurupi-to'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/guarai-to'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/campinas-sp'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/americana-sp'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/portofranco-ma'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/saopaulo-sp'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/jundiai-sp'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/porangatu-go'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/alvorada-to'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/uruacu-go'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/miranorte-to'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/gurupi-to'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/uberaba-mg'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/uberlandia-mg'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/guarai-to'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/americana-sp'},
    {link: '/passagem-de-onibus/anapolis-go/igarapava-sp'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/goiania-go'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/limeira-sp'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/estreito-ma'},
    {link: '/passagem-de-onibus/caldasnovas-go/barradogarcas-mt'},
    {link: '/passagem-de-onibus/uberaba-mg/piranhas-go'},
    {link: '/passagem-de-onibus/maedorio-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/maedorio-pa/americana-sp'},
    {link: '/passagem-de-onibus/maedorio-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/maedorio-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/maedorio-pa/alvorada-to'},
    {link: '/passagem-de-onibus/maedorio-pa/limeira-sp'},
    {link: '/passagem-de-onibus/maedorio-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/maedorio-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/maedorio-pa/campinas-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/marzagao-go/limeira-sp'},
    {link: '/passagem-de-onibus/campinas-sp/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/ipora-go'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/barradogarcas-mt'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/piranhas-go'},
    {link: '/passagem-de-onibus/paragominas-pa/limeira-sp'},
    {link: '/passagem-de-onibus/paragominas-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/paragominas-pa/campinas-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/sonora-ms'},
    {link: '/passagem-de-onibus/paragominas-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/paragominas-pa/americana-sp'},
    {link: '/passagem-de-onibus/paragominas-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/paragominas-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/paragominas-pa/porangatu-go'},
    {link: '/passagem-de-onibus/paragominas-pa/alvorada-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/piranhas-go'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/uberaba-mg'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/limeira-sp'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/santamariadopara-pa/limeira-sp'},
    {link: '/passagem-de-onibus/santamariadopara-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/americana-sp'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/alvorada-to'},
    {link: '/passagem-de-onibus/santamariadopara-pa/alvorada-to'},
    {link: '/passagem-de-onibus/brasilia-df/assis-sp'},
    {link: '/passagem-de-onibus/santamariadopara-pa/campinas-sp'},
    {link: '/passagem-de-onibus/santamariadopara-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/santamariadopara-pa/portofranco-ma'},
    {link: '/passagem-de-onibus/santamariadopara-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/santamariadopara-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/santamariadopara-pa/porangatu-go'},
    {link: '/passagem-de-onibus/piracanjuba-go/barradogarcas-mt'},
    {link: '/passagem-de-onibus/piracanjuba-go/ipora-go'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/piranhas-go'},
    {link: '/passagem-de-onibus/morrinhos-go/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/curitiba-pr/prata-mg'},
    {link: '/passagem-de-onibus/ourinhos-sp/frutal-mg'},
    {link: '/passagem-de-onibus/ananindeua-pa/americana-sp'},
    {link: '/passagem-de-onibus/ananindeua-pa/jundiai-sp'},
    {link: '/passagem-de-onibus/ananindeua-pa/alvorada-to'},
    {link: '/passagem-de-onibus/barradogarcas-mt/araguari-mg'},
    {link: '/passagem-de-onibus/barradogarcas-mt/uberlandia-mg'},
    {link: '/passagem-de-onibus/barradogarcas-mt/campinas-sp'},
    {link: '/passagem-de-onibus/barradogarcas-mt/americana-sp'},
    {link: '/passagem-de-onibus/barradogarcas-mt/piracanjuba-go'},
    {link: '/passagem-de-onibus/limeira-sp/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/limeira-sp/piranhas-go'},
    {link: '/passagem-de-onibus/araguari-mg/barradogarcas-mt'},
    {link: '/passagem-de-onibus/araguari-mg/piranhas-go'},
    {link: '/passagem-de-onibus/ipora-go/uberaba-mg'},
    {link: '/passagem-de-onibus/ipora-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/ipora-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/barradogarcas-mt'},
    {link: '/passagem-de-onibus/uberaba-mg/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/marzagao-go/piracanjuba-go'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/campinas-sp/piranhas-go'},
    {link: '/passagem-de-onibus/campinas-sp/ipora-go'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/araguari-mg'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/ipora-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/barradogarcas-mt'},
    {link: '/passagem-de-onibus/piracanjuba-go/piranhas-go'},
    {link: '/passagem-de-onibus/piracanjuba-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/piracanjuba-go/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/jundiai-sp/piranhas-go'},
    {link: '/passagem-de-onibus/jundiai-sp/ipora-go'},
    {link: '/passagem-de-onibus/franca-sp/anapolis-go'},
    {link: '/passagem-de-onibus/franca-sp/caldasnovas-go'},
    {link: '/passagem-de-onibus/franca-sp/marzagao-go'},
    {link: '/passagem-de-onibus/ituiutaba-mg/limeira-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/americana-sp'},
    {link: '/passagem-de-onibus/barradogarcas-mt/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/goiania-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/frutal-mg/limeira-sp'},
    {link: '/passagem-de-onibus/limeira-sp/frutal-mg'},
    {link: '/passagem-de-onibus/limeira-sp/ituiutaba-mg'},
    {link: '/passagem-de-onibus/piranhas-go/araguari-mg'},
    {link: '/passagem-de-onibus/frutal-mg/americana-sp'},
    {link: '/passagem-de-onibus/araguari-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/ipora-go/araguari-mg'},
    {link: '/passagem-de-onibus/paraisodotocantins-to/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/ipora-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/ipora-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ipora-go/jundiai-sp'},
    {link: '/passagem-de-onibus/anapolis-go/franca-sp'},
    {link: '/passagem-de-onibus/anapolis-go/tupaciguara-mg'},
    {link: '/passagem-de-onibus/araguari-mg/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/tupaciguara-mg/franca-sp'},
    {link: '/passagem-de-onibus/caldasnovas-go/ipora-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/piranhas-go'},
    {link: '/passagem-de-onibus/uberaba-mg/barradogarcas-mt'},
    {link: '/passagem-de-onibus/uberaba-mg/ipora-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/ipora-go'},
    {link: '/passagem-de-onibus/marzagao-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/americana-sp/prata-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/ipora-go'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/prata-mg/curitiba-pr'},
    {link: '/passagem-de-onibus/jundiai-sp/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/barradogarcas-mt'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/corumbaiba-go'},
    {link: '/passagem-de-onibus/ourinhos-sp/prata-mg'},
    {link: '/passagem-de-onibus/franca-sp/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/franca-sp/corumbaiba-go'},
    {link: '/passagem-de-onibus/barradogarcas-mt/sorocaba-sp'},
    {link: '/passagem-de-onibus/barradogarcas-mt/uberaba-mg'},
    {link: '/passagem-de-onibus/goiania-go/marzagao-go'},
    {link: '/passagem-de-onibus/limeira-sp/prata-mg'},
    {link: '/passagem-de-onibus/araguari-mg/ipora-go'},
    {link: '/passagem-de-onibus/cascavel-pr/prata-mg'},
    {link: '/passagem-de-onibus/piranhas-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ipora-go/campinas-sp'},
    {link: '/passagem-de-onibus/campomourao-pr/prata-mg'},
    {link: '/passagem-de-onibus/caldasnovas-go/corumbaiba-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/piranhas-go'},
    {link: '/passagem-de-onibus/americana-sp/ipora-go'},
    {link: '/passagem-de-onibus/americana-sp/ituiutaba-mg'},
    {link: '/passagem-de-onibus/americana-sp/frutal-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/prata-mg/brasilia-df'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/ipora-go'},
    {link: '/passagem-de-onibus/goiania-go/corumbaiba-go'},
    {link: '/passagem-de-onibus/franca-sp/piracanjuba-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/campinas-sp'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/americana-sp'},
    {link: '/passagem-de-onibus/corumbaiba-go/limeira-sp'},
    {link: '/passagem-de-onibus/corumbaiba-go/campinas-sp'},
    {link: '/passagem-de-onibus/piranhas-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/campinas-sp'},
    {link: '/passagem-de-onibus/caldasnovas-go/saoluisdemontesbelos-go'},
    {link: '/passagem-de-onibus/tupaciguara-mg/anapolis-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/marzagao-go'},
    {link: '/passagem-de-onibus/uberaba-mg/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/marzagao-go/campinas-sp'},
    {link: '/passagem-de-onibus/marzagao-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/campinas-sp/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/rioverde-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/itumbiara-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/jatai-go'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/saoluisdemontesbelos-go/sorocaba-sp'},
    {link: '/passagem-de-onibus/brasilia-df/gurupi-to'},
    {link: '/passagem-de-onibus/igarapava-sp/araguari-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/franca-sp/araguari-mg'},
    {link: '/passagem-de-onibus/corumbaiba-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/araguari-mg/corumbaiba-go'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/araguaina-to'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/limeira-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/marzagao-go/corumbaiba-go'},
    {link: '/passagem-de-onibus/brasilia-df/porangatu-go'},
    {link: '/passagem-de-onibus/itumbiara-go/franca-sp'},
    {link: '/passagem-de-onibus/franca-sp/goiania-go'},
    {link: '/passagem-de-onibus/franca-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/araguaina-to/uberaba-mg'},
    {link: '/passagem-de-onibus/araguaina-to/uberlandia-mg'},
    {link: '/passagem-de-onibus/araguaina-to/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/araguaina-to/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/araguaina-to/saopaulo-sp'},
    {link: '/passagem-de-onibus/belem-pa/belohorizonte-mg'},
    {link: '/passagem-de-onibus/belem-pa/limeira-sp'},
    {link: '/passagem-de-onibus/belem-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/belem-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/belem-pa/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belem-pa/campinas-sp'},
    {link: '/passagem-de-onibus/belem-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/belem-pa/portofranco-ma'},
    {link: '/passagem-de-onibus/belem-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/goiania-go'},
    {link: '/passagem-de-onibus/jaru-ro/saocarlos-sp'},
    {link: '/passagem-de-onibus/jaru-ro/saopaulo-sp'},
    {link: '/passagem-de-onibus/jaru-ro/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/jaru-ro/barretos-sp'},
    {link: '/passagem-de-onibus/araguaina-to/belohorizonte-mg'},
    {link: '/passagem-de-onibus/goiania-go/medianeira-pr'},
    {link: '/passagem-de-onibus/goiania-go/igarapava-sp'},
    {link: '/passagem-de-onibus/jaru-ro/limeira-sp'},
    {link: '/passagem-de-onibus/jaru-ro/araraquara-sp'},
    {link: '/passagem-de-onibus/jaru-ro/campinas-sp'},
    {link: '/passagem-de-onibus/jaru-ro/jaciara-mt'},
    {link: '/passagem-de-onibus/goiania-go/canapolis-mg'},
    {link: '/passagem-de-onibus/goiania-go/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/goiania-go/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/goiania-go/prata-mg'},
    {link: '/passagem-de-onibus/goiania-go/piracicaba-sp'},
    {link: '/passagem-de-onibus/goiania-go/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/goiania-go/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/goiania-go/centralina-mg'},
    {link: '/passagem-de-onibus/goiania-go/tupaciguara-mg'},
    {link: '/passagem-de-onibus/franca-sp/itumbiara-go'},
    {link: '/passagem-de-onibus/goiania-go/franca-sp'},
    {link: '/passagem-de-onibus/goiania-go/santoandre-sp'},
    {link: '/passagem-de-onibus/goiania-go/ituiutaba-mg'},
    {link: '/passagem-de-onibus/orizona-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/orizona-go/brasilia-df'},
    {link: '/passagem-de-onibus/orizona-go/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/orizona-go/luziania-go'},
    {link: '/passagem-de-onibus/castanhal-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/castanhal-pa/brasilia-df'},
    {link: '/passagem-de-onibus/colinasdotocantins-to/uberlandia-mg'},
    {link: '/passagem-de-onibus/colinasdotocantins-to/americana-sp'},
    {link: '/passagem-de-onibus/acailandia-ma/americana-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/goiania-go'},
    {link: '/passagem-de-onibus/ituiutaba-mg/canapolis-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/frutal-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/centralina-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/araraquara-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/campinas-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/prata-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/ituiutaba-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/morrinhos-go'},
    {link: '/passagem-de-onibus/ituiutaba-mg/barretos-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/uberaba-mg'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/santahelenadegoias-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/orizona-go/piresdorio-go'},
    {link: '/passagem-de-onibus/orizona-go/vianopolis-go'},
    {link: '/passagem-de-onibus/corumbaiba-go/franca-sp'},
    {link: '/passagem-de-onibus/corumbaiba-go/araguari-mg'},
    {link: '/passagem-de-onibus/corumbaiba-go/uberaba-mg'},
    {link: '/passagem-de-onibus/corumbaiba-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/corumbaiba-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/limeira-sp/jaru-ro'},
    {link: '/passagem-de-onibus/limeira-sp/araguaina-to'},
    {link: '/passagem-de-onibus/limeira-sp/belem-pa'},
    {link: '/passagem-de-onibus/limeira-sp/castanhal-pa'},
    {link: '/passagem-de-onibus/acailandia-ma/saopaulo-sp'},
    {link: '/passagem-de-onibus/acailandia-ma/brasilia-df'},
    {link: '/passagem-de-onibus/canapolis-mg/goiania-go'},
    {link: '/passagem-de-onibus/canapolis-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/canapolis-mg/centralina-mg'},
    {link: '/passagem-de-onibus/canapolis-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/canapolis-mg/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/canapolis-mg/morrinhos-go'},
    {link: '/passagem-de-onibus/canapolis-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/santoandre-sp/frutal-mg'},
    {link: '/passagem-de-onibus/santoandre-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/santoandre-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/santoandre-sp/prata-mg'},
    {link: '/passagem-de-onibus/santoandre-sp/itumbiara-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/araguaina-to'},
    {link: '/passagem-de-onibus/belohorizonte-mg/belem-pa'},
    {link: '/passagem-de-onibus/belohorizonte-mg/imperatriz-ma'},
    {link: '/passagem-de-onibus/belohorizonte-mg/ulianopolis-pa'},
    {link: '/passagem-de-onibus/limeira-sp/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/limeira-sp/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/limeira-sp/imperatriz-ma'},
    {link: '/passagem-de-onibus/limeira-sp/vilhena-ro'},
    {link: '/passagem-de-onibus/limeira-sp/gurupi-to'},
    {link: '/passagem-de-onibus/limeira-sp/caldasnovas-go'},
    {link: '/passagem-de-onibus/limeira-sp/guarai-to'},
    {link: '/passagem-de-onibus/limeira-sp/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/limeira-sp/acailandia-ma'},
    {link: '/passagem-de-onibus/limeira-sp/domeliseu-pa'},
    {link: '/passagem-de-onibus/limeira-sp/estreito-ma'},
    {link: '/passagem-de-onibus/limeira-sp/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/estreito-ma/belem-pa'},
    {link: '/passagem-de-onibus/estreito-ma/paragominas-pa'},
    {link: '/passagem-de-onibus/estreito-ma/brasilia-df'},
    {link: '/passagem-de-onibus/piresdorio-go/orizona-go'},
    {link: '/passagem-de-onibus/piresdorio-go/vianopolis-go'},
    {link: '/passagem-de-onibus/piresdorio-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/piresdorio-go/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/piresdorio-go/luziania-go'},
    {link: '/passagem-de-onibus/goiatuba-go/santoandre-sp'},
    {link: '/passagem-de-onibus/frutal-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/frutal-mg/santos-sp'},
    {link: '/passagem-de-onibus/frutal-mg/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/limeira-sp/piracanjuba-go'},
    {link: '/passagem-de-onibus/limeira-sp/porangatu-go'},
    {link: '/passagem-de-onibus/limeira-sp/uruacu-go'},
    {link: '/passagem-de-onibus/limeira-sp/miranorte-to'},
    {link: '/passagem-de-onibus/domeliseu-pa/limeira-sp'},
    {link: '/passagem-de-onibus/frutal-mg/santoandre-sp'},
    {link: '/passagem-de-onibus/frutal-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/ji-parana-ro/araraquara-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/jaciara-mt'},
    {link: '/passagem-de-onibus/ji-parana-ro/saocarlos-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/barretos-sp'},
    {link: '/passagem-de-onibus/goiatuba-go/frutal-mg'},
    {link: '/passagem-de-onibus/goiatuba-go/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/goiatuba-go/araraquara-sp'},
    {link: '/passagem-de-onibus/goiatuba-go/campinas-sp'},
    {link: '/passagem-de-onibus/goiatuba-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/goiatuba-go/prata-mg'},
    {link: '/passagem-de-onibus/goiatuba-go/jundiai-sp'},
    {link: '/passagem-de-onibus/goiatuba-go/barretos-sp'},
    {link: '/passagem-de-onibus/goiatuba-go/santos-sp'},
    {link: '/passagem-de-onibus/goiatuba-go/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/cascavel-pr/anapolis-go'},
    {link: '/passagem-de-onibus/cascavel-pr/lins-sp'},
    {link: '/passagem-de-onibus/cascavel-pr/brasilia-df'},
    {link: '/passagem-de-onibus/cascavel-pr/brasilia-df'},
    {link: '/passagem-de-onibus/maringa-pr/anapolis-go'},
    {link: '/passagem-de-onibus/limeira-sp/ariquemes-ro'},
    {link: '/passagem-de-onibus/limeira-sp/paragominas-pa'},
    {link: '/passagem-de-onibus/limeira-sp/portofranco-ma'},
    {link: '/passagem-de-onibus/limeira-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/limeira-sp/caceres-mt'},
    {link: '/passagem-de-onibus/limeira-sp/cacoal-ro'},
    {link: '/passagem-de-onibus/limeira-sp/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/frutal-mg/goiatuba-go'},
    {link: '/passagem-de-onibus/frutal-mg/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/frutal-mg/araraquara-sp'},
    {link: '/passagem-de-onibus/frutal-mg/campinas-sp'},
    {link: '/passagem-de-onibus/frutal-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/frutal-mg/prata-mg'},
    {link: '/passagem-de-onibus/aguaclara-ms/caceres-mt'},
    {link: '/passagem-de-onibus/piracicaba-sp/goiania-go'},
    {link: '/passagem-de-onibus/piracicaba-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/paraisodotocantins-to/tresmarias-mg'},
    {link: '/passagem-de-onibus/sorocaba-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/anapolis-go/cascavel-pr'},
    {link: '/passagem-de-onibus/anapolis-go/maringa-pr'},
    {link: '/passagem-de-onibus/anapolis-go/lins-sp'},
    {link: '/passagem-de-onibus/maringa-pr/prata-mg'},
    {link: '/passagem-de-onibus/maringa-pr/brasilia-df'},
    {link: '/passagem-de-onibus/maringa-pr/brasilia-df'},
    {link: '/passagem-de-onibus/araguari-mg/franca-sp'},
    {link: '/passagem-de-onibus/araguari-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/anapolis-go/itumbiara-go'},
    {link: '/passagem-de-onibus/itingadomaranhao-ma/campinas-sp'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/uberaba-mg'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/bomjesusdegoias-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/goiania-go'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/belohorizonte-mg'},
    {link: '/passagem-de-onibus/piracicaba-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/piracicaba-sp/itumbiara-go'},
    {link: '/passagem-de-onibus/paraisodotocantins-to/limeira-sp'},
    {link: '/passagem-de-onibus/paraisodotocantins-to/saopaulo-sp'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/ipixunadopara-pa/uruacu-go'},
    {link: '/passagem-de-onibus/centralina-mg/goiania-go'},
    {link: '/passagem-de-onibus/centralina-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/centralina-mg/canapolis-mg'},
    {link: '/passagem-de-onibus/centralina-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/centralina-mg/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/centralina-mg/morrinhos-go'},
    {link: '/passagem-de-onibus/centralina-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/vianopolis-go/orizona-go'},
    {link: '/passagem-de-onibus/vianopolis-go/piresdorio-go'},
    {link: '/passagem-de-onibus/vianopolis-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/vianopolis-go/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/vianopolis-go/luziania-go'},
    {link: '/passagem-de-onibus/lins-sp/cascavel-pr'},
    {link: '/passagem-de-onibus/araguari-mg/marzagao-go'},
    {link: '/passagem-de-onibus/araguari-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/araguari-mg/americana-sp'},
    {link: '/passagem-de-onibus/araguari-mg/igarapava-sp'},
    {link: '/passagem-de-onibus/araguari-mg/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/araguari-mg/piracanjuba-go'},
    {link: '/passagem-de-onibus/araguari-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/anapolis-go/medianeira-pr'},
    {link: '/passagem-de-onibus/anapolis-go/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/anapolis-go/prata-mg'},
    {link: '/passagem-de-onibus/anapolis-go/londrina-pr'},
    {link: '/passagem-de-onibus/caldasnovas-go/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/luziania-go'},
    {link: '/passagem-de-onibus/tupaciguara-mg/goiania-go'},
    {link: '/passagem-de-onibus/tupaciguara-mg/piracicaba-sp'},
    {link: '/passagem-de-onibus/tupaciguara-mg/sorocaba-sp'},
    {link: '/passagem-de-onibus/tupaciguara-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/tupaciguara-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/tupaciguara-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/tupaciguara-mg/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/cuiaba-mt/barretos-sp'},
    {link: '/passagem-de-onibus/lins-sp/anapolis-go'},
    {link: '/passagem-de-onibus/lins-sp/medianeira-pr'},
    {link: '/passagem-de-onibus/lins-sp/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/lins-sp/prata-mg'},
    {link: '/passagem-de-onibus/lins-sp/brasilia-df'},
    {link: '/passagem-de-onibus/cuiaba-mt/santoandre-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/jundiai-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/barretos-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/caldasnovas-go/franca-sp'},
    {link: '/passagem-de-onibus/caldasnovas-go/orizona-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/limeira-sp'},
    {link: '/passagem-de-onibus/caldasnovas-go/piresdorio-go'},
    {link: '/passagem-de-onibus/caldasnovas-go/vianopolis-go'},
    {link: '/passagem-de-onibus/imperatriz-ma/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/imperatriz-ma/saopaulo-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/araraquara-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/americana-sp'},
    {link: '/passagem-de-onibus/vilhena-ro/saocarlos-sp'},
    {link: '/passagem-de-onibus/imperatriz-ma/belohorizonte-mg'},
    {link: '/passagem-de-onibus/imperatriz-ma/uberaba-mg'},
    {link: '/passagem-de-onibus/imperatriz-ma/uberlandia-mg'},
    {link: '/passagem-de-onibus/imperatriz-ma/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/imperatriz-ma/campinas-sp'},
    {link: '/passagem-de-onibus/campomourao-pr/anapolis-go'},
    {link: '/passagem-de-onibus/campomourao-pr/lins-sp'},
    {link: '/passagem-de-onibus/campomourao-pr/brasilia-df'},
    {link: '/passagem-de-onibus/campomourao-pr/brasilia-df'},
    {link: '/passagem-de-onibus/coxim-ms/caceres-mt'},
    {link: '/passagem-de-onibus/coxim-ms/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/araguaina-to'},
    {link: '/passagem-de-onibus/uberaba-mg/belem-pa'},
    {link: '/passagem-de-onibus/uberaba-mg/castanhal-pa'},
    {link: '/passagem-de-onibus/uberaba-mg/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/uberaba-mg/acailandia-ma'},
    {link: '/passagem-de-onibus/uberaba-mg/canapolis-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/paragominas-pa'},
    {link: '/passagem-de-onibus/uberlandia-mg/portofranco-ma'},
    {link: '/passagem-de-onibus/uberlandia-mg/prata-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/campinaverde-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/igarapava-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/piracanjuba-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/castanhal-pa'},
    {link: '/passagem-de-onibus/uberlandia-mg/acailandia-ma'},
    {link: '/passagem-de-onibus/uberlandia-mg/canapolis-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/corumbaiba-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/piracicaba-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/uberlandia-mg/centralina-mg'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/prata-mg'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/itumbiara-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/araguaina-to'},
    {link: '/passagem-de-onibus/uberlandia-mg/belem-pa'},
    {link: '/passagem-de-onibus/uberlandia-mg/santoandre-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/imperatriz-ma'},
    {link: '/passagem-de-onibus/uberlandia-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/marzagao-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/guarai-to'},
    {link: '/passagem-de-onibus/uberlandia-mg/iturama-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/centralina-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/imperatriz-ma'},
    {link: '/passagem-de-onibus/uberaba-mg/tupaciguara-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/marzagao-go'},
    {link: '/passagem-de-onibus/uberaba-mg/guarai-to'},
    {link: '/passagem-de-onibus/uberaba-mg/corumbaiba-go'},
    {link: '/passagem-de-onibus/uberaba-mg/domeliseu-pa'},
    {link: '/passagem-de-onibus/uberaba-mg/estreito-ma'},
    {link: '/passagem-de-onibus/uberaba-mg/araguari-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/piracicaba-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/uberaba-mg/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/uberaba-mg/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/uberaba-mg/porangatu-go'},
    {link: '/passagem-de-onibus/uberaba-mg/miranorte-to'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/frutal-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/paragominas-pa'},
    {link: '/passagem-de-onibus/uberaba-mg/portofranco-ma'},
    {link: '/passagem-de-onibus/uberaba-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/igarapava-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/piracanjuba-go'},
    {link: '/passagem-de-onibus/ariquemes-ro/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/ariquemes-ro/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/jundiai-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/barretos-sp'},
    {link: '/passagem-de-onibus/campinas-sp/jaru-ro'},
    {link: '/passagem-de-onibus/campinas-sp/araguaina-to'},
    {link: '/passagem-de-onibus/campinas-sp/belem-pa'},
    {link: '/passagem-de-onibus/medianeira-pr/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/medianeira-pr/itumbiara-go'},
    {link: '/passagem-de-onibus/maedorio-pa/estreito-ma'},
    {link: '/passagem-de-onibus/maedorio-pa/porangatu-go'},
    {link: '/passagem-de-onibus/marzagao-go/franca-sp'},
    {link: '/passagem-de-onibus/marzagao-go/araguari-mg'},
    {link: '/passagem-de-onibus/marzagao-go/uberaba-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/cacoal-ro'},
    {link: '/passagem-de-onibus/araraquara-sp/prata-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/araraquara-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/ariquemes-ro/araraquara-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/jaciara-mt'},
    {link: '/passagem-de-onibus/ariquemes-ro/saocarlos-sp'},
    {link: '/passagem-de-onibus/marzagao-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/guarai-to/limeira-sp'},
    {link: '/passagem-de-onibus/iturama-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/iturama-mg/prata-mg'},
    {link: '/passagem-de-onibus/iturama-mg/campinaverde-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/ituiutaba-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/frutal-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/campinas-sp/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/campinas-sp/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/campinas-sp/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/campinas-sp/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/campinas-sp/imperatriz-ma'},
    {link: '/passagem-de-onibus/uberlandia-mg/porangatu-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/miranorte-to'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/araguaina-to'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belem-pa'},
    {link: '/passagem-de-onibus/campinas-sp/ituiutaba-mg'},
    {link: '/passagem-de-onibus/campinas-sp/castanhal-pa'},
    {link: '/passagem-de-onibus/campinas-sp/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/campinas-sp/acailandia-ma'},
    {link: '/passagem-de-onibus/campinas-sp/domeliseu-pa'},
    {link: '/passagem-de-onibus/campinas-sp/frutal-mg'},
    {link: '/passagem-de-onibus/campinas-sp/estreito-ma'},
    {link: '/passagem-de-onibus/campinas-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/araraquara-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/araraquara-sp/vilhena-ro'},
    {link: '/passagem-de-onibus/araraquara-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/ariquemes-ro'},
    {link: '/passagem-de-onibus/araraquara-sp/jaciara-mt'},
    {link: '/passagem-de-onibus/araraquara-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/araraquara-sp/caceres-mt'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/castanhal-pa'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/acailandia-ma'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/imperatriz-ma'},
    {link: '/passagem-de-onibus/medianeira-pr/goiania-go'},
    {link: '/passagem-de-onibus/medianeira-pr/anapolis-go'},
    {link: '/passagem-de-onibus/medianeira-pr/lins-sp'},
    {link: '/passagem-de-onibus/medianeira-pr/marilia-sp'},
    {link: '/passagem-de-onibus/medianeira-pr/brasilia-df'},
    {link: '/passagem-de-onibus/medianeira-pr/brasilia-df'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/araguaina-to'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/belem-pa'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/castanhal-pa'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/acailandia-ma'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/domeliseu-pa'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/estreito-ma'},
    {link: '/passagem-de-onibus/jatai-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/paragominas-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/americana-sp/araguaina-to'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/guarai-to'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/paragominas-pa'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/portofranco-ma'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/porangatu-go'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/miranorte-to'},
    {link: '/passagem-de-onibus/jaciara-mt/presidentemedici-ro'},
    {link: '/passagem-de-onibus/jaciara-mt/pimentabueno-ro'},
    {link: '/passagem-de-onibus/jaciara-mt/barretos-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/bomjesusdegoias-go'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/imperatriz-ma'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/campinas-sp/miranorte-to'},
    {link: '/passagem-de-onibus/jaciara-mt/jaru-ro'},
    {link: '/passagem-de-onibus/jaciara-mt/ji-parana-ro'},
    {link: '/passagem-de-onibus/jaciara-mt/araraquara-sp'},
    {link: '/passagem-de-onibus/jaciara-mt/ariquemes-ro'},
    {link: '/passagem-de-onibus/jaciara-mt/portovelho-ro'},
    {link: '/passagem-de-onibus/jaciara-mt/saocarlos-sp'},
    {link: '/passagem-de-onibus/campinas-sp/portofranco-ma'},
    {link: '/passagem-de-onibus/campinas-sp/presidentemedici-ro'},
    {link: '/passagem-de-onibus/campinas-sp/cacoal-ro'},
    {link: '/passagem-de-onibus/campinas-sp/prata-mg'},
    {link: '/passagem-de-onibus/campinas-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/campinas-sp/guarai-to'},
    {link: '/passagem-de-onibus/campinas-sp/paragominas-pa'},
    {link: '/passagem-de-onibus/portovelho-ro/saocarlos-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/ribasdoriopardo-ms'},
    {link: '/passagem-de-onibus/portovelho-ro/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/andradina-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/barretos-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/saocarlos-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/saocarlos-sp/jaciara-mt'},
    {link: '/passagem-de-onibus/saocarlos-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/goiania-go'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/anapolis-go'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/lins-sp'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/brasilia-df'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/brasilia-df'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/itumbiara-go'},
    {link: '/passagem-de-onibus/americana-sp/belem-pa'},
    {link: '/passagem-de-onibus/americana-sp/castanhal-pa'},
    {link: '/passagem-de-onibus/americana-sp/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/americana-sp/acailandia-ma'},
    {link: '/passagem-de-onibus/americana-sp/domeliseu-pa'},
    {link: '/passagem-de-onibus/americana-sp/estreito-ma'},
    {link: '/passagem-de-onibus/americana-sp/araguari-mg'},
    {link: '/passagem-de-onibus/americana-sp/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/americana-sp/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/americana-sp/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/americana-sp/portofranco-ma'},
    {link: '/passagem-de-onibus/americana-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/americana-sp/caceres-mt'},
    {link: '/passagem-de-onibus/americana-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/americana-sp/miranorte-to'},
    {link: '/passagem-de-onibus/marilia-sp/medianeira-pr'},
    {link: '/passagem-de-onibus/marilia-sp/brasilia-df'},
    {link: '/passagem-de-onibus/caceres-mt/limeira-sp'},
    {link: '/passagem-de-onibus/portofranco-ma/limeira-sp'},
    {link: '/passagem-de-onibus/portofranco-ma/uberaba-mg'},
    {link: '/passagem-de-onibus/portofranco-ma/porangatu-go'},
    {link: '/passagem-de-onibus/portofranco-ma/alvorada-to'},
    {link: '/passagem-de-onibus/portovelho-ro/limeira-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/araraquara-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/jaciara-mt'},
    {link: '/passagem-de-onibus/americana-sp/imperatriz-ma'},
    {link: '/passagem-de-onibus/americana-sp/vilhena-ro'},
    {link: '/passagem-de-onibus/americana-sp/guarai-to'},
    {link: '/passagem-de-onibus/americana-sp/paragominas-pa'},
    {link: '/passagem-de-onibus/caceres-mt/coxim-ms'},
    {link: '/passagem-de-onibus/caceres-mt/araraquara-sp'},
    {link: '/passagem-de-onibus/caceres-mt/americana-sp'},
    {link: '/passagem-de-onibus/caceres-mt/saocarlos-sp'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/canapolis-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/centralina-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/morrinhos-go'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/goiania-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/jaru-ro'},
    {link: '/passagem-de-onibus/saopaulo-sp/araguari-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/prata-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/miranorte-to'},
    {link: '/passagem-de-onibus/cacoal-ro/limeira-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/araraquara-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/campinas-sp'},
    {link: '/passagem-de-onibus/caceres-mt/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/rioverde-go/uberaba-mg'},
    {link: '/passagem-de-onibus/rioverde-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/rioverde-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/ribasdoriopardo-ms/portovelho-ro'},
    {link: '/passagem-de-onibus/presidentemedici-ro/campinas-sp'},
    {link: '/passagem-de-onibus/presidentemedici-ro/jaciara-mt'},
    {link: '/passagem-de-onibus/presidentemedici-ro/saopaulo-sp'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/goiania-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/presidentemedici-ro'},
    {link: '/passagem-de-onibus/saopaulo-sp/cacoal-ro'},
    {link: '/passagem-de-onibus/saopaulo-sp/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/piracanjuba-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/araguaina-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/belem-pa'},
    {link: '/passagem-de-onibus/saopaulo-sp/ituiutaba-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/santahelenadegoias-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/castanhal-pa'},
    {link: '/passagem-de-onibus/saopaulo-sp/colinasdotocantins-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/acailandia-ma'},
    {link: '/passagem-de-onibus/saopaulo-sp/domeliseu-pa'},
    {link: '/passagem-de-onibus/saopaulo-sp/frutal-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/estreito-ma'},
    {link: '/passagem-de-onibus/saopaulo-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/saopaulo-sp/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/saopaulo-sp/imperatriz-ma'},
    {link: '/passagem-de-onibus/saopaulo-sp/tupaciguara-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/guarai-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/paragominas-pa'},
    {link: '/passagem-de-onibus/saopaulo-sp/portofranco-ma'},
    {link: '/passagem-de-onibus/saopaulo-sp/caldasnovas-go'},
    {link: '/passagem-de-onibus/caceres-mt/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/caceres-mt/treslagoas-ms'},
    {link: '/passagem-de-onibus/caceres-mt/barretos-sp'},
    {link: '/passagem-de-onibus/caceres-mt/sonora-ms'},
    {link: '/passagem-de-onibus/brasilia-df/estreito-ma'},
    {link: '/passagem-de-onibus/brasilia-df/cascavel-pr'},
    {link: '/passagem-de-onibus/brasilia-df/maringa-pr'},
    {link: '/passagem-de-onibus/brasilia-df/paraisodotocantins-to'},
    {link: '/passagem-de-onibus/brasilia-df/imperatriz-ma'},
    {link: '/passagem-de-onibus/brasilia-df/campomourao-pr'},
    {link: '/passagem-de-onibus/brasilia-df/medianeira-pr'},
    {link: '/passagem-de-onibus/prata-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/londrina-pr/anapolis-go'},
    {link: '/passagem-de-onibus/londrina-pr/brasilia-df'},
    {link: '/passagem-de-onibus/londrina-pr/brasilia-df'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/campinas-sp'},
    {link: '/passagem-de-onibus/prata-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/prata-mg/londrina-pr'},
    {link: '/passagem-de-onibus/prata-mg/campinaverde-mg'},
    {link: '/passagem-de-onibus/prata-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/prata-mg/morrinhos-go'},
    {link: '/passagem-de-onibus/prata-mg/barretos-sp'},
    {link: '/passagem-de-onibus/prata-mg/santos-sp'},
    {link: '/passagem-de-onibus/prata-mg/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/porangatu-go'},
    {link: '/passagem-de-onibus/saomigueldoguama-pa/uruacu-go'},
    {link: '/passagem-de-onibus/santamariadopara-pa/gurupi-to'},
    {link: '/passagem-de-onibus/santamariadopara-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/santamariadopara-pa/americana-sp'},
    {link: '/passagem-de-onibus/brasilia-df/araguaina-to'},
    {link: '/passagem-de-onibus/brasilia-df/belem-pa'},
    {link: '/passagem-de-onibus/brasilia-df/orizona-go'},
    {link: '/passagem-de-onibus/cacoal-ro/jaciara-mt'},
    {link: '/passagem-de-onibus/cacoal-ro/saocarlos-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/saopaulo-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/cacoal-ro/barretos-sp'},
    {link: '/passagem-de-onibus/prata-mg/goiania-go'},
    {link: '/passagem-de-onibus/prata-mg/santoandre-sp'},
    {link: '/passagem-de-onibus/prata-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/prata-mg/frutal-mg'},
    {link: '/passagem-de-onibus/brasilia-df/prata-mg'},
    {link: '/passagem-de-onibus/brasilia-df/londrina-pr'},
    {link: '/passagem-de-onibus/brasilia-df/orizona-go'},
    {link: '/passagem-de-onibus/brasilia-df/piresdorio-go'},
    {link: '/passagem-de-onibus/brasilia-df/vianopolis-go'},
    {link: '/passagem-de-onibus/brasilia-df/imperatriz-ma'},
    {link: '/passagem-de-onibus/prata-mg/goiatuba-go'},
    {link: '/passagem-de-onibus/prata-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/prata-mg/maringa-pr'},
    {link: '/passagem-de-onibus/prata-mg/lins-sp'},
    {link: '/passagem-de-onibus/prata-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/prata-mg/medianeira-pr'},
    {link: '/passagem-de-onibus/prata-mg/iturama-mg'},
    {link: '/passagem-de-onibus/prata-mg/araraquara-sp'},
    {link: '/passagem-de-onibus/prata-mg/campinas-sp'},
    {link: '/passagem-de-onibus/prata-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/brasilia-df/guarai-to'},
    {link: '/passagem-de-onibus/brasilia-df/portofranco-ma'},
    {link: '/passagem-de-onibus/brasilia-df/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/vilhena-ro'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/medianeira-pr'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/ariquemes-ro'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/birigui-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/barretos-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/franca-sp'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/ituiutaba-mg'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/canapolis-mg'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/frutal-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/presidentemedici-ro'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/cacoal-ro'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/brasilia-df'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/morrinhos-go'},
    {link: '/passagem-de-onibus/piracanjuba-go/franca-sp'},
    {link: '/passagem-de-onibus/piracanjuba-go/limeira-sp'},
    {link: '/passagem-de-onibus/piracanjuba-go/araguari-mg'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/caceres-mt'},
    {link: '/passagem-de-onibus/igarapava-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/igarapava-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/limeira-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/araraquara-sp'},
    {link: '/passagem-de-onibus/campinaverde-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/campinaverde-mg/iturama-mg'},
    {link: '/passagem-de-onibus/campinaverde-mg/prata-mg'},
    {link: '/passagem-de-onibus/treslagoas-ms/portovelho-ro'},
    {link: '/passagem-de-onibus/treslagoas-ms/caceres-mt'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/jaru-ro'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/araguari-mg'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/piracicaba-sp'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/centralina-mg'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/tupaciguara-mg'},
    {link: '/passagem-de-onibus/aparecidadegoiania-go/marzagao-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/orizona-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/piresdorio-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/vianopolis-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/luziania-go'},
    {link: '/passagem-de-onibus/porangatu-go/acailandia-ma'},
    {link: '/passagem-de-onibus/jundiai-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/jundiai-sp/presidentemedici-ro'},
    {link: '/passagem-de-onibus/jundiai-sp/prata-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/piracanjuba-go/uberaba-mg'},
    {link: '/passagem-de-onibus/piracanjuba-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/ituiutaba-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/frutal-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/araguari-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/vilhena-ro'},
    {link: '/passagem-de-onibus/pimentabueno-ro/limeira-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/araraquara-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/jaciara-mt'},
    {link: '/passagem-de-onibus/pimentabueno-ro/americana-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/saocarlos-sp'},
    {link: '/passagem-de-onibus/porangatu-go/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/porangatu-go/imperatriz-ma'},
    {link: '/passagem-de-onibus/porangatu-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/porangatu-go/portofranco-ma'},
    {link: '/passagem-de-onibus/luziania-go/orizona-go'},
    {link: '/passagem-de-onibus/luziania-go/piresdorio-go'},
    {link: '/passagem-de-onibus/luziania-go/vianopolis-go'},
    {link: '/passagem-de-onibus/luziania-go/caldasnovas-go'},
    {link: '/passagem-de-onibus/pimentabueno-ro/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/jundiai-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/barretos-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/barretos-sp/ituiutaba-mg'},
    {link: '/passagem-de-onibus/morrinhos-go/ituiutaba-mg'},
    {link: '/passagem-de-onibus/morrinhos-go/canapolis-mg'},
    {link: '/passagem-de-onibus/morrinhos-go/centralina-mg'},
    {link: '/passagem-de-onibus/morrinhos-go/prata-mg'},
    {link: '/passagem-de-onibus/morrinhos-go/barretos-sp'},
    {link: '/passagem-de-onibus/morrinhos-go/aparecidadegoiania-go'},
    {link: '/passagem-de-onibus/saogabrieldooeste-ms/caceres-mt'},
    {link: '/passagem-de-onibus/barretos-sp/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/barretos-sp/morrinhos-go'},
    {link: '/passagem-de-onibus/barretos-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/sonora-ms/caceres-mt'},
    {link: '/passagem-de-onibus/miranorte-to/limeira-sp'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/caceres-mt'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/prata-mg'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/itumbiara-go/belem-pa'},
    {link: '/passagem-de-onibus/itumbiara-go/santoandre-sp'},
    {link: '/passagem-de-onibus/itumbiara-go/ituiutaba-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/castanhal-pa'},
    {link: '/passagem-de-onibus/santos-sp/itumbiara-go'},
    {link: '/passagem-de-onibus/ananindeua-pa/limeira-sp'},
    {link: '/passagem-de-onibus/ananindeua-pa/uberaba-mg'},
    {link: '/passagem-de-onibus/ananindeua-pa/uberlandia-mg'},
    {link: '/passagem-de-onibus/ananindeua-pa/campinas-sp'},
    {link: '/passagem-de-onibus/ananindeua-pa/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ananindeua-pa/saopaulo-sp'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/ituiutaba-mg'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/frutal-mg'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/vilhena-ro'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/barretos-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/barretos-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/barretos-sp/cuiaba-mt'},
    {link: '/passagem-de-onibus/barretos-sp/vilhena-ro'},
    {link: '/passagem-de-onibus/barretos-sp/ariquemes-ro'},
    {link: '/passagem-de-onibus/barretos-sp/jaciara-mt'},
    {link: '/passagem-de-onibus/barretos-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/barretos-sp/caceres-mt'},
    {link: '/passagem-de-onibus/barretos-sp/cacoal-ro'},
    {link: '/passagem-de-onibus/barretos-sp/prata-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/acailandia-ma'},
    {link: '/passagem-de-onibus/itumbiara-go/canapolis-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/domeliseu-pa'},
    {link: '/passagem-de-onibus/itumbiara-go/piracicaba-sp'},
    {link: '/passagem-de-onibus/itumbiara-go/anapolis-go'},
    {link: '/passagem-de-onibus/itumbiara-go/itingadomaranhao-ma'},
    {link: '/passagem-de-onibus/itumbiara-go/ipixunadopara-pa'},
    {link: '/passagem-de-onibus/itumbiara-go/centralina-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/osasco-sp'},
    {link: '/passagem-de-onibus/itumbiara-go/imperatriz-ma'},
    {link: '/passagem-de-onibus/miranorte-to/campinas-sp'},
    {link: '/passagem-de-onibus/miranorte-to/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/miranorte-to/saopaulo-sp'},
    {link: '/passagem-de-onibus/miranorte-to/brasilia-df'},
    {link: '/passagem-de-onibus/santos-sp/frutal-mg'},
    {link: '/passagem-de-onibus/santos-sp/goiatuba-go'},
    {link: '/passagem-de-onibus/santos-sp/prata-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/itumbiara-go/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/prata-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/porangatu-go'},
    {link: '/passagem-de-onibus/itumbiara-go/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/itumbiara-go/medianeira-pr'},
    {link: '/passagem-de-onibus/itumbiara-go/paragominas-pa'},
    {link: '/passagem-de-onibus/itumbiara-go/portofranco-ma'},
    {link: '/passagem-de-onibus/itumbiara-go/uruacu-go'},
    {link: '/passagem-de-onibus/itumbiara-go/santos-sp'},

    {link: '/passagem-de-onibus/paraty-rj/ubatuba-sp'},
    {link: '/passagem-de-onibus/paraty-rj/taubate-sp'},
    {link: '/passagem-de-onibus/ubatuba-sp/paraty-rj'},
    {link: '/passagem-de-onibus/taubate-sp/paraty-rj'},
    {link: '/passagem-de-onibus/guaratingueta-sp/paraty-rj'},
    {link: '/passagem-de-onibus/paraty-rj/guaratingueta-sp'},

];
export default rodeRotas;