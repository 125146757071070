const dadosCardsDestinos=
[
    {
        'empresaId': 388,
        'idCidadeDestino': 4160,
        'ufDestino': 'RJ',
        'nomeCidadeDestino': 'Rio de Janeiro - RJ',
        'nomeCidadeDestinoComUF': 'Rio de Janeiro - RJ',
        'nomeCidadeDestinoComUFNormalizado': 'riodejaneiro-rj',
        'idCidadeOrigem': 5848,
        'ufOrigem': 'SP',
        'nomeCidadeOrigem': 'São Paulo-SP',
        'nomeCidadeOrigemComUF': 'São Paulo - SP',
        'nomeCidadeOrigemComUFNormalizado': 'saopaulo-sp',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Rio de Janeiro_16-0192.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 3788,
        'ufDestino': 'PR',
        'nomeCidadeDestino': 'Curitiba - PR',
        'nomeCidadeDestinoComUF': 'Curitiba - PR',
        'nomeCidadeDestinoComUFNormalizado': 'curitiba-pr',
        'idCidadeOrigem': 4160,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Rio de Janeiro - RJ',
        'nomeCidadeOrigemComUF': 'Rio de Janeiro - RJ',
        'nomeCidadeOrigemComUFNormalizado': 'riodejaneiro-rj',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Curitiba_7-6301.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 126,
        'ufDestino': 'GO',
        'nomeCidadeDestino': 'Goiânia - GO',
        'nomeCidadeDestinoComUF': 'Goiânia - GO',
        'nomeCidadeDestinoComUFNormalizado': 'goiania-go',
        'idCidadeOrigem': 1,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 89.90,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Goiânia_2-0609.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 4742,
        'ufDestino': 'RS',
        'nomeCidadeDestino': 'Porto Alegre - RS',
        'nomeCidadeDestinoComUF': 'Porto Alegre - RS',
        'nomeCidadeDestinoComUFNormalizado': 'portoalegre-rs',
        'idCidadeOrigem': 3788,
        'ufOrigem': 'PR',
        'nomeCidadeOrigem': 'Curitiba - PR',
        'nomeCidadeOrigemComUF': 'Curitiba - PR',
        'nomeCidadeOrigemComUFNormalizado': 'curitiba-pr',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Porto Alegre_14-1531.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 5002,
        'ufDestino': 'SC',
        'nomeCidadeDestino': 'Florianópolis - SC',
        'nomeCidadeDestinoComUF': 'Florianópolis - SC',
        'nomeCidadeDestinoComUFNormalizado': 'florianopolis-sc',
        'idCidadeOrigem': 4742,
        'ufOrigem': 'RS',
        'nomeCidadeOrigem': 'Porto Alegre - RS',
        'nomeCidadeOrigemComUF': 'Porto Alegre - RS',
        'nomeCidadeOrigemComUFNormalizado': 'portoalegre-rs',
        'valor': 125,
        'quantidade': 302,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Florianópolis_1-7525.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 3417,
        'ufDestino': 'PE',
        'nomeCidadeDestino': 'Recife - PE',
        'nomeCidadeDestinoComUF': 'Recife - PE',
        'nomeCidadeDestinoComUFNormalizado': 'recife-pe',
        'idCidadeOrigem': 6036,
        'ufOrigem': 'BA',
        'nomeCidadeOrigem': 'Salvador - BA',
        'nomeCidadeOrigemComUF': 'Salvador - BA',
        'nomeCidadeOrigemComUFNormalizado': 'salvador-ba',
        'valor': 114,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Recife_15-7754.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 1023,
        'ufDestino': 'CE',
        'nomeCidadeDestino': 'Fortaleza - CE',
        'nomeCidadeDestinoComUF': 'Fortaleza - CE',
        'nomeCidadeDestinoComUFNormalizado': 'fortaleza-ce',
        'idCidadeOrigem': 3417,
        'ufOrigem': 'PE',
        'nomeCidadeOrigem': 'Recife - PE',
        'nomeCidadeOrigemComUF': 'Recife - PE',
        'nomeCidadeOrigemComUFNormalizado': 'recife-pe',
        'valor': 249,
        'quantidade': 55,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Fortaleza_9-9649.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 2085,
        'ufDestino': 'PA',
        'nomeCidadeDestino': 'Belém - PA',
        'nomeCidadeDestinoComUF': 'Belém - PA',
        'nomeCidadeDestinoComUFNormalizado': 'belem-pa',
        'idCidadeOrigem': 794,
        'ufOrigem': 'AM',
        'nomeCidadeOrigem': 'Manaus - AM',
        'nomeCidadeOrigemComUF': 'Manaus - AM',
        'nomeCidadeOrigemComUFNormalizado': 'manaus-am',
        'valor': 165,
        'quantidade': 32,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Belém_4-4315.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 463,
        'ufDestino': 'MA',
        'nomeCidadeDestino': 'São Luís - MA',
        'nomeCidadeDestinoComUF': 'São Luís - MA',
        'nomeCidadeDestinoComUFNormalizado': 'saoluis-ma',
        'idCidadeOrigem': 2085,
        'ufOrigem': 'PA',
        'nomeCidadeOrigem': 'Belém - PA',
        'nomeCidadeOrigemComUF': 'Belém - PA',
        'nomeCidadeOrigemComUFNormalizado': 'belem-pa',
        'valor': 189,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata São Luís_18-4436.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 3684,
        'ufDestino': 'PI',
        'nomeCidadeDestino': 'Teresina - PI',
        'nomeCidadeDestinoComUF': 'Teresina - PI',
        'nomeCidadeDestinoComUFNormalizado': 'teresina-pi',
        'idCidadeOrigem': 463,
        'ufOrigem': 'MA',
        'nomeCidadeOrigem': 'São Luís - MA',
        'nomeCidadeOrigemComUF': 'São Luís - MA',
        'nomeCidadeOrigemComUFNormalizado': 'saoluis-ma',
        'valor': 169,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Teresina_19-3703.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 1023,
        'ufDestino': 'CE',
        'nomeCidadeDestino': 'Fortaleza - CE',
        'nomeCidadeDestinoComUF': 'Fortaleza - CE',
        'nomeCidadeDestinoComUFNormalizado': 'fortaleza-ce',
        'idCidadeOrigem': 3684,
        'ufOrigem': 'PI',
        'nomeCidadeOrigem': 'Teresina - PI',
        'nomeCidadeOrigemComUF': 'Teresina - PI',
        'nomeCidadeOrigemComUFNormalizado': 'teresina-pi',
        'valor': 159,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Fortaleza_9-9649.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 4271,
        'ufDestino': 'RN',
        'nomeCidadeDestino': 'Natal - RN',
        'nomeCidadeDestinoComUF': 'Natal - RN',
        'nomeCidadeDestinoComUFNormalizado': 'natal-rn',
        'idCidadeOrigem': 1023,
        'ufOrigem': 'CE',
        'nomeCidadeOrigem': 'Fortaleza - CE',
        'nomeCidadeOrigemComUF': 'Fortaleza - CE',
        'nomeCidadeOrigemComUFNormalizado': 'fortaleza-ce',
        'valor': 139,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Natal_13-4265.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 6036,
        'ufDestino': 'BA',
        'nomeCidadeDestino': 'Salvador - BA',
        'nomeCidadeDestinoComUF': 'Salvador - BA',
        'nomeCidadeDestinoComUFNormalizado': 'salvador-ba',
        'idCidadeOrigem': 5211,
        'ufOrigem': 'SE',
        'nomeCidadeOrigem': 'Aracaju - SE',
        'nomeCidadeOrigemComUF': 'Aracaju - SE',
        'nomeCidadeOrigemComUFNormalizado': 'aracaju-se',
        'valor': 119,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Aracaju_3-2508.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 1,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília - DF',
        'nomeCidadeDestinoComUF': 'Brasília - DF',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeOrigem': 5848,
        'ufOrigem': 'SP',
        'nomeCidadeOrigem': 'São Paulo-SP',
        'nomeCidadeOrigemComUF': 'São Paulo - SP',
        'nomeCidadeOrigemComUFNormalizado': 'saopaulo-sp',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Brasília_6-9382.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 2304,
        'ufDestino': 'PB',
        'nomeCidadeDestino': 'João Pessoa - PB',
        'nomeCidadeDestinoComUF': 'João Pessoa - PB',
        'nomeCidadeDestinoComUFNormalizado': 'joaopessoa-pb',
        'idCidadeOrigem': 4271,
        'ufOrigem': 'RN',
        'nomeCidadeOrigem': 'Natal - RN',
        'nomeCidadeOrigemComUF': 'Natal - RN',
        'nomeCidadeOrigemComUFNormalizado': 'natal-rn',
        'valor': 109,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata João Pessoa_10-9218.webp'
    },
    {
        'empresaId': 388,
        'idCidadeOrigem': 1,
        'ufDestino': 'GO',
        'nomeCidadeDestino': 'Goiânia - GO',
        'nomeCidadeDestinoComUF': 'Goiânia - GO',
        'nomeCidadeDestinoComUFNormalizado': 'goiania-go',
        'idCidadeDestino': 126,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 89.90,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Goiânia_2-0609.webp',
    },

    // Cards inferior

    {
        'empresaId': 388,
        'idCidadeDestino': 701,
        'ufDestino': 'SE',
        'nomeCidadeDestino': 'Maceió - AL',
        'nomeCidadeDestinoComUF': 'Maceió - AL',
        'nomeCidadeDestinoComUFNormalizado': 'maceio-al',
        'idCidadeOrigem': 5211,
        'ufOrigem': 'SE',
        'nomeCidadeOrigem': 'Aracaju - SE',
        'nomeCidadeOrigemComUF': 'Aracaju - SE',
        'nomeCidadeOrigemComUFNormalizado': 'aracaju-se',
        'valor': 119,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Aracaju_3-2508.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 5002,
        'ufDestino': 'SC',
        'nomeCidadeDestino': 'Florianópolis - SC',
        'nomeCidadeDestinoComUF': 'Florianópolis - SC',
        'nomeCidadeDestinoComUFNormalizado': 'florianopolis-sc',
        'idCidadeOrigem': 3788,
        'ufOrigem': 'PR',
        'nomeCidadeOrigem': 'Curitiba - PR',
        'nomeCidadeOrigemComUF': 'Curitiba - PR',
        'nomeCidadeOrigemComUFNormalizado': 'curitiba-pr',
        'valor': 125,
        'quantidade': 302,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Florianópolis_1-7525.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 126,
        'ufDestino': 'GO',
        'nomeCidadeDestino': 'Goiânia - GO',
        'nomeCidadeDestinoComUF': 'Goiânia - GO',
        'nomeCidadeDestinoComUFNormalizado': 'goiania-go',
        'idCidadeOrigem': 5848,
        'ufOrigem': 'SP',
        'nomeCidadeOrigem': 'São Paulo-SP',
        'nomeCidadeOrigemComUF': 'São Paulo - SP',
        'nomeCidadeOrigemComUFNormalizado': 'saopaulo-sp',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Goiânia_2-0609.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 2498,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte - MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte - MG',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeOrigem': 1,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 89.90,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 1023,
        'ufDestino': 'CE',
        'nomeCidadeDestino': 'Fortaleza - CE',
        'nomeCidadeDestinoComUF': 'Fortaleza - CE',
        'nomeCidadeDestinoComUFNormalizado': 'fortaleza-ce',
        'idCidadeOrigem': 4271,
        'ufOrigem': 'RN',
        'nomeCidadeOrigem': 'Natal - RN',
        'nomeCidadeOrigemComUF': 'Natal - RN',
        'nomeCidadeOrigemComUFNormalizado': 'natal-rn',
        'valor': 99,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Fortaleza_9-9649.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 4742,
        'ufDestino': 'RS',
        'nomeCidadeDestino': 'Porto Alegre - RS',
        'nomeCidadeDestinoComUF': 'Porto Alegre - RS',
        'nomeCidadeDestinoComUFNormalizado': 'portoalegre-rs',
        'idCidadeOrigem': 4160,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Rio de Janeiro - RJ',
        'nomeCidadeOrigemComUF': 'Rio de Janeiro - RJ',
        'nomeCidadeOrigemComUFNormalizado': 'riodejaneiro-rj',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Porto Alegre_14-1531.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 3417,
        'ufDestino': 'PE',
        'nomeCidadeDestino': 'Recife - PE',
        'nomeCidadeDestinoComUF': 'Recife - PE',
        'nomeCidadeDestinoComUFNormalizado': 'recife-pe',
        'idCidadeOrigem': 2304,
        'ufOrigem': 'PB',
        'nomeCidadeOrigem': 'João Pessoa - PB',
        'nomeCidadeOrigemComUF': 'João Pessoa - PB',
        'nomeCidadeOrigemComUFNormalizado': 'joaopessoa-pb',
        'valor': 104,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Recife_15-7754.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 6036,
        'ufDestino': 'BA',
        'nomeCidadeDestino': 'Salvador - BA',
        'nomeCidadeDestinoComUF': 'Salvador - BA',
        'nomeCidadeDestinoComUFNormalizado': 'salvador-ba',
        'idCidadeOrigem': 1,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 289.90,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Salvador_17-1086.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 1023,
        'ufDestino': 'CE',
        'nomeCidadeDestino': 'Fortaleza - CE',
        'nomeCidadeDestinoComUF': 'Fortaleza - CE',
        'nomeCidadeDestinoComUFNormalizado': 'fortaleza-ce',
        'idCidadeOrigem': 4271,
        'ufOrigem': 'RN',
        'nomeCidadeOrigem': 'Natal - RN',
        'nomeCidadeOrigemComUF': 'Natal - RN',
        'nomeCidadeOrigemComUFNormalizado': 'natal-rn',
        'valor': 99,
        'quantidade': 14,
        'proximaData': '2024-07-24',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Fortaleza_9-9649.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 5211,
        'ufDestino': 'SE',
        'nomeCidadeDestino': 'Aracaju - SE',
        'nomeCidadeDestinoComUF': 'Aracaju - SE',
        'nomeCidadeDestinoComUFNormalizado': 'aracaju-se',
        'idCidadeOrigem': 6036,
        'ufOrigem': 'BA',
        'nomeCidadeOrigem': 'Salvador - BA',
        'nomeCidadeOrigemComUF': 'Salvador - BA',
        'nomeCidadeOrigemComUFNormalizado': 'salvador-ba',
        'valor': 114,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Aracaju_3-2508.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 4160,
        'ufDestino': 'RJ',
        'nomeCidadeDestino': 'Rio de Janeiro - RJ',
        'nomeCidadeDestinoComUF': 'Rio de Janeiro - RJ',
        'nomeCidadeDestinoComUFNormalizado': 'riodejaneiro-rj',
        'idCidadeOrigem': 1,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 279.90,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Rio de Janeiro_16-0192.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 3788,
        'ufDestino': 'PR',
        'nomeCidadeDestino': 'Curitiba - PR',
        'nomeCidadeDestinoComUF': 'Curitiba - PR',
        'nomeCidadeDestinoComUFNormalizado': 'curitiba-pr',
        'idCidadeOrigem': 4742,
        'ufOrigem': 'RS',
        'nomeCidadeOrigem': 'Porto Alegre - RS',
        'nomeCidadeOrigemComUF': 'Porto Alegre - RS',
        'nomeCidadeOrigemComUFNormalizado': 'portoalegre-rs',
        'valor': 152,
        'quantidade': 302,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Curitiba_7-6301.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 1,
        'ufDestino': 'DF',
        'nomeCidadeDestino': 'Brasília - DF',
        'nomeCidadeDestinoComUF': 'Brasília - DF',
        'nomeCidadeDestinoComUFNormalizado': 'brasilia-df',
        'idCidadeOrigem': 2498,
        'ufOrigem': 'MG',
        'nomeCidadeOrigem': 'Belo Horizonte - MG',
        'nomeCidadeOrigemComUF': 'Belo Horizonte - MG',
        'nomeCidadeOrigemComUFNormalizado': 'belohorizonte-mg',
        'valor': 175,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Brasília_6-9382.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 3417,
        'ufDestino': 'PE',
        'nomeCidadeDestino': 'Recife - PE',
        'nomeCidadeDestinoComUF': 'Recife - PE',
        'nomeCidadeDestinoComUFNormalizado': 'recife-pe',
        'idCidadeOrigem': 4160,
        'ufOrigem': 'RJ',
        'nomeCidadeOrigem': 'Rio de Janeiro - RJ',
        'nomeCidadeOrigemComUF': 'Rio de Janeiro - RJ',
        'nomeCidadeOrigemComUFNormalizado': 'riodejaneiro-rj',
        'valor': 129,
        'quantidade': 302,
        'proximaData': '2024-12-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado Passagem de ônibus mais barata Recife_15-7754.webp',
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 5848,
        'ufDestino': 'SP',
        'nomeCidadeDestino': 'São Paulo - SP',
        'nomeCidadeDestinoComUF': 'São Paulo - SP',
        'nomeCidadeDestinoComUFNormalizado': 'saopaulo-sp',
        'idCidadeOrigem': 5002,
        'ufOrigem': 'SC',
        'nomeCidadeOrigem': 'Florianópolis - SC',
        'nomeCidadeOrigemComUF': 'Florianópolis - SC',
        'nomeCidadeOrigemComUFNormalizado': 'florianopolis-sc',
        'valor': 135,
        'quantidade': 302,
        'proximaData': '2024-07-28',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Vexado passagem de ônibus para São Paulo-SP-4872.webp'
    },
    {
        'empresaId': 388,
        'idCidadeDestino': 2498,
        'ufDestino': 'MG',
        'nomeCidadeDestino': 'Belo Horizonte - MG',
        'nomeCidadeDestinoComUF': 'Belo Horizonte - MG',
        'nomeCidadeDestinoComUFNormalizado': 'belohorizonte-mg',
        'idCidadeOrigem': 1,
        'ufOrigem': 'DF',
        'nomeCidadeOrigem': 'Brasília-DF',
        'nomeCidadeOrigemComUF': 'Brasília-DF',
        'nomeCidadeOrigemComUFNormalizado': 'brasilia-df',
        'valor': 89.90,
        'quantidade': 265,
        'proximaData': '2024-07-26',
        'urlImagem': 'https://imagens-vexado-live.s3-sa-east-1.amazonaws.com/Belo horizonte mg vexado passagens-1341.webp',
    }
]

export default dadosCardsDestinos;