const luxor = [

    {link: '/passagem-de-onibus/guarulhos-sp/delta-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/delta-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/barretos-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/fronteira-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/barretos-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/olimpia-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/olimpia-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/olimpia-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/barretos-sp'},
    {link: '/passagem-de-onibus/guarulhos-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/guarulhos-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/guarulhos-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/olimpia-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/delta-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/olimpia-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/delta-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/delta-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/barretos-sp'},
    {link: '/passagem-de-onibus/barretos-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/barretos-sp/saopaulo-sp'}

];
export default luxor;