const gontijo = [
    {link: '/passagem-de-onibus/serradosalitre-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/serradosalitre-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/serradosalitre-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/araxa-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/serradosalitre-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/araxa-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/pedrasdemariadacruz-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/janauba-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/januaria-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/engenheironavarro-mg'},
    {link: '/passagem-de-onibus/franca-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/franca-sp/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/trescoracoes-mg/cristalina-go'},
    {link: '/passagem-de-onibus/trescoracoes-mg/brasilia-df'},
    {link: '/passagem-de-onibus/abrecampo-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/afranio-pe/fortaleza-ce'},
    {link: '/passagem-de-onibus/afranio-pe/acaua-pi'},
    {link: '/passagem-de-onibus/afranio-pe/taua-ce'},
    {link: '/passagem-de-onibus/afranio-pe/pedrabranca-ce'},
    {link: '/passagem-de-onibus/afranio-pe/picos-pi'},
    {link: '/passagem-de-onibus/afranio-pe/patosdopiaui-pi'},
    {link: '/passagem-de-onibus/afranio-pe/paulistana-pi'},
    {link: '/passagem-de-onibus/afranio-pe/crateus-ce'},
    {link: '/passagem-de-onibus/afranio-pe/boaviagem-ce'},
    {link: '/passagem-de-onibus/afranio-pe/valencadopiaui-pi'},
    {link: '/passagem-de-onibus/augustodelima-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/monteazul-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/augustodelima-mg/lontra-mg'},
    {link: '/passagem-de-onibus/franca-sp/espinosa-mg'},
    {link: '/passagem-de-onibus/franca-sp/urandi-ba'},
    {link: '/passagem-de-onibus/franca-sp/guanambi-ba'},
    {link: '/passagem-de-onibus/franca-sp/pindai-ba'},
    {link: '/passagem-de-onibus/franca-sp/janauba-mg'},
    {link: '/passagem-de-onibus/franca-sp/riachodesantana-ba'},
    {link: '/passagem-de-onibus/franca-sp/pirapora-mg'},
    {link: '/passagem-de-onibus/santavitoria-mg/saosimao-go'},
    {link: '/passagem-de-onibus/afranio-pe/caninde-ce'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/vilhena-ro'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/uberaba-mg'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/uberlandia-mg'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/ariquemes-ro'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/campinas-sp'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/betim-mg'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/juizdefora-mg'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/pimentabueno-ro'},
    {link: '/passagem-de-onibus/caruaru-pe/natal-rn'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/belohorizonte-mg'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/ituiutaba-mg'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/ji-parana-ro'},
    {link: '/passagem-de-onibus/altogarcas-mt/belohorizonte-mg'},
    {link: '/passagem-de-onibus/altogarcas-mt/ituiutaba-mg'},
    {link: '/passagem-de-onibus/altogarcas-mt/ji-parana-ro'},
    {link: '/passagem-de-onibus/altogarcas-mt/uberlandia-mg'},
    {link: '/passagem-de-onibus/altogarcas-mt/campinas-sp'},
    {link: '/passagem-de-onibus/altogarcas-mt/portovelho-ro'},
    {link: '/passagem-de-onibus/altogarcas-mt/saopaulo-sp'},
    {link: '/passagem-de-onibus/altogarcas-mt/juizdefora-mg'},
    {link: '/passagem-de-onibus/altogarcas-mt/pimentabueno-ro'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/jequie-ba'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/aparecida-sp/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/juazeiro-ba'},
    {link: '/passagem-de-onibus/voltaredonda-rj/garanhuns-pe'},
    {link: '/passagem-de-onibus/voltaredonda-rj/jequie-ba'},
    {link: '/passagem-de-onibus/voltaredonda-rj/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/voltaredonda-rj/ubata-ba'},
    {link: '/passagem-de-onibus/voltaredonda-rj/feiradesantana-ba'},
    {link: '/passagem-de-onibus/conceicaodocastelo-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/inhambupe-ba'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/serrinha-ba'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/tucano-ba'},
    {link: '/passagem-de-onibus/aguaboa,mg-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/goiania-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/goiania-go/brumado-ba'},
    {link: '/passagem-de-onibus/jaru-ro/mantena-mg'},
    {link: '/passagem-de-onibus/jaru-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/jaru-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/anchieta-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/anchieta-es/portoseguro-ba'},
    {link: '/passagem-de-onibus/anchieta-es/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/aparecida-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/monteazul-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/matoverde-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/espinosa-mg'},
    {link: '/passagem-de-onibus/goiania-go/anage-ba'},
    {link: '/passagem-de-onibus/goiania-go/ipatinga-mg'},
    {link: '/passagem-de-onibus/goiania-go/itapetinga-ba'},
    {link: '/passagem-de-onibus/goiania-go/teofilootoni-mg'},
    {link: '/passagem-de-onibus/goiania-go/carmodoparanaiba-mg'},
    {link: '/passagem-de-onibus/goiania-go/patrocinio-mg'},
    {link: '/passagem-de-onibus/goiania-go/saogotardo-mg'},
    {link: '/passagem-de-onibus/alegre-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jaru-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/jaru-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/aparecida-sp/porteirinha-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/petrolina-pe'},
    {link: '/passagem-de-onibus/aparecida-sp/paraopeba-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/janauba-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/brasilia-df'},
    {link: '/passagem-de-onibus/aparecida-sp/curvelo-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/setelagoas-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/parambu-ce'},
    {link: '/passagem-de-onibus/aparecida-sp/pirapora-mg'},
    {link: '/passagem-de-onibus/goiania-go/patosdeminas-mg'},
    {link: '/passagem-de-onibus/goiania-go/portoseguro-ba'},
    {link: '/passagem-de-onibus/goiania-go/itarantim-ba'},
    {link: '/passagem-de-onibus/goiania-go/ibia-mg'},
    {link: '/passagem-de-onibus/goiania-go/medina-mg'},
    {link: '/passagem-de-onibus/goiania-go/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/goiania-go/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/goiania-go/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/goiania-go/potiragua-ba'},
    {link: '/passagem-de-onibus/goiania-go/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/goiania-go/luz-mg'},
    {link: '/passagem-de-onibus/goiania-go/araxa-mg'},
    {link: '/passagem-de-onibus/goiania-go/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/goiania-go/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/goiania-go/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/goiania-go/montecarmelo-mg'},
    {link: '/passagem-de-onibus/goiania-go/eunapolis-ba'},
    {link: '/passagem-de-onibus/goiania-go/caetite-ba'},
    {link: '/passagem-de-onibus/goiania-go/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/goiania-go/itaobim-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/araxa-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/bambui-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/cristalina-go'},
    {link: '/passagem-de-onibus/campobelo-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/ibia-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/ibia-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/barro-ce/sousa-pb'},
    {link: '/passagem-de-onibus/barro-ce/caico-rn'},
    {link: '/passagem-de-onibus/barro-ce/brejodocruz-pb'},
    {link: '/passagem-de-onibus/bomconselho-pe/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belemdesaofrancisco-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/barroduro,pi-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/berilo-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saosimao-go/santavitoria-mg'},
    {link: '/passagem-de-onibus/saosimao-go/ituiutaba-mg'},
    {link: '/passagem-de-onibus/saosimao-go/araxa-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/gouveia-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/aracuai-mg/josegoncalvesdeminas-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/coutodemagalhaesdeminas-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/aracuai-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/aracuai-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/garanhuns-pe/patos-pb'},
    {link: '/passagem-de-onibus/garanhuns-pe/teofilootoni-mg'},
    {link: '/passagem-de-onibus/garanhuns-pe/feiradesantana-ba'},
    {link: '/passagem-de-onibus/salgueiro-pe/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/salgueiro-pe/betim-mg'},
    {link: '/passagem-de-onibus/salgueiro-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/belohorizonte-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/altogarcas-mt'},
    {link: '/passagem-de-onibus/belohorizonte-mg/goiania-go'},
    {link: '/passagem-de-onibus/campobelo-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/saoraimundononato-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/saoraimundononato-pi/casanova-ba'},
    {link: '/passagem-de-onibus/saoraimundononato-pi/remanso-ba'},
    {link: '/passagem-de-onibus/saoraimundononato-pi/juazeiro-ba'},
    {link: '/passagem-de-onibus/aracuai-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/saosimao-go/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/saosimao-go/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/saosimao-go/mantena-mg'},
    {link: '/passagem-de-onibus/saosimao-go/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/saosimao-go/ipatinga-mg'},
    {link: '/passagem-de-onibus/garanhuns-pe/voltaredonda-rj'},
    {link: '/passagem-de-onibus/garanhuns-pe/caratinga-mg'},
    {link: '/passagem-de-onibus/garanhuns-pe/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/garanhuns-pe/jequie-ba'},
    {link: '/passagem-de-onibus/garanhuns-pe/osasco-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/alegre-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/anchieta-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/conceicaodocastelo-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/santoandre-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/corinto-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/corinto-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/corinto-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/conceicaodabarra-es'},
    {link: '/passagem-de-onibus/garanhuns-pe/natal-rn'},
    {link: '/passagem-de-onibus/garanhuns-pe/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/garanhuns-pe/milagres,ba-ba'},
    {link: '/passagem-de-onibus/garanhuns-pe/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/garanhuns-pe/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/garanhuns-pe/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/garanhuns-pe/realeza,mg-mg'},
    {link: '/passagem-de-onibus/garanhuns-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/garanhuns-pe/teixeira-pb'},
    {link: '/passagem-de-onibus/garanhuns-pe/leopoldina-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/inhambupe-ba'},
    {link: '/passagem-de-onibus/aracuai-mg/diamantina-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/diamantina-mg'},
    {link: '/passagem-de-onibus/aracuai-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/aracuai-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/aracuai-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/barro-ce/catoledorocha-pb'},
    {link: '/passagem-de-onibus/barro-ce/jerico-pb'},
    {link: '/passagem-de-onibus/barro-ce/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/campobelo-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/campobelo-mg/brasilia-df'},
    {link: '/passagem-de-onibus/belohorizonte-mg/campanario-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/crisolita-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/caravelas-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/vendanovadoimigrante-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/araxa-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/monteazul-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/domsilverio-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/catalao-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/durande-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/dionisio-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/campogrande-ms'},
    {link: '/passagem-de-onibus/belohorizonte-mg/martinssoares-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pedraazul-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/belohorizonte-mg/maringa-pr'},
    {link: '/passagem-de-onibus/belohorizonte-mg/castelo-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/aguasformosas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/mirandopolis-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/bertopolis-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saodomingosdoprata-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/malacacheta-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/luz-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/aimores,mg-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/campanario-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/ceres-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/lajinha-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/marataizes-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itinga-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/osasco-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/centraldeminas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/jequie-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/jequie-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/urandi-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pavao-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/ilheus-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/anapolis-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/lassance-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/lontra-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/medina-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/naque-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/alcobaca-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/machacalis-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/arapua-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/jau-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/ibia-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/mantena-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/belohorizonte-mg/maceio-al'},
    {link: '/passagem-de-onibus/belohorizonte-mg/penapolis-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/recife-pe'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itueta-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/guarapari-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/natal-rn'},
    {link: '/passagem-de-onibus/belohorizonte-mg/carloschagas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/assis-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/parapua-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/janauba-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/guacui-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/cachoeirodeitapemirim-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/coutodemagalhaesdeminas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/ipameri-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/mutum-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/araraquara-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/piuma-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/novaalmeida-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/resplendor-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/novavenecia-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/almenara-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/januaria-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saogabrieldapalha-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/novaera-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/barretos-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/curitiba-pr'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saojoaodomanteninha-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saomateus-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/vilavelha-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/novaalvoradadosul-ms'},
    {link: '/passagem-de-onibus/belohorizonte-mg/aracaju-se'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itabuna-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/treslagoas-ms'},
    {link: '/passagem-de-onibus/belohorizonte-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/novoorientedeminas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/nanuque-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/belohorizonte-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/galileia-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/jatai-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pontodosvolantes-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pederneiras-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pedrasdemariadacruz-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saocarlos-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/prado-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pirajui-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pote-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/pote-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/prudentedemorais-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/crateus-ce'},
    {link: '/passagem-de-onibus/belohorizonte-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/bauru-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/mineiros-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/guarulhos-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/marilia-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/rioverde-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/salvador-ba'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodoce,mg-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/belavistademinas-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/carmodoparanaiba-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/belohorizonte-mg/conselheiropena-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/guarapari-es'},
    {link: '/passagem-de-onibus/belohorizonte-mg/vilavelha-es'},
    {link: '/passagem-de-onibus/biritinga-ba/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ibicarai-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/caceres-mt'},
    {link: '/passagem-de-onibus/ituiutaba-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/ituiutaba-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/pimentabueno-ro'},
    {link: '/passagem-de-onibus/corinto-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/corinto-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/ibicarai-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/ituiutaba-mg/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/ituiutaba-mg/altogarcas-mt'},
    {link: '/passagem-de-onibus/ituiutaba-mg/saosimao-go'},
    {link: '/passagem-de-onibus/ituiutaba-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/luz-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/araxa-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/ji-parana-ro'},
    {link: '/passagem-de-onibus/ituiutaba-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/corinto-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/corinto-mg/engenheironavarro-mg'},
    {link: '/passagem-de-onibus/corinto-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/corinto-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/corinto-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/conceicaodabarra-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/conceicaodabarra-es/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/conceicaodabarra-es/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/conceicaodabarra-es/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/corinto-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/corinto-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/corinto-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/corinto-mg/prudentedemorais-mg'},
    {link: '/passagem-de-onibus/corinto-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/corinto-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/corinto-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/corinto-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/corinto-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/corinto-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/ituiutaba-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/cuiaba-mt'},
    {link: '/passagem-de-onibus/ituiutaba-mg/moema-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/paranaiguara-go'},
    {link: '/passagem-de-onibus/ituiutaba-mg/vilhena-ro'},
    {link: '/passagem-de-onibus/ituiutaba-mg/ibia-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/ituiutaba-mg/ariquemes-ro'},
    {link: '/passagem-de-onibus/ituiutaba-mg/portovelho-ro'},
    {link: '/passagem-de-onibus/corinto-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/corinto-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/corinto-mg/pedroleopoldo-mg'},
    {link: '/passagem-de-onibus/corinto-mg/lassance-mg'},
    {link: '/passagem-de-onibus/corinto-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/corinto-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/corinto-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/corinto-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/corinto-mg/prudentedemorais-mg'},
    {link: '/passagem-de-onibus/corinto-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/corinto-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/corinto-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/corinto-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/corinto-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/conceicaodabarra-es/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/campanario-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/crisolita-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/aguasformosas-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/pavao-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/novoorientedeminas-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/campinas-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/montesclaros-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/americana-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/pedrasdemariadacruz-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/pindai-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/aracaju-se'},
    {link: '/passagem-de-onibus/bertopolis-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/bertopolis-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/pousoalegre-mg/brasilia-df'},
    {link: '/passagem-de-onibus/mirabela-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/corinto-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/osasco-sp'},
    {link: '/passagem-de-onibus/mirabela-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/condeuba-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/limeira-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/cordeiros-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/jequie-ba'},
    {link: '/passagem-de-onibus/mirabela-mg/januaria-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/brumado-ba/goiania-go'},
    {link: '/passagem-de-onibus/brumado-ba/correntina-ba'},
    {link: '/passagem-de-onibus/brumado-ba/anapolis-go'},
    {link: '/passagem-de-onibus/brumado-ba/abadiania-go'},
    {link: '/passagem-de-onibus/brumado-ba/brasilia-df'},
    {link: '/passagem-de-onibus/itabaiana-se/saopaulo-sp'},
    {link: '/passagem-de-onibus/itabaiana-se/santos-sp'},
    {link: '/passagem-de-onibus/saodomingosdoprata-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saojoaodopiaui-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/saojoaodopiaui-pi/casanova-ba'},
    {link: '/passagem-de-onibus/saojoaodopiaui-pi/remanso-ba'},
    {link: '/passagem-de-onibus/saojoaodopiaui-pi/juazeiro-ba'},
    {link: '/passagem-de-onibus/gandu-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/gandu-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/gandu-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/gandu-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/mirabela-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/mirabela-mg/januaria-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/corinto-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/mirabela-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/corinto-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/franca-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/corinto-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/urandi-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/osasco-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/jacaraci-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/recife-pe'},
    {link: '/passagem-de-onibus/montesclaros-mg/maceio-al'},
    {link: '/passagem-de-onibus/montesclaros-mg/lontra-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/caratinga-mg/ibatiba-es'},
    {link: '/passagem-de-onibus/caratinga-mg/guarapari-es'},
    {link: '/passagem-de-onibus/caratinga-mg/maceio-al'},
    {link: '/passagem-de-onibus/caratinga-mg/campinas-sp'},
    {link: '/passagem-de-onibus/caratinga-mg/vitoria-es'},
    {link: '/passagem-de-onibus/santanadoipanema-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/santanadoipanema-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/saomigueldoscampos-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saomigueldoscampos-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/malacacheta-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/engenheironavarro-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/corinto-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/januaria-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/caratinga-mg/garanhuns-pe'},
    {link: '/passagem-de-onibus/montesclaros-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/januaria-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/montesclaros-mg/riachodesantana-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/montesclaros-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/serradosalitre-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/serradosalitre-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/serradosalitre-mg/araxa-mg'},
    {link: '/passagem-de-onibus/serradosalitre-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/serradosalitre-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/camocim-ce/saobenedito-ce'},
    {link: '/passagem-de-onibus/camocim-ce/ubajara-ce'},
    {link: '/passagem-de-onibus/camocim-ce/guaraciabadonorte-ce'},
    {link: '/passagem-de-onibus/camocim-ce/ipu-ce'},
    {link: '/passagem-de-onibus/luz-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/luz-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/luz-mg/araxa-mg'},
    {link: '/passagem-de-onibus/luz-mg/catalao-go'},
    {link: '/passagem-de-onibus/luz-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/luz-mg/mirandopolis-sp'},
    {link: '/passagem-de-onibus/luz-mg/jequie-ba'},
    {link: '/passagem-de-onibus/luz-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/luz-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/luz-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/camocim-ce/novarussas-ce'},
    {link: '/passagem-de-onibus/camocim-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/camocim-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/camocim-ce/vicosadoceara-ce'},
    {link: '/passagem-de-onibus/camocim-ce/crateus-ce'},
    {link: '/passagem-de-onibus/camocim-ce/ibiapina-ce'},
    {link: '/passagem-de-onibus/camocim-ce/tiangua-ce'},
    {link: '/passagem-de-onibus/luz-mg/goiania-go'},
    {link: '/passagem-de-onibus/luz-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/malacacheta-mg/periquito-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/serrinha-ba'},
    {link: '/passagem-de-onibus/malacacheta-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/tucano-ba'},
    {link: '/passagem-de-onibus/serradosalitre-mg/araxa-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/campanario-mg'},
    {link: '/passagem-de-onibus/malacacheta-mg/campanario-mg'},
    {link: '/passagem-de-onibus/luz-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/luz-mg/barretos-sp'},
    {link: '/passagem-de-onibus/luz-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/luz-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/luz-mg/carmodoparanaiba-mg'},
    {link: '/passagem-de-onibus/luz-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/condeuba-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/araxa-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/ibia-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/corinto-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/bocaiuva-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/bocaiuva-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/bocaiuva-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/bocaiuva-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/bocaiuva-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/bocaiuva-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/bocaiuva-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/bocaiuva-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/bocaiuva-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/bocaiuva-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/corinto-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/luz-mg/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/luz-mg/brasilia-df'},
    {link: '/passagem-de-onibus/luz-mg/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/luz-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/luz-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/luz-mg/aracaju-se'},
    {link: '/passagem-de-onibus/luz-mg/treslagoas-ms'},
    {link: '/passagem-de-onibus/luz-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/luz-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/luz-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/bambui-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/ibia-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/ibia-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/arcos-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/formiga-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/corinto-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/bocaiuva-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/luz-mg/ipameri-go'},
    {link: '/passagem-de-onibus/luz-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/luz-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/luz-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/luz-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/luz-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/luz-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/luz-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/luz-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/luz-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/lavras-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/araxa-mg'},
    {link: '/passagem-de-onibus/perdizes-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/luz-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/luz-mg/recife-pe'},
    {link: '/passagem-de-onibus/luz-mg/arapua-mg'},
    {link: '/passagem-de-onibus/luz-mg/ibia-mg'},
    {link: '/passagem-de-onibus/luz-mg/maceio-al'},
    {link: '/passagem-de-onibus/luz-mg/penapolis-sp'},
    {link: '/passagem-de-onibus/luz-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/luz-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/luz-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/luz-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/chapadadonorte-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saofranciscodesales-mg/frutal-mg'},
    {link: '/passagem-de-onibus/saofranciscodesales-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/saofranciscodesales-mg/iturama-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/corinto-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/bocaiuva-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/pilaoarcado-ba'},
    {link: '/passagem-de-onibus/correntina-ba/correntina-ba'},
    {link: '/passagem-de-onibus/limeira-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/limeira-sp/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/limeira-sp/espinosa-mg'},
    {link: '/passagem-de-onibus/limeira-sp/urandi-ba'},
    {link: '/passagem-de-onibus/limeira-sp/guanambi-ba'},
    {link: '/passagem-de-onibus/limeira-sp/pindai-ba'},
    {link: '/passagem-de-onibus/limeira-sp/janauba-mg'},
    {link: '/passagem-de-onibus/limeira-sp/riachodesantana-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/betim-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/paraopeba-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/casanova-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/casanova-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/remanso-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/salvador-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/capimgrosso-ba/salinas-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/curvelo-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/setelagoas-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/juazeiro-ba'},
    {link: '/passagem-de-onibus/limeira-sp/pirapora-mg'},
    {link: '/passagem-de-onibus/campanario-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/campanario-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/campanario-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/campanario-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/campanario-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/campanario-mg/periquito-mg'},
    {link: '/passagem-de-onibus/campanario-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/campanario-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/campanario-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/lavras-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/lavras-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/lavras-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/lavras-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/lavras-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/lavras-mg/arcos-mg'},
    {link: '/passagem-de-onibus/lavras-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/lavras-mg/formiga-mg'},
    {link: '/passagem-de-onibus/contagem-mg/osasco-sp'},
    {link: '/passagem-de-onibus/contagem-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/campanario-mg/bertopolis-mg'},
    {link: '/passagem-de-onibus/campanario-mg/crisolita-mg'},
    {link: '/passagem-de-onibus/campanario-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/campanario-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/campanario-mg/aguasformosas-mg'},
    {link: '/passagem-de-onibus/campanario-mg/pavao-mg'},
    {link: '/passagem-de-onibus/campanario-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/campanario-mg/machacalis-mg'},
    {link: '/passagem-de-onibus/campanario-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/campanario-mg/mucuri-ba'},
    {link: '/passagem-de-onibus/contagem-mg/curitiba-pr'},
    {link: '/passagem-de-onibus/saobenedito-ce/camocim-ce'},
    {link: '/passagem-de-onibus/saobenedito-ce/granja-ce'},
    {link: '/passagem-de-onibus/saobenedito-ce/novarussas-ce'},
    {link: '/passagem-de-onibus/saobenedito-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/saobenedito-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/saobenedito-ce/vicosadoceara-ce'},
    {link: '/passagem-de-onibus/saobenedito-ce/crateus-ce'},
    {link: '/passagem-de-onibus/aimores,mg-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/aimores,mg-mg/periquito-mg'},
    {link: '/passagem-de-onibus/aimores,mg-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/lavras-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/lavras-mg/araxa-mg'},
    {link: '/passagem-de-onibus/lavras-mg/bambui-mg'},
    {link: '/passagem-de-onibus/lavras-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/lavras-mg/ibia-mg'},
    {link: '/passagem-de-onibus/lavras-mg/ibia-mg'},
    {link: '/passagem-de-onibus/capimgrosso-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/carinhanha-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/aimores,mg-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/aimores,mg-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/almenara-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/vendanovadoimigrante-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/vendanovadoimigrante-es/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/vendanovadoimigrante-es/manhuacu-mg'},
    {link: '/passagem-de-onibus/vendanovadoimigrante-es/betim-mg'},
    {link: '/passagem-de-onibus/vendanovadoimigrante-es/pontenova-mg'},
    {link: '/passagem-de-onibus/vendanovadoimigrante-es/realeza,mg-mg'},
    {link: '/passagem-de-onibus/vendanovadoimigrante-es/riocasca-mg'},
    {link: '/passagem-de-onibus/catoledorocha-pb/caico-rn'},
    {link: '/passagem-de-onibus/caravelas-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/carai-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/padreparaiso-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/campanario-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/pedraazul-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/padreparaiso-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/bauru-sp'},
    {link: '/passagem-de-onibus/madalena-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/corinto-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/campanario-mg/novoorientedeminas-mg'},
    {link: '/passagem-de-onibus/campanario-mg/periquito-mg'},
    {link: '/passagem-de-onibus/campanario-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/campanario-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/conquista-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/conquista-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/conquista-mg/sacramento-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/araxa-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/campogrande-ms'},
    {link: '/passagem-de-onibus/divinopolis-mg/assis-sp'},
    {link: '/passagem-de-onibus/crisolita-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/cordeiros-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/cordeiros-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/catoledorocha-pb/barro-ce'},
    {link: '/passagem-de-onibus/catoledorocha-pb/crato-ce'},
    {link: '/passagem-de-onibus/catoledorocha-pb/missaovelha-ce'},
    {link: '/passagem-de-onibus/catoledorocha-pb/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/catoledorocha-pb/natal-rn'},
    {link: '/passagem-de-onibus/catoledorocha-pb/milagres,ce-ce'},
    {link: '/passagem-de-onibus/catoledorocha-pb/barbalha-ce'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/janauba-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/capitaoeneas-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/crisolita-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/crisolita-mg/campanario-mg'},
    {link: '/passagem-de-onibus/crisolita-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/crisolita-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/crisolita-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/jau-sp'},
    {link: '/passagem-de-onibus/divinopolis-mg/ibia-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/araraquara-sp'},
    {link: '/passagem-de-onibus/divinopolis-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/divinopolis-mg/pederneiras-sp'},
    {link: '/passagem-de-onibus/divinopolis-mg/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/divinopolis-mg/saocarlos-sp'},
    {link: '/passagem-de-onibus/divinopolis-mg/pirajui-sp'},
    {link: '/passagem-de-onibus/divinopolis-mg/marilia-sp'},
    {link: '/passagem-de-onibus/padreparaiso-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/padreparaiso-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/medina-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/pontodosvolantes-mg'},
    {link: '/passagem-de-onibus/padreparaiso-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/padreparaiso-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/vendanovadoimigrante-es/ouropreto-mg'},
    {link: '/passagem-de-onibus/domcavati-mg/vitoria-es'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/brasilia-df'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/mantena-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/saojoaodomanteninha-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/mantena-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/periquito-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/saojoaodomanteninha-mg'},
    {link: '/passagem-de-onibus/passos-mg/campogrande-ms'},
    {link: '/passagem-de-onibus/passos-mg/assis-sp'},
    {link: '/passagem-de-onibus/passos-mg/jau-sp'},
    {link: '/passagem-de-onibus/passos-mg/araraquara-sp'},
    {link: '/passagem-de-onibus/passos-mg/pederneiras-sp'},
    {link: '/passagem-de-onibus/passos-mg/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/passos-mg/saocarlos-sp'},
    {link: '/passagem-de-onibus/passos-mg/pirajui-sp'},
    {link: '/passagem-de-onibus/passos-mg/marilia-sp'},
    {link: '/passagem-de-onibus/passos-mg/bauru-sp'},
    {link: '/passagem-de-onibus/araxa-mg/goiania-go'},
    {link: '/passagem-de-onibus/araxa-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/araxa-mg/saosimao-go'},
    {link: '/passagem-de-onibus/araxa-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/araxa-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/araxa-mg/serradosalitre-mg'},
    {link: '/passagem-de-onibus/araxa-mg/serradosalitre-mg'},
    {link: '/passagem-de-onibus/araxa-mg/luz-mg'},
    {link: '/passagem-de-onibus/araxa-mg/rioverde-go'},
    {link: '/passagem-de-onibus/araxa-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/araxa-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/araxa-mg/londrina-pr'},
    {link: '/passagem-de-onibus/araxa-mg/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/araxa-mg/aracaju-se'},
    {link: '/passagem-de-onibus/araxa-mg/treslagoas-ms'},
    {link: '/passagem-de-onibus/araxa-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/araxa-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/araxa-mg/arcos-mg'},
    {link: '/passagem-de-onibus/araxa-mg/maceio-al'},
    {link: '/passagem-de-onibus/araxa-mg/penapolis-sp'},
    {link: '/passagem-de-onibus/araxa-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/araxa-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/araxa-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/araxa-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/araxa-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/araxa-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/araxa-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/araxa-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/araxa-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/araxa-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/araxa-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/araxa-mg/jatai-go'},
    {link: '/passagem-de-onibus/araxa-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/araxa-mg/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/araxa-mg/pindai-ba'},
    {link: '/passagem-de-onibus/araxa-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/araxa-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/araxa-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/araxa-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/araxa-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/araxa-mg/lavras-mg'},
    {link: '/passagem-de-onibus/araxa-mg/divinopolis-mg'},
    {link: '/passagem-de-onibus/araxa-mg/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/araxa-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/araxa-mg/maringa-pr'},
    {link: '/passagem-de-onibus/araxa-mg/mirandopolis-sp'},
    {link: '/passagem-de-onibus/araxa-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/araxa-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/araxa-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/araxa-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/araxa-mg/bambui-mg'},
    {link: '/passagem-de-onibus/araxa-mg/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/araxa-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/araxa-mg/recife-pe'},
    {link: '/passagem-de-onibus/araxa-mg/ibia-mg'},
    {link: '/passagem-de-onibus/araxa-mg/ibia-mg'},
    {link: '/passagem-de-onibus/araxa-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/mantena-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/periquito-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/divinodaslaranjeiras-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/cuiaba-mt'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pauloafonso-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/recife-pe'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/itueta-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/vilhena-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/maceio-al'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/araxa-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/araxa-mg/cruzeirodafortaleza-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/goiania-go'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/jaru-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/saosimao-go'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/garanhuns-pe'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/campanario-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/campanario-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/quixada-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ariquemes-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ouropretodooeste-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/taua-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/jatai-go'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pedrabranca-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pedrabranca-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/picos-pi'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/portovelho-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/periquito-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/sobral-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/rondonopolis-mt'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/caceres-mt'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/presidentemedici-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/crato-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/caninde-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/fortaleza-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ji-parana-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/maringa-pr'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ilheus-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/itabuna-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/crateus-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/mineiros-go'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pimentabueno-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/barretos-sp'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/boaviagem-ce'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/itapetinga-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/goiania-go'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/conceicaodabarra-es'},
    {link: '/passagem-de-onibus/araxa-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/araxa-mg/formiga-mg'},
    {link: '/passagem-de-onibus/araxa-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/araxa-mg/mineiros-go'},
    {link: '/passagem-de-onibus/araxa-mg/barretos-sp'},
    {link: '/passagem-de-onibus/araxa-mg/riachodesantana-ba'},
    {link: '/passagem-de-onibus/araxa-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/araxa-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/araxa-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/araxa-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/cacoal-ro'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/londrina-pr'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/brasilia-df'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/mossoro-rn'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/aracaju-se'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pontodosvolantes-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/periquito-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pote-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pote-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/brasilia-df'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/novavenecia-es'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/almenara-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/saogabrieldapalha-es'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/vitoria-es'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/saojoaodomanteninha-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/saomateus-es'},
    {link: '/passagem-de-onibus/frutal-mg/saofranciscodesales-mg'},
    {link: '/passagem-de-onibus/frutal-mg/conceicaodasalagoas-mg'},
    {link: '/passagem-de-onibus/frutal-mg/palmas,to-to'},
    {link: '/passagem-de-onibus/frutal-mg/jequie-ba'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/ibatiba-es'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pedraazul-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/aguasformosas-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/pavao-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/centraldeminas-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/guarapari-es'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/timoteo-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/carloschagas-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/mantena-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/naque-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/machacalis-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/novoorientedeminas-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/nanuque-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/crisolita-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/aguasformosas-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/itambacuri-mg/pavao-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/itambacuri-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/frutal-mg/aracaju-se'},
    {link: '/passagem-de-onibus/frutal-mg/porangatu-go'},
    {link: '/passagem-de-onibus/frutal-mg/portonacional-to'},
    {link: '/passagem-de-onibus/frutal-mg/alvorada-to'},
    {link: '/passagem-de-onibus/frutal-mg/uruacu-go'},
    {link: '/passagem-de-onibus/frutal-mg/jaragua-go'},
    {link: '/passagem-de-onibus/frutal-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/itambacuri-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/bertopolis-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/malacacheta-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/machacalis-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/novoorientedeminas-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/periquito-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/pote-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/pote-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/itambacuri-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/almenara-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/bertopolis-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/malacacheta-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/campanario-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/campanario-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/crisolita-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/governadorvaladares-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/frutal-mg/ceres-go'},
    {link: '/passagem-de-onibus/frutal-mg/recife-pe'},
    {link: '/passagem-de-onibus/frutal-mg/gurupi-to'},
    {link: '/passagem-de-onibus/frutal-mg/maceio-al'},
    {link: '/passagem-de-onibus/frutal-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/frutal-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/frutal-mg/iturama-mg'},
    {link: '/passagem-de-onibus/frutal-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/frutal-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/frutal-mg/itapagipe-mg'},
    {link: '/passagem-de-onibus/campogrande-ms/saocarlos-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/pirajui-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/barretos-sp'},
    {link: '/passagem-de-onibus/esplanada-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/esplanada-ba/santos-sp'},
    {link: '/passagem-de-onibus/cacule-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/conceicaodasalagoas-mg/frutal-mg'},
    {link: '/passagem-de-onibus/catalao-go/luz-mg'},
    {link: '/passagem-de-onibus/catalao-go/ibia-mg'},
    {link: '/passagem-de-onibus/catalao-go/brasilia-df'},
    {link: '/passagem-de-onibus/durande-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/durande-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/crato-ce/belohorizonte-mg'},
    {link: '/passagem-de-onibus/crato-ce/catoledorocha-pb'},
    {link: '/passagem-de-onibus/crato-ce/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/crato-ce/jerico-pb'},
    {link: '/passagem-de-onibus/crato-ce/natal-rn'},
    {link: '/passagem-de-onibus/crato-ce/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/conceicaodasalagoas-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/conceicaodasalagoas-mg/iturama-mg'},
    {link: '/passagem-de-onibus/martinssoares-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/martinssoares-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/montesanto-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/montalvania-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/franca-sp'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/goiania-go'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/correntina-ba'},
    {link: '/passagem-de-onibus/monteazul-mg/janauba-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/domsilverio-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/domsilverio-mg/caete-mg'},
    {link: '/passagem-de-onibus/domsilverio-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/domsilverio-mg/bomjesusdoamparo-mg'},
    {link: '/passagem-de-onibus/catalao-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/crato-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/crato-ce/sousa-pb'},
    {link: '/passagem-de-onibus/crato-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/crato-ce/caico-rn'},
    {link: '/passagem-de-onibus/crato-ce/brejodocruz-pb'},
    {link: '/passagem-de-onibus/ibatiba-es/caratinga-mg'},
    {link: '/passagem-de-onibus/ibatiba-es/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ibatiba-es/betim-mg'},
    {link: '/passagem-de-onibus/ibatiba-es/ouropreto-mg'},
    {link: '/passagem-de-onibus/itambacuri-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/monteazul-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/corinto-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/monteazul-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/campogrande-ms/passos-mg'},
    {link: '/passagem-de-onibus/campogrande-ms/recife-pe'},
    {link: '/passagem-de-onibus/campogrande-ms/uberaba-mg'},
    {link: '/passagem-de-onibus/campogrande-ms/araraquara-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/campogrande-ms/pederneiras-sp'},
    {link: '/passagem-de-onibus/dionisio-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/engenheirocaldas-mg/vitoria-es'},
    {link: '/passagem-de-onibus/campogrande-ms/belohorizonte-mg'},
    {link: '/passagem-de-onibus/campogrande-ms/divinopolis-mg'},
    {link: '/passagem-de-onibus/piresdorio-go/montecarmelo-mg'},
    {link: '/passagem-de-onibus/piresdorio-go/patosdeminas-mg'},
    {link: '/passagem-de-onibus/piresdorio-go/ibia-mg'},
    {link: '/passagem-de-onibus/piresdorio-go/patrocinio-mg'},
    {link: '/passagem-de-onibus/piresdorio-go/saogotardo-mg'},
    {link: '/passagem-de-onibus/domexpeditolopes-pi/fortaleza-ce'},
    {link: '/passagem-de-onibus/domexpeditolopes-pi/taua-ce'},
    {link: '/passagem-de-onibus/domexpeditolopes-pi/pedrabranca-ce'},
    {link: '/passagem-de-onibus/exu-pe/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/exu-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/teresopolis-rj/recife-pe'},
    {link: '/passagem-de-onibus/pedraazul-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/pedraazul-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/pedraazul-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/pedraazul-mg/medina-mg'},
    {link: '/passagem-de-onibus/pedraazul-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/pedraazul-mg/pontodosvolantes-mg'},
    {link: '/passagem-de-onibus/pedraazul-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/pedraazul-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/franca-sp'},
    {link: '/passagem-de-onibus/matoverde-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/missaovelha-ce/catoledorocha-pb'},
    {link: '/passagem-de-onibus/missaovelha-ce/jerico-pb'},
    {link: '/passagem-de-onibus/missaovelha-ce/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/missaovelha-ce/sousa-pb'},
    {link: '/passagem-de-onibus/missaovelha-ce/caico-rn'},
    {link: '/passagem-de-onibus/missaovelha-ce/brejodocruz-pb'},
    {link: '/passagem-de-onibus/piresdorio-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/piresdorio-go/luz-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/espinosa-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/corinto-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/carinhanha-ba'},
    {link: '/passagem-de-onibus/espinosa-mg/limeira-sp'},
    {link: '/passagem-de-onibus/espinosa-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/cacule-ba'},
    {link: '/passagem-de-onibus/espinosa-mg/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/espinosa-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/corinto-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/janauba-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/janauba-mg'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/lambari-mg/cristalina-go'},
    {link: '/passagem-de-onibus/lambari-mg/brasilia-df'},
    {link: '/passagem-de-onibus/ubajara-ce/camocim-ce'},
    {link: '/passagem-de-onibus/ubajara-ce/ipu-ce'},
    {link: '/passagem-de-onibus/ubajara-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/ubajara-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/ubajara-ce/crateus-ce'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/limeira-sp'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/araxa-mg'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/anapolis-go'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/abadiania-go'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/ibia-mg'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/campinas-sp'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/bomjesusdalapa-ba/americana-sp'},
    {link: '/passagem-de-onibus/saosebastiaodoparaiso-mg/jau-sp'},
    {link: '/passagem-de-onibus/saosebastiaodoparaiso-mg/araraquara-sp'},
    {link: '/passagem-de-onibus/saosebastiaodoparaiso-mg/pederneiras-sp'},
    {link: '/passagem-de-onibus/saosebastiaodoparaiso-mg/saocarlos-sp'},
    {link: '/passagem-de-onibus/saosebastiaodoparaiso-mg/pirajui-sp'},
    {link: '/passagem-de-onibus/saosebastiaodoparaiso-mg/bauru-sp'},
    {link: '/passagem-de-onibus/matoverde-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/matoverde-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/matoverde-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pedroleopoldo-mg/corinto-mg'},
    {link: '/passagem-de-onibus/pedroleopoldo-mg/lassance-mg'},
    {link: '/passagem-de-onibus/pedroleopoldo-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/pedroleopoldo-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/pedroleopoldo-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/taiobeiras-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/pedraazul-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pedraazul-mg/campanario-mg'},
    {link: '/passagem-de-onibus/pedraazul-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/pindai-ba'},
    {link: '/passagem-de-onibus/espinosa-mg/janauba-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/riachodesantana-ba'},
    {link: '/passagem-de-onibus/floresta-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/caninde-ce/afranio-pe'},
    {link: '/passagem-de-onibus/caninde-ce/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/caninde-ce/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/caninde-ce/jaicos-pi'},
    {link: '/passagem-de-onibus/caninde-ce/petrolina-pe'},
    {link: '/passagem-de-onibus/caninde-ce/picos-pi'},
    {link: '/passagem-de-onibus/caninde-ce/patosdopiaui-pi'},
    {link: '/passagem-de-onibus/caninde-ce/paulistana-pi'},
    {link: '/passagem-de-onibus/caninde-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/caninde-ce/ipatinga-mg'},
    {link: '/passagem-de-onibus/caninde-ce/teofilootoni-mg'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/eunapolis-ba'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/itabuna-ba'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/ubaitaba-ba'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/linhares-es'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/valenca-rj'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/saomateus-es'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/saomateus-es'},
    {link: '/passagem-de-onibus/fortaleza-ce/afranio-pe'},
    {link: '/passagem-de-onibus/fortaleza-ce/domexpeditolopes-pi'},
    {link: '/passagem-de-onibus/ji-parana-ro/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/ji-parana-ro/altogarcas-mt'},
    {link: '/passagem-de-onibus/ji-parana-ro/ituiutaba-mg'},
    {link: '/passagem-de-onibus/ji-parana-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ji-parana-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/ji-parana-ro/mantena-mg'},
    {link: '/passagem-de-onibus/ji-parana-ro/uberaba-mg'},
    {link: '/passagem-de-onibus/ji-parana-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/ji-parana-ro/campinas-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/jatai-go'},
    {link: '/passagem-de-onibus/ji-parana-ro/saopaulo-sp'},
    {link: '/passagem-de-onibus/ji-parana-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/palmas,to-to/frutal-mg'},
    {link: '/passagem-de-onibus/palmas,to-to/araraquara-sp'},
    {link: '/passagem-de-onibus/palmas,to-to/campinas-sp'},
    {link: '/passagem-de-onibus/palmas,to-to/americana-sp'},
    {link: '/passagem-de-onibus/palmas,to-to/saocarlos-sp'},
    {link: '/passagem-de-onibus/palmas,to-to/saopaulo-sp'},
    {link: '/passagem-de-onibus/espinosa-mg/urandi-ba'},
    {link: '/passagem-de-onibus/espinosa-mg/jacaraci-ba'},
    {link: '/passagem-de-onibus/espinosa-mg/liciniodealmeida-ba'},
    {link: '/passagem-de-onibus/espinosa-mg/campinas-sp'},
    {link: '/passagem-de-onibus/espinosa-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/espinosa-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/espinosa-mg/palmasdemontealto-ba'},
    {link: '/passagem-de-onibus/espinosa-mg/americana-sp'},
    {link: '/passagem-de-onibus/palmas,to-to/prata-mg'},
    {link: '/passagem-de-onibus/palmas,to-to/barretos-sp'},
    {link: '/passagem-de-onibus/palmas,to-to/planura-mg'},
    {link: '/passagem-de-onibus/palmas,to-to/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/palmas,to-to/itumbiara-go'},
    {link: '/passagem-de-onibus/cascavel-pr/belohorizonte-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/araxa-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/jequie-ba'},
    {link: '/passagem-de-onibus/fortaleza-ce/jaicos-pi'},
    {link: '/passagem-de-onibus/fortaleza-ce/nazaredopiaui-pi'},
    {link: '/passagem-de-onibus/fortaleza-ce/picos-pi'},
    {link: '/passagem-de-onibus/fortaleza-ce/patosdopiaui-pi'},
    {link: '/passagem-de-onibus/fortaleza-ce/paulistana-pi'},
    {link: '/passagem-de-onibus/fortaleza-ce/oeiras-pi'},
    {link: '/passagem-de-onibus/fortaleza-ce/floriano-pi'},
    {link: '/passagem-de-onibus/fortaleza-ce/teofilootoni-mg'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/eunapolis-ba'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/portoseguro-ba'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/maceio-al'},
    {link: '/passagem-de-onibus/camposdosgoytacazes-rj/itamaraju-ba'},
    {link: '/passagem-de-onibus/cascavel-pr/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/itaobim-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/cascavel-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/cascavel-pr/salvador-ba'},
    {link: '/passagem-de-onibus/cascavel-pr/ipatinga-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/teofilootoni-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/feiradesantana-ba'},
    {link: '/passagem-de-onibus/maringa-pr/belohorizonte-mg'},
    {link: '/passagem-de-onibus/mirandopolis-sp/araxa-mg'},
    {link: '/passagem-de-onibus/mirandopolis-sp/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/mirandopolis-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/eunapolis-ba/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/maceio-al'},
    {link: '/passagem-de-onibus/eunapolis-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/eunapolis-ba/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/guaraciabadonorte-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/guaraciabadonorte-ce/vicosadoceara-ce'},
    {link: '/passagem-de-onibus/castelo-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/bertopolis-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/campanario-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/pavao-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/novoorientedeminas-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/aguasformosas-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/mirandopolis-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/mirandopolis-sp/luz-mg'},
    {link: '/passagem-de-onibus/maringa-pr/ipatinga-mg'},
    {link: '/passagem-de-onibus/maringa-pr/teofilootoni-mg'},
    {link: '/passagem-de-onibus/maringa-pr/feiradesantana-ba'},
    {link: '/passagem-de-onibus/joaopessoa-pb/palmares-pe'},
    {link: '/passagem-de-onibus/joaopessoa-pb/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/joaopessoa-pb/riolargo-al'},
    {link: '/passagem-de-onibus/joaopessoa-pb/leopoldina-mg'},
    {link: '/passagem-de-onibus/joaopessoa-pb/teofilootoni-mg'},
    {link: '/passagem-de-onibus/montecarmelo-mg/goiania-go'},
    {link: '/passagem-de-onibus/eunapolis-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/eunapolis-ba/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/curitiba-pr'},
    {link: '/passagem-de-onibus/eunapolis-ba/teofilootoni-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/goiania-go'},
    {link: '/passagem-de-onibus/eunapolis-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/correntina-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/campanario-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/padreparaiso-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/itambacuri-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/eunapolis-ba/itaobim-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/anapolis-go'},
    {link: '/passagem-de-onibus/eunapolis-ba/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/abadiania-go'},
    {link: '/passagem-de-onibus/eunapolis-ba/timoteo-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/jacinto-mg'},
    {link: '/passagem-de-onibus/montecarmelo-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/montecarmelo-mg/ipameri-go'},
    {link: '/passagem-de-onibus/granja-ce/ipu-ce'},
    {link: '/passagem-de-onibus/granja-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/granja-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/granja-ce/crateus-ce'},
    {link: '/passagem-de-onibus/granja-ce/tiangua-ce'},
    {link: '/passagem-de-onibus/guaraciabadonorte-ce/camocim-ce'},
    {link: '/passagem-de-onibus/guaraciabadonorte-ce/granja-ce'},
    {link: '/passagem-de-onibus/maringa-pr/araxa-mg'},
    {link: '/passagem-de-onibus/maringa-pr/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/maringa-pr/jequie-ba'},
    {link: '/passagem-de-onibus/maringa-pr/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/maringa-pr/itaobim-mg'},
    {link: '/passagem-de-onibus/maringa-pr/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/maringa-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/maringa-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/maringa-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/maringa-pr/salvador-ba'},
    {link: '/passagem-de-onibus/caetite-ba/brasilia-df'},
    {link: '/passagem-de-onibus/piracicaba-sp/ilheus-ba'},
    {link: '/passagem-de-onibus/piracicaba-sp/linhares-es'},
    {link: '/passagem-de-onibus/piracicaba-sp/saomateus-es'},
    {link: '/passagem-de-onibus/jequie-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jequie-ba/osasco-sp'},
    {link: '/passagem-de-onibus/jequie-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/jequie-ba/campinas-sp'},
    {link: '/passagem-de-onibus/jequie-ba/olimpia-sp'},
    {link: '/passagem-de-onibus/jequie-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/jequie-ba/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/jequie-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/jequie-ba/londrina-pr'},
    {link: '/passagem-de-onibus/jequie-ba/capelinha-mg'},
    {link: '/passagem-de-onibus/jequie-ba/salinas-mg'},
    {link: '/passagem-de-onibus/jequie-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/jequie-ba/aracaju-se'},
    {link: '/passagem-de-onibus/jequie-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/jequie-ba/natal-rn'},
    {link: '/passagem-de-onibus/jequie-ba/virginopolis-mg'},
    {link: '/passagem-de-onibus/jequie-ba/campomourao-pr'},
    {link: '/passagem-de-onibus/jequie-ba/maceio-al'},
    {link: '/passagem-de-onibus/jequie-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/jequie-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/jequie-ba/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/jequie-ba/minasnovas-mg'},
    {link: '/passagem-de-onibus/jequie-ba/acucena-mg'},
    {link: '/passagem-de-onibus/jequie-ba/araxa-mg'},
    {link: '/passagem-de-onibus/jequie-ba/frutal-mg'},
    {link: '/passagem-de-onibus/jequie-ba/cascavel-pr'},
    {link: '/passagem-de-onibus/jequie-ba/maringa-pr'},
    {link: '/passagem-de-onibus/jequie-ba/joaopessoa-pb'},
    {link: '/passagem-de-onibus/jequie-ba/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/jequie-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/jequie-ba/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/jequie-ba/osasco-sp'},
    {link: '/passagem-de-onibus/jequie-ba/recife-pe'},
    {link: '/passagem-de-onibus/jequie-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/jequie-ba/voltaredonda-rj'},
    {link: '/passagem-de-onibus/jequie-ba/aracuai-mg'},
    {link: '/passagem-de-onibus/jequie-ba/garanhuns-pe'},
    {link: '/passagem-de-onibus/jequie-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jequie-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/jequie-ba/luz-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/naque-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/eunapolis-ba/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/eunapolis-ba/freiinocencio-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/almenara-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/novaera-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/luz-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/araxa-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/moema-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/ibia-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/jequie-ba/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/jequie-ba/almenara-mg'},
    {link: '/passagem-de-onibus/jequie-ba/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/jequie-ba/barretos-sp'},
    {link: '/passagem-de-onibus/jequie-ba/taubate-sp'},
    {link: '/passagem-de-onibus/jequie-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/jequie-ba/santos-sp'},
    {link: '/passagem-de-onibus/jequie-ba/patrocinio-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/teofilootoni-mg'},
    {link: '/passagem-de-onibus/caetite-ba/goiania-go'},
    {link: '/passagem-de-onibus/caetite-ba/correntina-ba'},
    {link: '/passagem-de-onibus/caetite-ba/anapolis-go'},
    {link: '/passagem-de-onibus/caetite-ba/abadiania-go'},
    {link: '/passagem-de-onibus/novaserrana-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/urandi-ba/campinas-sp'},
    {link: '/passagem-de-onibus/urandi-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/urandi-ba/santoantoniodomonte-mg'},
    {link: '/passagem-de-onibus/urandi-ba/americana-sp'},
    {link: '/passagem-de-onibus/urandi-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/urandi-ba/lagoadaprata-mg'},
    {link: '/passagem-de-onibus/urandi-ba/martinhocampos-mg'},
    {link: '/passagem-de-onibus/urandi-ba/pompeu-mg'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/goiania-go'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/novaserrana-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/gouveia-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/gouveia-mg/josegoncalvesdeminas-mg'},
    {link: '/passagem-de-onibus/gouveia-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/gouveia-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/gouveia-mg/coutodemagalhaesdeminas-mg'},
    {link: '/passagem-de-onibus/gouveia-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/gouveia-mg/diamantina-mg'},
    {link: '/passagem-de-onibus/gouveia-mg/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/formiga-mg'},
    {link: '/passagem-de-onibus/urandi-ba/franca-sp'},
    {link: '/passagem-de-onibus/urandi-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/urandi-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/urandi-ba/limeira-sp'},
    {link: '/passagem-de-onibus/urandi-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/urandi-ba/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/urandi-ba/moema-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/carmodoparanaiba-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/carmodoparanaiba-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/luz-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/araxa-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/moema-mg'},
    {link: '/passagem-de-onibus/novaserrana-mg/ibia-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/araxa-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/bambui-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/perdoes-mg/arcos-mg'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/maceio-al'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/penapolis-sp'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/maringa-pr'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/mirandopolis-sp'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/jequie-ba'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/urandi-ba'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/recife-pe'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/minasnovas-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/acucena-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/capelinha-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/aracuai-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/osasco-sp'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/virginopolis-mg'},
    {link: '/passagem-de-onibus/ribeiradopombal-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/pavao-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pavao-mg/bertopolis-mg'},
    {link: '/passagem-de-onibus/pavao-mg/campanario-mg'},
    {link: '/passagem-de-onibus/pavao-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/pavao-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/pavao-mg/aguasformosas-mg'},
    {link: '/passagem-de-onibus/pavao-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/pavao-mg/machacalis-mg'},
    {link: '/passagem-de-onibus/pavao-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/pavao-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/itaobim-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/itaobim-mg/londrina-pr'},
    {link: '/passagem-de-onibus/itaobim-mg/brasilia-df'},
    {link: '/passagem-de-onibus/itaobim-mg/aracaju-se'},
    {link: '/passagem-de-onibus/itaobim-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/itaobim-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/almenara-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/itaobim-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/recife-pe'},
    {link: '/passagem-de-onibus/itaobim-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/itaobim-mg/maceio-al'},
    {link: '/passagem-de-onibus/itaobim-mg/medina-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/itaobim-mg/goiania-go'},
    {link: '/passagem-de-onibus/itaobim-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/campanario-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/pedraazul-mg'},
    {link: '/passagem-de-onibus/itaobim-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/itaobim-mg/maringa-pr'},
    {link: '/passagem-de-onibus/itaobim-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/patosdeminas-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/recife-pe'},
    {link: '/passagem-de-onibus/patosdeminas-mg/arapua-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/maceio-al'},
    {link: '/passagem-de-onibus/patosdeminas-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/ipameri-go'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/londrina-pr'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/brasilia-df'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/aracaju-se'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/treslagoas-ms'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/bomdespacho,mg-mg/barretos-sp'},
    {link: '/passagem-de-onibus/itaobim-mg/barretos-sp'},
    {link: '/passagem-de-onibus/itaobim-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/goiania-go'},
    {link: '/passagem-de-onibus/patosdeminas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/serradosalitre-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/serradosalitre-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/luz-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/araxa-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/freiinocencio-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/almenara-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/novaera-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/teofilootoni-mg'},
    {link: '/passagem-de-onibus/anapolis-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/anapolis-go/brumado-ba'},
    {link: '/passagem-de-onibus/anapolis-go/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/anapolis-go/eunapolis-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/cruzeirodafortaleza-mg'},
    {link: '/passagem-de-onibus/carire-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/carire-ce/crateus-ce'},
    {link: '/passagem-de-onibus/ilheus-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/pindai-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/saogoncalodoabaete-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/aracaju-se'},
    {link: '/passagem-de-onibus/patosdeminas-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/patosdeminas-mg/riachodesantana-ba'},
    {link: '/passagem-de-onibus/bambui-mg/brasilia-df'},
    {link: '/passagem-de-onibus/bambui-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/bambui-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/bambui-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/goiania-go'},
    {link: '/passagem-de-onibus/portoseguro-ba/anchieta-es'},
    {link: '/passagem-de-onibus/portoseguro-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/padreparaiso-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/itambacuri-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/ilheus-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ilheus-ba/piracicaba-sp'},
    {link: '/passagem-de-onibus/ilheus-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/ilheus-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/ilheus-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/bambui-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/bambui-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/bambui-mg/lavras-mg'},
    {link: '/passagem-de-onibus/bambui-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/bambui-mg/araxa-mg'},
    {link: '/passagem-de-onibus/bambui-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/bambui-mg/cristalina-go'},
    {link: '/passagem-de-onibus/bambui-mg/ibia-mg'},
    {link: '/passagem-de-onibus/bambui-mg/ibia-mg'},
    {link: '/passagem-de-onibus/bambui-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/bambui-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/bambui-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/bambui-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/bambui-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/bambui-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/guarapari-es'},
    {link: '/passagem-de-onibus/portoseguro-ba/timoteo-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/jacinto-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/naque-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/portoseguro-ba/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/portoseguro-ba/brasilia-df'},
    {link: '/passagem-de-onibus/portoseguro-ba/itaobim-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/anapolis-go'},
    {link: '/passagem-de-onibus/portoseguro-ba/marataizes-es'},
    {link: '/passagem-de-onibus/portoseguro-ba/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/abadiania-go'},
    {link: '/passagem-de-onibus/cristalina-go/formiga-mg'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/goiania-go'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/jaru-ro'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/anchieta-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/saosimao-go'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/conceicaodabarra-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/vendanovadoimigrante-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/caninde-ce'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/ji-parana-ro'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/marataizes-es/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/marataizes-es/carangola-mg'},
    {link: '/passagem-de-onibus/marataizes-es/ouropreto-mg'},
    {link: '/passagem-de-onibus/cristalina-go/trescoracoes-mg'},
    {link: '/passagem-de-onibus/cristalina-go/campobelo-mg'},
    {link: '/passagem-de-onibus/cristalina-go/lambari-mg'},
    {link: '/passagem-de-onibus/cristalina-go/bambui-mg'},
    {link: '/passagem-de-onibus/cristalina-go/saolourenco-mg'},
    {link: '/passagem-de-onibus/cristalina-go/cambuquira-mg'},
    {link: '/passagem-de-onibus/cristalina-go/arcos-mg'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/novavenecia-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/saogabrieldapalha-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/mineiros-go'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/pimentabueno-ro'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/barretos-sp'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/vitoria-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/boaviagem-ce'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/saomateus-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/rondonopolis-mt'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/caceres-mt'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/presidentemedici-ro'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/cacoal-ro'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/londrina-pr'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/brasilia-df'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/cachoeirodeitapemirim-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/vilavelha-es'},
    {link: '/passagem-de-onibus/lajinha-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/lajinha-mg/mutum-mg'},
    {link: '/passagem-de-onibus/lajinha-mg/realeza,mg-mg'},
    {link: '/passagem-de-onibus/lajinha-mg/riocasca-mg'},
    {link: '/passagem-de-onibus/marataizes-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/marataizes-es/portoseguro-ba'},
    {link: '/passagem-de-onibus/marataizes-es/manhuacu-mg'},
    {link: '/passagem-de-onibus/marataizes-es/manhumirim-mg'},
    {link: '/passagem-de-onibus/marataizes-es/pontenova-mg'},
    {link: '/passagem-de-onibus/marataizes-es/realeza,mg-mg'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/maringa-pr'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/marataizes-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/cuiaba-mt'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/vilhena-ro'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/guarapari-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/ceres-go/frutal-mg'},
    {link: '/passagem-de-onibus/ceres-go/araraquara-sp'},
    {link: '/passagem-de-onibus/ceres-go/campinas-sp'},
    {link: '/passagem-de-onibus/ceres-go/saocarlos-sp'},
    {link: '/passagem-de-onibus/ceres-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/ceres-go/prata-mg'},
    {link: '/passagem-de-onibus/ceres-go/planura-mg'},
    {link: '/passagem-de-onibus/ceres-go/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/lajinha-mg/abrecampo-mg'},
    {link: '/passagem-de-onibus/lajinha-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/novaalmeida-es'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/ariquemes-ro'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/ouropretodooeste-ro'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/taua-ce'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/jatai-go'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/pedrabranca-ce'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/portovelho-ro'},
    {link: '/passagem-de-onibus/anapolis-go/caetite-ba'},
    {link: '/passagem-de-onibus/anapolis-go/portoseguro-ba'},
    {link: '/passagem-de-onibus/anapolis-go/itarantim-ba'},
    {link: '/passagem-de-onibus/anapolis-go/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/anapolis-go/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/anapolis-go/potiragua-ba'},
    {link: '/passagem-de-onibus/anapolis-go/anage-ba'},
    {link: '/passagem-de-onibus/anapolis-go/itapetinga-ba'},
    {link: '/passagem-de-onibus/ceres-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/jequie-ba'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/joaomonlevade-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itinga-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itinga-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/itinga-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/brasiliademinas-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/inhapim-mg/guarapari-es'},
    {link: '/passagem-de-onibus/inhapim-mg/vitoria-es'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/campanario-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/salvador-ba'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/almenara-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/jequitinhonha-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/araxa-mg'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/mantena-mg'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/ipatinga-mg'},
    {link: '/passagem-de-onibus/pauloafonso-ba/osasco-sp'},
    {link: '/passagem-de-onibus/pauloafonso-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/pauloafonso-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/cuiaba-mt/ipatinga-mg'},
    {link: '/passagem-de-onibus/cuiaba-mt/itumbiara-go'},
    {link: '/passagem-de-onibus/palmeiradosindios-al/natal-rn'},
    {link: '/passagem-de-onibus/palmeiradosindios-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/palmeiradosindios-al/salvador-ba'},
    {link: '/passagem-de-onibus/palmeiradosindios-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/portoferreira-sp/brasilia-df'},
    {link: '/passagem-de-onibus/itanhem-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/cuiaba-mt/ituiutaba-mg'},
    {link: '/passagem-de-onibus/cuiaba-mt/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/cuiaba-mt/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/cuiaba-mt/mantena-mg'},
    {link: '/passagem-de-onibus/cuiaba-mt/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/itarantim-ba/goiania-go'},
    {link: '/passagem-de-onibus/itarantim-ba/correntina-ba'},
    {link: '/passagem-de-onibus/itarantim-ba/anapolis-go'},
    {link: '/passagem-de-onibus/itarantim-ba/abadiania-go'},
    {link: '/passagem-de-onibus/itarantim-ba/brasilia-df'},
    {link: '/passagem-de-onibus/moema-mg/urandi-ba'},
    {link: '/passagem-de-onibus/moema-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/moema-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/moema-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/cuiaba-mt/juizdefora-mg'},
    {link: '/passagem-de-onibus/cuiaba-mt/betim-mg'},
    {link: '/passagem-de-onibus/recife-pe/ipatinga-mg'},
    {link: '/passagem-de-onibus/recife-pe/barretos-sp'},
    {link: '/passagem-de-onibus/recife-pe/curitiba-pr'},
    {link: '/passagem-de-onibus/recife-pe/pirapora-mg'},
    {link: '/passagem-de-onibus/recife-pe/teofilootoni-mg'},
    {link: '/passagem-de-onibus/recife-pe/patrocinio-mg'},
    {link: '/passagem-de-onibus/itueta-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itueta-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/itueta-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/itueta-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/itueta-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/abadiania-go/portoseguro-ba'},
    {link: '/passagem-de-onibus/abadiania-go/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/abadiania-go/potiragua-ba'},
    {link: '/passagem-de-onibus/abadiania-go/itagimirim-ba'},
    {link: '/passagem-de-onibus/itajuba-mg/brasilia-df'},
    {link: '/passagem-de-onibus/centraldeminas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/centraldeminas-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/centraldeminas-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/centraldeminas-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/centraldeminas-mg/mantena-mg'},
    {link: '/passagem-de-onibus/jaicos-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/jaicos-pi/taua-ce'},
    {link: '/passagem-de-onibus/jaicos-pi/pedrabranca-ce'},
    {link: '/passagem-de-onibus/jaicos-pi/boaviagem-ce'},
    {link: '/passagem-de-onibus/jacaraci-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/jacaraci-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/jacaraci-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/catoledorocha-pb'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/pauloafonso-ba'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/jerico-pb'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/natal-rn'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/sousa-pb'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/caico-rn'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/brejodocruz-pb'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/serratalhada-pe'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/aracaju-se'},
    {link: '/passagem-de-onibus/jacobinadopiaui-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/abadiania-go/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/abadiania-go/eunapolis-ba'},
    {link: '/passagem-de-onibus/recife-pe/uberlandia-mg'},
    {link: '/passagem-de-onibus/recife-pe/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/recife-pe/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/recife-pe/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/recife-pe/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/recife-pe/olimpia-sp'},
    {link: '/passagem-de-onibus/recife-pe/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/recife-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/recife-pe/salinas-mg'},
    {link: '/passagem-de-onibus/recife-pe/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/iguatama-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/jaicos-pi/caninde-ce'},
    {link: '/passagem-de-onibus/jaicos-pi/fortaleza-ce'},
    {link: '/passagem-de-onibus/centraldeminas-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/centraldeminas-mg/periquito-mg'},
    {link: '/passagem-de-onibus/centraldeminas-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/centraldeminas-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/jerico-pb/barro-ce'},
    {link: '/passagem-de-onibus/jerico-pb/crato-ce'},
    {link: '/passagem-de-onibus/jerico-pb/natal-rn'},
    {link: '/passagem-de-onibus/jerico-pb/milagres,ce-ce'},
    {link: '/passagem-de-onibus/recife-pe/belohorizonte-mg'},
    {link: '/passagem-de-onibus/recife-pe/montesclaros-mg'},
    {link: '/passagem-de-onibus/recife-pe/luz-mg'},
    {link: '/passagem-de-onibus/recife-pe/araxa-mg'},
    {link: '/passagem-de-onibus/recife-pe/frutal-mg'},
    {link: '/passagem-de-onibus/recife-pe/campogrande-ms'},
    {link: '/passagem-de-onibus/recife-pe/teresopolis-rj'},
    {link: '/passagem-de-onibus/recife-pe/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/recife-pe/itaobim-mg'},
    {link: '/passagem-de-onibus/recife-pe/patosdeminas-mg'},
    {link: '/passagem-de-onibus/recife-pe/uberaba-mg'},
    {link: '/passagem-de-onibus/pauloafonso-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/iguatama-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/lavras-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/araxa-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/ibia-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/ibia-mg'},
    {link: '/passagem-de-onibus/iguatama-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/serrinha-ba'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/tucano-ba'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/vilhena-ro/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/vilhena-ro/altogarcas-mt'},
    {link: '/passagem-de-onibus/vilhena-ro/ituiutaba-mg'},
    {link: '/passagem-de-onibus/vilhena-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/vilhena-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/vilhena-ro/mantena-mg'},
    {link: '/passagem-de-onibus/itabira-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/itabira-mg/serrinha-ba'},
    {link: '/passagem-de-onibus/itabira-mg/tucano-ba'},
    {link: '/passagem-de-onibus/itabira-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/jacinto-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/campanario-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/jacinto-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/itueta-mg/periquito-mg'},
    {link: '/passagem-de-onibus/itueta-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/itueta-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/itueta-mg/conselheiropena-mg'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/jequie-ba'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/saojoaoevangelista-mg/inhambupe-ba'},
    {link: '/passagem-de-onibus/jacinto-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/jacinto-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/jacinto-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/almenara-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/jacinto-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/guarapari-es/ipatinga-mg'},
    {link: '/passagem-de-onibus/guarapari-es/ouropreto-mg'},
    {link: '/passagem-de-onibus/timoteo-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/timoteo-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/timoteo-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/timoteo-mg/periquito-mg'},
    {link: '/passagem-de-onibus/timoteo-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/timoteo-mg/vitoria-es'},
    {link: '/passagem-de-onibus/itabira-mg/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/vilhena-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/vilhena-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/santoestevao-ba/voltaredonda-rj'},
    {link: '/passagem-de-onibus/guarapari-es/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/guarapari-es/portoseguro-ba'},
    {link: '/passagem-de-onibus/guarapari-es/inhapim-mg'},
    {link: '/passagem-de-onibus/guarapari-es/manhuacu-mg'},
    {link: '/passagem-de-onibus/guarapari-es/betim-mg'},
    {link: '/passagem-de-onibus/guarapari-es/pontenova-mg'},
    {link: '/passagem-de-onibus/guarapari-es/realeza,mg-mg'},
    {link: '/passagem-de-onibus/guarapari-es/riocasca-mg'},
    {link: '/passagem-de-onibus/guarapari-es/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/guarapari-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/guarapari-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/guarapari-es/caratinga-mg'},
    {link: '/passagem-de-onibus/santoestevao-ba/osasco-sp'},
    {link: '/passagem-de-onibus/virginopolis-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/virginopolis-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/manhuacu-mg/vendanovadoimigrante-es'},
    {link: '/passagem-de-onibus/manhuacu-mg/marataizes-es'},
    {link: '/passagem-de-onibus/manhuacu-mg/guarapari-es'},
    {link: '/passagem-de-onibus/manhuacu-mg/cachoeirodeitapemirim-es'},
    {link: '/passagem-de-onibus/ibia-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/ibia-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/ibia-mg/luz-mg'},
    {link: '/passagem-de-onibus/arapua-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/arapua-mg/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/arapua-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/arapua-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/jau-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jau-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/jau-sp/passos-mg'},
    {link: '/passagem-de-onibus/jau-sp/saosebastiaodoparaiso-mg'},
    {link: '/passagem-de-onibus/acaua-pi/afranio-pe'},
    {link: '/passagem-de-onibus/acaua-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/petrolina-pe'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/arapiraca-al'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/penedo-al'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/parambu-ce'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/juazeiro-ba'},
    {link: '/passagem-de-onibus/virginopolis-mg/jequie-ba'},
    {link: '/passagem-de-onibus/virginopolis-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/virginopolis-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/juazeirinho-pb/curraisnovos-rn'},
    {link: '/passagem-de-onibus/saolourenco-mg/cristalina-go'},
    {link: '/passagem-de-onibus/saolourenco-mg/brasilia-df'},
    {link: '/passagem-de-onibus/natal-rn/caruaru-pe'},
    {link: '/passagem-de-onibus/natal-rn/garanhuns-pe'},
    {link: '/passagem-de-onibus/natal-rn/belohorizonte-mg'},
    {link: '/passagem-de-onibus/natal-rn/catoledorocha-pb'},
    {link: '/passagem-de-onibus/natal-rn/crato-ce'},
    {link: '/passagem-de-onibus/natal-rn/palmeiradosindios-al'},
    {link: '/passagem-de-onibus/natal-rn/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/gurupi-to/barretos-sp'},
    {link: '/passagem-de-onibus/gurupi-to/planura-mg'},
    {link: '/passagem-de-onibus/gurupi-to/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/gurupi-to/itumbiara-go'},
    {link: '/passagem-de-onibus/carloschagas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/carloschagas-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/carloschagas-mg/periquito-mg'},
    {link: '/passagem-de-onibus/carloschagas-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/carloschagas-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/palmares-pe/joaopessoa-pb'},
    {link: '/passagem-de-onibus/inhuma-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/assis-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/assis-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/assis-sp/passos-mg'},
    {link: '/passagem-de-onibus/arapua-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/arapua-mg/luz-mg'},
    {link: '/passagem-de-onibus/arapua-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/arapua-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/arapua-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/arapua-mg/moema-mg'},
    {link: '/passagem-de-onibus/natal-rn/brejodocruz-pb'},
    {link: '/passagem-de-onibus/natal-rn/arapiraca-al'},
    {link: '/passagem-de-onibus/natal-rn/teofilootoni-mg'},
    {link: '/passagem-de-onibus/gurupi-to/frutal-mg'},
    {link: '/passagem-de-onibus/gurupi-to/araraquara-sp'},
    {link: '/passagem-de-onibus/gurupi-to/campinas-sp'},
    {link: '/passagem-de-onibus/gurupi-to/americana-sp'},
    {link: '/passagem-de-onibus/gurupi-to/saocarlos-sp'},
    {link: '/passagem-de-onibus/gurupi-to/prata-mg'},
    {link: '/passagem-de-onibus/natal-rn/jerico-pb'},
    {link: '/passagem-de-onibus/natal-rn/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/natal-rn/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/natal-rn/milagres,ce-ce'},
    {link: '/passagem-de-onibus/natal-rn/sousa-pb'},
    {link: '/passagem-de-onibus/natal-rn/riolargo-al'},
    {link: '/passagem-de-onibus/natal-rn/saopaulo-sp'},
    {link: '/passagem-de-onibus/ibia-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/ibia-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/ibia-mg/lavras-mg'},
    {link: '/passagem-de-onibus/ibia-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/ibia-mg/araxa-mg'},
    {link: '/passagem-de-onibus/ibia-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/ibia-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/ibia-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/ibia-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/ibia-mg/bambui-mg'},
    {link: '/passagem-de-onibus/mantena-mg/pimentabueno-ro'},
    {link: '/passagem-de-onibus/campomourao-pr/belohorizonte-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/araxa-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/jequie-ba'},
    {link: '/passagem-de-onibus/campomourao-pr/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/itaobim-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/mantena-mg/ji-parana-ro'},
    {link: '/passagem-de-onibus/mantena-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/mantena-mg/cuiaba-mt'},
    {link: '/passagem-de-onibus/mantena-mg/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/mantena-mg/centraldeminas-mg'},
    {link: '/passagem-de-onibus/mantena-mg/vilhena-ro'},
    {link: '/passagem-de-onibus/mantena-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/mantena-mg/ariquemes-ro'},
    {link: '/passagem-de-onibus/mantena-mg/ouropretodooeste-ro'},
    {link: '/passagem-de-onibus/mantena-mg/jatai-go'},
    {link: '/passagem-de-onibus/ibia-mg/brasilia-df'},
    {link: '/passagem-de-onibus/ibia-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/ibia-mg/arcos-mg'},
    {link: '/passagem-de-onibus/ibia-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/ibia-mg/formiga-mg'},
    {link: '/passagem-de-onibus/ibia-mg/riachodesantana-ba'},
    {link: '/passagem-de-onibus/ibia-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/ibia-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/lajedo-pe/osasco-sp'},
    {link: '/passagem-de-onibus/lajedo-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/mantena-mg/portovelho-ro'},
    {link: '/passagem-de-onibus/mantena-mg/periquito-mg'},
    {link: '/passagem-de-onibus/mantena-mg/rondonopolis-mt'},
    {link: '/passagem-de-onibus/mantena-mg/caceres-mt'},
    {link: '/passagem-de-onibus/mantena-mg/presidentemedici-ro'},
    {link: '/passagem-de-onibus/mantena-mg/cacoal-ro'},
    {link: '/passagem-de-onibus/mantena-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/mantena-mg/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/mantena-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/mantena-mg/mineiros-go'},
    {link: '/passagem-de-onibus/liciniodealmeida-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/mantena-mg/jaru-ro'},
    {link: '/passagem-de-onibus/mantena-mg/saosimao-go'},
    {link: '/passagem-de-onibus/mantena-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/mantena-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/mantena-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/mantena-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/mantena-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/mantena-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ibia-mg/moema-mg'},
    {link: '/passagem-de-onibus/ibia-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/ibia-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/ibia-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/ibia-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/ibia-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/ibia-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/ibia-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/ibia-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/ibia-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/ibia-mg/bambui-mg'},
    {link: '/passagem-de-onibus/ibia-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/ibia-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/ibia-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/ibia-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/ibia-mg/ipameri-go'},
    {link: '/passagem-de-onibus/ibia-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/ibia-mg/pindai-ba'},
    {link: '/passagem-de-onibus/ibia-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/ibia-mg/arcos-mg'},
    {link: '/passagem-de-onibus/ibia-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/ibia-mg/formiga-mg'},
    {link: '/passagem-de-onibus/ibia-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/ibia-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/ibia-mg/goiania-go'},
    {link: '/passagem-de-onibus/ibia-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/ibia-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/ibia-mg/lavras-mg'},
    {link: '/passagem-de-onibus/ibia-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/ibia-mg/araxa-mg'},
    {link: '/passagem-de-onibus/ibia-mg/catalao-go'},
    {link: '/passagem-de-onibus/ibia-mg/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/ibia-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/ibia-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/maceio-al/pirapora-mg'},
    {link: '/passagem-de-onibus/maceio-al/teofilootoni-mg'},
    {link: '/passagem-de-onibus/maceio-al/patrocinio-mg'},
    {link: '/passagem-de-onibus/penapolis-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/penapolis-sp/luz-mg'},
    {link: '/passagem-de-onibus/penapolis-sp/araxa-mg'},
    {link: '/passagem-de-onibus/penapolis-sp/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/penapolis-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/maceio-al/montesclaros-mg'},
    {link: '/passagem-de-onibus/maceio-al/caratinga-mg'},
    {link: '/passagem-de-onibus/maceio-al/luz-mg'},
    {link: '/passagem-de-onibus/maceio-al/araxa-mg'},
    {link: '/passagem-de-onibus/maceio-al/frutal-mg'},
    {link: '/passagem-de-onibus/maceio-al/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/maceio-al/eunapolis-ba'},
    {link: '/passagem-de-onibus/maceio-al/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/maceio-al/itaobim-mg'},
    {link: '/passagem-de-onibus/maceio-al/patosdeminas-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/campomourao-pr/salvador-ba'},
    {link: '/passagem-de-onibus/campomourao-pr/ipatinga-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/teofilootoni-mg'},
    {link: '/passagem-de-onibus/campomourao-pr/feiradesantana-ba'},
    {link: '/passagem-de-onibus/jeremoabo-ba/osasco-sp'},
    {link: '/passagem-de-onibus/jeremoabo-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/jeremoabo-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/maceio-al/belohorizonte-mg'},
    {link: '/passagem-de-onibus/maceio-al/uberaba-mg'},
    {link: '/passagem-de-onibus/maceio-al/uberlandia-mg'},
    {link: '/passagem-de-onibus/maceio-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/maceio-al/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/maceio-al/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/maceio-al/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/maceio-al/olimpia-sp'},
    {link: '/passagem-de-onibus/maceio-al/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/maceio-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/frutal-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/campogrande-ms'},
    {link: '/passagem-de-onibus/uberaba-mg/conceicaodasalagoas-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/ji-parana-ro'},
    {link: '/passagem-de-onibus/uberaba-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/maringa-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/mirandopolis-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/bambui-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/recife-pe'},
    {link: '/passagem-de-onibus/uberaba-mg/ibia-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/ibia-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/maceio-al'},
    {link: '/passagem-de-onibus/uberaba-mg/penapolis-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/serradosalitre-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/serradosalitre-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/luz-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/saofranciscodesales-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/lavras-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/conquista-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/conquista-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/araxa-mg'},
    {link: '/passagem-de-onibus/maceio-al/salinas-mg'},
    {link: '/passagem-de-onibus/maceio-al/itabuna-ba'},
    {link: '/passagem-de-onibus/maceio-al/leopoldina-mg'},
    {link: '/passagem-de-onibus/maceio-al/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/maceio-al/viana-es'},
    {link: '/passagem-de-onibus/maceio-al/ipatinga-mg'},
    {link: '/passagem-de-onibus/maceio-al/barretos-sp'},
    {link: '/passagem-de-onibus/maceio-al/vitoria-es'},
    {link: '/passagem-de-onibus/maceio-al/curitiba-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/iturama-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/ariquemes-ro'},
    {link: '/passagem-de-onibus/medina-mg/pontodosvolantes-mg'},
    {link: '/passagem-de-onibus/medina-mg/brasilia-df'},
    {link: '/passagem-de-onibus/medina-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/medina-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/naque-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/naque-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/naque-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/naque-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/naque-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/naque-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/lontra-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/medina-mg/goiania-go'},
    {link: '/passagem-de-onibus/medina-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/medina-mg/campanario-mg'},
    {link: '/passagem-de-onibus/medina-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/medina-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/medina-mg/pedraazul-mg'},
    {link: '/passagem-de-onibus/medina-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/medina-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/medina-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/naque-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/naque-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/naque-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/luz-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/araxa-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/ibia-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/cruzeirodafortaleza-mg'},
    {link: '/passagem-de-onibus/lassance-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/lassance-mg/corinto-mg'},
    {link: '/passagem-de-onibus/lassance-mg/pedroleopoldo-mg'},
    {link: '/passagem-de-onibus/lassance-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/lassance-mg/prudentedemorais-mg'},
    {link: '/passagem-de-onibus/lassance-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/lassance-mg/setelagoas-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/portovelho-ro'},
    {link: '/passagem-de-onibus/uberaba-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/guaira,sp-sp'},
    {link: '/passagem-de-onibus/lassance-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/lontra-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/lontra-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/lontra-mg/corinto-mg'},
    {link: '/passagem-de-onibus/lontra-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/lontra-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/lontra-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/lontra-mg/januaria-mg'},
    {link: '/passagem-de-onibus/lontra-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/salvador-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/sacramento-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/itapagipe-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/aracaju-se'},
    {link: '/passagem-de-onibus/uberaba-mg/treslagoas-ms'},
    {link: '/passagem-de-onibus/uberaba-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/arcos-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/formiga-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/mineiros-go'},
    {link: '/passagem-de-onibus/uberaba-mg/pimentabueno-ro'},
    {link: '/passagem-de-onibus/uberaba-mg/barretos-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/planura-mg'},
    {link: '/passagem-de-onibus/uberaba-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/barbalha-ce'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/saopaulo-sp'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/caico-rn'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/corinto-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/bocaiuva-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/betim-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/paraopeba-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/mineiros-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/barro-ce'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/crato-ce'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/missaovelha-ce'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/natal-rn'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/milagres,ce-ce'},
    {link: '/passagem-de-onibus/uberlandia-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/uberlandia-mg/altogarcas-mt'},
    {link: '/passagem-de-onibus/uberlandia-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/luz-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/aracaju-se'},
    {link: '/passagem-de-onibus/uberlandia-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/arcos-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/formiga-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/recife-pe'},
    {link: '/passagem-de-onibus/uberlandia-mg/ibia-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/ibia-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/maceio-al'},
    {link: '/passagem-de-onibus/uberlandia-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/lavras-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/divinopolis-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/araxa-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/colatina-es'},
    {link: '/passagem-de-onibus/uberlandia-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/bambui-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/salinas-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/curvelo-mg'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/setelagoas-mg'},
    {link: '/passagem-de-onibus/ipu-ce/camocim-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/petrolina-pe'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/sobral-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/crateus-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/teofilootoni-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/juazeiro-ba'},
    {link: '/passagem-de-onibus/alcobaca-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/alcobaca-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/alcobaca-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/itamaraju-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/iguatu-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/petrolina-pe'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/portorealdocolegio-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/lagarto-se'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/pedrocanario-es'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/mossoro-rn'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/itabuna-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/cruzdasalmas-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/ubaitaba-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/uniaodospalmares-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/linhares-es'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/camacari-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/valenca-rj'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/saomigueldoscampos-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/crato-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/exu-pe'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/joaopessoa-pb'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/eunapolis-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/eunapolis-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/ilheus-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/portoseguro-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/anapolis-go'},
    {link: '/passagem-de-onibus/ipu-ce/tiangua-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/goiania-go'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/bomconselho-pe'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/garanhuns-pe'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/salgueiro-pe'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/conceicaodabarra-es'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/gandu-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/gandu-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/santanadoipanema-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/crateus-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/arapiraca-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/penedo-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/barretos-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/curraisnovos-rn'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/ico-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/parambu-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/itapetinga-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/saomateus-es'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/saomateus-es'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/juazeiro-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/petrolina-pe'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/sobral-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/crateus-ce'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/teofilootoni-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/juazeiro-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/palmeiradosindios-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/pauloafonso-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/recife-pe'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/natal-rn'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/jeremoabo-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/maceio-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/messias-al'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/quixada-ce'},
    {link: '/passagem-de-onibus/ipu-ce/ubajara-ce'},
    {link: '/passagem-de-onibus/ipu-ce/granja-ce'},
    {link: '/passagem-de-onibus/ipu-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/ipu-ce/vicosadoceara-ce'},
    {link: '/passagem-de-onibus/ipu-ce/ibiapina-ce'},
    {link: '/passagem-de-onibus/monsenhorgil-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/coutodemagalhaesdeminas-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/coutodemagalhaesdeminas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/coutodemagalhaesdeminas-mg/josegoncalvesdeminas-mg'},
    {link: '/passagem-de-onibus/coutodemagalhaesdeminas-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/coutodemagalhaesdeminas-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/coutodemagalhaesdeminas-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/coutodemagalhaesdeminas-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/coutodemagalhaesdeminas-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/lavras-mg'},
    {link: '/passagem-de-onibus/manhumirim-mg/marataizes-es'},
    {link: '/passagem-de-onibus/manhumirim-mg/cachoeirodeitapemirim-es'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/aparecida-sp'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/corinto-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/bocaiuva-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/teresina-pi'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/pilaoarcado-ba'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/betim-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/picos-pi'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/casanova-ba'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/casanova-ba'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/remanso-ba'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/resende-rj'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/salinas-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/novaiguacu-rj'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/curvelo-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/setelagoas-mg'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/juazeiro-ba'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/milagres,ba-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/luz-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/bocaiuva-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/araxa-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/frutal-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/campanario-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/crisolita-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/pavao-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/novoorientedeminas-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/machacalis-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/araxa-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/bambui-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/ibia-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/ibia-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/arcos-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/formiga-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/garanhuns-pe'},
    {link: '/passagem-de-onibus/pedrinopolis-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/minasnovas-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/minasnovas-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/minasnovas-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/minasnovas-mg/inhambupe-ba'},
    {link: '/passagem-de-onibus/minasnovas-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/minasnovas-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/minasnovas-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/mombaca-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/jaru-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/ipameri-go/montecarmelo-mg'},
    {link: '/passagem-de-onibus/ipameri-go/patosdeminas-mg'},
    {link: '/passagem-de-onibus/ipameri-go/ibia-mg'},
    {link: '/passagem-de-onibus/ipameri-go/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/ipameri-go/brasilia-df'},
    {link: '/passagem-de-onibus/ipameri-go/carmodoparanaiba-mg'},
    {link: '/passagem-de-onibus/ipameri-go/patrocinio-mg'},
    {link: '/passagem-de-onibus/ipameri-go/saogotardo-mg'},
    {link: '/passagem-de-onibus/santaquiteria-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/goiania-go'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/saosimao-go'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/conceicaodabarra-es'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/malacacheta-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/campanario-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/campanario-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/garanhuns-pe'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/jequie-ba'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/petrolina-pe'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/ubata-ba'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/pedraazul-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/ji-parana-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/itinga-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/milagres,ce-ce/caico-rn'},
    {link: '/passagem-de-onibus/milagres,ce-ce/brejodocruz-pb'},
    {link: '/passagem-de-onibus/teresina-pi/capimgrosso-ba'},
    {link: '/passagem-de-onibus/teresina-pi/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/teresina-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/teresina-pi/salvador-ba'},
    {link: '/passagem-de-onibus/teresina-pi/feiradesantana-ba'},
    {link: '/passagem-de-onibus/teresina-pi/feiradesantana-ba'},
    {link: '/passagem-de-onibus/minasnovas-mg/jequie-ba'},
    {link: '/passagem-de-onibus/milagres,ba-ba/campinas-sp'},
    {link: '/passagem-de-onibus/milagres,ba-ba/paraopeba-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/milagres,ba-ba/curvelo-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/milagres,ba-ba/patrocinio-mg'},
    {link: '/passagem-de-onibus/ipameri-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/ipameri-go/luz-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/cuiaba-mt'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/centraldeminas-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/recife-pe'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/itueta-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/vilhena-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/mantena-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/maceio-al'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/medina-mg'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/parambu-ce'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/juazeiro-ba'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/milagres,ce-ce/catoledorocha-pb'},
    {link: '/passagem-de-onibus/milagres,ce-ce/jerico-pb'},
    {link: '/passagem-de-onibus/milagres,ce-ce/natal-rn'},
    {link: '/passagem-de-onibus/milagres,ce-ce/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/milagres,ce-ce/sousa-pb'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/novavenecia-es'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/almenara-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/saogabrieldapalha-es'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/mineiros-go'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/pimentabueno-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/saojoaodomanteninha-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/saomateus-es'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/naque-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/ariquemes-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/ouropretodooeste-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/galileia-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/jatai-go'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/pontodosvolantes-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/portovelho-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/periquito-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/pote-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/conselheiropena-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/malacacheta-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/itabira-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/araci-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/pote-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/salvador-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/guanhaes-mg'},
    {link: '/passagem-de-onibus/mutum-mg/martinssoares-mg'},
    {link: '/passagem-de-onibus/mutum-mg/lajinha-mg'},
    {link: '/passagem-de-onibus/mutum-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/mutum-mg/realeza,mg-mg'},
    {link: '/passagem-de-onibus/mutum-mg/riocasca-mg'},
    {link: '/passagem-de-onibus/mucuri-ba/campanario-mg'},
    {link: '/passagem-de-onibus/mucuri-ba/freiinocencio-mg'},
    {link: '/passagem-de-onibus/quixada-ce/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/quixada-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/quixada-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/pocoes-ba/campinas-sp'},
    {link: '/passagem-de-onibus/acucena-mg/jequie-ba'},
    {link: '/passagem-de-onibus/acucena-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/acucena-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/acucena-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/acucena-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/mutum-mg/abrecampo-mg'},
    {link: '/passagem-de-onibus/mutum-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/messias-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/messias-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/pocoes-ba/osasco-sp'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/pote-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/rondonopolis-mt'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/caceres-mt'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/salvador-ba'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/presidentemedici-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/cacoal-ro'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/coronelfabriciano-mg/aracaju-se'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/santamariadeitabira-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/araci-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/salvador-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/euclidesdacunha-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/novarussas-ce/ipu-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/sobral-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/varjota-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/vicosadoceara-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/crateus-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/ibiapina-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/tiangua-ce'},
    {link: '/passagem-de-onibus/nazaredopiaui-pi/fortaleza-ce'},
    {link: '/passagem-de-onibus/nazaredopiaui-pi/taua-ce'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/voltaredonda-rj'},
    {link: '/passagem-de-onibus/franciscosa-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/franciscosa-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/franciscosa-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/franciscosa-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/franciscosa-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/franciscosa-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/iturama-mg/carneirinho-mg'},
    {link: '/passagem-de-onibus/iturama-mg/paranaiba-ms'},
    {link: '/passagem-de-onibus/iturama-mg/saofranciscodesales-mg'},
    {link: '/passagem-de-onibus/iturama-mg/frutal-mg'},
    {link: '/passagem-de-onibus/piuma-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/novaalmeida-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/novaalmeida-es/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/novaalmeida-es/riocasca-mg'},
    {link: '/passagem-de-onibus/novarussas-ce/camocim-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/saobenedito-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/ubajara-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/granja-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/guaraciabadonorte-ce'},
    {link: '/passagem-de-onibus/novarussas-ce/carire-ce'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/campomourao-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/maceio-al'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/minasnovas-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/acucena-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/olimpia-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/betim-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/paraopeba-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/sobral-ce'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/iturama-mg/conceicaodasalagoas-mg'},
    {link: '/passagem-de-onibus/iturama-mg/carneirinho-mg'},
    {link: '/passagem-de-onibus/iturama-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/iturama-mg/paranaiba-ms'},
    {link: '/passagem-de-onibus/iturama-mg/itapagipe-mg'},
    {link: '/passagem-de-onibus/iturama-mg/planura-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/passos-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/aracuai-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/garanhuns-pe'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/corinto-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/luz-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/bocaiuva-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/araxa-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/frutal-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/cascavel-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/maringa-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/osasco-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/recife-pe'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/virginopolis-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/saosebastiaodoparaiso-mg'},
    {link: '/passagem-de-onibus/araraquara-sp/palmas,to-to'},
    {link: '/passagem-de-onibus/araraquara-sp/ceres-go'},
    {link: '/passagem-de-onibus/araraquara-sp/gurupi-to'},
    {link: '/passagem-de-onibus/araraquara-sp/porangatu-go'},
    {link: '/passagem-de-onibus/araraquara-sp/portonacional-to'},
    {link: '/passagem-de-onibus/araraquara-sp/alvorada-to'},
    {link: '/passagem-de-onibus/araraquara-sp/uruacu-go'},
    {link: '/passagem-de-onibus/araraquara-sp/jaragua-go'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/londrina-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/capelinha-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/mossoro-rn'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/salinas-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/aracaju-se'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/arapiraca-al'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/osasco-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/abadiania-go'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/recife-pe'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/campomourao-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/maceio-al'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/londrina-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/brasilia-df'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/mossoro-rn'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/salinas-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/aracaju-se'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/arapiraca-al'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/curvelo-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/curvelo-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/barretos-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/setelagoas-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/patrocinio-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/goiania-go'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/garanhuns-pe'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/olimpia-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/betim-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/paraopeba-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/sobral-ce'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/araxa-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/frutal-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/cascavel-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/maringa-pr'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/anapolis-go'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/barretos-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/setelagoas-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/patrocinio-mg'},
    {link: '/passagem-de-onibus/ariquemes-ro/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/ariquemes-ro/altogarcas-mt'},
    {link: '/passagem-de-onibus/ariquemes-ro/ituiutaba-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/corinto-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/luz-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/bocaiuva-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/correntina-ba'},
    {link: '/passagem-de-onibus/campinas-sp/riachodesantana-ba'},
    {link: '/passagem-de-onibus/campinas-sp/pirapora-mg'},
    {link: '/passagem-de-onibus/campinas-sp/uruacu-go'},
    {link: '/passagem-de-onibus/campinas-sp/teofilootoni-mg'},
    {link: '/passagem-de-onibus/campinas-sp/jaragua-go'},
    {link: '/passagem-de-onibus/campinas-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/campinas-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/canhotinho-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/novoorientedeminas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/novoorientedeminas-mg/campanario-mg'},
    {link: '/passagem-de-onibus/novoorientedeminas-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/porteirinha-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/itamaraju-ba/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/itamaraju-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/itamaraju-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/ouropretodooeste-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ouropretodooeste-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/novoorientedeminas-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/novoorientedeminas-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/novoorientedeminas-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/novooriente-ce/picos-pi'},
    {link: '/passagem-de-onibus/cicerodantas-ba/osasco-sp'},
    {link: '/passagem-de-onibus/cicerodantas-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/pilaoarcado-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/pilaoarcado-ba/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/pilaoarcado-ba/casanova-ba'},
    {link: '/passagem-de-onibus/pilaoarcado-ba/casanova-ba'},
    {link: '/passagem-de-onibus/ariquemes-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/campinas-sp/altogarcas-mt'},
    {link: '/passagem-de-onibus/campinas-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/campinas-sp/caratinga-mg'},
    {link: '/passagem-de-onibus/campinas-sp/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/campinas-sp/espinosa-mg'},
    {link: '/passagem-de-onibus/campinas-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/campinas-sp/palmas,to-to'},
    {link: '/passagem-de-onibus/ariquemes-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ariquemes-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/ariquemes-ro/mantena-mg'},
    {link: '/passagem-de-onibus/ariquemes-ro/uberaba-mg'},
    {link: '/passagem-de-onibus/ariquemes-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/ariquemes-ro/campinas-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ariquemes-ro/jatai-go'},
    {link: '/passagem-de-onibus/ariquemes-ro/saopaulo-sp'},
    {link: '/passagem-de-onibus/porteirinha-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/porteirinha-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/corinto-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/monteazul-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/porteirinha-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/pilaoarcado-ba/remanso-ba'},
    {link: '/passagem-de-onibus/pilaoarcado-ba/salvador-ba'},
    {link: '/passagem-de-onibus/pilaoarcado-ba/juazeiro-ba'},
    {link: '/passagem-de-onibus/pilaoarcado-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/nanuque-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/nanuque-mg/periquito-mg'},
    {link: '/passagem-de-onibus/nanuque-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/nanuque-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/novavicosa-ba/freiinocencio-mg'},
    {link: '/passagem-de-onibus/campinas-sp/jequie-ba'},
    {link: '/passagem-de-onibus/campinas-sp/urandi-ba'},
    {link: '/passagem-de-onibus/campinas-sp/ceres-go'},
    {link: '/passagem-de-onibus/campinas-sp/gurupi-to'},
    {link: '/passagem-de-onibus/campinas-sp/milagres,ba-ba'},
    {link: '/passagem-de-onibus/campinas-sp/pocoes-ba'},
    {link: '/passagem-de-onibus/campinas-sp/ariquemes-ro'},
    {link: '/passagem-de-onibus/campinas-sp/guanambi-ba'},
    {link: '/passagem-de-onibus/campinas-sp/pindai-ba'},
    {link: '/passagem-de-onibus/campinas-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/campinas-sp/salvador-ba'},
    {link: '/passagem-de-onibus/campinas-sp/janauba-mg'},
    {link: '/passagem-de-onibus/campinas-sp/porangatu-go'},
    {link: '/passagem-de-onibus/campinas-sp/portonacional-to'},
    {link: '/passagem-de-onibus/campinas-sp/alvorada-to'},
    {link: '/passagem-de-onibus/campinas-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/ouropretodooeste-ro/mantena-mg'},
    {link: '/passagem-de-onibus/ouropretodooeste-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/ouropretodooeste-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/araxa-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/bambui-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/ibia-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/sobral-ce'},
    {link: '/passagem-de-onibus/petrolina-pe/remanso-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/resende-rj'},
    {link: '/passagem-de-onibus/petrolina-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/petrolina-pe/saolourencodopiaui-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/salinas-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/crateus-ce'},
    {link: '/passagem-de-onibus/petrolina-pe/novaiguacu-rj'},
    {link: '/passagem-de-onibus/petrolina-pe/curvelo-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/setelagoas-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/petrolina-pe/teresina-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/franciscosa-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/taua-ce'},
    {link: '/passagem-de-onibus/petrolina-pe/betim-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/paraopeba-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/picos-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/patosdopiaui-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/paulistana-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/casanova-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/caninde-ce'},
    {link: '/passagem-de-onibus/petrolina-pe/jaicos-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/jacobinadopiaui-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/inhuma-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/acaua-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/petrolina-pe/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/petrolina-pe/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/petrolina-pe/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/petrolina-pe/monsenhorgil-pi'},
    {link: '/passagem-de-onibus/olimpia-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/olimpia-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/olimpia-sp/aracaju-se'},
    {link: '/passagem-de-onibus/olimpia-sp/ipatinga-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/teofilootoni-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/iguatu-ce/belohorizonte-mg'},
    {link: '/passagem-de-onibus/iguatu-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/iguatu-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/petrolina-pe/aparecida-sp'},
    {link: '/passagem-de-onibus/petrolina-pe/saoraimundononato-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/barroduro,pi-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/belohorizonte-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/corinto-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/saojoaodopiaui-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/montesclaros-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/bocaiuva-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/luz-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/araxa-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/jequie-ba'},
    {link: '/passagem-de-onibus/olimpia-sp/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/recife-pe'},
    {link: '/passagem-de-onibus/olimpia-sp/maceio-al'},
    {link: '/passagem-de-onibus/olimpia-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/arcos-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/formiga-mg'},
    {link: '/passagem-de-onibus/olimpia-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/galileia-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/galileia-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/galileia-mg/itueta-mg'},
    {link: '/passagem-de-onibus/galileia-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/galileia-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/luz-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/araxa-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/moema-mg'},
    {link: '/passagem-de-onibus/novaponte-mg/ibia-mg'},
    {link: '/passagem-de-onibus/sousa-pb/caico-rn'},
    {link: '/passagem-de-onibus/jaciara-mt/betim-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/luz-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/divinopolis-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/araxa-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/parambu-ce'},
    {link: '/passagem-de-onibus/petrolina-pe/valencadopiaui-pi'},
    {link: '/passagem-de-onibus/petrolina-pe/elesbaoveloso-pi'},
    {link: '/passagem-de-onibus/ipueiras-ce/camocim-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/saobenedito-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/ubajara-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/granja-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/guaraciabadonorte-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/carire-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/sobral-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/varjota-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/vicosadoceara-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/ibiapina-ce'},
    {link: '/passagem-de-onibus/ipueiras-ce/tiangua-ce'},
    {link: '/passagem-de-onibus/taua-ce/afranio-pe'},
    {link: '/passagem-de-onibus/taua-ce/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/taua-ce/domexpeditolopes-pi'},
    {link: '/passagem-de-onibus/taua-ce/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/galileia-mg/periquito-mg'},
    {link: '/passagem-de-onibus/galileia-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/aracuai-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/berilo-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/chapadadonorte-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/espinosa-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/taua-ce/ipatinga-mg'},
    {link: '/passagem-de-onibus/taua-ce/teofilootoni-mg'},
    {link: '/passagem-de-onibus/sousa-pb/barro-ce'},
    {link: '/passagem-de-onibus/sousa-pb/crato-ce'},
    {link: '/passagem-de-onibus/sousa-pb/missaovelha-ce'},
    {link: '/passagem-de-onibus/sousa-pb/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/sousa-pb/natal-rn'},
    {link: '/passagem-de-onibus/sousa-pb/milagres,ce-ce'},
    {link: '/passagem-de-onibus/sousa-pb/barbalha-ce'},
    {link: '/passagem-de-onibus/sousa-pb/saopaulo-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/bambui-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/ibia-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/arcos-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/formiga-mg'},
    {link: '/passagem-de-onibus/taua-ce/jaicos-pi'},
    {link: '/passagem-de-onibus/taua-ce/nazaredopiaui-pi'},
    {link: '/passagem-de-onibus/taua-ce/petrolina-pe'},
    {link: '/passagem-de-onibus/taua-ce/picos-pi'},
    {link: '/passagem-de-onibus/taua-ce/patosdopiaui-pi'},
    {link: '/passagem-de-onibus/taua-ce/paulistana-pi'},
    {link: '/passagem-de-onibus/taua-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/taua-ce/oeiras-pi'},
    {link: '/passagem-de-onibus/taua-ce/floriano-pi'},
    {link: '/passagem-de-onibus/araci-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/araci-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/araci-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/araci-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/araci-ba/tucano-ba'},
    {link: '/passagem-de-onibus/araci-ba/tucano-ba'},
    {link: '/passagem-de-onibus/araci-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/jatai-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jatai-go/araxa-mg'},
    {link: '/passagem-de-onibus/jatai-go/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/pompeu-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/curvelo-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/riachodesantana-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/pirapora-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/teofilootoni-mg'},
    {link: '/passagem-de-onibus/araci-ba/osasco-sp'},
    {link: '/passagem-de-onibus/betim-mg/vendanovadoimigrante-es'},
    {link: '/passagem-de-onibus/betim-mg/ibatiba-es'},
    {link: '/passagem-de-onibus/betim-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/betim-mg/cuiaba-mt'},
    {link: '/passagem-de-onibus/betim-mg/guarapari-es'},
    {link: '/passagem-de-onibus/betim-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/betim-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/betim-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/betim-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/betim-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/moema-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/coutodemagalhaesdeminas-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/minasnovas-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/ariquemes-ro'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/guanambi-ba'},
    {link: '/passagem-de-onibus/jatai-go/ji-parana-ro'},
    {link: '/passagem-de-onibus/jatai-go/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/jatai-go/mantena-mg'},
    {link: '/passagem-de-onibus/jatai-go/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/jatai-go/ariquemes-ro'},
    {link: '/passagem-de-onibus/jatai-go/betim-mg'},
    {link: '/passagem-de-onibus/jatai-go/portovelho-ro'},
    {link: '/passagem-de-onibus/jatai-go/juizdefora-mg'},
    {link: '/passagem-de-onibus/jatai-go/ipatinga-mg'},
    {link: '/passagem-de-onibus/jatai-go/pimentabueno-ro'},
    {link: '/passagem-de-onibus/betim-mg/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/betim-mg/altogarcas-mt'},
    {link: '/passagem-de-onibus/betim-mg/salgueiro-pe'},
    {link: '/passagem-de-onibus/betim-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/betim-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/betim-mg/jaciara-mt'},
    {link: '/passagem-de-onibus/betim-mg/jatai-go'},
    {link: '/passagem-de-onibus/betim-mg/inhambupe-ba'},
    {link: '/passagem-de-onibus/betim-mg/sobral-ce'},
    {link: '/passagem-de-onibus/betim-mg/rondonopolis-mt'},
    {link: '/passagem-de-onibus/betim-mg/rioverde-go'},
    {link: '/passagem-de-onibus/betim-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/betim-mg/vilavelha-es'},
    {link: '/passagem-de-onibus/betim-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/pindai-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/turmalina-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/diamantina-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/janauba-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/lagoadaprata-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/martinhocampos-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/ubata-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/jequie-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/jequie-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/gouveia-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/urandi-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/guanambi-ba/franca-sp'},
    {link: '/passagem-de-onibus/guanambi-ba/limeira-sp'},
    {link: '/passagem-de-onibus/guanambi-ba/araxa-mg'},
    {link: '/passagem-de-onibus/guanambi-ba/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/guanambi-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/guanambi-ba/moema-mg'},
    {link: '/passagem-de-onibus/pedrabranca-ce/teofilootoni-mg'},
    {link: '/passagem-de-onibus/portorealdocolegio-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/portorealdocolegio-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/pontochique-mg/saofrancisco,mg-mg'},
    {link: '/passagem-de-onibus/pontochique-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/pontochique-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/lagarto-se/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/lagarto-se/saopaulo-sp'},
    {link: '/passagem-de-onibus/lagarto-se/santos-sp'},
    {link: '/passagem-de-onibus/guanambi-ba/ibia-mg'},
    {link: '/passagem-de-onibus/guanambi-ba/campinas-sp'},
    {link: '/passagem-de-onibus/guanambi-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/guanambi-ba/santoantoniodomonte-mg'},
    {link: '/passagem-de-onibus/guanambi-ba/americana-sp'},
    {link: '/passagem-de-onibus/guanambi-ba/janauba-mg'},
    {link: '/passagem-de-onibus/guanambi-ba/lagoadaprata-mg'},
    {link: '/passagem-de-onibus/guanambi-ba/martinhocampos-mg'},
    {link: '/passagem-de-onibus/guanambi-ba/pompeu-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/paraopeba-mg/coutodemagalhaesdeminas-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/paraopeba-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/paraopeba-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/paraopeba-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/paraopeba-mg/pedrasdemariadacruz-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/diamantina-mg'},
    {link: '/passagem-de-onibus/betim-mg/mineiros-go'},
    {link: '/passagem-de-onibus/betim-mg/vitoria-es'},
    {link: '/passagem-de-onibus/betim-mg/ico-ce'},
    {link: '/passagem-de-onibus/betim-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/betim-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/betim-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/santoantoniodomonte-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/santoantoniodomonte-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/itororo-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/itororo-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/guanambi-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/arcoverde-pe/teixeira-pb'},
    {link: '/passagem-de-onibus/arcoverde-pe/patos-pb'},
    {link: '/passagem-de-onibus/paraopeba-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/paraopeba-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/corinto-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/corinto-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/corinto-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/paraopeba-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/monteazul-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/josegoncalvesdeminas-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/lontra-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/janauba-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/paraopeba-mg/januaria-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/paraopeba-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/paraopeba-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/paranaiba-ms/carneirinho-mg'},
    {link: '/passagem-de-onibus/paranaiba-ms/iturama-mg'},
    {link: '/passagem-de-onibus/paranaiba-ms/iturama-mg'},
    {link: '/passagem-de-onibus/venturosa-pe/teixeira-pb'},
    {link: '/passagem-de-onibus/venturosa-pe/patos-pb'},
    {link: '/passagem-de-onibus/pedrabranca-ce/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/pedrabranca-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/pombal-pb/saopaulo-sp'},
    {link: '/passagem-de-onibus/pedrasdemariadacruz-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/pedrasdemariadacruz-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pedrasdemariadacruz-mg/corinto-mg'},
    {link: '/passagem-de-onibus/pedrasdemariadacruz-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/pedrasdemariadacruz-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/pedrasdemariadacruz-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/pedrasdemariadacruz-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/pedrasdemariadacruz-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/pederneiras-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/pederneiras-sp/passos-mg'},
    {link: '/passagem-de-onibus/pederneiras-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/pederneiras-sp/saosebastiaodoparaiso-mg'},
    {link: '/passagem-de-onibus/palmasdemontealto-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/americana-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/americana-sp/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/americana-sp/espinosa-mg'},
    {link: '/passagem-de-onibus/americana-sp/palmas,to-to'},
    {link: '/passagem-de-onibus/americana-sp/urandi-ba'},
    {link: '/passagem-de-onibus/inhambupe-ba/acucena-mg'},
    {link: '/passagem-de-onibus/inhambupe-ba/betim-mg'},
    {link: '/passagem-de-onibus/inhambupe-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/inhambupe-ba/capelinha-mg'},
    {link: '/passagem-de-onibus/inhambupe-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/inhambupe-ba/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/passos-mg'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/araxa-mg'},
    {link: '/passagem-de-onibus/pontodosvolantes-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pontodosvolantes-mg/campanario-mg'},
    {link: '/passagem-de-onibus/pontodosvolantes-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/pontodosvolantes-mg/pedraazul-mg'},
    {link: '/passagem-de-onibus/pontodosvolantes-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/pontodosvolantes-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/pontodosvolantes-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/pontodosvolantes-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/pederneiras-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/picos-pi/crateus-ce'},
    {link: '/passagem-de-onibus/picos-pi/boaviagem-ce'},
    {link: '/passagem-de-onibus/picos-pi/parambu-ce'},
    {link: '/passagem-de-onibus/picos-pi/feiradesantana-ba'},
    {link: '/passagem-de-onibus/picos-pi/feiradesantana-ba'},
    {link: '/passagem-de-onibus/inhambupe-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/inhambupe-ba/aracuai-mg'},
    {link: '/passagem-de-onibus/inhambupe-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/inhambupe-ba/virginopolis-mg'},
    {link: '/passagem-de-onibus/inhambupe-ba/minasnovas-mg'},
    {link: '/passagem-de-onibus/americana-sp/ceres-go'},
    {link: '/passagem-de-onibus/americana-sp/gurupi-to'},
    {link: '/passagem-de-onibus/americana-sp/guanambi-ba'},
    {link: '/passagem-de-onibus/americana-sp/pindai-ba'},
    {link: '/passagem-de-onibus/americana-sp/janauba-mg'},
    {link: '/passagem-de-onibus/americana-sp/porangatu-go'},
    {link: '/passagem-de-onibus/americana-sp/portonacional-to'},
    {link: '/passagem-de-onibus/americana-sp/alvorada-to'},
    {link: '/passagem-de-onibus/americana-sp/riachodesantana-ba'},
    {link: '/passagem-de-onibus/americana-sp/pirapora-mg'},
    {link: '/passagem-de-onibus/americana-sp/uruacu-go'},
    {link: '/passagem-de-onibus/americana-sp/jaragua-go'},
    {link: '/passagem-de-onibus/potiragua-ba/goiania-go'},
    {link: '/passagem-de-onibus/potiragua-ba/correntina-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/anapolis-go'},
    {link: '/passagem-de-onibus/potiragua-ba/abadiania-go'},
    {link: '/passagem-de-onibus/potiragua-ba/brasilia-df'},
    {link: '/passagem-de-onibus/picos-pi/afranio-pe'},
    {link: '/passagem-de-onibus/picos-pi/capimgrosso-ba'},
    {link: '/passagem-de-onibus/picos-pi/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/picos-pi/caninde-ce'},
    {link: '/passagem-de-onibus/picos-pi/fortaleza-ce'},
    {link: '/passagem-de-onibus/picos-pi/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/picos-pi/novooriente-ce'},
    {link: '/passagem-de-onibus/picos-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/picos-pi/taua-ce'},
    {link: '/passagem-de-onibus/picos-pi/pedrabranca-ce'},
    {link: '/passagem-de-onibus/picos-pi/sobral-ce'},
    {link: '/passagem-de-onibus/picos-pi/salvador-ba'},
    {link: '/passagem-de-onibus/pindai-ba/franca-sp'},
    {link: '/passagem-de-onibus/pindai-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/pindai-ba/limeira-sp'},
    {link: '/passagem-de-onibus/pindai-ba/araxa-mg'},
    {link: '/passagem-de-onibus/pindai-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/pindai-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/pindai-ba/ibia-mg'},
    {link: '/passagem-de-onibus/pindai-ba/campinas-sp'},
    {link: '/passagem-de-onibus/pindai-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/pindai-ba/americana-sp'},
    {link: '/passagem-de-onibus/patosdopiaui-pi/fortaleza-ce'},
    {link: '/passagem-de-onibus/patosdopiaui-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/patosdopiaui-pi/taua-ce'},
    {link: '/passagem-de-onibus/patosdopiaui-pi/pedrabranca-ce'},
    {link: '/passagem-de-onibus/patosdopiaui-pi/boaviagem-ce'},
    {link: '/passagem-de-onibus/saocarlos-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saocarlos-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/saocarlos-sp/passos-mg'},
    {link: '/passagem-de-onibus/saocarlos-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/saocarlos-sp/saosebastiaodoparaiso-mg'},
    {link: '/passagem-de-onibus/pote-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/pote-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/pote-mg/periquito-mg'},
    {link: '/passagem-de-onibus/pote-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/paudosferros-rn/uirauna-pb'},
    {link: '/passagem-de-onibus/paudosferros-rn/saopaulo-sp'},
    {link: '/passagem-de-onibus/paulistana-pi/afranio-pe'},
    {link: '/passagem-de-onibus/paulistana-pi/caninde-ce'},
    {link: '/passagem-de-onibus/paulistana-pi/fortaleza-ce'},
    {link: '/passagem-de-onibus/paulistana-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/portovelho-ro/uberaba-mg'},
    {link: '/passagem-de-onibus/portovelho-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/portovelho-ro/campinas-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/jatai-go'},
    {link: '/passagem-de-onibus/portovelho-ro/saopaulo-sp'},
    {link: '/passagem-de-onibus/portovelho-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/patosdopiaui-pi/afranio-pe'},
    {link: '/passagem-de-onibus/patosdopiaui-pi/caninde-ce'},
    {link: '/passagem-de-onibus/pindai-ba/janauba-mg'},
    {link: '/passagem-de-onibus/pindai-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/pindai-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/portovelho-ro/altogarcas-mt'},
    {link: '/passagem-de-onibus/portovelho-ro/ituiutaba-mg'},
    {link: '/passagem-de-onibus/portovelho-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/portovelho-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/portovelho-ro/mantena-mg'},
    {link: '/passagem-de-onibus/saocarlos-sp/palmas,to-to'},
    {link: '/passagem-de-onibus/saocarlos-sp/ceres-go'},
    {link: '/passagem-de-onibus/saocarlos-sp/gurupi-to'},
    {link: '/passagem-de-onibus/saocarlos-sp/porangatu-go'},
    {link: '/passagem-de-onibus/saocarlos-sp/portonacional-to'},
    {link: '/passagem-de-onibus/saocarlos-sp/alvorada-to'},
    {link: '/passagem-de-onibus/saocarlos-sp/uruacu-go'},
    {link: '/passagem-de-onibus/saocarlos-sp/jaragua-go'},
    {link: '/passagem-de-onibus/prado-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/prado-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/pote-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/pote-mg/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/pote-mg/periquito-mg'},
    {link: '/passagem-de-onibus/pote-mg/serrinha-ba'},
    {link: '/passagem-de-onibus/pote-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/pote-mg/tucano-ba'},
    {link: '/passagem-de-onibus/pote-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pote-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/pote-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/pontenova-mg/marataizes-es'},
    {link: '/passagem-de-onibus/pontenova-mg/guarapari-es'},
    {link: '/passagem-de-onibus/pontenova-mg/vilavelha-es'},
    {link: '/passagem-de-onibus/pontenova-mg/vitoria-es'},
    {link: '/passagem-de-onibus/pote-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pote-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/pote-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/pote-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/prado-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/pirajui-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pirajui-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/pirajui-sp/passos-mg'},
    {link: '/passagem-de-onibus/pirajui-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/pirajui-sp/saosebastiaodoparaiso-mg'},
    {link: '/passagem-de-onibus/pontenova-mg/vendanovadoimigrante-es'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/itaobim-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/recife-pe'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/maceio-al'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/salvador-ba'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/paulistana-pi/taua-ce'},
    {link: '/passagem-de-onibus/paulistana-pi/pedrabranca-ce'},
    {link: '/passagem-de-onibus/paulistana-pi/crateus-ce'},
    {link: '/passagem-de-onibus/paulistana-pi/boaviagem-ce'},
    {link: '/passagem-de-onibus/prudentedemorais-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/prudentedemorais-mg/corinto-mg'},
    {link: '/passagem-de-onibus/prudentedemorais-mg/corinto-mg'},
    {link: '/passagem-de-onibus/prudentedemorais-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/prudentedemorais-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/casanova-ba/saoraimundononato-pi'},
    {link: '/passagem-de-onibus/casanova-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/casanova-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/casanova-ba/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/casanova-ba/pilaoarcado-ba'},
    {link: '/passagem-de-onibus/casanova-ba/remanso-ba'},
    {link: '/passagem-de-onibus/casanova-ba/salvador-ba'},
    {link: '/passagem-de-onibus/casanova-ba/juazeiro-ba'},
    {link: '/passagem-de-onibus/casanova-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/turmalina-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/sobral-ce/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/sobral-ce/betim-mg'},
    {link: '/passagem-de-onibus/sobral-ce/picos-pi'},
    {link: '/passagem-de-onibus/sobral-ce/varjota-ce'},
    {link: '/passagem-de-onibus/sobral-ce/feiradesantana-ba'},
    {link: '/passagem-de-onibus/camposaltos-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/araxa-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/lavras-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/araxa-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/bambui-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/ibia-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/ibia-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/casanova-ba/saojoaodopiaui-pi'},
    {link: '/passagem-de-onibus/casanova-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/casanova-ba/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/casanova-ba/pilaoarcado-ba'},
    {link: '/passagem-de-onibus/casanova-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/casanova-ba/remanso-ba'},
    {link: '/passagem-de-onibus/casanova-ba/salvador-ba'},
    {link: '/passagem-de-onibus/casanova-ba/saolourencodopiaui-pi'},
    {link: '/passagem-de-onibus/casanova-ba/juazeiro-ba'},
    {link: '/passagem-de-onibus/sobral-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/sobral-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/sobral-ce/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/sobral-ce/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/sobral-ce/petrolina-pe'},
    {link: '/passagem-de-onibus/camposaltos-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/arcos-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/camposaltos-mg/formiga-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/belohorizonte-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/araxa-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/eunapolis-ba'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/jequie-ba'},
    {link: '/passagem-de-onibus/rondonopolis-mt/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/rondonopolis-mt/betim-mg'},
    {link: '/passagem-de-onibus/rondonopolis-mt/juizdefora-mg'},
    {link: '/passagem-de-onibus/rondonopolis-mt/ipatinga-mg'},
    {link: '/passagem-de-onibus/rondonopolis-mt/itumbiara-go'},
    {link: '/passagem-de-onibus/pedrocanario-es/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/pedrocanario-es/saopaulo-sp'},
    {link: '/passagem-de-onibus/marilia-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/marilia-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/estancia-se/saopaulo-sp'},
    {link: '/passagem-de-onibus/estancia-se/santos-sp'},
    {link: '/passagem-de-onibus/apora-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/quipapa-pe/saopaulo-sp'},
    {link: '/passagem-de-onibus/remanso-ba/saoraimundononato-pi'},
    {link: '/passagem-de-onibus/remanso-ba/saojoaodopiaui-pi'},
    {link: '/passagem-de-onibus/remanso-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/remanso-ba/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/aracaju-se'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/itabuna-ba'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/linhares-es'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/ipatinga-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/vitoria-es'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/teofilootoni-mg'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/saomateus-es'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/feiradesantana-ba'},
    {link: '/passagem-de-onibus/fozdoiguacu-pr/feiradesantana-ba'},
    {link: '/passagem-de-onibus/guaira,sp-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/novaera-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/teofilootoni-mg'},
    {link: '/passagem-de-onibus/rondonopolis-mt/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/rondonopolis-mt/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/rondonopolis-mt/mantena-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/itaobim-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/abadiania-go'},
    {link: '/passagem-de-onibus/itagimirim-ba/timoteo-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/jacinto-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/naque-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/freiinocencio-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/almenara-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/josegoncalvesdeminas-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/diamantina-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/diamantina-mg/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/josegoncalvesdeminas-mg'},
    {link: '/passagem-de-onibus/diamantina-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/remanso-ba/juazeiro-ba'},
    {link: '/passagem-de-onibus/remanso-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/correntina-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/campanario-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/padreparaiso-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/itambacuri-mg'},
    {link: '/passagem-de-onibus/remanso-ba/casanova-ba'},
    {link: '/passagem-de-onibus/remanso-ba/salvador-ba'},
    {link: '/passagem-de-onibus/remanso-ba/saolourencodopiaui-pi'},
    {link: '/passagem-de-onibus/remanso-ba/pilaoarcado-ba'},
    {link: '/passagem-de-onibus/remanso-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/remanso-ba/casanova-ba'},
    {link: '/passagem-de-onibus/riodoce,mg-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodoce,mg-mg/caete-mg'},
    {link: '/passagem-de-onibus/riodoce,mg-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/riodoce,mg-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/riodoce,mg-mg/bomjesusdoamparo-mg'},
    {link: '/passagem-de-onibus/resende-rj/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/resende-rj/petrolina-pe'},
    {link: '/passagem-de-onibus/resende-rj/parambu-ce'},
    {link: '/passagem-de-onibus/resende-rj/juazeiro-ba'},
    {link: '/passagem-de-onibus/realeza,mg-mg/abrecampo-mg'},
    {link: '/passagem-de-onibus/realeza,mg-mg/garanhuns-pe'},
    {link: '/passagem-de-onibus/realeza,mg-mg/vendanovadoimigrante-es'},
    {link: '/passagem-de-onibus/realeza,mg-mg/lajinha-mg'},
    {link: '/passagem-de-onibus/realeza,mg-mg/marataizes-es'},
    {link: '/passagem-de-onibus/realeza,mg-mg/guarapari-es'},
    {link: '/passagem-de-onibus/realeza,mg-mg/mutum-mg'},
    {link: '/passagem-de-onibus/realeza,mg-mg/riocasca-mg'},
    {link: '/passagem-de-onibus/realeza,mg-mg/cachoeirodeitapemirim-es'},
    {link: '/passagem-de-onibus/realeza,mg-mg/vitoria-es'},
    {link: '/passagem-de-onibus/belavistademinas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/marilia-sp/passos-mg'},
    {link: '/passagem-de-onibus/caceres-mt/ituiutaba-mg'},
    {link: '/passagem-de-onibus/caceres-mt/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/caceres-mt/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/caceres-mt/mantena-mg'},
    {link: '/passagem-de-onibus/caceres-mt/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/salvador-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/salvador-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/salvador-ba/cascavel-pr'},
    {link: '/passagem-de-onibus/salvador-ba/maringa-pr'},
    {link: '/passagem-de-onibus/salvador-ba/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/salvador-ba/palmeiradosindios-al'},
    {link: '/passagem-de-onibus/salvador-ba/campomourao-pr'},
    {link: '/passagem-de-onibus/salvador-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/salvador-ba/teresina-pi'},
    {link: '/passagem-de-onibus/riocasca-mg/vendanovadoimigrante-es'},
    {link: '/passagem-de-onibus/riocasca-mg/durande-mg'},
    {link: '/passagem-de-onibus/riocasca-mg/martinssoares-mg'},
    {link: '/passagem-de-onibus/riocasca-mg/lajinha-mg'},
    {link: '/passagem-de-onibus/riocasca-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/riocasca-mg/guarapari-es'},
    {link: '/passagem-de-onibus/riocasca-mg/mutum-mg'},
    {link: '/passagem-de-onibus/riocasca-mg/novaalmeida-es'},
    {link: '/passagem-de-onibus/riocasca-mg/realeza,mg-mg'},
    {link: '/passagem-de-onibus/riocasca-mg/vitoria-es'},
    {link: '/passagem-de-onibus/caceres-mt/ipatinga-mg'},
    {link: '/passagem-de-onibus/rioverde-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/rioverde-go/araxa-mg'},
    {link: '/passagem-de-onibus/rioverde-go/betim-mg'},
    {link: '/passagem-de-onibus/rioverde-go/juizdefora-mg'},
    {link: '/passagem-de-onibus/salvador-ba/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/salvador-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/salvador-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/salvador-ba/campinas-sp'},
    {link: '/passagem-de-onibus/salvador-ba/pilaoarcado-ba'},
    {link: '/passagem-de-onibus/salvador-ba/picos-pi'},
    {link: '/passagem-de-onibus/salvador-ba/casanova-ba'},
    {link: '/passagem-de-onibus/salvador-ba/casanova-ba'},
    {link: '/passagem-de-onibus/salvador-ba/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/salvador-ba/remanso-ba'},
    {link: '/passagem-de-onibus/salvador-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/salvador-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/londrina-pr'},
    {link: '/passagem-de-onibus/salvador-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/salvador-ba/campinagrande-pb'},
    {link: '/passagem-de-onibus/salvador-ba/almenara-mg'},
    {link: '/passagem-de-onibus/salvador-ba/ipatinga-mg'},
    {link: '/passagem-de-onibus/salvador-ba/tucano-ba'},
    {link: '/passagem-de-onibus/salvador-ba/tucano-ba'},
    {link: '/passagem-de-onibus/salvador-ba/tucano-ba'},
    {link: '/passagem-de-onibus/salvador-ba/tucano-ba'},
    {link: '/passagem-de-onibus/salvador-ba/taubate-sp'},
    {link: '/passagem-de-onibus/novasoure-ba/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/uirauna-pb/paudosferros-rn'},
    {link: '/passagem-de-onibus/uirauna-pb/saopaulo-sp'},
    {link: '/passagem-de-onibus/uirauna-pb/mossoro-rn'},
    {link: '/passagem-de-onibus/presidentemedici-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/presidentemedici-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/presidentemedici-ro/mantena-mg'},
    {link: '/passagem-de-onibus/presidentemedici-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/presidentemedici-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/luz-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/araxa-mg'},
    {link: '/passagem-de-onibus/ribeiradoamparo-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/ribeiradoamparo-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/ribeiradoamparo-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/parapua-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/janauba-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/janauba-mg/franca-sp'},
    {link: '/passagem-de-onibus/janauba-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/janauba-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/janauba-mg/corinto-mg'},
    {link: '/passagem-de-onibus/janauba-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/moema-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/ibia-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/janauba-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/janauba-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/janauba-mg/americana-sp'},
    {link: '/passagem-de-onibus/janauba-mg/pindai-ba'},
    {link: '/passagem-de-onibus/janauba-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/janauba-mg/santana,ba-ba'},
    {link: '/passagem-de-onibus/janauba-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/janauba-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/janauba-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/janauba-mg/riachodesantana-ba'},
    {link: '/passagem-de-onibus/riolargo-al/joaopessoa-pb'},
    {link: '/passagem-de-onibus/riolargo-al/natal-rn'},
    {link: '/passagem-de-onibus/guanhaes-mg/osasco-sp'},
    {link: '/passagem-de-onibus/guanhaes-mg/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/guanhaes-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/guanhaes-mg/serrinha-ba'},
    {link: '/passagem-de-onibus/guanhaes-mg/tucano-ba'},
    {link: '/passagem-de-onibus/guanhaes-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ribeiradoamparo-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/janauba-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/janauba-mg/limeira-sp'},
    {link: '/passagem-de-onibus/janauba-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/janauba-mg/monteazul-mg'},
    {link: '/passagem-de-onibus/janauba-mg/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/janauba-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/janauba-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/janauba-mg/campinas-sp'},
    {link: '/passagem-de-onibus/janauba-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/moema-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/ibia-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/luz-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/araxa-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/montealegredeminas-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/itabira-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/natal-rn'},
    {link: '/passagem-de-onibus/saopaulo-sp/virginopolis-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/lajedo-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/jeremoabo-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/maceio-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/saopaulo-sp/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/alcobaca-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/santaquiteria-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/brasiliademinas-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/palmeiradosindios-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/itanhem-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/moema-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/pauloafonso-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/jacaraci-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/recife-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/pimentabueno-ro'},
    {link: '/passagem-de-onibus/saopaulo-sp/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/aracuai-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/belemdesaofrancisco-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/garanhuns-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/salgueiro-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/ibicarai-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/corinto-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/mirabela-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/minasnovas-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/mombaca-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/messias-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/acucena-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/quixada-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/franciscosa-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/exu-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/pedroleopoldo-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/taiobeiras-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/pedraazul-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/floresta-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/caninde-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/palmas,to-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/eunapolis-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/eunapolis-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/jequie-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/urandi-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/ilheus-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/portoseguro-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/ceres-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/altogarcas-mt'},
    {link: '/passagem-de-onibus/saopaulo-sp/ji-parana-ro'},
    {link: '/passagem-de-onibus/saopaulo-sp/ariquemes-ro'},
    {link: '/passagem-de-onibus/saopaulo-sp/portovelho-ro'},
    {link: '/passagem-de-onibus/saopaulo-sp/itabaiana-se'},
    {link: '/passagem-de-onibus/saopaulo-sp/gandu-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/gandu-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/santanadoipanema-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/saomigueldoscampos-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/bocaiuva-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/capimgrosso-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/contagem-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/madalena-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/cordeiros-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/carai-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/crato-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/esplanada-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/montesanto-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/montalvania-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/salinas-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/aracaju-se'},
    {link: '/passagem-de-onibus/saopaulo-sp/itabuna-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/saopaulo-sp/martinhocampos-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/queimadas-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/cruzdasalmas-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/buenopolis-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/tucano-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/setelagoas-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/riachodesantana-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/boaviagem-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/curraisnovos-rn'},
    {link: '/passagem-de-onibus/saopaulo-sp/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/ico-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/parambu-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/valenca-rj'},
    {link: '/passagem-de-onibus/saopaulo-sp/alagoinhas-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/serrinha-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/pompeu-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/portonacional-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/crateus-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/arapiraca-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/alvorada-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/penedo-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/lagoadaprata-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/caico-rn'},
    {link: '/passagem-de-onibus/saopaulo-sp/saofrancisco,mg-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/santamariadeitabira-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/saojoaodoparaiso-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/matozinhos-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/acopiara-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/capelinha-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/mossoro-rn'},
    {link: '/passagem-de-onibus/saopaulo-sp/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/jacobina-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/ubaitaba-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/ubata-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/uniaodospalmares-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/almenara-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/patos-pb'},
    {link: '/passagem-de-onibus/saopaulo-sp/linhares-es'},
    {link: '/passagem-de-onibus/saopaulo-sp/januaria-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/camacari-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/canhotinho-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/cicerodantas-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/porteirinha-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/itamaraju-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/iguatu-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/petrolina-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/taua-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/sousa-pb'},
    {link: '/passagem-de-onibus/saopaulo-sp/araci-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/betim-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/portorealdocolegio-al'},
    {link: '/passagem-de-onibus/saopaulo-sp/lagarto-se'},
    {link: '/passagem-de-onibus/saopaulo-sp/inhambupe-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/pombal-pb'},
    {link: '/passagem-de-onibus/saopaulo-sp/pindai-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/prado-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/paudosferros-rn'},
    {link: '/passagem-de-onibus/saopaulo-sp/prudentedemorais-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/diamantina-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/estancia-se'},
    {link: '/passagem-de-onibus/saopaulo-sp/apora-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/quipapa-pe'},
    {link: '/passagem-de-onibus/saopaulo-sp/pedrocanario-es'},
    {link: '/passagem-de-onibus/saopaulo-sp/salvador-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/uirauna-pb'},
    {link: '/passagem-de-onibus/saopaulo-sp/guanhaes-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/janauba-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/santoantoniodomonte-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/itororo-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/paraopeba-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/pedrabranca-ce'},
    {link: '/passagem-de-onibus/saopaulo-sp/pedrabranca-ce'},
    {link: '/passagem-de-onibus/saofrancisco,mg-mg/ibiai-mg'},
    {link: '/passagem-de-onibus/saofrancisco,mg-mg/ubai-mg'},
    {link: '/passagem-de-onibus/saofrancisco,mg-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/saofrancisco,mg-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/saogoncalodoabaete-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/brejodocruz-pb/barro-ce'},
    {link: '/passagem-de-onibus/brejodocruz-pb/crato-ce'},
    {link: '/passagem-de-onibus/brejodocruz-pb/natal-rn'},
    {link: '/passagem-de-onibus/brejodocruz-pb/milagres,ce-ce'},
    {link: '/passagem-de-onibus/londrina-pr/araxa-mg'},
    {link: '/passagem-de-onibus/prata-mg/uruacu-go'},
    {link: '/passagem-de-onibus/prata-mg/jaragua-go'},
    {link: '/passagem-de-onibus/saolourencodopiaui-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/saolourencodopiaui-pi/casanova-ba'},
    {link: '/passagem-de-onibus/saolourencodopiaui-pi/remanso-ba'},
    {link: '/passagem-de-onibus/saolourencodopiaui-pi/juazeiro-ba'},
    {link: '/passagem-de-onibus/caico-rn/barro-ce'},
    {link: '/passagem-de-onibus/caico-rn/catoledorocha-pb'},
    {link: '/passagem-de-onibus/caico-rn/crato-ce'},
    {link: '/passagem-de-onibus/caico-rn/missaovelha-ce'},
    {link: '/passagem-de-onibus/caico-rn/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/caico-rn/jerico-pb'},
    {link: '/passagem-de-onibus/caico-rn/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/caico-rn/milagres,ce-ce'},
    {link: '/passagem-de-onibus/caico-rn/barbalha-ce'},
    {link: '/passagem-de-onibus/caico-rn/sousa-pb'},
    {link: '/passagem-de-onibus/caico-rn/saopaulo-sp'},
    {link: '/passagem-de-onibus/caico-rn/brejodocruz-pb'},
    {link: '/passagem-de-onibus/saofrancisco,mg-mg/pontochique-mg'},
    {link: '/passagem-de-onibus/saofrancisco,mg-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/itapetinga-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/cansancao-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/pirapora-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/uruacu-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/saomateus-es'},
    {link: '/passagem-de-onibus/saopaulo-sp/saomateus-es'},
    {link: '/passagem-de-onibus/saopaulo-sp/itaguara-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/juazeiro-ba'},
    {link: '/passagem-de-onibus/lagoadaprata-mg/urandi-ba'},
    {link: '/passagem-de-onibus/lagoadaprata-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/lagoadaprata-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/lagoadaprata-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/prata-mg/palmas,to-to'},
    {link: '/passagem-de-onibus/prata-mg/ceres-go'},
    {link: '/passagem-de-onibus/prata-mg/gurupi-to'},
    {link: '/passagem-de-onibus/prata-mg/porangatu-go'},
    {link: '/passagem-de-onibus/prata-mg/portonacional-to'},
    {link: '/passagem-de-onibus/prata-mg/alvorada-to'},
    {link: '/passagem-de-onibus/saopaulo-sp/cipo-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/jaragua-go'},
    {link: '/passagem-de-onibus/saopaulo-sp/medeirosneto-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/saopaulo-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/araci-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/salvador-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/tucano-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/tucano-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/tucano-ba'},
    {link: '/passagem-de-onibus/santabarbara,ba-ba/tucano-ba'},
    {link: '/passagem-de-onibus/cacoal-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/cacoal-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/cacoal-ro/mantena-mg'},
    {link: '/passagem-de-onibus/cacoal-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/cacoal-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/londrina-pr/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/londrina-pr/jequie-ba'},
    {link: '/passagem-de-onibus/londrina-pr/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/londrina-pr/itaobim-mg'},
    {link: '/passagem-de-onibus/londrina-pr/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/londrina-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/londrina-pr/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/capelinha-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/cachoeirodeitapemirim-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/cachoeirodeitapemirim-es/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/cachoeirodeitapemirim-es/manhuacu-mg'},
    {link: '/passagem-de-onibus/cachoeirodeitapemirim-es/manhumirim-mg'},
    {link: '/passagem-de-onibus/cachoeirodeitapemirim-es/realeza,mg-mg'},
    {link: '/passagem-de-onibus/mossoro-rn/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/mossoro-rn/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/mossoro-rn/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/mossoro-rn/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/acopiara-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/brasilia-df/trescoracoes-mg'},
    {link: '/passagem-de-onibus/brasilia-df/aparecida-sp'},
    {link: '/passagem-de-onibus/brasilia-df/campobelo-mg'},
    {link: '/passagem-de-onibus/brasilia-df/pousoalegre-mg'},
    {link: '/passagem-de-onibus/brasilia-df/brumado-ba'},
    {link: '/passagem-de-onibus/brasilia-df/luz-mg'},
    {link: '/passagem-de-onibus/brasilia-df/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/brasilia-df/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/araxa-mg'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/capelinha-mg/jequie-ba'},
    {link: '/passagem-de-onibus/capelinha-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/capelinha-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/capelinha-mg/inhambupe-ba'},
    {link: '/passagem-de-onibus/capelinha-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/capelinha-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/moema-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/arapua-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/ipameri-go'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/santamariadeitabira-mg/tucano-ba'},
    {link: '/passagem-de-onibus/santamariadeitabira-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/guacui-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saojoaodoparaiso-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/goiania-go'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/luz-mg'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/lagoaformosa-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/brasilia-df/cambuquira-mg'},
    {link: '/passagem-de-onibus/brasilia-df/arcos-mg'},
    {link: '/passagem-de-onibus/brasilia-df/piquete-sp'},
    {link: '/passagem-de-onibus/brasilia-df/ipatinga-mg'},
    {link: '/passagem-de-onibus/brasilia-df/formiga-mg'},
    {link: '/passagem-de-onibus/brasilia-df/casabranca-sp'},
    {link: '/passagem-de-onibus/brasilia-df/itapetinga-ba'},
    {link: '/passagem-de-onibus/brasilia-df/teofilootoni-mg'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/luz-mg'},
    {link: '/passagem-de-onibus/brasilia-df/portoferreira-sp'},
    {link: '/passagem-de-onibus/brasilia-df/itarantim-ba'},
    {link: '/passagem-de-onibus/brasilia-df/itajuba-mg'},
    {link: '/passagem-de-onibus/brasilia-df/saolourenco-mg'},
    {link: '/passagem-de-onibus/brasilia-df/ibia-mg'},
    {link: '/passagem-de-onibus/brasilia-df/medina-mg'},
    {link: '/passagem-de-onibus/brasilia-df/ipameri-go'},
    {link: '/passagem-de-onibus/brasilia-df/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/brasilia-df/potiragua-ba'},
    {link: '/passagem-de-onibus/brasilia-df/anage-ba'},
    {link: '/passagem-de-onibus/londrina-pr/salvador-ba'},
    {link: '/passagem-de-onibus/londrina-pr/ipatinga-mg'},
    {link: '/passagem-de-onibus/londrina-pr/teofilootoni-mg'},
    {link: '/passagem-de-onibus/londrina-pr/feiradesantana-ba'},
    {link: '/passagem-de-onibus/santamariadeitabira-mg/osasco-sp'},
    {link: '/passagem-de-onibus/santamariadeitabira-mg/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/santamariadeitabira-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/santamariadeitabira-mg/serrinha-ba'},
    {link: '/passagem-de-onibus/brasilia-df/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/brasilia-df/lambari-mg'},
    {link: '/passagem-de-onibus/brasilia-df/caetite-ba'},
    {link: '/passagem-de-onibus/brasilia-df/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/brasilia-df/itaobim-mg'},
    {link: '/passagem-de-onibus/brasilia-df/bambui-mg'},
    {link: '/passagem-de-onibus/brasilia-df/portoseguro-ba'},
    {link: '/passagem-de-onibus/brasilia-df/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/curitiba-pr'},
    {link: '/passagem-de-onibus/santoantoniodejesus-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/santoantoniodejesus-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/salinas-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/salinas-mg/jequie-ba'},
    {link: '/passagem-de-onibus/salinas-mg/recife-pe'},
    {link: '/passagem-de-onibus/salinas-mg/maceio-al'},
    {link: '/passagem-de-onibus/salinas-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/moema-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/carmodoparanaiba-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/moema-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/luz-mg'},
    {link: '/passagem-de-onibus/mossoro-rn/uirauna-pb'},
    {link: '/passagem-de-onibus/mossoro-rn/saopaulo-sp'},
    {link: '/passagem-de-onibus/mossoro-rn/ipatinga-mg'},
    {link: '/passagem-de-onibus/mossoro-rn/feiradesantana-ba'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/anchieta-es'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/marataizes-es'},
    {link: '/passagem-de-onibus/teixeiradefreitas-ba/guarapari-es'},
    {link: '/passagem-de-onibus/salinas-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/serratalhada-pe/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/vilavelha-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/vilavelha-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/vilavelha-es/betim-mg'},
    {link: '/passagem-de-onibus/vilavelha-es/pontenova-mg'},
    {link: '/passagem-de-onibus/vilavelha-es/ouropreto-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/rioparanaiba-mg/luz-mg'},
    {link: '/passagem-de-onibus/salinas-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/salinas-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/salinas-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/salinas-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/salinas-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/salinas-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/salinas-mg/aracaju-se'},
    {link: '/passagem-de-onibus/salinas-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/salinas-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/salinas-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/saovitor-mg/periquito-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/saojoaodomanteninha-mg'},
    {link: '/passagem-de-onibus/novaalvoradadosul-ms/belohorizonte-mg'},
    {link: '/passagem-de-onibus/sacramento-mg/conquista-mg'},
    {link: '/passagem-de-onibus/sacramento-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/santana,ba-ba/janauba-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/centraldeminas-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/itueta-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/mantena-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/saovitor-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/jequie-ba'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/inhambupe-ba'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/serrinha-ba'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/tucano-ba'},
    {link: '/passagem-de-onibus/teofilandia-ba/tucano-ba'},
    {link: '/passagem-de-onibus/teofilandia-ba/tucano-ba'},
    {link: '/passagem-de-onibus/teofilandia-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/campinagrande-pb/salvador-ba'},
    {link: '/passagem-de-onibus/campinagrande-pb/aracaju-se'},
    {link: '/passagem-de-onibus/campinagrande-pb/curraisnovos-rn'},
    {link: '/passagem-de-onibus/itabuna-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itabuna-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/itabuna-ba/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/itabuna-ba/maceio-al'},
    {link: '/passagem-de-onibus/itabuna-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/itabuna-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/itabuna-ba/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/itabuna-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/itabuna-ba/curitiba-pr'},
    {link: '/passagem-de-onibus/leopoldina-mg/garanhuns-pe'},
    {link: '/passagem-de-onibus/leopoldina-mg/joaopessoa-pb'},
    {link: '/passagem-de-onibus/leopoldina-mg/maceio-al'},
    {link: '/passagem-de-onibus/anage-ba/goiania-go'},
    {link: '/passagem-de-onibus/anage-ba/correntina-ba'},
    {link: '/passagem-de-onibus/aracaju-se/montesclaros-mg'},
    {link: '/passagem-de-onibus/aracaju-se/luz-mg'},
    {link: '/passagem-de-onibus/aracaju-se/araxa-mg'},
    {link: '/passagem-de-onibus/aracaju-se/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/aracaju-se/frutal-mg'},
    {link: '/passagem-de-onibus/aracaju-se/jequie-ba'},
    {link: '/passagem-de-onibus/aracaju-se/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/aracaju-se/itaobim-mg'},
    {link: '/passagem-de-onibus/aracaju-se/patosdeminas-mg'},
    {link: '/passagem-de-onibus/aracaju-se/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/ipu-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/vicosadoceara-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/ibiapina-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/tiangua-ce'},
    {link: '/passagem-de-onibus/itapagipe-mg/frutal-mg'},
    {link: '/passagem-de-onibus/itapagipe-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/itapagipe-mg/iturama-mg'},
    {link: '/passagem-de-onibus/aracaju-se/belohorizonte-mg'},
    {link: '/passagem-de-onibus/aracaju-se/salinas-mg'},
    {link: '/passagem-de-onibus/aracaju-se/campinagrande-pb'},
    {link: '/passagem-de-onibus/aracaju-se/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/aracaju-se/ipatinga-mg'},
    {link: '/passagem-de-onibus/aracaju-se/barretos-sp'},
    {link: '/passagem-de-onibus/aracaju-se/curitiba-pr'},
    {link: '/passagem-de-onibus/aracaju-se/pirapora-mg'},
    {link: '/passagem-de-onibus/aracaju-se/teofilootoni-mg'},
    {link: '/passagem-de-onibus/aracaju-se/santos-sp'},
    {link: '/passagem-de-onibus/aracaju-se/patrocinio-mg'},
    {link: '/passagem-de-onibus/teixeira-pb/garanhuns-pe'},
    {link: '/passagem-de-onibus/teixeira-pb/arcoverde-pe'},
    {link: '/passagem-de-onibus/teixeira-pb/venturosa-pe'},
    {link: '/passagem-de-onibus/teixeira-pb/sertania-pe'},
    {link: '/passagem-de-onibus/teofilandia-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/teofilandia-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/teofilandia-ba/salvador-ba'},
    {link: '/passagem-de-onibus/teofilandia-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/teofilandia-ba/tucano-ba'},
    {link: '/passagem-de-onibus/teofilandia-ba/tucano-ba'},
    {link: '/passagem-de-onibus/aracaju-se/uberaba-mg'},
    {link: '/passagem-de-onibus/aracaju-se/uberlandia-mg'},
    {link: '/passagem-de-onibus/aracaju-se/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/aracaju-se/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/aracaju-se/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/aracaju-se/olimpia-sp'},
    {link: '/passagem-de-onibus/aracaju-se/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/aracaju-se/saopaulo-sp'},
    {link: '/passagem-de-onibus/santamariadosuacui-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/oeiras-pi/fortaleza-ce'},
    {link: '/passagem-de-onibus/oeiras-pi/taua-ce'},
    {link: '/passagem-de-onibus/oeiras-pi/pedrabranca-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/camocim-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/saobenedito-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/ubajara-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/granja-ce'},
    {link: '/passagem-de-onibus/tamboril-ce/guaraciabadonorte-ce'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/saopaulo-sp'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/teofilootoni-mg'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/santos-sp'},
    {link: '/passagem-de-onibus/martinhocampos-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/martinhocampos-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/martinhocampos-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/anage-ba/anapolis-go'},
    {link: '/passagem-de-onibus/anage-ba/abadiania-go'},
    {link: '/passagem-de-onibus/anage-ba/brasilia-df'},
    {link: '/passagem-de-onibus/treslagoas-ms/belohorizonte-mg'},
    {link: '/passagem-de-onibus/treslagoas-ms/luz-mg'},
    {link: '/passagem-de-onibus/treslagoas-ms/araxa-mg'},
    {link: '/passagem-de-onibus/treslagoas-ms/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/treslagoas-ms/uberaba-mg'},
    {link: '/passagem-de-onibus/treslagoas-ms/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/treslagoas-ms'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/ipatinga-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/pirapora-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/teofilootoni-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/patrocinio-mg'},
    {link: '/passagem-de-onibus/resplendor-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/resplendor-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/treslagoas-ms/barretos-sp'},
    {link: '/passagem-de-onibus/cambuquira-mg/cristalina-go'},
    {link: '/passagem-de-onibus/cambuquira-mg/brasilia-df'},
    {link: '/passagem-de-onibus/santajuliana-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/ituiutaba-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/luz-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/lavras-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/araxa-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/patosdeminas-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/recife-pe'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/maceio-al'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/milagres,ba-ba'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/salinas-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/aracaju-se'},
    {link: '/passagem-de-onibus/santajuliana-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/novaponte-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/montealegredeminas-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/arcos-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/formiga-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/bambui-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/moema-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/ibia-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/ibia-mg'},
    {link: '/passagem-de-onibus/santajuliana-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/luz-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/araxa-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/itaobim-mg'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/montesclaros-mg'},
    {link: '/passagem-de-onibus/almenara-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/almenara-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/almenara-mg/salvador-ba'},
    {link: '/passagem-de-onibus/almenara-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/almenara-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/almenara-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/almenara-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/almenara-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/almenara-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/ituiutaba-mg'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/resplendor-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/resplendor-mg/periquito-mg'},
    {link: '/passagem-de-onibus/resplendor-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/novavenecia-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/novavenecia-es/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/novavenecia-es/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/novavenecia-es/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/queimadas-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/cruzdasalmas-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/cruzdasalmas-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/mantena-mg'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/ponteselacerda-mt/ipatinga-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/novavicosa-ba'},
    {link: '/passagem-de-onibus/freiinocencio-mg/periquito-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/freiinocencio-mg/almenara-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/almenara-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/almenara-mg/campanario-mg'},
    {link: '/passagem-de-onibus/almenara-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/almenara-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/almenara-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/almenara-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/almenara-mg/jequie-ba'},
    {link: '/passagem-de-onibus/almenara-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/almenara-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/almenara-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/almenara-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/almenara-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/arcos-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/arcos-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/arcos-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/arcos-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/arcos-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/arcos-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/arcos-mg/brasilia-df'},
    {link: '/passagem-de-onibus/arcos-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/arcos-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/arcos-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/uniaodospalmares-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/uniaodospalmares-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/arcos-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/arcos-mg/lavras-mg'},
    {link: '/passagem-de-onibus/arcos-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/arcos-mg/araxa-mg'},
    {link: '/passagem-de-onibus/arcos-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/arcos-mg/cristalina-go'},
    {link: '/passagem-de-onibus/arcos-mg/ibia-mg'},
    {link: '/passagem-de-onibus/arcos-mg/ibia-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/itambacuri-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/freiinocencio-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/freiinocencio-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/freiinocencio-mg/mucuri-ba'},
    {link: '/passagem-de-onibus/freiinocencio-mg/nanuque-mg'},
    {link: '/passagem-de-onibus/jacobina-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/ubaitaba-ba/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/ubaitaba-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/ubaitaba-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/ubata-ba/voltaredonda-rj'},
    {link: '/passagem-de-onibus/ubata-ba/osasco-sp'},
    {link: '/passagem-de-onibus/ubata-ba/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/ubata-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ubata-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/vicosadoceara-ce/camocim-ce'},
    {link: '/passagem-de-onibus/vicosadoceara-ce/ipu-ce'},
    {link: '/passagem-de-onibus/vicosadoceara-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/vicosadoceara-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/vicosadoceara-ce/crateus-ce'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/jequie-ba'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/gouveia-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/josegoncalvesdeminas-mg'},
    {link: '/passagem-de-onibus/novaera-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/valenca-rj/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/valenca-rj/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/valenca-rj/saopaulo-sp'},
    {link: '/passagem-de-onibus/varjota-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/varjota-ce/sobral-ce'},
    {link: '/passagem-de-onibus/varjota-ce/crateus-ce'},
    {link: '/passagem-de-onibus/alagoinhas-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/aracuai-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/patos-pb/garanhuns-pe'},
    {link: '/passagem-de-onibus/patos-pb/arcoverde-pe'},
    {link: '/passagem-de-onibus/patos-pb/venturosa-pe'},
    {link: '/passagem-de-onibus/patos-pb/saopaulo-sp'},
    {link: '/passagem-de-onibus/patos-pb/sertania-pe'},
    {link: '/passagem-de-onibus/piquete-sp/brasilia-df'},
    {link: '/passagem-de-onibus/floriano-pi/fortaleza-ce'},
    {link: '/passagem-de-onibus/floriano-pi/taua-ce'},
    {link: '/passagem-de-onibus/linhares-es/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/linhares-es/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/linhares-es/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/linhares-es/saopaulo-sp'},
    {link: '/passagem-de-onibus/linhares-es/curitiba-pr'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/campanario-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/camacari-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/saogabrieldapalha-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saogabrieldapalha-es/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/saogabrieldapalha-es/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/saogabrieldapalha-es/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/novaera-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/novaera-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/novaera-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/novaera-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/almenara-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/saltodadivisa-mg/teofilootoni-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/virginopolis-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/minasnovas-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/acucena-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/betim-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/alagoinhas-ba/capelinha-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/alagoinhas-ba/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/januaria-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/januaria-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/januaria-mg/corinto-mg'},
    {link: '/passagem-de-onibus/januaria-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/januaria-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/januaria-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/januaria-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/januaria-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/januaria-mg/osasco-sp'},
    {link: '/passagem-de-onibus/januaria-mg/lontra-mg'},
    {link: '/passagem-de-onibus/januaria-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/januaria-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/januaria-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/januaria-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/camacari-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/engenheironavarro-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/viana-es/maceio-al'},
    {link: '/passagem-de-onibus/ibiai-mg/saofrancisco,mg-mg'},
    {link: '/passagem-de-onibus/ibiai-mg/ubai-mg'},
    {link: '/passagem-de-onibus/ibiai-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/ibiai-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/alagoinhas-ba'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/malacacheta-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/osasco-sp'},
    {link: '/passagem-de-onibus/serrinha-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/formiga-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/mineiros-go'},
    {link: '/passagem-de-onibus/juizdefora-mg/itumbiara-go'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/corinto-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/corinto-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/monteazul-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/janauba-mg'},
    {link: '/passagem-de-onibus/joaquimfelicio-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/itabira-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/pote-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/guanhaes-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/ribeiradoamparo-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/serrinha-ba/santamariadeitabira-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/campobelo-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/luz-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/divinopolis-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/araxa-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/bambui-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/cuiaba-mt'},
    {link: '/passagem-de-onibus/juizdefora-mg/iguatama-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/ibia-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/jatai-go'},
    {link: '/passagem-de-onibus/juizdefora-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/rondonopolis-mt'},
    {link: '/passagem-de-onibus/juizdefora-mg/rioverde-go'},
    {link: '/passagem-de-onibus/juizdefora-mg/arcos-mg'},
    {link: '/passagem-de-onibus/serrinha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/tucano-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/cipo-ba'},
    {link: '/passagem-de-onibus/pompeu-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/pompeu-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/pompeu-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/juizdefora-mg/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/juizdefora-mg/altogarcas-mt'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/coutodemagalhaesdeminas-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/inhambupe-ba'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/diamantina-mg'},
    {link: '/passagem-de-onibus/virgemdalapa-mg/diamantina-mg'},
    {link: '/passagem-de-onibus/arapiraca-al/voltaredonda-rj'},
    {link: '/passagem-de-onibus/arapiraca-al/natal-rn'},
    {link: '/passagem-de-onibus/arapiraca-al/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/arapiraca-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/arapiraca-al/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/arapiraca-al/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/arapiraca-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/arapiraca-al/feiradesantana-ba'},
    {link: '/passagem-de-onibus/alvorada-to/frutal-mg'},
    {link: '/passagem-de-onibus/alvorada-to/araraquara-sp'},
    {link: '/passagem-de-onibus/alvorada-to/campinas-sp'},
    {link: '/passagem-de-onibus/alvorada-to/americana-sp'},
    {link: '/passagem-de-onibus/alvorada-to/saocarlos-sp'},
    {link: '/passagem-de-onibus/alvorada-to/saopaulo-sp'},
    {link: '/passagem-de-onibus/alvorada-to/prata-mg'},
    {link: '/passagem-de-onibus/alvorada-to/barretos-sp'},
    {link: '/passagem-de-onibus/alvorada-to/planura-mg'},
    {link: '/passagem-de-onibus/alvorada-to/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/alvorada-to/itumbiara-go'},
    {link: '/passagem-de-onibus/ipatinga-mg/goiania-go'},
    {link: '/passagem-de-onibus/portonacional-to/planura-mg'},
    {link: '/passagem-de-onibus/portonacional-to/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/portonacional-to/itumbiara-go'},
    {link: '/passagem-de-onibus/crateus-ce/afranio-pe'},
    {link: '/passagem-de-onibus/crateus-ce/belohorizonte-mg'},
    {link: '/passagem-de-onibus/crateus-ce/camocim-ce'},
    {link: '/passagem-de-onibus/crateus-ce/saobenedito-ce'},
    {link: '/passagem-de-onibus/crateus-ce/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/crateus-ce/ubajara-ce'},
    {link: '/passagem-de-onibus/porangatu-go/frutal-mg'},
    {link: '/passagem-de-onibus/porangatu-go/araraquara-sp'},
    {link: '/passagem-de-onibus/porangatu-go/campinas-sp'},
    {link: '/passagem-de-onibus/porangatu-go/americana-sp'},
    {link: '/passagem-de-onibus/porangatu-go/saocarlos-sp'},
    {link: '/passagem-de-onibus/porangatu-go/prata-mg'},
    {link: '/passagem-de-onibus/porangatu-go/barretos-sp'},
    {link: '/passagem-de-onibus/porangatu-go/planura-mg'},
    {link: '/passagem-de-onibus/porangatu-go/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/portonacional-to/frutal-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/jaru-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/saosimao-go'},
    {link: '/passagem-de-onibus/ipatinga-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/caninde-ce'},
    {link: '/passagem-de-onibus/ipatinga-mg/ji-parana-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/crateus-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/crateus-ce/picos-pi'},
    {link: '/passagem-de-onibus/crateus-ce/paulistana-pi'},
    {link: '/passagem-de-onibus/crateus-ce/sobral-ce'},
    {link: '/passagem-de-onibus/crateus-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/crateus-ce/varjota-ce'},
    {link: '/passagem-de-onibus/crateus-ce/vicosadoceara-ce'},
    {link: '/passagem-de-onibus/crateus-ce/ibiapina-ce'},
    {link: '/passagem-de-onibus/crateus-ce/tiangua-ce'},
    {link: '/passagem-de-onibus/crateus-ce/granja-ce'},
    {link: '/passagem-de-onibus/crateus-ce/guaraciabadonorte-ce'},
    {link: '/passagem-de-onibus/crateus-ce/carire-ce'},
    {link: '/passagem-de-onibus/crateus-ce/ipu-ce'},
    {link: '/passagem-de-onibus/crateus-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/crateus-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/crateus-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/crateus-ce/novarussas-ce'},
    {link: '/passagem-de-onibus/crateus-ce/petrolina-pe'},
    {link: '/passagem-de-onibus/portonacional-to/araraquara-sp'},
    {link: '/passagem-de-onibus/portonacional-to/campinas-sp'},
    {link: '/passagem-de-onibus/portonacional-to/americana-sp'},
    {link: '/passagem-de-onibus/portonacional-to/saocarlos-sp'},
    {link: '/passagem-de-onibus/portonacional-to/saopaulo-sp'},
    {link: '/passagem-de-onibus/portonacional-to/prata-mg'},
    {link: '/passagem-de-onibus/portonacional-to/barretos-sp'},
    {link: '/passagem-de-onibus/ubai-mg/ibiai-mg'},
    {link: '/passagem-de-onibus/ubai-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/ubai-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/casabranca-sp/brasilia-df'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/abadiania-go'},
    {link: '/passagem-de-onibus/penedo-al/voltaredonda-rj'},
    {link: '/passagem-de-onibus/penedo-al/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/penedo-al/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/penedo-al/saopaulo-sp'},
    {link: '/passagem-de-onibus/buenopolis-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/formiga-mg/santajuliana-mg'},
    {link: '/passagem-de-onibus/formiga-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/oliveira-mg/divinopolis-mg'},
    {link: '/passagem-de-onibus/oliveira-mg/araxa-mg'},
    {link: '/passagem-de-onibus/oliveira-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/oliveira-mg/ibia-mg'},
    {link: '/passagem-de-onibus/oliveira-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/oliveira-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/oliveira-mg/juizdefora-mg'},
    {link: '/passagem-de-onibus/ubai-mg/saofrancisco,mg-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/mineiros-go'},
    {link: '/passagem-de-onibus/ipatinga-mg/pimentabueno-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/barretos-sp'},
    {link: '/passagem-de-onibus/ipatinga-mg/vitoria-es'},
    {link: '/passagem-de-onibus/ipatinga-mg/boaviagem-ce'},
    {link: '/passagem-de-onibus/ipatinga-mg/saojoaodomanteninha-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/salvador-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/presidentemedici-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/cacoal-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/londrina-pr'},
    {link: '/passagem-de-onibus/ipatinga-mg/brasilia-df'},
    {link: '/passagem-de-onibus/ipatinga-mg/mossoro-rn'},
    {link: '/passagem-de-onibus/ipatinga-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/aracaju-se'},
    {link: '/passagem-de-onibus/ipatinga-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/ipatinga-mg/ponteselacerda-mt'},
    {link: '/passagem-de-onibus/ipatinga-mg/maringa-pr'},
    {link: '/passagem-de-onibus/ipatinga-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/cuiaba-mt'},
    {link: '/passagem-de-onibus/ipatinga-mg/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/ipatinga-mg/centraldeminas-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/recife-pe'},
    {link: '/passagem-de-onibus/ipatinga-mg/vilhena-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/guarapari-es'},
    {link: '/passagem-de-onibus/ipatinga-mg/mantena-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/ipatinga-mg/maceio-al'},
    {link: '/passagem-de-onibus/ipatinga-mg/alcobaca-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/ariquemes-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/ouropretodooeste-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/formiga-mg/cristalina-go'},
    {link: '/passagem-de-onibus/formiga-mg/ibia-mg'},
    {link: '/passagem-de-onibus/formiga-mg/ibia-mg'},
    {link: '/passagem-de-onibus/formiga-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/formiga-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/formiga-mg/pedrinopolis-mg'},
    {link: '/passagem-de-onibus/formiga-mg/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/formiga-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/formiga-mg/camposaltos-mg'},
    {link: '/passagem-de-onibus/formiga-mg/brasilia-df'},
    {link: '/passagem-de-onibus/ipatinga-mg/naque-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/galileia-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/periquito-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/conselheiropena-mg'},
    {link: '/passagem-de-onibus/formiga-mg/perdizes-mg'},
    {link: '/passagem-de-onibus/formiga-mg/lavras-mg'},
    {link: '/passagem-de-onibus/formiga-mg/canaverde-mg'},
    {link: '/passagem-de-onibus/formiga-mg/araxa-mg'},
    {link: '/passagem-de-onibus/formiga-mg/perdoes-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/taua-ce'},
    {link: '/passagem-de-onibus/ipatinga-mg/jatai-go'},
    {link: '/passagem-de-onibus/ipatinga-mg/pedrabranca-ce'},
    {link: '/passagem-de-onibus/ipatinga-mg/portovelho-ro'},
    {link: '/passagem-de-onibus/ipatinga-mg/periquito-mg'},
    {link: '/passagem-de-onibus/ipatinga-mg/prado-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/ipatinga-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/ipatinga-mg/rondonopolis-mt'},
    {link: '/passagem-de-onibus/ipatinga-mg/caceres-mt'},
    {link: '/passagem-de-onibus/buenopolis-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/pedrasdemariadacruz-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/janauba-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/buenopolis-mg/januaria-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/engenheironavarro-mg'},
    {link: '/passagem-de-onibus/bauru-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/bauru-sp/divinopolis-mg'},
    {link: '/passagem-de-onibus/bauru-sp/passos-mg'},
    {link: '/passagem-de-onibus/bauru-sp/saosebastiaodoparaiso-mg'},
    {link: '/passagem-de-onibus/novaiguacu-rj/petrolina-pe'},
    {link: '/passagem-de-onibus/novaiguacu-rj/parambu-ce'},
    {link: '/passagem-de-onibus/novaiguacu-rj/juazeiro-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/curvelo-mg/aracuai-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/janauba-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/engenheironavarro-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/monteazul-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/pedroleopoldo-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/josegoncalvesdeminas-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/lassance-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/lemedoprado-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/coutodemagalhaesdeminas-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/porteirinha-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/curvelo-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/curvelo-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/diamantina-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/diamantina-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/monteazul-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/matoverde-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/espinosa-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/lontra-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/corinto-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/corinto-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/bocaiuva-mg'},
    {link: '/passagem-de-onibus/curvelo-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/curvelo-mg/capitaoeneas-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/corinto-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/corinto-mg'},
    {link: '/passagem-de-onibus/buenopolis-mg/mirabela-mg'},
    {link: '/passagem-de-onibus/tucano-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/tucano-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/tucano-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/pimentabueno-ro/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/pimentabueno-ro/altogarcas-mt'},
    {link: '/passagem-de-onibus/pimentabueno-ro/ituiutaba-mg'},
    {link: '/passagem-de-onibus/pimentabueno-ro/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/pimentabueno-ro/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/pimentabueno-ro/mantena-mg'},
    {link: '/passagem-de-onibus/pimentabueno-ro/uberaba-mg'},
    {link: '/passagem-de-onibus/tucano-ba/salvador-ba'},
    {link: '/passagem-de-onibus/tucano-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/tucano-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/tucano-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/tucano-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/tucano-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/tucano-ba/malacacheta-mg'},
    {link: '/passagem-de-onibus/tucano-ba/osasco-sp'},
    {link: '/passagem-de-onibus/tucano-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/barretos-sp/araxa-mg'},
    {link: '/passagem-de-onibus/barretos-sp/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/barretos-sp/campogrande-ms'},
    {link: '/passagem-de-onibus/barretos-sp/palmas,to-to'},
    {link: '/passagem-de-onibus/barretos-sp/jequie-ba'},
    {link: '/passagem-de-onibus/barretos-sp/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/barretos-sp/itaobim-mg'},
    {link: '/passagem-de-onibus/barretos-sp/ceres-go'},
    {link: '/passagem-de-onibus/barretos-sp/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/barretos-sp/recife-pe'},
    {link: '/passagem-de-onibus/mineiros-go/juizdefora-mg'},
    {link: '/passagem-de-onibus/mineiros-go/ipatinga-mg'},
    {link: '/passagem-de-onibus/sertania-pe/teixeira-pb'},
    {link: '/passagem-de-onibus/sertania-pe/patos-pb'},
    {link: '/passagem-de-onibus/tucano-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/araci-ba'},
    {link: '/passagem-de-onibus/tucano-ba/salvador-ba'},
    {link: '/passagem-de-onibus/tucano-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/tucano-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/tucano-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/pimentabueno-ro/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/pimentabueno-ro/campinas-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/jatai-go'},
    {link: '/passagem-de-onibus/pimentabueno-ro/saopaulo-sp'},
    {link: '/passagem-de-onibus/pimentabueno-ro/ipatinga-mg'},
    {link: '/passagem-de-onibus/barretos-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/barretos-sp/luz-mg'},
    {link: '/passagem-de-onibus/mineiros-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/mineiros-go/araxa-mg'},
    {link: '/passagem-de-onibus/mineiros-go/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/mineiros-go/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/mineiros-go/mantena-mg'},
    {link: '/passagem-de-onibus/mineiros-go/uberaba-mg'},
    {link: '/passagem-de-onibus/mineiros-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/mineiros-go/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/mineiros-go/betim-mg'},
    {link: '/passagem-de-onibus/tucano-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/tucano-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/salvador-ba'},
    {link: '/passagem-de-onibus/tucano-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/tucano-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/tucano-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/tucano-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/itabira-mg'},
    {link: '/passagem-de-onibus/tucano-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/tucano-ba/araci-ba'},
    {link: '/passagem-de-onibus/tucano-ba/pote-mg'},
    {link: '/passagem-de-onibus/tucano-ba/salvador-ba'},
    {link: '/passagem-de-onibus/tucano-ba/guanhaes-mg'},
    {link: '/passagem-de-onibus/tucano-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/tucano-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/tucano-ba/santamariadeitabira-mg'},
    {link: '/passagem-de-onibus/barretos-sp/gurupi-to'},
    {link: '/passagem-de-onibus/barretos-sp/jaragua-go'},
    {link: '/passagem-de-onibus/barretos-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/setelagoas-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/setelagoas-mg/corinto-mg'},
    {link: '/passagem-de-onibus/setelagoas-mg/capimgrosso-ba'},
    {link: '/passagem-de-onibus/setelagoas-mg/lassance-mg'},
    {link: '/passagem-de-onibus/setelagoas-mg/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/setelagoas-mg/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/setelagoas-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/setelagoas-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/vitoria-es/realeza,mg-mg'},
    {link: '/passagem-de-onibus/vitoria-es/riocasca-mg'},
    {link: '/passagem-de-onibus/vitoria-es/ipatinga-mg'},
    {link: '/passagem-de-onibus/vitoria-es/ouropreto-mg'},
    {link: '/passagem-de-onibus/riachodesantana-ba/franca-sp'},
    {link: '/passagem-de-onibus/riachodesantana-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/riachodesantana-ba/limeira-sp'},
    {link: '/passagem-de-onibus/riachodesantana-ba/araxa-mg'},
    {link: '/passagem-de-onibus/riachodesantana-ba/espinosa-mg'},
    {link: '/passagem-de-onibus/vitoria-es/domcavati-mg'},
    {link: '/passagem-de-onibus/vitoria-es/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/vitoria-es/engenheirocaldas-mg'},
    {link: '/passagem-de-onibus/vitoria-es/inhapim-mg'},
    {link: '/passagem-de-onibus/vitoria-es/timoteo-mg'},
    {link: '/passagem-de-onibus/vitoria-es/maceio-al'},
    {link: '/passagem-de-onibus/vitoria-es/betim-mg'},
    {link: '/passagem-de-onibus/vitoria-es/pontenova-mg'},
    {link: '/passagem-de-onibus/vitoria-es/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/setelagoas-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/setelagoas-mg/petrolina-pe'},
    {link: '/passagem-de-onibus/setelagoas-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/setelagoas-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/setelagoas-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/setelagoas-mg/juazeiro-ba'},
    {link: '/passagem-de-onibus/setelagoas-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/vitoria-es/caratinga-mg'},
    {link: '/passagem-de-onibus/boaviagem-ce/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/boaviagem-ce/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/boaviagem-ce/jaicos-pi'},
    {link: '/passagem-de-onibus/boaviagem-ce/nazaredopiaui-pi'},
    {link: '/passagem-de-onibus/boaviagem-ce/picos-pi'},
    {link: '/passagem-de-onibus/boaviagem-ce/patosdopiaui-pi'},
    {link: '/passagem-de-onibus/boaviagem-ce/paulistana-pi'},
    {link: '/passagem-de-onibus/boaviagem-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/boaviagem-ce/ipatinga-mg'},
    {link: '/passagem-de-onibus/boaviagem-ce/teofilootoni-mg'},
    {link: '/passagem-de-onibus/riachodesantana-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/riachodesantana-ba/ibia-mg'},
    {link: '/passagem-de-onibus/riachodesantana-ba/campinas-sp'},
    {link: '/passagem-de-onibus/riachodesantana-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/riachodesantana-ba/americana-sp'},
    {link: '/passagem-de-onibus/riachodesantana-ba/janauba-mg'},
    {link: '/passagem-de-onibus/riachodesantana-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/riachodesantana-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/curitiba-pr/belohorizonte-mg'},
    {link: '/passagem-de-onibus/barretos-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/barretos-sp/aracaju-se'},
    {link: '/passagem-de-onibus/barretos-sp/treslagoas-ms'},
    {link: '/passagem-de-onibus/barretos-sp/porangatu-go'},
    {link: '/passagem-de-onibus/barretos-sp/portonacional-to'},
    {link: '/passagem-de-onibus/barretos-sp/alvorada-to'},
    {link: '/passagem-de-onibus/barretos-sp/ipatinga-mg'},
    {link: '/passagem-de-onibus/barretos-sp/uruacu-go'},
    {link: '/passagem-de-onibus/barretos-sp/teofilootoni-mg'},
    {link: '/passagem-de-onibus/curitiba-pr/eunapolis-ba'},
    {link: '/passagem-de-onibus/curitiba-pr/recife-pe'},
    {link: '/passagem-de-onibus/curitiba-pr/maceio-al'},
    {link: '/passagem-de-onibus/curitiba-pr/teixeiradefreitas-ba'},
    {link: '/passagem-de-onibus/curitiba-pr/aracaju-se'},
    {link: '/passagem-de-onibus/curitiba-pr/itabuna-ba'},
    {link: '/passagem-de-onibus/curitiba-pr/linhares-es'},
    {link: '/passagem-de-onibus/curitiba-pr/saomateus-es'},
    {link: '/passagem-de-onibus/boaviagem-ce/afranio-pe'},
    {link: '/passagem-de-onibus/barretos-sp/maceio-al'},
    {link: '/passagem-de-onibus/barretos-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/barretos-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/barretos-sp/milagres,ba-ba'},
    {link: '/passagem-de-onibus/barretos-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/curraisnovos-rn/juazeirinho-pb'},
    {link: '/passagem-de-onibus/curraisnovos-rn/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/curraisnovos-rn/saopaulo-sp'},
    {link: '/passagem-de-onibus/curraisnovos-rn/campinagrande-pb'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/ico-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/ico-ce/betim-mg'},
    {link: '/passagem-de-onibus/ico-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/taubate-sp/jequie-ba'},
    {link: '/passagem-de-onibus/taubate-sp/salvador-ba'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/divinodaslaranjeiras-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/periquito-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/saovitor-mg'},
    {link: '/passagem-de-onibus/saojoaodomanteninha-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/itapetinga-ba/brasilia-df'},
    {link: '/passagem-de-onibus/cansancao-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/pirapora-mg/franca-sp'},
    {link: '/passagem-de-onibus/pirapora-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/pirapora-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/corinto-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/corinto-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/limeira-sp'},
    {link: '/passagem-de-onibus/pirapora-mg/bomjesusdalapa-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/pedroleopoldo-mg'},
    {link: '/passagem-de-onibus/planura-mg/uruacu-go'},
    {link: '/passagem-de-onibus/planura-mg/jaragua-go'},
    {link: '/passagem-de-onibus/itapetinga-ba/goiania-go'},
    {link: '/passagem-de-onibus/itapetinga-ba/correntina-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/itapetinga-ba/anapolis-go'},
    {link: '/passagem-de-onibus/itapetinga-ba/abadiania-go'},
    {link: '/passagem-de-onibus/itapetinga-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/itapetinga-ba/guarulhos-sp'},
    {link: '/passagem-de-onibus/itapetinga-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/planura-mg/conceicaodasalagoas-mg'},
    {link: '/passagem-de-onibus/planura-mg/palmas,to-to'},
    {link: '/passagem-de-onibus/planura-mg/ceres-go'},
    {link: '/passagem-de-onibus/planura-mg/gurupi-to'},
    {link: '/passagem-de-onibus/planura-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/planura-mg/iturama-mg'},
    {link: '/passagem-de-onibus/planura-mg/itapagipe-mg'},
    {link: '/passagem-de-onibus/planura-mg/porangatu-go'},
    {link: '/passagem-de-onibus/planura-mg/portonacional-to'},
    {link: '/passagem-de-onibus/planura-mg/alvorada-to'},
    {link: '/passagem-de-onibus/parambu-ce/aparecida-sp'},
    {link: '/passagem-de-onibus/parambu-ce/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/parambu-ce/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/parambu-ce/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/parambu-ce/petrolina-pe'},
    {link: '/passagem-de-onibus/parambu-ce/picos-pi'},
    {link: '/passagem-de-onibus/parambu-ce/resende-rj'},
    {link: '/passagem-de-onibus/parambu-ce/saopaulo-sp'},
    {link: '/passagem-de-onibus/parambu-ce/novaiguacu-rj'},
    {link: '/passagem-de-onibus/planura-mg/saofranciscodesales-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/saofrancisco,mg-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/aracaju-se'},
    {link: '/passagem-de-onibus/pirapora-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/pirapora-mg/ibiai-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/ubai-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/setelagoas-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/riachodesantana-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/pirapora-mg/guanambi-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/pontochique-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/americana-sp'},
    {link: '/passagem-de-onibus/pirapora-mg/pindai-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/prudentedemorais-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/pirapora-mg/jequie-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/recife-pe'},
    {link: '/passagem-de-onibus/pirapora-mg/maceio-al'},
    {link: '/passagem-de-onibus/pirapora-mg/lassance-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/campinas-sp'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/prudentedemorais-mg'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/setelagoas-mg'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/pirapora-mg'},
    {link: '/passagem-de-onibus/carangola-mg/marataizes-es'},
    {link: '/passagem-de-onibus/saomateus-es/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saomateus-es/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/saomateus-es/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/saomateus-es/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/varzeadapalma-mg'},
    {link: '/passagem-de-onibus/pirapora-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/valencadopiaui-pi/afranio-pe'},
    {link: '/passagem-de-onibus/valencadopiaui-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/teofilootoni-mg/goiania-go'},
    {link: '/passagem-de-onibus/teofilootoni-mg/garanhuns-pe'},
    {link: '/passagem-de-onibus/teofilootoni-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/bertopolis-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/campanario-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/campinas-sp'},
    {link: '/passagem-de-onibus/teofilootoni-mg/novoorientedeminas-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/olimpia-sp'},
    {link: '/passagem-de-onibus/teofilootoni-mg/taua-ce'},
    {link: '/passagem-de-onibus/teofilootoni-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/teofilootoni-mg/pedrabranca-ce'},
    {link: '/passagem-de-onibus/teofilootoni-mg/pedrabranca-ce'},
    {link: '/passagem-de-onibus/teofilootoni-mg/pontodosvolantes-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/guarulhos-sp'},
    {link: '/passagem-de-onibus/teofilootoni-mg/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/saomateus-es/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saomateus-es/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/saomateus-es/saopaulo-sp'},
    {link: '/passagem-de-onibus/saomateus-es/camposdosgoytacazes-rj'},
    {link: '/passagem-de-onibus/saomateus-es/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saomateus-es/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/saomateus-es/saopaulo-sp'},
    {link: '/passagem-de-onibus/saomateus-es/curitiba-pr'},
    {link: '/passagem-de-onibus/ibiapina-ce/camocim-ce'},
    {link: '/passagem-de-onibus/ibiapina-ce/ipu-ce'},
    {link: '/passagem-de-onibus/teofilootoni-mg/jacinto-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/natal-rn'},
    {link: '/passagem-de-onibus/teofilootoni-mg/campomourao-pr'},
    {link: '/passagem-de-onibus/teofilootoni-mg/maceio-al'},
    {link: '/passagem-de-onibus/teofilootoni-mg/medina-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/teofilootoni-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/teofilootoni-mg/machacalis-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/joaopessoa-pb'},
    {link: '/passagem-de-onibus/teofilootoni-mg/aguasformosas-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/teofilootoni-mg/eunapolis-ba'},
    {link: '/passagem-de-onibus/teofilootoni-mg/pavao-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/itaobim-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/portoseguro-ba'},
    {link: '/passagem-de-onibus/teofilootoni-mg/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/osasco-sp'},
    {link: '/passagem-de-onibus/teofilootoni-mg/recife-pe'},
    {link: '/passagem-de-onibus/teofilootoni-mg/saltodadivisa-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/barretos-sp'},
    {link: '/passagem-de-onibus/teofilootoni-mg/boaviagem-ce'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/corinto-mg'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/corinto-mg'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/pedroleopoldo-mg'},
    {link: '/passagem-de-onibus/varzeadapalma-mg/paraopeba-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/itagimirim-ba'},
    {link: '/passagem-de-onibus/teofilootoni-mg/londrina-pr'},
    {link: '/passagem-de-onibus/teofilootoni-mg/brasilia-df'},
    {link: '/passagem-de-onibus/teofilootoni-mg/aracaju-se'},
    {link: '/passagem-de-onibus/teofilootoni-mg/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/teofilootoni-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/teofilootoni-mg/freiinocencio-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/almenara-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/campanario-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/crisolita-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/padreparaiso-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/teofilootoni-mg/caninde-ce'},
    {link: '/passagem-de-onibus/teofilootoni-mg/fortaleza-ce'},
    {link: '/passagem-de-onibus/teofilootoni-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/teofilootoni-mg/maringa-pr'},
    {link: '/passagem-de-onibus/ibiapina-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/ibiapina-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/ibiapina-ce/crateus-ce'},
    {link: '/passagem-de-onibus/itaguara-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/ouropreto-mg/vendanovadoimigrante-es'},
    {link: '/passagem-de-onibus/ouropreto-mg/ibatiba-es'},
    {link: '/passagem-de-onibus/ouropreto-mg/marataizes-es'},
    {link: '/passagem-de-onibus/ouropreto-mg/guarapari-es'},
    {link: '/passagem-de-onibus/ouropreto-mg/vilavelha-es'},
    {link: '/passagem-de-onibus/ouropreto-mg/vitoria-es'},
    {link: '/passagem-de-onibus/juazeiro-ba/aparecida-sp'},
    {link: '/passagem-de-onibus/juazeiro-ba/saoraimundononato-pi'},
    {link: '/passagem-de-onibus/juazeiro-ba/corinto-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/saojoaodopiaui-pi'},
    {link: '/passagem-de-onibus/juazeiro-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/bocaiuva-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/juazeiro-ba/governadorvaladares-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/juazeiro-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/juazeiro-ba/remanso-ba'},
    {link: '/passagem-de-onibus/juazeiro-ba/resende-rj'},
    {link: '/passagem-de-onibus/juazeiro-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/juazeiro-ba/saolourencodopiaui-pi'},
    {link: '/passagem-de-onibus/juazeiro-ba/salinas-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/novaiguacu-rj'},
    {link: '/passagem-de-onibus/juazeiro-ba/curvelo-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/setelagoas-mg'},
    {link: '/passagem-de-onibus/santos-sp/itabaiana-se'},
    {link: '/passagem-de-onibus/santos-sp/esplanada-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/luz-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/araxa-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/cascavel-pr'},
    {link: '/passagem-de-onibus/feiradesantana-ba/maringa-pr'},
    {link: '/passagem-de-onibus/feiradesantana-ba/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ribeiradopombal-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/jequitinhonha-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/juazeiro-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/juazeiro-ba/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/juazeiro-ba/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/juazeiro-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/pilaoarcado-ba'},
    {link: '/passagem-de-onibus/juazeiro-ba/betim-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/paraopeba-mg'},
    {link: '/passagem-de-onibus/juazeiro-ba/casanova-ba'},
    {link: '/passagem-de-onibus/juazeiro-ba/casanova-ba'},
    {link: '/passagem-de-onibus/jaragua-go/saopaulo-sp'},
    {link: '/passagem-de-onibus/jaragua-go/prata-mg'},
    {link: '/passagem-de-onibus/jaragua-go/barretos-sp'},
    {link: '/passagem-de-onibus/jaragua-go/planura-mg'},
    {link: '/passagem-de-onibus/jaragua-go/rioclaro,sp-sp'},
    {link: '/passagem-de-onibus/medeirosneto-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/cipo-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/cipo-ba/santabarbara,ba-ba'},
    {link: '/passagem-de-onibus/cipo-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/cipo-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/jaragua-go/frutal-mg'},
    {link: '/passagem-de-onibus/jaragua-go/araraquara-sp'},
    {link: '/passagem-de-onibus/jaragua-go/campinas-sp'},
    {link: '/passagem-de-onibus/jaragua-go/americana-sp'},
    {link: '/passagem-de-onibus/jaragua-go/saocarlos-sp'},
    {link: '/passagem-de-onibus/santos-sp/lagarto-se'},
    {link: '/passagem-de-onibus/santos-sp/estancia-se'},
    {link: '/passagem-de-onibus/santos-sp/aracaju-se'},
    {link: '/passagem-de-onibus/santos-sp/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/elesbaoveloso-pi/petrolina-pe'},
    {link: '/passagem-de-onibus/cipo-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/campomourao-pr'},
    {link: '/passagem-de-onibus/feiradesantana-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/teresina-pi'},
    {link: '/passagem-de-onibus/feiradesantana-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/euclidesdacunha-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/campinas-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/pilaoarcado-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/luz-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/bocaiuva-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/araxa-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/frutal-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/osasco-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saojoaoevangelista-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/itabira-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/virginopolis-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/feiradesantana-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/feiradesantana-ba/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/teresina-pi'},
    {link: '/passagem-de-onibus/feiradesantana-ba/minasnovas-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/acucena-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/araci-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/picos-pi'},
    {link: '/passagem-de-onibus/feiradesantana-ba/casanova-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/casanova-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/feiradesantana-ba/remanso-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ribeiradoamparo-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/londrina-pr'},
    {link: '/passagem-de-onibus/feiradesantana-ba/salinas-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/teofilandia-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/almenara-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/tucano-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/tucano-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/tucano-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/tucano-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/cipo-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/patrocinio-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/aguaboa,mg-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/voltaredonda-rj'},
    {link: '/passagem-de-onibus/feiradesantana-ba/aracuai-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/garanhuns-pe'},
    {link: '/passagem-de-onibus/feiradesantana-ba/belohorizonte-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/corinto-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/campinas-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/olimpia-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/betim-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/paraopeba-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/santamariadosuacui-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/virgemdalapa-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/arapiraca-al'},
    {link: '/passagem-de-onibus/feiradesantana-ba/curvelo-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/barretos-sp'},
    {link: '/passagem-de-onibus/feiradesantana-ba/setelagoas-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/curitiba-pr'},
    {link: '/passagem-de-onibus/feiradesantana-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/patrocinio-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/goiania-go'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/luz-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/moema-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/ipameri-go'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/parademinas-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/rioparanaiba-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/carmodoparanaiba-mg/saogotardo-mg'},
    {link: '/passagem-de-onibus/tiangua-ce/camocim-ce'},
    {link: '/passagem-de-onibus/tiangua-ce/granja-ce'},
    {link: '/passagem-de-onibus/feiradesantana-ba/santamariadeitabira-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/capelinha-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/mossoro-rn'},
    {link: '/passagem-de-onibus/feiradesantana-ba/salinas-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/fozdoiguacu-pr'},
    {link: '/passagem-de-onibus/feiradesantana-ba/guanhaes-mg'},
    {link: '/passagem-de-onibus/feiradesantana-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/tiangua-ce/ipu-ce'},
    {link: '/passagem-de-onibus/tiangua-ce/novarussas-ce'},
    {link: '/passagem-de-onibus/tiangua-ce/ipueiras-ce'},
    {link: '/passagem-de-onibus/feiradesantana-ba/picos-pi'},
    {link: '/passagem-de-onibus/feiradesantana-ba/sobral-ce'},
    {link: '/passagem-de-onibus/patrocinio-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/patrocinio-mg/jequie-ba'},
    {link: '/passagem-de-onibus/patrocinio-mg/recife-pe'},
    {link: '/passagem-de-onibus/patrocinio-mg/maceio-al'},
    {link: '/passagem-de-onibus/engenheironavarro-mg/joaquimfelicio-mg'},
    {link: '/passagem-de-onibus/engenheironavarro-mg/buenopolis-mg'},
    {link: '/passagem-de-onibus/engenheironavarro-mg/curvelo-mg'},
    {link: '/passagem-de-onibus/patrocinio-mg/goiania-go'},
    {link: '/passagem-de-onibus/engenheironavarro-mg/augustodelima-mg'},
    {link: '/passagem-de-onibus/engenheironavarro-mg/corinto-mg'},
    {link: '/passagem-de-onibus/tiangua-ce/tamboril-ce'},
    {link: '/passagem-de-onibus/tiangua-ce/crateus-ce'},
    {link: '/passagem-de-onibus/engenheironavarro-mg/montesclaros-mg'},
    {link: '/passagem-de-onibus/patrocinio-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/patrocinio-mg/milagres,ba-ba'},
    {link: '/passagem-de-onibus/patrocinio-mg/ipameri-go'},
    {link: '/passagem-de-onibus/patrocinio-mg/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/patrocinio-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/patrocinio-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/saogotardo-mg/araxa-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/ibia-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/ibia-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/goiania-go'},
    {link: '/passagem-de-onibus/saogotardo-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/luz-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/araxa-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/bomdespacho,mg-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/patrocinio-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/patrocinio-mg/aracaju-se'},
    {link: '/passagem-de-onibus/saogotardo-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/ipameri-go'},
    {link: '/passagem-de-onibus/saogotardo-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/novaserrana-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/moema-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/arapua-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/ibia-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/ibia-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/piresdorio-go'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/jaragua-go'},
    {link: '/passagem-de-onibus/itumbiara-go/belohorizonte-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/araxa-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/palmas,to-to'},
    {link: '/passagem-de-onibus/itumbiara-go/cuiaba-mt'},
    {link: '/passagem-de-onibus/itumbiara-go/gurupi-to'},
    {link: '/passagem-de-onibus/itumbiara-go/betim-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/rondonopolis-mt'},
    {link: '/passagem-de-onibus/itumbiara-go/juizdefora-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/portonacional-to'},
    {link: '/passagem-de-onibus/itumbiara-go/alvorada-to'},
    {link: '/passagem-de-onibus/conselheiropena-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/conselheiropena-mg/joaomonlevade-mg'},
    {link: '/passagem-de-onibus/conselheiropena-mg/itueta-mg'},
    {link: '/passagem-de-onibus/conselheiropena-mg/coronelfabriciano-mg'},
    {link: '/passagem-de-onibus/conselheiropena-mg/saogoncalodorioabaixo-mg'},
    {link: '/passagem-de-onibus/conselheiropena-mg/periquito-mg'},
    {link: '/passagem-de-onibus/conselheiropena-mg/ipatinga-mg'},
    {link: '/passagem-de-onibus/cruzeirodafortaleza-mg/araxa-mg'},
    {link: '/passagem-de-onibus/cruzeirodafortaleza-mg/patosdeminas-mg'},
    {link: '/passagem-de-onibus/cruzeirodafortaleza-mg/uberaba-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/lagoaformosa-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/corregodanta-mg'},
    {link: '/passagem-de-onibus/saogotardo-mg/carmodoparanaiba-mg'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/palmas,to-to'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/ceres-go'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/gurupi-to'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/porangatu-go'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/portonacional-to'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/alvorada-to'},
    {link: '/passagem-de-onibus/rioclaro,sp-sp/uruacu-go'},
    {link: '/passagem-de-onibus/saogotardo-mg/parademinas-mg'},
];
export default gontijo;