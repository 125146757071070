import React, { Component } from 'react';
import { connect } from 'react-redux';

class LoadingAssinc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
        };
        this.interval = null;
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState(prevState => ({
                progress: (prevState.progress + 5) % 101,
            }));
        }, 100);
    }

    componentDidUpdate(prevProps) {
        if (this.props.loadingInteg !== prevProps.loadingInteg) {
          clearInterval(this.interval);
    
          if (this.props.loadingInteg) {
            this.interval = setInterval(() => {
              this.setState((prevState) => ({
                progress: (prevState.progress + 5) % 101,
              }));
            }, 100);
          }
        }
      }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { progress } = this.state;

        return (
            <div>
                {this.props.loadingInteg ?
                    <div className='loading-container'>
                        <div className="loading-bar-wrapper">
                            <progress className='loading-bar' value={progress} max="100" />
                        </div>
                    </div> : ''}
            </div>
        );
    }
}

function mapStateToProps ({ loadingInteg }) {
    return loadingInteg
  }

export default connect(mapStateToProps)(LoadingAssinc)