const topTrechos = [
    {
        idCidadeOrigem: 1,
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        idCidadeDestino: 2498,
        nomeCidadeDestinoComUF: 'Belo Horizonte-MG',
        nomeCidadeDestinoComUFNormalizado: 'belohorizonte-mg',
        ordem: 1
    },
    {
        idCidadeOrigem: 2498,
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        nomeCidadeOrigemComUFNormalizado: 'belohorizonte-mg',
        idCidadeDestino: 1,
        nomeCidadeDestinoComUF: 'Brasília-DF',
        nomeCidadeDestinoComUFNormalizado: 'brasilia-df',
        ordem: 2
    },
    {
        idCidadeOrigem: 2498,
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        nomeCidadeOrigemComUFNormalizado: 'belohorizonte-mg',
        idCidadeDestino: 126,
        nomeCidadeDestinoComUF: 'Goiânia-GO',
        nomeCidadeDestinoComUFNormalizado: 'goiania-go',
        ordem: 3
    },
    {
        idCidadeOrigem: 1,
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        idCidadeDestino: 4093,
        nomeCidadeDestinoComUF: 'Angra dos Reis-RJ',
        nomeCidadeDestinoComUFNormalizado: 'angradosreis-rj',
        ordem: 4
    },
    {
        idCidadeOrigem: 5848,
        nomeCidadeOrigemComUF: 'São Paulo-SP',
        nomeCidadeOrigemComUFNormalizado: 'saopaulo-sp',
        idCidadeDestino: 2818,
        nomeCidadeDestinoComUF: 'Ituiutaba-MG',
        nomeCidadeDestinoComUFNormalizado: 'ituiutaba-mg',
        ordem: 5
    },
    {
        idCidadeOrigem: 1,
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        idCidadeDestino: 2504,
        nomeCidadeDestinoComUF: 'Betim-MG',
        nomeCidadeDestinoComUFNormalizado: 'betim-mg',
        ordem: 6
    },
    {
        idCidadeOrigem: 1,
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        idCidadeDestino: 2983,
        nomeCidadeDestinoComUF: 'Paracatu-MG',
        nomeCidadeDestinoComUFNormalizado: 'paracatu-mg',
        ordem: 7
    },
    {
        idCidadeOrigem: 2818,
        nomeCidadeOrigemComUF: 'Ituiutaba-MG',
        nomeCidadeOrigemComUFNormalizado: 'ituiutaba-mg',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 8
    },
    {
        idCidadeOrigem: 1,
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        idCidadeDestino: 3246,
        nomeCidadeDestinoComUF: 'Três Marias-MG',
        nomeCidadeDestinoComUFNormalizado: 'tresmarias-mg',
        ordem: 9
    },
    {
        idCidadeOrigem: 2498,
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        nomeCidadeOrigemComUFNormalizado: 'belohorizonte-mg',
        idCidadeDestino: 4100,
        nomeCidadeDestinoComUF: 'Barra Mansa-RJ',
        nomeCidadeDestinoComUFNormalizado: 'barramansa-rj',
        ordem: 10
    },
    {
        idCidadeOrigem: 1,
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        idCidadeDestino: 3221,
        nomeCidadeDestinoComUF: 'Sete Lagoas-MG',
        nomeCidadeDestinoComUFNormalizado: 'setelagoas-mg',
        ordem: 11
    },
    {
        idCidadeOrigem: 2818,
        nomeCidadeOrigemComUF: 'Ituiutaba-MG',
        nomeCidadeOrigemComUFNormalizado: 'ituiutaba-mg',
        idCidadeDestino: 5842,
        nomeCidadeDestinoComUF: 'São José do Rio Preto-SP',
        nomeCidadeDestinoComUFNormalizado: 'saojosedoriopreto-sp',
        ordem: 12
    },
    {
        idCidadeOrigem: 2498,
        nomeCidadeOrigemComUF: 'Belo Horizonte-MG',
        nomeCidadeOrigemComUFNormalizado: 'belohorizonte-mg',
        idCidadeDestino: 4184,
        nomeCidadeDestinoComUF: 'Volta Redonda-RJ',
        nomeCidadeDestinoComUFNormalizado: 'voltaredonda-rj',
        ordem: 13
    },
    {
        idCidadeOrigem: 1,
        nomeCidadeOrigemComUF: 'Brasília-DF',
        nomeCidadeOrigemComUFNormalizado: 'brasilia-df',
        idCidadeDestino: 2843,
        nomeCidadeDestinoComUF: 'João Pinheiro-MG',
        nomeCidadeDestinoComUFNormalizado: 'joaopinheiro-mg',
        ordem: 14
    },
    {
        idCidadeOrigem: 4184,
        nomeCidadeOrigemComUF: 'Volta Redonda-RJ',
        nomeCidadeOrigemComUFNormalizado: 'voltaredonda-rj',
        idCidadeDestino: 3221,
        nomeCidadeDestinoComUF: 'Sete Lagoas-MG',
        nomeCidadeDestinoComUFNormalizado: 'setelagoas-mg',
        ordem: 15
    },
]

export default topTrechos;