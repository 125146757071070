const catedral = [

    {link: '/passagem-de-onibus/araguari-mg/osasco-sp'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/trindade-go'},
    {link: '/passagem-de-onibus/joaopessoa-pb/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/maceio-al/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/santana,ba-ba/maceio-al'},
    {link: '/passagem-de-onibus/trindade-go/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/barreiras-ba/trindade-go'},
    {link: '/passagem-de-onibus/corrente-pi/trindade-go'},
    {link: '/passagem-de-onibus/correntina-ba/joaopessoa-pb'},
    {link: '/passagem-de-onibus/correntina-ba/maceio-al'},
    {link: '/passagem-de-onibus/maceio-al/santana,ba-ba'},
    {link: '/passagem-de-onibus/arapiraca-al/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/arapiraca-al/formosa-go'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/trindade-go/barreiras-ba'},
    {link: '/passagem-de-onibus/trindade-go/corrente-pi'},
    {link: '/passagem-de-onibus/trindade-go/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/aracaju-se/santana,ba-ba'},
    {link: '/passagem-de-onibus/aracaju-se/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/santana,ba-ba/joaopessoa-pb'},
    {link: '/passagem-de-onibus/santana,ba-ba/aracaju-se'},
    {link: '/passagem-de-onibus/joaopessoa-pb/correntina-ba'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/maceio-al/correntina-ba'},
    {link: '/passagem-de-onibus/trindade-go/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/caruaru-pe'},
    {link: '/passagem-de-onibus/caruaru-pe/formosa-go'},
    {link: '/passagem-de-onibus/joaopessoa-pb/santana,ba-ba'},
    {link: '/passagem-de-onibus/riachaodasneves-ba/trindade-go'},
    {link: '/passagem-de-onibus/aracaju-se/correntina-ba'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/aracaju-se'},
    {link: '/passagem-de-onibus/caruaru-pe/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/arapiraca-al'},
    {link: '/passagem-de-onibus/barreiras-ba/arapiraca-al'},
    {link: '/passagem-de-onibus/itaberaba-ba/caruaru-pe'},
    {link: '/passagem-de-onibus/formosa-go/caruaru-pe'},
    {link: '/passagem-de-onibus/itaberaba-ba/arapiraca-al'},
    {link: '/passagem-de-onibus/correntina-ba/aracaju-se'},
    {link: '/passagem-de-onibus/formosa-go/arapiraca-al'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/trindade-go'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/joaopessoa-pb'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/maceio-al'},
    {link: '/passagem-de-onibus/ibotirama-ba/caruaru-pe'},
    {link: '/passagem-de-onibus/arapiraca-al/joaopessoa-pb'},
    {link: '/passagem-de-onibus/ibotirama-ba/arapiraca-al'},
    {link: '/passagem-de-onibus/feiradesantana-ba/arapiraca-al'},
    {link: '/passagem-de-onibus/seabra-ba/caruaru-pe'},
    {link: '/passagem-de-onibus/seabra-ba/arapiraca-al'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/maceio-al'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/paudosferros-rn'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/aracaju-se'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/joaopessoa-pb'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/recife-pe'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/natal-rn'},
    {link: '/passagem-de-onibus/caruaru-pe/joaopessoa-pb'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/apodi-rn'},
    {link: '/passagem-de-onibus/barreiras-ba/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/brejinhodenazare-to/brasilia-df'},
    {link: '/passagem-de-onibus/brejinhodenazare-to/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/jacobina-ba'},
    {link: '/passagem-de-onibus/aliancadotocantins-to/brasilia-df'},
    {link: '/passagem-de-onibus/aliancadotocantins-to/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/goiania-go/apodi-rn'},
    {link: '/passagem-de-onibus/apodi-rn/brejosanto-ce'},
    {link: '/passagem-de-onibus/apodi-rn/juazeiro-ba'},
    {link: '/passagem-de-onibus/apodi-rn/seabra-ba'},
    {link: '/passagem-de-onibus/goiania-go/juazeiro-ba'},
    {link: '/passagem-de-onibus/apodi-rn/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/apodi-rn/goiania-go'},
    {link: '/passagem-de-onibus/apodi-rn/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/apodi-rn/campinas-sp'},
    {link: '/passagem-de-onibus/apodi-rn/petrolina-pe'},
    {link: '/passagem-de-onibus/apodi-rn/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/apodi-rn/saopaulo-sp'},
    {link: '/passagem-de-onibus/apodi-rn/brasilia-df'},
    {link: '/passagem-de-onibus/apodi-rn/santana,ba-ba'},
    {link: '/passagem-de-onibus/apodi-rn/ibotirama-ba'},
    {link: '/passagem-de-onibus/apodi-rn/jacobina-ba'},
    {link: '/passagem-de-onibus/apodi-rn/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/goiania-go/jaborandi-ba'},
    {link: '/passagem-de-onibus/goiania-go/paudosferros-rn'},
    {link: '/passagem-de-onibus/goiania-go/santana,ba-ba'},
    {link: '/passagem-de-onibus/apodi-rn/salgueiro-pe'},
    {link: '/passagem-de-onibus/apodi-rn/correntina-ba'},
    {link: '/passagem-de-onibus/apodi-rn/formosa-go'},
    {link: '/passagem-de-onibus/apodi-rn/araguari-mg'},
    {link: '/passagem-de-onibus/apodi-rn/anapolis-go'},
    {link: '/passagem-de-onibus/apodi-rn/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/apodi-rn/uberaba-mg'},
    {link: '/passagem-de-onibus/apodi-rn/uberlandia-mg'},
    {link: '/passagem-de-onibus/apodi-rn/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/apodi-rn/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/goiania-go/cocos-ba'},
    {link: '/passagem-de-onibus/goiania-go/coribe-ba'},
    {link: '/passagem-de-onibus/salgueiro-pe/jacobina-ba'},
    {link: '/passagem-de-onibus/salgueiro-pe/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/salgueiro-pe/seabra-ba'},
    {link: '/passagem-de-onibus/candidosales-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/candidosales-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/candidosales-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/candidosales-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/candidosales-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/candidosales-ba/campinas-sp'},
    {link: '/passagem-de-onibus/candidosales-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/candidosales-ba/saopaulo-sp'},
    {link: '/passagem-de-onibus/candidosales-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/itaberaba-ba/araguari-mg'},
    {link: '/passagem-de-onibus/itaberaba-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/itaberaba-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/correntina-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/correntina-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/correntina-ba/trindade-go'},
    {link: '/passagem-de-onibus/correntina-ba/brejosanto-ce'},
    {link: '/passagem-de-onibus/corrente-pi/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/coribe-ba/goiania-go'},
    {link: '/passagem-de-onibus/formosadoriopreto-ba/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/figueiropolis-to/brasilia-df'},
    {link: '/passagem-de-onibus/salgueiro-pe/apodi-rn'},
    {link: '/passagem-de-onibus/salgueiro-pe/correntina-ba'},
    {link: '/passagem-de-onibus/salgueiro-pe/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/salgueiro-pe/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/salgueiro-pe/paudosferros-rn'},
    {link: '/passagem-de-onibus/salgueiro-pe/santana,ba-ba'},
    {link: '/passagem-de-onibus/campinorte-go/brasilia-df'},
    {link: '/passagem-de-onibus/campinorte-go/portonacional-to'},
    {link: '/passagem-de-onibus/correntina-ba/apodi-rn'},
    {link: '/passagem-de-onibus/correntina-ba/salgueiro-pe'},
    {link: '/passagem-de-onibus/correntina-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/correntina-ba/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/montesclaros-mg/salvador-ba'},
    {link: '/passagem-de-onibus/cocos-ba/goiania-go'},
    {link: '/passagem-de-onibus/campinorte-go/palmas,to-to'},
    {link: '/passagem-de-onibus/formosa-go/apodi-rn'},
    {link: '/passagem-de-onibus/formosa-go/joaopessoa-pb'},
    {link: '/passagem-de-onibus/formosa-go/recife-pe'},
    {link: '/passagem-de-onibus/formosa-go/natal-rn'},
    {link: '/passagem-de-onibus/formosa-go/maceio-al'},
    {link: '/passagem-de-onibus/itaberaba-ba/campinas-sp'},
    {link: '/passagem-de-onibus/itaberaba-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/montesclaros-mg/candidosales-ba'},
    {link: '/passagem-de-onibus/jequie-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/patosdeminas-mg/candidosales-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/jequie-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/campinas-sp'},
    {link: '/passagem-de-onibus/patosdeminas-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/patosdeminas-mg/salvador-ba'},
    {link: '/passagem-de-onibus/patosdeminas-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/joaopessoa-pb/uberlandia-mg'},
    {link: '/passagem-de-onibus/joaopessoa-pb/campinas-sp'},
    {link: '/passagem-de-onibus/joaopessoa-pb/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/joaopessoa-pb/arapiraca-al'},
    {link: '/passagem-de-onibus/formosa-go/aracaju-se'},
    {link: '/passagem-de-onibus/palmas,to-to/campinorte-go'},
    {link: '/passagem-de-onibus/palmas,to-to/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/araguari-mg/apodi-rn'},
    {link: '/passagem-de-onibus/anapolis-go/apodi-rn'},
    {link: '/passagem-de-onibus/anapolis-go/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/anapolis-go/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/anapolis-go/paudosferros-rn'},
    {link: '/passagem-de-onibus/araguari-mg/itaberaba-ba'},
    {link: '/passagem-de-onibus/araguari-mg/joaopessoa-pb'},
    {link: '/passagem-de-onibus/araguari-mg/recife-pe'},
    {link: '/passagem-de-onibus/araguari-mg/natal-rn'},
    {link: '/passagem-de-onibus/araguari-mg/maceio-al'},
    {link: '/passagem-de-onibus/araguari-mg/salvador-ba'},
    {link: '/passagem-de-onibus/araguari-mg/aracaju-se'},
    {link: '/passagem-de-onibus/araguari-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/joaopessoa-pb/caruaru-pe'},
    {link: '/passagem-de-onibus/anapolis-go/santana,ba-ba'},
    {link: '/passagem-de-onibus/anapolis-go/tabocasdobrejovelho-ba'},
    {link: '/passagem-de-onibus/anapolis-go/juazeiro-ba'},
    {link: '/passagem-de-onibus/osasco-sp/araguari-mg'},
    {link: '/passagem-de-onibus/joaopessoa-pb/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/joaopessoa-pb/formosa-go'},
    {link: '/passagem-de-onibus/joaopessoa-pb/araguari-mg'},
    {link: '/passagem-de-onibus/joaopessoa-pb/uberaba-mg'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/apodi-rn'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/salgueiro-pe'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/correntina-ba'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/jequie-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/jequie-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/jequie-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/jequie-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/jequie-ba/campinas-sp'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/petrolina-pe'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/paudosferros-rn'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/santana,ba-ba'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/ibotirama-ba'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/jacobina-ba'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/juazeiro-ba'},
    {link: '/passagem-de-onibus/juazeirodonorte-ce/seabra-ba'},
    {link: '/passagem-de-onibus/recife-pe/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/gurupi-to/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/recife-pe/formosa-go'},
    {link: '/passagem-de-onibus/recife-pe/araguari-mg'},
    {link: '/passagem-de-onibus/recife-pe/campinas-sp'},
    {link: '/passagem-de-onibus/recife-pe/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/regeneracao-pi'},
    {link: '/passagem-de-onibus/maceio-al/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/maceio-al/formosa-go'},
    {link: '/passagem-de-onibus/maceio-al/araguari-mg'},
    {link: '/passagem-de-onibus/maceio-al/campinas-sp'},
    {link: '/passagem-de-onibus/maceio-al/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/natal-rn/araguari-mg'},
    {link: '/passagem-de-onibus/natal-rn/uberaba-mg'},
    {link: '/passagem-de-onibus/natal-rn/uberlandia-mg'},
    {link: '/passagem-de-onibus/natal-rn/campinas-sp'},
    {link: '/passagem-de-onibus/natal-rn/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/jaborandi-ba/goiania-go'},
    {link: '/passagem-de-onibus/natal-rn/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/natal-rn/formosa-go'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/seabra-ba'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/goiania-go'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/apodi-rn'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/anapolis-go'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/senhordobonfim-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/apodi-rn'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/correntina-ba'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/anapolis-go'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/paudosferros-rn'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/santana,ba-ba'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/jacobina-ba'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/cajazeiras,pb-pb/juazeiro-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/apodi-rn'},
    {link: '/passagem-de-onibus/uberaba-mg/arapiraca-al'},
    {link: '/passagem-de-onibus/uberlandia-mg/apodi-rn'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/apodi-rn'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/salgueiro-pe'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/uberlandia-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/salvador-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/regeneracao-pi'},
    {link: '/passagem-de-onibus/uberlandia-mg/floriano-pi'},
    {link: '/passagem-de-onibus/uberlandia-mg/arapiraca-al'},
    {link: '/passagem-de-onibus/uberaba-mg/candidosales-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/itaberaba-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/joaopessoa-pb'},
    {link: '/passagem-de-onibus/uberaba-mg/jequie-ba'},
    {link: '/passagem-de-onibus/uberaba-mg/natal-rn'},
    {link: '/passagem-de-onibus/uberaba-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/candidosales-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/itaberaba-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/joaopessoa-pb'},
    {link: '/passagem-de-onibus/uberlandia-mg/jequie-ba'},
    {link: '/passagem-de-onibus/uberlandia-mg/natal-rn'},
    {link: '/passagem-de-onibus/uberlandia-mg/teresina-pi'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/campinas-sp'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/estancia-se'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/brejosanto-ce'},
    {link: '/passagem-de-onibus/teresina-pi/uberlandia-mg'},
    {link: '/passagem-de-onibus/pocoes-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/pocoes-ba/uberaba-mg'},
    {link: '/passagem-de-onibus/pocoes-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/petrolina-pe/apodi-rn'},
    {link: '/passagem-de-onibus/petrolina-pe/correntina-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/petrolina-pe/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/paudosferros-rn'},
    {link: '/passagem-de-onibus/petrolina-pe/santana,ba-ba'},
    {link: '/passagem-de-onibus/campinas-sp/jequie-ba'},
    {link: '/passagem-de-onibus/campinas-sp/patosdeminas-mg'},
    {link: '/passagem-de-onibus/campinas-sp/recife-pe'},
    {link: '/passagem-de-onibus/campinas-sp/natal-rn'},
    {link: '/passagem-de-onibus/campinas-sp/maceio-al'},
    {link: '/passagem-de-onibus/campinas-sp/franciscosa-mg'},
    {link: '/passagem-de-onibus/campinas-sp/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/campinas-sp/apodi-rn'},
    {link: '/passagem-de-onibus/campinas-sp/candidosales-ba'},
    {link: '/passagem-de-onibus/campinas-sp/itaberaba-ba'},
    {link: '/passagem-de-onibus/campinas-sp/joaopessoa-pb'},
    {link: '/passagem-de-onibus/campinas-sp/salinas-mg'},
    {link: '/passagem-de-onibus/campinas-sp/aracaju-se'},
    {link: '/passagem-de-onibus/campinas-sp/arapiraca-al'},
    {link: '/passagem-de-onibus/franciscosa-mg/jequie-ba'},
    {link: '/passagem-de-onibus/franciscosa-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/franciscosa-mg/campinas-sp'},
    {link: '/passagem-de-onibus/franciscosa-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/franciscosa-mg/salvador-ba'},
    {link: '/passagem-de-onibus/franciscosa-mg/feiradesantana-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/pocoes-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/franciscosa-mg/candidosales-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/salvador-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/salvador-ba/salinas-mg'},
    {link: '/passagem-de-onibus/salvador-ba/pirapora-mg'},
    {link: '/passagem-de-onibus/riachaodasneves-ba/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/petrolina-pe/jacobina-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/petrolina-pe/seabra-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/apodi-rn'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/candidosales-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/itaberaba-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/ibotirama-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/jacobina-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/brejosanto-ce'},
    {link: '/passagem-de-onibus/paudosferros-rn/juazeiro-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/seabra-ba'},
    {link: '/passagem-de-onibus/salvador-ba/montesclaros-mg'},
    {link: '/passagem-de-onibus/regeneracao-pi/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/regeneracao-pi/uberlandia-mg'},
    {link: '/passagem-de-onibus/regeneracao-pi/brasilia-df'},
    {link: '/passagem-de-onibus/saopaulo-sp/apodi-rn'},
    {link: '/passagem-de-onibus/saopaulo-sp/candidosales-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/salvador-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/salinas-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/aracaju-se'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/arapiraca-al'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/feiradesantana-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/paudosferros-rn/goiania-go'},
    {link: '/passagem-de-onibus/salvador-ba/araguari-mg'},
    {link: '/passagem-de-onibus/salvador-ba/patosdeminas-mg'},
    {link: '/passagem-de-onibus/salvador-ba/uberlandia-mg'},
    {link: '/passagem-de-onibus/salvador-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/paudosferros-rn/salgueiro-pe'},
    {link: '/passagem-de-onibus/paudosferros-rn/correntina-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/anapolis-go'},
    {link: '/passagem-de-onibus/paudosferros-rn/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/paudosferros-rn/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/paudosferros-rn/senhordobonfim-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/paudosferros-rn/petrolina-pe'},
    {link: '/passagem-de-onibus/paudosferros-rn/brasilia-df'},
    {link: '/passagem-de-onibus/paudosferros-rn/santana,ba-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/joaopessoa-pb'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/patosdeminas-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/recife-pe'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/natal-rn'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/maceio-al'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/pocoes-ba'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/franciscosa-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/patosdeminas-mg'},
    {link: '/passagem-de-onibus/salinas-mg/campinas-sp'},
    {link: '/passagem-de-onibus/salinas-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/salinas-mg/salvador-ba'},
    {link: '/passagem-de-onibus/santana,ba-ba/goiania-go'},
    {link: '/passagem-de-onibus/santana,ba-ba/apodi-rn'},
    {link: '/passagem-de-onibus/santana,ba-ba/salgueiro-pe'},
    {link: '/passagem-de-onibus/brasilia-df/juazeiro-ba'},
    {link: '/passagem-de-onibus/brasilia-df/regeneracao-pi'},
    {link: '/passagem-de-onibus/salinas-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/santana,ba-ba/anapolis-go'},
    {link: '/passagem-de-onibus/santana,ba-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/santana,ba-ba/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/santana,ba-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/santana,ba-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/santana,ba-ba/trindade-go'},
    {link: '/passagem-de-onibus/santana,ba-ba/brejosanto-ce'},
    {link: '/passagem-de-onibus/tabocasdobrejovelho-ba/anapolis-go'},
    {link: '/passagem-de-onibus/brasilia-df/paudosferros-rn'},
    {link: '/passagem-de-onibus/brasilia-df/regeneracao-pi'},
    {link: '/passagem-de-onibus/brasilia-df/talisma-to'},
    {link: '/passagem-de-onibus/brasilia-df/jacobina-ba'},
    {link: '/passagem-de-onibus/brasilia-df/trindade-go'},
    {link: '/passagem-de-onibus/brasilia-df/brejinhodenazare-to'},
    {link: '/passagem-de-onibus/brasilia-df/campinorte-go'},
    {link: '/passagem-de-onibus/brasilia-df/figueiropolis-to'},
    {link: '/passagem-de-onibus/brasilia-df/aliancadotocantins-to'},
    {link: '/passagem-de-onibus/brasilia-df/apodi-rn'},
    {link: '/passagem-de-onibus/floriano-pi/uberlandia-mg'},
    {link: '/passagem-de-onibus/trindade-go/correntina-ba'},
    {link: '/passagem-de-onibus/trindade-go/brasilia-df'},
    {link: '/passagem-de-onibus/trindade-go/santana,ba-ba'},
    {link: '/passagem-de-onibus/trindade-go/tabocasdobrejovelho-ba'},
    {link: '/passagem-de-onibus/trindade-go/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/aliancadotocantins-to'},
    {link: '/passagem-de-onibus/talisma-to/brasilia-df'},
    {link: '/passagem-de-onibus/ibotirama-ba/apodi-rn'},
    {link: '/passagem-de-onibus/tabocasdobrejovelho-ba/trindade-go'},
    {link: '/passagem-de-onibus/aracaju-se/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/aracaju-se/formosa-go'},
    {link: '/passagem-de-onibus/aracaju-se/araguari-mg'},
    {link: '/passagem-de-onibus/ibotirama-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/ibotirama-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/jacobina-ba/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/aracaju-se/campinas-sp'},
    {link: '/passagem-de-onibus/aracaju-se/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/jacobina-ba/apodi-rn'},
    {link: '/passagem-de-onibus/jacobina-ba/salgueiro-pe'},
    {link: '/passagem-de-onibus/jacobina-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/jacobina-ba/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/jacobina-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/jacobina-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/jacobina-ba/brasilia-df'},
    {link: '/passagem-de-onibus/jacobina-ba/brejosanto-ce'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/alvorada-to'},
    {link: '/passagem-de-onibus/portonacional-to/campinorte-go'},
    {link: '/passagem-de-onibus/portonacional-to/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/arapiraca-al/uberaba-mg'},
    {link: '/passagem-de-onibus/arapiraca-al/uberlandia-mg'},
    {link: '/passagem-de-onibus/arapiraca-al/campinas-sp'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/barreiras-ba'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/brejinhodenazare-to'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/corrente-pi'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/formosadoriopreto-ba'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/palmas,to-to'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/gurupi-to'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/riachaodasneves-ba'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/portonacional-to'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/seabra-ba/apodi-rn'},
    {link: '/passagem-de-onibus/seabra-ba/salgueiro-pe'},
    {link: '/passagem-de-onibus/arapiraca-al/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/alvorada-to/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/apodi-rn'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/salgueiro-pe'},
    {link: '/passagem-de-onibus/brejosanto-ce/santana,ba-ba'},
    {link: '/passagem-de-onibus/brejosanto-ce/jacobina-ba'},
    {link: '/passagem-de-onibus/brejosanto-ce/santamariadavitoria-ba'},
    {link: '/passagem-de-onibus/brejosanto-ce/juazeiro-ba'},
    {link: '/passagem-de-onibus/brejosanto-ce/seabra-ba'},
    {link: '/passagem-de-onibus/juazeiro-ba/goiania-go'},
    {link: '/passagem-de-onibus/juazeiro-ba/apodi-rn'},
    {link: '/passagem-de-onibus/juazeiro-ba/anapolis-go'},
    {link: '/passagem-de-onibus/juazeiro-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/juazeiro-ba/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/juazeiro-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/juazeiro-ba/brasilia-df'},
    {link: '/passagem-de-onibus/juazeiro-ba/brejosanto-ce'},
    {link: '/passagem-de-onibus/feiradesantana-ba/araguari-mg'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/trindade-go'},
    {link: '/passagem-de-onibus/santamariadavitoria-ba/brejosanto-ce'},
    {link: '/passagem-de-onibus/pirapora-mg/candidosales-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/jequie-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/pocoes-ba'},
    {link: '/passagem-de-onibus/pirapora-mg/salvador-ba'},
    {link: '/passagem-de-onibus/brejosanto-ce/apodi-rn'},
    {link: '/passagem-de-onibus/brejosanto-ce/correntina-ba'},
    {link: '/passagem-de-onibus/brejosanto-ce/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/brejosanto-ce/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/brejosanto-ce/paudosferros-rn'},
    {link: '/passagem-de-onibus/feiradesantana-ba/franciscosa-mg'},
    {link: '/passagem-de-onibus/seabra-ba/juazeirodonorte-ce'},
    {link: '/passagem-de-onibus/seabra-ba/cajazeiras,pb-pb'},
    {link: '/passagem-de-onibus/seabra-ba/petrolina-pe'},
    {link: '/passagem-de-onibus/seabra-ba/paudosferros-rn'},
    {link: '/passagem-de-onibus/seabra-ba/brejosanto-ce'},
];
export default catedral;