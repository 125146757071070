import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import {connect} from 'react-redux'
// CORE UI
import '../node_modules/jquery/dist/jquery'
import '../node_modules/popper.js/dist/umd/popper.min'
import '../node_modules/bootstrap/dist/js/bootstrap.min'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/perfect-scrollbar/dist/perfect-scrollbar'
import '../node_modules/@coreui/coreui/dist/js/coreui.min'
import '../node_modules/@coreui/coreui/dist/css/coreui.min.css'
import './estilo/comum/css/App.css'
import './estilo/comum/css/trecho.css'
import '../node_modules/material-design-icons/iconfont/material-icons.css';

import '../node_modules/react-s-alert/dist/s-alert-default.css';
import '../node_modules/react-s-alert/dist/s-alert-css-effects/slide.css';


import Loading from './componentes/inicio/Loading'

import BarraNavegacao from './componentes/layout/BarraNavegacao';
import Footer from './componentes/layout/Footer';
import AppRoutes from './routes/AppRoutes';
import { isAmbienteProd, recuperarConfEmpresa } from './util/applicationContext';
import Portal from '@material-ui/core/Portal'
import MessagesBar from './componentes/mensagem/MessagesBar';
import Modal from './componentes/mensagem/MessageModal';
import Cookies from './componentes/Cookies';
import Head from './componentes/Head';
import TagManager from 'react-gtm-module';
import ModalPromocao from './componentes/layout/ModalPromocao';

import('./estilo/' + recuperarConfEmpresa().pastaEstilo + '/css/default.css');
import('./estilo/comum/css/estilo-comum.css')

export const App = () => {
    recuperarConfEmpresa().idsTagManager.map(it => TagManager.initialize({
        gtmId: it
    } ));
    return <BrowserRouter>
        <React.Fragment>
            <Head/>
            <Loading/>
            <BarraNavegacao/>
            <AppRoutes/>
            <Footer/>
            <Portal>
                <Cookies/>
                <MessagesBar/>
                <Modal/>
                {isAmbienteProd() && recuperarConfEmpresa().exibePopUpPromocao !== false ? <ModalPromocao /> : '' }
            </Portal>
        </React.Fragment>
    </BrowserRouter>
};

export default connect()(App)
