/* eslint-disable react/no-unknown-property */
import React from 'react'
import Button from '@material-ui/core/Button';

const NossaFrota = () => (
    <div id="NossaFrota">
        <div className="container">
            <div>
                <h1 id='nossa-frota' className="loginHeader">Nossa Frota</h1>
                <div className="divider"></div>
            </div>
            <div className="row col-12 col-sm-12 col-md-12">
                <div className="card card-sub">
                    <div className="card-body">
                        <p className="card-text texto">Para você chegar com conforto e segurança ao seu destino, a nossa frota é composta por modelos de alto nível de qualidade e eficiência. Aliando sofisticação, conforto e tecnologia nossos ônibus oferecem amplo espaço interno e muitas comodidades.</p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="card col-12 col-sm-12 col-md-5">
                    <div className="card-body">
                        <h5 className="card-title titulo">Poltronas Anatômicas</h5>
                        <p className="card-text texto1">Nossa frota possui poltronas anatômicas, com reclinação de até 65º, equipadas com cinto de segurança e revestimento de alta qualidade.</p>
                    </div>
                </div>
                <div className="card col-12 col-sm-12 col-md-5">
                    <div className="card-body">
                        <h5 className="card-title titulo">Ar-condicionado</h5>
                        <p className="card-text texto1">Para o seu maior conforto, nossos ônibus possuem sistema de ar-condicionado ecológico com tecnologia sustentável que reduz a quantidade de energia e ruídos..</p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="card col-12 col-sm-12 col-md-5">
                    <div className="card-body">
                        <h5 className="card-title titulo">Apoio para as pernas</h5>
                        <p className="card-text texto1">Atendendo as normas nacionais de segurança e conforto, nossas poltronas possuem encosto de cabeça de viscoelástico e apoio ergonômico para as pernas..</p>
                    </div>
                </div>
                <div className="card col-12 col-sm-12 col-md-5">
                    <div className="card-body">
                        <h5 className="card-title titulo">Bagageiro de Mão</h5>
                        <p className="card-text texto1">Oferecendo mais espaço de acomodação e segurança para o transporte de bagagens, nossos ônibus têm bagageiro com capacidade de até 20,7m³.</p>
                    </div>
                </div>
            </div>
            <div className="row col-12 col-sm-12 col-md-12">
                <div className="card card-sub">
                    <div className="card-body">
                        <h2 className="titulo conforto">Conforto e Segurança</h2>
                        <p className="card-text texto">Para você chegar com conforto e segurança ao seu destino, a nossa frota é composta por modelos de alto nível de qualidade e eficiência. Aliando sofisticação, conforto e tecnologia nossos ônibus oferecem amplo espaço interno e muitas comodidades.</p>
                    </div>
                    <div id='contrateAgora' className="card-body">
                        <h5 className="card-title titulo">Contrate Agora</h5>
                        <p className="card-text texto">Viva uma aventura única em ônibus personalizados pelo Brasil e América do Sul! Aluguel, fretes e turismo de ônibus – tudo em um só lugar. Estamos prontos para planejar sua viagem perfeita. Converse conosco via WhatsApp: +55 62 98330-9166 e garanta a melhor cotação. Vamos nessa!</p>
                        <div className='card-body'>
                            <Button id='buttonCotacaoFrete' variant="primary" href="https://forms.gle/d4XVPn7vGwusTstt9" target="_blank" rel="noopener noreferrer">
                                <span id='fraseErroWhats'>
                                    Faça sua cotação aqui!
                                    <svg id='iconCotacaoFrete' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-text" viewBox="0 0 16 16">
                                        <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z" />
                                        <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </span>
                            </Button>
                            <Button id='buttonPagErroWhats' variant="primary" href="https://wa.me/5562983309166" target="_blank" rel="noopener noreferrer">
                                <span id='fraseErroWhats'>
                                    Iniciar conversa WhatsApp
                                    <i id='iconWhatsErro' className='fab fa-whatsapp'></i>
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <video className="w-100 videosInicial" title="Background" autoPlay={false} loop={false} controls>
                        <source src="https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/amarelinho-higienizacao.mp4" type="video/mp4" />
                        <track kind="captions" label="Legendas" src="legendas.vtt" />
                    </video>
                </div>
            </div>
        </div>
    </div>
)

export default NossaFrota