import React from 'react';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

const ConteudoQuemSomosAD = () => (
  <div className="page-contato" style={{fontSize: 16, backgroundColor: '#ffffff'}}>
    <section className="gv-section">
      <div className="section-content">
        <h2 className="gv-title">O Brasil Passagens</h2>
        <p className="txt-item">Bem-vindo à Brasil Passagens, o seu portal dedicado à facilitação de viagens terrestres em todo o território brasileiro. Nós entendemos que a comodidade e a acessibilidade são essenciais quando se trata de escolher o seu meio de transporte. Aqui, gostaríamos de compartilhar nossa história, missão, valores e a tecnologia que impulsiona nossa plataforma voltada para passagens de ônibus.</p>
        <h2 className="gv-title" style={{ marginTop: 24 }}>Nossa Missão:</h2>
        <p className="txt-item">Na Brasil Passagens, nossa missão é simplificar o processo de reserva de passagens de ônibus, tornando-o mais acessível, eficiente e confiável para todos os brasileiros. Acreditamos que viajar de ônibus deve ser uma experiência conveniente e agradável, e trabalhamos incansavelmente para tornar isso uma realidade para nossos clientes. Estamos comprometidos em oferecer uma ampla gama de opções de itinerários e horários, garantindo que você encontre a melhor rota para atender às suas necessidades.</p>
        <h2 className="gv-title" style={{ marginTop: 24 }}>Nossos Valores:</h2>
        <p className="txt-item"><strong>1.  Excelência:</strong> Buscamos constantemente a excelência em tudo o que fazemos. Desde a seleção de rotas até a qualidade do atendimento ao cliente, estamos comprometidos com a qualidade em todos os aspectos.</p>
        <p className="txt-item"><strong>2.  Transparência:</strong> Valorizamos a transparência em nossas operações. Apresentamos informações claras sobre horários, preços e políticas para que nossos clientes possam tomar decisões informadas.</p>
        <p className="txt-item"><strong>3.  Atendimento ao Cliente:</strong> A satisfação do cliente é nossa prioridade número um. Nossa equipe de atendimento ao cliente está sempre à disposição para ajudar e garantir que todas as suas perguntas sejam respondidas.</p>
        <p className="txt-item"><strong>4.  Inovação:</strong> A tecnologia é a base de nossa plataforma. Estamos constantemente inovando e adotando as últimas tendências tecnológicas para tornar a reserva de passagens de ônibus mais fácil e conveniente.</p>
        <p className="txt-item"><strong>5.  Sustentabilidade:</strong> Preocupamo-nos com o meio ambiente e buscamos promover práticas sustentáveis na indústria de transporte terrestre. Estamos comprometidos em reduzir nosso impacto ambiental e apoiar iniciativas de conservação.</p>
        <h2 className="gv-title" style={{ marginTop: 24 }}>Tecnologia Avançada:</h2>
        <p className="txt-item">A tecnologia é fundamental para a Brasil Passagens. Nossa plataforma utiliza algoritmos avançados para encontrar as melhores opções de itinerários e preços, garantindo que nossos clientes obtenham passagens de ônibus convenientes e econômicas. Além disso, investimos constantemente em melhorias tecnológicas para tornar a reserva de passagens de ônibus ainda mais rápida e simples.</p>
        <p className="txt-item">Com uma ampla cobertura em todo o Brasil e uma equipe dedicada, estamos comprometidos em tornar sua experiência de viagem terrestre tranquila e memorável. Seja para viagens de curta ou longa distância, a Brasil Passagens está aqui para atender a todas as suas necessidades de transporte terrestre. Obrigado por escolher a Brasil Passagens como seu parceiro de viagem confiável. Juntos, podemos explorar as belezas do Brasil da maneira mais conveniente e acessível possível!</p>
        <section className="gv-cards" style={{ marginTop: 50 }}>
          <div className="gv-cards-wrapper">
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <DevicesOutlinedIcon className="box-item-icon"></DevicesOutlinedIcon>
                  <p>Tecnologia</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Descomplicamos o jeito antigo e complicado de comprar uma passagem!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <EmojiObjectsOutlinedIcon className="box-item-icon"></EmojiObjectsOutlinedIcon>
                  <p>Inovação</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Inovamos em um mercado promissor. Tecnologia simples, aplicada e segura para você, sua família e amigos.</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <PeopleAltOutlinedIcon className="box-item-icon"></PeopleAltOutlinedIcon>
                  <p>Relacionamento</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Pensamos em você muito mais que um simples cliente, temos você como parte de nossa empresa. Sem você não teríamos motivos para estar aqui!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <TrendingUpOutlinedIcon className="box-item-icon"></TrendingUpOutlinedIcon>
                  <p>Agilidade</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Rápido e descomplicado. Clicou, comprou, viajou!</p>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
)

export default ConteudoQuemSomosAD

