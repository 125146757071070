const cidadeDoSol = [

    {link: '/passagem-de-onibus/baixao-ba/santaines,ba-ba'},
    {link: '/passagem-de-onibus/baixao-ba/jequie-ba'},
    {link: '/passagem-de-onibus/baixao-ba/jaguaquara-ba'},
    {link: '/passagem-de-onibus/aracas-ba/baixao-ba'},
    {link: '/passagem-de-onibus/aracas-ba/jequie-ba'},
    {link: '/passagem-de-onibus/aracas-ba/itaquara-ba'},
    {link: '/passagem-de-onibus/aracas-ba/jaguaquara-ba'},
    {link: '/passagem-de-onibus/aracas-ba/santaines,ba-ba'},
    {link: '/passagem-de-onibus/itaquara-ba/cravolandia-ba'},
    {link: '/passagem-de-onibus/itaquara-ba/jaguaquara-ba'},
    {link: '/passagem-de-onibus/itaquara-ba/santaines,ba-ba'},
    {link: '/passagem-de-onibus/itaquara-ba/aracas-ba'},
    {link: '/passagem-de-onibus/itaquara-ba/baixao-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/camamu-ba'},
    {link: '/passagem-de-onibus/jaguaquara-ba/baixao-ba'},
    {link: '/passagem-de-onibus/camamu-ba/aratuipe-ba'},
    {link: '/passagem-de-onibus/cravolandia-ba/jaguaquara-ba'},
    {link: '/passagem-de-onibus/cravolandia-ba/aracas-ba'},
    {link: '/passagem-de-onibus/cravolandia-ba/jequie-ba'},
    {link: '/passagem-de-onibus/cravolandia-ba/itaquara-ba'},
    {link: '/passagem-de-onibus/munizferreira-ba/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/ubata-ba/travessao-ba'},
    {link: '/passagem-de-onibus/baixao-ba/aracas-ba'},
    {link: '/passagem-de-onibus/itaquara-ba/jequie-ba'},
    {link: '/passagem-de-onibus/cravolandia-ba/baixao-ba'},
    {link: '/passagem-de-onibus/cravolandia-ba/santaines,ba-ba'},
    {link: '/passagem-de-onibus/jaguaquara-ba/cravolandia-ba'},
    {link: '/passagem-de-onibus/santaines,ba-ba/aracas-ba'},
    {link: '/passagem-de-onibus/santaines,ba-ba/cravolandia-ba'},
    {link: '/passagem-de-onibus/jequie-ba/cravolandia-ba'},
    {link: '/passagem-de-onibus/itacare-ba/cachoeira-ba'},
    {link: '/passagem-de-onibus/cabucu-ba/salvador-ba'},
    {link: '/passagem-de-onibus/jaguaquara-ba/jequie-ba'},
    {link: '/passagem-de-onibus/santaines,ba-ba/jequie-ba'},
    {link: '/passagem-de-onibus/santaines,ba-ba/jaguaquara-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/cruzdasalmas-ba/salvador-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/nilopecanha-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/munizferreira-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/itubera-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/camamu-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/pinare-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/salinasdamargarida-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/saomigueldasmatas-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/tapuia-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/travessao-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/ubata-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/valenca-rj'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/varzedo-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/igrapiuna-ba'},
    {link: '/passagem-de-onibus/barradorocha-ba/jequie-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/acarai-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/amargosa-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/aratuipe-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/barradorocha-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/jequie-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/itacare-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/jaguaripe-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/jaguaripe-ba'},
    {link: '/passagem-de-onibus/bomdespacho,ba-ba/jaguaquara-ba'},
    {link: '/passagem-de-onibus/amargosa-ba/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/amargosa-ba/varzedo-ba'},
    {link: '/passagem-de-onibus/andarai-ba/argoim-ba'},
    {link: '/passagem-de-onibus/andarai-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/andarai-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/andarai-ba/santoestevao-ba'},
    {link: '/passagem-de-onibus/andarai-ba/paraguassu-ba'},
    {link: '/passagem-de-onibus/andarai-ba/salvador-ba'},
    {link: '/passagem-de-onibus/andarai-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/bravo-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/bravo-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/bravo-ba/ipira-ba'},
    {link: '/passagem-de-onibus/bravo-ba/macajuba-ba'},
    {link: '/passagem-de-onibus/bravo-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/bravo-ba/salvador-ba'},
    {link: '/passagem-de-onibus/bravo-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/bravo-ba/serrolandia-ba'},
    {link: '/passagem-de-onibus/bravo-ba/varzeadopoco-ba'},
    {link: '/passagem-de-onibus/bravo-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ameliarodrigues-ba/salvador-ba'},
    {link: '/passagem-de-onibus/ameliarodrigues-ba/camacari-ba'},
    {link: '/passagem-de-onibus/aratuipe-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/aratuipe-ba/nazare-ba'},
    {link: '/passagem-de-onibus/catu-ba/pojuca-ba'},
    {link: '/passagem-de-onibus/catu-ba/salvador-ba'},
    {link: '/passagem-de-onibus/bravo-ba/mairi-ba'},
    {link: '/passagem-de-onibus/barradorocha-ba/ilheus-ba'},
    {link: '/passagem-de-onibus/barradorocha-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/barradorocha-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/barradorocha-ba/urucuca-ba'},
    {link: '/passagem-de-onibus/barradorocha-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/barradorocha-ba/olivenca-ba'},
    {link: '/passagem-de-onibus/barradorocha-ba/una-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/argoim-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/argoim-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/argoim-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/amargosa-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/amargosa-ba/nazare-ba'},
    {link: '/passagem-de-onibus/amargosa-ba/munizferreira-ba'},
    {link: '/passagem-de-onibus/amargosa-ba/saomigueldasmatas-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/ipira-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/irece-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/americadourada-ba/salvador-ba'},
    {link: '/passagem-de-onibus/baixagrande-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/baixagrande-ba/bravo-ba'},
    {link: '/passagem-de-onibus/baixagrande-ba/ipira-ba'},
    {link: '/passagem-de-onibus/baixagrande-ba/macajuba-ba'},
    {link: '/passagem-de-onibus/baixagrande-ba/salvador-ba'},
    {link: '/passagem-de-onibus/baixagrande-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/baixagrande-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/ipira-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/santoestevao-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/mucuge-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/paraguassu-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/salvador-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/utinga-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/wagner-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/salvador-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/gandu-ba/algodao-ba'},
    {link: '/passagem-de-onibus/gandu-ba/jequie-ba'},
    {link: '/passagem-de-onibus/gandu-ba/itamari-ba'},
    {link: '/passagem-de-onibus/gandu-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/gandu-ba/ibirataia-ba'},
    {link: '/passagem-de-onibus/gandu-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/gandu-ba/itubera-ba'},
    {link: '/passagem-de-onibus/gandu-ba/piraidonorte-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/bomjesusdospobres-ba/acupe-ba'},
    {link: '/passagem-de-onibus/bomjesusdospobres-ba/cabucu-ba'},
    {link: '/passagem-de-onibus/bomjesusdospobres-ba/salvador-ba'},
    {link: '/passagem-de-onibus/bomjesusdospobres-ba/saubara-ba'},
    {link: '/passagem-de-onibus/bomjesusdospobres-ba/santoamaro-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/argoim-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/andarai-ba'},
    {link: '/passagem-de-onibus/itaberaba-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/cachoeira-ba/salvador-ba'},
    {link: '/passagem-de-onibus/cachoeira-ba/santoamaro-ba'},
    {link: '/passagem-de-onibus/cachoeira-ba/saofelix-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/ipira-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/irece-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/barradomendes-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/olindina-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/lagarto-se'},
    {link: '/passagem-de-onibus/biritinga-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/biritinga-ba/aracaju-se'},
    {link: '/passagem-de-onibus/biritinga-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/biritinga-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/coracaodemaria-ba/irara-ba'},
    {link: '/passagem-de-onibus/coracaodemaria-ba/salvador-ba'},
    {link: '/passagem-de-onibus/cachoeira-ba/muritiba-ba'},
    {link: '/passagem-de-onibus/cachoeira-ba/murutuba-ba'},
    {link: '/passagem-de-onibus/candeias,ba-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/coracaodemaria-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/coracaodemaria-ba/conceicaodojacuipe-ba'},
    {link: '/passagem-de-onibus/central-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/nazare-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/nazare-ba/itacare-ba'},
    {link: '/passagem-de-onibus/nazare-ba/nilopecanha-ba'},
    {link: '/passagem-de-onibus/nazare-ba/itubera-ba'},
    {link: '/passagem-de-onibus/nazare-ba/camamu-ba'},
    {link: '/passagem-de-onibus/nazare-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/nazare-ba/valenca-rj'},
    {link: '/passagem-de-onibus/nazare-ba/igrapiuna-ba'},
    {link: '/passagem-de-onibus/jequie-ba/aracas-ba'},
    {link: '/passagem-de-onibus/ilheus-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/ilheus-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/ilheus-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/ilheus-ba/ubata-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/ipira-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/irece-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/salvador-ba'},
    {link: '/passagem-de-onibus/ibipeba-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ibicoara-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/ibicoara-ba/mucuge-ba'},
    {link: '/passagem-de-onibus/jequie-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/jequie-ba/travessao-ba'},
    {link: '/passagem-de-onibus/jequie-ba/valenca-rj'},
    {link: '/passagem-de-onibus/jequie-ba/una-ba'},
    {link: '/passagem-de-onibus/jequie-ba/igrapiuna-ba'},
    {link: '/passagem-de-onibus/ilheus-ba/barradorocha-ba'},
    {link: '/passagem-de-onibus/ilheus-ba/jequie-ba'},
    {link: '/passagem-de-onibus/ilheus-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/central-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/central-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/central-ba/ipira-ba'},
    {link: '/passagem-de-onibus/central-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/central-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/central-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/central-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/central-ba/salvador-ba'},
    {link: '/passagem-de-onibus/jequie-ba/pocoes-ba'},
    {link: '/passagem-de-onibus/jequie-ba/manoelvitorino-ba'},
    {link: '/passagem-de-onibus/jequie-ba/itubera-ba'},
    {link: '/passagem-de-onibus/jequie-ba/olivenca-ba'},
    {link: '/passagem-de-onibus/jequie-ba/camamu-ba'},
    {link: '/passagem-de-onibus/jequie-ba/saquaira-ba'},
    {link: '/passagem-de-onibus/jequie-ba/santaines,ba-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/capimgrosso-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/jequie-ba/algodao-ba'},
    {link: '/passagem-de-onibus/jequie-ba/baixao-ba'},
    {link: '/passagem-de-onibus/jequie-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/jequie-ba/boanova-ba'},
    {link: '/passagem-de-onibus/jequie-ba/gandu-ba'},
    {link: '/passagem-de-onibus/jequie-ba/comandatuba-ba'},
    {link: '/passagem-de-onibus/jequie-ba/nazare-ba'},
    {link: '/passagem-de-onibus/jequie-ba/ilheus-ba'},
    {link: '/passagem-de-onibus/jequie-ba/itamari-ba'},
    {link: '/passagem-de-onibus/jequie-ba/itaquara-ba'},
    {link: '/passagem-de-onibus/jequie-ba/itirucu-ba'},
    {link: '/passagem-de-onibus/jequie-ba/ibirataia-ba'},
    {link: '/passagem-de-onibus/jequie-ba/itacare-ba'},
    {link: '/passagem-de-onibus/jequie-ba/jaguaquara-ba'},
    {link: '/passagem-de-onibus/jequie-ba/nilopecanha-ba'},
    {link: '/passagem-de-onibus/jequie-ba/urucuca-ba'},
    {link: '/passagem-de-onibus/jequie-ba/urucuca-ba'},
    {link: '/passagem-de-onibus/jequie-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/jequie-ba/marau,ba-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/ipira-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/irece-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/ibicoara-ba/salvador-ba'},
    {link: '/passagem-de-onibus/ibicoara-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ibicui-ba/iguai-ba'},
    {link: '/passagem-de-onibus/ibicui-ba/pocoes-ba'},
    {link: '/passagem-de-onibus/ibicui-ba/novacanaa-ba'},
    {link: '/passagem-de-onibus/ibicui-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/argoim-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/iacu-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/joaoamaro-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/santoestevao-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/paraguassu-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/salvador-ba'},
    {link: '/passagem-de-onibus/iramaia-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/irara-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/irara-ba/aramari-ba'},
    {link: '/passagem-de-onibus/irara-ba/catu-ba'},
    {link: '/passagem-de-onibus/irara-ba/conceicaodojacuipe-ba'},
    {link: '/passagem-de-onibus/irara-ba/coracaodemaria-ba'},
    {link: '/passagem-de-onibus/irara-ba/ouricangas-ba'},
    {link: '/passagem-de-onibus/irara-ba/pojuca-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/salvador-ba'},
    {link: '/passagem-de-onibus/ibitita-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/boanova-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/ilheus-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/urucuca-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/pocoes-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/itapicuru-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/olivenca-ba'},
    {link: '/passagem-de-onibus/ipiau-ba/una-ba'},
    {link: '/passagem-de-onibus/ipira-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/ipira-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/ipira-ba/bravo-ba'},
    {link: '/passagem-de-onibus/ipira-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/ipira-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/ipira-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/ipira-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/ipira-ba/salvador-ba'},
    {link: '/passagem-de-onibus/ipira-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/ipira-ba/utinga-ba'},
    {link: '/passagem-de-onibus/ipira-ba/wagner-ba'},
    {link: '/passagem-de-onibus/ipira-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/ipira-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/ipira-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/ipira-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/ipira-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/ipira-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/ipira-ba/irece-ba'},
    {link: '/passagem-de-onibus/ipira-ba/macajuba-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/nilopecanha-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/nilopecanha-ba/nazare-ba'},
    {link: '/passagem-de-onibus/nilopecanha-ba/itacare-ba'},
    {link: '/passagem-de-onibus/nilopecanha-ba/itubera-ba'},
    {link: '/passagem-de-onibus/nilopecanha-ba/camamu-ba'},
    {link: '/passagem-de-onibus/nilopecanha-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/nilopecanha-ba/valenca-rj'},
    {link: '/passagem-de-onibus/nilopecanha-ba/igrapiuna-ba'},
    {link: '/passagem-de-onibus/urucuca-ba/barradorocha-ba'},
    {link: '/passagem-de-onibus/urucuca-ba/jequie-ba'},
    {link: '/passagem-de-onibus/jaguaquara-ba/santaines,ba-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/ipira-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/irece-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/ouroverde-ba'},
    {link: '/passagem-de-onibus/irara-ba/salvador-ba'},
    {link: '/passagem-de-onibus/irara-ba/alagoinhas-ba'},
    {link: '/passagem-de-onibus/itacare-ba/aratuipe-ba'},
    {link: '/passagem-de-onibus/itacare-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/itacare-ba/barradorocha-ba'},
    {link: '/passagem-de-onibus/itacare-ba/nazare-ba'},
    {link: '/passagem-de-onibus/itacare-ba/jequie-ba'},
    {link: '/passagem-de-onibus/itacare-ba/governadormangabeira-ba'},
    {link: '/passagem-de-onibus/itacare-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/itacare-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/itacare-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/itacare-ba/valenca-rj'},
    {link: '/passagem-de-onibus/itacare-ba/igrapiuna-ba'},
    {link: '/passagem-de-onibus/itacare-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/jaguaripe-ba/jaguaripe-ba'},
    {link: '/passagem-de-onibus/jaguaripe-ba/salinasdamargarida-ba'},
    {link: '/passagem-de-onibus/jaguaripe-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/jaguaripe-ba/salinasdamargarida-ba'},
    {link: '/passagem-de-onibus/jaguaquara-ba/itaquara-ba'},
    {link: '/passagem-de-onibus/jitauna-ba/urucuca-ba'},
    {link: '/passagem-de-onibus/jitauna-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/jitauna-ba/olivenca-ba'},
    {link: '/passagem-de-onibus/jitauna-ba/una-ba'},
    {link: '/passagem-de-onibus/jordania-mg/itambe-ba'},
    {link: '/passagem-de-onibus/jordania-mg/itarantim-ba'},
    {link: '/passagem-de-onibus/jordania-mg/maiquinique-ba'},
    {link: '/passagem-de-onibus/jordania-mg/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/urucuca-ba/ilheus-ba'},
    {link: '/passagem-de-onibus/urucuca-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/urucuca-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/urucuca-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/urucuca-ba/ubata-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/barradorocha-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/comandatuba-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/jequie-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/ilheus-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/salvador-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/varzeanova-ba'},
    {link: '/passagem-de-onibus/joaodourado-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/jitauna-ba/ilheus-ba'},
    {link: '/passagem-de-onibus/jordania-mg/riachodesantana-ba'},
    {link: '/passagem-de-onibus/jordania-mg/itapetinga-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/argoim-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/andarai-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/mucuge-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/paraguassu-ba'},
    {link: '/passagem-de-onibus/santoestevao-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/itacare-ba/nilopecanha-ba'},
    {link: '/passagem-de-onibus/itacare-ba/itubera-ba'},
    {link: '/passagem-de-onibus/itacare-ba/camamu-ba'},
    {link: '/passagem-de-onibus/itacare-ba/pinare-ba'},
    {link: '/passagem-de-onibus/itacare-ba/sapeacu-ba'},
    {link: '/passagem-de-onibus/itacare-ba/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/itacare-ba/tapuia-ba'},
    {link: '/passagem-de-onibus/itacare-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/itacare-ba/cruzdasalmas-ba'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/macajuba-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/xique-xique-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/maragogipe-ba/cachoeira-ba'},
    {link: '/passagem-de-onibus/maragogipe-ba/coqueiros-ba'},
    {link: '/passagem-de-onibus/maragogipe-ba/murutuba-ba'},
    {link: '/passagem-de-onibus/maragogipe-ba/nage-ba'},
    {link: '/passagem-de-onibus/maragogipe-ba/salvador-ba'},
    {link: '/passagem-de-onibus/maragogipe-ba/santoamaro-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/central-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/ipira-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/irece-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/olindina-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/lagarto-se'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/salvador-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/aracaju-se'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/urucuca-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/olivenca-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/ubata-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/una-ba'},
    {link: '/passagem-de-onibus/irece-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/irece-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/irece-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/irece-ba/bonito,ba-ba'},
    {link: '/passagem-de-onibus/irece-ba/ipira-ba'},
    {link: '/passagem-de-onibus/irece-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/irece-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/irece-ba/ouroverde-ba'},
    {link: '/passagem-de-onibus/irece-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/macajuba-ba/bravo-ba'},
    {link: '/passagem-de-onibus/macajuba-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/macajuba-ba/ipira-ba'},
    {link: '/passagem-de-onibus/macajuba-ba/salvador-ba'},
    {link: '/passagem-de-onibus/macajuba-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/macajuba-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/morrodochapeu-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/irece-ba/varzeanova-ba'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/riachaodojacuipe-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/irece-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/irece-ba/salvador-ba'},
    {link: '/passagem-de-onibus/irece-ba/riachaodoutinga-ba'},
    {link: '/passagem-de-onibus/irece-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/irece-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/irece-ba/utinga-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/teolandia-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/valenca-rj'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/riachodesantana-ba'},
    {link: '/passagem-de-onibus/olindina-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/ibirataia-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/jordania-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/maracas-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/manoelvitorino-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/novacanaa-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/presidentetancredoneves-ba'},
    {link: '/passagem-de-onibus/itubera-ba/itacare-ba'},
    {link: '/passagem-de-onibus/itubera-ba/nilopecanha-ba'},
    {link: '/passagem-de-onibus/itubera-ba/piraidonorte-ba'},
    {link: '/passagem-de-onibus/itubera-ba/camamu-ba'},
    {link: '/passagem-de-onibus/itubera-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/itubera-ba/valenca-rj'},
    {link: '/passagem-de-onibus/itubera-ba/igrapiuna-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/itaia-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/algodao-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/novacanaa-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/florestaazul-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/itabuna-ba'},
    {link: '/passagem-de-onibus/muritiba-ba/cachoeira-ba'},
    {link: '/passagem-de-onibus/muritiba-ba/murutuba-ba'},
    {link: '/passagem-de-onibus/muritiba-ba/salvador-ba'},
    {link: '/passagem-de-onibus/muritiba-ba/santoamaro-ba'},
    {link: '/passagem-de-onibus/muritiba-ba/saofelix-ba'},
    {link: '/passagem-de-onibus/mucuge-ba/argoim-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/boanova-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/gandu-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/santacruzdavitoria-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/ibicui-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/iguai-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/itirucu-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/mucuge-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/mucuge-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/mucuge-ba/santoestevao-ba'},
    {link: '/passagem-de-onibus/mucuge-ba/paraguassu-ba'},
    {link: '/passagem-de-onibus/mucuge-ba/salvador-ba'},
    {link: '/passagem-de-onibus/mucuge-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/manoelvitorino-ba/jequie-ba'},
    {link: '/passagem-de-onibus/manoelvitorino-ba/pocoes-ba'},
    {link: '/passagem-de-onibus/manoelvitorino-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/olindina-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/olindina-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/olindina-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/olindina-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/olindina-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/olindina-ba/sambaiba-ba'},
    {link: '/passagem-de-onibus/olindina-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/maragogipe-ba/saofelix-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/itaia-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/ibicarai-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/santacruzdavitoria-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/firminoalves-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/jequie-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/ibicui-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/iguai-ba'},
    {link: '/passagem-de-onibus/pocoes-ba/manoelvitorino-ba'},
    {link: '/passagem-de-onibus/nage-ba/cachoeira-ba'},
    {link: '/passagem-de-onibus/nage-ba/coqueiros-ba'},
    {link: '/passagem-de-onibus/nage-ba/maragogipe-ba'},
    {link: '/passagem-de-onibus/nage-ba/murutuba-ba'},
    {link: '/passagem-de-onibus/nage-ba/salvador-ba'},
    {link: '/passagem-de-onibus/nage-ba/santoamaro-ba'},
    {link: '/passagem-de-onibus/nage-ba/saofelix-ba'},
    {link: '/passagem-de-onibus/itubera-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/itubera-ba/gandu-ba'},
    {link: '/passagem-de-onibus/itubera-ba/nazare-ba'},
    {link: '/passagem-de-onibus/camamu-ba/tapuia-ba'},
    {link: '/passagem-de-onibus/camamu-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/camamu-ba/cruzdasalmas-ba'},
    {link: '/passagem-de-onibus/camamu-ba/valenca-rj'},
    {link: '/passagem-de-onibus/camamu-ba/igrapiuna-ba'},
    {link: '/passagem-de-onibus/camamu-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/lagarto-se/portofeliz-ba'},
    {link: '/passagem-de-onibus/lagarto-se/piritiba-ba'},
    {link: '/passagem-de-onibus/lagarto-se/novasoure-ba'},
    {link: '/passagem-de-onibus/lagarto-se/jacobina-ba'},
    {link: '/passagem-de-onibus/camamu-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/camamu-ba/cachoeira-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/ipira-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/irece-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/olindina-ba'},
    {link: '/passagem-de-onibus/camamu-ba/nazare-ba'},
    {link: '/passagem-de-onibus/camamu-ba/governadormangabeira-ba'},
    {link: '/passagem-de-onibus/camamu-ba/itacare-ba'},
    {link: '/passagem-de-onibus/camamu-ba/nilopecanha-ba'},
    {link: '/passagem-de-onibus/camamu-ba/marau,ba-ba'},
    {link: '/passagem-de-onibus/camamu-ba/itubera-ba'},
    {link: '/passagem-de-onibus/camamu-ba/pinare-ba'},
    {link: '/passagem-de-onibus/camamu-ba/saquaira-ba'},
    {link: '/passagem-de-onibus/camamu-ba/sapeacu-ba'},
    {link: '/passagem-de-onibus/camamu-ba/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/lagarto-se/biritinga-ba'},
    {link: '/passagem-de-onibus/lagarto-se/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/lagarto-se/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/lagarto-se'},
    {link: '/passagem-de-onibus/portofeliz-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/salvador-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/aracaju-se'},
    {link: '/passagem-de-onibus/portofeliz-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/portofeliz-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/olindina-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/lagarto-se'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/aracaju-se'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/miguelcalmon-ba/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/salvador-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/salvador-ba/bomjesusdospobres-ba'},
    {link: '/passagem-de-onibus/salvador-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/salvador-ba/cabucu-ba'},
    {link: '/passagem-de-onibus/salvador-ba/conceicaodojacuipe-ba'},
    {link: '/passagem-de-onibus/salvador-ba/coracaodemaria-ba'},
    {link: '/passagem-de-onibus/salvador-ba/cabaceirasdoparaguacu-ba'},
    {link: '/passagem-de-onibus/salvador-ba/cachoeira-ba'},
    {link: '/passagem-de-onibus/salvador-ba/coqueiros-ba'},
    {link: '/passagem-de-onibus/pojuca-ba/aramari-ba'},
    {link: '/passagem-de-onibus/pojuca-ba/catu-ba'},
    {link: '/passagem-de-onibus/pojuca-ba/salvador-ba'},
    {link: '/passagem-de-onibus/pojuca-ba/alagoinhas-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/portofeliz-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/paraguassu-ba/argoim-ba'},
    {link: '/passagem-de-onibus/paraguassu-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/paraguassu-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/paraguassu-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/inhambupe-ba/salvador-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/salvador-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/aracaju-se'},
    {link: '/passagem-de-onibus/piritiba-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/piritiba-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/salvador-ba/acupe-ba'},
    {link: '/passagem-de-onibus/salvador-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/salvador-ba/argoim-ba'},
    {link: '/passagem-de-onibus/salvador-ba/central-ba'},
    {link: '/passagem-de-onibus/salvador-ba/cravolandia-ba'},
    {link: '/passagem-de-onibus/salvador-ba/aguafria-ba'},
    {link: '/passagem-de-onibus/salvador-ba/governadormangabeira-ba'},
    {link: '/passagem-de-onibus/salvador-ba/iacu-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ibicoara-ba'},
    {link: '/passagem-de-onibus/salvador-ba/itaete-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/ipira-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/irece-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/olindina-ba'},
    {link: '/passagem-de-onibus/piritiba-ba/lagarto-se'},
    {link: '/passagem-de-onibus/piritiba-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/salvador-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/salvador-ba/itirucu-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ipira-ba'},
    {link: '/passagem-de-onibus/salvador-ba/iramaia-ba'},
    {link: '/passagem-de-onibus/salvador-ba/irara-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ibirataia-ba'},
    {link: '/passagem-de-onibus/salvador-ba/joaoamaro-ba'},
    {link: '/passagem-de-onibus/salvador-ba/jaguaquara-ba'},
    {link: '/passagem-de-onibus/salvador-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/salvador-ba/aiquara-ba'},
    {link: '/passagem-de-onibus/salvador-ba/mairi-ba'},
    {link: '/passagem-de-onibus/salvador-ba/algodao-ba'},
    {link: '/passagem-de-onibus/salvador-ba/andarai-ba'},
    {link: '/passagem-de-onibus/salvador-ba/anguera-ba'},
    {link: '/passagem-de-onibus/salvador-ba/aramari-ba'},
    {link: '/passagem-de-onibus/salvador-ba/catu-ba'},
    {link: '/passagem-de-onibus/salvador-ba/bravo-ba'},
    {link: '/passagem-de-onibus/salvador-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/salvador-ba/irece-ba'},
    {link: '/passagem-de-onibus/serrolandia-ba/salvador-ba'},
    {link: '/passagem-de-onibus/serrolandia-ba/varzeadopoco-ba'},
    {link: '/passagem-de-onibus/serrolandia-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/santoamaro-ba/salvador-ba'},
    {link: '/passagem-de-onibus/saofelix-ba/cachoeira-ba'},
    {link: '/passagem-de-onibus/saofelix-ba/muritiba-ba'},
    {link: '/passagem-de-onibus/saofelix-ba/murutuba-ba'},
    {link: '/passagem-de-onibus/saofelix-ba/salvador-ba'},
    {link: '/passagem-de-onibus/saofelix-ba/santoamaro-ba'},
    {link: '/passagem-de-onibus/santoantoniodejesus-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/wagner-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/salinasdamargarida-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/salinasdamargarida-ba/jaguaripe-ba'},
    {link: '/passagem-de-onibus/salinasdamargarida-ba/jaguaripe-ba'},
    {link: '/passagem-de-onibus/serrolandia-ba/mairi-ba'},
    {link: '/passagem-de-onibus/serrolandia-ba/anguera-ba'},
    {link: '/passagem-de-onibus/serrolandia-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/serrolandia-ba/ipira-ba'},
    {link: '/passagem-de-onibus/serrolandia-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/bravo-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/ipira-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/macajuba-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/salvador-ba'},
    {link: '/passagem-de-onibus/ruybarbosa-ba/utinga-ba'},
    {link: '/passagem-de-onibus/salvador-ba/utinga-ba'},
    {link: '/passagem-de-onibus/salvador-ba/varzeadopoco-ba'},
    {link: '/passagem-de-onibus/salvador-ba/wagner-ba'},
    {link: '/passagem-de-onibus/salvador-ba/xique-xique-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/salvador-ba/serrolandia-ba'},
    {link: '/passagem-de-onibus/salvador-ba/santaines,ba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/santoamaro-ba'},
    {link: '/passagem-de-onibus/salvador-ba/saofelix-ba'},
    {link: '/passagem-de-onibus/salvador-ba/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/salvador-ba/tapiramuta-ba'},
    {link: '/passagem-de-onibus/salvador-ba/cruzdasalmas-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ubaira-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/olindina-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/lagarto-se'},
    {link: '/passagem-de-onibus/novasoure-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/novasoure-ba/aracaju-se'},
    {link: '/passagem-de-onibus/novasoure-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/novasoure-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ouricangas-ba'},
    {link: '/passagem-de-onibus/salvador-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/salvador-ba/paraguassu-ba'},
    {link: '/passagem-de-onibus/salvador-ba/inhambupe-ba'},
    {link: '/passagem-de-onibus/salvador-ba/pojuca-ba'},
    {link: '/passagem-de-onibus/salvador-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/salvador-ba/sambaiba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/saubara-ba'},
    {link: '/passagem-de-onibus/salvador-ba/maragogipe-ba'},
    {link: '/passagem-de-onibus/salvador-ba/marcioniliosouza-ba'},
    {link: '/passagem-de-onibus/salvador-ba/muritiba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/mucuge-ba'},
    {link: '/passagem-de-onibus/salvador-ba/mutuipe-ba'},
    {link: '/passagem-de-onibus/salvador-ba/murutuba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/nage-ba'},
    {link: '/passagem-de-onibus/salvador-ba/olindina-ba'},
    {link: '/passagem-de-onibus/salvador-ba/laje-ba'},
    {link: '/passagem-de-onibus/salvador-ba/macajuba-ba'},
    {link: '/passagem-de-onibus/salvador-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/salvador-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/santoantoniodejesus-ba/nazare-ba'},
    {link: '/passagem-de-onibus/santoantoniodejesus-ba/governadormangabeira-ba'},
    {link: '/passagem-de-onibus/santoantoniodejesus-ba/munizferreira-ba'},
    {link: '/passagem-de-onibus/santoantoniodejesus-ba/sapeacu-ba'},
    {link: '/passagem-de-onibus/tapiramuta-ba/bravo-ba'},
    {link: '/passagem-de-onibus/tapiramuta-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/tapiramuta-ba/ipira-ba'},
    {link: '/passagem-de-onibus/tapiramuta-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/tapiramuta-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/tapiramuta-ba/salvador-ba'},
    {link: '/passagem-de-onibus/tapiramuta-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/aracaju-se/biritinga-ba'},
    {link: '/passagem-de-onibus/aracaju-se/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/comandatuba-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/ibicui-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/iguai-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/pocoes-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/novacanaa-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/una-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/biritinga-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/portofeliz-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/piritiba-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/novasoure-ba'},
    {link: '/passagem-de-onibus/tobiasbarreto-se/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/taperoa,ba-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/taperoa,ba-ba/nazare-ba'},
    {link: '/passagem-de-onibus/taperoa,ba-ba/itacare-ba'},
    {link: '/passagem-de-onibus/taperoa,ba-ba/itubera-ba'},
    {link: '/passagem-de-onibus/taperoa,ba-ba/camamu-ba'},
    {link: '/passagem-de-onibus/aracaju-se/portofeliz-ba'},
    {link: '/passagem-de-onibus/aracaju-se/piritiba-ba'},
    {link: '/passagem-de-onibus/aracaju-se/novasoure-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/itaia-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/riachaodojacuipe-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/olindina-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/ouroverde-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/lagarto-se'},
    {link: '/passagem-de-onibus/taperoa,ba-ba/valenca-rj'},
    {link: '/passagem-de-onibus/taperoa,ba-ba/igrapiuna-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/capimgrosso-ba'},
    {link: '/passagem-de-onibus/utinga-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/utinga-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/camacari-ba/ameliarodrigues-ba'},
    {link: '/passagem-de-onibus/camacari-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/valenca-rj/acarai-ba'},
    {link: '/passagem-de-onibus/valenca-rj/algodao-ba'},
    {link: '/passagem-de-onibus/valenca-rj/aratuipe-ba'},
    {link: '/passagem-de-onibus/valenca-rj/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/valenca-rj/gandu-ba'},
    {link: '/passagem-de-onibus/valenca-rj/travessao-ba'},
    {link: '/passagem-de-onibus/valenca-rj/cruzdasalmas-ba'},
    {link: '/passagem-de-onibus/valenca-rj/ubaitaba-ba'},
    {link: '/passagem-de-onibus/valenca-rj/igrapiuna-ba'},
    {link: '/passagem-de-onibus/valenca-rj/feiradesantana-ba'},
    {link: '/passagem-de-onibus/alagoinhas-ba/pojuca-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/valenca-rj/manoelvitorino-ba'},
    {link: '/passagem-de-onibus/valenca-rj/itubera-ba'},
    {link: '/passagem-de-onibus/valenca-rj/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/valenca-rj/camamu-ba'},
    {link: '/passagem-de-onibus/valenca-rj/pinare-ba'},
    {link: '/passagem-de-onibus/valenca-rj/presidentetancredoneves-ba'},
    {link: '/passagem-de-onibus/valenca-rj/sapeacu-ba'},
    {link: '/passagem-de-onibus/valenca-rj/santoantoniodejesus-ba'},
    {link: '/passagem-de-onibus/valenca-rj/teolandia-ba'},
    {link: '/passagem-de-onibus/valenca-rj/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/ubata-ba/una-ba'},
    {link: '/passagem-de-onibus/utinga-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/utinga-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/utinga-ba/ipira-ba'},
    {link: '/passagem-de-onibus/utinga-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/utinga-ba/irece-ba'},
    {link: '/passagem-de-onibus/utinga-ba/salvador-ba'},
    {link: '/passagem-de-onibus/utinga-ba/riachaodoutinga-ba'},
    {link: '/passagem-de-onibus/valenca-rj/cachoeira-ba'},
    {link: '/passagem-de-onibus/valenca-rj/nazare-ba'},
    {link: '/passagem-de-onibus/valenca-rj/jequie-ba'},
    {link: '/passagem-de-onibus/valenca-rj/governadormangabeira-ba'},
    {link: '/passagem-de-onibus/valenca-rj/ipiau-ba'},
    {link: '/passagem-de-onibus/valenca-rj/ibirataia-ba'},
    {link: '/passagem-de-onibus/valenca-rj/itacare-ba'},
    {link: '/passagem-de-onibus/valenca-rj/jitauna-ba'},
    {link: '/passagem-de-onibus/valenca-rj/nilopecanha-ba'},
    {link: '/passagem-de-onibus/valenca-rj/pocoes-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/aracaju-se'},
    {link: '/passagem-de-onibus/jacobina-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/jacobina-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/serrinha-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/varzeanova-ba'},
    {link: '/passagem-de-onibus/jacobina-ba/conceicaodocoite-ba'},
    {link: '/passagem-de-onibus/ubaitaba-ba/barradorocha-ba'},
    {link: '/passagem-de-onibus/ubaitaba-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/ubaitaba-ba/olivenca-ba'},
    {link: '/passagem-de-onibus/ubaitaba-ba/una-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/serrinha-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/ubata-ba/ilheus-ba'},
    {link: '/passagem-de-onibus/ubata-ba/urucuca-ba'},
    {link: '/passagem-de-onibus/ubata-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/ubata-ba/olivenca-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/tobiasbarreto-se'},
    {link: '/passagem-de-onibus/itapetinga-ba/itarantim-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/jordania-mg'},
    {link: '/passagem-de-onibus/itapetinga-ba/maiquinique-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/riachodesantana-ba'},
    {link: '/passagem-de-onibus/xique-xique-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/xique-xique-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/itapicuru-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/olindina-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/miguelcalmon-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/lagarto-se'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/novasoure-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/aracaju-se'},
    {link: '/passagem-de-onibus/serrinha-ba/jacobina-ba'},
    {link: '/passagem-de-onibus/wagner-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/wagner-ba/ipira-ba'},
    {link: '/passagem-de-onibus/wagner-ba/salvador-ba'},
    {link: '/passagem-de-onibus/wagner-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/wagner-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/una-ba/barradorocha-ba'},
    {link: '/passagem-de-onibus/una-ba/jequie-ba'},
    {link: '/passagem-de-onibus/una-ba/ipiau-ba'},
    {link: '/passagem-de-onibus/una-ba/jitauna-ba'},
    {link: '/passagem-de-onibus/una-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/una-ba/olivenca-ba'},
    {link: '/passagem-de-onibus/una-ba/itabuna-ba'},
    {link: '/passagem-de-onibus/una-ba/ubaitaba-ba'},
    {link: '/passagem-de-onibus/una-ba/ubata-ba'},
    {link: '/passagem-de-onibus/conceicaodocoite-ba/biritinga-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/nilopecanha-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/macajuba-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/mucuge-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/itubera-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/camamu-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/barradomendes-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/candeias,ba-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/cachoeira-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/central-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/governadormangabeira-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ibipeba-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ibitita-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ipira-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/itacare-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/americadourada-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/argoim-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/andarai-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/anguera-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/bravo-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/baixagrande-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/itaberaba-ba'},
    {link: '/passagem-de-onibus/igrapiuna-ba/bomdespacho,ba-ba'},
    {link: '/passagem-de-onibus/igrapiuna-ba/nazare-ba'},
    {link: '/passagem-de-onibus/igrapiuna-ba/itacare-ba'},
    {link: '/passagem-de-onibus/igrapiuna-ba/nilopecanha-ba'},
    {link: '/passagem-de-onibus/igrapiuna-ba/itubera-ba'},
    {link: '/passagem-de-onibus/igrapiuna-ba/camamu-ba'},
    {link: '/passagem-de-onibus/igrapiuna-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/igrapiuna-ba/valenca-rj'},
    {link: '/passagem-de-onibus/xique-xique-ba/joaodourado-ba'},
    {link: '/passagem-de-onibus/xique-xique-ba/morrodochapeu-ba'},
    {link: '/passagem-de-onibus/xique-xique-ba/portofeliz-ba'},
    {link: '/passagem-de-onibus/xique-xique-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/xique-xique-ba/salvador-ba'},
    {link: '/passagem-de-onibus/xique-xique-ba/feiradesantana-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/paraguassu-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/pinare-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/piritiba-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/salvador-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/ruybarbosa-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/sapeacu-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/tapiramuta-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/tapuia-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/taperoa,ba-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/cruzdasalmas-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/utinga-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/camacari-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/valenca-rj'},
    {link: '/passagem-de-onibus/feiradesantana-ba/wagner-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/xique-xique-ba'},
    {link: '/passagem-de-onibus/feiradesantana-ba/igrapiuna-ba'},

    {link: '/passagem-de-onibus/itaucu-go/itapirapua-go'},
    {link: '/passagem-de-onibus/mozarlandia-go/cocalinho-mt'},
    {link: '/passagem-de-onibus/itaucu-go/araguapaz-go'},
    {link: '/passagem-de-onibus/itaucu-go/mozarlandia-go'},
    {link: '/passagem-de-onibus/itaucu-go/mossamedes-go'},
    {link: '/passagem-de-onibus/barradogarcas-mt/montesclarosdegoias-go'},
    {link: '/passagem-de-onibus/itaberai-go/goiania-go'},
    {link: '/passagem-de-onibus/itaberai-go/itaucu-go'},
    {link: '/passagem-de-onibus/itaberai-go/faina-go'},
    {link: '/passagem-de-onibus/itaberai-go/goias-go'},
    {link: '/passagem-de-onibus/itaberai-go/aruana-go'},
    {link: '/passagem-de-onibus/itaberai-go/montesclarosdegoias-go'},
    {link: '/passagem-de-onibus/itaberai-go/jussara-go'},
    {link: '/passagem-de-onibus/itaberai-go/araguapaz-go'},
    {link: '/passagem-de-onibus/itaberai-go/inhumas-go'},
    {link: '/passagem-de-onibus/itaberai-go/mossamedes-go'},
    {link: '/passagem-de-onibus/goiania-go/mossamedes-go'},
    {link: '/passagem-de-onibus/itaucu-go/goiania-go'},
    {link: '/passagem-de-onibus/itaucu-go/itaberai-go'},
    {link: '/passagem-de-onibus/itaucu-go/goias-go'},
    {link: '/passagem-de-onibus/itaucu-go/aruana-go'},
    {link: '/passagem-de-onibus/itaucu-go/montesclarosdegoias-go'},
    {link: '/passagem-de-onibus/goiania-go/matrincha-go'},
    {link: '/passagem-de-onibus/goiania-go/faina-go'},
    {link: '/passagem-de-onibus/goiania-go/goias-go'},
    {link: '/passagem-de-onibus/goiania-go/aruana-go'},
    {link: '/passagem-de-onibus/goiania-go/montesclarosdegoias-go'},
    {link: '/passagem-de-onibus/goiania-go/jussara-go'},
    {link: '/passagem-de-onibus/goiania-go/itapirapua-go'},
    {link: '/passagem-de-onibus/goiania-go/araguapaz-go'},
    {link: '/passagem-de-onibus/goiania-go/itaucu-go'},
    {link: '/passagem-de-onibus/goiania-go/itaberai-go'},
    {link: '/passagem-de-onibus/goiania-go/cocalinho-mt'},
    {link: '/passagem-de-onibus/inhumas-go/aruana-go'},
    {link: '/passagem-de-onibus/inhumas-go/montesclarosdegoias-go'},
    {link: '/passagem-de-onibus/inhumas-go/jussara-go'},
    {link: '/passagem-de-onibus/inhumas-go/araguapaz-go'},
    {link: '/passagem-de-onibus/inhumas-go/mossamedes-go'},
    {link: '/passagem-de-onibus/mozarlandia-go/itaucu-go'},
    {link: '/passagem-de-onibus/cocalinho-mt/mozarlandia-go'},
    {link: '/passagem-de-onibus/matrincha-go/goiania-go'},
    {link: '/passagem-de-onibus/matrincha-go/inhumas-go'},
    {link: '/passagem-de-onibus/faina-go/goiania-go'},
    {link: '/passagem-de-onibus/faina-go/itaberai-go'},
    {link: '/passagem-de-onibus/faina-go/aruana-go'},
    {link: '/passagem-de-onibus/faina-go/inhumas-go'},
    {link: '/passagem-de-onibus/faina-go/novacrixas-go'},
    {link: '/passagem-de-onibus/goias-go/goiania-go'},
    {link: '/passagem-de-onibus/mundonovo,go-go/goias-go'},
    {link: '/passagem-de-onibus/araguapaz-go/goiania-go'},
    {link: '/passagem-de-onibus/araguapaz-go/itaucu-go'},
    {link: '/passagem-de-onibus/araguapaz-go/itaberai-go'},
    {link: '/passagem-de-onibus/jussara-go/itaberai-go'},
    {link: '/passagem-de-onibus/jussara-go/goias-go'},
    {link: '/passagem-de-onibus/jussara-go/montesclarosdegoias-go'},
    {link: '/passagem-de-onibus/jussara-go/aragarcas-go'},
    {link: '/passagem-de-onibus/jussara-go/inhumas-go'},
    {link: '/passagem-de-onibus/itapirapua-go/goiania-go'},
    {link: '/passagem-de-onibus/aragarcas-go/montesclarosdegoias-go'},
    {link: '/passagem-de-onibus/aragarcas-go/jussara-go'},
    {link: '/passagem-de-onibus/goias-go/novacrixas-go'},
    {link: '/passagem-de-onibus/aruana-go/goiania-go'},
    {link: '/passagem-de-onibus/aruana-go/itaucu-go'},
    {link: '/passagem-de-onibus/aruana-go/itaberai-go'},
    {link: '/passagem-de-onibus/aruana-go/faina-go'},
    {link: '/passagem-de-onibus/aruana-go/goias-go'},
    {link: '/passagem-de-onibus/aruana-go/araguapaz-go'},
    {link: '/passagem-de-onibus/aruana-go/goianira-go'},
    {link: '/passagem-de-onibus/aruana-go/inhumas-go'},
    {link: '/passagem-de-onibus/montesclarosdegoias-go/goiania-go'},
    {link: '/passagem-de-onibus/goias-go/itaucu-go'},
    {link: '/passagem-de-onibus/goias-go/itaberai-go'},
    {link: '/passagem-de-onibus/goias-go/aruana-go'},
    {link: '/passagem-de-onibus/goias-go/montesclarosdegoias-go'},
    {link: '/passagem-de-onibus/goias-go/jussara-go'},
    {link: '/passagem-de-onibus/goias-go/itapirapua-go'},
    {link: '/passagem-de-onibus/goias-go/mundonovo,go-go'},
    {link: '/passagem-de-onibus/goias-go/araguapaz-go'},
    {link: '/passagem-de-onibus/goias-go/inhumas-go'},
    {link: '/passagem-de-onibus/goias-go/mozarlandia-go'},
    {link: '/passagem-de-onibus/araguapaz-go/faina-go'},
    {link: '/passagem-de-onibus/araguapaz-go/goias-go'},
    {link: '/passagem-de-onibus/araguapaz-go/aruana-go'},
    {link: '/passagem-de-onibus/araguapaz-go/inhumas-go'},
    {link: '/passagem-de-onibus/inhumas-go/itaberai-go'},
    {link: '/passagem-de-onibus/inhumas-go/matrincha-go'},
    {link: '/passagem-de-onibus/inhumas-go/faina-go'},
    {link: '/passagem-de-onibus/inhumas-go/goias-go'},
    {link: '/passagem-de-onibus/mozarlandia-go/faina-go'},
    {link: '/passagem-de-onibus/mozarlandia-go/goias-go'},
    {link: '/passagem-de-onibus/novacrixas-go/faina-go'},
    {link: '/passagem-de-onibus/novacrixas-go/goias-go'},
    {link: '/passagem-de-onibus/montesclarosdegoias-go/itaucu-go'},
    {link: '/passagem-de-onibus/montesclarosdegoias-go/barradogarcas-mt'},
    {link: '/passagem-de-onibus/montesclarosdegoias-go/itaberai-go'},
    {link: '/passagem-de-onibus/montesclarosdegoias-go/goias-go'},
    {link: '/passagem-de-onibus/montesclarosdegoias-go/jussara-go'},
    {link: '/passagem-de-onibus/montesclarosdegoias-go/aragarcas-go'},
    {link: '/passagem-de-onibus/montesclarosdegoias-go/inhumas-go'},
    {link: '/passagem-de-onibus/jussara-go/goiania-go'},
    {link: '/passagem-de-onibus/mossamedes-go/goiania-go'},
    {link: '/passagem-de-onibus/mossamedes-go/itaucu-go'},
    {link: '/passagem-de-onibus/mossamedes-go/itaberai-go'},
    {link: '/passagem-de-onibus/mossamedes-go/inhumas-go'}

];
export default cidadeDoSol;