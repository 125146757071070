import React from 'react';
import logoVpb from '../../../estilo/comum/img/logo-VipBrasil1.webp';
import iconVipBrasil from '../../../estilo/comum/img/icon/vip-brasil.ico';
import dadosSiteComum from './dadosSiteComum';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/11.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/19.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoBrasilpassagens.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/20.webp';
import ConteudoQuemSomosVPB from '../../../componentes/quem-somos/vip-brasil/ConteudoQuemSomosVPB';

const logoBrasilCabecalho = <img className="logo-site brasilp" src={logoVpb} alt='Logo Brasil Passagens'/>;
const logoBrasilRodape = <img className="brasilp" src={logoVpb} alt="Logo Brasil Passagens" style={{width: '45%'}}/>;

export const dadosVipBrasil = {
    ...dadosSiteComum,
    id: 'vipbrasil',
    pastaEstilo: 'vipbrasil',
    nome: 'Vip Brasil',
    nomePrefixo: 'a Vip Brasil',
    razaoSocial: 'VEXADO PROMOTORA DE VENDAS E INTERMEDIAÇÕES DE NEGÓCIOS EIRELI',
    cnpj: '32.843.031/0001-09',
    endereco:'Q SCS Quadra 01, Bloco M, número 30, Sala 508, Asa Sul/DF, CEP: 70.305-900',
    telefone: '(61) 99253-4333',
    wtpp: '(62) 99853-5318',
    instagram: 'https://www.instagram.com/vipbrasil.tur?igsh=MTBydDcycXBzdzNhaQ%3D%3D&utm_source=qr',
    tiktok: 'https://www.tiktok.com',
    facebook: 'https://www.facebook.com/vipturbr?mibextid=LQQJ4d',
    youtube: 'youtube.com',
    email: 'operacionalviacaovipbrasil@outlook.com',
    emailSuporte: 'operacionalviacaovipbrasil@outlook.com',
    lkemailSuporte: 'mailto:operacionalviacaovipbrasil@outlook.com',
    lktelefone: 'tel:5562998535318',
    twitter: 'https://twitter.com/i/flow/login',
    lkemail: 'mailto:operacionalviacaovipbrasil@outlook.com',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561981537600&text=Olá. Preciso de atendimento.',
    logo: logoVpb,
    logoCabecacho: logoBrasilCabecalho,
    logoRodape: logoBrasilRodape,
    OgImage: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/Via%C3%A7%C3%A3o_vip_brasil_Passagens_online!_Um_novo_jeito_de_viajar._O_melhor_valor_sem_precisar_sai_de_casa_para_comprar!.webp',
    description: 'Compre passagens de ônibus sem sair de casa. São diversas opções de horários e viações para você escolher.',
    icone: iconVipBrasil,
    quemSomos: ConteudoQuemSomosVPB,
    videoInicio: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/video-backbp2.mp4',
    idsTagManager: ['GTM-PHGZ336'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+descontoBrasilpassagens.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/1.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/1.mobile.webp',
    distribusion: false,
    exibePopUpPromocao: false
};
