const cantelle = [

    {link: '/passagem-de-onibus/fredericowestphalen-rs/rodavelha-ba'},
    {link: '/passagem-de-onibus/panambi-rs/rodavelha-ba'},
    {link: '/passagem-de-onibus/patobranco-pr/rodavelha-ba'},
    {link: '/passagem-de-onibus/abelardoluz-sc/rodavelha-ba'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/rodavelha-ba'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/abelardoluz-sc'},
    {link: '/passagem-de-onibus/alvoradadonorte-go/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/palmitos-sc'},
    {link: '/passagem-de-onibus/barreiras-ba/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/barreiras-ba/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/barreiras-ba/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/toledo-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/umuarama-pr'},
    {link: '/passagem-de-onibus/abelardoluz-sc/umuarama-pr'},
    {link: '/passagem-de-onibus/abelardoluz-sc/luziania-go'},
    {link: '/passagem-de-onibus/abelardoluz-sc/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/abelardoluz-sc'},
    {link: '/passagem-de-onibus/barreiras-ba/cascavel-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/maringa-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/irai-rs'},
    {link: '/passagem-de-onibus/barreiras-ba/xaxim-sc'},
    {link: '/passagem-de-onibus/barreiras-ba/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/catalao-go/abelardoluz-sc'},
    {link: '/passagem-de-onibus/catalao-go/correntina-ba'},
    {link: '/passagem-de-onibus/catalao-go/cascavel-pr'},
    {link: '/passagem-de-onibus/catalao-go/maringa-pr'},
    {link: '/passagem-de-onibus/catalao-go/xanxere-sc'},
    {link: '/passagem-de-onibus/barreiras-ba/xanxere-sc'},
    {link: '/passagem-de-onibus/barreiras-ba/realeza-mg'},
    {link: '/passagem-de-onibus/barreiras-ba/panambi-rs'},
    {link: '/passagem-de-onibus/barreiras-ba/penapolis-sp'},
    {link: '/passagem-de-onibus/barreiras-ba/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/barreiras-ba/patobranco-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/cianorte-pr'},
    {link: '/passagem-de-onibus/barreiras-ba/chapeco-sc'},
    {link: '/passagem-de-onibus/abelardoluz-sc/posse-go'},
    {link: '/passagem-de-onibus/abelardoluz-sc/brasilia-df'},
    {link: '/passagem-de-onibus/abelardoluz-sc/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/abelardoluz-sc/cruzalta-rs'},
    {link: '/passagem-de-onibus/abelardoluz-sc/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/abelardoluz-sc/barreiras-ba'},
    {link: '/passagem-de-onibus/abelardoluz-sc/correntina-ba'},
    {link: '/passagem-de-onibus/abelardoluz-sc/catalao-go'},
    {link: '/passagem-de-onibus/abelardoluz-sc/formosa-go'},
    {link: '/passagem-de-onibus/abelardoluz-sc/araguari-mg'},
    {link: '/passagem-de-onibus/abelardoluz-sc/cristalina-go'},
    {link: '/passagem-de-onibus/abelardoluz-sc/panambi-rs'},
    {link: '/passagem-de-onibus/abelardoluz-sc/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/abelardoluz-sc/penapolis-sp'},
    {link: '/passagem-de-onibus/abelardoluz-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/abelardoluz-sc/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/abelardoluz-sc/cianorte-pr'},
    {link: '/passagem-de-onibus/catalao-go/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/catalao-go/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/catalao-go/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/catalao-go/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/catalao-go/toledo-pr'},
    {link: '/passagem-de-onibus/catalao-go/umuarama-pr'},
    {link: '/passagem-de-onibus/catalao-go/realeza-mg'},
    {link: '/passagem-de-onibus/catalao-go/cristalina-go'},
    {link: '/passagem-de-onibus/catalao-go/panambi-rs'},
    {link: '/passagem-de-onibus/catalao-go/penapolis-sp'},
    {link: '/passagem-de-onibus/catalao-go/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/catalao-go/patobranco-pr'},
    {link: '/passagem-de-onibus/catalao-go/cianorte-pr'},
    {link: '/passagem-de-onibus/catalao-go/chapeco-sc'},
    {link: '/passagem-de-onibus/catalao-go/palmitos-sc'},
    {link: '/passagem-de-onibus/formosa-go/umuarama-pr'},
    {link: '/passagem-de-onibus/formosa-go/irai-rs'},
    {link: '/passagem-de-onibus/formosa-go/xaxim-sc'},
    {link: '/passagem-de-onibus/formosa-go/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/cascavel-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/cascavel-pr/correntina-ba'},
    {link: '/passagem-de-onibus/cascavel-pr/catalao-go'},
    {link: '/passagem-de-onibus/cascavel-pr/formosa-go'},
    {link: '/passagem-de-onibus/formosa-go/correntina-ba'},
    {link: '/passagem-de-onibus/formosa-go/cascavel-pr'},
    {link: '/passagem-de-onibus/formosa-go/maringa-pr'},
    {link: '/passagem-de-onibus/formosa-go/xanxere-sc'},
    {link: '/passagem-de-onibus/formosa-go/realeza-mg'},
    {link: '/passagem-de-onibus/formosa-go/panambi-rs'},
    {link: '/passagem-de-onibus/formosa-go/penapolis-sp'},
    {link: '/passagem-de-onibus/formosa-go/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/catalao-go/irai-rs'},
    {link: '/passagem-de-onibus/catalao-go/xaxim-sc'},
    {link: '/passagem-de-onibus/catalao-go/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/marmeleiro-pr/panambi-rs'},
    {link: '/passagem-de-onibus/marmeleiro-pr/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/marmeleiro-pr/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/marmeleiro-pr/cruzalta-rs'},
    {link: '/passagem-de-onibus/marmeleiro-pr/irai-rs'},
    {link: '/passagem-de-onibus/formosa-go/abelardoluz-sc'},
    {link: '/passagem-de-onibus/cascavel-pr/posse-go'},
    {link: '/passagem-de-onibus/cascavel-pr/luziania-go'},
    {link: '/passagem-de-onibus/maringa-pr/posse-go'},
    {link: '/passagem-de-onibus/maringa-pr/luziania-go'},
    {link: '/passagem-de-onibus/cascavel-pr/araguari-mg'},
    {link: '/passagem-de-onibus/cascavel-pr/cristalina-go'},
    {link: '/passagem-de-onibus/cascavel-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/cascavel-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/formosa-go/patobranco-pr'},
    {link: '/passagem-de-onibus/formosa-go/cianorte-pr'},
    {link: '/passagem-de-onibus/formosa-go/chapeco-sc'},
    {link: '/passagem-de-onibus/formosa-go/palmitos-sc'},
    {link: '/passagem-de-onibus/formosa-go/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/formosa-go/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/formosa-go/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/formosa-go/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/formosa-go/toledo-pr'},
    {link: '/passagem-de-onibus/maringa-pr/cristalina-go'},
    {link: '/passagem-de-onibus/maringa-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/maringa-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/maringa-pr/correntina-ba'},
    {link: '/passagem-de-onibus/maringa-pr/catalao-go'},
    {link: '/passagem-de-onibus/maringa-pr/formosa-go'},
    {link: '/passagem-de-onibus/maringa-pr/araguari-mg'},
    {link: '/passagem-de-onibus/araguari-mg/cianorte-pr'},
    {link: '/passagem-de-onibus/araguari-mg/chapeco-sc'},
    {link: '/passagem-de-onibus/araguari-mg/palmitos-sc'},
    {link: '/passagem-de-onibus/araguari-mg/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/araguari-mg/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/araguari-mg/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/araguari-mg/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/araguari-mg/toledo-pr'},
    {link: '/passagem-de-onibus/cristalina-go/panambi-rs'},
    {link: '/passagem-de-onibus/cristalina-go/penapolis-sp'},
    {link: '/passagem-de-onibus/cristalina-go/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/cristalina-go/patobranco-pr'},
    {link: '/passagem-de-onibus/cristalina-go/cianorte-pr'},
    {link: '/passagem-de-onibus/cristalina-go/chapeco-sc'},
    {link: '/passagem-de-onibus/cristalina-go/palmitos-sc'},
    {link: '/passagem-de-onibus/cristalina-go/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/cristalina-go/abelardoluz-sc'},
    {link: '/passagem-de-onibus/cristalina-go/correntina-ba'},
    {link: '/passagem-de-onibus/cristalina-go/catalao-go'},
    {link: '/passagem-de-onibus/cristalina-go/cascavel-pr'},
    {link: '/passagem-de-onibus/cristalina-go/maringa-pr'},
    {link: '/passagem-de-onibus/cristalina-go/xanxere-sc'},
    {link: '/passagem-de-onibus/cristalina-go/realeza-mg'},
    {link: '/passagem-de-onibus/realeza-mg/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/realeza-mg/uberlandia-mg'},
    {link: '/passagem-de-onibus/realeza-mg/posse-go'},
    {link: '/passagem-de-onibus/realeza-mg/brasilia-df'},
    {link: '/passagem-de-onibus/araguari-mg/umuarama-pr'},
    {link: '/passagem-de-onibus/araguari-mg/irai-rs'},
    {link: '/passagem-de-onibus/araguari-mg/xaxim-sc'},
    {link: '/passagem-de-onibus/araguari-mg/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/xanxere-sc/barreiras-ba'},
    {link: '/passagem-de-onibus/xanxere-sc/correntina-ba'},
    {link: '/passagem-de-onibus/xanxere-sc/catalao-go'},
    {link: '/passagem-de-onibus/xanxere-sc/formosa-go'},
    {link: '/passagem-de-onibus/realeza-mg/barreiras-ba'},
    {link: '/passagem-de-onibus/realeza-mg/correntina-ba'},
    {link: '/passagem-de-onibus/realeza-mg/catalao-go'},
    {link: '/passagem-de-onibus/realeza-mg/formosa-go'},
    {link: '/passagem-de-onibus/realeza-mg/araguari-mg'},
    {link: '/passagem-de-onibus/realeza-mg/cristalina-go'},
    {link: '/passagem-de-onibus/xanxere-sc/araguari-mg'},
    {link: '/passagem-de-onibus/xanxere-sc/cristalina-go'},
    {link: '/passagem-de-onibus/xanxere-sc/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/xanxere-sc/penapolis-sp'},
    {link: '/passagem-de-onibus/xanxere-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/xanxere-sc/posse-go'},
    {link: '/passagem-de-onibus/xanxere-sc/brasilia-df'},
    {link: '/passagem-de-onibus/araguari-mg/abelardoluz-sc'},
    {link: '/passagem-de-onibus/araguari-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/araguari-mg/maringa-pr'},
    {link: '/passagem-de-onibus/araguari-mg/xanxere-sc'},
    {link: '/passagem-de-onibus/araguari-mg/realeza-mg'},
    {link: '/passagem-de-onibus/araguari-mg/panambi-rs'},
    {link: '/passagem-de-onibus/araguari-mg/penapolis-sp'},
    {link: '/passagem-de-onibus/araguari-mg/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/araguari-mg/patobranco-pr'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/patobranco-pr'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/cianorte-pr'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/chapeco-sc'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/palmitos-sc'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/toledo-pr'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/umuarama-pr'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/irai-rs'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/xaxim-sc'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/penapolis-sp/abelardoluz-sc'},
    {link: '/passagem-de-onibus/cristalina-go/xaxim-sc'},
    {link: '/passagem-de-onibus/cristalina-go/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/panambi-rs/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/panambi-rs/barreiras-ba'},
    {link: '/passagem-de-onibus/panambi-rs/correntina-ba'},
    {link: '/passagem-de-onibus/panambi-rs/catalao-go'},
    {link: '/passagem-de-onibus/panambi-rs/marmeleiro-pr'},
    {link: '/passagem-de-onibus/panambi-rs/formosa-go'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/maringa-pr'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/xanxere-sc'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/realeza-mg'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/panambi-rs'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/penapolis-sp'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/cristalina-go/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/cristalina-go/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/cristalina-go/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/cristalina-go/toledo-pr'},
    {link: '/passagem-de-onibus/cristalina-go/umuarama-pr'},
    {link: '/passagem-de-onibus/cristalina-go/irai-rs'},
    {link: '/passagem-de-onibus/panambi-rs/araguari-mg'},
    {link: '/passagem-de-onibus/panambi-rs/cristalina-go'},
    {link: '/passagem-de-onibus/panambi-rs/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/panambi-rs/penapolis-sp'},
    {link: '/passagem-de-onibus/panambi-rs/uberlandia-mg'},
    {link: '/passagem-de-onibus/panambi-rs/posse-go'},
    {link: '/passagem-de-onibus/panambi-rs/brasilia-df'},
    {link: '/passagem-de-onibus/penapolis-sp/barreiras-ba'},
    {link: '/passagem-de-onibus/penapolis-sp/correntina-ba'},
    {link: '/passagem-de-onibus/penapolis-sp/catalao-go'},
    {link: '/passagem-de-onibus/penapolis-sp/formosa-go'},
    {link: '/passagem-de-onibus/penapolis-sp/araguari-mg'},
    {link: '/passagem-de-onibus/penapolis-sp/xanxere-sc'},
    {link: '/passagem-de-onibus/penapolis-sp/cristalina-go'},
    {link: '/passagem-de-onibus/penapolis-sp/panambi-rs'},
    {link: '/passagem-de-onibus/panambi-rs/luziania-go'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/abelardoluz-sc'},
    {link: '/passagem-de-onibus/luiseduardomagalhaes-ba/cascavel-pr'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/uberlandia-mg'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/posse-go'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/brasilia-df'},
    {link: '/passagem-de-onibus/uberlandia-mg/toledo-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/umuarama-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/irai-rs'},
    {link: '/passagem-de-onibus/uberlandia-mg/xaxim-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/abelardoluz-sc'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/barreiras-ba'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/luziania-go'},
    {link: '/passagem-de-onibus/patobranco-pr/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/correntina-ba'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/catalao-go'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/marmeleiro-pr'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/formosa-go'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/araguari-mg'},
    {link: '/passagem-de-onibus/palmeiradasmissoes-rs/cristalina-go'},
    {link: '/passagem-de-onibus/uberlandia-mg/patobranco-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/cianorte-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/chapeco-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/palmitos-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/uberlandia-mg/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/penapolis-sp/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/penapolis-sp/patobranco-pr'},
    {link: '/passagem-de-onibus/penapolis-sp/cianorte-pr'},
    {link: '/passagem-de-onibus/penapolis-sp/chapeco-sc'},
    {link: '/passagem-de-onibus/penapolis-sp/posse-go'},
    {link: '/passagem-de-onibus/penapolis-sp/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/cascavel-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/xanxere-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/realeza-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/panambi-rs'},
    {link: '/passagem-de-onibus/uberlandia-mg/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/penapolis-sp/toledo-pr'},
    {link: '/passagem-de-onibus/penapolis-sp/cruzalta-rs'},
    {link: '/passagem-de-onibus/penapolis-sp/umuarama-pr'},
    {link: '/passagem-de-onibus/penapolis-sp/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/abelardoluz-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/correntina-ba'},
    {link: '/passagem-de-onibus/patobranco-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/patobranco-pr/correntina-ba'},
    {link: '/passagem-de-onibus/patobranco-pr/catalao-go'},
    {link: '/passagem-de-onibus/patobranco-pr/formosa-go'},
    {link: '/passagem-de-onibus/patobranco-pr/araguari-mg'},
    {link: '/passagem-de-onibus/patobranco-pr/cristalina-go'},
    {link: '/passagem-de-onibus/patobranco-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/patobranco-pr/penapolis-sp'},
    {link: '/passagem-de-onibus/patobranco-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/patobranco-pr/posse-go'},
    {link: '/passagem-de-onibus/patobranco-pr/brasilia-df'},
    {link: '/passagem-de-onibus/chapeco-sc/brasilia-df'},
    {link: '/passagem-de-onibus/palmitos-sc/barreiras-ba'},
    {link: '/passagem-de-onibus/palmitos-sc/correntina-ba'},
    {link: '/passagem-de-onibus/chapeco-sc/penapolis-sp'},
    {link: '/passagem-de-onibus/chapeco-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/chapeco-sc/posse-go'},
    {link: '/passagem-de-onibus/chapeco-sc/barreiras-ba'},
    {link: '/passagem-de-onibus/chapeco-sc/correntina-ba'},
    {link: '/passagem-de-onibus/chapeco-sc/catalao-go'},
    {link: '/passagem-de-onibus/chapeco-sc/formosa-go'},
    {link: '/passagem-de-onibus/chapeco-sc/araguari-mg'},
    {link: '/passagem-de-onibus/chapeco-sc/cristalina-go'},
    {link: '/passagem-de-onibus/chapeco-sc/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/cianorte-pr/posse-go'},
    {link: '/passagem-de-onibus/cianorte-pr/brasilia-df'},
    {link: '/passagem-de-onibus/palmitos-sc/catalao-go'},
    {link: '/passagem-de-onibus/palmitos-sc/formosa-go'},
    {link: '/passagem-de-onibus/palmitos-sc/araguari-mg'},
    {link: '/passagem-de-onibus/palmitos-sc/cristalina-go'},
    {link: '/passagem-de-onibus/palmitos-sc/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/palmitos-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/cianorte-pr/formosa-go'},
    {link: '/passagem-de-onibus/cianorte-pr/araguari-mg'},
    {link: '/passagem-de-onibus/cianorte-pr/cristalina-go'},
    {link: '/passagem-de-onibus/cianorte-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/cianorte-pr/penapolis-sp'},
    {link: '/passagem-de-onibus/cianorte-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/patobranco-pr/luziania-go'},
    {link: '/passagem-de-onibus/cianorte-pr/abelardoluz-sc'},
    {link: '/passagem-de-onibus/cianorte-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/cianorte-pr/correntina-ba'},
    {link: '/passagem-de-onibus/cianorte-pr/catalao-go'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/barreiras-ba'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/correntina-ba'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/catalao-go'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/marmeleiro-pr'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/formosa-go'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/posse-go'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/brasilia-df'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/luziania-go'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/cristalina-go'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/araguari-mg'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/cristalina-go'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/fredericowestphalen-rs/uberlandia-mg'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/barreiras-ba'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/correntina-ba'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/catalao-go'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/formosa-go'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/araguari-mg'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/posse-go'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/brasilia-df'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/luziania-go'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/posse-go/abelardoluz-sc'},
    {link: '/passagem-de-onibus/posse-go/cascavel-pr'},
    {link: '/passagem-de-onibus/posse-go/maringa-pr'},
    {link: '/passagem-de-onibus/posse-go/xanxere-sc'},
    {link: '/passagem-de-onibus/posse-go/realeza-mg'},
    {link: '/passagem-de-onibus/posse-go/panambi-rs'},
    {link: '/passagem-de-onibus/palmitos-sc/posse-go'},
    {link: '/passagem-de-onibus/palmitos-sc/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/palmitos-sc/brasilia-df'},
    {link: '/passagem-de-onibus/posse-go/penapolis-sp'},
    {link: '/passagem-de-onibus/posse-go/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/posse-go/patobranco-pr'},
    {link: '/passagem-de-onibus/posse-go/cianorte-pr'},
    {link: '/passagem-de-onibus/posse-go/chapeco-sc'},
    {link: '/passagem-de-onibus/posse-go/palmitos-sc'},
    {link: '/passagem-de-onibus/posse-go/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/posse-go/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/brasilia-df/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/brasilia-df/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/brasilia-df/toledo-pr'},
    {link: '/passagem-de-onibus/brasilia-df/umuarama-pr'},
    {link: '/passagem-de-onibus/brasilia-df/irai-rs'},
    {link: '/passagem-de-onibus/brasilia-df/xaxim-sc'},
    {link: '/passagem-de-onibus/brasilia-df/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/correntina-ba'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/catalao-go'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/formosa-go'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/araguari-mg'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/cristalina-go'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/palmitos-sc'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/posse-go'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/brasilia-df'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/araguari-mg'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/cristalina-go'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/penapolis-sp'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/brasilia-df/abelardoluz-sc'},
    {link: '/passagem-de-onibus/brasilia-df/xanxere-sc'},
    {link: '/passagem-de-onibus/brasilia-df/realeza-mg'},
    {link: '/passagem-de-onibus/brasilia-df/panambi-rs'},
    {link: '/passagem-de-onibus/posse-go/irai-rs'},
    {link: '/passagem-de-onibus/posse-go/xaxim-sc'},
    {link: '/passagem-de-onibus/posse-go/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/correntina-ba'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/catalao-go'},
    {link: '/passagem-de-onibus/franciscobeltrao-pr/formosa-go'},
    {link: '/passagem-de-onibus/brasilia-df/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/brasilia-df/patobranco-pr'},
    {link: '/passagem-de-onibus/brasilia-df/cianorte-pr'},
    {link: '/passagem-de-onibus/brasilia-df/chapeco-sc'},
    {link: '/passagem-de-onibus/brasilia-df/palmitos-sc'},
    {link: '/passagem-de-onibus/brasilia-df/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/brasilia-df/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/posse-go/franciscobeltrao-pr'},
    {link: '/passagem-de-onibus/posse-go/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/posse-go/toledo-pr'},
    {link: '/passagem-de-onibus/posse-go/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/posse-go/cruzalta-rs'},
    {link: '/passagem-de-onibus/posse-go/umuarama-pr'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/chapeco-sc'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/posse-go'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/brasilia-df'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/toledo-pr'},
    {link: '/passagem-de-onibus/cruzalta-rs/abelardoluz-sc'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/correntina-ba'},
    {link: '/passagem-de-onibus/toledo-pr/posse-go'},
    {link: '/passagem-de-onibus/toledo-pr/brasilia-df'},
    {link: '/passagem-de-onibus/toledo-pr/luziania-go'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/abelardoluz-sc'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/cruzalta-rs'},
    {link: '/passagem-de-onibus/capitaoleonidasmarques-pr/luziania-go'},
    {link: '/passagem-de-onibus/toledo-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/toledo-pr/correntina-ba'},
    {link: '/passagem-de-onibus/toledo-pr/catalao-go'},
    {link: '/passagem-de-onibus/toledo-pr/formosa-go'},
    {link: '/passagem-de-onibus/toledo-pr/araguari-mg'},
    {link: '/passagem-de-onibus/toledo-pr/cristalina-go'},
    {link: '/passagem-de-onibus/toledo-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/toledo-pr/penapolis-sp'},
    {link: '/passagem-de-onibus/toledo-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/cruzalta-rs/correntina-ba'},
    {link: '/passagem-de-onibus/cruzalta-rs/marmeleiro-pr'},
    {link: '/passagem-de-onibus/umuarama-pr/abelardoluz-sc'},
    {link: '/passagem-de-onibus/umuarama-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/umuarama-pr/correntina-ba'},
    {link: '/passagem-de-onibus/umuarama-pr/catalao-go'},
    {link: '/passagem-de-onibus/umuarama-pr/formosa-go'},
    {link: '/passagem-de-onibus/umuarama-pr/araguari-mg'},
    {link: '/passagem-de-onibus/irai-rs/uberlandia-mg'},
    {link: '/passagem-de-onibus/irai-rs/posse-go'},
    {link: '/passagem-de-onibus/irai-rs/rodavelha-ba'},
    {link: '/passagem-de-onibus/luziania-go/cascavel-pr'},
    {link: '/passagem-de-onibus/luziania-go/maringa-pr'},
    {link: '/passagem-de-onibus/luziania-go/panambi-rs'},
    {link: '/passagem-de-onibus/luziania-go/palmeiradasmissoes-rs'},
    {link: '/passagem-de-onibus/luziania-go/patobranco-pr'},
    {link: '/passagem-de-onibus/luziania-go/cianorte-pr'},
    {link: '/passagem-de-onibus/luziania-go/fredericowestphalen-rs'},
    {link: '/passagem-de-onibus/luziania-go/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/luziania-go/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/luziania-go/toledo-pr'},
    {link: '/passagem-de-onibus/luziania-go/umuarama-pr'},
    {link: '/passagem-de-onibus/luziania-go/irai-rs'},
    {link: '/passagem-de-onibus/luziania-go/assischateaubriand-pr'},
    {link: '/passagem-de-onibus/irai-rs/alvoradadonorte-go'},
    {link: '/passagem-de-onibus/irai-rs/barreiras-ba'},
    {link: '/passagem-de-onibus/irai-rs/correntina-ba'},
    {link: '/passagem-de-onibus/umuarama-pr/posse-go'},
    {link: '/passagem-de-onibus/umuarama-pr/brasilia-df'},
    {link: '/passagem-de-onibus/luziania-go/abelardoluz-sc'},
    {link: '/passagem-de-onibus/umuarama-pr/cristalina-go'},
    {link: '/passagem-de-onibus/umuarama-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/umuarama-pr/penapolis-sp'},
    {link: '/passagem-de-onibus/umuarama-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/irai-rs/catalao-go'},
    {link: '/passagem-de-onibus/irai-rs/marmeleiro-pr'},
    {link: '/passagem-de-onibus/irai-rs/formosa-go'},
    {link: '/passagem-de-onibus/irai-rs/araguari-mg'},
    {link: '/passagem-de-onibus/irai-rs/cristalina-go'},
    {link: '/passagem-de-onibus/irai-rs/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/cruzalta-rs/capitaoleonidasmarques-pr'},
    {link: '/passagem-de-onibus/cruzalta-rs/penapolis-sp'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/cristalina-go'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/penapolis-sp'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/irai-rs/brasilia-df'},
    {link: '/passagem-de-onibus/irai-rs/luziania-go'},
    {link: '/passagem-de-onibus/xaxim-sc/barreiras-ba'},
    {link: '/passagem-de-onibus/xaxim-sc/correntina-ba'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/abelardoluz-sc'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/barreiras-ba'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/correntina-ba'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/catalao-go'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/formosa-go'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/araguari-mg'},
    {link: '/passagem-de-onibus/xaxim-sc/posse-go'},
    {link: '/passagem-de-onibus/xaxim-sc/brasilia-df'},
    {link: '/passagem-de-onibus/xaxim-sc/catalao-go'},
    {link: '/passagem-de-onibus/xaxim-sc/formosa-go'},
    {link: '/passagem-de-onibus/xaxim-sc/araguari-mg'},
    {link: '/passagem-de-onibus/xaxim-sc/cristalina-go'},
    {link: '/passagem-de-onibus/xaxim-sc/luiseduardomagalhaes-ba'},
    {link: '/passagem-de-onibus/xaxim-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/posse-go'},
    {link: '/passagem-de-onibus/assischateaubriand-pr/brasilia-df'},
];
export default cantelle;