import {recuperarConfEmpresa} from '../util/applicationContext';

export const MARCAR_PROMOCAO_VISUALIZADO = '@promocoesActions/MARCAR_PROMOCAO_VISUALIZADO';

const intialState = {
    site: null,
    marcado: false
}

export default function reducerPromocao(state = intialState, action) {
    switch (action.type) {
        case MARCAR_PROMOCAO_VISUALIZADO: {
            return {
                ...state,
                site: recuperarConfEmpresa().id,
                marcado: true
            };
        }

        default: {
            return state
        }
    }

}
