
import React, { Component } from 'react';
import { recuperarConfEmpresa } from '../../util/applicationContext';


const BannerQueroUmaAgencia = () => {
  return (
    <div className='container-banner-quero-uma-agencia'>
      <div>
        <a href="https://queroumaagencia.com/" target="_blank" rel="noopener noreferrer">
          <img
            src={recuperarConfEmpresa().banner}
            className="banner-quero-uma-agencia"
            alt="Acesse nosso site Quero uma Agencia"
          />
        </a>
      </div>
    </div>
  );
};

const BannerMobileQueroUmaAgencia = () => {
  return (
    <div className='container-banner-quero-uma-agencia'>
      <div>
        <a href="https://queroumaagencia.com/" target="_blank" rel="noopener noreferrer">
          <img
            src={recuperarConfEmpresa().bannerMobile}
            className="banner-quero-uma-agencia-mobile"
            alt="Acesse nosso site Quero uma Agencia"
          />
        </a>
      </div>
    </div>
  );
};

export class CardQueroUmaAgencia extends Component {


  render() {
    return (
      <div>
        {window.matchMedia('(min-width:601px)').matches ? <BannerQueroUmaAgencia /> 
          : <BannerMobileQueroUmaAgencia />}
      </div>
    )
  }

}


export default CardQueroUmaAgencia;