import axios from 'axios'
import {recuperarConfEmpresa} from '../util/applicationContext';

axios.interceptors.request.use(
    (config) => {
        config.headers.site = recuperarConfEmpresa().id;

        return config;
    }
);
