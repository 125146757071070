import React from 'react';
import iconVexado from '../../../estilo/comum/img/icon/vexado.ico'
import logo from '../../../estilo/comum/img/logo-03.png'
import ConteudoQuemSomosVX from '../../../componentes/quem-somos/vexado/ConteudoQuemSomosVX';
import dadosSiteComum from './dadosSiteComum';
import ConteudoQueroUmaAgencia from '../../../componentes/layout/queroUmaAgencia/ConteudoQueroUmaAgencia';
// import bannerQueroUmaAgencia from '../../../estilo/comum/img/queroUmaAgencia/2.webp';
// import bannerMobileQueroUmaAgencia from '../../../estilo/comum/img/queroUmaAgencia/2.mobile.webp';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/8.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/15.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoVexado.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/16.webp';

const logoVexadoCabecalho = <img className="logo-site vexado" src={logo} alt='Logo Vexado'/>;
const logoVexadoRodape = <img className="vexado" src={logo} alt="Logo Vexado" style={{width: '45%'}}/>;

export const dadosVexado = {
    ...dadosSiteComum,
    id: 'vexado',
    pastaEstilo: 'vexado',
    nome: 'Vexado',
    razaoSocial: 'VEXADO PROMOTORA DE VENDAS E INTERMEDIAÇÕES DE NEGÓCIOS EIRELI',
    cnpj: '32.843.031/0001-09',
    endereco:'Q SCS Quadra 01, Bloco M, número 30, Sala 508, Asa Sul/DF, CEP: 70.305-900',
    nomePrefixo: 'o Vexado',
    telefone: '(61) 98153-7600',
    wtpp: '(61) 98153-7600',
    instagram: 'https://www.instagram.com/vexadooficial',
    tiktok: 'https://www.tiktok.com/@vexadoo',
    facebook: 'https://www.facebook.com/vexadovendadepassagens',
    youtube: 'youtube.com',
    twitter: '@Vexado',
    email: 'contato@vexado.com.br',
    emailSuporte: 'suporte@vexado.com.br',
    lkemailSuporte: 'mailto:suporte@vexado.com.br',
    lktelefone: 'tel:5561981537600',
    lkemail: 'mailto:contato@vexado.com.br',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561981537600&text=Olá. Preciso de atendimento.',
    logo: logo,
    logoCabecacho: logoVexadoCabecalho,
    logoRodape: logoVexadoRodape,
    OgImage: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/vexado-passagens/vexado+passagens.webp',
    icone: iconVexado,
    quemSomos: ConteudoQuemSomosVX,
    queroUmaAgencia: ConteudoQueroUmaAgencia,
    videoInicio: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/video-back.mp4',
    idsTagManager: ['GTM-PHGZ336'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+desconto+vexado.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/2.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/2.mobile.webp',
    urlBase: 'https://vexado.com.br'
};
