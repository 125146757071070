const PREPOSICOES = ['de', 'da', 'do', 'e', 'no', 'na'];

const capitalizarTodasPrimeirasLetras = (texto) => {
  if (!texto) {
    return texto
  }

  let resultado = '';

  const palavras = texto.split(' ');

  palavras.forEach((palavra) => {
    if (!PREPOSICOES.includes(palavra.toLowerCase())) {
      resultado += `${palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()} `
    } else {
      resultado += `${palavra.toLowerCase()} `
    }
  });

  return resultado.trim()
};

export default capitalizarTodasPrimeirasLetras
