const util = [

    {link: '/passagem-de-onibus/barbacena-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/angradosreis-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/cabofrio-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/barramansa-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/tresrios-rj'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/resende-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/niteroi-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/voltaredonda-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/angradosreis-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/voltaredonda-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/cabofrio-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/barradopirai-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/macae-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/vassouras-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/resende-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodasostras-rj'},
    {link: '/passagem-de-onibus/barradopirai-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/vassouras-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/macae-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/petropolis-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodasostras-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/barramansa-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/atibaia-sp/itumirim-mg-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/nazareno-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/carmodacachoeira-mg'},
    {link: '/passagem-de-onibus/barroso-mg/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/barroso-mg/campinas-sp'},
    {link: '/passagem-de-onibus/barroso-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/barroso-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/aparecida-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/betim-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/cambuquira-mg'},
    {link: '/passagem-de-onibus/voltaredonda-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/goiania-go/coxipo-mt'},
    {link: '/passagem-de-onibus/aparecida-sp/trescoracoes-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/aparecida-sp/ourobranco-mg'},
    {link: '/passagem-de-onibus/angradosreis-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/angradosreis-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/angradosreis-rj/santosdumont-mg'},
    {link: '/passagem-de-onibus/angradosreis-rj/saosebastiao-sp'},
    {link: '/passagem-de-onibus/angradosreis-rj/ubatuba-sp'},
    {link: '/passagem-de-onibus/angradosreis-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/altogarcas-mt/uberaba-mg'},
    {link: '/passagem-de-onibus/altogarcas-mt/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/altogarcas-mt/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/altogarcas-mt/resende-rj'},
    {link: '/passagem-de-onibus/altogarcas-mt/itumbiara-go'},
    {link: '/passagem-de-onibus/barroso-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/barroso-mg/santos-sp'},
    {link: '/passagem-de-onibus/itutinga-mg/atibaia-sp'},
    {link: '/passagem-de-onibus/itutinga-mg/campinas-sp'},
    {link: '/passagem-de-onibus/itutinga-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/itutinga-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/itutinga-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/itutinga-mg/santos-sp'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/resende-rj'},
    {link: '/passagem-de-onibus/voltaredonda-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/voltaredonda-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/voltaredonda-rj/santosdumont-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/barroso-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/itutinga-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/lavras-mg'},
    {link: '/passagem-de-onibus/atibaia-sp/carandai-mg'},
    {link: '/passagem-de-onibus/altoaraguaia-mt/itumbiara-go'},
    {link: '/passagem-de-onibus/trescoracoes-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/trescoracoes-mg/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/trescoracoes-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/trescoracoes-mg/taubate-sp'},
    {link: '/passagem-de-onibus/angradosreis-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/cruzeiro-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/guarani-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/sorocaba-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/barradopirai-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/macae-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/merces-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/niteroi-rj'},
    {link: '/passagem-de-onibus/pousoalegre-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/bomsucesso,mg-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/bomsucesso,mg-mg/betim-mg'},
    {link: '/passagem-de-onibus/bomsucesso,mg-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/bomsucesso,mg-mg/santoantoniodoamparo-mg'},
    {link: '/passagem-de-onibus/bomsucesso,mg-mg/itatiaiucu-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/mogidascruzes-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/vassouras-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riopomba-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/resende-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodasostras-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/rionovo-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/santoantoniodoamparo-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saojoaonepomuceno-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/saosebastiao-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/barramansa-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/tresrios-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itatiaiucu-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/niteroi-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/angradosreis-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/voltaredonda-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/bomsucesso,mg-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/cabofrio-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/campogrande,rj-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/caraguatatub%D0%B0-sp'},
    {link: '/passagem-de-onibus/congonhas-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/congonhas-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/congonhas-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/lavras-mg/atibaia-sp'},
    {link: '/passagem-de-onibus/lavras-mg/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/lavras-mg/campinas-sp'},
    {link: '/passagem-de-onibus/lavras-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/lavras-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/lavras-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/taubate-sp'},
    {link: '/passagem-de-onibus/belohorizonte-mg/itaguara-mg'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/belohorizonte-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/cabofrio-rj/betim-mg'},
    {link: '/passagem-de-onibus/cabofrio-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/campogrande,rj-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/bomsucesso,mg-mg/itaguara-mg'},
    {link: '/passagem-de-onibus/cabofrio-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/cabofrio-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/itaguai-rj/saosebastiao-sp'},
    {link: '/passagem-de-onibus/cuiaba-mt/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/cuiaba-mt/resende-rj'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/resende-rj'},
    {link: '/passagem-de-onibus/paracatu-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/paracatu-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/santosdumont-mg'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/lagoadourada-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/lagoadourada-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/altogarcas-mt'},
    {link: '/passagem-de-onibus/uberaba-mg/coxipo-mt'},
    {link: '/passagem-de-onibus/uberaba-mg/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/uberaba-mg/rioverde-go'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/barroso-mg'},
    {link: '/passagem-de-onibus/joaopinheiro-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/joaopinheiro-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/sorocaba-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/sorocaba-sp/betim-mg'},
    {link: '/passagem-de-onibus/barradopirai-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/barradopirai-rj/ourobranco-mg'},
    {link: '/passagem-de-onibus/barradopirai-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/barradopirai-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/valencaba-ba/juizdefora-mg'},
    {link: '/passagem-de-onibus/cruzeiro-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/cruzeiro-sp/betim-mg'},
    {link: '/passagem-de-onibus/entreriosdeminas-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/entreriosdeminas-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/guarani-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/guarani-mg/merces-mg'},
    {link: '/passagem-de-onibus/guarani-mg/riopomba-mg'},
    {link: '/passagem-de-onibus/guarani-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/guarani-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/guarani-mg/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/coxipo-mt'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/uberaba-mg'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/uberlandia-mg'},
    {link: '/passagem-de-onibus/santaritadoaraguaia-go/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/lavras-mg/santos-sp'},
    {link: '/passagem-de-onibus/contagem-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/coxipo-mt/goiania-go'},
    {link: '/passagem-de-onibus/coxipo-mt/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/coxipo-mt/uberaba-mg'},
    {link: '/passagem-de-onibus/coxipo-mt/uberlandia-mg'},
    {link: '/passagem-de-onibus/coxipo-mt/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/coxipo-mt/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/coxipo-mt/jatai-go'},
    {link: '/passagem-de-onibus/coxipo-mt/rioverde-go'},
    {link: '/passagem-de-onibus/coxipo-mt/resende-rj'},
    {link: '/passagem-de-onibus/coxipo-mt/mineiros-go'},
    {link: '/passagem-de-onibus/coxipo-mt/itumbiara-go'},
    {link: '/passagem-de-onibus/caraguatatub%D0%B0-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/caraguatatub%D0%B0-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/caraguatatub%D0%B0-sp/betim-mg'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/lavras-mg'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/carandai-mg'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/nazareno-mg'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/coxipo-mt'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/cuiaba-mt'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/paracatu-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/carandai-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/mariana-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/ourobranco-mg'},
    {link: '/passagem-de-onibus/uberlandia-mg/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/altogarcas-mt'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/rondonopolis-mt'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/rioverde-go'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/santosdumont-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/saosebastiao-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/ubatuba-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/mineiros-go'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/ouropreto-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/santos-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/itumbiara-go'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/congonhas-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/contagem-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/coxipo-mt'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/caraguatatub%D0%B0-sp'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/joaopinheiro-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/jatai-go'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/betim-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/jaciara-mt'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/riopomba-mg/pirauba-mg'},
    {link: '/passagem-de-onibus/riopomba-mg/santabarbaradotugurio-mg'},
    {link: '/passagem-de-onibus/riopomba-mg/saojoaonepomuceno-mg'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/atibaia-sp'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/campinas-sp'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/saojoaodelrei-mg/santos-sp'},
    {link: '/passagem-de-onibus/ourobranco-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/mogidascruzes-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/mogidascruzes-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/mogidascruzes-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/itumirim-mg-mg/campinas-sp'},
    {link: '/passagem-de-onibus/itumirim-mg-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/itumirim-mg-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/vassouras-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/vassouras-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/vassouras-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/mariana-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/mariana-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/mariana-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/nazareno-mg/atibaia-sp'},
    {link: '/passagem-de-onibus/nazareno-mg/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/nazareno-mg/campinas-sp'},
    {link: '/passagem-de-onibus/nazareno-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/ourobranco-mg'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/betim-mg'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/cambuquira-mg'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/tresrios-rj'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/juizdefora-mg'},
    {link: '/passagem-de-onibus/merces-mg/santabarbaradotugurio-mg'},
    {link: '/passagem-de-onibus/carandai-mg/atibaia-sp'},
    {link: '/passagem-de-onibus/carandai-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/carandai-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/carandai-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/carandai-mg/santos-sp'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/trescoracoes-mg'},
    {link: '/passagem-de-onibus/saojosedoscampos-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/campinas-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/riopomba-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riopomba-mg/merces-mg'},
    {link: '/passagem-de-onibus/riopomba-mg/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/nazareno-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/nazareno-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/nazareno-mg/santos-sp'},
    {link: '/passagem-de-onibus/campinas-sp/barroso-mg'},
    {link: '/passagem-de-onibus/campinas-sp/itutinga-mg'},
    {link: '/passagem-de-onibus/campinas-sp/lavras-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/macae-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/macae-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/macae-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/macae-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/merces-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/merces-mg/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/merces-mg/pirauba-mg'},
    {link: '/passagem-de-onibus/campinas-sp/itumirim-mg-mg'},
    {link: '/passagem-de-onibus/campinas-sp/nazareno-mg'},
    {link: '/passagem-de-onibus/campinas-sp/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/ourobranco-mg/barradopirai-rj'},
    {link: '/passagem-de-onibus/ourobranco-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/ourobranco-mg/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/ourobranco-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/ourobranco-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/ourobranco-mg/barramansa-rj'},
    {link: '/passagem-de-onibus/ourobranco-mg/tresrios-rj'},
    {link: '/passagem-de-onibus/ourobranco-mg/taubate-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/angradosreis-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/voltaredonda-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/atibaia-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/cabofrio-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/barradopirai-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/mineiros-go'},
    {link: '/passagem-de-onibus/jatai-go/coxipo-mt'},
    {link: '/passagem-de-onibus/jatai-go/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/barramansa-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/tresrios-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/jundiai-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/barbacena-mg/taubate-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/santos-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/altogarcas-mt'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/coxipo-mt'},
    {link: '/passagem-de-onibus/jatai-go/resende-rj'},
    {link: '/passagem-de-onibus/betim-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/betim-mg/bomsucesso,mg-mg'},
    {link: '/passagem-de-onibus/betim-mg/carvalhos-mg'},
    {link: '/passagem-de-onibus/betim-mg/igarape-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/rioverde-go'},
    {link: '/passagem-de-onibus/jaciara-mt/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/jaciara-mt/resende-rj'},
    {link: '/passagem-de-onibus/jaciara-mt/itumbiara-go'},
    {link: '/passagem-de-onibus/barbacena-mg/macae-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/mogidascruzes-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/vassouras-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/campinas-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/pirauba-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/resende-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/riodasostras-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/rionovo-mg'},
    {link: '/passagem-de-onibus/barbacena-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/barbacena-mg/saojoaonepomuceno-mg'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/resende-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/riodasostras-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/saojoaonepomuceno-mg'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/barramansa-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/tresrios-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/taubate-sp'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/santos-sp'},
    {link: '/passagem-de-onibus/petropolis-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/petropolis-rj/joaopinheiro-mg'},
    {link: '/passagem-de-onibus/petropolis-rj/paracatu-mg'},
    {link: '/passagem-de-onibus/petropolis-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/petropolis-rj/brasilia-df'},
    {link: '/passagem-de-onibus/petropolis-rj/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/ourobranco-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/carmodacachoeira-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/ouropreto-mg'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/angradosreis-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/voltaredonda-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/atibaia-sp'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/cabofrio-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/guarani-mg'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/barradopirai-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/macae-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/merces-mg'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/mogidascruzes-sp'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/vassouras-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/riopomba-mg'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/conselheirolafaiete-mg/pirauba-mg'},
    {link: '/passagem-de-onibus/betim-mg/cruzeiro-sp'},
    {link: '/passagem-de-onibus/betim-mg/sorocaba-sp'},
    {link: '/passagem-de-onibus/betim-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/betim-mg/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/betim-mg/saosebastiao-sp'},
    {link: '/passagem-de-onibus/betim-mg/niteroi-rj'},
    {link: '/passagem-de-onibus/betim-mg/taubate-sp'},
    {link: '/passagem-de-onibus/paraty-rj/saosebastiao-sp'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/barroso-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/itutinga-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/congonhas-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/lavras-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/entreriosdeminas-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/lagoadourada-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/carandai-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/itumirim-mg-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/mariana-mg'},
    {link: '/passagem-de-onibus/bragancapaulista-sp/nazareno-mg'},
    {link: '/passagem-de-onibus/betim-mg/santoantoniodoamparo-mg'},
    {link: '/passagem-de-onibus/betim-mg/itatiaiucu-mg'},
    {link: '/passagem-de-onibus/betim-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/betim-mg/oliveira-mg'},
    {link: '/passagem-de-onibus/betim-mg/itaguara-mg'},
    {link: '/passagem-de-onibus/betim-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/betim-mg/cabofrio-rj'},
    {link: '/passagem-de-onibus/betim-mg/caraguatatub%D0%B0-sp'},
    {link: '/passagem-de-onibus/rondonopolis-mt/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/rondonopolis-mt/resende-rj'},
    {link: '/passagem-de-onibus/saopaulo-sp/ouropreto-mg'},
    {link: '/passagem-de-onibus/santoantoniodoamparo-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/santoantoniodoamparo-mg/bomsucesso,mg-mg'},
    {link: '/passagem-de-onibus/santoantoniodoamparo-mg/igarape-mg'},
    {link: '/passagem-de-onibus/santoantoniodoamparo-mg/betim-mg'},
    {link: '/passagem-de-onibus/santoantoniodoamparo-mg/itatiaiucu-mg'},
    {link: '/passagem-de-onibus/saojoaonepomuceno-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saojoaonepomuceno-mg/merces-mg'},
    {link: '/passagem-de-onibus/saojoaonepomuceno-mg/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/saojoaonepomuceno-mg/pirauba-mg'},
    {link: '/passagem-de-onibus/riodasflores-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/rionovo-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/barroso-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/itutinga-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/bomsucesso,mg-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/congonhas-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/lavras-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/entreriosdeminas-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/lagoadourada-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/carandai-mg'},
    {link: '/passagem-de-onibus/resende-rj/rioverde-go'},
    {link: '/passagem-de-onibus/resende-rj/mineiros-go'},
    {link: '/passagem-de-onibus/riodasostras-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/riodasostras-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/riodasostras-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/riodasostras-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/rioverde-go/coxipo-mt'},
    {link: '/passagem-de-onibus/rioverde-go/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saopaulo-sp/itumirim-mg-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/mariana-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/nazareno-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/ourobranco-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/nepomuceno-mg'},
    {link: '/passagem-de-onibus/saopaulo-sp/carmodacachoeira-mg'},
    {link: '/passagem-de-onibus/resende-rj/jaciara-mt'},
    {link: '/passagem-de-onibus/resende-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/resende-rj/jatai-go'},
    {link: '/passagem-de-onibus/resende-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/resende-rj/rondonopolis-mt'},
    {link: '/passagem-de-onibus/resende-rj/altogarcas-mt'},
    {link: '/passagem-de-onibus/resende-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/resende-rj/coxipo-mt'},
    {link: '/passagem-de-onibus/resende-rj/cuiaba-mt'},
    {link: '/passagem-de-onibus/resende-rj/santaritadoaraguaia-go'},
    {link: '/passagem-de-onibus/rioverde-go/resende-rj'},
    {link: '/passagem-de-onibus/pirauba-mg/merces-mg'},
    {link: '/passagem-de-onibus/pirauba-mg/riopomba-mg'},
    {link: '/passagem-de-onibus/pirauba-mg/barbacena-mg'},
    {link: '/passagem-de-onibus/pirauba-mg/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/resende-rj/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/juizdefora-mg/taubate-sp'},
    {link: '/passagem-de-onibus/juizdefora-mg/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/nepomuceno-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/carmodacachoeira-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/carmodacachoeira-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/carmodacachoeira-mg/santos-sp'},
    {link: '/passagem-de-onibus/oliveira-mg/belohorizonte-mg'},
    {link: '/passagem-de-onibus/oliveira-mg/betim-mg'},
    {link: '/passagem-de-onibus/saosebastiao-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/saosebastiao-sp/itaguai-rj'},
    {link: '/passagem-de-onibus/saosebastiao-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saosebastiao-sp/betim-mg'},
    {link: '/passagem-de-onibus/saosebastiao-sp/paraty-rj'},
    {link: '/passagem-de-onibus/barramansa-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/pousoalegre-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/lavras-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/itumirim-mg-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/nazareno-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/barbacena-mg'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/petropolis-rj'},
    {link: '/passagem-de-onibus/valparaisodegoias-go/juizdefora-mg'},
    {link: '/passagem-de-onibus/tresrios-rj/ourobranco-mg'},
    {link: '/passagem-de-onibus/tresrios-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/tresrios-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/tresrios-rj/taubate-sp'},
    {link: '/passagem-de-onibus/ubatuba-sp/angradosreis-rj'},
    {link: '/passagem-de-onibus/ubatuba-sp/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/jundiai-sp/trescoracoes-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/barroso-mg'},
    {link: '/passagem-de-onibus/jundiai-sp/itutinga-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/angradosreis-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/valencaba-ba'},
    {link: '/passagem-de-onibus/juizdefora-mg/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/macae-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/juizdefora-mg/riodasostras-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/riodasflores-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/valparaisodegoias-go'},
    {link: '/passagem-de-onibus/barramansa-rj/ourobranco-mg'},
    {link: '/passagem-de-onibus/barramansa-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/barramansa-rj/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/barramansa-rj/santosdumont-mg'},
    {link: '/passagem-de-onibus/cambuquira-mg/aparecida-sp'},
    {link: '/passagem-de-onibus/cambuquira-mg/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/cambuquira-mg/taubate-sp'},
    {link: '/passagem-de-onibus/tresrios-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/tresrios-rj/saojosedoscampos-sp'},
    {link: '/passagem-de-onibus/mineiros-go/resende-rj'},
    {link: '/passagem-de-onibus/niteroi-rj/belohorizonte-mg'},
    {link: '/passagem-de-onibus/niteroi-rj/betim-mg'},
    {link: '/passagem-de-onibus/taubate-sp/trescoracoes-mg'},
    {link: '/passagem-de-onibus/taubate-sp/belohorizonte-mg'},
    {link: '/passagem-de-onibus/taubate-sp/ourobranco-mg'},
    {link: '/passagem-de-onibus/taubate-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/taubate-sp/betim-mg'},
    {link: '/passagem-de-onibus/brasilia-df/petropolis-rj'},
    {link: '/passagem-de-onibus/santosdumont-mg/angradosreis-rj'},
    {link: '/passagem-de-onibus/santosdumont-mg/voltaredonda-rj'},
    {link: '/passagem-de-onibus/santosdumont-mg/duquedecaxias-rj'},
    {link: '/passagem-de-onibus/santosdumont-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/santosdumont-mg/barramansa-rj'},
    {link: '/passagem-de-onibus/saosebastiao-sp/angradosreis-rj'},
    {link: '/passagem-de-onibus/mineiros-go/coxipo-mt'},
    {link: '/passagem-de-onibus/mineiros-go/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/mineiros-go/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/santos-sp/itumirim-mg-mg'},
    {link: '/passagem-de-onibus/santos-sp/nazareno-mg'},
    {link: '/passagem-de-onibus/santos-sp/saojoaodelrei-mg'},
    {link: '/passagem-de-onibus/santos-sp/barbacena-mg'},
    {link: '/passagem-de-onibus/santos-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/santos-sp/carmodacachoeira-mg'},
    {link: '/passagem-de-onibus/itumbiara-go/altoaraguaia-mt'},
    {link: '/passagem-de-onibus/itumbiara-go/altogarcas-mt'},
    {link: '/passagem-de-onibus/itumbiara-go/coxipo-mt'},
    {link: '/passagem-de-onibus/itumbiara-go/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/itumbiara-go/jaciara-mt'},
    {link: '/passagem-de-onibus/ouropreto-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/ouropreto-mg/bragancapaulista-sp'},
    {link: '/passagem-de-onibus/ouropreto-mg/saopaulo-sp'},
    {link: '/passagem-de-onibus/santos-sp/barroso-mg'},
    {link: '/passagem-de-onibus/santos-sp/itutinga-mg'},
    {link: '/passagem-de-onibus/santos-sp/lavras-mg'},
    {link: '/passagem-de-onibus/santos-sp/carandai-mg'},
    {link: '/passagem-de-onibus/taubate-sp/conselheirolafaiete-mg'},
    {link: '/passagem-de-onibus/taubate-sp/cambuquira-mg'},
    {link: '/passagem-de-onibus/taubate-sp/tresrios-rj'},
    {link: '/passagem-de-onibus/taubate-sp/juizdefora-mg'},
    {link: '/passagem-de-onibus/itaguara-mg/belohorizonte-mg'},
];
export default util;