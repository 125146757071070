import React from 'react';
import logoBp from '../../../estilo/comum/img/logo-brasilp.png';
import iconBrasilPassagens from '../../../estilo/comum/img/icon/brasilpassagens.ico';
import ConteudoQuemSomosBP from '../../../componentes/quem-somos/brasil-passagens/ConteudoQuemSomosBP';
import dadosSiteComum from './dadosSiteComum';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/11.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/19.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoBrasilpassagens.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/20.webp';

const logoBrasilCabecalho = <img className="logo-site brasilp" src={logoBp} alt='Logo Brasil Passagens'/>;
const logoBrasilRodape = <img className="brasilp" src={logoBp} alt="Logo Brasil Passagens" style={{width: '45%'}}/>;

export const dadosBrasilPassagem = {
    ...dadosSiteComum,
    id: 'brasilpassagens',
    pastaEstilo: 'brasilpassagens',
    nome: 'Brasil Passagens',
    nomePrefixo: 'o Brasil Passagens',
    razaoSocial: 'VEXADO PROMOTORA DE VENDAS E INTERMEDIAÇÕES DE NEGÓCIOS EIRELI',
    cnpj: '32.843.031/0001-09',
    endereco:'Q SCS Quadra 01, Bloco M, número 30, Sala 508, Asa Sul/DF, CEP: 70.305-900',
    telefone: '(61) 98153-7600',
    wtpp: '(61) 98153-7600',
    instagram: 'https://instagram.com/brasilpassagensoficial?utm_medium=copy_link',
    tiktok: 'https://www.tiktok.com/@vexadoo',
    facebook: 'https://www.facebook.com/brasilpassagensoficial/',
    youtube: 'youtube.com',
    email: 'contato@brasilpassagens.com.br',
    emailSuporte: 'suporte@brasilpassagens.com.br',
    lkemailSuporte: 'mailto:suporte@brasilpassagens.com.br',
    lktelefone: 'tel:556161981537600',
    twitter: '@BrasilPassagens',
    lkemail: 'mailto:contato@brasilpassagens.com.br',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561981537600&text=Olá. Preciso de atendimento.',
    logo: logoBp,
    logoCabecacho: logoBrasilCabecalho,
    logoRodape: logoBrasilRodape,
    OgImage: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/brasil-passagens/Brasil+passagens.webp',
    description: 'Compre passagens de ônibus sem sair de casa. São diversas opções de horários e viações para você escolher.',
    icone: iconBrasilPassagens,
    quemSomos: ConteudoQuemSomosBP,
    videoInicio: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/video-backbp2.mp4',
    idsTagManager: ['GTM-PHGZ336'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+descontoBrasilpassagens.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/1.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/1.mobile.webp',
    urlBase: 'https://brasilpassagens.com.br'
};
