export const MOSTRAR_LOADING = '@loadingReducer/MOSTRAR_LOADING';
export const FECHAR_LOADING = '@loadingReducer/FECHAR_LOADING';
export const MOSTRAR_LOADING_INTEG = '@loadingReducer/MOSTRAR_LOADING_INTEG';
export const FECHAR_LOADING_INTEG = '@loadingReducer/FECHAR_LOADING_INTEG';
const initialState = {loading: false, qtdOperacoesEmAndamento: 0, loadingInteg: false};

export default function (state = initialState, action) {
    let qtd = state.qtdOperacoesEmAndamento;

    switch (action.type) {
        case MOSTRAR_LOADING: {
            qtd += 1;
            return {loading: true, qtdOperacoesEmAndamento: qtd, loadingInteg: false}
        }

        case FECHAR_LOADING: {
            qtd -= 1;

            if (qtd > 0) {
                return {loading: true, qtdOperacoesEmAndamento: qtd, loadingInteg: false}
            }

            return {loading: false, qtdOperacoesEmAndamento: qtd, loadingInteg: true}
        }

        case MOSTRAR_LOADING_INTEG: {
            if (action.payload) {
                return {loading: false, loadingInteg: true}
            }

            return state
        }

        case FECHAR_LOADING_INTEG: {
            return {loading: false, loagindInteg: false}
        }

        default:
            return state
    }
}
