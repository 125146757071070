import store from '../store/configureStore'
import {fecharLoading, mostrarLoading, mostrarLoadingInteg, fecharLoadingInteg} from '../actions/loadingActions'


export const SLOW_REQUEST_HEADER = 'X-Slow-Request'

export const SLOW_REQUEST_HEADER_INT_DIST = 'X-Slow-Request'

export const SLOW_REQUEST_CONFIG = {
    headers: {
        [SLOW_REQUEST_HEADER]: true,
    },
}

export const SLOW_REQUEST_CONFIG_INT_DIST = {
    headers: {
        [SLOW_REQUEST_HEADER_INT_DIST]: true,
        'authenticationKey': 'IntDistribusion.69D*V9n@P0BJ9p%%krKx#PD1vd'
    },
}

export const iniciarLoading = () => {
    store.dispatch(mostrarLoading())
}

export const finalizarLoading = () => {
    store.dispatch(fecharLoading())
}

export const iniciarLoadingInteg = (ehBusca) => {
    store.dispatch(mostrarLoadingInteg(ehBusca))
}

export const finalizarLoadingInteg = () => {
    store.dispatch(fecharLoadingInteg())
}
