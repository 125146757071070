import React, {Component} from 'react'
import {recuperarConfEmpresa} from '../util/applicationContext';

class PoliticaCookies extends Component {
    render() {
        let confEmpresa = recuperarConfEmpresa();
        let nomeEmpresa = confEmpresa.nome.toUpperCase();
        let siteEmpresa = window.location.host;
        return (
            <div className='conteudo'>
                <div className='col-12 conteudo-internas'>
                    <div className='card border-0'>
                        <div className='card-body'>
                            <div className='loginHeader'>
                            <h1 id='secao-politica-cookies'>Política de cookies</h1>
                                <div className="divider"/>
                            </div>
                            <div className='card'>
                                <div className='card-body informacoes'>
                                    <strong><h1>POLÍTICA DE COOKIES – {nomeEmpresa}</h1></strong><br/>
                                    <p>Atualizada em 12 de Setembro de 2022 </p>
                                    <p><strong>1. INTRODUÇÃO:</strong></p>
                                    <p>A presente Política de Cookies aplica-se ao site <a href={'https://'+ siteEmpresa}><u>{'https://'+ siteEmpresa}</u></a>,
                                        de gestão admistrativa de, <strong>VEXADO PROMOTORA DE VENDAS E INTERMEDIAÇÕES DE NEGÓCIO EIRELI,
                                        inscrita no CNPJ/MF sob o n° 32.843.031/0001-09, com sede na Cidade de Brasília, na Quadra SCS 01,
                                        Bloco M, sala 508, Asa Sul, CEP: 70.30-900, visa proporcionar uma experiência ainda mais transparante
                                        em nosso site, apresentando o conceito de Cookies, como os utilizamos para melhorar sua navegação e,
                                        ainda, as possibilidade de customização de uso.</strong></p>
                                    <p>Caso não concorde com o uso de Cookies da forma apresentada, você poderá: (i) ajustar as configurações de 
                                        seu navegador de internet para não permitir o uso de Cookies ou (ii) não acessar o site.</p>
                                    <p>Lembramos que desabilitar o uso de Cookies poderá impactar sua experiência ao navegar no site.</p>
                                    <p>Recomendamos também a leitura da nossa Política de Privacidade (<a href={'/politica-privacidade'}><u>{'https://'+ siteEmpresa +'/politica-privacidade'}</u></a>), 
                                        na qual trazemos à você mais informações sobre nosso compromisso com a sua privacidade e a proteção 
                                        dos seus dados pessoais.</p>
                                    <p><strong>2. O QUE SÃO COOKIES?</strong></p>
                                    <p>Cookies são pequenos arquivos digitais em formato de texto que são armazenados no seu dispositivo (computador, smartphone, 
                                        tablet, etc.) pelo navegador de internet e que guardam informações relacionadas às suas preferências, como idioma preferido, 
                                        localização, recorrência das suas sessões, e outras variáveis que os desenvolvedores consideram relevantes para tornar a sua experiência muito mais eficiente.</p>
                                    <p><strong>3. PARA QUE SERVEM OS COOKIES?</strong></p>
                                    <p>Os Cookies servem para aprimorar a sua experiência, tanto em termos de performance, como em termos de usabilidade, uma vez que os conteúdos 
                                        disponibilizados serão direcionados às suas necessidades e expectativas. </p>
                                    <p>Também poderão ser utilizados para compilar estatísticas anônimas e agregadas que permitem entender
                                        como você utiliza o site, bem como para aprimorar suas estruturas e conteúdo. Por serem estatísticas anônimas, 
                                        não é possível identificá-lo pessoalmente por meio desses dados.</p>
                                    <p>A utilização de Cookies é algo muito comum em qualquer plataforma digital atualmente. O seu uso não prejudica de forma 
                                        alguma os dispositivos em que são armazenados.</p>
                                    <p><strong>4. QUE TIPO DE COOKIES UTILIZAMOS</strong></p>
                                    <p>Nós utilizamos Cookies próprios e de terceiros no site, os quais podem ser classificados quanto à sua temporalidade:</p>
                                    <p><strong>Cookies de Sessão</strong> - são os Cookies temporários que permanecem arquivados até que você saia do site ou encerre o navegador.</p>
                                    <p><strong>Cookies Persistentes</strong> - são os Cookies que ficam armazenados no seu dispositivo até que sejam excluídos (o tempo que o 
                                        Cookie permanecerá no dispositivo depende do seu “tempo de vida” e das configurações do seu navegador de internet).</p>
                                    <p>Os Cookies - de Sessão ou Pesistentes - ainda podem ser categorizados de acordo com sua função:</p>
                                    <p><strong>Cookies Estritamente Necessários</strong> - permitem a navegação e utilização das aplicações, bem como o
                                        acesso a áreas seguras do site. Sem estes Cookies, o site não funciona corretamente.</p>
                                    <p><strong>Cookies Analíticos</strong> - coletam dados estatísticos anônimos com a finalidade de analisar 
                                        a utilização do site e seu respectivo desempenho.</p>
                                    <p><strong>Cookies de Funcionalidade</strong> - são utilizados para assegurar a disponibilização de funcionalidades adicionais do site ou para 
                                        guardar as preferências definidas pelo usuário ao navegar na internet, sempre que utilizar o mesmo dispositivo.</p>
                                    <p><strong>Cookies de Publicidade</strong> - coletam as informações de sua visita em nosso site para que as propagandas 
                                        nele dispostas sejam mais relevantes para você e de acordo com os seus interesses. Geralmente estão atrelados a Cookies de propriedade de terceiros. 
                                        Caso você desabilite estes cookies, note que você ainda visualizará as publicidades, mas elas serão personalizadas às suas preferências.</p>
                                    <p><strong>5. É POSSÍVEL CONTROLAR OU EXCLUIR COOKIES?</strong></p>
                                    <p>Para que nosso site opere corretamente, você deverá habilitar o uso dos Cookies. Caso decida não os habilitar, 
                                        você ainda poderá navegar no site, mas algumas funcionalidades não estarão disponíveis.</p>
                                    <p>Você pode alterar as configurações para bloquear o uso de Cookies ou alertá-lo quando um Cookie estiver sendo enviado para o seu dispositivo. 
                                        Existem várias formas de gerenciar Cookies, consulte as instruções de seu navegador ou a seção de ajuda para saber mais sobre como 
                                        ajustar ou alterar essas configurações.</p>
                                    <p>Caso você utilize dispositivos diferentes para acessar o site, deverá assegurar-se de que cada navegador, de cada dispositivo, 
                                        esteja ajustado para atender às suas preferências em relação aos Cookies.</p>
                                    <p>Para que você possa, de forma simples e intuitiva, gerir suas preferências a partir do seu navegador, poderá utilizar um dos links abaixo:</p>
                                    <p>Para mais informações sobre gestão de Cookies no Firefox, 
                                        <a href='https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US'> clique aqui</a>.</p>
                                    <p>Para mais informações sobre gestão de Cookies no Chrome,
                                        <a href='https://support.google.com/chrome/answer/95647?hl=en'> clique aqui</a>.</p>
                                    <p>Para mais informações sobre gestão de Cookies no Internet Explorer,
                                        <a href='https://support.microsoft.com/pt-pt/windows/eliminar-e-gerir-cookies-168dab11-0753-043d-7c16-ede5947fc64d'> clique aqui</a>.
                                    </p>
                                    <p>Para mais informações sobre gestão de Cookies no Safari,
                                        <a href='https://support.apple.com/pt-br/guide/safari/sfri11471/mac'> clique aqui</a>.
                                    </p>
                                    <p>Para mais informações sobre gestão de Cookies no Opera,
                                        <a href='https://help.opera.com/en/latest/security-and-privacy/#privateWindow'> clique aqui</a>.
                                    </p>
                                    <p>Para mais informações sobre gestão de Cookies no Microsoft Edge,
                                        <a href='https://support.microsoft.com/pt-br/microsoft-edge/navegação-inprivate-no-microsoft-edge-e6f47704-340c-7d4f-b00d-d0cf35aa1fcc'> clique aqui</a>.
                                    </p>
                                    <p><strong>6. INFORMAÇÕES DETALHADAS SOBRE OS COOKIES</strong></p>
                                    <p>Apresentamos abaixo os detalhes sobre o uso dos Cookies no site, bem como as suas finalidades:</p>
                                    <p><strong>6.1 Cookies Estritamente Necessários</strong></p>
                                    <table className='table table-bordered'>
                                    <thead>
                                            <tr>
                                                <td className='col-2'><strong>Cookie</strong></td>
                                                <td className='col-2'><strong>Validade</strong></td>
                                                <td className='col-4'><strong>Finalidade</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='align-middle'>incap_ses_#</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie preserva o status do usuário através das páginas</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>edsid</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie preserva o status do usuário através das páginas</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>PHPSESSID</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie preserva o status do usuário através das páginas</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>visid_incap_#</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Este Cookie preserva o status do usuário através das páginas</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><strong>6.2 Cookies Analíticos</strong></p>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <td className='col-1'><strong>Cookie</strong></td>
                                                <td className='col-2'><strong>Validade</strong></td>
                                                <td className='col-4'><strong>Finalidade</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='align-middle'>_dc_gtm_UA-#</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie é utilizado pelo Google para gerenciar os serviços do Google Analytics</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_ga</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie armazena dados estatísticos em como os visitantes utilizam o site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>PHPSESSID</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie preserva o status do usuário através das páginas</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_gid</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie armazena dados estatísticos em como os visitantes utilizam o site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_hjAbsoluteSession InProgress</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie é usado para contar quantas vezes o site foi visitado por diferentes 
                                                    visitantes, onde cada um possui um ID único</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_hjid</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Este Cookie determina um ID único para a sessão, permitindo que o 
                                                    site obtenha dados sobre o comportamento do visitante, para propósitos estatísticos</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_hjIncludeInPageviewSample</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie determina se a navegação do usuário deve ser registrada em certos espaços estatísticos reservados</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>hjIncludedInSessionSample</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie registra as ações dentro do site e é utilizado para fins de análises internas para otimização</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_hjTLDTest</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie registra as ações dentro do site e é utilizado para fins de análises internas para otimização</td>
                                            </tr> 
                                            <tr>
                                                <td className='align-middle'>collect</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie é utilizado pelo Google Analytics para analisar os dados estatísticos e indicar conteúdo publicitário</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>Dtcookie</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie monitora atividades realizadas para refletir no desempenho e o impacto na navegação no site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>dtlatC</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie monitora atividades realizadas para refletir no desempenho e o impacto na navegação no site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>dtPC</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie monitora atividades realizadas para refletir no desempenho e o impacto na navegação no site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>dtSa</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie é utilizado para salvar nomes e ações para monitoramento do desempenho no site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>rxec</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie é utilizado para monitorar a performance do site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>rxvisitid</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie gera e armazena um ID anônimo para navegação entre as sessões</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>rxvt</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie é responsável por determinar a duração e o encerramento da sessão</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>__kdtv</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Este Cookie é utilizado para análise de navegação do site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_kdt</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Este Cookie é utilizado para análise de navegação do site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>dtDisabled</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie é utilizado para manter os dados de sessão do usuário</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p><strong>6.1 Cookies de Publicidade</strong></p>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <td className='col-2'><strong>Cookie</strong></td>
                                                <td className='col-2'><strong>Validade</strong></td>
                                                <td className='col-4'><strong>Finalidade</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='align-middle'>___utmvc</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie é utilizado para registrar informações 
                                                    sobre o uso de sites e otimizar a relevância do anúncio apresentado</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>Host-GAPS</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie armazena dados estatísticos em como os visitantes utilizam o site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_fbp</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 3 meses</td>
                                                <td className='align-middle'>Este Cookie é utilizado pelo Facebook para 
                                                    a entrega de anúncios de produtos, com ofertas em tempo real de terceiros</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>__gcl_au</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 3 meses</td>
                                                <td className='align-middle'>Este Cookie é utilizado pelo Google Adsense 
                                                    para estabelecer a eficiência dos anúncios através de websites</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_gs</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie é utilizado para coletar 
                                                    informações sobre preferências de uso e interação com o conteúdo da campanha web</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_gu</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Este Cookie é utilizado para coletar informações 
                                                    sobre preferências de uso e interação com o conteúdo da campanha web</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>gw</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Este Cookie é utilizado para coletar informações 
                                                    sobre preferências de uso e interação com o conteúdo da campanha web</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_uetsid</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie é utilizado em múltiplos sites 
                                                    para apresentar os anúncios mais relevantes aos usuários e indica quantas vezes o 
                                                    usuário visualizou o mesmo anúncio</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_uetsid_exp</td>
                                                <td className='align-middle'>Cookie Persistente</td>
                                                <td className='align-middle'>Este Cookie é utilizado para indicar a data de expiração do cookie anterior</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_uetvid</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 16 dias</td>
                                                <td className='align-middle'>Este Cookie é utilizado para registrar ações realizadas em 
                                                    diversos sites de forma a indicar conteúdo publicitário relevante ao usuário</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_uetvid_exp</td>
                                                <td className='align-middle'>Cookie Persistente</td>
                                                <td className='align-middle'>Este Cookie é definido pelo Bing Ads (&quot;Microsoft Advertising&quot;). Ele é usado para rastrear o comportamento do usuário em um site e medir a eficácia de uma campanha publicitária executada no Bing Ads.</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>ads/ga-audiences</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Cookie vinculado ao uso do Google AdWords para registro de navegação e publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>anj</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 3 meses.</td>
                                                <td className='align-middle'>Este Cookie registra um ID único que é usado para verificar 
                                                    o retorno de acesso ao site com o mesmo dispositivo com fins de divulgação de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>criteo_write_test</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie registra um ID único que permite que o direcionamento de conteúdo 
                                                    publicitário relevante ao usuário</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>cto_tld_test</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie é utilizado para registrar ações realizadas em 
                                                    diversos sites de forma a indicar conteúdo publicitário relevante ao usuário</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>eng_mt</td>
                                                <td className='align-middle'>Cookie Persistente</td>
                                                <td className='align-middle'>Este Cookie é utilizado para acompanhar a taxa de conversão dos usuários e os banners de anunciantes do site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>fr</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 3 meses</td>
                                                <td className='align-middle'>Cookie vinculado ao uso do Facebook para fins de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>IDE</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Este Cookie é utilizado pelo Google DoubleClick para registrar as ações do usuário 
                                                    do site após a visualização ou clique em anúncios publicitários com fins de mensurar a eficácia destes anúncios</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>MUID</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Este Cookie permite o rastreamento por meio da sincronização do ID em vários domínios da Microsoft</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>NID</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 6 meses</td>
                                                <td className='align-middle'>Este Cookie registra um ID único que é usado 
                                                    para verificar o retorno de acesso ao site com o mesmo dispositivo com fins de divulgação de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>nlbi_#</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie é utilizado para manter a sessão de navegação do usuário</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>pagead/1p-conversion/#</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie permite o direcionamento de conteúdo publicitário relevante ao usuário</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>pagead/1p-user-list/#</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Cookie utilizado para rastrear se o usuário mostra interesse em
                                                    produtos específicos ou mesmo em vários sites para fins de direcionamento de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>taboola global:user-id</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 3 meses</td>
                                                <td className='align-middle'>Este Cookie registra um ID único que permite que o direcionamento de 
                                                    conteúdo publicitário relevante ao usuário</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>test_cookie</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie registra é utilizado para verificar se o navegador do usuário suporta Cookies</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>tr</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Cookie vinculado ao uso do Facebook para fins de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>uid</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 2 anos</td>
                                                <td className='align-middle'>Este Cookie é utilizado para coletar informações 
                                                    estatísticas sobre o uso do site e registrar um único ID que indica o retorno do usuário ao site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>uuid2</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 3 meses</td>
                                                <td className='align-middle'>Este Cookie registra um ID único que é usado para verificar o 
                                                    retorno de acesso ao site com o mesmo dispositivo com fins de divulgação de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>dsid</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 7 anos</td>
                                                <td className='align-middle'>Este Cookie é utilizado pelo Google DoubleClick para registrar as 
                                                    ações do usuário do site após a visualização ou clique em anúncios publicitários com fins de 
                                                    mensurar a eficácia destes anúncios</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>_ssc</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 dia</td>
                                                <td className='align-middle'>Este Cookie registra um ID único que permite que o direcionamento 
                                                    de conteúdo publicitário relevante ao usuário</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>cbFirstAccess</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 2 anos</td>
                                                <td className='align-middle'>Marca a timestamp do primeiro acesso do visitante no site</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>dynad_rt</td>
                                                <td className='align-middle'>Cookie Persistente</td>
                                                <td className='align-middle'>Cookie utilizado para rastrear se o usuário mostra interesse em 
                                                    produtos específicos ou mesmo em vários sites para fins de direcionamento de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>edsid</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Este Cookie preserva o status do usuário através das páginas</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>ml_jsuuid</td>
                                                <td className='align-middle'>Cookie Persistente</td>
                                                <td className='align-middle'>Cookie utilizado para rastrear se o usuário mostra interesse em 
                                                    produtos específicos ou mesmo em vários sites para fins de direcionamento de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>u</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 1 ano</td>
                                                <td className='align-middle'>Cookie utilizado para rastrear se o usuário mostra interesse em 
                                                    produtos específicos ou mesmo em vários sites para fins de direcionamento de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>ua_campaign</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 29 dias</td>
                                                <td className='align-middle'>Registra a qual é a campanha que será coletada no Google Tag Manager e 
                                                    contabilizada no Google Analytics</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>ua_gclid</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 29 dias</td>
                                                <td className='align-middle'>Tagueamento automático do Google com finalidades de trackeamento de tráfego</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>ua_medium</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 29 dias</td>
                                                <td className='align-middle'>Registra a qual é o canal que gerou tráfego, ele será coletado pelo 
                                                    Google Tag Manager e contabilizado no Google Analytics</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>ua_referrer</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 29 dias</td>
                                                <td className='align-middle'>Registra a qual é o site referência que gerou tráfego, ele será coletado pelo 
                                                    Google Tag Manager e contabilizado no Google Analytics</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>ua_source</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 29 dias</td>
                                                <td className='align-middle'>Registra qual é o termo que gerou tráfego, ele será coletado pelo 
                                                    Google Tag Manager e contabilizado no Google Analytics</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>uolDataLayer</td>
                                                <td className='align-middle'>Cookie Persistente</td>
                                                <td className='align-middle'>Cookie utilizado para rastrear se o usuário mostra interesse 
                                                    em produtos específicos ou mesmo em vários sites para fins de direcionamento de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>view/TT-10162-1/QZO68EB598/track</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Cookie utilizado para rastrear se o usuário mostra interesse em produtos 
                                                    específicos ou mesmo em vários sites para fins de direcionamento de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>view/TT-10162-1/X69WVGLWYP/track</td>
                                                <td className='align-middle'>Cookie de Sessão: expira quando o usuário encerra o navegador</td>
                                                <td className='align-middle'>Cookie utilizado para rastrear se o usuário mostra interesse em produtos 
                                                    específicos ou mesmo em vários sites para fins de direcionamento de publicidade</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>visitorRandomID</td>
                                                <td className='align-middle'>Cookie Persistente: expira em 2 anos</td>
                                                <td className='align-middle'>Compõe o cookie para classificar o ID do visitante através de um número 
                                                    aleatório gerado no momento de geração da sessão</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>zanpid</td>
                                                <td className='align-middle text-center'> - </td>
                                                <td className='align-middle'>Registra qual é o parceiro afiliado que gerou tráfego, ele será coletado pelo 
                                                    Google Tag Manager e contabilizado no Google Analytics</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>click_id</td>
                                                <td className='align-middle text-center'> - </td>
                                                <td className='align-middle'>Compõe o cookie para classificar o ID do visitante</td>
                                            </tr>
                                            <tr>
                                                <td className='align-middle'>cbuem</td>
                                                <td className='align-middle text-center'> - </td>
                                                <td className='align-middle'>Compõe o cookie para classificar o ID do visitante - guarda o email preenchido no checkout</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default PoliticaCookies
