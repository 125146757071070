import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change as changeFieldValue, reduxForm } from 'redux-form';
import qs from 'qs';
import { Link } from 'react-router-dom';
import 'moment/locale/pt-br'
import 'react-datepicker/dist/react-datepicker.css';
import {
    adicionarPassageiros,
    buscarMapaVeiculoItinerario,
    buscarPassagensPorNomeCidades,
    buscarPassagensPorNomeCidadesDistribusion,
    criarReserva,
    selecionarDataIda,
    selecionarDataRetorno,
    selecionarItinerarioIda,
    selecionarItinerarioRetorno,
    validarVendaPendente,
    liberarBloqueioPoltronasTemporario,
    buscarMapaVeiculoItegracao
} from '../../actions/vendasActions';
import BuscaPassagem from './BuscaPassagem';
import Passagens from './Passagens';
import AbaSelecionarEMarcarPoltronaIda from './AbaSelecionarPoltronasIda';
import AbaSelecionarPoltronasRetorno from './AbaSelecionarPoltronasRetorno';
import Passageiro from './Passageiro';
import BarraNavegacaoDatas from './BarraNavegacaoDatas';
import { getVendaPendente, saveVendaPendenteLocalStorage } from '../../seguranca/securityContext';
import moment from 'moment';
import { buscarCidadeRotaPorNomeCidadeOrigem } from '../../actions/cidadeActions';
import CidadeRota from '../cidade/CidadeRota';
import { recuperarConfEmpresa } from '../../util/applicationContext';

export class PaginaBuscarPassagens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selecionada: 'ida',
            abaSelecionada: 1,
            itinerarioSelecionado: null,
            agrupadorCidadeRota: null,
            abaCidades: 1,
        }
    }

    async componentDidMount() {
        const { match: { params } } = this.props;
        let origem = params.origem;
        let destino = params.destino;
        let ida = params.ida;
        let dataIdaFormatada;
        let dataVoltaFormatada;
        const venda = getVendaPendente();
        if (origem && destino) {
            if (!ida) {
                ida = moment().format('YYYY-MM-DD')
            }
            dataIdaFormatada = this.formatarData(ida);
            dataVoltaFormatada = this.formatarData(params.volta);
            this.props.selecionarDataIda(dataIdaFormatada);
            this.props.selecionarDataRetorno(dataVoltaFormatada);
        }
        if (venda) {
            const parametros = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
            if (parametros.pagina) {
                this.setState({ abaSelecionada: 5 })
            }
            validarVendaPendente()
        } else {
            if (origem && destino) {
                const { empresaSelecionada } = this.props
                const idEmpresa = empresaSelecionada? empresaSelecionada.empresaId : null;
                await this.props.buscarPassagensPorNomeCidades(origem, destino, dataIdaFormatada, dataVoltaFormatada, idEmpresa);
                if (recuperarConfEmpresa().distribusion) {
                    this.props.buscarPassagensPorNomeCidadesDistribusion(origem, destino, dataIdaFormatada, dataVoltaFormatada, idEmpresa);
                }
            }
            if (origem && !destino) {
                this.props.buscarCidadeRotaPorNomeCidadeOrigem(origem)
                    .then(resp => this.setState({ agrupadorCidadeRota: resp.payload }))
            }
        }
    }

    formatarData(data) {
        if (data) {
            if (!isNaN(Date.parse(data))) {
                return moment(data).format('YYYY-MM-DD')
            }
            return moment().format('YYYY-MM-DD')
        }
        return null;

    }


    avancar() {
        const { passagensRetorno, passagensIda, itinerarioIda } = this.props.venda;
        const { abaSelecionada } = this.state;
        const idEmpresaIda = itinerarioIda ? itinerarioIda.idEmpresa : passagensIda[0].idEmpresa
        const possuiPassagemVoltaComMesmaEmpresaPassagemIda = passagensRetorno.filter(it => it.idEmpresa === idEmpresaIda)
        if (passagensRetorno.length > 0 && possuiPassagemVoltaComMesmaEmpresaPassagemIda.length > 0) {
            this.setState({ abaSelecionada: abaSelecionada + 1 })
        } else {
            this.setState({ abaSelecionada: abaSelecionada + 3 })
        }
    }

    retornaPrimeiraAba() {
        this.setState({ abaSelecionada: 1 })
    }

    alterarAba(pagina) {
        this.setState({ abaSelecionada: pagina })
    }

    cancelar() {
        saveVendaPendenteLocalStorage(null)
    }

    voltar() {
        const { passagensRetorno } = this.props.venda;
        const { abaSelecionada } = this.state;
        if (passagensRetorno.length > 0 || abaSelecionada <= 3) {
            this.setState({ abaSelecionada: abaSelecionada - 1 })
        } else {
            this.setState({ abaSelecionada: abaSelecionada - 3 })
        }
    }

    abaSelecionada(aba) {
        if (this.state.abaSelecionada >= aba) {
            return 'nav-link active'
        }
        return 'nav-link'
    }

    selecionarItinerario(itinerario, seIda) {
        const { venda: { origem, destino } } = this.props;
        let comandoMapaVeiculoIntegracao;
        if(itinerario.integracao){
            // eslint-disable-next-line no-unused-vars
            comandoMapaVeiculoIntegracao = {
                departureStation: itinerario.idEstacaoOrigem,
                arrivalStation: itinerario.idEstacaoDestino,
                departureTime:itinerario.dataHoraPartida,
                arrivalTime: itinerario.dataHoraChegada,
                marketingCarrier: itinerario.idFareClass.split('-')[0],
                fareClass: itinerario.fareClass,
                numeroPoltrona: 0
            }
            this.props.selecionarItinerarioIda(itinerario);
            this.props.buscarMapaVeiculoItegracao(comandoMapaVeiculoIntegracao).then(resp => {
                if (resp) {
                    this.setState({ abaSelecionada: 2 })
                }
            })

        }else if (seIda) {
            this.props.selecionarItinerarioIda(itinerario);
            this.props.buscarMapaVeiculoItinerario(itinerario.idItinerario, origem.id, destino.id, seIda, itinerario.tipoVeiculo).then(resp => {
                if (resp) {
                    this.setState({ abaSelecionada: 2 })
                }
            })
        } else {
            this.props.selecionarItinerarioRetorno(itinerario);
            this.props.buscarMapaVeiculoItinerario(itinerario.idItinerario, destino.id, origem.id, seIda, itinerario.tipoVeiculo).then(resp => {
                if (resp) {
                    this.setState({ abaSelecionada: 4 })
                }
            })

        }
    }

    limparAssentosRetornoInformados() {
        const { adicionarPassageiros, venda: { passageiros } } = this.props;
        adicionarPassageiros(passageiros.filter(f => f.sentido === 'IDA'))
    }

    limparTodosAssentosMarcados() {
        const { adicionarPassageiros } = this.props;
        adicionarPassageiros([])
    }

    handleAbaCidade(value) {
        this.setState({ abaCidades: value })
    }

    renderAbaSelecionada() {
        const { handleSubmit, submitting, invalid, venda: { passagensIda, datasIdaProximas, origem, destino } } = this.props;
        switch (this.state.abaSelecionada) {
            case 1: {
                this.limparTodosAssentosMarcados();
                return (
                    <div className='col-md-12'>
                        {this.props.dataIdaSelecionada ? <BarraNavegacaoDatas data={this.props.dataIdaSelecionada}
                            selecionarData={this.props.selecionarDataIda}
                            tipo='ida' /> : ''}
                        {passagensIda.length > 0 && this.state.selecionada === 'ida' || (this.props.dataIdaSelecionada && !this.props.dataRetornoSelecionada) ?
                            <Passagens data={this.props.dataIdaSelecionada} passagens={passagensIda}
                                alterarAba={this.alterarAba.bind(this)}
                                selecionarItinerario={this.selecionarItinerario.bind(this)}
                                adicionarPassageiros={this.props.adicionarPassageiros.bind(this)}
                                trecho={origem && destino ? `${origem.nomeComUf} -> ${destino.nomeComUf} ` : ''}
                                proximas={datasIdaProximas} origem={origem}
                                destino={destino} seIda={true} /> : ''}
                    </div>)
            }

            case 2: {
                return (<AbaSelecionarEMarcarPoltronaIda avancar={this.avancar.bind(this)}
                    voltar={this.voltar.bind(this)}
                    passagens={passagensIda} />)
            }

            case 3: {
                this.limparAssentosRetornoInformados();
                const { passagensRetorno, datasRetornoProximas, origem, destino, passagensIda, itinerarioIda } = this.props.venda;

                return (
                    <div className='col-md-12'>
                        {this.props.dataRetornoSelecionada ?
                            <BarraNavegacaoDatas tipo='retorno' data={this.props.dataRetornoSelecionada}
                                selecionarData={this.props.selecionarDataRetorno} /> : ''}
                        {passagensRetorno.length > 0 || (this.props.dataIdaSelecionada && this.props.dataRetornoSelecionada) ?
                            <Passagens data={this.props.dataRetornoSelecionada} passagens={passagensRetorno}
                                selecionarItinerario={this.selecionarItinerario.bind(this)}
                                idEmpresaIda={itinerarioIda ? itinerarioIda.idEmpresa : passagensIda[0].idEmpresa}
                                voltar={this.voltar.bind(this)}
                                trecho={`${destino.nomeComUf} -> ${origem.nomeComUf}`}
                                proximas={datasRetornoProximas} origem={destino}
                                destino={origem} seIda={false} /> : ''}
                    </div>)
            }
            case 4: {
                return (<AbaSelecionarPoltronasRetorno avancar={this.avancar.bind(this)}
                    voltar={this.voltar.bind(this)}
                    passagens={passagensIda} />)
            }

            case 5: {
                return (<Passageiro
                    key={passagensIda.idItinerario}
                    handleSubmit={handleSubmit}
                    submitting={submitting}
                    invalid={invalid}
                    changeFieldValue={this.props.changeFieldValue}
                    voltar={this.voltar.bind(this)}
                    criarReserva={this.props.criarReserva.bind(this)}
                    adicionarPassageiros={this.props.adicionarPassageiros.bind(this)}
                    isAuthenticated={this.props.isAuthenticated}
                    venda={this.props.venda}
                    />)
            }

            default: {
                return '';
            }
        }
    }

    renderBotaoVoltar() {
        const { abaSelecionada } = this.state;
        const { itinerarioIda } = this.props.venda;
        const vendaPendente = getVendaPendente();
        if (vendaPendente && abaSelecionada !== 1) {
            return itinerarioIda ? (
                <div>
                    <button type="button" className="btn btn-light float-left btn-sistema mr-2"
                        onClick={() => this.voltar()}>Voltar
                    </button>
                    <Link to='/'>
                        <button type="button" className="btn btn-light float-right btn-sistema"
                            onClick={() => this.cancelar()}>Cancelar
                        </button>
                    </Link>
                </div>
            ) : (
                <Link to='/'>
                    <button type="button" className="btn btn-light float-left btn-sistema"
                        onClick={() => this.cancelar()}>Cancelar
                    </button>
                </Link>

            )
        }
        return abaSelecionada !== 1 ? (
            <button type="button" className="btn btn-light float-left btn-sistema"
                onClick={() => this.voltar()}>Voltar</button>
        ) : ''
    }

    renderBotaoAvancar() {
        const { abaSelecionada } = this.state;
        const { passageiros } = this.props.venda;
        // eslint-disable-next-line no-undef
        return (abaSelecionada === 2 || abaSelecionada === 4) && _.size(passageiros) > 0 ? (
            <button type="button" className="btn btn-laranja float-right btn-sistema btn-branco"
                onClick={() => this.avancar()}>Avançar</button>
        ) : ''
    }

    renderAbas() {
        return (<div className='col-12 conteudo-internas conteudo-painel area-btn-filtro-painel'>
            <div className='card border-0'>
                <div className='card-body'>
                    {this.renderAbaSelecionada()}
                </div>
                <div className="card-footer">
                    {this.renderBotaoVoltar()}
                    {this.renderBotaoAvancar()}
                </div>
            </div>
        </div>)
    }

    render() {
        const { agrupadorCidadeRota } = this.state
        const { abaCidades } = this.state
        return agrupadorCidadeRota ? (
            <CidadeRota agrupadorCidadeRota={agrupadorCidadeRota}
                handleAbaCidade={this.handleAbaCidade.bind(this)}
                abaCidades={abaCidades} />
        ) : (
            <div className='conteudo'>
                <div>
                    <BuscaPassagem avancar={this.retornaPrimeiraAba.bind(this)} />
                </div>
                {
                    this.renderAbas()
                }
            </div>
        )
    }
}

export const mapStateToProps = state => {
    return{
        dataIdaSelecionada: state.venda.dataIdaSelecionada,
        dataRetornoSelecionada: state.venda.dataRetornoSelecionada,
        isAuthenticated: state.usuario.dadosAutenticacao.isAuthenticated,
        venda: state.venda,
        empresaSelecionada: state.empresa.selecionada
    }};


export default connect(mapStateToProps,
    {
        buscarPassagensPorNomeCidades,
        buscarPassagensPorNomeCidadesDistribusion,
        buscarMapaVeiculoItinerario,
        adicionarPassageiros,
        selecionarDataIda,
        selecionarDataRetorno,
        selecionarItinerarioIda,
        selecionarItinerarioRetorno,
        criarReserva,
        buscarCidadeRotaPorNomeCidadeOrigem,
        liberarBloqueioPoltronasTemporario,
        buscarMapaVeiculoItegracao,
        changeFieldValue,

    })(reduxForm({
        form: 'formReserva',
    })(PaginaBuscarPassagens))
