import React from 'react';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

const ConteudoQuemSomosTcb = () => (
  <div className="page-contato" style={{fontSize: 16, backgroundColor: '#ffffff'}}>
    <section className="gv-section">
      <div className="section-content">
        <h2 className="gv-title">TCB - Transbrasil</h2>
        <p className="txt-item">Bem-vindo à Transbrasil, o seu portal dedicado à facilitação de viagens terrestres em todo o território brasileiro. Nós entendemos que a comodidade e a acessibilidade são essenciais quando se trata de escolher o seu meio de transporte. Aqui, gostaríamos de compartilhar nossa história, missão, valores e a tecnologia que impulsiona nossa plataforma voltada para passagens de ônibus.</p>
        <p className="txt-item">A TCB - Transbrasil, cuja razão social é Transporte Coletivo Brasil Ltda, foi fundada em 13/09/1984 e tem se destacado como uma empresa líder no segmento de Viação - Ônibus Intermunicipais, Interestaduais e Internacionais. Com o CNPJ 05.376.934/0014-60, a TCB - Transbrasil orgulha-se de sua história e compromisso em oferecer serviços de transporte de alta qualidade aos brasileiros.</p>
        
        <h2 className="gv-title" style={{ marginTop: 24 }}>Nossa Missão:</h2>
        <p className="txt-item">A missão da TCB - Transbrasil é conectar pessoas e comunidades, proporcionando serviços de transporte rodoviário coletivo de passageiros de alta qualidade. Estamos empenhados em oferecer uma experiência de viagem segura, confiável e confortável, contribuindo assim para a mobilidade e o bem-estar de nossos passageiros.</p>
        
        <h2 className="gv-title" style={{ marginTop: 24 }}>Nossos Valores:</h2>
        <p className="txt-item"><strong>Segurança:</strong> Colocamos a segurança em primeiro lugar, tanto para nossos passageiros quanto para nossa equipe. Investimos em manutenção rigorosa de nossa frota e em práticas seguras de condução.</p>
        <p className="txt-item"><strong>Excelência no Atendimento:</strong> Nossa equipe é treinada para oferecer um atendimento amigável e eficiente, garantindo que cada passageiro tenha uma experiência positiva em nossa empresa.</p>
        <p className="txt-item"><strong>Conforto e Qualidade:</strong> Buscamos constantemente melhorar a qualidade de nossos serviços, oferecendo ônibus modernos e confortáveis, bem como itinerários eficientes e pontuais.</p>
        <p className="txt-item"><strong>Sustentabilidade:</strong> Preocupamo-nos com o meio ambiente e adotamos medidas para reduzir nosso impacto ambiental. Promovemos práticas sustentáveis em nossa operação.</p>
        
        <h2 className="gv-title" style={{ marginTop: 24 }}>Tecnologia:</h2>
        <p className="txt-item">A tecnologia desempenha um papel fundamental em nossa empresa. Utilizamos sistemas de gestão avançados para garantir a eficiência de nossas operações, incluindo o monitoramento de itinerários e horários em tempo real. Além disso, estamos constantemente inovando para oferecer aos nossos passageiros as melhores soluções tecnológicas para uma experiência de viagem mais conveniente.</p>
        <p className="txt-item">Na TCB - Transbrasil, estamos comprometidos em fornecer serviços de transporte seguros, confiáveis e confortáveis para nossos passageiros. Nossa frota moderna e equipe qualificada trabalham juntas para garantir que sua experiência de viagem seja a melhor possível. Agradecemos por escolher a TCB - Transbrasil como sua opção de transporte e estamos ansiosos para servi-lo em suas futuras jornadas pelo Brasil e além. Juntos, viajamos com segurança e conforto.</p>
        <section className="gv-cards" style={{ marginTop: 50 }}>
          <div className="gv-cards-wrapper">
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <DevicesOutlinedIcon className="box-item-icon"></DevicesOutlinedIcon>
                  <p>Tecnologia</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Descomplicamos o jeito antigo e complicado de comprar uma passagem!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <EmojiObjectsOutlinedIcon className="box-item-icon"></EmojiObjectsOutlinedIcon>
                  <p>Inovação</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Inovamos em um mercado promissor. Tecnologia simples, aplicada e segura para você, sua família e amigos.</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <PeopleAltOutlinedIcon className="box-item-icon"></PeopleAltOutlinedIcon>
                  <p>Relacionamento</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Pensamos em você muito mais que um simples cliente, temos você como parte de nossa empresa. Sem você não teríamos motivos para estar aqui!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <TrendingUpOutlinedIcon className="box-item-icon"></TrendingUpOutlinedIcon>
                  <p>Agilidade</p>
                  <div className="divider"/>
                </span>
                <span className="box-item-card-info">
                  <p>Rápido e descomplicado. Clicou, comprou, viajou!</p>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
)

export default ConteudoQuemSomosTcb

