import React, { Component } from 'react'
import iconPoltrona from '../../estilo/comum/img/poltrona.png'
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ModalDescricaoPoltronas from './ModalDescricaoPoltronas';
import { MapaEmbarque } from './MapaEmbarque';
import { MapaDesembarque } from './MapaDesembarque';
import capitalizarTodasPrimeirasLetras from '../../util/capitalizarTodasPrimeirasLetras';

export class Passagem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
            showModal: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    exibirClassName(quantidade, integracao) {
        return quantidade > 0 || integracao ? 'item-passagem' : '';
    }

    handleClick() {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded
        }));
    }

    handleShow() {
        this.setState({
            showModal: true,
        });
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }

    exibirMaisInformacoes() {
        const { isExpanded } = this.state;
        const { passagem, origem, destino } = this.props;
        return isExpanded ?
            <div className="card-body active">
                <div id='maisInfoContainer' className='container'>
                    <div className='avisoInformativo'>
                        <div className='conteudoWarning'>
                            <i id='iconWarning' className="fa fa-warning"></i>
                            Você precisa retirar a passagem neste local: {passagem.pontoEmbarque}
                        </div>
                    </div>
                    <div id='containerActive' className='row'>
                        <div className='sectionMaps'>
                            <div className='sectionMapa1'>
                                <div className='embarque'>
                                    <p><b>Embarque:</b> {origem.nome} - {origem.uf}</p>
                                </div>
                                <div className='enderecoEmbarque'>
                                    <p><b>Endereço do embarque:</b> {passagem.pontoEmbarque}</p>
                                </div>
                                <div className='renderMapa'>
                                    <MapaEmbarque
                                        passagem={this.props.passagem}
                                    />
                                </div>
                            </div>
                            <div className='sectionMapa2'>
                                <div className='desembarque'>
                                    <p><b>Desembarque:</b> {destino.nome} - {destino.uf}</p>
                                </div>
                                <div className='enderecoDesembarque'>
                                    <p><b>Endereço do desembarque:</b> {passagem.pontoDesembarque}</p>
                                </div>
                                <div className='renderMapa'>
                                    <MapaDesembarque
                                        passagem={this.props.passagem}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id='containerInform' className='col-md-6 col-sm-12'>
                            <div className='sectionInformativos'>
                                <div id='informativo1' className='row informativos'>
                                    <span id='informativoIcons' className="fa fa-map-marker"></span>
                                    <h3 id='tituloInformativos'><b>Paradas para embarque e desembarque</b></h3>
                                    <div className='informativoText'>
                                        As paradas são de aproximadamente 20 minutos.
                                    </div>
                                </div>
                                <div id='informativo2' className='row informativos'>
                                    <span id='informativoIcons' className="fas fa-retweet"></span>
                                    <h3 id='tituloInformativos'><b>Troca, cancelamento e reembolso</b></h3>
                                    <div className='informativoText'>
                                        Nenhuma taxa é cobrada para fazer trocas/alterações e quaisquer valores pagos
                                        por clientes são devolvidos integralmente;
                                        As reservas podem ser canceladas e alteradas até 3h antes do embarque.
                                    </div>
                                </div>
                                <div id='informativo3' className='row informativos'>
                                    <span id='informativoIcons' className="fas fa-user-alt-slash"></span>
                                    <h3 id='tituloInformativos'><b>Ausência no embarque</b></h3>
                                    <div className='informativoText'>
                                        Em caso de não comparecimento a {passagem.nomeEmpresa} disponibiliza um cupom para remarcação de
                                        80% do valor pago na reserva.
                                    </div>
                                </div>
                                <div id='informativo4' className='row informativos'>
                                    <span id='informativoIcons' className="far fa-address-card"></span>
                                    <h3 id='tituloInformativos'><b>Documentos para Viajar</b></h3>
                                    <div className='informativoText'>
                                        Para embarcar você deve levar apenas o RG ou CNH ou Passaporte ou Carteira de Trabalho.
                                        O documento deve ser original e estar legível.
                                        Você precisa retirar a passagem na {passagem.pontoEmbarque}.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''
    }

    render() {
        const { showModal } = this.state;
        const { isExpanded } = this.state;
        const arrowClass = isExpanded ? 'arrow expanded' : 'arrow';
        const cardGroupClass = isExpanded ? 'card-group mb-2' : 'card-group mb-2 collapsed';
        const { passagem, seIda, origem, destino } = this.props;
        return <div id='divPassagem' className='card-group mb-2'>
            <div id='divPassagem'>
                <div
                    className={`card col-md-2 align-items-center ${passagem.assentosDisponiveis > 0 || passagem.integracao ? 'item-passagem' : ''}`}>
                    <div className='row' id='cardImagem' style={{ margin: 'auto 0' }}>
                        <div className='wrapper'>
                            <i id='bilhete' className="material-icons nav-icon">&#xe638;</i>
                            Revenda de passagem
                            <div className='tooltip'> Essa viagem é de responsabilidade da empresa de transporte
                                parceira. Dúvidas ou ajuda com sua viagem devem ser tratadas com a
                                empresa: {passagem.nomeEmpresa}. </div>
                        </div>
                        <img id='imagemEmpresa' alt={passagem.nomeEmpresa} src={passagem.urlImagemEmpresa} />
                        <div className='avaliacao'>
                            <h3><b>Avaliação: {passagem.nota} </b></h3>
                            <div className='fas fa-star'></div>
                        </div>
                    </div>
                </div>
                <div
                    className={`card col-md-10 align-content-center ${this.exibirClassName(passagem.assentosDisponiveis, passagem.integracao)}`}>
                    <div className="card-body ml-2 align-content-center row" style={{ fontSize: 20 }}>
                        <div id='trechoHora' className="col-md-6">
                            <div className="rota">
                                <div className="rota-row">
                                    <span className="rota-hora rota-hora-start">
                                        {passagem.horaPartida}
                                    </span>
                                    <div className="rota-path  rota-path-start">
                                    </div>
                                    <div className="rota-tempo">
                                        <p className="rota-tempo-texto tempo">
                                            {passagem.duracao}
                                        </p>
                                    </div>
                                    <div className="rota-path rota-path-end">
                                    </div>
                                    <span className="rota-hora rota-hora-end">
                                        {passagem.horaChegada}
                                    </span>
                                    <sup
                                        title={'Data de chegada: ' + moment(passagem.dataHoraChegada).format('DD/MM/YY HH:mm')}
                                        style={{ color: 'red' }}>{passagem.qtdDias > 0 ? '+' + passagem.qtdDias : ''}</sup>
                                </div>
                                <div className="rota-row">
                                    <div className="rota-cidade rota-cidade-start">
                                        <p>{origem.nome} - {origem.uf}</p>
                                        <p></p>
                                    </div>
                                    <div className="rota-cidade rota-cidade-end">
                                        <p>{destino.nome} - {destino.uf}</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='precoPassagem' className='col-md-3'>
                            <strong>
                                <span
                                    className="card-title valor-passagem">R$ {passagem.empresaHabilitadoValorAntt ? passagem.precoANTT : passagem.preco}</span>
                            </strong>
                        </div>
                        <div id='infoVeiculo' className='col-md-3'>
                            { passagem.assentosDisponiveis > 0 && passagem.assentosDisponiveis !== null ?
                                <span className="card-title tit-assento">Assentos disponíveis:</span>
                                : <span className="card-title tit-assento">Ver disponíveis</span> }
                            { passagem.assentosDisponiveis > 0 && passagem.assentosDisponiveis !== null ?
                                <span id='imgPoltrona' className="qtd-poltrona">
                                    <img src={iconPoltrona} alt="imagem de poltrona" />
                                    <strong>{passagem.assentosDisponiveis}</strong>
                                </span> :
                                <span id='imgPoltrona' className="qtd-poltrona">
                                    <img src={iconPoltrona} alt="imagem de poltrona" />
                                    <strong>?</strong>
                                </span>
                            }
                            <strong>
                                <span id='tipoVeiculoDesc' className="card-title">
                                    {capitalizarTodasPrimeirasLetras(passagem.descricaoTipoVeiculo)}
                                    <i onClick={this.handleShow} id='iconInfoPoltrona' className="material-icons"
                                        style={{ cursor: 'pointer' }}>
                                        info_outline
                                    </i>
                                </span>
                            </strong>
                            <ModalDescricaoPoltronas showModal={showModal} handleClose={this.handleClose.bind(this)} />

                            {passagem.assentosDisponiveis > 0 || passagem.integracao ?
                                <a onClick={() => this.props.selecionarItinerario(passagem, seIda)}>
                                    <button>Selecionar</button>
                                </a>
                                : ''}
                        </div>
                    </div>
                </div>
                <button id='divPassagemButton' className={cardGroupClass} onClick={this.handleClick} type='button'
                    data-toggle='collapse' aria-expanded='true'>
                    Mais informações <span className={arrowClass}></span>
                </button>
            </div>
            {this.exibirMaisInformacoes()}
        </div>
    }
}


export default Passagem;
