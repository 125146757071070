import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosJonasTur = {
    ...dadosAgenciaComum,
    id: 'jonastur',
    nome: 'Jonas Tur',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    email: 'suportevexado@gmail.com',
    idsTagManager: ['GTM-WL4BH38']
};
