import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {alterarDadosCliente, buscarDadosUsuarioLogado, excluirCliente} from '../../actions/usuarioActions'
import {logoutUser} from '../../actions/usuarioActions'
import {renderField, required, requiredCPF, validarNomeCompleto} from '../../util/inputs';
import {showSuccessMsg} from '../../util/messagesUtil';
import capitalizarTodasPrimeirasLetras from '../../util/capitalizarTodasPrimeirasLetras';

const ModalConfirmacaoExclusaoCliente = ({excluir}) => (
    <div className="modal fade" id="modalConfirmacaoExclusaoCliente" tabIndex="-1" role="dialog"
         aria-labelledby="modalConfirmacao" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-body texto-centralizado">
                    <p>Deseja excluir a conta ?</p>
                </div>
                <div className="modal-footer ">
                    <button id='cancelar' type="button" className="btn btn-outline-secondary"
                            data-dismiss="modal">Cancelar
                    </button>
                    <button type="button" className="btn btn-laranja float-left" data-dismiss="modal"
                            onClick={() => excluir()}>Excluir
                    </button>
                </div>
            </div>
        </div>
    </div>);

class PaginaPerfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chaveCaptcha: null,
        };
    }

    componentDidMount() {
        this.props.buscarDadosUsuarioLogado();
    }

    excluir() {
        this.props.excluirCliente().then(() => {
          showSuccessMsg('A conta foi excluída com sucesso!');
          this.props.logoutUser();
          setTimeout(() => window.location.reload(), 1500);
        });
    }

    onSubmit(values) {
        this.props.alterarDadosCliente(values).then(() => {
            showSuccessMsg('Dados alterado com sucesso!');
            this.props.history.push('/');
        })
    }

    render() {
        const {
            handleSubmit, submitting, invalid,
        } = this.props;

        return (
            <>
                <div className="back-login">
                    <form className="container form-login" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <div className='loginHeader'>
                            <h1>Perfil</h1>
                            <div className="divider"/>
                        </div>
                        <Field
                            label='Nome completo'
                            name='nome'
                            type='text'
                            id='nome'
                            placeholder=''
                            validate={[required, validarNomeCompleto]}
                            component={renderField}
                        />
                        <Field
                            label='E-mail'
                            name='email'
                            type='email'
                            id='nome'
                            placeholder=''
                            css="form-control"
                            readOnly={true}
                            validate={[required]}
                            component={renderField}
                        />
                        <Field
                            name='cpf'
                            id='cpf'
                            placeholder="___.___.___-__"
                            css="form-control"
                            label='CPF'
                            type='cpf'
                            readOnly={true}
                            validate={[requiredCPF]}
                            component={renderField}/>
                        <Field
                            label='Telefone'
                            name='telefone'
                            id='telefone'
                            type='celular'
                            placeholder=''
                            validate={[required]}
                            component={renderField}
                        />
                        <button type='submit' className='btn btn-laranja btn-sistema'
                                disabled={invalid || submitting}>Alterar
                        </button>
                        <button type='button' className='btn btn-danger btn-sistema'
                            title='Excluir Conta'
                            data-toggle="modal"
                            data-target="#modalConfirmacaoExclusaoCliente">
                            Excluir Conta
                        </button>
                    </form>                
                </div>
                <ModalConfirmacaoExclusaoCliente excluir={() => this.excluir()} />
            </>
        )
    }
}

export function mapStateToProps(state) {
    return {
        initialValues: {
            email: state.usuario.dadosUsuario.email,
            nome: capitalizarTodasPrimeirasLetras(state.usuario.dadosUsuario.nome),
            cpf: state.usuario.dadosUsuario.cpfCnpj,
            telefone: state.usuario.dadosUsuario.telefone,
        }
    }
}


export default connect(mapStateToProps, {buscarDadosUsuarioLogado, alterarDadosCliente, excluirCliente, logoutUser})(reduxForm({
    form: 'perfilForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(PaginaPerfil));
