import React from 'react';
import logoTbp from '../../../estilo/comum/img/logo_trasnbrasil.png'
import iconAgencia from '../../../estilo/comum/img/icon/transbrasil-icon.png'
import bannerAgencia from '../../../estilo/comum/img/banner-inicio-transbrasil3.webp'
import bannerAgenciaMobile from '../../../estilo/comum/img/banner-transbrasil-mobile3.png'
import bannerAgenciaMobile2 from '../../../estilo/comum/img/banner-transbrasil-mobile2.png'
import ConteudoQuemSomosTcb from '../../../componentes/quem-somos/transbrasil-passagens/ConteudoQuemSomosTCB';
import dadosSiteComum from './dadosSiteComum';
// import bannerQueroUmaAgencia from '../../../estilo/comum/img/queroUmaAgencia/5.webp';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/12.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/17.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoAgencias.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/18.webp';

const logoTbpCabecalho = <img className="logo-site agencia" src={logoTbp} alt='Logo Agência'/>;
const logoTbpRodape = <img className="agencia" src={logoTbp} alt="Logo Agência" style={{width: '70px'}}/>;

export const dadosTransbrasilPassagens = {
    ...dadosSiteComum,
    id: 'transbrasilpassagens',
    pastaEstilo: 'transbrasilpassagens',
    nome: 'Transbrasil Passagens',
    nomePrefixo: 'a Transbrasil Passagens',
    razaoSocial: 'TRANSPORTE COLETIVO BRASIL LTDA',
    cnpj: '05.376.934/0001-46',
    endereco:'Rua 10, Quadra Lote 88, Setor Marechal Rondon, Goiânia, Goiás/GO, CEP: 74560-390',
    telefone: '(62) 99372-4485',
    wtpp: '(62) 99372-4485',
    instagram: 'http://Instagram.com',
    facebook: 'http://Facebook.com',
    youtube: 'http://youtube.com',
    email: 'transbrasil@hotmail.com.br',
    emailSuporte: 'transbrasil@hotmail.com.br',
    lkemailSuporte: 'mailto:transbrasil@hotmail.com.br',
    lktelefone: 'tel:5562993724485',
    twitter: 'https://twitter.com',
    lkemail: 'mailto:transbrasil@hotmail.com.br',
    lkwhats: 'https://api.whatsapp.com/send?phone=5562993724485&text=Olá. Preciso de atendimento.',    
    logo: logoTbp,
    logoCabecacho: logoTbpCabecalho,
    logoTbpRodape: logoTbpRodape,
    OgImage: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/transbrasil-passagens/TRANSBRASIL_FIGURA_DE_COMPARTILHAMENTO.webp',
    icone: iconAgencia,
    quemSomos: ConteudoQuemSomosTcb,
    videoInicio: bannerAgencia,
    imgMobile: bannerAgenciaMobile,
    imgMobile2: bannerAgenciaMobile2,
    idsTagManager: ['GTM-595WH57'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+desconto+expresso+diamante+-+transbrasil++-+passagens+adamantina.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/5.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/5.mobile.webp',
    distribusion: false
};
