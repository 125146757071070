import {SELECIONAR_RESERVA, CANCELAR_RESERVA, BUSCAR_RESERVA} from '../reducers/reservasReducer';
import {SLOW_REQUEST_CONFIG} from '../util/loadingUtil';
import axios from 'axios'
import {URL} from '../util/ambienteActions';

export function selecionarReserva (reserva) {
    return {
        type: SELECIONAR_RESERVA,
        payload: reserva
    }
}

export function cancelarReserva (comandoCancelarReserva) {
    const url = `${URL}/reserva/cancelar/site`;
    return dispatch => axios.post(url, comandoCancelarReserva, SLOW_REQUEST_CONFIG).then(
         response => dispatch({
                type: CANCELAR_RESERVA,
                payload: response
            })  
    );
}

export function buscar(parametros, captchar) {
    const url = `${URL}/reserva/public`;
    return dispatch => axios.post(url, {...parametros, captchar: captchar}, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: BUSCAR_RESERVA,
            payload: response,
        }),
    );
}