export const SELECIONAR_EMPRESA = '@reservasReducer/SELECIONAR_EMPRESA';


const initialState = {
    selecionada: null,
};


export default function reducerEmpresa(state = initialState, action) {
    switch (action.type) {

        case SELECIONAR_EMPRESA: {
            return {
                ...state,
                selecionada: action.payload,
            }
        }

        default: {
            return state
        }
    }

}
