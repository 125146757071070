import React, { Component } from 'react';
import BuscaPassagem from '../passagem/BuscaPassagem';
import { Card } from 'react-bootstrap';
import { URL } from '../../util/ambienteActions';
import axios from 'axios';
import { SLOW_REQUEST_CONFIG } from '../../util/loadingUtil';
import { isAmbienteProd } from '../../util/applicationContext';
import logoEmpSandbox from '../../estilo/comum/img/logo-03.png'

export class PaginaEmpresas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            destinos: [],
            activePage: 1,
            totalRegistros: 0
        };
    }

    validarNomeFantasiaNormalizado = (nomeFantasia) => {
        const nomeSemAcentos = nomeFantasia.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        const nomeNormalizado = nomeSemAcentos
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9-]/g, '');

        return nomeNormalizado;
    };

    handleCardClick = (empresaId, nomeFantasia) => {
        const nomeFantasiaNormalizado = this.validarNomeFantasiaNormalizado(nomeFantasia);
        const paginaTrechosEmpresa = {
            pathname: `/trechos-empresa/${nomeFantasiaNormalizado}`,
            state: { empresaId: empresaId }
        };
        this.props.history.push(paginaTrechosEmpresa);
    };

    handleButtonClick = () => {
        this.props.history.push('/pesquisar-empresas');
    };

    componentDidMount() {
        axios.get(`${URL}/empresa/public/todas-empresas`, SLOW_REQUEST_CONFIG)
            .then(resp => {
                if (Array.isArray(resp.data)) {
                    const empresas = resp.data;
                    this.setState({ empresas });
                } else {
                    console.error('Dados da API não estão no formato esperado:', resp.data);
                }
            }).catch(error => {
                console.error('Erro ao buscar dados da API:', error);
            });
    }

    render() {
        const { empresas } = this.state
        return (
            <div className='conteudo'>
                <div>
                    <BuscaPassagem />
                </div>
                <div className='page-contato container mt-5'>
                    <h2 id='secao-trechos' className="gv-title">Clique na imagem da empresa e veja seus principais trechos:</h2>
                    <div className='container-trechos'>
                        {empresas && empresas.map((empresa, index) => (
                            <div className='card-principaisTrechos' key={`indice-${index}-codigo-${empresa.empresaId}`}>
                                <Card className='card-Empresa'>
                                    <a onClick={() => this.handleCardClick(empresa.empresaId, empresa.nomeFantasiaNormalizado)}>
                                        <Card.Body className='card-body-empresa'>
                                            <Card.Img className='card-img-empresa' alt='Logo Empresa' variant="top" src={isAmbienteProd() ? empresa.logomarca : logoEmpSandbox} />
                                        </Card.Body>
                                    </a>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default PaginaEmpresas;