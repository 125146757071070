const viacaoAdamantina = [

    {link: '/passagem-de-onibus/dracena-sp/presidentevenceslau-sp'},
    {link: '/passagem-de-onibus/dracena-sp/santoanastacio-sp'},
    {link: '/passagem-de-onibus/santoanastacio-sp/dracena-sp'},
    {link: '/passagem-de-onibus/santoanastacio-sp/tupipaulista-sp'},
    {link: '/passagem-de-onibus/santoanastacio-sp/andradina-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/dourado-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/tupipaulista-sp/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/tupipaulista-sp/presidentevenceslau-sp'},
    {link: '/passagem-de-onibus/tupipaulista-sp/santoanastacio-sp'},
    {link: '/passagem-de-onibus/andradina-sp/presidenteprudente-sp'},
    {link: '/passagem-de-onibus/andradina-sp/presidentevenceslau-sp'},
    {link: '/passagem-de-onibus/andradina-sp/santoanastacio-sp'},
    {link: '/passagem-de-onibus/dourado-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/campinas-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/campinas-sp/dourado-sp'},
    {link: '/passagem-de-onibus/campinas-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/tupipaulista-sp'},
    {link: '/passagem-de-onibus/presidenteprudente-sp/andradina-sp'},
    {link: '/passagem-de-onibus/presidentevenceslau-sp/dracena-sp'},
    {link: '/passagem-de-onibus/presidentevenceslau-sp/tupipaulista-sp'},
    {link: '/passagem-de-onibus/presidentevenceslau-sp/andradina-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/campinas-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/ribeiraobonito-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/bocaina-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/dourado-sp'},
    {link: '/passagem-de-onibus/jundiai-sp/ribeiraobonito-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/campinas-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/bocaina-sp/jundiai-sp'},
    {link: '/passagem-de-onibus/dourado-sp/campinas-sp'},
    {link: '/passagem-de-onibus/dourado-sp/saopaulo-sp'},
];
export default viacaoAdamantina;