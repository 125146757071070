import {FECHAR_LOADING, MOSTRAR_LOADING, FECHAR_LOADING_INTEG, MOSTRAR_LOADING_INTEG} from '../reducers/loadingReducer';


export function mostrarLoading() {
    return {
        type: MOSTRAR_LOADING,
        payload: null,
    }
}


export function fecharLoading() {
    return {
        type: FECHAR_LOADING,
        payload: null,
    }
}

export function mostrarLoadingInteg(ehBusca) {
    return {
        type: MOSTRAR_LOADING_INTEG,
        payload: ehBusca,
    }
}


export function fecharLoadingInteg() {
    return {
        type: FECHAR_LOADING_INTEG,
        payload: null,
    }
}
