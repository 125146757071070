import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import guanabaraUtil from '../TopTrechosPassagens/linksEmpresas/guanabaraUtil';
import expressoGuanabara from '../TopTrechosPassagens/linksEmpresas/expressoGuanabara';
import {BuscaPassagem} from '../../passagem/BuscaPassagem';
import realExpresso from '../TopTrechosPassagens/linksEmpresas/realExpresso';
import realTransportes from '../TopTrechosPassagens/linksEmpresas/realTransportes';
import rapidoFederal from '../TopTrechosPassagens/linksEmpresas/rapidoFederal';
import catedral from '../TopTrechosPassagens/linksEmpresas/catedral';
import cantelle from '../TopTrechosPassagens/linksEmpresas/cantelle';
import gontijo from '../TopTrechosPassagens/linksEmpresas/gontijo';
import eucatur from '../TopTrechosPassagens/linksEmpresas/eucatur';
import amatur from '../TopTrechosPassagens/linksEmpresas/amatur';
import emtram from '../TopTrechosPassagens/linksEmpresas/emtram';
import itapemirim from '../TopTrechosPassagens/linksEmpresas/itapemirim';
import util from '../TopTrechosPassagens/linksEmpresas/util';
import viacaoAdamantina from '../TopTrechosPassagens/linksEmpresas/viacaoAdamantina';
import aguiaBranca from '../TopTrechosPassagens/linksEmpresas/aguiaBranca';
import luxor from '../TopTrechosPassagens/linksEmpresas/luxor';
import ouroEprata from '../TopTrechosPassagens/linksEmpresas/ouroEprata';
import andorinha from '../TopTrechosPassagens/linksEmpresas/andorinha';
import brasileiro from '../TopTrechosPassagens/linksEmpresas/brasileiro';
import cidadeDoSol from '../TopTrechosPassagens/linksEmpresas/cidadeDoSol';
import gadotti from '../TopTrechosPassagens/linksEmpresas/gadotti';
import expressoItamarati from '../TopTrechosPassagens/linksEmpresas/expressoItamarati';
import expressoDePrata from '../TopTrechosPassagens/linksEmpresas/expressoDePrata';
import reunidasPaulista from '../TopTrechosPassagens/linksEmpresas/reunidasPaulista';
import expressoFloriano from '../TopTrechosPassagens/linksEmpresas/expressoFloriano';
import viacaoPretti from '../TopTrechosPassagens/linksEmpresas/viacaoPretti';
import expressoSaoLuiz from '../TopTrechosPassagens/linksEmpresas/expressoSaoLuiz';
import expressoJoia from '../TopTrechosPassagens/linksEmpresas/expressoJoia';
import sataliteNorte from '../TopTrechosPassagens/linksEmpresas/sateliteNorte';
import viacaoRioDoce from '../TopTrechosPassagens/linksEmpresas/viacaoRioDoce';
import reunidasTransportes from '../TopTrechosPassagens/linksEmpresas/reunidasTransportes';
import rota from '../TopTrechosPassagens/linksEmpresas/rota';
import rodeRotas from '../TopTrechosPassagens/linksEmpresas/rodeRotas';
import uneSul from '../TopTrechosPassagens/linksEmpresas/uneSul';
import reunidasSa from '../TopTrechosPassagens/linksEmpresas/reunidasSa';
import princesaDoNorte from '../TopTrechosPassagens/linksEmpresas/princesaDoNorte';
import lopesTur from '../TopTrechosPassagens/linksEmpresas/lopesTur';
import guerino from '../TopTrechosPassagens/linksEmpresas/guerino';
import juntos from '../TopTrechosPassagens/linksEmpresas/juntos';
import expressoGardenia from '../TopTrechosPassagens/linksEmpresas/expressoGardenia';

class PaginaDestinos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [
                ...guanabaraUtil, ...expressoGuanabara, ...realExpresso, ...realTransportes, ...rapidoFederal, ...catedral, ...cantelle, ...gontijo, ...eucatur, ...amatur, ...emtram, ...itapemirim,
                ...util, ...viacaoAdamantina, ...aguiaBranca, ...luxor, ...ouroEprata, ...andorinha, ...brasileiro, ...cidadeDoSol, ...gadotti, ...expressoItamarati, ...expressoDePrata, ...reunidasPaulista,
                ...expressoFloriano, ...viacaoPretti, ...expressoSaoLuiz, ...expressoJoia, ...sataliteNorte, ...viacaoRioDoce, ...reunidasTransportes, ...rota, ...rodeRotas, ...uneSul, ...reunidasSa, ...princesaDoNorte,
                ...lopesTur, ...guerino, ...juntos, ...expressoGardenia
            ],
            linksFiltrados: []
        };
    }

    componentDidMount() {
        this.filtrarLinks();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.destino !== this.props.match.params.destino) {
            this.filtrarLinks();
        }
    }

    filtrarLinks = () => {
        const {destino} = this.props.match.params;
        const {links} = this.state;

        if (destino) {
            const linksFiltrados = links.filter(link =>
                link.link.includes(destino.toLowerCase().replace(/ /g, ''))
            );
            this.setState({linksFiltrados});
        }
    }

    formatLinkText = (link) => {
        const parts = link.link.split('/');
        const origem = parts[2].replace(/-/g, ' ');
        const destino = parts[3].replace(/-/g, ' ');

        return `${origem} -> ${destino}`;
    }

    render() {
        const {linksFiltrados} = this.state;
        const {destino} = this.props.match.params;

        return (
            <div className='conteudo'>
                <div>
                    <BuscaPassagem/>
                </div>

                <div className="container">
                    <div className='col-12'>
                        <div className="row titulo-pag-rotas justify-content-center">
                            <h1 className="title-pag-rota"> Confira os Destinos para -
                                <span className="cidade-selecionada"> {destino.replace(/-/g, ' ')} </span>
                            </h1>
                            <div className="divider"/>
                        </div>

                        <div className="container-PagDestinos">
                            <ul className="cards-destino" id="cards-destino" style={{display: 'flex', flexWrap:'wrap'}}>
                                {linksFiltrados.length > 0 ? (
                                    linksFiltrados.map((link, index) => (
                                        <li className="col-6" key={index}>
                                        <a className='card itinerario-dia rotas-cidades' style={{fontSize: 15}}
                                              href={link.link}>
                                            {this.formatLinkText(link)}
                                        </a>
                                        </li>
                                    ))
                                ) : (
                                    <li className='mt-2'>Nenhum link encontrado para {destino.replace(/-/g, ' ')}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(PaginaDestinos);