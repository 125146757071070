import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import { recuperarConfEmpresa } from '../../util/applicationContext';
import {
    marcarPromocaoComoVisualizado,
} from '../../actions/promocoesActions';

export class ModalPromocao extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confEmpresa: recuperarConfEmpresa()
        }
    }

    componentDidMount() {
        if (!(this.state.confEmpresa.id === this.props.promocao.site && this.props.promocao.marcado)) {
            const valorTempo = 10 * 1000;
            setTimeout(() => {
                $('#modal-promocao').modal();
            }, valorTempo)
        }
    }

    fecharModal() {
        this.props.marcarPromocaoComoVisualizado();
        this.props.history.push('/cadastro-promocao');
        $('#modal-promocao').modal('hide');
    }

    render() {
        return (
            <div className="modal" role="dialog" id="modal-promocao" style={{ zIndex: '99999' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a href="#" className="close close-modal-promocao" data-dismiss="modal" aria-label="Close" style={{ color: '#fff', fontWeight: 'normal', fontSize: '13px' }}>
                                Fechar
                            </a>
                        </div>
                        
                        <button type="button" className="btn btn-excluir "
                            title='Modal Promocao'
                            onClick={() => this.fecharModal()}>
                            <img style={{ cursor: 'pointer' }} title='Clique aqui' src={(this.state.confEmpresa.bannerModalPromocao)} width='100%' />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


export const mapStateToProps = (state) => {    
    return {
        promocao: state.promocao
    }
};


export default withRouter(connect(mapStateToProps, {
    marcarPromocaoComoVisualizado,
})(ModalPromocao))