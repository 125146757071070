import React from 'react';
import logoBp from '../../../estilo/comum/img/logo-politur-transfer.webp';
import iconBrasilPassagens from '../../../estilo/comum/img/icon/politur-transfer.ico';

import ConteudoQuemSomosPolitur from '../../../componentes/quem-somos/politurtransfer/ConteudoQuemSomosPolitur';
import dadosSiteComum from './dadosSiteComum';

import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/8.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/15.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/POLITUR_TRANSFER.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/22.webp';


const logoBrasilCabecalho = <img className="logo-site Politur" src={logoBp} alt='Logo Politur Transfer'/>;
const logoBrasilRodape = <img className="brasilp" src={logoBp} alt="Logo Politur Transfer" style={{width: '45%'}}/>;

export const dadosPoliturTransferConfig = {
    ...dadosSiteComum,
    id: 'politurtransfer',
    pastaEstilo: 'politurtransfer',
    nome: 'Politur Transfer',
    nomePrefixo: 'o Politur Transfer',
    razaoSocial: 'POLITUR VIAGENS E TURISMO LTDA',
    cnpj: '49.733.691/0001-21',
    endereco:'BA-001, 12 - Ilha do Sol, Vera Cruz - BA, 44470-000',
    telefone: '(71) 99925-3816',
    wtpp: '(71) 99951-7900',
    instagram: 'https://www.instagram.com/politur_turismo/?utm_medium=copy_link',    
    tiktok: 'https://www.tiktok.com',
    facebook: 'https://www.facebook.com/p/Politur-Turismo-100071867514162/',
    youtube: 'youtube.com',
    email: 'contato@politurturismo.com.br',
    emailSuporte: 'contato@politurturismo.com.br',
    lkemailSuporte: 'mailto:contato@politurturismo.com.br',
    lktelefone: 'tel:5571999253816',
    twitter: '@BrasilPassagens',
    lkemail: 'mailto:contato@politurturismo.com.br',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561981537600&text=Olá. Preciso de atendimento.',
    logo: logoBp,
    logoCabecacho: logoBrasilCabecalho,
    logoRodape: logoBrasilRodape,
    OgImage: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/POLITUR_TRANSFER.webp',
    description: 'Compre passagens de ônibus sem sair de casa. São diversas opções de horários e viações para você escolher.',
    icone: iconBrasilPassagens,
    quemSomos: ConteudoQuemSomosPolitur,
    videoInicio: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/Transfer da Politur para Morro de São Paulo - Bahia1.mp4',
    idsTagManager: ['GTM-PHGZ336'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/Cupom_de_desconto_politur_transfer.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/2.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/2.mobile.webp',
    urlBase: 'https://politurtransfer.com'
};
