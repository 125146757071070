import { SLOW_REQUEST_CONFIG } from './loadingUtil';

import axios from 'axios';
import {recuperarConfEmpresa, salvarAmbiente} from './applicationContext';

export const BUSCAR_AMBIENTE = '@cidadeActions/BUSCAR_AMBIENTE';

const URL = process.env.NODE_ENV === 'production'
    ? 'https://vexado-api-site.vexado.com.br'
    : 'http://server-internal.vexado.com:8588';

const VERSION = 'master';
export { URL, VERSION}

export const ambienteUrl = `${URL}/ambiente`;

export const teste = () => {
    let confEmpresa = recuperarConfEmpresa();
    document.getElementsByName('description')[0].content = confEmpresa.nome
    document.getElementsByName('og:description')[0].content = confEmpresa.nome
    document.getElementsByName('og:title')[0].content = confEmpresa.nome
    document.getElementsByName('og:image')[0].content = confEmpresa.OgImage
};

export const recuperarAmbiente = callback => dispatch => axios.get(ambienteUrl, SLOW_REQUEST_CONFIG)
    .then((response) => {
        const ambiente = response.data;
        salvarAmbiente(ambiente);
        if (callback) {
            callback();
        }
        return dispatch({
            type: BUSCAR_AMBIENTE,
            payload: ambiente
        });
    });
