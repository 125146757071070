import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosSDTour = {
    ...dadosAgenciaComum,
    id: 'sdtour',
    nome: 'SD Passagens',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    email: 'suportevexado@gmail.com',
    idsTagManager: ['GTM-MCV3NTB']
};
