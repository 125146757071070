import React from 'react'
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import moment from 'moment'
import validador from 'br-validations';
import {validateCard, validateCVV} from './cartaoUtil';
import {isAmbienteProd} from './applicationContext';

export function required(value) {
    return value && value.length > 0 ? undefined : 'Campo obrigatório';
}

export function requiredSelect(value) {
    return value && value.value ? undefined : 'Campo obrigatório';
}

export const validarIgualdadeSenhas = (input, allInputs) => {
    return input === allInputs.password ? undefined : 'As senhas informadas são diferentes';
};

export const validarNomeCompleto = (input) => {
    const pattern = /^[a-zA-ZÀ-ÿ]{2,40}( [a-zA-ZÀ-ÿ]{2,40})+$/;
    return input && pattern.test(input.trim()) ? undefined : 'Nome inválido';
};


export function data(value) {
    if (!moment(value, 'DD/MM/YYYY', true)
        .isValid()) {
        return 'Data inválida'
    }
    return undefined
}

export function minDocumento(value) {
    if (!value || value.length < 2) {
        return 'Quantidade de caracteres deve ser maior que 1';
    }
    return undefined;
}

export function maxValueTelefone (value) {
  if (value && value.replace(/[^\d]/g, '').length != 11) {
    return 'Campo inválido';
  }
  return undefined;
}

export function maxValueData (value) {
  if (value && value.replace(/[^\d]/g, '').length != 8) {
    return 'Campo inválido';
  }
  return undefined;
}

export function minCartao(value) {
  if(!isAmbienteProd()){
    return undefined
  }
  const ehValido = validateCard(value)
  if (!ehValido) {
    return 'Número de cartão inválido';
  }
  return undefined;
}

export function validarCVV(numCartao, cvv) {
  const ehValido = validateCVV(numCartao,cvv)
  if (!ehValido) {
    return 'Código inválido';
  }
  return undefined;
}

export const requiredCPF = value => {
    return (value && validador.cpf.validate(value) ? undefined : 'CPF inválido');
};

export function apenasLetra (value) {
  if(value && value.match(/[\d]/g)){
    return 'O campo não pode conter caracteres numéricos'
  }
  return undefined;
}

export function maxTamanhoNome (value) {
  if (!value || value.length > 255) {
    return 'Quantidade de caracteres deve ser menor que 255';
  }
  return undefined;
}

export const renderFieldNumeroDocumento = ({
                                               input,
                                               label,
                                               type,
                                               required,
                                               css,
                                               max,
                                               readOnly, preencherNumeroDocumentoPassageiro, passageiro,
                                               meta: {touched, error},
                                           }) => (
    <div>
        <label className='mb-2'>{label}</label>
        <div>
            <input {...input} type={type}
                   id={input.name}
                   className={css}
                   required={required}
                   maxLength={max}
                   style={{borderColor: touched && error ? 'red' : ''}}
                   disabled={readOnly}
                   onBlur={(e) => preencherNumeroDocumentoPassageiro(e.target.value, passageiro)}
                   readOnly={readOnly}/>
            {touched
            && (error && <span style={{color: 'red'}}>{error}</span>)}
        </div>
    </div>
);
export const renderFieldNomePassageiro = ({
                                              input,
                                              label,
                                              type,
                                              required,
                                              css,
                                              max,
                                              readOnly, preencherNomePassageiro, passageiro,
                                              meta: {touched, error},
                                          }) => (
    <div>
        <label className='mb-2'>{label}</label>
        <div>
            <input {...input} type={type}
                   id={input.name}
                   className={css}
                   required={required}
                   maxLength={max}
                   style={{borderColor: touched && error ? 'red' : ''}}
                   disabled={readOnly}
                   onBlur={(e) => preencherNomePassageiro(e.target.value, passageiro)}
                   readOnly={readOnly}/>
            {touched
            && (error && <span style={{color: 'red'}}>{error}</span>)}
        </div>
    </div>
);

export const renderFieldText = ({
                                    input,
                                    label,
                                    type,
                                    css,
                                    max,
                                    placeholder,
                                    readOnly,
                                    meta: {touched, error},
                                }) => (
    <div>
        <label className='mb-2 mt-2'>{label}</label>
        <div>
            <input {...input} type={type}
                   className={css}
                   placeholder={placeholder}
                   maxLength={max}
                   value={input.value}
                   style={{borderColor: touched && error ? 'red' : ''}}
                   disabled={readOnly}
                   readOnly={readOnly}/>
            {touched
            && (error && <span style={{color: 'red'}}>{error}</span>)}
        </div>
    </div>
);


export const renderFieldSelectParcelas = ({
                                              input,
                                              placeholder,
                                              options,
                                              label,
                                              isClearable,
                                              disabled,
                                              meta: {touched, error},
                                          }) => (<div>
    <div>
        {label ? <label className='mb-2 mt-2'>{label}</label> : ''}
        <Select {...input}
                id={input.name}
                isClearable={isClearable}
                placeholder={placeholder}
                value={input.value}
                isDisabled={disabled}
                noOptionsMessage={() => ('Nenhuma opção encontrada')}
                options={options.map(elemento => ({
                    label: elemento.parcela,
                    value: elemento.qtdParcelas,
                    valorTotal: elemento.valorTotal,
                    qtdParcelas: elemento.qtdParcelas,
                    metodoPagamento: elemento.metodoPagamento,
                }))}
                onChange={value => input.onChange(value)}
                onBlur={() => input.onBlur()}
        />
        {touched
        && (error && <span style={{color: 'red'}}>{error}</span>)}
    </div>
</div>);

export const renderFieldSelect = ({
                                            input,
                                            placeholder,
                                            options,
                                            label,
                                            isClearable,
                                            disabled,
                                            meta: {touched, error},
                                          }) => (<div>
  <div>
    {label ? <label className='mb-2 mt-2'>{label}</label> : ''}
    <Select {...input}
            id={input.name}
            isClearable={isClearable}
            placeholder={placeholder}
            value={input.value}
            isDisabled={disabled}
            noOptionsMessage={() => ('Nenhuma opção encontrada')}
            options={options.map(elemento => ({
                label: elemento.label,
                value: elemento.value
              }))}
            onChange={value => input.onChange(value)}
            onBlur={() => input.onBlur()}
    />
    {touched
    && (error && <span style={{color: 'red'}}>{error}</span>)}
  </div>
</div>);

export const renderField = ({
                                input,
                                label,
                                type,
                                css,
                                max,
                                disabled,
                                readOnly,
                                placeholder,
                                options,
                                meta: {
                                    touched,
                                    error,
                                },
                            }) => {
    if (input.type === 'textarea') {
        return (<div>
            <label className="mb-2 mt-2">{label}</label>
            <div>
                <textarea {...input}
                          className={css}
                          rows="5"
                          id={input.name}
                          maxLength={max}
                          style={{borderColor: touched && error ? 'red' : ''}}
                          disabled={readOnly || disabled}
                          readOnly={readOnly}
                          placeholder={placeholder}/>
                {touched
                && (error && <span style={{color: 'red'}}>{error}</span>)}
            </div>
        </div>);
    }
    if (type === 'checkbox') {
        return (<div>
            <div>
                <input {...input} type={type}
                       className={css}
                       id={input.name}
                       maxLength={max}
                       style={{borderColor: touched && error ? 'red' : ''}}
                       disabled={readOnly || disabled}
                       readOnly={readOnly}/>
                <label className="ml-2">{label}</label>
                {touched
                && (error && <span style={{color: 'red'}}>{`  ${error}`}</span>)}
            </div>
        </div>);
    }
    if (type === 'select') {
        return (<div>
            <div>
                <Select {...input}
                        id={input.name}
                        placeholder={placeholder}
                        value={input.value}
                        noOptionsMessage={() => ('Nenhuma opção encontrada')}
                        options={options.map(elemento => ({
                            label: elemento.descricao,
                            value: elemento.nome,
                        }))}
                        onChange={value => input.onChange(value)}
                        onBlur={() => input.onBlur()}
                />
                {touched
                && (error && <span style={{color: 'red'}}>{error}</span>)}
            </div>
        </div>);
    }
    if (type === 'cpf') {
        return (<div>
            <label className="mb-2 mt-2">{label}</label>
            <div>
                <NumberFormat {...input} format="###.###.###-##"
                              className={css}
                              maxLength={max}
                              placeholder={placeholder}
                              id={input.name}
                              style={{borderColor: touched && error ? 'red' : ''}}
                              readOnly={readOnly}
                />
                {touched
                && (error && <span style={{color: 'red'}}>{error}</span>)}
            </div>
        </div>);
    }

    if (type === 'cep') {
        return (<div><label className="mb-2 mt-2">{label}</label>
            <div>
                <NumberFormat {...input} format="#####-###"
                              className={css}
                              maxLength={max}
                              style={{borderColor: touched && error ? 'red' : ''}}
                              readOnly={readOnly}
                />
                {touched
                && (error && <span style={{color: 'red'}}>{error}</span>)}
            </div>
        </div>)
    }

    if (type === 'celular') {
        return (<div>
            <label className='mt-2'>{label}</label>
            <div>
                <NumberFormat {...input} format="(##) #####-####"
                              className={css}
                              maxLength={max}
                              id={input.name}
                              style={{borderColor: touched && error ? 'red' : ''}}
                              readOnly={readOnly}
                />
                {touched
                && (error && <span style={{color: 'red'}}>{error}</span>)}
            </div>
        </div>)
    }

    if (type === 'email') {
      return (
        <div>
          <label className='login-nm'>{label}</label>
          <input className="form-control"
                 id={input.id}                 
                 style={{ textTransform: 'lowercase', borderColor: touched && error ? 'red' : '' }}
                 type={type}
                 placeholder={placeholder}
                 {...input}
                 autoFocus={input.name === 'username'}
                 value={input.value.toLowerCase()} />
          <div>
          {touched
                && (error && <span style={{color: 'red'}}>{error}</span>)}
          </div>
        </div>
      )
    }

    return (<div>
        {label ? <label className="mb-2 mt-2">{label}</label> : ''}
        <div>
            <input {...input} type={type}
                   className={css}
                   id={input.name}
                   maxLength={max}
                   style={{borderColor: touched && error ? 'red' : ''}}
                   disabled={readOnly || disabled}
                   readOnly={readOnly}
                   placeholder={placeholder}/>
            {touched
            && (error && <span style={{color: 'red'}}>{error}</span>)}
        </div>
    </div>);
};


export const renderFieldCPF = ({
                                      input,
                                      label,
                                      css,
                                      max,
                                      readOnly,
                                      preencherCpfPassageiro,
                                      passageiro,
                                      meta: { touched, error }
                                    }) => (
  <div>
    <label className="mb-2">{label}</label>
    <div>
      <NumberFormat {...input} format="###.###.###-##"
                    className={css}
                    maxLength={max}
                    id={input.name}
                    onBlur={ e => preencherCpfPassageiro(e.target.value, passageiro)}
                    style={{borderColor: touched && error ? 'red' : ''}}
                    readOnly={readOnly}
      />
      {touched
      && (error && <span style={{color: 'red'}}>{error}</span>)}
    </div>
  </div>
);


export const renderFieldTelefonePassageiro = ({
                                      input,
                                      label,
                                      css,
                                      max,
                                      readOnly,
                                      preencherTelefonePassageiro,
                                      passageiro,
                                      meta: { touched, error }
                                    }) => (
  <div>
    <label className='mb-2'>{label}</label>
    <div>
      <NumberFormat {...input} format="(##) #####-####"
                    className={css}
                    maxLength={max}
                    id={input.name}
                    style={{ borderColor: touched && error ? 'red' : '' }}
                    onBlur={ e => preencherTelefonePassageiro(e.target.value, passageiro)}
                    readOnly={readOnly}
      />
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>
);

export const renderFieldTelefone = ({
                                      input,
                                      label,
                                      css,
                                      max,
                                      readOnly,
                                      meta: { touched, error }
                                    }) => (
  <div>
    <label className='mb-2 mt-2'>{label}</label>
    <div>
      <NumberFormat {...input} format="(##) #####-####"
                    className={css}
                    maxLength={max}
                    id={input.name}
                    style={{ borderColor: touched && error ? 'red' : '' }}
                    readOnly={readOnly}
      />
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>
);

export const renderFieldData = ({
                                      input,
                                      label,
                                      css,
                                      max,
                                      readOnly,
                                      meta: { touched, error }
                                    }) => (
  <div>
    <label className='mb-2 mt-2'>{label}</label>
    <div>
      <NumberFormat {...input} format="##/##/####"
                    className={css}
                    maxLength={max}
                    id={input.name}
                    style={{ borderColor: touched && error ? 'red' : '' }}
                    readOnly={readOnly}
      />
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>
);

export const renderFieldValidadeCartao = ({
                                             input,
                                             label,
                                             css,
                                             max,
                                             readOnly,
                                             meta: { touched, error }
                                           }) => (
  <div>
    <label className='mb-2 mt-2'>{label}</label>
    <div>
      <NumberFormat {...input} format='##/##'
                    className={css}
                    maxLength={max}
                    id={input.name}
                    style={{ borderColor: touched && error ? 'red' : '' }}
                    readOnly={readOnly}
      />
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>
);

export const renderFieldCodigoSeguranca = ({
                                      input,
                                      css,
                                      max,
                                      readOnly,
                                      meta: { touched, error }
                                    }) => (
  <div>
    <div>
      <NumberFormat {...input} format="###"
                    className={css}
                    maxLength={max}
                    id={input.name}
                    style={{ borderColor: touched && error ? 'red' : '' }}
                    readOnly={readOnly}
      />
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>
);

export const renderFieldNumeroCartao = ({
                                             input,
                                             label,
                                             css,
                                             max,
                                             readOnly,
                                             buscarParcelas,
                                             meta: { touched, error }
                                           }) => (
  <div>
    <label className='mb-2 mt-2'>{label}</label>
    <div>
      <NumberFormat {...input} format="#### #### #### ####"
                    className={css}
                    maxLength={max}
                    id={input.name}
                    onChange={buscarParcelas}
                    style={{ borderColor: touched && error ? 'red' : '' }}
                    readOnly={readOnly}
      />
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>
);

export const renderTextArea2 = ({
                                  input,
                                  label,
                                  readOnly,
                                  css,
                                  max,
                                  meta: { touched, error }
                                }) => (
  <div>
    <span className='mt-2'>{label}</span>
    <div>
        <textarea {...input}
                  className={css}
                  rows='5'
                  id={input.name}
                  maxLength={max}
                  style={{ borderColor: touched && error ? 'red' : '' }}
                  disabled={readOnly}
                  readOnly={readOnly}/>
      {touched
      && (error && <span style={{ color: 'red' }}>{error}</span>)}
    </div>
  </div>);

export const renderFieldUpload = ({
                                    input,
                                    handleChange, titulo,
                                    meta: { touched, error }
                                  }) => (
  <div>
    <div className='input-wrapper'>
      <label htmlFor='input-file'>
        {titulo}
      </label>
      <input {...input} id='input-file' type='file' value='' accept="image/*" onChange={handleChange}/>
      <span id='file-name'/>
      {touched
      && (error && <strong style={{
        color: 'red',
        fontSize: 10
      }}>{error}</strong>)}
    </div>
  </div>
);
