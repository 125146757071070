const topTrechosVexadoEagencias = [
    {
        idCidadeOrigem: 1023,
        nomeCidadeOrigemComUF: 'Fortaleza-CE',
        nomeCidadeOrigemComUFNormalizado: 'fortaleza-ce',
        idCidadeDestino: 1110,
        nomeCidadeDestinoComUF: 'Juazeiro do Norte-CE',
        nomeCidadeDestinoComUFNormalizado: 'juazeirodonorte-ce',
        ordem: 1
    },
    {
        idCidadeOrigem: 4742,
        nomeCidadeOrigemComUF: 'Porto Alegre-RS',
        nomeCidadeOrigemComUFNormalizado: 'portoalegre-rs',
        idCidadeDestino: 5002,
        nomeCidadeDestinoComUF: 'Florianópolis-SC',
        nomeCidadeDestinoComUFNormalizado: 'florianopolis-sc',
        ordem: 2
    },
    {
        idCidadeOrigem: 4160,
        nomeCidadeOrigemComUF: 'Rio de Janeiro-RJ',
        nomeCidadeOrigemComUFNormalizado: 'riodejaneiro-rj',
        idCidadeDestino: 1292,
        nomeCidadeDestinoComUF: 'Vitória-ES',
        nomeCidadeDestinoComUFNormalizado: 'vitoria-es',
        ordem: 3
    },
    {
        idCidadeOrigem: 3788,
        nomeCidadeOrigemComUF: 'Curitiba-PR',
        nomeCidadeOrigemComUFNormalizado: 'curitiba-pr',
        idCidadeDestino: 4951,
        nomeCidadeDestinoComUF: 'Blumenau-SC',
        nomeCidadeDestinoComUFNormalizado: 'blumenau-sc',
        ordem: 4
    },
    {
        idCidadeOrigem: 4387,
        nomeCidadeOrigemComUF: 'Porto Velho-RO',
        nomeCidadeOrigemComUFNormalizado: 'portovelho-ro',
        idCidadeDestino: 794,
        nomeCidadeDestinoComUF: 'Manaus-AM',
        nomeCidadeDestinoComUFNormalizado: 'manaus-am',
        ordem: 5
    },
    {
        idCidadeOrigem: 1964,
        nomeCidadeOrigemComUF: 'Cuiabá-MT',
        nomeCidadeOrigemComUFNormalizado: 'cuiaba-mt',
        idCidadeDestino: 1867,
        nomeCidadeDestinoComUF: 'Campo Grande-MS',
        nomeCidadeDestinoComUFNormalizado: 'campogrande-ms',
        ordem: 6
    },
    {
        idCidadeOrigem: 5211,
        nomeCidadeOrigemComUF: 'Aracaju-SE',
        nomeCidadeOrigemComUFNormalizado: 'aracaju-se',
        idCidadeDestino: 701,
        nomeCidadeDestinoComUF: 'Maceió-AL',
        nomeCidadeDestinoComUFNormalizado: 'maceio-al',
        ordem: 7
    },
    {
        idCidadeOrigem: 1292,
        nomeCidadeOrigemComUF: 'Vitória-ES',
        nomeCidadeOrigemComUFNormalizado: 'vitoria-es',
        idCidadeDestino: 5848,
        nomeCidadeDestinoComUF: 'São Paulo-SP',
        nomeCidadeDestinoComUFNormalizado: 'saopaulo-sp',
        ordem: 8
    },
    {
        idCidadeOrigem: 3411,
        nomeCidadeOrigemComUF: 'Petrolina-PE',
        nomeCidadeOrigemComUFNormalizado: 'petrolina-pe',
        idCidadeDestino: 6036,
        nomeCidadeDestinoComUF: 'Salvador-BA',
        nomeCidadeDestinoComUFNormalizado: 'salvador-ba',
        ordem: 9
    },
    {
        idCidadeOrigem: 5051,
        nomeCidadeOrigemComUF: 'Joinville-SC',
        nomeCidadeOrigemComUFNormalizado: 'joinville-sc',
        idCidadeDestino: 3788,
        nomeCidadeDestinoComUF: 'Curitiba-PR',
        nomeCidadeDestinoComUFNormalizado: 'curitiba-pr',
        ordem: 10
    },
    {
        idCidadeOrigem: 583,
        nomeCidadeOrigemComUF: 'Palmas-TO',
        nomeCidadeOrigemComUFNormalizado: 'palmas-to',
        idCidadeDestino: 1,
        nomeCidadeDestinoComUF: 'Brasília-DF',
        nomeCidadeDestinoComUFNormalizado: 'brasilia-df',
        ordem: 11
    },
    {
        idCidadeOrigem: 3684,
        nomeCidadeOrigemComUF: 'Teresina-PI',
        nomeCidadeOrigemComUFNormalizado: 'teresina-pi',
        idCidadeDestino: 1023,
        nomeCidadeDestinoComUF: 'Fortaleza-CE',
        nomeCidadeDestinoComUFNormalizado: 'fortaleza-ce',
        ordem: 12
    },
    {
        idCidadeOrigem: 463,
        nomeCidadeOrigemComUF: 'São Luís-MA',
        nomeCidadeOrigemComUFNormalizado: 'saoluis-ma',
        idCidadeDestino: 2085,
        nomeCidadeDestinoComUF: 'Belém-PA',
        nomeCidadeDestinoComUFNormalizado: 'belem-pa',
        ordem: 13
    },
    {
        idCidadeOrigem: 4194,
        nomeCidadeOrigemComUF: 'Apodi-RN',
        nomeCidadeOrigemComUFNormalizado: 'apodi-rn',
        idCidadeDestino: 3423,
        nomeCidadeDestinoComUF: 'Salgueiro-PE',
        nomeCidadeDestinoComUFNormalizado: 'salgueiro-pe',
        ordem: 14
    },
    {
        idCidadeOrigem: 701,
        nomeCidadeOrigemComUF: 'Maceió-AL',
        nomeCidadeOrigemComUFNormalizado: 'maceio-al',
        idCidadeDestino: 5211,
        nomeCidadeDestinoComUF: 'Aracaju-SE',
        nomeCidadeDestinoComUFNormalizado: 'aracaju-se',
        ordem: 15
    },
]

export default topTrechosVexadoEagencias;