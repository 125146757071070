import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContenText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { dismissMessage } from '../actions/messagesActions';

const styles = {
  paperClassName: {
    borderRadius: '2%',
    outline: 'none'
  },
  backdropClassName: {
    backgroundColor: 'rgba(255, 255, 255, 0.65)'
  },
  item: {
    paddingTop: 12
  },
  link: {
    padding: 12
  }
};

export class PaginaErro extends Component {
  componentWillUnmount() {
    this.props.dismissMessage();
  }

  render() {
    const { messages: { message } } = this.props;

    return (
      <main>
        <Dialog open
          PaperProps={{ elevation: 2 }}>
          <DialogTitle disableTypography>
            <Typography variant='h4' color='secondary'>
              Ocorreu um erro inesperado
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContenText component='div'>
              <Typography variant='h6'>
                Aguarde um momento e tente novamente.
              </Typography>
            </DialogContenText>
            <DialogContenText component='div'>
              <Typography variant='subtitle1' >
                Se o problema persistir entre em contato com o suporte através do whatsapp: (61) 98153-7600.
                <div className='whatsapp'>
                  <Button id='buttonPagErroWhats' variant="primary" href="https://wa.me/5561981537600" target="_blank" rel="noopener noreferrer">
                    <span id='fraseErroWhats'>
                      Iniciar conversa WhatsApp
                      <i id='iconWhatsErro' className='fab fa-whatsapp'></i>
                    </span>
                  </Button>
                </div>
              </Typography>

            </DialogContenText>
            {message ? (
              <DialogContenText component='div'>
                <Typography variant='body2' style={{ maxWidth: 800 }}>
                  Mensagem: {`"${message.msgText}"`}
                </Typography>
              </DialogContenText>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { window.location = '/'; }} color='default'>
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}

const materialUIEnhanced = withStyles(styles)(PaginaErro);

const mapStateToProps = ({ messages }) => ({ messages });

const reduxEnhanced = connect(mapStateToProps, { dismissMessage })(materialUIEnhanced);

export default withRouter(reduxEnhanced);
