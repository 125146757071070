import React, { Component } from 'react'
import logoSistemaVexado from '../../../estilo/comum/img/sistemaVexado/sistemaVexadoLogo.png'
import graficoSistemaVexado from '../../../estilo/comum/img/sistemaVexado/5.png'
import imgGerenciamento from '../../../estilo/comum/img/sistemaVexado/4.png'
import imgSistemaVexado from '../../../estilo/comum/img/sistemaVexado/8.png'
import imgEstrategia from '../../../estilo/comum/img/sistemaVexado/7.png'

export class SitemaVexado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openCollapse: null
        };
    }

    buttonSaibaMais() {
        return <a href='https://wa.me/5561981537600'>
            <button id='btnSaibaMais' type='button' className='btn btn-success'>SAIBA MAIS</button>
        </a>
    }

    handleCollapseClick = (event) => {
        const target = document.querySelector(event.currentTarget.getAttribute('data-target'));
        const isTargetOpen = target.classList.contains('show');
        const collapses = document.querySelectorAll('.collapse');
        collapses.forEach(collapse => {
            if (collapse !== target) {
                collapse.classList.remove('show');
            }
        });
        target.classList.toggle('show', !isTargetOpen);
    };

    arrow() {
        return <svg xmlns='http://www.w3.org/2000/svg' id='arrow' width='12' height='12' fill='currentColor'
            className='bi bi-caret-up-fill' viewBox='0 0 16 16'>
            <path
                d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z' />
        </svg>
    }

    componentDidMount() {
        const debounce = function (func, wait, immediate) {
            let timeout;
            return function (...args) {
                const context = this;
                const later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        const target = document.querySelectorAll('[data-anime]');
        const animationClass = 'animate';

        function animeScroll() {
            const windowTop = window.pageYOffset + (window.innerHeight * 3) / 4;
            target.forEach(function (element) {
                if (windowTop > element.offsetTop) {
                    element.classList.add(animationClass);
                } else {
                    element.classList.remove(animationClass);
                }
            });
        }

        animeScroll();
        if (target.length) {
            window.addEventListener(
                'scroll',
                debounce(function () {
                    animeScroll();
                }, 200)
            );
        }
    }

    render() {
        return (
            <div id='secao-sistema-vexado' className='VexadoSistemasPag'>
                <div className='logoVexadoSistemas'>
                    <img id='LogoSistemaVexado' className='logo' src={logoSistemaVexado} alt='Logo SistemaVexado' />
                </div>
                <div id='container-first' className='containerVexado1'>
                    <div className='row'>
                        <div id='section1' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Sistema pensado e desenvolvido para gestão 360° da operação do transporte de ponta a ponta.
                            </div>
                            <div className='text'>
                                Venha conosco e faça sua empresa voar
                                Desenvolvemos e implementamos soluções em tecnologia personalizadas
                                que agregam valor ao seu negócio e potencializam resultados.
                            </div>
                            <div className='text'>
                                Nós do Vexado Sistemas, , preparamos empresas para os desafios do presente e do futuro,
                                direcionando-as à Transformação Digital,
                                eficiência, redução de custos, agilidade e humanização dos processos,
                                pensando sempre na melhor experiência do cliente.
                            </div>
                            <div>
                                {this.buttonSaibaMais()}
                            </div>
                        </div>
                        <div id='section1' className='col-md-6'>
                            <div>
                                <img id='imgInicial' src={graficoSistemaVexado} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-second' className='bg-primary' data-anime="left">
                    <div className='row'>
                        <div id='section-gerenciamento' className='col-md-6'>
                            <div id='tituloSobreNos' className='text'>
                                Sobre o Vexado
                            </div>
                            <div className='sectionImg'>
                                <img id='imgGerenciamento' src={imgGerenciamento} />
                            </div>
                        </div>
                        <div id='section2' className='col-md-6'>
                            <div className='text'>
                                Nós do Vexado Sistemas, preparamos empresas para os desafios do presente e do futuro, direcionando-as à Transformação Digital, eficiência, redução de custos, agilidade e humanização dos processos, pensando sempre na melhor experiência do cliente.
                            </div>
                            <div className='text'>
                                Trabalhamos para evoluir a sua empresa de dentro para fora, de forma rápida, consistente e compatível com as mudanças cada vez mais aceleradas do nosso tempo. Seu desenvolvimento, nossa Meta.
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-third' className='bg-primary' data-anime="right">
                    <div className='row'>
                        <div id='section4' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Soluções Vexado
                            </div>
                            <div className='text'>
                                Por meio das nossas ferramentas e tecnologias, Agregamos valor a sua Empresa de modo geral, para que o seu negócio evolua a cada dia. Se a sua empresa se identifica com alguns dos cenários abaixo, nós temos as soluções para supri-los.
                            </div>
                            <div>
                                {this.buttonSaibaMais()}
                            </div>
                        </div>
                        <div id='section3' className='col-md-6'>
                            <div>
                                <img id='imgAdminVexado' src={imgSistemaVexado} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-fourth' className='bg-primary' data-anime="left">
                    <div className='row'>
                        <div id='section5' className='col-md-6'>
                            <div>
                                <img id='imgEstrategia' src={imgEstrategia} />
                            </div>
                        </div>
                        <div id='section3' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Estratégia e Soluções Digitais
                            </div>
                            <div className='accordion' id='accordionExample'>
                                <div className='card'>
                                    <div className='card-header' id='headingOne'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseOne'
                                                aria-controls='collapseOne'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Pressão por transformação digital.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseOne' className='collapse' aria-labelledby='headingOne'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A pressão por transformação digital não deve ser vista como um fardo, mas sim como uma oportunidade para desenvolver estratégias e soluções digitais que impulsionem o crescimento e a competitividade do negócio.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingTwo'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseTwo'
                                                aria-controls='collapseTwo'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Necessidade por redução de custos e eficiência operacional.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseTwo' className='collapse' aria-labelledby='headingTwo'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            Em um cenário de competitividade acirrada, a estratégia digital que visa a redução de custos e aumento da eficiência operacional torna-se essencial para o sucesso das empresas.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingThree'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseThree'
                                                aria-controls='collapseThree'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Não consegue crescer e expandir o negócio?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseThree' className='collapse' aria-labelledby='headingThree'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            Ao adotar uma estratégia digital eficiente e soluções inovadoras, é possível superar as barreiras que impedem o crescimento e expansão do negócio, abrindo caminhos para novas oportunidades no mercado.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingFor'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseFor'
                                                aria-controls='collapseFor'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Adaptações internas e externas com novas tecnologias que conduzem à Transformação Digital nas empresas, facilitando a gestão e operação.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseFor' className='collapse' aria-labelledby='headingFor'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            Através da adoção de soluções digitais e adaptações internas e externas, as empresas podem alcançar a transformação digital e facilitar a gestão e operação, tornando-se mais ágeis, eficientes e competitivas no mercado.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingFive'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseFive'
                                                aria-controls='collapseFive'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Soluções tecnológicas personalizadas e eficientes para maior performance e mais agilidade de negócios.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseFive' className='collapse' aria-labelledby='headingFive'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            Através da implementação de soluções tecnológicas personalizadas e eficientes, as empresas podem atingir uma maior performance e agilidade em seus negócios, otimizando processos, reduzindo custos e aumentando a satisfação dos clientes.
                                            <a id='adquirirAgencia' href='/formulario-de-cadastro/'> Não perca tempo! Se cadastre e não fique de fora dessa!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-fourth' className='bg-primary' data-anime="right">
                    <div className='row'>
                        <div id='section3' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Plataformas de Gestão
                            </div>
                            <div className='accordion' id='accordionExample'>
                                <div className='card'>
                                    <div className='card-header' id='headingSix'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseSix'
                                                aria-controls='collapseSix'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Falta de confiança na informação e duplicidade dos dados
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseSix' className='collapse' aria-labelledby='headingOne'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A adoção de plataformas de gestão digitais confiáveis e integradas pode resolver a falta de confiança na informação e a duplicidade dos dados, permitindo que as empresas tenham uma visão única e precisa de seus processos e tomem decisões mais acertadas e estratégicas.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingSeven'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseSeven'
                                                aria-controls='collapseSeven'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Softwares de gestão lentos e ultrapassados
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseSeven' className='collapse' aria-labelledby='headingSeven'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A adoção de plataformas de gestão digitais modernas e ágeis pode substituir os softwares lentos e ultrapassados, permitindo que as empresas tenham um controle mais efetivo sobre seus processos, aumentando a eficiência e produtividade, e mantendo-se competitivas em um mercado em constante evolução.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingEight'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseEight'
                                                aria-controls='collapseEight'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Softwares que te obriga a está preso a um computador.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseEight' className='collapse' aria-labelledby='headingEight'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A utilização de plataformas de gestão digitais baseadas em nuvem permite que as empresas não fiquem mais presas a um computador, possibilitando um gerenciamento remoto de seus processos, aumentando a flexibilidade e mobilidade da equipe e proporcionando uma maior agilidade na tomada de decisões.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingNine'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseNine'
                                                aria-controls='collapseNine'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Controle e visão limitados do negócio e da real situação da empresa.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseNine' className='collapse' aria-labelledby='headingNine'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A implementação de plataformas de gestão digitais eficientes e integradas pode fornecer uma visão mais completa e detalhada do negócio, permitindo que a empresa tenha um controle mais preciso e uma compreensão mais profunda de sua real situação, facilitando a identificação de oportunidades de melhoria e tomada de decisões mais estratégicas.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingTen'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseTen'
                                                aria-controls='collapseTen'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Toda alteração que precisa eles te cobram valores absurdos.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseTen' className='collapse' aria-labelledby='headingTen'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A implementação de plataformas de gestão digitais pode evitar custos excessivos com alterações de software, uma vez que essas plataformas permitem personalizações de forma mais rápida e fácil, reduzindo a dependência de desenvolvedores e minimizando os valores cobrados por essas alterações.
                                            <a id='adquirirAgencia' href='/formulario-de-cadastro/'>Não perca tempo! Se cadastre e não fique de fora dessa!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='section6' className='col-md-6'>
                            <div id='' className='text'>
                                Trabalhamos no formato de parceria de negócio, visando o seu maior desempenho e aproveitamento, a visão é descomplicar e integrar, trazendo uma agilidade maior na ponta sem esquecer da retaguarda, criando e inovando conforme a sua necessidade, que após firmar a parceria a necessidade passa a ser nossa, e a partir daí buscamos com mão de obra e tecnologia os melhores resultados.
                            </div>
                            <div>
                                {this.buttonSaibaMais()}
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-fifth' className='bg-primary' data-anime="left">
                    <div id='section7' className='col-md-6'>
                        <div id='titulo' className='text'>
                            Nossas redes sociais
                        </div>
                        <div id='wrapperSocialMedia' className='wrapper'>
                            <div className='icon facebook'>
                                <div className='tooltip'>
                                    Facebook
                                </div>
                                <span><i className='fab fa-facebook-f'></i></span>
                            </div>
                            <div className='icon twitter'>
                                <div className='tooltip'>
                                    Twitter
                                </div>
                                <span><i className='fab fa-twitter'></i></span>
                            </div>
                            <div className='icon instagram'>
                                <div className='tooltip'>
                                    Instagram
                                </div>
                                <span><i className='fab fa-instagram'></i></span>
                            </div>
                            <div className='icon whatsapp'>
                                <div className='tooltip'>
                                    WhatsApp
                                </div>
                                <span>
                                    <a href="https://wa.me/5561981537600">
                                        <i id='icon-map' className='fab fa-whatsapp'></i>
                                    </a>
                                </span>
                            </div>
                            <div className='icon youtube'>
                                <div className='tooltip'>
                                    YouTube
                                </div>
                                <span><i className='fab fa-youtube'></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-fourth' className='bg-primary' data-anime="right">
                    <div className='row'>
                        <div id='section3' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Melhoria de Performance e Suporte
                            </div>
                            <div className='accordion' id='accordionExample'>
                                <div className='card'>
                                    <div className='card-header' id='headingElleven'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseElleven'
                                                aria-controls='collapseElleven'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Grande número de interrupções causando impacto no negócio.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseElleven' className='collapse' aria-labelledby='headingElleven'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A adoção de soluções de suporte especializado e ferramentas de monitoramento de desempenho pode reduzir o grande número de interrupções que impactam o negócio, permitindo que as empresas melhorem a sua performance operacional e minimizem as perdas financeiras e de produtividade decorrentes dessas interrupções.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingTwelve'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseTwelve'
                                                aria-controls='collapseTwelve'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Capacidade limitada de inovação.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseTwelve' className='collapse' aria-labelledby='headingTwelve'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A busca por soluções de suporte especializado e ferramentas de análise de dados pode aumentar a capacidade de inovação das empresas, permitindo que elas identifiquem novas oportunidades de negócios, melhorem seus processos e produtos, e se mantenham competitivas em um mercado em constante evolução.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingThirteen'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseThirteen'
                                                aria-controls='collapseThirteen'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Falta de expertise técnica.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseThirteen' className='collapse' aria-labelledby='headingThirteen'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A contratação de serviços de suporte técnico especializado pode suprir a falta de expertise técnica das empresas, oferecendo soluções eficazes para os desafios tecnológicos, aumentando a produtividade e melhorando a performance operacional.
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header' id='headingFourteen'>
                                        <h2 className='mb-0'>
                                            <button ref={this.buttonRef} className={`btn btn-link btn-block text-left ${!this.state.showOne ? 'collapsed' : ''}`}
                                                type='button'
                                                data-target='#collapseFourteen'
                                                aria-controls='collapseFourteen'
                                                onClick={this.handleCollapseClick}>
                                                {this.arrow()} Para receber suporte tem que abrir chamado.
                                            </button>
                                        </h2>
                                    </div>
                                    <div id='collapseFourteen' className='collapse' aria-labelledby='headingFourteen'
                                        data-parent='#accordionExample'>
                                        <div className='card-body'>
                                            A adoção de serviços de suporte técnico remoto reduz a necessidade de abrir chamados para receber suporte, permitindo que as empresas obtenham ajuda imediata e resolvam os problemas de forma mais ágil, o que pode resultar em uma melhoria significativa de sua performance operacional.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id='section6' className='col-md-6'>
                            <div id='' className='text'>
                                Gestão eficiente de atendimento e suporte técnico para melhor experiência do usuário, com redução de custo e ganho de produtividade, suporte 24 horas por dia, contato direto sem frescura e sem abertura de chamado, para o vexado a solução é agora.
                            </div>
                            <div>
                                {this.buttonSaibaMais()}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default (SitemaVexado)