import React from 'react';

const SacAtendimento = ({ confEmpresa }) => (
    confEmpresa.id === 'amarelinho' ?
    <div style={{ lineHeight: 1.6, fontSize: '16px' }}>
        <div>
            <div><strong>SAC:</strong></div>
            <div>Disque {confEmpresa.telefone} ou {confEmpresa.wtpp}</div>
            <div>Email: <a href={confEmpresa.lkemail} target="_blank" rel="noopener noreferrer">{confEmpresa.email}</a></div>
            <div>
                <p><strong>Deficiente Auditivo ou Fala:</strong> {confEmpresa.telelefoneAtendimentoDeficienteAuditivoOuFala}</p>
            </div>
        </div>
    </div> : ''
)

export default SacAtendimento