import React from 'react';
import logoAgencia from '../../../estilo/comum/img/logo-geral-agencia.png'
import iconAgencia from '../../../estilo/comum/img/icon/logo-geral-agencia.png'
import bannerAgencia from '../../../estilo/comum/img/banner-site-agencias.png'
import bannerAgenciaMobile from '../../../estilo/comum/img/banner-site-agencias-mobile.png'
import bannerAgenciaMobile2 from '../../../estilo/comum/img/banner-site-agencias-mobile-2.png'
import ConteudoQuemSomosAG from '../../../componentes/quem-somos/agencias/ConteudoQuemSomosAG';
import dadosSiteComum from './dadosSiteComum';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/12.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/17.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoAgencias.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/18.webp';

const logoAgenciaRodape = <img className="agencia" src={logoAgencia} alt="Logo Agência" style={{width: '70px'}}/>;
const logoAgenciaCabecalho = <img className="logo-site agencia" src={logoAgencia} alt='Logo Agência'/>;

export const dadosNmTurismo = {
    ...dadosSiteComum,
    id: 'nmturismo',
    pastaEstilo: 'agencia',
    nome: 'NM Turismo',
    nomePrefixo: 'a NM Turismo',
    razaoSocial: '',
    cnpj: '05.376.934/0001-46',
    telefone: '',
    wtpp: '',
    instagram: 'https://instagram.com/',
    facebook: 'https://www.facebook.com/',
    youtube: 'youtube.com',
    email: 'nmturismosite@gmail.com',
    emailSuporte: 'pedidos.gtba@gmail.om',
    lkemailSuporte: 'mailto:pedidos.gtba@gmail.om',
    lktelefone: '',
    twitter: '',
    lkemail: 'mailto:nmturismosite@gmail.com',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561999677039&text=Olá. Preciso de atendimento.',
    logo: logoAgencia,
    logoCabecacho: logoAgenciaCabecalho,
    logoRodape: logoAgenciaRodape,
    icone: iconAgencia,
    quemSomos: ConteudoQuemSomosAG,
    videoInicio: bannerAgencia,
    imgMobile: bannerAgenciaMobile,
    imgMobile2: bannerAgenciaMobile2,
    idsTagManager: ['GTM-PHGZ336'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+desconto+vexado.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/5.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/5.mobile.webp'
};
