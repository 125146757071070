import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosPegasusTpTur = {
    ...dadosAgenciaComum,
    id: 'pegasustptur',
    nome: 'Pegasus TP Tur',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    email: 'suportevexado@gmail.com',
    idsTagManager: ['GTM-KB26DMW']
};
