import React, {Component} from 'react'
import 'moment/locale/pt-br'


import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {connect} from 'react-redux';
import {buscarCidadesPorNome} from '../../actions/cidadeActions';
import {
    buscarPassagens,
    buscarPassagensDistribusion,
    selecionarOrigem,
    selecionarDestino,
    limparDataRetorno,
    selecionarDataIda,
    selecionarDataRetorno,
} from '../../actions/vendasActions';
import {withRouter} from 'react-router-dom';
import { recuperarConfEmpresa } from '../../util/applicationContext';

export class DataProxima extends Component {
    async buscarPassagens(origem, destino, dataIda, dataRetorno){
        let url = `/passagem-de-onibus/${origem.nomeComUfNormalizado}/${destino.nomeComUfNormalizado}/${moment(dataIda).format('YYYY-MM-DD')}`;
        if (dataRetorno) {
            url = `${url}/${moment(dataRetorno).format('YYYY-MM-DD')}`
        }
        const { empresaSelecionada } = this.props;
        this.props.history.push(url);
        const idEmpresa = empresaSelecionada? empresaSelecionada.empresaId : null;
        await this.props.buscarPassagens(origem.id, destino.id, moment(dataIda), dataRetorno, idEmpresa);
        if (recuperarConfEmpresa().distribusion) {
            this.props.buscarPassagensDistribusion(origem.id, destino.id, moment(dataIda), dataRetorno, idEmpresa)
        }

    }
    buscarPassagensDataProxima(data, seIda) {
        const {origem, destino} = this.props.venda
        if (seIda && !this.props.dataRetornoSelecionada) {
            this.props.selecionarDataIda(moment(data));
            this.buscarPassagens(origem, destino, data);

        }
        if (seIda && this.props.dataRetornoSelecionada) {
            this.props.selecionarDataIda(moment(data))
            if (moment(this.props.dataRetornoSelecionada).isBefore(moment(data))
                || moment(data).format('DD/MM/YYYY').toString() === moment(this.props.dataRetornoSelecionada).format('DD/MM/YYYY').toString()) {
                this.props.selecionarDataRetorno(moment(data).add(1, 'days'))
                this.buscarPassagens(origem, destino,
                    data, data.add(1, 'days'));
            } else {
                this.buscarPassagens(origem, destino, data, this.props.dataRetornoSelecionada);
            }
        }
        if (!seIda) {
            this.props.selecionarDataRetorno(moment(data));
            if (moment(data).isBefore(this.props.dataIdaSelecionada)
                || moment(data).format('DD/MM/YYYY').toString() === moment(this.props.dataIdaSelecionada).format('DD/MM/YYYY').toString()) {
                this.props.selecionarDataIda(moment(data).subtract(1, 'days'));
                this.buscarPassagens(origem, destino, data.subtract(1, 'days'), data);
            } else {
                this.buscarPassagens(origem, destino, this.props.dataIdaSelecionada, data);
            }
        }
    }

    render() {
        return (
            <div className="justify-content-center card-group mb-2">
                <div className="card  col-md-2 align-content-center align-items-center"
                     style={{cursor: 'pointer', fontSize: 20}}
                     onClick={() => this.buscarPassagensDataProxima(this.props.data, this.props.seIda)}>
                    <div className='row  mb-2 mt-2'><strong><span className="card-title"
                                                                  style={{color: '#f26b35'}}>{moment(this.props.data).format('DD/MM/YYYY')}</span></strong>
                    </div>
                </div>
            </div>
        )
    }
}


export const mapStateToProps = state => (
    {
        // origem: { nome: 'Brasília', uf: 'DF' }, // state.origem,
        // destino: { nome: 'Rio de Janeiro', uf: 'RJ' }, // state.destino,
        venda: state.venda,
        dataIdaSelecionada: state.venda.dataIdaSelecionada,
        dataRetornoSelecionada: state.venda.dataRetornoSelecionada,
        passagens: state.passagens,
        empresaSelecionada: state.empresa.selecionada
    });


export default withRouter(connect(mapStateToProps,
    {
        buscarCidadesPorNome,
        selecionarOrigem,
        selecionarDestino,
        selecionarDataIda,
        selecionarDataRetorno,
        buscarPassagens,
        buscarPassagensDistribusion,
        limparDataRetorno,
    })(DataProxima))
