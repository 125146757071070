import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import BuscaPassagem from '../passagem/BuscaPassagem';

class CidadeRota extends Component {

  handleLinkCidadeRota(ogigemDestino) {
    window.location.href = `/passagem-de-onibus/${ogigemDestino}`
  }
  
  renderCidadeRota(agrupador) {
    const {abaCidades} = this.props
    return abaCidades === 1 ? (
      agrupador.origens.map(cidade =>
        <label onClick={() => this.handleLinkCidadeRota(cidade.split('@')[0])}
               className='col-6' key={cidade}
               style={{cursor: 'pointer'}}>
          <span className='card itinerario-dia rotas-cidades' style={{fontSize: 15}}>{cidade.split('@')[1]}</span>
        </label>
      )
    ) : (
      agrupador.destinos.map(cidade =>
        <label onClick={() => this.handleLinkCidadeRota(cidade.split('@')[0])}
               className='col-6'
               style={{cursor: 'pointer'}}
               key={cidade}>
          <span className='card itinerario-dia rotas-cidades' style={{fontSize: 15}}>{cidade.split('@')[1]}</span>
        </label>
      )
    )
  }

  renderTitulo(origens) {
    const cidade = origens[0].split('@')[1]
    return (
      <div className="row titulo-pag-rotas justify-content-center">
          <h1 className="title-pag-rota">Viagens de ônibus - <span className="cidade-selecionada">{cidade.split('->')[1]}</span></h1>
          <h3 className="subtitle-pag-rota">Acompanhe abaixo a listagem de passagens disponíveis para chegar ou sair da cidade</h3>
          <div className="divider"/>
      </div>
    )
  }
  
  renderAba(origens) {
    const cidade = origens[0].split('@')[1]
    const {abaCidades, handleAbaCidade} = this.props
    return (
      <div className="row rotas-onibus justify-content-center">
        <div className={`col-md-6 ${abaCidades === 1 ? 'active' : ''}`} onClick={() => handleAbaCidade(1)}>
          <span><p className="title-rota">Destino </p><p className="cidade-rota">{cidade.split('->')[1]}</p></span>
        </div>
        <div className={`col-md-6 ${abaCidades === 2 ? 'active' : ''}`} onClick={() => handleAbaCidade(2)}>
          <span><p className="title-rota">Origem </p><p className="cidade-rota">{cidade.split('->')[1]}</p></span>
        </div>
      </div>
    )
  }
  
  render() {
      const { agrupadorCidadeRota } = this.props;

      return (
          <div className='conteudo'>
              <div>
                  <BuscaPassagem/>
              </div>
              <div className="container">
                  <div className="col-12">
                      {this.renderTitulo(agrupadorCidadeRota.origens)}
                  </div>
              </div>
              <div className="container">
                  <div className='col-12'>
                      {this.renderAba(agrupadorCidadeRota.origens)}
                  </div>
              </div>

              <section className="cards-destino" id="cards-destino">
                  <div className="container">
                      <div className='col-12'>
                          {this.renderCidadeRota(agrupadorCidadeRota)}
                      </div>
                  </div>
              </section>
          </div>
      )
  }
}

export default withRouter(CidadeRota);