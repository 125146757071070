const princesaDoNorte = [

    {link: '/passagem-de-onibus/chavantes-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/itaporanga-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/itaporanga-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/florianopolis-sc/botucatu-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/fartura-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/taquarituba-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/itai-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/taquarituba-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/andradina-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/avare-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/treslagoas-ms'},
    {link: '/passagem-de-onibus/botucatu-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/botucatu-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/andradina-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/botucatu-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/avare-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/bauru-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/itaporanga-sp'},
    {link: '/passagem-de-onibus/jau-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/jacarezinho-pr/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/cerqueiracesar-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/treslagoas-ms/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/bauru-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/bauru-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/bauru-sp'},
    {link: '/passagem-de-onibus/abatia-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/botucatu-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/congonhinhas-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/carlopolis-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/piracicaba-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/campinas-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/americana-sp'},
    {link: '/passagem-de-onibus/itapema-sc/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/japira-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/florianopolis-sc/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/jaboti-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/jau-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/jau-sp/brasilia-df'},
    {link: '/passagem-de-onibus/uberaba-mg/bauru-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/jau-sp'},
    {link: '/passagem-de-onibus/jacarezinho-pr/bauru-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/itapema-sc'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/americana-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/campinas-sp/avare-sp'},
    {link: '/passagem-de-onibus/campinas-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/campinas-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/joinville-sc'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/americana-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/aguasdesantabarbara-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/sarutaia-sp'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/carlopolis-pr'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/taquarituba-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/sarutaia-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/brasilia-df/bauru-sp'},
    {link: '/passagem-de-onibus/taquarituba-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/tomazina-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/piraju-sp/bauru-sp'},
    {link: '/passagem-de-onibus/piraju-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/piraju-sp/campinas-sp'},
    {link: '/passagem-de-onibus/piraju-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/bauru-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/bauru-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/bauru-sp/piraju-sp'},
    {link: '/passagem-de-onibus/bauru-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/bauru-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/avare-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/tomazina-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/botucatu-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/congonhinhas-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/itaporanga-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/japira-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/piraju-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/cerqueiracesar-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/fartura-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/itaporanga-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/taquarituba-sp'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/jau-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/florianopolis-sc/araraquara-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/treslagoas-ms'},
    {link: '/passagem-de-onibus/uberlandia-mg/florianopolis-sc'},
    {link: '/passagem-de-onibus/jacarezinho-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/manduri-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/itajai-sc'},
    {link: '/passagem-de-onibus/saopaulo-sp/piraju-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/guapirama-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/abatia-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/carlopolis-pr'},
    {link: '/passagem-de-onibus/joinville-sc/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/itai-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/americana-sp'},
    {link: '/passagem-de-onibus/aguasdesantabarbara-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/avare-sp/campinas-sp'},
    {link: '/passagem-de-onibus/carlopolis-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/cerquilho-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/guapirama-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/cristalina-go/curitiba-pr'},
    {link: '/passagem-de-onibus/jacarezinho-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/campinas-sp/piraju-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/itaporanga-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/manduri-sp'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/brasilia-df/jau-sp'},
    {link: '/passagem-de-onibus/treslagoas-ms/florianopolis-sc'},
    {link: '/passagem-de-onibus/curitiba-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/bauru-sp/brasilia-df'},
    {link: '/passagem-de-onibus/curitiba-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/cristalina-go'},
    {link: '/passagem-de-onibus/curitiba-pr/jaboti-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/avare-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/campinas-sp'},
    {link: '/passagem-de-onibus/itajai-sc/araraquara-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/bauru-sp'},
    {link: '/passagem-de-onibus/joinville-sc/bauru-sp'},
    {link: '/passagem-de-onibus/abatia-pr/castro-pr'},
    {link: '/passagem-de-onibus/abatia-pr/ibaiti-pr'},
    {link: '/passagem-de-onibus/abatia-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/abatia-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/abatia-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/abatia-pr/bandeirantes,pr-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/ventania-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/ventania-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/abatia-pr/congonhinhas-pr'},
    {link: '/passagem-de-onibus/abatia-pr/santaamelia-pr'},
    {link: '/passagem-de-onibus/abatia-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/abatia-pr/ventania-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/joinville-sc'},
    {link: '/passagem-de-onibus/arapoti-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/itajai-sc'},
    {link: '/passagem-de-onibus/arapoti-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/aguasdesantabarbara-sp/avare-sp'},
    {link: '/passagem-de-onibus/aguasdesantabarbara-sp/cerqueiracesar-sp'},
    {link: '/passagem-de-onibus/aguasdesantabarbara-sp/fartura-sp'},
    {link: '/passagem-de-onibus/aguasdesantabarbara-sp/manduri-sp'},
    {link: '/passagem-de-onibus/aguasdesantabarbara-sp/sarutaia-sp'},
    {link: '/passagem-de-onibus/aguasdesantabarbara-sp/piraju-sp'},
    {link: '/passagem-de-onibus/avare-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/avare-sp/aguasdesantabarbara-sp'},
    {link: '/passagem-de-onibus/avare-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/avare-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/avare-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/avare-sp/conselheiromairinck-pr'},
    {link: '/passagem-de-onibus/avare-sp/cerqueiracesar-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/ventania-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/castro-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/itaporanga-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/itapema-sc'},
    {link: '/passagem-de-onibus/arapoti-pr/florianopolis-sc'},
    {link: '/passagem-de-onibus/arapoti-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/jau-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/telemacoborba-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/ventania-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/angatuba-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/angatuba-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/angatuba-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/angatuba-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/angatuba-sp/piraju-sp'},
    {link: '/passagem-de-onibus/angatuba-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/angatuba-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/avare-sp/carlopolis-pr'},
    {link: '/passagem-de-onibus/avare-sp/fartura-sp'},
    {link: '/passagem-de-onibus/avare-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/avare-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/avare-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/avare-sp/guapirama-pr'},
    {link: '/passagem-de-onibus/avare-sp/castro-pr'},
    {link: '/passagem-de-onibus/avare-sp/ibaiti-pr'},
    {link: '/passagem-de-onibus/avare-sp/itapema-sc'},
    {link: '/passagem-de-onibus/avare-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/avare-sp/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/avare-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/avare-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/avare-sp/manduri-sp'},
    {link: '/passagem-de-onibus/avare-sp/americana-sp'},
    {link: '/passagem-de-onibus/avare-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/avare-sp/quatigua-pr'},
    {link: '/passagem-de-onibus/avare-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/avare-sp/sarutaia-sp'},
    {link: '/passagem-de-onibus/avare-sp/tiete-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/arapoti-pr/curiuva-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/arapoti-pr/marilia-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/ventania-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/curiuva-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/telemacoborba-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/avare-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/arapoti-pr/botucatu-sp'},
    {link: '/passagem-de-onibus/arapoti-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/botucatu-sp/castro-pr'},
    {link: '/passagem-de-onibus/botucatu-sp/itapema-sc'},
    {link: '/passagem-de-onibus/botucatu-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/botucatu-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/botucatu-sp/joinville-sc'},
    {link: '/passagem-de-onibus/botucatu-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/botucatu-sp/itajai-sc'},
    {link: '/passagem-de-onibus/cerquilho-sp/avare-sp'},
    {link: '/passagem-de-onibus/cerquilho-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/cerquilho-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/piraju-sp'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/santoandre-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/botucatu-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/botucatu-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/marilia-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/joinville-sc'},
    {link: '/passagem-de-onibus/cerquilho-sp/campinas-sp'},
    {link: '/passagem-de-onibus/cerquilho-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/cerquilho-sp/americana-sp'},
    {link: '/passagem-de-onibus/cerquilho-sp/piraju-sp'},
    {link: '/passagem-de-onibus/cerquilho-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/angatuba-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/avare-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/santoandre-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/avare-sp/piraju-sp'},
    {link: '/passagem-de-onibus/avare-sp/joinville-sc'},
    {link: '/passagem-de-onibus/avare-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/avare-sp/itajai-sc'},
    {link: '/passagem-de-onibus/avare-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/castro-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/tomazina-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/angatuba-sp'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/avare-sp'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/bernardinodecampos-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/lins-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/itapema-sc'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/florianopolis-sc'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/jau-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/bauru-sp'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/itajai-sc'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/castro-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/ibaiti-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/jaboti-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/japira-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/pinhalao-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/jaguariaiva-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/conselheiromairinck-pr/itai-sp'},
    {link: '/passagem-de-onibus/congonhinhas-pr/abatia-pr'},
    {link: '/passagem-de-onibus/congonhinhas-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/congonhinhas-pr/ventania-pr'},
    {link: '/passagem-de-onibus/congonhinhas-pr/castro-pr'},
    {link: '/passagem-de-onibus/congonhinhas-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/congonhinhas-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/congonhinhas-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/limeira-sp/itapema-sc'},
    {link: '/passagem-de-onibus/limeira-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/carlopolis-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/carlopolis-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/carlopolis-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/carlopolis-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/carlopolis-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/carlopolis-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/carlopolis-pr/itai-sp'},
    {link: '/passagem-de-onibus/santaamelia-pr/abatia-pr'},
    {link: '/passagem-de-onibus/santaamelia-pr/bandeirantes,pr-pr'},
    {link: '/passagem-de-onibus/espiritosantodoturvo-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/piraju-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/conselheiromairinck-pr/avare-sp'},
    {link: '/passagem-de-onibus/conselheiromairinck-pr/fartura-sp'},
    {link: '/passagem-de-onibus/conselheiromairinck-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/conselheiromairinck-pr/japira-pr'},
    {link: '/passagem-de-onibus/conselheiromairinck-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/conselheiromairinck-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/espiritosantodoturvo-sp/piraju-sp'},
    {link: '/passagem-de-onibus/espiritosantodoturvo-sp/bauru-sp'},
    {link: '/passagem-de-onibus/espiritosantodoturvo-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/fartura-sp/aguasdesantabarbara-sp'},
    {link: '/passagem-de-onibus/fartura-sp/avare-sp'},
    {link: '/passagem-de-onibus/fartura-sp/conselheiromairinck-pr'},
    {link: '/passagem-de-onibus/fartura-sp/cerqueiracesar-sp'},
    {link: '/passagem-de-onibus/fartura-sp/carlopolis-pr'},
    {link: '/passagem-de-onibus/fartura-sp/guapirama-pr'},
    {link: '/passagem-de-onibus/fartura-sp/ibaiti-pr'},
    {link: '/passagem-de-onibus/limeira-sp/itajai-sc'},
    {link: '/passagem-de-onibus/cerqueiracesar-sp/aguasdesantabarbara-sp'},
    {link: '/passagem-de-onibus/cerqueiracesar-sp/avare-sp'},
    {link: '/passagem-de-onibus/cerqueiracesar-sp/fartura-sp'},
    {link: '/passagem-de-onibus/cerqueiracesar-sp/manduri-sp'},
    {link: '/passagem-de-onibus/cerqueiracesar-sp/sarutaia-sp'},
    {link: '/passagem-de-onibus/cerqueiracesar-sp/piraju-sp'},
    {link: '/passagem-de-onibus/carlopolis-pr/avare-sp'},
    {link: '/passagem-de-onibus/carlopolis-pr/fartura-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/jau-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/pontagrossa-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/pinhalao-pr'},
    {link: '/passagem-de-onibus/fartura-sp/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/fartura-sp/manduri-sp'},
    {link: '/passagem-de-onibus/fartura-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/fartura-sp/quatigua-pr'},
    {link: '/passagem-de-onibus/fartura-sp/sarutaia-sp'},
    {link: '/passagem-de-onibus/fartura-sp/piraju-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/abatia-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/avare-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/botucatu-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/congonhinhas-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/carlopolis-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/itaporanga-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/jaboti-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/japira-pr'},
    {link: '/passagem-de-onibus/chavantes-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/osasco-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/campinas-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/americana-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/chavantes-sp/tiete-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/sorocaba-sp/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/sorocaba-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/santanadoitarare-pr'},
    {link: '/passagem-de-onibus/sorocaba-sp/piraju-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/sorocaba-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/dracena-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/brasilia-df'},
    {link: '/passagem-de-onibus/pontagrossa-pr/tomazina-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/tiete-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/piraju-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/avare-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/araguari-mg/bauru-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/avare-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/santoandre-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/espiritosantodoturvo-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/osasco-sp'},
    {link: '/passagem-de-onibus/santacruzdoriopardo-sp/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/ventania-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/ventania-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/ventania-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/castro-pr/abatia-pr'},
    {link: '/passagem-de-onibus/castro-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/castro-pr/avare-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/angatuba-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/conselheiromairinck-pr'},
    {link: '/passagem-de-onibus/sorocaba-sp/carlopolis-pr'},
    {link: '/passagem-de-onibus/sorocaba-sp/guapirama-pr'},
    {link: '/passagem-de-onibus/sorocaba-sp/itaporanga-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/jaboti-pr'},
    {link: '/passagem-de-onibus/piracicaba-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/piraju-sp'},
    {link: '/passagem-de-onibus/piracicaba-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/guapirama-pr/avare-sp'},
    {link: '/passagem-de-onibus/guapirama-pr/fartura-sp'},
    {link: '/passagem-de-onibus/guapirama-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/guapirama-pr/japira-pr'},
    {link: '/passagem-de-onibus/guapirama-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/guapirama-pr/itai-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/itai-sp'},
    {link: '/passagem-de-onibus/sorocaba-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/sorocaba-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/ventania-pr/abatia-pr'},
    {link: '/passagem-de-onibus/ventania-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/ventania-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/ventania-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/ventania-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/ventania-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/ventania-pr/congonhinhas-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/adamantina-sp'},
    {link: '/passagem-de-onibus/pontagrossa-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/pontagrossa-pr/bauru-sp'},
    {link: '/passagem-de-onibus/ibaiti-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/ibaiti-pr/itai-sp'},
    {link: '/passagem-de-onibus/ibaiti-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/lins-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/lins-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/lins-sp/joinville-sc'},
    {link: '/passagem-de-onibus/lins-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/itaporanga-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/itaporanga-sp/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/itaporanga-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/itaporanga-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/itaporanga-sp/santanadoitarare-pr'},
    {link: '/passagem-de-onibus/itaporanga-sp/taquarituba-sp'},
    {link: '/passagem-de-onibus/itaporanga-sp/joinville-sc'},
    {link: '/passagem-de-onibus/itaporanga-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/castro-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/castro-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/castro-pr/botucatu-sp'},
    {link: '/passagem-de-onibus/castro-pr/congonhinhas-pr'},
    {link: '/passagem-de-onibus/castro-pr/itaporanga-sp'},
    {link: '/passagem-de-onibus/castro-pr/jaboti-pr'},
    {link: '/passagem-de-onibus/castro-pr/japira-pr'},
    {link: '/passagem-de-onibus/castro-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/castro-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/itaporanga-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/itaporanga-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/itaporanga-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/itaporanga-sp/castro-pr'},
    {link: '/passagem-de-onibus/itaporanga-sp/itapema-sc'},
    {link: '/passagem-de-onibus/itaporanga-sp/jaboti-pr'},
    {link: '/passagem-de-onibus/itaporanga-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/castro-pr/jau-sp'},
    {link: '/passagem-de-onibus/castro-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/castro-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/castro-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/castro-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/castro-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/castro-pr/pinhalao-pr'},
    {link: '/passagem-de-onibus/castro-pr/marilia-sp'},
    {link: '/passagem-de-onibus/castro-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/itaporanga-sp/itai-sp'},
    {link: '/passagem-de-onibus/itaporanga-sp/itajai-sc'},
    {link: '/passagem-de-onibus/ibaiti-pr/abatia-pr'},
    {link: '/passagem-de-onibus/ibaiti-pr/avare-sp'},
    {link: '/passagem-de-onibus/ibaiti-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/ibaiti-pr/fartura-sp'},
    {link: '/passagem-de-onibus/ibaiti-pr/japira-pr'},
    {link: '/passagem-de-onibus/ibaiti-pr/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/ibaiti-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/ibaiti-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/castro-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/castro-pr/tomazina-pr'},
    {link: '/passagem-de-onibus/castro-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/castro-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/castro-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/castro-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/osasco-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/osasco-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/osasco-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/florianopolis-sc/piraidosul-pr'},
    {link: '/passagem-de-onibus/florianopolis-sc/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/osvaldocruz-sp/itapema-sc'},
    {link: '/passagem-de-onibus/osvaldocruz-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/osvaldocruz-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/osvaldocruz-sp/joinville-sc'},
    {link: '/passagem-de-onibus/osvaldocruz-sp/piraidosul-pr'},
    {link: '/passagem-de-onibus/japira-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/japira-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/japira-pr/itai-sp'},
    {link: '/passagem-de-onibus/japira-pr/conselheiromairinck-pr'},
    {link: '/passagem-de-onibus/japira-pr/guapirama-pr'},
    {link: '/passagem-de-onibus/japira-pr/ibaiti-pr'},
    {link: '/passagem-de-onibus/japira-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/florianopolis-sc/osvaldocruz-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/jacarezinho-pr'},
    {link: '/passagem-de-onibus/florianopolis-sc/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/americana-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/dracena-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/tupa-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/taquarituba-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/andradina-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/adamantina-sp'},
    {link: '/passagem-de-onibus/itapema-sc/taquarituba-sp'},
    {link: '/passagem-de-onibus/itapema-sc/andradina-sp'},
    {link: '/passagem-de-onibus/itapema-sc/adamantina-sp'},
    {link: '/passagem-de-onibus/itapema-sc/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/itapema-sc/piraidosul-pr'},
    {link: '/passagem-de-onibus/itapema-sc/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/florianopolis-sc/arapoti-pr'},
    {link: '/passagem-de-onibus/florianopolis-sc/avare-sp'},
    {link: '/passagem-de-onibus/florianopolis-sc/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/jaboti-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/jaboti-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/jaboti-pr/itai-sp'},
    {link: '/passagem-de-onibus/japira-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/japira-pr/castro-pr'},
    {link: '/passagem-de-onibus/japira-pr/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/itapema-sc/jau-sp'},
    {link: '/passagem-de-onibus/itapema-sc/uberaba-mg'},
    {link: '/passagem-de-onibus/itapema-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/itapema-sc/jacarezinho-pr'},
    {link: '/passagem-de-onibus/itapema-sc/araraquara-sp'},
    {link: '/passagem-de-onibus/itapema-sc/americana-sp'},
    {link: '/passagem-de-onibus/itapema-sc/dracena-sp'},
    {link: '/passagem-de-onibus/itapema-sc/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/itapema-sc/treslagoas-ms'},
    {link: '/passagem-de-onibus/itapema-sc/tupa-sp'},
    {link: '/passagem-de-onibus/jaboti-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/jaboti-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/jaboti-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/jaboti-pr/castro-pr'},
    {link: '/passagem-de-onibus/jaboti-pr/itaporanga-sp'},
    {link: '/passagem-de-onibus/jaboti-pr/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/jaboti-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/jaboti-pr/itapetininga-sp'},
    {link: '/passagem-de-onibus/itapema-sc/arapoti-pr'},
    {link: '/passagem-de-onibus/itapema-sc/avare-sp'},
    {link: '/passagem-de-onibus/itapema-sc/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/itapema-sc/botucatu-sp'},
    {link: '/passagem-de-onibus/itapema-sc/limeira-sp'},
    {link: '/passagem-de-onibus/itapema-sc/itaporanga-sp'},
    {link: '/passagem-de-onibus/itapema-sc/osvaldocruz-sp'},
    {link: '/passagem-de-onibus/osasco-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/botucatu-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/pontagrossa-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/castro-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/itaporanga-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/itapema-sc'},
    {link: '/passagem-de-onibus/uberaba-mg/jau-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/jacarezinho-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/uberaba-mg/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/taquarituba-sp'},
    {link: '/passagem-de-onibus/assis-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/assis-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/jau-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/jau-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/jau-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/jau-sp/castro-pr'},
    {link: '/passagem-de-onibus/jau-sp/itapema-sc'},
    {link: '/passagem-de-onibus/jau-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/jau-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/jau-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/uberaba-mg/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/curitiba-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/ourinhos-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/itajai-sc'},
    {link: '/passagem-de-onibus/uberaba-mg/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/saobernardodocampo-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/arapoti-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/itai-sp'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/avare-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/castro-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/itaporanga-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/itapema-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/jacarezinho-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/barrabonita-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/avare-sp'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/carlopolis-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/castro-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/marilia-sp'},
    {link: '/passagem-de-onibus/joaquimtavora-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/jau-sp/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/jau-sp/joinville-sc'},
    {link: '/passagem-de-onibus/jau-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/jau-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/jau-sp/itajai-sc'},
    {link: '/passagem-de-onibus/jau-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/arapoti-pr'},
    {link: '/passagem-de-onibus/uberaba-mg/avare-sp'},
    {link: '/passagem-de-onibus/uberaba-mg/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/joinville-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/bauru-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/taquarituba-sp'},
    {link: '/passagem-de-onibus/manduri-sp/piraju-sp'},
    {link: '/passagem-de-onibus/ibipora-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/ibipora-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/castro-pr'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/cornelioprocopio-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/manduri-sp/aguasdesantabarbara-sp'},
    {link: '/passagem-de-onibus/manduri-sp/avare-sp'},
    {link: '/passagem-de-onibus/manduri-sp/cerqueiracesar-sp'},
    {link: '/passagem-de-onibus/manduri-sp/fartura-sp'},
    {link: '/passagem-de-onibus/manduri-sp/sarutaia-sp'},
    {link: '/passagem-de-onibus/jacarezinho-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/curiuva-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/curiuva-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/curiuva-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/jataizinho-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/jataizinho-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/araraquara-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/araraquara-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/araraquara-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/araraquara-sp/castro-pr'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/japira-pr'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/pinhalao-pr'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/santanadoitarare-pr'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/tomazina-pr'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/taquarituba-sp'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/itai-sp'},
    {link: '/passagem-de-onibus/jacarezinho-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/araraquara-sp/itapema-sc'},
    {link: '/passagem-de-onibus/araraquara-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/araraquara-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/araraquara-sp/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/araraquara-sp/joinville-sc'},
    {link: '/passagem-de-onibus/araraquara-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/araraquara-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/campinas-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/campinas-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/campinas-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/campinas-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/abatia-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/congonhinhas-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/ventania-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/castro-pr'},
    {link: '/passagem-de-onibus/novafatima-pr-pr/ibaiti-pr'},
    {link: '/passagem-de-onibus/jacarezinho-pr/ibipora-pr'},
    {link: '/passagem-de-onibus/jacarezinho-pr/cornelioprocopio-pr'},
    {link: '/passagem-de-onibus/jacarezinho-pr/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/jacarezinho-pr/marilia-sp'},
    {link: '/passagem-de-onibus/jacarezinho-pr/londrina-pr'},
    {link: '/passagem-de-onibus/jacarezinho-pr/brasilia-df'},
    {link: '/passagem-de-onibus/jacarezinho-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/jacarezinho-pr/joinville-sc'},
    {link: '/passagem-de-onibus/jacarezinho-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/jacarezinho-pr/itajai-sc'},
    {link: '/passagem-de-onibus/jacarezinho-pr/lins-sp'},
    {link: '/passagem-de-onibus/jacarezinho-pr/itapema-sc'},
    {link: '/passagem-de-onibus/jacarezinho-pr/florianopolis-sc'},
    {link: '/passagem-de-onibus/jacarezinho-pr/jau-sp'},
    {link: '/passagem-de-onibus/jacarezinho-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/jacarezinho-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/jacarezinho-pr/jataizinho-pr'},
    {link: '/passagem-de-onibus/jacarezinho-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/jacarezinho-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/curitiba-pr'},
    {link: '/passagem-de-onibus/uberlandia-mg/ourinhos-sp'},
    {link: '/passagem-de-onibus/uberlandia-mg/itajai-sc'},
    {link: '/passagem-de-onibus/uberlandia-mg/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/itaporanga-sp'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/ibaiti-pr'},
    {link: '/passagem-de-onibus/coronelmacedo-sp/jaboti-pr'},
    {link: '/passagem-de-onibus/cornelioprocopio-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/cornelioprocopio-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/cornelioprocopio-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/pompeia-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/pompeia-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/arapoti-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/avare-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/botucatu-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/limeira-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/sarutaia-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/tiete-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/piraju-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/americana-sp/avare-sp'},
    {link: '/passagem-de-onibus/americana-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/americana-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/americana-sp/itapema-sc'},
    {link: '/passagem-de-onibus/americana-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/americana-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/pardinho-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/fartura-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/campinas-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/americana-sp'},
    {link: '/passagem-de-onibus/pardinho-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/marilia-sp/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/marilia-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/marilia-sp/quatigua-pr'},
    {link: '/passagem-de-onibus/marilia-sp/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/marilia-sp/joinville-sc'},
    {link: '/passagem-de-onibus/marilia-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/marilia-sp/piraidosul-pr'},
    {link: '/passagem-de-onibus/marilia-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/pinhalao-pr/castro-pr'},
    {link: '/passagem-de-onibus/pinhalao-pr/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/pinhalao-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/pinhalao-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/pinhalao-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/pinhalao-pr/itai-sp'},
    {link: '/passagem-de-onibus/cornelioprocopio-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/americana-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/americana-sp/tiete-sp'},
    {link: '/passagem-de-onibus/americana-sp/piraju-sp'},
    {link: '/passagem-de-onibus/americana-sp/joinville-sc'},
    {link: '/passagem-de-onibus/americana-sp/itajai-sc'},
    {link: '/passagem-de-onibus/americana-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/pinhalao-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/andradina-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/adamantina-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/piraidosul-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/marilia-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/marilia-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/marilia-sp/castro-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/itaporanga-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/osvaldocruz-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/jau-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/uberaba-mg'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/jacarezinho-pr'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/araraquara-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/americana-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/dracena-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/treslagoas-ms'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/tupa-sp'},
    {link: '/passagem-de-onibus/balneariocamboriu-sc/taquarituba-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/santanadoitarare-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/barrabonita-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/londrina-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/londrina-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/itapetininga-sp/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/santanadoitarare-pr'},
    {link: '/passagem-de-onibus/itapetininga-sp/taquarituba-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/taquarituba-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/piraju-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/itapetininga-sp/itai-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/quatigua-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/dracena-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/dracena-sp/itapema-sc'},
    {link: '/passagem-de-onibus/dracena-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/dracena-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/dracena-sp/joinville-sc'},
    {link: '/passagem-de-onibus/dracena-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/dracena-sp/piraidosul-pr'},
    {link: '/passagem-de-onibus/dracena-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/avare-sp'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/curitiba-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/itapetininga-sp/angatuba-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/itaporanga-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/jaboti-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/marilia-sp'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/bauru-sp'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/castro-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/siqueiracampos-pr/jau-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/conselheiromairinck-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/carlopolis-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/jaboti-pr'},
    {link: '/passagem-de-onibus/saopaulo-sp/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/saopaulo-sp/quatigua-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/avare-sp'},
    {link: '/passagem-de-onibus/quatigua-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/carlopolis-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/fartura-sp'},
    {link: '/passagem-de-onibus/quatigua-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/castro-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/marilia-sp'},
    {link: '/passagem-de-onibus/quatigua-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/quatigua-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/quatigua-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/quatigua-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/quatigua-pr/itai-sp'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/santanadoitarare-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/santanadoitarare-pr/itaporanga-sp'},
    {link: '/passagem-de-onibus/santanadoitarare-pr/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/santanadoitarare-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/santanadoitarare-pr/itapetininga-sp'},
    {link: '/passagem-de-onibus/santanadoitarare-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/santanadoitarare-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/sarutaia-sp/aguasdesantabarbara-sp'},
    {link: '/passagem-de-onibus/itapetininga-sp/saopaulo-sp'},
    {link: '/passagem-de-onibus/brasilia-df/pontagrossa-pr'},
    {link: '/passagem-de-onibus/brasilia-df/jacarezinho-pr'},
    {link: '/passagem-de-onibus/brasilia-df/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/itapema-sc'},
    {link: '/passagem-de-onibus/aracatuba,sp-sp/joinville-sc'},
    {link: '/passagem-de-onibus/tomazina-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/tomazina-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/tomazina-pr/itai-sp'},
    {link: '/passagem-de-onibus/tupa-sp/itapema-sc'},
    {link: '/passagem-de-onibus/tupa-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/tupa-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/tupa-sp/joinville-sc'},
    {link: '/passagem-de-onibus/sarutaia-sp/avare-sp'},
    {link: '/passagem-de-onibus/sarutaia-sp/cerqueiracesar-sp'},
    {link: '/passagem-de-onibus/sarutaia-sp/fartura-sp'},
    {link: '/passagem-de-onibus/sarutaia-sp/manduri-sp'},
    {link: '/passagem-de-onibus/sarutaia-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/tiete-sp/avare-sp'},
    {link: '/passagem-de-onibus/tiete-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/tiete-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/tiete-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/tiete-sp/americana-sp'},
    {link: '/passagem-de-onibus/tupa-sp/piraidosul-pr'},
    {link: '/passagem-de-onibus/tupa-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/japira-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/taquarituba-sp/santanadoitarare-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/castro-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/itaporanga-sp'},
    {link: '/passagem-de-onibus/taquarituba-sp/ibaiti-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/itapema-sc'},
    {link: '/passagem-de-onibus/taquarituba-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/taquarituba-sp/jaboti-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/japira-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/taquarituba-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/taquarituba-sp/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/taquarituba-sp/pinhalao-pr'},
    {link: '/passagem-de-onibus/tomazina-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/tomazina-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/tomazina-pr/castro-pr'},
    {link: '/passagem-de-onibus/tomazina-pr/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/tiete-sp/piraju-sp'},
    {link: '/passagem-de-onibus/tiete-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/tiete-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/treslagoas-ms/itapema-sc'},
    {link: '/passagem-de-onibus/treslagoas-ms/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/treslagoas-ms/joinville-sc'},
    {link: '/passagem-de-onibus/treslagoas-ms/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/adamantina-sp/itapema-sc'},
    {link: '/passagem-de-onibus/adamantina-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/adamantina-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/adamantina-sp/joinville-sc'},
    {link: '/passagem-de-onibus/adamantina-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/adamantina-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/joinville-sc/arapoti-pr'},
    {link: '/passagem-de-onibus/joinville-sc/avare-sp'},
    {link: '/passagem-de-onibus/joinville-sc/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/joinville-sc/botucatu-sp'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/castro-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/ibaiti-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/cornelioprocopio-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/piraju-sp/angatuba-sp'},
    {link: '/passagem-de-onibus/piraju-sp/aguasdesantabarbara-sp'},
    {link: '/passagem-de-onibus/piraju-sp/avare-sp'},
    {link: '/passagem-de-onibus/andradina-sp/itapema-sc'},
    {link: '/passagem-de-onibus/andradina-sp/florianopolis-sc'},
    {link: '/passagem-de-onibus/andradina-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/andradina-sp/joinville-sc'},
    {link: '/passagem-de-onibus/andradina-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/abatia-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/congonhinhas-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/ribeiraodopinhal-pr/ventania-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/taquarituba-sp/quatigua-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/taquarituba-sp/santanadoitarare-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/tomazina-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/joinville-sc'},
    {link: '/passagem-de-onibus/taquarituba-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/taquarituba-sp/itai-sp'},
    {link: '/passagem-de-onibus/taquarituba-sp/itajai-sc'},
    {link: '/passagem-de-onibus/piraju-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/piraju-sp/americana-sp'},
    {link: '/passagem-de-onibus/piraju-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/piraju-sp/tiete-sp'},
    {link: '/passagem-de-onibus/piraju-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/saocaetanodosul-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/adamantina-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/joinville-sc/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/ranchoalegre,pr-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/ranchoalegre,pr-pr'},
    {link: '/passagem-de-onibus/joinville-sc/dracena-sp'},
    {link: '/passagem-de-onibus/joinville-sc/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/joinville-sc/treslagoas-ms'},
    {link: '/passagem-de-onibus/joinville-sc/tupa-sp'},
    {link: '/passagem-de-onibus/joinville-sc/taquarituba-sp'},
    {link: '/passagem-de-onibus/joinville-sc/andradina-sp'},
    {link: '/passagem-de-onibus/joinville-sc/adamantina-sp'},
    {link: '/passagem-de-onibus/joinville-sc/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/joinville-sc/piraidosul-pr'},
    {link: '/passagem-de-onibus/joinville-sc/ourinhos-sp'},
    {link: '/passagem-de-onibus/joinville-sc/itaporanga-sp'},
    {link: '/passagem-de-onibus/joinville-sc/lins-sp'},
    {link: '/passagem-de-onibus/joinville-sc/osvaldocruz-sp'},
    {link: '/passagem-de-onibus/joinville-sc/jau-sp'},
    {link: '/passagem-de-onibus/joinville-sc/uberaba-mg'},
    {link: '/passagem-de-onibus/joinville-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/joinville-sc/jacarezinho-pr'},
    {link: '/passagem-de-onibus/joinville-sc/araraquara-sp'},
    {link: '/passagem-de-onibus/joinville-sc/americana-sp'},
    {link: '/passagem-de-onibus/joinville-sc/marilia-sp'},
    {link: '/passagem-de-onibus/piraju-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/piraju-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/piraju-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/piraju-sp/cerqueiracesar-sp'},
    {link: '/passagem-de-onibus/piraju-sp/espiritosantodoturvo-sp'},
    {link: '/passagem-de-onibus/piraju-sp/fartura-sp'},
    {link: '/passagem-de-onibus/piraju-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/piraju-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/piraju-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/piraju-sp/manduri-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/curitiba-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/curitiba-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/pinhalao-pr'},
    {link: '/passagem-de-onibus/curitiba-pr/pompeia-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/dracena-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/telemacoborba-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/avare-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/itai-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/itajai-sc'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/bauru-sp/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/bauru-sp/espiritosantodoturvo-sp'},
    {link: '/passagem-de-onibus/bauru-sp/pontagrossa-pr'},
    {link: '/passagem-de-onibus/bauru-sp/araguari-mg'},
    {link: '/passagem-de-onibus/bauru-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/bauru-sp/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/marilia-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/itapetininga-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/taquarituba-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/joinville-sc'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/bauru-sp/curitiba-pr'},
    {link: '/passagem-de-onibus/bauru-sp/itajai-sc'},
    {link: '/passagem-de-onibus/bauru-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/jau-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/adamantina-sp'},
    {link: '/passagem-de-onibus/curitiba-pr/bauru-sp'},
    {link: '/passagem-de-onibus/piraidosul-pr/abatia-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/jau-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/curiuva-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/ventania-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/castro-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/itapema-sc'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/florianopolis-sc'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/assis-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/botucatu-sp'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/carlopolis-pr'},
    {link: '/passagem-de-onibus/wenceslaubraz-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/joinville-sc'},
    {link: '/passagem-de-onibus/piraidosul-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/ourinhos-sp'},
    {link: '/passagem-de-onibus/piraidosul-pr/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/angatuba-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/arapoti-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/santoandre-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/itajai-sc/uberlandia-mg'},
    {link: '/passagem-de-onibus/itajai-sc/jacarezinho-pr'},
    {link: '/passagem-de-onibus/itajai-sc/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/itajai-sc/americana-sp'},
    {link: '/passagem-de-onibus/itajai-sc/taquarituba-sp'},
    {link: '/passagem-de-onibus/itajai-sc/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/itajai-sc/bauru-sp'},
    {link: '/passagem-de-onibus/itajai-sc/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/arapoti-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/pinhalao-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/piraidosul-pr/marilia-sp'},
    {link: '/passagem-de-onibus/piraidosul-pr/quatigua-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/dracena-sp'},
    {link: '/passagem-de-onibus/piraidosul-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/tomazina-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/tupa-sp'},
    {link: '/passagem-de-onibus/piraidosul-pr/congonhinhas-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/itapema-sc'},
    {link: '/passagem-de-onibus/piraidosul-pr/florianopolis-sc'},
    {link: '/passagem-de-onibus/piraidosul-pr/osvaldocruz-sp'},
    {link: '/passagem-de-onibus/piraidosul-pr/jaboti-pr'},
    {link: '/passagem-de-onibus/piraidosul-pr/japira-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/tiete-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/joinville-sc'},
    {link: '/passagem-de-onibus/ourinhos-sp/piraidosul-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/ipassu-sp'},
    {link: '/passagem-de-onibus/itai-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/itai-sp/itaporanga-sp'},
    {link: '/passagem-de-onibus/itai-sp/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/itajai-sc/arapoti-pr'},
    {link: '/passagem-de-onibus/itajai-sc/avare-sp'},
    {link: '/passagem-de-onibus/itajai-sc/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/itajai-sc/botucatu-sp'},
    {link: '/passagem-de-onibus/itajai-sc/limeira-sp'},
    {link: '/passagem-de-onibus/itajai-sc/itaporanga-sp'},
    {link: '/passagem-de-onibus/itajai-sc/jau-sp'},
    {link: '/passagem-de-onibus/itajai-sc/uberaba-mg'},
    {link: '/passagem-de-onibus/ourinhos-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/castro-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/osasco-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/joaquimtavora-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/uberaba-mg'},
    {link: '/passagem-de-onibus/ourinhos-sp/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/uberlandia-mg'},
    {link: '/passagem-de-onibus/ourinhos-sp/jacarezinho-pr'},
    {link: '/passagem-de-onibus/ourinhos-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/ourinhos-sp/quatigua-pr'},
    {link: '/passagem-de-onibus/itai-sp/ibaiti-pr'},
    {link: '/passagem-de-onibus/itai-sp/jaboti-pr'},
    {link: '/passagem-de-onibus/itai-sp/japira-pr'},
    {link: '/passagem-de-onibus/itai-sp/coronelmacedo-sp'},
    {link: '/passagem-de-onibus/itai-sp/pinhalao-pr'},
    {link: '/passagem-de-onibus/itai-sp/quatigua-pr'},
    {link: '/passagem-de-onibus/itai-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/itai-sp/tomazina-pr'},
    {link: '/passagem-de-onibus/itai-sp/taquarituba-sp'},
    {link: '/passagem-de-onibus/itai-sp/taquarituba-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/jaguariaiva-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/pontagrossa-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/sorocaba-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/ribeiraodopinhal-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/adamantina-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/joinville-sc'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/wenceslaubraz-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/piraidosul-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/itajai-sc'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/santos-sp'},
    {link: '/passagem-de-onibus/santos-sp/santoantoniodaplatina-pr'},
    {link: '/passagem-de-onibus/ipassu-sp/angatuba-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/dracena-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/saopaulo-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/londrina-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/siqueiracampos-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/brasilia-df'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/aracatuba,sp-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/treslagoas-ms'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/tupa-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/andradina-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/uberlandia-mg'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/jacarezinho-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/jataizinho-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/araraquara-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/novafatima-pr-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/ibipora-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/cornelioprocopio-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/balneariocamboriu-sc'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/marilia-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/ibaiti-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/lins-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/itapema-sc'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/florianopolis-sc'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/osvaldocruz-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/japira-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/assis-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/jau-sp'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/uberaba-mg'},
    {link: '/passagem-de-onibus/ipassu-sp/osasco-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/saobernardodocampo-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/campinas-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/pardinho-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/americana-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/itapetininga-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/tiete-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/piraju-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/saocaetanodosul-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/bauru-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/avare-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/bernardinodecampos-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/santoandre-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/cerquilho-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/chavantes-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/espiritosantodoturvo-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/santacruzdoriopardo-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/piracicaba-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/sorocaba-sp'},
    {link: '/passagem-de-onibus/ipassu-sp/ourinhos-sp'},
    {link: '/passagem-de-onibus/bandeirantes,pr-pr/abatia-pr'},
    {link: '/passagem-de-onibus/bandeirantes,pr-pr/santaamelia-pr'},
    {link: '/passagem-de-onibus/santoantoniodaplatina-pr/castro-pr'},

    {link: '/passagem-de-onibus/pocosdecaldas-mg/aguasdaprata-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/portoferreira-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/saojoaodaboavista-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/santacruzdaspalmeiras-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/divinolandia-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/casabranca-sp'},
    {link: '/passagem-de-onibus/mococa-sp/cajuru-sp'},
    {link: '/passagem-de-onibus/mococa-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/cajuru-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/cajuru-sp/mococa-sp'},
    {link: '/passagem-de-onibus/cajuru-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/cajuru-sp/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/cajuru-sp/divinolandia-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/vargemgrandedosul-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/cajuru-sp'},
    {link: '/passagem-de-onibus/pocosdecaldas-mg/mococa-sp'},
    {link: '/passagem-de-onibus/vargemgrandedosul-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/vargemgrandedosul-sp/aguasdaprata-sp'},
    {link: '/passagem-de-onibus/vargemgrandedosul-sp/portoferreira-sp'},
    {link: '/passagem-de-onibus/vargemgrandedosul-sp/saojoaodaboavista-sp'},
    {link: '/passagem-de-onibus/vargemgrandedosul-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/vargemgrandedosul-sp/santacruzdaspalmeiras-sp'},
    {link: '/passagem-de-onibus/vargemgrandedosul-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/itobi-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/itobi-sp/caconde-sp'},
    {link: '/passagem-de-onibus/itobi-sp/tapiratiba-sp'},
    {link: '/passagem-de-onibus/morroagudo-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saojoaodaboavista-sp/vargemgrandedosul-sp'},
    {link: '/passagem-de-onibus/saojoaodaboavista-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/saojoaodaboavista-sp/aguasdaprata-sp'},
    {link: '/passagem-de-onibus/saojoaodaboavista-sp/portoferreira-sp'},
    {link: '/passagem-de-onibus/saojoaodaboavista-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saojoaodaboavista-sp/santacruzdaspalmeiras-sp'},
    {link: '/passagem-de-onibus/aguasdaprata-sp/santacruzdaspalmeiras-sp'},
    {link: '/passagem-de-onibus/aguasdaprata-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/portoferreira-sp/vargemgrandedosul-sp'},
    {link: '/passagem-de-onibus/portoferreira-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/portoferreira-sp/aguasdaprata-sp'},
    {link: '/passagem-de-onibus/portoferreira-sp/saojoaodaboavista-sp'},
    {link: '/passagem-de-onibus/portoferreira-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/portoferreira-sp/santacruzdaspalmeiras-sp'},
    {link: '/passagem-de-onibus/portoferreira-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/itobi-sp/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/tapiratiba-sp'},
    {link: '/passagem-de-onibus/santacruzdaspalmeiras-sp/vargemgrandedosul-sp'},
    {link: '/passagem-de-onibus/santacruzdaspalmeiras-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/santacruzdaspalmeiras-sp/aguasdaprata-sp'},
    {link: '/passagem-de-onibus/santacruzdaspalmeiras-sp/portoferreira-sp'},
    {link: '/passagem-de-onibus/santacruzdaspalmeiras-sp/saojoaodaboavista-sp'},
    {link: '/passagem-de-onibus/santacruzdaspalmeiras-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/santacruzdaspalmeiras-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/severinia-sp/monteazulpaulista-sp'},
    {link: '/passagem-de-onibus/olimpia-sp/severinia-sp'},
    {link: '/passagem-de-onibus/olimpia-sp/bebedouro,sp-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/vargemgrandedosul-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/cajuru-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/mococa-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/aguasdaprata-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/portoferreira-sp'},
    {link: '/passagem-de-onibus/saojoaodaboavista-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/monteazulpaulista-sp/olimpia-sp'},
    {link: '/passagem-de-onibus/monteazulpaulista-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/monteazulpaulista-sp/severinia-sp'},
    {link: '/passagem-de-onibus/monteazulpaulista-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/monteazulpaulista-sp/bebedouro,sp-sp'},
    {link: '/passagem-de-onibus/olimpia-sp/monteazulpaulista-sp'},
    {link: '/passagem-de-onibus/olimpia-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/mococa-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/mococa-sp/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/mococa-sp/divinolandia-sp'},
    {link: '/passagem-de-onibus/aguasdaprata-sp/vargemgrandedosul-sp'},
    {link: '/passagem-de-onibus/aguasdaprata-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/aguasdaprata-sp/portoferreira-sp'},
    {link: '/passagem-de-onibus/aguasdaprata-sp/saojoaodaboavista-sp'},
    {link: '/passagem-de-onibus/aguasdaprata-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/cajuru-sp'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/mococa-sp'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/itobi-sp'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/divinolandia-sp'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/saojosedoriopardo-sp/caconde-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/morroagudo-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/saojoaodaboavista-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/monteazulpaulista-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/olimpia-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/santacruzdaspalmeiras-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/severinia-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/divinolandia-sp'},
    {link: '/passagem-de-onibus/ribeiraopreto-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/severinia-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/vargemgrandedosul-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/casabranca-sp/aguasdaprata-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/portoferreira-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/itobi-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/saojoaodaboavista-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/divinolandia-sp/cajuru-sp'},
    {link: '/passagem-de-onibus/divinolandia-sp/pocosdecaldas-mg'},
    {link: '/passagem-de-onibus/divinolandia-sp/mococa-sp'},
    {link: '/passagem-de-onibus/divinolandia-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/divinolandia-sp/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/saojosedoriopreto-sp/monteazulpaulista-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/santacruzdaspalmeiras-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/caconde-sp'},
    {link: '/passagem-de-onibus/casabranca-sp/tapiratiba-sp'},
    {link: '/passagem-de-onibus/caconde-sp/itobi-sp'},
    {link: '/passagem-de-onibus/caconde-sp/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/caconde-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/caconde-sp/tapiratiba-sp'},
    {link: '/passagem-de-onibus/bebedouro,sp-sp/monteazulpaulista-sp'},
    {link: '/passagem-de-onibus/bebedouro,sp-sp/olimpia-sp'},
    {link: '/passagem-de-onibus/bebedouro,sp-sp/severinia-sp'},
    {link: '/passagem-de-onibus/tapiratiba-sp/itobi-sp'},
    {link: '/passagem-de-onibus/severinia-sp/olimpia-sp'},
    {link: '/passagem-de-onibus/severinia-sp/ribeiraopreto-sp'},
    {link: '/passagem-de-onibus/severinia-sp/saojosedoriopreto-sp'},
    {link: '/passagem-de-onibus/severinia-sp/bebedouro,sp-sp'},
    {link: '/passagem-de-onibus/tapiratiba-sp/saojosedoriopardo-sp'},
    {link: '/passagem-de-onibus/tapiratiba-sp/casabranca-sp'},
    {link: '/passagem-de-onibus/tapiratiba-sp/caconde-sp'},

];
export default princesaDoNorte;