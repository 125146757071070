import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

function MapaEmbarque(props) {
    let { latitudeOrigem, longitudeOrigem } = props.passagem;
    latitudeOrigem = Number(latitudeOrigem)
    longitudeOrigem = Number(longitudeOrigem)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCX5wkdWCROf0nxL2Zbh0knUdCVvtYsyEI'
    });

    const getZoom = () => {
        if (latitudeOrigem && longitudeOrigem) {
            return 18;
        }
        return 5;
    };

    return (
        <div className="form-row">
            <div>
                <div className="col-12">
                    {isLoaded && (
                        <GoogleMap
                            center={{ lat: latitudeOrigem, lng: longitudeOrigem }}
                            zoom={getZoom()}
                            mapContainerClassName="renderMapa"
                            options={{
                                disableDefaultUI: true,
                                zoomControl: true,
                                fullscreenControl: true,
                                streetViewControl: true,
                            }}
                        >
                            <Marker
                                key={'embarque'}
                                position={{ lat: latitudeOrigem, lng: longitudeOrigem }}
                            />
                        </GoogleMap>
                    )}
                </div>
            </div>
        </div>
    );
}

export { MapaEmbarque };