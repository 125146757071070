import React from 'react'

const ConteudoQuemSomosAG = () => (
  <div className="page-contato" style={{ fontSize: 16, backgroundColor: '#ffffff' }}>
    <section className="gv-section">
      <div className="section-content">
        <h2 className="gv-title"> <b>Bilheteria digital</b></h2>
        <p className="txt-item text-center"> <b>UMA BILHETERIA NA PALMA DE SUA MÃO</b></p>
        <p className="txt-item">A Agência online é uma plataforma de venda de passagens de ônibus intermunicipais e interestaduais. Nosso objetivo é fornecer a você a melhor experiência de viagem de ônibus, ao mesmo tempo em que facilita o planejamento de sua viagem. Entendemos que há muitos fatores que entram em jogo ao planejar uma viagem, e nós temos que fazer lhe oferecer as melhores empresa e preços para facilitar e atender a todas as suas necessidades.</p>
        <p className="txt-item">Nossa ideia é continuar criando tecnologias para a plataforma on-line, onde as pessoas pudessem comprar passagens sem ter que passar pelo ônibus passar por um agente ou encontrar um agente de viagens na loja. Com isso, nosso negócio cresce tremendamente e continuamos a oferecer a nossos clientes o melhor serviço possível.</p>
        <p className="txt-item">Queremos que o nosso site seja seguro para que seja fácil de usar. Com nosso site, você pode comprar passagens de qualquer lugar do Brasil, podendo ir a rodoviária somente no dia de sua viagem. Nossa plataforma oferece as melhores ofertas em viagens de ônibus através desta Plataforma online. Temos passagens para mais de 1.000 locais ao redor do Brasil.</p>
        <p className="txt-item">Sabemos o quanto você está ocupado, por isso facilitamos para você conseguir o que precisa. É tudo uma questão de aperto, acessibilidade e preços acessíveis para nossos clientes. É por isso que somos uma das empresas únicas que fornece uma plataforma de reservas on-line que pode ser acessada de qualquer lugar do mundo, 24 horas por dia, 7 dias por semana. Você pode enviar um bilhete de ônibus a qualquer para qualquer pessoa a qualquer hora do dia ou à noite. A melhor maneira de viajar é com um sorriso em seu rosto. Temos ajudado pessoas a economizar dinheiro ao reservar o tempo e sua passagem de ônibus com antecedência, em nossa plataforma.</p>
        <p className="txt-item">Nos orgulhamos de ter uma grande seleção de bilhetes, e uma equipe de atendimento Especializada em atendimento e prestação de serviço com qualidades. Nós somos sua fonte para viagens de ônibus. Reserve suas passagens de ônibus conosco hoje, e as empresas parceiras o levaram de forma confortável e segura para onde desejar. </p>
        <p className="txt-item">Pesquisou, comprou, viajou! A bilheteria na palma de sua mão!</p>
      </div>
    </section>
  </div>
)

export default ConteudoQuemSomosAG

