import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import {authenticateUser} from '../../actions/usuarioActions'
import ModalEsqueciMinhaSenha from './ModalEsqueciMinhaSenha';
import ReCaptchaV2 from 'react-google-recaptcha'
import {AUTHENTICATION_FAILED} from '../../reducers/usuariosReducer';
import {renderField, required} from '../../util/inputs';
import {getBrowserHistory} from '../../util/applicationContext';
import {validarVendaPendente, adicionarPassageiros} from '../../actions/vendasActions';
import {getVendaPendente} from '../../seguranca/securityContext';


class PaginaLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chaveCaptcha: null,
        };
        this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
        validarVendaPendente()
    }

    componentDidUpdate() {
        const {adicionarPassageiros} = this.props
        const vendaPendente = getVendaPendente();
        if (vendaPendente && this.props.dadosAutenticacao.isAuthenticated) {
            const passageiros = vendaPendente.reservas.map(reserva=>reserva.passageiroDto)
            adicionarPassageiros(passageiros)
            getBrowserHistory().push('/passagem-de-onibus?pagina=5')
        }
    }

    changeRecaptcha(value) {
        if (value) {
            this.setState({chaveCaptcha: value})
        }
    }

    onSubmit(values) {
        this.props.authenticateUser({
            username: values.username.toLowerCase()
                .trim(),
            password: values.password,
            chaveCaptcha: this.state.chaveCaptcha,
        }).then((resp) => {
            if (resp.type === AUTHENTICATION_FAILED) {
                this.setState({chaveCaptcha: null});
                this.recaptchaRef.current.reset();
            }
        })
    }

    renderLinkCadastro() {
        if (this.props.location.search === '?venda') {
            return '/cadastro?venda'
        }
        return '/cadastro'
    }

    render() {
        const {from} = this.props.location.state || {from: {pathname: '/'}};
        if (this.props.dadosAutenticacao.isAuthenticated) {
            return (<Redirect to={from}/>)
        }

        const {handleSubmit} = this.props;

        return (
            <div className="back-login">
                <form className="container form-login" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div className='loginHeader'>
                        <h1>Login</h1>
                        <div className="divider"></div>
                    </div>
                    <Field
                        label='Usuário'
                        name='username'
                        type='email'
                        id='username'
                        placeholder=''
                        validate={[required]}
                        component={renderField}
                    />
                    <Field
                        label='Senha'
                        name='password'
                        id='password'
                        type='password'
                        placeholder=''
                        validate={[required]}
                        component={renderField}
                    />
                    <a className='float-right' data-toggle="modal" data-target="#modalEsqueciMinhaSenha">Esqueci minha
                        senha</a>
                    <button id='btn-entrar' type='submit' className='btn btn-laranja btn-sistema'
                            disabled={!this.state.chaveCaptcha}>ENTRAR
                    </button>
                    <div className='col-12 text-center'>
                        <ReCaptchaV2
                            ref={this.recaptchaRef}
                            style={{display: 'inline-block'}}
                            sitekey={'6LfNrvkdAAAAAL2wVMaGIuOwJ0STFzIOavTKhNvw'}
                            onChange={(value) => this.changeRecaptcha(value)}
                            onExpired={() => this.setState({chaveCaptcha: null})}
                            onErrored={() => this.setState({chaveCaptcha: null})}/>
                    </div>
                    <div className='col-12 text-center mt-2'>
                        <span>Ainda não tem conta?</span>
                        <br/>
                        <Link to={this.renderLinkCadastro()} className="btn-cadastro">Cadastre-se</Link>
                    </div>
                </form>
                <ModalEsqueciMinhaSenha/>
            </div>

        )
    }
}

export const mapStateToProps = state => (
    {
        dadosAutenticacao: state.usuario.dadosAutenticacao
    }
)

const reduxConnectEnhance = connect(mapStateToProps, {authenticateUser, adicionarPassageiros})(PaginaLogin);

export default reduxForm({
    form: 'LoginForm',
})(reduxConnectEnhance)
