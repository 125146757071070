import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosbusexpresso = {
    ...dadosAgenciaComum,
    id: 'busexpresso',
    nome: 'Bus Expresso',
    instagram: 'https://www.instagram.com',
    tiktok: 'https://www.tiktok.com/@vexadoo',
    facebook: 'https://www.facebook.com',
    youtube: 'https://www.youtube.com',
    email: 'turismo.bsb@gmail.com',
    lktelefone: 'tel:61999794850',
    lkemail: 'mailto:turismo.bsb@gmail.com',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561998700861&text=Olá. Preciso de atendimento.',
    idsTagManager: ['GTM-NLDDVFN']
};
