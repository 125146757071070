import React from 'react';

const textoBannerSite = [<h5 key={0} style={{textShadow: '3px 2px black'}}>Passagens online!</h5>, <p key={1} style={{textShadow: '3px 2px black'}}>O melhor valor sem precisar sair de casa!</p>]; 

const dadosSiteComum = {
    textoBanner: textoBannerSite,
    textoCardGuiaDicas: '',
    bannerMobile: null,
    distribusion: true,
    exibePopUpPromocao: true
};

export default dadosSiteComum