import React from 'react';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

const ConteudoQuemSomosVPB = () => (
  <div className="page-contato" style={{ fontSize: 16, backgroundColor: '#ffffff' }}>
    <section className="gv-section">
      <div className="section-content">

        <h2 className="gv-title">A VIP Brasil Transporte e Turismo</h2>
        <p className="txt-item">A VIP Brasil Transporte e Turismo é uma empresa com anos de experiência no mercado, especializada em oferecer serviços de transporte e turismo com excelência. Desde nossa fundação, temos o compromisso de proporcionar aos nossos clientes uma experiência única, marcada pela pontualidade, segurança e conforto em todas as etapas da viagem.</p>
        <h2 className="gv-title" style={{ marginTop: 24 }}>Nossa Missão:</h2>
        <p className="txt-item">Nossa missão é trabalhar continuamente para atender cada vez melhor nossos clientes, primando sempre pela satisfação e segurança de todos. Nosso compromisso é oferecer serviços de qualidade, com atendimento personalizado e eficiente, garantindo que cada viagem seja uma experiência inesquecível.</p>

        <h2 className="gv-title" style={{ marginTop: 24 }}>Por que a VIP Brasil?</h2>
        <p className="txt-item"><strong>1.  Experiência:</strong> Com anos de experiência no mercado, somos especialistas em oferecer serviços de transporte e turismo de qualidade.</p>
        <p className="txt-item"><strong>2.  Qualidade:</strong> Nossa frota é composta por veículos modernos e bem equipados, garantindo conforto e segurança em todas as viagens.</p>
        <p className="txt-item"><strong>3.  Atendimento personalizado:</strong> Nossa equipe altamente qualificada está sempre pronta para atender às necessidades dos nossos clientes, oferecendo um serviço personalizado e eficiente.</p>
        <p className="txt-item"><strong>4.  Pontualidade:</strong> Nosso compromisso é oferecer serviços pontuais, garantindo que nossos clientes cheguem ao seu destino no horário certo.</p>
        <p className="txt-item"><strong>5.  Segurança:</strong> A segurança dos nossos clientes é a nossa prioridade, e tomamos todas as medidas necessárias para garantir uma viagem segura e tranquila.</p>

        <h2 className="gv-title" style={{ marginTop: 24 }}>Acompanhe-nos</h2>
        <p className="txt-item">Fique por dentro de todas as novidades e promoções em nossa página no Instagram: <a href="https://www.instagram.com/vipbrasil.tur/" target="_blank" rel="noopener noreferrer"><b>@vipbrasil.tur</b></a></p>

        <h2 className="gv-title" style={{ marginTop: 24 }}>Não perca tempo!</h2>
        <p className="txt-item">Venha viajar com a VIP Brasil Transporte e Turismo e desfrute de uma experiência única, com conforto, segurança e pontualidade garantidos. Estamos aqui para atender você e sua família, sempre com respeito à vida e responsabilidade.</p>

        <h2 className="gv-title" style={{ marginTop: 24 }}>Nossa História:</h2>
        <div>
          <video className="w-100 videosInicial" title="Background" autoPlay={true} loop={true} controls muted>
            <source src="https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/empresa-vip-brasil-vexado-sua-passagem-de-onibus.mp4" type="video/mp4" />
            <track kind="captions" label="Legendas" src="legendas.vtt" />
          </video>
        </div>

        <section className="gv-cards" style={{ marginTop: 50 }}>
          <div className="gv-cards-wrapper">
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <DevicesOutlinedIcon className="box-item-icon"></DevicesOutlinedIcon>
                  <p>Tecnologia</p>
                  <div className="divider" />
                </span>
                <span className="box-item-card-info">
                  <p>Descomplicamos o jeito antigo e complicado de comprar uma passagem!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <EmojiObjectsOutlinedIcon className="box-item-icon"></EmojiObjectsOutlinedIcon>
                  <p>Inovação</p>
                  <div className="divider" />
                </span>
                <span className="box-item-card-info">
                  <p>Inovamos em um mercado promissor. Tecnologia simples, aplicada e segura para você, sua família e amigos.</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <PeopleAltOutlinedIcon className="box-item-icon"></PeopleAltOutlinedIcon>
                  <p>Relacionamento</p>
                  <div className="divider" />
                </span>
                <span className="box-item-card-info">
                  <p>Pensamos em você muito mais que um simples cliente, temos você como parte de nossa empresa. Sem você não teríamos motivos para estar aqui!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <TrendingUpOutlinedIcon className="box-item-icon"></TrendingUpOutlinedIcon>
                  <p>Agilidade</p>
                  <div className="divider" />
                </span>
                <span className="box-item-card-info">
                  <p>Rápido e descomplicado. Clicou, comprou, viajou!</p>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section >
  </div >
)

export default ConteudoQuemSomosVPB