import React, {Component} from 'react'
import {reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {cadastrarCliente} from '../../actions/usuarioActions'
import BuscaPassagem from '../passagem/BuscaPassagem';
import {recuperarConfEmpresa} from '../../util/applicationContext';

class QueSomos extends Component {
    render() {
        let confEmpresa = recuperarConfEmpresa();
        let PaginaQuemSomos = confEmpresa.quemSomos;
        return (
            <div className='conteudo'>
                <div>
                    <BuscaPassagem/>
                </div>
                <div className='col-12 conteudo-internas'>
                    <div className='card border-0'>
                        <div className='card-body'>
                            <div className='loginHeader'>
                                <h1 id='quem-somos'>Quem somos</h1>
                                <div className="divider"/>
                            </div>
                            <PaginaQuemSomos confEmpresa={confEmpresa}/>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export const mapStateToProps = state => (
    {
        dadosAutenticacao: state.usuario.dadosAutenticacao,
    }
)

const reduxConnectEnhance = connect(mapStateToProps, {cadastrarCliente})(QueSomos);

export default reduxForm({
    form: 'NovoUsuarioForm',
})(reduxConnectEnhance)
