import React from 'react';
import logoAgencia from '../../../estilo/comum/img/logo-geral-agencia-rosa.png'
import iconAgencia from '../../../estilo/comum/img/logo-geral-agencia.png'
import bannerAgencia from '../../../estilo/comum/img/banner-site-agencias.png'
import bannerAgenciaMobile from '../../../estilo/comum/img/banner-site-agencias-mobile.png'
import bannerAgenciaMobile2 from '../../../estilo/comum/img/banner-site-agencias-mobile-2.png'
import ConteudoQuemSomosAG from '../../../componentes/quem-somos/agencias/ConteudoQuemSomosAG';
import dadosSiteComum from './dadosSiteComum';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/9.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/23.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoExpressoDiamanteTransbrasilPassagensAdamantina.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/24.webp';

const logoAgenciaRodape = <img className="agencia" src={logoAgencia} alt="Logo Agência" style={{width: '70px'}}/>;
const logoAgenciaCabecalho = <img className="logo-site agencia" src={logoAgencia} alt='Logo Agência'/>;

export const dadosPegabus = {
    ...dadosSiteComum,
    id: 'pegabus',
    pastaEstilo: 'pegabus',
    nome: 'Pegabus',
    nomePrefixo: 'o Pegabus',
    razaoSocial: 'Pegabus Log LTDA',
    cnpj: '23.302.515/0001-41',
    endereco:'Q SCS Quadra 01, Bloco M, número 30, Sala 508, Asa Sul/DF, CEP: 70.305-900',
    telefone: '(11) 949750931',
    wtpp: '(11) 949750931',
    instagram: 'https://www.instagram.com/pegabus.ofc/',
    facebook: 'https://www.facebook.com/pegabus.ofc',
    tiktok: 'https://www.tiktok.com',
    youtube: 'youtube.com',
    email: 'thpegabus@gmail.com',
    emailSuporte: 'thpegabus@gmail.com',
    lkemailSuporte: 'mailto:thpegabus@gmail.com',
    lktelefone: 'tel:551194975-0931',
    twitter: '',
    lkemail: 'mailto:thpegabus@gmail.com',
    lkwhats: 'https://api.whatsapp.com/send?phone=5511949750931&text=Olá. Preciso de atendimento.',    
    logo: logoAgencia,
    logoCabecacho: logoAgenciaCabecalho,
    logoRodape: logoAgenciaRodape,
    icone: iconAgencia,
    quemSomos: ConteudoQuemSomosAG,
    videoInicio: bannerAgencia,
    imgMobile: bannerAgenciaMobile,
    imgMobile2: bannerAgenciaMobile2,
    idsTagManager: ['GTM-MMPW4Z9'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+desconto+expresso+diamante+-+transbrasil++-+passagens+adamantina.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/6.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/6.mobile.webp'
};