import React, {Component} from 'react'

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {connect} from 'react-redux';
import {buscarPassagens, buscarPassagensDistribusion, limparPassagens} from '../../actions/vendasActions';
import {withRouter} from 'react-router-dom';
import { recuperarConfEmpresa } from '../../util/applicationContext';

const semana = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

export class BarraNavegacaoDatas extends Component {
    calculaData(isAdd, isSub, val, data) {
        let dataTemp = moment(data);
        if (isAdd) {
            dataTemp = dataTemp.add(val, 'days');
        }
        if (isSub) {
            dataTemp = dataTemp.subtract(val, 'days');
        }
        return dataTemp
    }

    async buscarPassagem(data, dataMaiorAtual) {
        if (!dataMaiorAtual) {
            this.props.limparPassagens();
            this.props.selecionarData(data);
            const {venda: {origem, destino, dataIdaSelecionada, dataRetornoSelecionada}, empresaSelecionada} = this.props;
            const dataIda = this.props.tipo === 'ida' ? data : dataIdaSelecionada;
            const dataRetorno = this.props.tipo === 'ida' ? dataRetornoSelecionada : data;
            let url = `/passagem-de-onibus/${origem.nomeComUfNormalizado}/${destino.nomeComUfNormalizado}/${moment(dataIda).format('YYYY-MM-DD')}`;
            if (dataRetorno) {
                url = `${url}/${moment(dataRetorno).format('YYYY-MM-DD')}`
            }
            const idEmpresa = empresaSelecionada? empresaSelecionada.empresaId : null;
            await this.props.buscarPassagens(origem.id, destino.id, dataIda, dataRetorno, idEmpresa);
            if (recuperarConfEmpresa().distribusion) {
                this.props.buscarPassagensDistribusion(origem.id, destino.id, dataIda, dataRetorno, idEmpresa);
            }
            this.props.history.push(url);
        }
    }


    isDataMaiorQueDataAtual(isAdd, isSub, val, data) {
      const {venda: {dataIdaSelecionada }} = this.props;
        if (this.props.tipo !== 'ida') {
            return this.calculaData(isAdd, isSub, val, data).isBefore(dataIdaSelecionada) || moment(dataIdaSelecionada).format('DD/MM/YYYY').toString() === this.calculaData(isAdd, isSub, val, data).format('DD/MM/YYYY').toString();
        }
        return this.calculaData(isAdd, isSub, val, data).isBefore(moment()) && !(moment().format('DD/MM/YYYY').toString() === this.calculaData(isAdd, isSub, val, data).format('DD/MM/YYYY').toString());
    }

    renderData(isAdd, isSub, val) {
        let data = this.calculaData(isAdd, isSub, val, this.props.data);
        let dataMaiorAtual = this.isDataMaiorQueDataAtual(isAdd, isSub, val, this.props.data)
        let isDataSelecionada = !isAdd && !isSub;
        return (<div className={'col-md-1' + (isDataSelecionada ? ' active' : dataMaiorAtual ? ' disabled' : '')}
                     onClick={() => this.buscarPassagem(data, dataMaiorAtual || isDataSelecionada)}>
                    <span>
                      <p>{semana[data.day()]}</p>
                      <p>{data.format('DD/MM/YYYY')}</p>
                    </span>
        </div>)
    }

    renderDataMobile(isAdd, isSub, val) {
        let data = this.calculaData(isAdd, isSub, val, this.props.data);
        let dataMaiorAtual = this.isDataMaiorQueDataAtual(isAdd, isSub, val, this.props.data)
        let isDataSelecionada = !isAdd && !isSub;
        return (<div className={'col-md-4' + (isDataSelecionada ? ' active' : dataMaiorAtual ? ' disabled' : '')}
                     onClick={() => this.buscarPassagem(data, dataMaiorAtual || isDataSelecionada)}>
                    <span>
                      <p>{semana[data.day()]}</p>
                      <p>{data.format('DD/MM/YYYY')}</p>
                    </span>
        </div>)
    }

    render() {
        return (
            <div className="container">
                <div className="row calendario-semana justify-content-center">
                    {this.renderData(false, true, 3)}
                    {this.renderData(false, true, 2)}
                    {this.renderData(false, true, 1)}
                    {this.renderData(false, false, 1)}
                    {this.renderData(true, false, 1)}
                    {this.renderData(true, false, 2)}
                    {this.renderData(true, false, 3)}
                    {this.renderData(true, false, 4)}
                </div>
                <div className="row calendario-semana-mobile justify-content-center">
                    {this.renderDataMobile(false, true, 1)}
                    {this.renderDataMobile(false, false, 1)}
                    {this.renderDataMobile(true, false, 1)}
                </div>
            </div>
        )
    }
}

export const mapStateToProps = state => (
    {
        venda: state.venda,
        dataRetornoSelecionada: state.venda.dataRetornoSelecionada,
        dataIdaSelecionada: state.venda.dataIdaSelecionada,
        empresaSelecionada: state.empresa.selecionada
    });


export default withRouter(connect(mapStateToProps,
    {
        buscarPassagens,
        buscarPassagensDistribusion,
        limparPassagens
    })(BarraNavegacaoDatas))
