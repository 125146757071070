import React, {Component} from 'react'
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

export class CadastroNews extends Component {
    render() {
        return !this.props.isAuthenticated ?
            <section className="news" id="news">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8" style={{padding: '5px 0'}}>
                            <p className="txt-destaque">É PROMOÇÃO QUE VOCÊ PROCURA?</p>
                            <p className="txt-descritivo">Cadastre o número do seu WhatsApp e receba todas as nossas
                                promoções:</p>
                        </div>
                        <div className="col-md-4">
                            <button className="btn-acao"
                                    onClick={() => this.props.history.push('/cadastro-promocao')}>CADASTRE-SE
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        : ''
    }
}

export const mapStateToProps = state => (
  {
      isAuthenticated: state.usuario.dadosAutenticacao.isAuthenticated,
  });

export default withRouter(connect(mapStateToProps, {})(CadastroNews))
