import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosBilheteRodoviario = {
    ...dadosAgenciaComum,
    id: 'bilheterodoviario',
    nome: 'Bilhete Rodoviário',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    email: 'bilheterodoviario@gmail.com',
    lktelefone: 'tel:61991182624',
    lkemail: 'mailto:turismo.bsb@gmail.com',
    lkwhats: 'https://api.whatsapp.com/send?phone=5561991182624&text=Olá. Preciso de atendimento.',
    idsTagManager: ['GTM-58Q6W54']
};
