import React from 'react'
import { recuperarConfEmpresa } from '../util/applicationContext'

let confEmpresa = recuperarConfEmpresa();
let nomeEmpresa = confEmpresa.nome.toUpperCase();

const TermosCompra = () => {
    return <div style={{ marginTop: '50px' }}>
        <div className="container" id="TermosCompra">
            <div className="loginHeader">
                <h1 id='secao-termos-compra'>Termos de Compra</h1>
                <div className="divider"></div>
            </div>
            <div>
                <p><strong>CONTRATO DE ADESÃO PARA AQUISIÇÃO DE BILHETE DE PASSAGEM PARA LINHAS RODOVIÁRIAS INTERESTADUAIS VIA INTERNET</strong></p>
                <p><strong>As partes:</strong></p>
                <p>{nomeEmpresa}, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob número 33.698.981/0001-41, sediada na Avenida Angelica, 2163 – Sala 171 2VG, Consolação, na cidade de São Paulo, Estado de São Paulo, neste ato por seus representantes legais, doravante denominada TRANSPORTADORA e a PESSOA FÍSICA OU JURÍDICA adquirente do bilhete de passagem via INTERNET, doravante denominada USUÁRIO; têm justo e combinado a celebração do presente Contrato para Aquisição de Bilhete de Passagem para Linhas Rodoviárias Interestaduais via Internet, mediante as seguintes cláusulas e condições.</p>
                <p><strong>Para efeito do presente instrumento fica estabelecido que:</strong></p>
                <p>(I) Transportadora: é a empresa indicada no Bilhete de Reserva/Serviço;<br />(II) Operadora: é a Empresa Agenciadora que estiver intermediando a venda;<br />(III) Usuário: é o Cliente, pessoa física ou jurídica, que efetivou o pagamento do valor relativo às passagens adquiridas via Internet.</p>
                <p><strong>CLÁUSULA PRIMEIRA – DO OBJETO</strong></p>
                <p>1.1. O presente contrato tem por objeto a venda e compra, por meio da Internet, de Bilhete de Passagem para as linhas de característica rodoviária, operadas pela TRANSPORTADORA e disponibilizadas através de seu site.</p>
                <p><strong>CLAUSULA SEGUNDA – DO FUNCIONAMENTO DA VENDA E AQUISIÇÃO</strong></p>
                <p>2.1. O USUÁRIO poderá realizar a compra dos bilhetes de passagem por meio de qualquer computador com acesso à Internet, bem como por meio dos Terminais de Autoatendimento disponíveis em locais públicos; anuindo, por intermédio do sistema web, com todas as condições estabelecidas no presente contrato, antes da efetivação da compra.</p>

                <p>2.2. O USUÁRIO, a seu exclusivo critério e responsabilidade, selecionará entre as opções disponibilizadas pela OPERADORA, o itinerário que pretende viajar, envolvendo origem e destino, a data da viagem, o horário de partida e o tipo de serviço disponível (convencional, executivo, semi-leito e leito).</p>
                <p>2.3. As compras objeto do presente contrato somente poderão ser efetivadas se adquiridas com antecedência máxima de 01:30 (uma hora e trinta minutos) em relação ao embarque.</p>
                <p>2.4. Estão aptas à compra de passagens pela web, todas as pessoas físicas e jurídicas com capacidade legal para contratar.</p>
                <p>2.5. O passageiro deverá portar, indispensavelmente, o bilhete de passagem adquirido e os documentos válidos para fins de comprovação de sua identidade, tais como: cédula de identidade, Carteira Nacional de Habilitação, Documento de Identidade de Classe, Certidão de Nascimento, dentre os demais legalmente aceitos.</p>
                <p>2.6 – O passageiro para embarcar deverá obrigatoriamente trocar seu VOUCHER pelo bilhete de passagem, diretamente nos guichês de origem da {nomeEmpresa} TRANSPORTE DE PASSAGEIROS LTDA, com antecedência mínima de 30 minutos.</p>
                <p>2.7 – As passagens compradas pela web são nominais, sendo assim, apenas o passageiro indicado no ato da compra poderá retirar a passagem e embarcar.</p>
                <p><strong>CLAUSULA TERCEIRA – DAS CONDIÇÕES PARA COMPRA</strong></p>
                <p>3.1. O USUÁRIO, interessado na compra de Bilhete(s) de Passagem(s) Rodoviária(s) via Internet, deverá cadastrar-se no site da OPERADORA; ficando as informações prestadas armazenadas no banco de dados do sistema, para utilização em caso de aquisição de novas passagens; sendo assegurado o sigilo dos dados e privacidade das informações.</p>
                <p>3.2. A veracidade das informações cadastrais fornecidas pelo USUÁRIO serão de sua única e exclusiva responsabilidade.</p>            <p>3.3. A TRANSPORTADORA reserva-se no direito de cancelar, sem prévio aviso, e sem nenhuma espécie de indenização, quaisquer tarifas promocionais, preservando os benefícios tarifários dos bilhetes adquiridos pelo USUÁRIO, desde que emitidos para utilização no mesmo período da promoção.</p><p>3.4. A concretização da transação estará condicionada ao recebimento do valor pela Administradora do Cartão.</p><p><strong>CLÁUSULA QUARTA – DAS CONDIÇÕES DE PAGAMENTO</strong></p><p>4.1. O pagamento, em razão da modalidade de compra ora contratada, deverá ser efetuado exclusivamente por meio de cartão de crédito, cuja utilização do cartão e senha, além do fornecimento de dados, serão de exclusiva responsabilidade do USUÁRIO, adquirente dos bilhetes de passagem.</p><p>4.2. Na hipótese de desistência de uma compra efetivada, o USUÁRIO deverá devolver o documento de emissão do bilhete de passagem em um dos Pontos de Venda da TRANSPORTADORA e terá o valor ressarcido após comprovação do desconto na fatura de seu cartão de crédito e do respectivo repasse para a TRANSPORTADORA.</p>
                <p>4.3. Nos casos de pagamento parcelado com cartão de crédito, a devolução dos valores será efetuada de acordo com o repasse da Administradora à TRANSPORTADORA.</p>
                <p>4.4. Não haverá, em qualquer hipótese, o ressarcimento em favor do USUÁRIO de valores não repassados pela Administradora do Cartão de Crédito à TRANSPORTADORA.</p>
                <p>4.5. Fica expressamente vedada ao USUÁRIO, a utilização de meios ilícitos e/ou fraudulentos na realização de compra de passagem rodoviária pela web, sob pena de descumprimento contratual e do pleito de aplicação das penalidades cíveis e criminais cabíveis.</p>
                <p><strong>CLÁUSULA QUINTA – DA HIPÓTESE DE TRANSFERÊNCIA OU CANCELAMENTO DO BILHETE DE PASSAGEM</strong></p>
                <p>5.1. Fica assegurado ao USUÁRIO solicitar a transferência da data ou horário de embarque.<br />a) nos pontos de venda e guichê da empresa TRANSPORTADORA nos terminais rodoviários;<br />b) no local de origem da viagem;</p>
                <p>5.2. A transferência de horário ficará condicionada à disponibilidade de passagens na data e horário desejados pelo USUÁRIO, ficando, ainda, assegurada a opção pela passagem com data e horário “em aberto”, assim permanecendo pelo prazo máximo de 12 (doze) meses, contados da data de emissão do bilhete original.</p><p>5.3. Fica assegurado ao USUÁRIO o cancelamento da passagem, desde que a solicitação seja feita em até no máximo 03 (três) horas antes do horário estabelecido para o embarque.<br />a) nos pontos de venda e guichê da empresa TRANSPORTADORA nos terminais rodoviários;<br />b) no local de origem da viagem; ou<br />c) pelo telefone 0800 262 7356. &nbsp;</p>
                <p>5.4. Nos termos do art. 8º, §2º da Resolução n.º 978 da ANTT, fica a TRANSPORTADORA autorizada a reter até 5% (cinco por cento) do valor pago pela passagem rodoviária, a título de multa compensatória, no caso de o USUÁRIO desistir da viagem no prazo previsto no item “5.3”.</p>
                <p><strong>CLÁUSULA SEXTA – DOS DIREITOS E OBRIGAÇÕES DOS USUÁRIOS</strong></p>
                <p>5.5 RESOLUÇÃO Nº 4.282, DE 17 DE FEVEREIRO DE 2014 – A partir de 3 (três) horas antes do horário do início da viagem até a data de validade do bilhete, faculta-se à transportadora efetuar a cobrança de até 20% (vinte por cento) do valor da tarifa a título de remarcação, e com entrega de recibo ao usuário.</p>
                <p><strong>CLÁUSULA SEXTA – DOS DIREITOS E OBRIGAÇÕES DOS USUÁRIOS</strong></p>
                <p>6.1. Na forma da regulamentação em vigor, essencialmente o disposto no artigo 29 do Decreto nº 2.521, de 20 de março de 1998 e demais Resoluções publicadas pela ANTT, sem prejuízo do disposto na Lei 8.078, de 11 de Setembro de 1990, constituem direitos e obrigações do usuário:
                    <br />a) receber o serviço adequado;
                    <br />b) receber da Agência Nacional de Transportes Terrestres – ANTT e da TRANSPORTADORA informações para defesa de interesses individuais ou coletivos;
                    <br />c) obter e utilizar o serviço com liberdade de escolha;
                    <br />d) levar ao conhecimento do órgão de fiscalização as irregularidades de que tenha conhecimento, referentes ao serviço delegado;
                    <br />e) zelar pela conservação dos bens e equipamentos por meio dos quais lhes são prestados os serviços;
                    <br />f) ser transportado com pontualidade, segurança, higiene e conforto, do início ao término da viagem;
                    <br />g) ter garantida sua poltrona no ônibus, nas condições especificadas no bilhete de passagem;
                    <br />h) ser atendido com urbanidade pelos prepostos da TRANSPORTADORA e pelos agentes de fiscalização;
                    <br />i) ser auxiliado no embarque e desembarque, em se tratando de crianças, pessoas idosas ou com dificuldade de locomoção;
                    <br />j) receber da TRANSPORTADORA informações acerca das características dos serviços, tais como horários, tempo de viagem, localidades atendidas, preço de passagem e outras relacionadas com os serviços;
                    <br />k) receber os comprovantes das bagagens transportadas no bagageiro;
                    <br />l) ser indenizado por extravio ou dano da bagagem transportada no bagageiro, devendo a reclamação ser efetuada ao término da viagem, em formulário próprio fornecido pela TRANSPORTADORA (alterado pela Resolução ANTT nº 1922/2007);
                    <br />m) receber a diferença do preço da passagem, quando a viagem se faça, total ou parcialmente, em veículo de características inferiores às daquele contratado;
                    <br />n) receber, às expensas da TRANSPORTADORA, enquanto perdurar a situação, alimentação e pousada, nos casos de venda de mais de um bilhete de passagem para a mesma poltrona, ou interrupção ou retardamento da viagem, quando tais fatos forem imputados exclusivamente à TRANSPORTADORA;
                    <br />o) receber, em caso de acidente, imediata e adequada assistência da TRANSPORTADORA;
                    <br />p) sem pagamento, crianças de até seis anos incompletos, desde que não ocupem poltronas, observadas as disposições legais e regulamentares aplicáveis ao transporte de menores;
                    <br />q) efetuar a compra de passagem com data de utilização em aberto, sujeita a reajuste de preço se não utilizada dentro do prazo de 01 (um) ano contado da data de emissão;
                    <br />r) receber a importância paga, ou revalidar sua passagem, no caso de desistência da viagem, observando o disposto no Decreto 2521/98;
                    <br />s) estar garantido pelos seguros previstos no art.20, inciso XV, do referido Decreto;
                    <br />t) comparecer com antecedência mínima de 30 minutos para o embarque.</p>
                <p>6.2. Considerando o disposto no artigo 30 do Decreto 2521/98, terá recusado o embarque, ou estará sujeito ao desembarque, o USUÁRIO que:
                    <br />a) não se identificar, quando exigido;
                    <br />b) estiver em aparente estado de embriaguez;
                    <br />c) portar arma sem autorização da autoridade competente;
                    <br />d) transportar ou pretender embarcar produtos considerados perigosos pela legislação específica;
                    <br />e) transportar ou pretender embarcar consigo animais domésticos ou silvestres, sem o devido acondicionamento ou em desacordo com disposições legais ou regulamentares;
                    <br />f) pretender embarcar objeto de dimensões e acondicionamento incompatíveis com o porta-embrulhos;
                    <br />g) comprometer a segurança, o conforto ou a tranqüilidade dos demais passageiros;
                    <br />h) fizer uso de aparelho sonoro, depois de advertido pela tripulação do veículo;
                    <br />i) demonstrar incontinência no comportamento;
                    <br />j) recusar-se ao pagamento da tarifa;
                    <br />k) fizer uso de produtos fumígenos no interior do ônibus, em desacordo com a legislação pertinente.</p>
                <p>6.3. À vista do disposto no artigo 70 e seguintes do Decreto 2521/98, o preço da passagem abrange, a título de franquia, o transporte obrigatório e gratuito de bagagem no bagageiro e no volume de porta-embrulhos, observados os seguintes limites máximos de peso e dimensão:
                    <br />a) bagageiro, 30 KG (trinta quilos) de peso total e volume máximo de 300 cm³ (trezentos centímetros cúbicos), limitada a maior dimensão de qualquer volume a um metro e no porta-embrulhos, 05 kg (cinco quilos) de peso total, com dimensões que se adaptem ao local, desde que o conforto, a segurança e a higiene dos passageiros não sejam prejudicados;
                    <br />b) excedida a franquia prevista, o passageiro pagará até meio por cento do preço da passagem correspondente ao serviço convencional, pelo transporte de cada quilograma de excesso;
                    <br />c) é vedado o transporte de produtos considerados perigosos, indicados na legislação específica, bem como aqueles que, por sua forma ou natureza, comprometam a segurança do veículo, de seus ocupantes ou de terceiros, nos termos do art. 72 do Decreto 2521/98.</p>
                <p><strong>CLÁUSULA SÉTIMA – DAS OBRIGAÇÕES DA TRANSPORTADORA</strong></p>
                <p>São obrigações da TRANSPORTADORA, na forma do que estabelece o Decreto 2.521/1998:
                    <br />a) prestar serviço adequado, na forma prevista no Decreto, de acordo com as normas técnicas aplicáveis (art. 34, inciso I);
                    <br />b) zelar pela manutenção dos bens utilizados na prestação do serviço (art. 34, inciso VI);
                    <br />c) promover a retirada de serviço, do veículo cujo afastamento de tráfego tenha sido exigido pela fiscalização (art. 34, inciso VII);
                    <br />d) identificar os passageiros no momento do embarque, de acordo com a sistemática estabelecida pelos órgãos fiscalizadores (art. 39);
                    <br />e) nos casos de interrupção ou retardamento da viagem, a TRANSPORTADORA diligenciará, para a sua conclusão, obtendo outro veículo (art. 43);
                    <br />f) responsabilizar-se pela segurança da operação e pela adequada manutenção, conservação e preservação das características técnicas dos veículos (art. 56, § 1º);
                    <br />g) orientar o seu pessoal para que estejam adequadamente trajados e identificados, prestando as informações necessárias sobre a operação da linha, ou das linhas, de modo que possa prestar informações sobre horários, itinerários, tempo de percurso, distâncias e preços de passagens (art. 58).</p>
                <p>Parágrafo Único – Verificado o excesso de peso do veículo, será providenciado, sem prejuízo das penalidades cabíveis, o descarregamento das encomendas excedentes até o limite de peso admitido, ficando sob inteira responsabilidade da TRANSPORTADORA a guarda do material descarregado, respeitadas as disposições do Código Nacional de Trânsito, conforme artigo 75 do Decreto 2521, de 20.03.98.</p>
                <p><strong>CLÁUSULA OITAVA – DAS ADVERTÊNCIAS E PENALIDADES</strong></p>
                <p>8.1. Para fins de garantia da integridade do sistema, fica vedada aos USUÁRIOS, a utilização de qualquer tipo de equipamento, software, ou procedimento que venha causar dano ao funcionamento apropriado deste site.</p>
                <p>8.2. A inobservância desta condição configura crime de fraude contra a propriedade intelectual de programa de computador, imputando ao infrator às penas previstas na Lei 9609, de 19.2.1998, art. 12 e seguintes, além de caracterizar a quebra de contrato e sujeitar-se as sanções dela decorrentes.</p>
                <p><strong>CLÁUSULA NONA – DAS DECLARAÇÕES</strong></p>
                <p>9.1. O USUÁRIO aceita e declara ter pleno conhecimento das condições de compra/venda estabelecidas neste instrumento, razão pela qual, não poderá, em tempo algum, alegar desconhecimento ou vício de qualquer natureza.</p>
                <p>9.2. A OPERADORA e a TRANSPORTADORA não garantem o acesso contínuo e ininterrupto às páginas deste site, uma vez que a conexão está sujeita a sofrer interferência causada por diversos fatores externos. Por essa razão, a OPERADORA, bem como as TRANSPORTADORAS não poderão ser responsabilizadas por lucros cessantes ou quaisquer tipos de danos oriundos de negligência, imperícia ou imprudência nas operações deste site.</p>
                <p>9.3. Sem limitar o alcance das demais disposições descritas no presente Contrato, a OPERADORA não poderá ser considerada responsável por danos resultantes de perda, alteração ou acesso fraudulento ao sistema, em face da transmissão de vírus ou de outros elementos perniciosos, ou ainda, de invasão do sistema por terceiros.</p>
                <p><strong>CLÁUSULA DÉCIMA – DAS DISPOSIÇÕES FINAIS</strong></p><p>10.1. As condições de compra/venda de Bilhetes de Passagem integrantes deste Contrato de Adesão são regidas pela legislação brasileira, observando-se que, eventuais disposições consideradas inválidas por determinação legal, não invalidam nem prejudicam as demais cláusulas e critérios do presente ajuste. Desta forma, os contratantes elegem estas condições como incondicionais, irrevogáveis e irretratáveis.</p>
                <p>10.2. Será firmado um único Contrato de Adesão por USUÁRIO, sendo que o USUÁRIO poderá comprar até 05 (cinco) passagens por acesso, não sendo limitado o número de acessos, respeitando-se a qualificação de cada trajeto e o preço de cada passagem.</p>
                <p>10.3. Em qualquer tempo, poderão ser retirados ou alterados, em parte, ou em sua totalidade, os serviços disponibilizados neste site, a critério exclusivo da OPERADORA ou TRANSPORTADORA, sem que gere qualquer direito ou indenização em favor do USUÁRIO.</p>
                <p><strong>CLÁUSULA DÉCIMA PRIMEIRA – DO FORO DE ELEIÇÃO</strong></p>
                <p>11.1. Para eventuais demandas provenientes deste Contrato, fica eleito o Foro da Comarca São Paulo – SP, dispensando-se outros, por mais privilegiados que o sejam.</p>
                <p><strong>CLÁUSULA DÉCIMA SEGUNDA – DO REGISTRO E PUBLICIDADE DO CONTRATO</strong></p>
                <p>12.1. Este Contrato de Adesão encontra-se registrado no Cartório de Títulos e Documentos de São Paulo, SP.</p>
            </div>
        </div>
    </div>
}

export default TermosCompra