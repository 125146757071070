import React from 'react'
import $ from 'jquery';
import {connect} from 'react-redux';

export class MapaVeiculoItinerario extends React.Component {
    constructor(props) {
        $(document)
            .ready(function () {
                $('[data-toggle="tooltip"]')
                    .tooltip();
            });
        super(props);
        this.state = {
            andarSelecionado: 1,
        }
    }

    // eslint-disable-next-line class-methods-use-this
    exibirCorredor(fileira) {
        return (fileira.exibeCorredor ? <div className="transport-meio-corredor" key={`corredor-${fileira.id}`}/> : '')
    }

    // eslint-disable-next-line class-methods-use-this
    exibirFileira(fileira) {
        if (fileira.assentos.length > 0) {
            return (
                <div className="transport-meio-fileira" key={`fileira-${fileira.numero}-${this.props.andar}`}>
                    {this.exibirAssentos(fileira)}
                    <label htmlFor="_" className="transport-meio-assento espaco-vago" key='meio6'>_</label>
                </div>
            )
        }
    }

    exibirAssentos(fileira) {
        return fileira.assentos.map(assento => <div
            key={`assento-${this.props.sentido}-${fileira.numero}-${assento.ordem}`}>
            {this.props.bloqueio ? this.exibirAssentoBloqueio(assento, fileira) : this.exibirAssento(assento, fileira)}
        </div>)
    }

    selecionarEMarcarPoltrona(assento, fileira) {
        this.props.selecionar(assento, fileira, this.props.sentido)
    }

    recuperarDadosBloqueio(poltrona) {
        const trechos = poltrona.bloqueios.map(bloqueio => `<li>${bloqueio.trechoOrigem} -> ${bloqueio.trechoDestino}</li>`);
        const tabela = '<div><span>Poltrona bloqueada nos trechos:</span>' +
            '      <ul>' +
            trechos
        '    </ul></div>';
        return tabela;
    }

    exibirAssento(assento, fileira) {
        const {poltrona} = assento;
        if (assento.tipoAssento !== 'NORMAL') {
            return <label key={assento.id} className="espaco-vago  js-seat-selected-amplitude"></label>
        }

        if (!poltrona || poltrona.bloqueoPorTipoCategoria) {
            return <label data-toggle="tooltip" data-html="true" title="" key={assento.id}
                          className="assento assento-ocupado"></label>
        }

        if (!poltrona || poltrona.bloqueada) {
            return <label data-toggle="tooltip" data-html="true" key={assento.id} className="assento assento-ocupado"
                          style={{
                              backgroundColor: 'red',
                              color: '#FFF',
                          }}>{poltrona.numero}</label>
        }
        if (!poltrona || poltrona.reservada) {
            return <label data-toggle="tooltip" data-html="true" key={assento.id}
                          className="assento assento-ocupado">{poltrona.numero}</label>
        }

        return (
            <label key={assento.id}
                   style={!assento.selecionado ? !poltrona.reservada && poltrona.reservas && poltrona.reservas.length ? {
                       backgroundColor: '#2b4ec6',
                       color: '#FFF',
                   } : {
                       backgroundColor: '#02c659',
                       color: '#FFF',
                   } : null}
                   className={`assento assento-disponivel  js-seat-selected-amplitude ${assento.selecionado ? 'assento-selecionado' : ''}`}>
                {poltrona.numero}
                <input data-toggle="tooltip" data-html="true"
                       type="checkbox" name={poltrona.id} id={poltrona.id} data-assento={poltrona.numero}
                       onClick={() => {
                           this.selecionarEMarcarPoltrona(assento, fileira);
                           if (document.querySelectorAll('[role="tooltip"]')[0]) {
                               document.querySelectorAll('[role="tooltip"]')[0].remove();
                           }
                       }}
                       value={poltrona.numero}/>

            </label>
        )
    }


    exibirAssentoBloqueio(assento, fileira) {
        const {poltrona} = assento;
        if (assento.tipoAssento !== 'NORMAL') {
            return <label key={assento.id} className="espaco-vago  js-seat-selected-amplitude"></label>
        }
        return (
            <label key={assento.id}
                   className={'assento assento-disponivel  js-seat-selected-amplitude'}
                   style={{
                       backgroundColor: assento.selecionado ? 'green' : poltrona.bloqueada ? 'red' : '',
                       color: poltrona.bloqueada || assento.selecionado ? 'white' : '',
                   }}>
                {poltrona.numero}
                <input type="checkbox" name={poltrona.id} id={poltrona.id} data-assento={poltrona.numero}
                       onClick={() => this.selecionarEMarcarPoltrona(assento, fileira)}
                       value={poltrona.numero}/>

            </label>
        )
    }

    exibirMapaVeiculo() {
        if (this.props.mapaVeiculo && this.props.mapaVeiculo.length) {


            return (this.props.mapaVeiculo.map(fileira => <React.Fragment key={fileira.id}>
                <div>
                    {this.exibirFileira(fileira)}
                </div>
                {this.exibirCorredor(fileira)}
            </React.Fragment>))
        }
    }

    exibirTraseiraOnibus() {
        const {ehIntegracao} = this.props
        if (ehIntegracao) {
            return <div className="transport-traseira" style={{transform: 'rotate(178deg)'}}/>
        }
        return <div className="transport-traseira"/>

    }

    exibirFrenteOnibus() {
        const {ehIntegracao} = this.props
        if (ehIntegracao) {
            return <div className="transport-frente" style={{transform: 'rotate(178deg)'}}/>
        }
        return <div className="transport-frente"/>

    }


    render() {
        return (
            <React.Fragment>
                <div className="row titulo-principal-componente container">
                    <div className="col-md-2">&nbsp;</div>
                    <div className="col-md-6">
                        <h1 id="titulo-page">{this.props.trecho}</h1>
                        <h4 id="titulo-page">Saída {this.props.data} - Chegada {this.props.dataChegada}<p
                            className="linha"></p>
                        </h4>
                    </div>
                    <div className="col-md-4">
                        <p className="valor-passagem-itinerario">
                            {this.props.valorTotalPassagens.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </p>
                    </div>
                </div>
                <div className="transport-result-wrapper transport-result-wrapper-connection "
                     data-wrapper-direction="">
                    <div className="js-count-transport">
                        {this.montarDesingOnibus()}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    montarDesingOnibus() {
        const {ehIntegracao} = this.props
        if (ehIntegracao) {
            return <div className="transport bus-design bus-design-side ">
                {this.exibirTraseiraOnibus()}
                <div className="transport-meio transport-meio-thin">
                    {this.exibirMapaVeiculo()}
                </div>
                {this.exibirFrenteOnibus()}

            </div>
        }
        return <div className="transport bus-design bus-design-side ">
            {this.exibirFrenteOnibus()}
            <div className="transport-meio transport-meio-thin">
                {this.exibirMapaVeiculo()}
            </div>
            {this.exibirTraseiraOnibus()}
        </div>;
    }
}


export function mapStateToProps(state) {
    return {
        empresaSelecionada: state.empresaSelecionada,
    }
}


export default connect(mapStateToProps, {})(MapaVeiculoItinerario)
