import { React, Component } from 'react';
import $ from 'jquery'
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined'

export class BotaoVoltarAoTopo extends Component {

    componentDidMount() {
        const btn = $('#voltar-topo');
        btn.click(function () {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        });
    }

    render() {
        return (
            <div className="row setaRetornaAoTopo">
                <div className="d-md-block">
                    <a href='#' id='voltar-topo' aria-label='voltar-topo'>
                        <DoubleArrowOutlinedIcon className="iconUp" />
                    </a>
                </div>
            </div>
        )
    }
}

export default (BotaoVoltarAoTopo);