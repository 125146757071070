import React, { Component } from 'react';

export class TextoInicialLogin extends Component {

    render() {
        return (
            <div className='textoInicial'>
                <div>
                    <span className='textInicio'>Nosso serviço de impressão de bilhete de passagens:</span>
                </div>
                <div>
                    <span className='textInicio'>Rápido e Fácil: Em poucos cliques, você pode imprimir seu bilhete de passagem e estar pronto para embarcar.</span>
                </div>
                <div>
                    <span className='textInicio'>Conveniente: Não precisa se deslocar até uma bilheteria. <br />
                        Imprima seus Bilhetes no conforto da sua casa ou escritório.
                    </span>
                </div>
                <div>
                    <span className='textInicio'>Economize tempo: Evite longas filas e atrasos. <br />
                        Com nosso serviço, você ganha tempo para aproveitar mais sua viagem.
                    </span>
                </div>
            </div>
        )
    }
}

export default TextoInicialLogin;