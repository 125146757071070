import React, { Component } from 'react';
import PaginaGeracaoBilhete from './PaginaGeracaoBilhete';
import {withRouter} from 'react-router-dom';

class BilheteNaMao extends Component {

    render() {
        return (
            <div className='bilhete-na-mao'>
                <PaginaGeracaoBilhete />
            </div>
        )
    }
}

export default withRouter(BilheteNaMao);