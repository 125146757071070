import dadosAgenciaComum from './dadosAgenciaComum';

export const dadosPassagemVirtual = {
    ...dadosAgenciaComum,
    id: 'passagemvirtual',
    nome: 'Passagem Virtual',
    instagram: 'instagram.com',
    facebook: 'facebook.com',
    youtube: 'youtube.com',
    email: 'suportevexado@gmail.com',
    idsTagManager: ['GTM-NHVKV45']
};
