import React from 'react'
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

const ConteudoQuemSomosVX = () => (
  <div className="page-contato" style={{ fontSize: 16, backgroundColor: '#ffffff' }}>
    <section className="gv-section">
      <div className="section-content">
        <h2 className="gv-title"><b>TRANSFORMAÇÃO DIGITAL NO SISTEMA DE TRANSPORTE DE PASSAGEIROS</b></h2>
        <p className="txt-item">Venha conosco e faça sua empresa voar!</p>
        <p className="txt-item">Desenvolvemos e implementamos soluções em tecnologia personalizadas que agregam valor ao seu negócio e potencializam resultados.</p>
        <p className="txt-item">Nós do Vexado Sistemas, preparamos empresas para os desafios do presente e do futuro, direcionando-as à Transformação Digital, eficiência, redução de custos, agilidade e humanização dos processos, pensando sempre na melhor experiência do cliente.</p>
        <p className="txt-item">Trabalhamos para evoluir a sua empresa de dentro para fora, de forma rápida, consistente e compatível com as mudanças cada vez mais aceleradas do nosso tempo. Seu desenvolvimento, nossa Meta.</p>
        <h2 className="gv-title" style={{ marginTop: 24 }}><b>Nosso propósito: Crescimento humano com tecnologia</b></h2>
        <p className="txt-item">Os valores do Vexado são a expressão do comportamento esperado das pessoas, em especial da liderança.</p>
        <p className="txt-item">Uma parte se reflete no DNA que iniciou a Meta, e a outra parte na construção da trajetória: o indivíduo constrói a empresa, mas a experiência também forja a personalidade do indivíduo.</p>
        <br></br>
        <p className="txt-item">Entendemos que toda entrega, projeto ou relação, é uma oportunidade de crescimento.</p>
        <p className="txt-item">Sempre que nos é apresentado um desafio empresarial, nos deparamos com uma oportunidade de desenvolvimento pessoal e coletivo, pois a empresa só cresce quando as pessoas também crescem!</p>
        <br></br>
        <p className="txt-item">Este sentido de responsabilidade pela própria jornada, que chamamos de “atitude de dono do negócio”, está sendo construído na essência de todos os nossos colaboradores, sem distinção de posição.</p>
        <br></br>
        <p className="txt-item">A empresa acelera a sua velocidade quando todos assumem o seu posto único de protagonista da jornada e, dessa maneira, todos ganham.</p>
        <br></br>
        <p className="txt-item">Acreditar no talento das pessoas, e na oportunidade de proporcionar um ambiente diferenciado para desenvolver seus potenciais, é aquilo que acende o nosso propósito.</p>
        <p className="txt-item"></p>

        <h2 className="gv-title" style={{ marginTop: 24 }}><b>Nossos valores</b></h2>
        <p className="txt-item">São o combustível diário para a dedicação do nosso time.</p>
        <p className="txt-item">Eles, juntos, formam a essência do Vexado.</p>
        <br></br>
        <p className="txt-item"><b>SOMOS PESSOAS SERVINDO PESSOAS</b></p>
        <p className="txt-item"><b>PENSAMOS E AGIMOS COMO DONOS</b></p>
        <p className="txt-item"><b>BUSCAMOS A EXCELÊNCIA E A SIMPLICIDADE</b></p>
        <p className="txt-item"><b>TEMOS GANA POR PERFORMANCE</b></p>
        <p className="txt-item"><b>CRESCEMOS E APRENDEMOS JUNTOS</b></p>
        <p className="txt-item"><b>INSPIRAMOS, INCENTIVAMOS E CELEBRAMOS A CULTURA DE INOVAÇÃO</b></p>
        <br></br>
        <p className="txt-item">O VEXADO implementa e desenvolve soluções personalizadas, não importa o tamanho,</p>
        <p className="txt-item">o segmento ou o modelo de negócio. Independentemente do que as empresas precisam, nós temos a solução.</p>


        <section className="gv-cards" style={{ marginTop: 50 }}>
          <div className="gv-cards-wrapper">
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <DevicesOutlinedIcon className="box-item-icon"></DevicesOutlinedIcon>
                  <p>Tecnologia</p>
                  <div className="divider" />
                </span>
                <span className="box-item-card-info">
                  <p>Descomplicamos o jeito antigo complicado de comprar uma passagem!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <EmojiObjectsOutlinedIcon className="box-item-icon"></EmojiObjectsOutlinedIcon>
                  <p>Inovação</p>
                  <div className="divider" />
                </span>
                <span className="box-item-card-info">
                  <p>Inovamos em um mercado promissor, tecnologia simples, aplicada e segura para você, sua família e amigos.</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <PeopleAltOutlinedIcon className="box-item-icon"></PeopleAltOutlinedIcon>
                  <p>Relacionamento</p>
                  <div className="divider" />
                </span>
                <span className="box-item-card-info">
                  <p>Pensamos em você muito mais que um simples cliente, temos você como parte de nossa empresa, sem você não teríamos motivos de estar aqui!</p>
                </span>
              </div>
            </div>
            <div className="gv-card gv-card-qs">
              <div className="nome-card">
                <span className="box-item-card-title">
                  <TrendingUpOutlinedIcon className="box-item-icon"></TrendingUpOutlinedIcon>
                  <p>Agilidade</p>
                  <div className="divider" />
                </span>
                <span className="box-item-card-info">
                  <p>Rápido e descomplicado, clicou, comprou, viajou.</p>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
)

export default ConteudoQuemSomosVX

