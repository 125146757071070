import React, { Component } from 'react'

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { recuperarConfEmpresa } from '../util/applicationContext';

const url = window.location.protocol + '//' + window.location.host;
const logo = url + recuperarConfEmpresa().logo

const ldJson = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    'name': recuperarConfEmpresa().nome,
    'url': url,
    'description': recuperarConfEmpresa().nome + ' - Conectando pessoas à lugares! Compre passagens de forma rápida, segura e com descontos exclusivos!',
    'foundingDate': '1984-01-01',
    'founders': ['João'],
    'foundingLocation': 'Manaus - AM',
    'logo': logo,
    'contactPoint': [{
        '@type': 'ContactPoint',
        'url': url,
        'telephone': recuperarConfEmpresa().telefone,
        'email': recuperarConfEmpresa().email,
        'contactType': 'customer service',
        'description': 'Vendas e Atendimento ao Cliente',
    }],
};

export class Head extends Component {



    render() {
        const anoAtual = new Date().getFullYear();
        let confEmpresa = recuperarConfEmpresa();
        return <Helmet>
            <title>{recuperarConfEmpresa().nome}</title>
            <link id="favicon" rel="shortcut icon" href={url + recuperarConfEmpresa().icone} />
            {/*<meta name='og:type' content='webSite' />*/}
            {/*<meta property="og:title" content={confEmpresa.nome} />*/}
            {/*<meta property="og:image" content={confEmpresa.OgImage} />*/}
            {/*<meta property="og:site_name" content={`${confEmpresa.nome} - A melhor escolha para passagens de ônibus`} />*/}
            {/*<meta property="og:url" content={window.location.href} />*/}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content={confEmpresa.twitter} />
            <meta property="twitter:image" content={confEmpresa.OgImage} />
            <meta name="facebook:card" content="summary" />
            <meta name="facebook:site" content={confEmpresa.facebook} />
            <meta property="facebook:image" content={confEmpresa.OgImage} />
            <meta name="robots" content="index, follow" />
            <meta name="Language" content="Portuguese" />
            <meta name="classification" content="Online Store" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=6, minimum-scale=1" />
            <meta name="MobileOptimized" content="480" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="classification" content="Online Store" />
            <meta name="rating" content="General" />
            <meta name="abstract" content="Loja Virtual" />
            <meta name="country" content="Brazil" />
            <meta name="city" content="Brasília" />
            <meta name="distribution" content="Global" />
            <meta name="Revisit-After" content="1 day" />
            <meta name="Publisher" content={`${confEmpresa.nome}, Loja Virtual de passagens de ônibus`} />
            <meta name="Designer" content={confEmpresa.nome} />
            <meta name="copyright" content={`© ${anoAtual} Copyright - Todos os direitos reservados. ${confEmpresa.nome}`} />
            <meta name="subject" content="Retail" />
            <meta name="DC.Creator" content={confEmpresa.nome} />
            <link rel="canonical" href={url} />
            <script type="application/ld+json">
                {JSON.stringify(ldJson)}
            </script>
            {/*{this.metaDescription(origem, destino, 'description')}*/}
            {/*{this.metaDescription(origem, destino, 'twitter:description')}*/}
            {/*{this.metaDescription(origem, destino, 'facebook:description')}*/}
            {/*{this.metaTitle(origem, destino, 'og:title')}*/}
            {/*{this.metaTitle(origem, destino, 'twitter:title')}*/}
            {/*{this.metaTitle(origem, destino, 'facebook:title')}*/}
        </Helmet>
    }
}


export const mapStateToProps = state => (
    {
        venda: state.venda,
    });

export default withRouter(connect(mapStateToProps, {})(Head))
