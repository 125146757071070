const brasileiro = [

    {link: '/passagem-de-onibus/itambe-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/itambe-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/ranchoalegre-ba/aimoreziho-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/itagimirim-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/potiragua-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/itapetinga-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/itambe-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/maiquinique-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/potiragua-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/gurupamirimba-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/gurupamirimba-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/medeirosneto-ba/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/camacan-ba/medeirosneto-ba'},
    {link: '/passagem-de-onibus/barrolandia-ba/embaubaba-ba'},
    {link: '/passagem-de-onibus/barrolandia-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/barrolandia-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/barrolandia-ba/pontocentral-ba'},
    {link: '/passagem-de-onibus/caraiva-ba/barravelha-caraiva-ba'},
    {link: '/passagem-de-onibus/caraiva-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/caraiva-ba/montepascoal-ba'},
    {link: '/passagem-de-onibus/argolo-ba/serradosaimores-mg'},
    {link: '/passagem-de-onibus/argolo-ba/itabata-ba'},
    {link: '/passagem-de-onibus/belmonte-ba/belmonte-ba'},
    {link: '/passagem-de-onibus/belmonte-ba/barrolandia-ba'},
    {link: '/passagem-de-onibus/belmonte-ba/guaiu-ba'},
    {link: '/passagem-de-onibus/belmonte-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/belmonte-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/belmonte-ba/santacruzcabralia-ba'},
    {link: '/passagem-de-onibus/belmonte-ba/santoandre,ba-ba'},
    {link: '/passagem-de-onibus/camacan-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/camacan-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/camacan-ba/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/argolo-ba/aimoreziho-mg'},
    {link: '/passagem-de-onibus/argolo-ba/belavista-ba-ba'},
    {link: '/passagem-de-onibus/argolo-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/argolo-ba/nanuque-mg'},
    {link: '/passagem-de-onibus/argolo-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/argolo-ba/ranchoalegre-ba'},
    {link: '/passagem-de-onibus/argolo-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/itapetinga-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/gurupamirimba-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/medeirosneto-ba'},
    {link: '/passagem-de-onibus/saojoaodoparaiso,mascote-ba-ba/bocadocorrego-ba'},
    {link: '/passagem-de-onibus/saojoaodoparaiso,mascote-ba-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/saojoaodoparaiso,mascote-ba-ba/nanuque-mg'},
    {link: '/passagem-de-onibus/itambe-ba/potiragua-ba'},
    {link: '/passagem-de-onibus/itambe-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/itambe-ba/itabela-ba'},
    {link: '/passagem-de-onibus/itambe-ba/gurupamirimba-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/arraialdajuda-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/eunapolis-ba/belmonte-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/barreiro-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/barrolandia-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/barravelha-caraiva-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/coroavermelha-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/caraiva-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/embaubaba-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/itambe-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/itarantim-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/santacruzcabralia-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/itabela-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/itapetinga-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/santacruzcabralia-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/santamariaeterna-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/itabata-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/teixeiradoprogresso-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/trancoso-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/vargito-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/pontocentral-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/potiragua-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/prado-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/ranchoalegre-ba'},
    {link: '/passagem-de-onibus/caraiva-ba/itabela-ba'},
    {link: '/passagem-de-onibus/caravelas-ba/canabrava-ba-ba'},
    {link: '/passagem-de-onibus/caravelas-ba/alcobaca-ba'},
    {link: '/passagem-de-onibus/caravelas-ba/cascata-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/guaratinga-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/coroavermelha-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/guaratinga-ba/itambe-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/itapebi-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/itarantim-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/alcobaca-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/maiquinique-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/eunapolis-ba/montepascoal-ba'},
    {link: '/passagem-de-onibus/itambe-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/itambe-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/itambe-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/itambe-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/itambe-ba/itarantim-ba'},
    {link: '/passagem-de-onibus/itambe-ba/maiquinique-ba'},
    {link: '/passagem-de-onibus/itambe-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/itambe-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/saojoaodoparaiso,mascote-ba-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/saojoaodoparaiso,mascote-ba-ba/santamariaeterna-ba'},
    {link: '/passagem-de-onibus/saojoaodoparaiso,mascote-ba-ba/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/saojoaodoparaiso,mascote-ba-ba/teixeiradoprogresso-ba'},
    {link: '/passagem-de-onibus/saojoaodoparaiso,mascote-ba-ba/medeirosneto-ba'},
    {link: '/passagem-de-onibus/ilheus-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/portoseguro-ba/barreiro-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/itabela-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/medeirosneto-ba'},
    {link: '/passagem-de-onibus/alcobaca-ba/canabrava-ba-ba'},
    {link: '/passagem-de-onibus/alcobaca-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/alcobaca-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/alcobaca-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/alcobaca-ba/prado-ba'},
    {link: '/passagem-de-onibus/alcobaca-ba/cascata-ba'},
    {link: '/passagem-de-onibus/alcobaca-ba/itabela-ba'},
    {link: '/passagem-de-onibus/mucuri-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/itapebi-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/itapebi-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/itapebi-ba/santamariaeterna-ba'},
    {link: '/passagem-de-onibus/itanhem-ba/patioba-ba'},
    {link: '/passagem-de-onibus/itanhem-ba/novalidice-ba'},
    {link: '/passagem-de-onibus/itanhem-ba/medeirosneto-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/barreiro-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/saojoaodoparaiso,mascote-ba-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/nanuque-mg'},
    {link: '/passagem-de-onibus/canavieiras-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/teixeiradoprogresso-ba'},
    {link: '/passagem-de-onibus/canavieiras-ba/vargito-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/itabata-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/teixeiradoprogresso-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/vargito-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/itabela-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/itapetinga-ba'},
    {link: '/passagem-de-onibus/itapebi-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/potiragua-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/prado-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/ranchoalegre-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/itambe-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/portoseguro-ba/alcobaca-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/itabela-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/gurupamirimba-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/argolo-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/aimoreziho-mg'},
    {link: '/passagem-de-onibus/nanuque-mg/saojoaodoparaiso,mascote-ba-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/belavista-ba-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/canavieiras-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/mucuri-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/duasbarras-ba-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/itambe-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/jade-ba-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/jucurucu-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/alcobaca-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/novaalegria-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/potiragua-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/periclesvalmorba-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/prado-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/ranchoalegre-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/serradosaimores-mg'},
    {link: '/passagem-de-onibus/novavicosa-ba/itabata-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/itabela-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/antoniodepaula-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/canabrava-ba-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/coqueiro-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/cumuruxatiba-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/cumuruxatiba-ba'},
    {link: '/passagem-de-onibus/mucuri-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/mucuri-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/mucuri-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/mucuri-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/mucuri-ba/itabata-ba'},
    {link: '/passagem-de-onibus/mucuri-ba/itabela-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/vitoriadaconquista-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/medeirosneto-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/argolo-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/aimoreziho-mg'},
    {link: '/passagem-de-onibus/novavicosa-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/belavista-ba-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/novavicosa-ba/ranchoalegre-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/santoamaro-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/itabata-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/cascata-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/sudeneba-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/itapetinga-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/medeirosneto-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/ranchoalegre-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/serradosaimores-mg'},
    {link: '/passagem-de-onibus/nanuque-mg/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/nanuque-mg/itabata-ba'},
    {link: '/passagem-de-onibus/santacruzcabralia-ba/coroavermelha-ba'},
    {link: '/passagem-de-onibus/santacruzcabralia-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/santacruzcabralia-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/santacruzcabralia-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/santacruzcabralia-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/santacruzcabralia-ba/itabela-ba'},
    {link: '/passagem-de-onibus/itabata-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/itabata-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/itabata-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/itabata-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/ranchoalegre-ba/belavista-ba-ba'},
    {link: '/passagem-de-onibus/ranchoalegre-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/ranchoalegre-ba/nanuque-mg'},
    {link: '/passagem-de-onibus/ranchoalegre-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/ranchoalegre-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/ranchoalegre-ba/itabata-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/camacan-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/saojoaodoparaiso,mascote-ba-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/ilheus-ba'},
    {link: '/passagem-de-onibus/itamaraju-ba/guarani-ba-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/itambe-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/itarantim-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/maiquinique-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/itabela-ba'},
    {link: '/passagem-de-onibus/prado-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/prado-ba/cascata-ba'},
    {link: '/passagem-de-onibus/prado-ba/itabela-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/itambe-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/santamariaeterna-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/itapetinga-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/gurupamirimba-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/medeirosneto-ba'},
    {link: '/passagem-de-onibus/ranchoalegre-ba/argolo-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/itapebi-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/itarantim-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/maiquinique-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/itagimirim-ba/potiragua-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/itabata-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/itabuna-ba'},
    {link: '/passagem-de-onibus/postodamata-ba/itabela-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/itapetinga-ba'},
    {link: '/passagem-de-onibus/potiragua-ba/gurupamirimba-ba'},
    {link: '/passagem-de-onibus/prado-ba/canabrava-ba-ba'},
    {link: '/passagem-de-onibus/prado-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/prado-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/prado-ba/alcobaca-ba'},
    {link: '/passagem-de-onibus/itabata-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/itabata-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/itabata-ba/itabela-ba'},
    {link: '/passagem-de-onibus/itabuna-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/trancoso-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/itabela-ba/santoantoniodojacinto-mg'},
    {link: '/passagem-de-onibus/itabela-ba/buranhem-ba'},
    {link: '/passagem-de-onibus/itabela-ba/escadinha-ba'},
    {link: '/passagem-de-onibus/itabela-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/itabela-ba/itambe-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/potiragua-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/itabela-ba'},
    {link: '/passagem-de-onibus/medeirosneto-ba/camacan-ba'},
    {link: '/passagem-de-onibus/medeirosneto-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/itabela-ba/saojoaodosul-ba'},
    {link: '/passagem-de-onibus/itabela-ba/itabata-ba'},
    {link: '/passagem-de-onibus/itabela-ba/itapetinga-ba'},
    {link: '/passagem-de-onibus/itabela-ba/gurupamirimba-ba'},
    {link: '/passagem-de-onibus/itabela-ba/medeirosneto-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/guaratinga-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/eunapolis-ba'},
    {link: '/passagem-de-onibus/itapetinga-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/itabela-ba/vitoriadaconquista-ba'},
    {link: '/passagem-de-onibus/itabela-ba/novavicosa-ba'},
    {link: '/passagem-de-onibus/itabela-ba/potiragua-ba'},
    {link: '/passagem-de-onibus/itabela-ba/prado-ba'},
    {link: '/passagem-de-onibus/itabela-ba/ranchoalegre-ba'},
    {link: '/passagem-de-onibus/itabela-ba/postodamata-ba'},
    {link: '/passagem-de-onibus/itabela-ba/santaluzia,ba-ba'},
    {link: '/passagem-de-onibus/itabela-ba/portoseguro-ba'},
    {link: '/passagem-de-onibus/itabela-ba/itarantim-ba'},
    {link: '/passagem-de-onibus/itabela-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/itabela-ba/alcobaca-ba'},
    {link: '/passagem-de-onibus/itabela-ba/maiquinique-ba'},
    {link: '/passagem-de-onibus/itabela-ba/mundonovo,ba-ba'},
    {link: '/passagem-de-onibus/itabela-ba/mucuri-ba'},
    {link: '/passagem-de-onibus/medeirosneto-ba/saojoaodoparaiso,mascote-ba-ba'},
    {link: '/passagem-de-onibus/medeirosneto-ba/canavieiras-ba'},
    {link: '/passagem-de-onibus/medeirosneto-ba/nanuque-mg'},
    {link: '/passagem-de-onibus/medeirosneto-ba/itamaraju-ba'},
    {link: '/passagem-de-onibus/medeirosneto-ba/itagimirim-ba'},
    {link: '/passagem-de-onibus/medeirosneto-ba/itabela-ba'},

    {link: '/passagem-de-onibus/juizdefora-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/bicas-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/bicas-mg/niteroi-rj'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/bicas-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/matiasbarbosa-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/mardeespanha-mg'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/saojoaonepomuceno-mg'},
    {link: '/passagem-de-onibus/bicas-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saojoaonepomuceno-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/saojoaonepomuceno-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/saojoaonepomuceno-mg/niteroi-rj'},
    {link: '/passagem-de-onibus/matiasbarbosa-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/mardeespanha-mg/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/barbacena-mg/niteroi-rj'},
    {link: '/passagem-de-onibus/petropolis-rj/bicas-mg'},
    {link: '/passagem-de-onibus/petropolis-rj/saojoaonepomuceno-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/niteroi-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/niteroi-rj'},
    {link: '/passagem-de-onibus/niteroi-rj/bicas-mg'},
    {link: '/passagem-de-onibus/niteroi-rj/barbacena-mg'},
    {link: '/passagem-de-onibus/niteroi-rj/saojoaonepomuceno-mg'},
    {link: '/passagem-de-onibus/niteroi-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/niteroi-rj/juizdefora-mg'},

    {link: '/passagem-de-onibus/petropolis-rj/barradatijuca-rj'},
    {link: '/passagem-de-onibus/petropolis-rj/cabofrio-rj'},
    {link: '/passagem-de-onibus/petropolis-rj/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/petropolis-rj/matiasbarbosa-mg'},
    {link: '/passagem-de-onibus/petropolis-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/juizdefora-mg/areal-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/cabofrio-rj'},
    {link: '/passagem-de-onibus/juizdefora-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/petropolis-rj'},
    {link: '/passagem-de-onibus/matiasbarbosa-mg/petropolis-rj'},
    {link: '/passagem-de-onibus/petropolis-rj/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/petropolis-rj/riodejaneiro-rj'},
    {link: '/passagem-de-onibus/cabofrio-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/santacruzdaserra-rj/barradatijuca-rj'},
    {link: '/passagem-de-onibus/duquedecaxias-rj/petropolis-rj'},
    {link: '/passagem-de-onibus/riodejaneiro-rj/petropolis-rj'},
    {link: '/passagem-de-onibus/areal-rj/juizdefora-mg'},
    {link: '/passagem-de-onibus/barradatijuca-rj/santacruzdaserra-rj'},
    {link: '/passagem-de-onibus/barradatijuca-rj/petropolis-rj'},
    {link: '/passagem-de-onibus/cabofrio-rj/petropolis-rj'},

    {link: '/passagem-de-onibus/humbertodecampos-ma/morros-ma'},
    {link: '/passagem-de-onibus/humbertodecampos-ma/bacabeira-ma'},
    {link: '/passagem-de-onibus/bacabeira-ma/morros-ma'},
    {link: '/passagem-de-onibus/bacabeira-ma/matoes-ma'},
    {link: '/passagem-de-onibus/bacabeira-ma/urbanosantos-ma'},
    {link: '/passagem-de-onibus/tutoia-ma/matoes-ma'},
    {link: '/passagem-de-onibus/bacabeira-ma/barreirinhas-ma'},
    {link: '/passagem-de-onibus/bacabeira-ma/humbertodecampos-ma'},
    {link: '/passagem-de-onibus/santarita-ma/urbanosantos-ma'},
    {link: '/passagem-de-onibus/matoes-ma/saoluis-ma'},
    {link: '/passagem-de-onibus/paulinoneves-ma/morros-ma'},
    {link: '/passagem-de-onibus/paulinoneves-ma/matoes-ma'},
    {link: '/passagem-de-onibus/matoes-ma/barreirinhas-ma'},
    {link: '/passagem-de-onibus/matoes-ma/morros-ma'},
    {link: '/passagem-de-onibus/matoes-ma/paulinoneves-ma'},
    {link: '/passagem-de-onibus/matoes-ma/tutoia-ma'},
    {link: '/passagem-de-onibus/matoes-ma/bacabeira-ma'},
    {link: '/passagem-de-onibus/saoluis-ma/morros-ma'},
    {link: '/passagem-de-onibus/saoluis-ma/matoes-ma'},
    {link: '/passagem-de-onibus/santarita-ma/vargemgrande-ma'},
    {link: '/passagem-de-onibus/tutoia-ma/morros-ma'},
    {link: '/passagem-de-onibus/barreirinhas-ma/morros-ma'},
    {link: '/passagem-de-onibus/barreirinhas-ma/matoes-ma'},
    {link: '/passagem-de-onibus/barreirinhas-ma/bacabeira-ma'},
    {link: '/passagem-de-onibus/vargemgrande-ma/santarita-ma'},
    {link: '/passagem-de-onibus/urbanosantos-ma/santarita-ma'},
    {link: '/passagem-de-onibus/urbanosantos-ma/bacabeira-ma'},
    {link: '/passagem-de-onibus/vargemgrande-ma/urbanosantos-ma'},
    {link: '/passagem-de-onibus/saoluis-ma/barreirinhas-ma'},
    {link: '/passagem-de-onibus/saoluis-ma/humbertodecampos-ma'},
    {link: '/passagem-de-onibus/saoluis-ma/urbanosantos-ma'},
    {link: '/passagem-de-onibus/tutoia-ma/barreirinhas-ma'},
    {link: '/passagem-de-onibus/urbanosantos-ma/vargemgrande-ma'},
    {link: '/passagem-de-onibus/urbanosantos-ma/saoluis-ma'},
    {link: '/passagem-de-onibus/barreirinhas-ma/saoluis-ma'},
    {link: '/passagem-de-onibus/humbertodecampos-ma/saoluis-ma'},
    {link: '/passagem-de-onibus/paulinoneves-ma/barreirinhas-ma'},
    {link: '/passagem-de-onibus/barreirinhas-ma/paulinoneves-ma'},
    {link: '/passagem-de-onibus/barreirinhas-ma/tutoia-ma'},

];
export default brasileiro;