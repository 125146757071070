import decode from 'jwt-decode'
import {recuperarConfEmpresa} from '../util/applicationContext';

const authTokenKey = 'authTokenKey_';
const vendaPendente = 'vendaPendente';

export const PERFIL_ADMIN = '1';
export const PREFIXO = '_';


export const saveSecurityToken = (authentication) => {
  try {
    localStorage.setItem(authTokenKey+recuperarConfEmpresa().id, JSON.stringify(authentication));
    return authentication
  } catch (err) {
    console.log(err);
    throw err
  }
};


export const saveVendaPendenteLocalStorage = (venda) => {
  try {
    localStorage.setItem(vendaPendente, JSON.stringify(venda));
  } catch (err) {
    console.log(err);
    throw err
  }
};

export const getVendaPendente = () => {
  try {
    const item = localStorage.getItem(vendaPendente);
    return item ? JSON.parse(item) : item
  } catch (err) {
    console.log(err);
    throw err
  }
};
export const removeSecurityToken = () => {
  try {
    localStorage.removeItem(authTokenKey+recuperarConfEmpresa().id);
    return authTokenKey
  } catch (err) {
    console.log(err);
    throw err
  }
};

export const getAuthentication = () => {
  try {
    const item = localStorage.getItem(authTokenKey+recuperarConfEmpresa().id);
    return item ? JSON.parse(item) : item
  } catch (err) {
    console.log(err);
    throw err
  }
};


export const getRolesUsuario = () => {
  const authentication = getAuthentication();

  if (authentication) {
    const { tokenJwt } = getAuthentication();

    let perfis = [];
    if (tokenJwt) {
      perfis = decode(tokenJwt).roles
    }

    return perfis
  }
  return []
};

export const getPerfisUsuario = () => getRolesUsuario().map(role => role);
export const hasPerfil = perfil => getPerfisUsuario().includes(perfil);
