const amatur = [

    {link: '/passagem-de-onibus/humaita-am/careiro-am'},
    {link: '/passagem-de-onibus/portovelho-ro/careiro-am'},
    {link: '/passagem-de-onibus/portovelho-ro/igapoacu-am'},
    {link: '/passagem-de-onibus/humaita-am/igapoacu-am'},
    {link: '/passagem-de-onibus/humaita-am/realidade-am'},
    {link: '/passagem-de-onibus/igapoacu-am/portovelho-ro'},
    {link: '/passagem-de-onibus/igapoacu-am/humaita-am'},
    {link: '/passagem-de-onibus/careiro-am/igapoacu-am'},
    {link: '/passagem-de-onibus/careiro-am/realidade-am'},
    {link: '/passagem-de-onibus/careiro-am/humaita-am'},
    {link: '/passagem-de-onibus/realidade-am/humaita-am'},
    {link: '/passagem-de-onibus/portovelho-ro/realidade-am'},
    {link: '/passagem-de-onibus/ji-parana-ro/apui-am'},
    {link: '/passagem-de-onibus/igapoacu-am/careiro-am'},
    {link: '/passagem-de-onibus/igapoacu-am/realidade-am'},
    {link: '/passagem-de-onibus/ouropretodooeste-ro/apui-am'},
    {link: '/passagem-de-onibus/ouropretodooeste-ro/humaita-am'},
    {link: '/passagem-de-onibus/realidade-am/careiro-am'},
    {link: '/passagem-de-onibus/realidade-am/igapoacu-am'},
    {link: '/passagem-de-onibus/realidade-am/portovelho-ro'},
    {link: '/passagem-de-onibus/apui-am/ji-parana-ro'},
    {link: '/passagem-de-onibus/apui-am/ariquemes-ro'},
    {link: '/passagem-de-onibus/apui-am/humaita-am'},
    {link: '/passagem-de-onibus/candeiasdojamari-ro/apui-am'},
    {link: '/passagem-de-onibus/jaru-ro/apui-am'},
    {link: '/passagem-de-onibus/jaru-ro/humaita-am'},
    {link: '/passagem-de-onibus/apui-am/jaru-ro'},
    {link: '/passagem-de-onibus/vistaalegredoabuna-ro/riobranco-ac'},
    {link: '/passagem-de-onibus/itapuadooeste-ro/apui-am'},
    {link: '/passagem-de-onibus/itapuadooeste-ro/humaita-am'},
    {link: '/passagem-de-onibus/acrelandia-ac/riobranco-ac'},
    {link: '/passagem-de-onibus/apui-am/candeiasdojamari-ro'},
    {link: '/passagem-de-onibus/apui-am/ouropretodooeste-ro'},
    {link: '/passagem-de-onibus/apui-am/portovelho-ro'},
    {link: '/passagem-de-onibus/vistaalegredoabuna-ro/novacalifornia-ro-ro'},
    {link: '/passagem-de-onibus/humaita-am/jaru-ro'},
    {link: '/passagem-de-onibus/ariquemes-ro/apui-am'},
    {link: '/passagem-de-onibus/ariquemes-ro/humaita-am'},
    {link: '/passagem-de-onibus/normandia-rr/boavista-rr'},
    {link: '/passagem-de-onibus/manaus-am/realidade-am'},
    {link: '/passagem-de-onibus/novacalifornia-ro-ro/acrelandia-ac'},
    {link: '/passagem-de-onibus/ji-parana-ro/humaita-am'},
    {link: '/passagem-de-onibus/igapoacu-am/manaus-am'},
    {link: '/passagem-de-onibus/manaus-am/humaita-am'},
    {link: '/passagem-de-onibus/apui-am/itapuadooeste-ro'},
    {link: '/passagem-de-onibus/candeiasdojamari-ro/humaita-am'},
    {link: '/passagem-de-onibus/boavista-rr/normandia-rr'},
    {link: '/passagem-de-onibus/humaita-am/ouropretodooeste-ro'},
    {link: '/passagem-de-onibus/humaita-am/manaus-am'},
    {link: '/passagem-de-onibus/humaita-am/itapuadooeste-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/apui-am'},
    {link: '/passagem-de-onibus/extrema,ro-ro/novacalifornia-ro-ro'},
    {link: '/passagem-de-onibus/acrelandia-ac/portovelho-ro'},
    {link: '/passagem-de-onibus/humaita-am/ji-parana-ro'},
    {link: '/passagem-de-onibus/realidade-am/manaus-am'},
    {link: '/passagem-de-onibus/riobranco-ac/acrelandia-ac'},
    {link: '/passagem-de-onibus/riobranco-ac/extrema,ro-ro'},
    {link: '/passagem-de-onibus/riobranco-ac/jaciparana-ro'},
    {link: '/passagem-de-onibus/riobranco-ac/novacalifornia-ro-ro'},
    {link: '/passagem-de-onibus/riobranco-ac/portovelho-ro'},
    {link: '/passagem-de-onibus/riobranco-ac/vistaalegredoabuna-ro'},
    {link: '/passagem-de-onibus/novacalifornia-ro-ro/jaciparana-ro'},
    {link: '/passagem-de-onibus/novacalifornia-ro-ro/portovelho-ro'},
    {link: '/passagem-de-onibus/novacalifornia-ro-ro/riobranco-ac'},
    {link: '/passagem-de-onibus/novacalifornia-ro-ro/vistaalegredoabuna-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/acrelandia-ac'},
    {link: '/passagem-de-onibus/jaciparana-ro/acrelandia-ac'},
    {link: '/passagem-de-onibus/jaciparana-ro/extrema,ro-ro'},
    {link: '/passagem-de-onibus/jaciparana-ro/novacalifornia-ro-ro'},
    {link: '/passagem-de-onibus/jaciparana-ro/portovelho-ro'},
    {link: '/passagem-de-onibus/jaciparana-ro/riobranco-ac'},
    {link: '/passagem-de-onibus/jaciparana-ro/vistaalegredoabuna-ro'},
    {link: '/passagem-de-onibus/labrea-am/portovelho-ro'},
    {link: '/passagem-de-onibus/novamamore-ro/portovelho-ro'},
    {link: '/passagem-de-onibus/novacalifornia-ro-ro/extrema,ro-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/extrema,ro-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/guajara-mirim-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/jaciparana-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/labrea-am'},
    {link: '/passagem-de-onibus/portovelho-ro/novamamore-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/novacalifornia-ro-ro'},
    {link: '/passagem-de-onibus/portovelho-ro/riobranco-ac'},
    {link: '/passagem-de-onibus/portovelho-ro/vistaalegredoabuna-ro'},
    {link: '/passagem-de-onibus/careiro-am/manaus-am'},
    {link: '/passagem-de-onibus/extrema,ro-ro/acrelandia-ac'},
    {link: '/passagem-de-onibus/extrema,ro-ro/jaciparana-ro'},
    {link: '/passagem-de-onibus/extrema,ro-ro/portovelho-ro'},
    {link: '/passagem-de-onibus/extrema,ro-ro/riobranco-ac'},
    {link: '/passagem-de-onibus/extrema,ro-ro/vistaalegredoabuna-ro'},
    {link: '/passagem-de-onibus/guajara-mirim-ro/portovelho-ro'},
    {link: '/passagem-de-onibus/acrelandia-ac/extrema,ro-ro'},
    {link: '/passagem-de-onibus/acrelandia-ac/jaciparana-ro'},
    {link: '/passagem-de-onibus/acrelandia-ac/novacalifornia-ro-ro'},
    {link: '/passagem-de-onibus/acrelandia-ac/vistaalegredoabuna-ro'},
    {link: '/passagem-de-onibus/vistaalegredoabuna-ro/acrelandia-ac'},
    {link: '/passagem-de-onibus/vistaalegredoabuna-ro/extrema,ro-ro'},
    {link: '/passagem-de-onibus/vistaalegredoabuna-ro/jaciparana-ro'},
    {link: '/passagem-de-onibus/vistaalegredoabuna-ro/portovelho-ro'},
    {link: '/passagem-de-onibus/humaita-am/apui-am'},
    {link: '/passagem-de-onibus/humaita-am/candeiasdojamari-ro'},
    {link: '/passagem-de-onibus/humaita-am/ariquemes-ro'},
];
export default amatur;