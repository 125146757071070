import React, { Component } from 'react'
import logoEncomendaExpress from '../../../estilo/comum/img/vexadoEncomendas/Encomenda-Express.png'
import graficoSistemaVexado from '../../../estilo/comum/img/vexadoEncomendas/7.png'
import imgGerenciamento from '../../../estilo/comum/img/vexadoEncomendas/1.png'
import imgSistemaVexado from '../../../estilo/comum/img//vexadoEncomendas/2.png'
import imgEstrategia2 from '../../../estilo/comum/img/vexadoEncomendas/8.png'
import imgGaleria1 from '../../../estilo/comum/img/vexadoEncomendas/4.png'
import imgGaleria2 from '../../../estilo/comum/img/vexadoEncomendas/5.png'
import imgGaleria3 from '../../../estilo/comum/img/vexadoEncomendas/6.png'

export class VexadoEncomendas extends Component {

    buttonSaibaMais() {
        return <a id='linkText' href='https://wa.me/5561981537600'>
            <button id='btnSaibaMais' type='button' className='btn btn-success'>SAIBA MAIS</button>
        </a>
    }

    arrow() {
        return <svg xmlns='http://www.w3.org/2000/svg' id='arrow' width='12' height='12' fill='currentColor'
            className='bi bi-caret-up-fill' viewBox='0 0 16 16'>
            <path
                d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z' />
        </svg>
    }

    componentDidMount() {
        const debounce = function (func, wait, immediate) {
            let timeout;
            return function (...args) {
                const context = this;
                const later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        const target = document.querySelectorAll('[data-anime]');
        const animationClass = 'animate';

        function animeScroll() {
            const windowTop = window.pageYOffset + (window.innerHeight * 3) / 4;
            target.forEach(function (element) {
                if (windowTop > element.offsetTop) {
                    element.classList.add(animationClass);
                } else {
                    element.classList.remove(animationClass);
                }
            });
        }

        animeScroll();

        if (target.length) {
            window.addEventListener(
                'scroll',
                debounce(function () {
                    animeScroll();
                }, 200)
            );
        }
    }

    render() {
        return (
            <div id='secao-vexado-express' className='VexadoEncomendasPag'>
                <div className='container-LogoVexadoSistemas'>
                    <div className='logoVexadoSistemas'>
                        <img id='LogoSistemaVexado' className='logo' src={logoEncomendaExpress} alt='Logo SistemaVexado' />
                    </div>
                </div>
                <div id='container-first' className='bg-primary' style={{ height: '100%' }} data-anime="top">
                    <div className='row'>
                        <div id='section1' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Sistema pensado e desenvolvido para gestão 360° da operação do transporte de ponta a ponta. <br />
                            </div>
                            <div className='text'>
                                <a id='linkText' href="https://www.instagram.com/vexadoo/">O Vexado</a> é uma empresa especializada em sistemas prestação de serviços de transporte de
                                passageiros, Cargas e encomendas. Com o objetivo de atender as demandas das empresas de
                                ônibus nas demandas de cargas e encomendas, nós desenvolvemos o VexadoExpress
                                <br />
                            </div>
                            <div>
                                {this.buttonSaibaMais()}
                            </div>
                        </div>
                        <div id='section-grafico' className='col-md-6'>
                            <div>
                                <img id='imgInicial' src={graficoSistemaVexado} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-second' className='bg-primary' style={{ height: '100%' }} data-anime="right">
                    <div className='row'>
                        <div id='section3' className='col-md-6'>
                            <div>
                                <img id='imgGerenciamento' src={imgGerenciamento} />
                            </div>
                        </div>
                        <div id='section3' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Características
                            </div>
                            <div className='text'>
                                <a id='linkText' href="https://www.instagram.com/p/CV_bOIfPIa0/?utm_source=ig_web_copy_link">O sistema tem como característica</a> a integração a rota/itinerário gerando um resumo financeiro por viagem ou por período. Além disso, permite a criação de cadastros para remetente e destinatário; possibilidade de cobrança sobre peso e medidas ou combinar com o remetente; monitoramento de carga.
                                <br />
                            </div>
                            <div className='text'>
                                Os usuários podem visualizar informações como: valores cobrados pela empresa; valor da tarifa da carga e encomenda; quantidade de unidades transportadas; tipologia das embalagens utilizadas (caixa ou envelope).
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-third' className='bg-primary' style={{ height: '100%' }} data-anime="left">
                    <div className='row'>
                        <div id='section3' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Gestão
                            </div>
                            <div className='text'>
                                O sistema também disponibiliza relatórios específicos, por vendedor(agente), por rota, por itinerário, por cidade e com possibilidade de se adequar a suas necessidades do dia a dia.
                            </div>
                            <div className='text'>
                                Por se tratar de um sistema 100% web, temos a vantagem de poder ser acessado de qualquer um desses aparelhos: Computador de mesa, notebook, tablet ou celular.
                            </div>
                            <div>
                                {this.buttonSaibaMais()}
                            </div>
                        </div>

                        <div id='section3' className='col-md-6'>
                            <div>
                                <img id='imgAdminVexado' src={imgSistemaVexado} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-fourth' className='bg-primary' style={{ height: '100%' }} data-anime="right">
                    <div className='row'>
                        <div id='section3' className='col-md-6'>
                            <div>
                                <img id='imgEstrategia' src={imgEstrategia2} />
                            </div>
                        </div>
                        <div id='section8' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Estratégia e Soluções Digitais
                            </div>
                            <div className='text'>
                                <a id='linkText' href="https://www.instagram.com/p/CV8b8DGv1rg/?utm_source=ig_web_copy_link">Nossa preocupação</a> é dar aos usuários e gestores um controle absoluto da operação, desde o inicio de um cadastro de usuários ao fim, da entrega de uma encomenda, trazendo menos furos de pagamentos e alinhando a logística de encomendas.
                            </div>
                            <div className='text'>
                                VexadoExpress a solução que resolve.
                            </div>
                            <div>
                                {this.buttonSaibaMais()}
                            </div>

                        </div>
                    </div>
                </div>

                <div id='container-fifth' className='bg-primary' style={{ height: '100%' }} data-anime="top">
                    <div id='section7' className='col-md-6'>
                        <div id='titulo' className='text'>
                            Nossas redes sociais
                        </div>
                        <div id='wrapperSocialMedia' className='wrapper'>
                            <div className='icon facebook'>
                                <div className='tooltip'>
                                    Facebook
                                </div>
                                <span><i className='fab fa-facebook-f'></i></span>
                            </div>
                            <div className='icon twitter'>
                                <div className='tooltip'>
                                    Twitter
                                </div>
                                <span><i className='fab fa-twitter'></i></span>
                            </div>
                            <div className='icon instagram'>
                                <div className='tooltip'>
                                    Instagram
                                </div>
                                <span><i className='fab fa-instagram'></i></span>
                            </div>
                            <div className='icon whatsapp'>
                                <div className='tooltip'>
                                    WhatsApp
                                </div>
                                <span>
                                    <a href="https://wa.me/5561981537600">
                                        <i id='icon-map' className='fab fa-whatsapp'></i>
                                    </a>
                                </span>
                            </div>
                            <div className='icon youtube'>
                                <div className='tooltip'>
                                    YouTube
                                </div>
                                <span><i className='fab fa-youtube'></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-Sixth' className='bg-primary' data-anime="right">
                    <div id='titulo' className='text'>
                        Sistemas de entregas expressas
                    </div>
                    <div id='' className='text'>
                        Com o aumento da demanda por entregas rápidas, a melhoria de performance e suporte em sistemas de encomendas expresso tornou-se uma necessidade vital para as empresas que buscam fornecer serviços de qualidade aos seus clientes.
                    </div>
                    <div className='container-galery'>
                        <div id='sectionGaleria' className='galery'>
                            <div className='card-galery'>
                                <img id='imgGaleria' src={imgGaleria1} />
                                <div id='textGalery' className='text'>
                                    Entregas rápidas são uma exigência cada vez maior dos clientes, tornando a melhoria da performance e suporte em sistemas de encomendas expresso um requisito fundamental para a satisfação dos clientes e para a competitividade das empresas.
                                </div>
                                <div className='buttonGalery'>
                                    {this.buttonSaibaMais()}
                                </div>
                            </div>
                        </div>
                        <div id='sectionGaleria' className='galery'>
                            <div className='card-galery'>
                                <img id='imgGaleria' src={imgGaleria2} />
                                <div id='textGalery' className='text'>
                                    A implementação de soluções tecnológicas avançadas, como ferramentas de análise de dados e serviços de suporte especializado, pode ajudar as empresas de entregas expressas a melhorar sua performance operacional, garantindo entregas mais rápidas e eficientes.
                                </div>
                                <div className='buttonGalery'>
                                    {this.buttonSaibaMais()}
                                </div>
                            </div>
                        </div>
                        <div id='sectionGaleria' className='galery'>
                            <div className='card-galery'>
                                <img id='imgGaleria' src={imgGaleria3} />
                                <div id='textGalery' className='text'>
                                    Ao investir em melhoria de performance e suporte em sistemas de encomendas expresso, as empresas podem aumentar a confiabilidade e segurança em suas entregas, além de gerar uma melhor experiência do cliente e maior fidelização, impactando diretamente no crescimento do negócio.
                                </div>
                                <div className='buttonGalery'>
                                    {this.buttonSaibaMais()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='container-fourth' className='bg-primary' style={{ height: '100%' }} data-anime="right">
                    <div className='row'>
                        <div id='section3' className='col-md-6'>
                            <div id='titulo' className='text'>
                                Melhoria de Performance e Suporte
                            </div>
                            <div className='text'>
                                Com o aumento da demanda por entregas rápidas, a melhoria de performance e suporte em sistemas de encomendas expresso tornou-se uma necessidade vital para as empresas que buscam fornecer serviços de qualidade aos seus clientes.
                            </div>
                            <div className='text'>
                                Para melhorar a performance do sistema de encomendas expresso, é importante investir em tecnologia de ponta e infraestrutura de rede confiável. Isso inclui a utilização de softwares de última geração, equipamentos de comunicação de alta velocidade e servidores de processamento de dados eficientes.
                            </div>

                        </div>

                        <div id='section6' className='col-md-6'>
                            <div className='text'>
                                Além disso, a disponibilidade de suporte eficaz e rápido é essencial para garantir que os clientes tenham uma experiência positiva ao utilizar o serviço de encomendas expresso. Isso pode ser alcançado através de uma equipe de suporte altamente capacitada e disponível 24 horas por dia, 7 dias por semana.
                            </div>
                            <div>
                                {this.buttonSaibaMais()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (VexadoEncomendas)