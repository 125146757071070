import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {cadastrarPromocao} from '../../actions/promocoesActions'
import ReCaptchaV2 from 'react-google-recaptcha'
import {renderField, required, validarNomeCompleto} from '../../util/inputs';
import {showSuccessMsg} from '../../util/messagesUtil';
import SelecaoCidadeAutocomplete from '../comum/SelecaoCidadeAutocomplete'
import { selecionarOrigem, selecionarDestino} from '../../actions/vendasActions'

class PaginaNovoUsuario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chaveCaptcha: null,
        };
        this.recaptchaRef = React.createRef();
    }

    changeRecaptcha(value) {
        if (value) {
            this.setState({chaveCaptcha: value})
        }
    }

    limpaViagens () {
        this.props.selecionarOrigem(null)
        this.props.selecionarDestino(null)
    }
    selecionarDestino (destino) {
        this.props.selecionarDestino(destino);
    }
    
    selecionarOrigem (origem) {
        this.props.selecionarOrigem(origem);
    }

    onSubmit(values) {        
        this.props.cadastrarPromocao({cidadeOrigem: this.props.origem.id, cidadeDestino: this.props.destino.id, ...values}, this.state.chaveCaptcha).then(() => {
            showSuccessMsg('Cadastro realizado com sucesso! Em breve você receberá promoções exclusivas!', true);
            this.props.history.push('/')
        })
    }

    render() {
        const {from} = this.props.location.state || {from: {pathname: '/'}};

        if (this.props.dadosAutenticacao.isAuthenticated) {
            return (<Redirect to={from}/>)
        }

        const {
            handleSubmit, submitting, invalid, pristine,
        } = this.props;

        return (
            <div className="back-login">
                <form className="container form-login" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div className='loginHeader'>
                        <h1>Cadastre-se para receber promoções exclusivas</h1>
                        <div className="divider"/>
                    </div>
                    <Field
                        label='Nome completo'
                        name='nome'
                        type='text'
                        id='nome'
                        placeholder=''
                        validate={[required, validarNomeCompleto]}
                        component={renderField}
                    />
                    <Field
                        label='E-mail'
                        name='email'
                        type='email'
                        id='nome'
                        placeholder=''
                        validate={[required]}
                        component={renderField}
                    />
                    <Field
                        label='Telefone'
                        name='telefone'
                        id='telefone'
                        type='celular'
                        placeholder=''
                        validate={[required]}
                        component={renderField}
                    />
                    
                    <div className='cidade-recebimento-promocao'>
                        <label className='mt-2'>Cidade origem:</label>
                        <SelecaoCidadeAutocomplete 
                            name='pesquisaOrigem'                            
                            selecionarCidade={this.selecionarOrigem.bind(this)}
                            value={this.props.origem}
                            limparViagens={this.limpaViagens.bind(this)}
                            ehVenda={true}
                        />
                        <Field name="origem" type="hidden" component={renderField} css="form-control col-md-12"/>                        
                    </div>

                    <div className='cidade-recebimento-promocao'>
                        <label className='mt-2'>Cidade Destino:</label>
                        <SelecaoCidadeAutocomplete 
                            name='pesquisaDestino'                            
                            selecionarCidade={this.selecionarDestino.bind(this)}
                            value={this.props.destino}
                            limparViagens={this.limpaViagens.bind(this)}
                            ehVenda={true}
                        />
                        <Field name="destino" type="hidden" component={renderField} css="form-control col-md-12"/>                        
                    </div>
                    
                    <button type='submit' className='btn btn-laranja btn-sistema'
                            disabled={invalid || submitting || pristine ||!this.props.origem ||!this.props.destino || !this.state.chaveCaptcha}>CADASTRAR
                    </button>
                    <div className='col-12 text-center'>
                        <ReCaptchaV2
                            ref={this.recaptchaRef}
                            style={{display: 'inline-block'}}
                            sitekey={'6LfNrvkdAAAAAL2wVMaGIuOwJ0STFzIOavTKhNvw'}
                            onChange={(value) => this.changeRecaptcha(value)}
                            onExpired={() => this.setState({chaveCaptcha: null})}
                            onErrored={() => this.setState({chaveCaptcha: null})}/>
                    </div>
                </form>
            </div>

        )
    }
}

export const mapStateToProps = state => (
    {
        dadosAutenticacao: state.usuario.dadosAutenticacao,
        origem: state.venda.origem,
        destino: state.venda.destino
    }
)

const reduxConnectEnhance = connect(mapStateToProps, {
    cadastrarPromocao,
    selecionarOrigem,
    selecionarDestino
})(PaginaNovoUsuario);
export default reduxForm({
    form: 'NovoUsuarioForm',
})(reduxConnectEnhance)
