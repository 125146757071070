import React, { Component } from 'react';
import { Carousel, Card } from 'react-bootstrap';
import { recuperarConfEmpresa } from '../../util/applicationContext';

const CarouselListCard = ({ cards }) => {
  return (
    <Carousel interval={null}>
      {cards.map((cardSet, index) => (
        <Carousel.Item key={index}>
          <div id='container-cards' className="row">
            {Array.isArray(cardSet) ? (
              cardSet.map((card, cardIndex) => {
                return (
                  <div id='container-card-banner' className="col-md-4" key={cardIndex}>
                    <Card>
                      <a href={card.link} target="_blank" rel="noopener noreferrer">
                        <Card.Body>
                          <Card.Img alt='cupom de desconto viajem de ônibus mais barata' variant="top" src={card.image} />
                        </Card.Body>
                      </a>
                    </Card>
                  </div>
                );
              })
            ) : (
              <p>Nenhum blog encontrado.</p>
            )}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>

  );
};

export class CarouselCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [
        [
          {
            link: '/principais-trechos/#secao-trechos',
            image: recuperarConfEmpresa().cardPrecoPromocao
          },
          {
            link: 'https://vexado.com.br/sistema-vexado#secao-sistema-vexado',
            image: recuperarConfEmpresa().cardContrateVexado
          },
          {
            link: '/cadastro-promocao',
            image: recuperarConfEmpresa().cardModalPromocao
          }
        ],
        [
          {
            link: 'https://vexado.com.br/sistema-vexado#secao-sistema-vexado',
            image: recuperarConfEmpresa().cardContrateVexado
          },
          {
            link: 'https://queroumaagencia.com/',
            image: recuperarConfEmpresa().cardQueroAgencia
          },
          {
            link: '/cadastro-promocao',
            image: recuperarConfEmpresa().cardModalPromocao
          }
        ]
      ],
    };
  }

  render() {
    return (
      <div id='container-banners' className="App">
        <div className='Container-Carousel col-10'>
          <CarouselListCard cards={this.state.cards} />
        </div>
      </div>
    );
  }
}

export default CarouselCards;