import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {selecionarEmpresa} from '../../actions/empresaActions'
import {buscarPassagens, buscarPassagensDistribusion, limparPassagens} from '../../actions/vendasActions';
import {connect} from 'react-redux';
import moment from 'moment';
import {recuperarConfEmpresa} from '../../util/applicationContext';

class CardsFiltro extends Component {
    constructor(props) {
        super(props);
    }

    handleButtonClick = () => {
        this.props.selecionarEmpresa(null);
        this.props.history.push(this.props.location.pathname);
        this.buscarPassagem()
    };

    async buscarPassagem() {
            this.props.limparPassagens();
            const {venda: {origem, destino, dataIdaSelecionada, dataRetornoSelecionada}, empresaSelecionada} = this.props;
            let url = `/passagem-de-onibus/${origem.nomeComUfNormalizado}/${destino.nomeComUfNormalizado}/${moment(dataIdaSelecionada).format('YYYY-MM-DD')}`;
            if (dataRetornoSelecionada) {
                url = `${url}/${moment(dataRetornoSelecionada).format('YYYY-MM-DD')}`
            }
            const idEmpresa = empresaSelecionada? empresaSelecionada.empresaId : null;
            await this.props.buscarPassagens(origem.id, destino.id, dataIdaSelecionada, dataRetornoSelecionada, idEmpresa);
            if (recuperarConfEmpresa().distribusion) {
                this.props.buscarPassagensDistribusion(origem.id, destino.id, dataIdaSelecionada, dataRetornoSelecionada, idEmpresa);
            }
            this.props.history.push(url);
    }



    render() {
        const{selecionada}= this.props;
        if (!selecionada)
            return '';
        return (
                <div className="card-group mb-2 mt-4">
                    <div className="card col-md-8 itinerario-dia">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span className="mt-2" style={{fontSize: 20, fontWeight: 'bold', color: '#d7a52c'}}>BUSCANDO NA EMPRESA </span>
                            <button type="button" className="close" aria-label="Fechar" onClick={() => this.handleButtonClick()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="card-body">
                            <span className="mb-2 mt-2" style={{fontSize: 20}}>{selecionada.nome}</span>
                        </div>
                    </div>
                </div>

        );
    }
}

export const mapStateToProps = state => (
    {
        dataIdaSelecionada: state.venda.dataIdaSelecionada,
        dataRetornoSelecionada: state.venda.dataRetornoSelecionada,
        isAuthenticated: state.usuario.dadosAutenticacao.isAuthenticated,
        venda: state.venda,
        selecionada: state.empresa.selecionada,
    }
)

export default withRouter(connect(mapStateToProps, { selecionarEmpresa, limparPassagens, buscarPassagens, buscarPassagensDistribusion })(CardsFiltro))
