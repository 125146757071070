import React from 'react';
import logoBp from '../../../estilo/comum/img/logo-amarelinho.png';
import iconAmarelinho from '../../../estilo/comum/img/icon/amarelinho.ico';
import ConteudoQuemSomosA from '../../../componentes/quem-somos/amarelinho/ConteudoQuemSomosA';
import dadosSiteComum from './dadosSiteComum';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/10.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/21.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoViacaoAmarelinho.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/22.webp';

const logoBrasilCabecalho = <img className="logo-site brasilp" src={logoBp} alt='Logo Amarelinho'/>;
const logoBrasilRodape = <img className="brasilp" src={logoBp} alt="Logo Amarelinho" style={{width: '45%'}}/>;


const textoBannerSite = [<h5 key={0} style={{textShadow: '3px 2px black'}}>Passagens online!</h5>, 
    <p key={1} style={{textShadow: '3px 2px black'}}>Um novo jeito de viajar!</p>, 
    <p key={2} style={{textShadow: '3px 2px black'}}>O melhor valor sem precisar sair de casa!</p>];

const textoCardGuiaDicasSite = <p>Não importa se você vai curtir as férias, reencontrar a família ou fazer negócios. Quando o assunto é viagem o mais importante é chegar com conforto e segurança ao destino. Por essa razão, nosso maior propósito é fazer de tudo para que a sua jornada seja inesquecível a bordo de um de nossos amarelinhos.</p>;

export const dadosAmarelinho = {
    ...dadosSiteComum,
    id: 'amarelinho',
    pastaEstilo: 'amarelinho',
    nome: 'Amarelinho',
    nomePrefixo: 'a Amarelinho',
    razaoSocial: 'VEXADO PROMOTORA DE VENDAS E INTERMEDIAÇÕES DE NEGÓCIOS EIRELI',
    cnpj: '32.843.031/0001-09',
    endereco:'Q SCS Quadra 01, Bloco M, número 30, Sala 508, Asa Sul/DF, CEP: 70.305-900',
    telefone: '(62) 99372-4485',
    wtpp: '(62) 99372-4485',
    instagram: 'https://www.instagram.com/viacao.amarelinho/',
    facebook: 'https://www.facebook.com/viacaoamarelinhooficial',
    tiktok: 'https://www.tiktok.com',
    youtube: 'youtube.com',
    email: 'faleconosco@viacaoamarelinho.com.br',
    emailSuporte: 'faleconosco@viacaoamarelinho.com.br',
    lkemailSuporte: 'mailto:faleconosco@viacaoamarelinho.com.br',
    lktelefone: 'tel:5562993724485',
    twitter: '',
    lkemail: 'mailto:faleconosco@viacaoamarelinho.com.br',
    lkwhats: 'https://api.whatsapp.com/send?phone=5562993724485&text=Olá. Preciso de atendimento.',
    logo: logoBp,
    logoCabecacho: logoBrasilCabecalho,
    logoRodape: logoBrasilRodape,
    OgImage: 'https://imagens-open-graph-sites-agencias.s3.amazonaws.com/amarelinho/via%C3%A7%C3%A3o+amarelinho.webp',
    icone: iconAmarelinho,
    quemSomos: ConteudoQuemSomosA,
    videoInicio: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/viacao_amarelhinho_trailer4.mp4',
    textoBanner: textoBannerSite,
    textoCardGuiaDicas: textoCardGuiaDicasSite,
    telelefoneAtendimentoDeficienteAuditivoOuFala: '0800 262 7357',
    idsTagManager: ['GTM-NBFGXW3'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+desconto+via%C3%A7%C3%A1o+amarelinho.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/3.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/3.mobile.webp',
    distribusion: false
};
