import React from 'react'
import {connect} from 'react-redux'
import Autosuggest from 'react-autosuggest';
import {reduxForm} from 'redux-form';
import {findAll} from 'highlight-words-core'
import {buscarCidadesPorNome} from '../../actions/cidadeActions'
import {limparPassagens} from '../../actions/vendasActions';


/* https://github.com/moroshko/react-autosuggest#theme-prop */
const theme = {
    input: 'form-control form-control-lg campo-busca ',
    suggestion: 'list-group-item truncate-dd-a',
    suggestionsList: 'p-0',
    suggestionHighlighted: 'bg-light',
    suggestionFocused: 'active',
};

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '');


const classeHighlight = 'font-weight-bold';

const getValorSugestao = sugestao => `${sugestao.nome}-${sugestao.uf}`;

const trataAcentuacao = (value) => {
    let expressao = value;

    const mapaAcentuacao = [
        {expressaoProcurar: /a/gi, expressaoSubstituir: '[\u0061\u00E1\u00E2\u00E3]'},
        {expressaoProcurar: /c/gi, expressaoSubstituir: '[\u0063\u00E7]'},
        {expressaoProcurar: /e/gi, expressaoSubstituir: '[\u0065\u00E9\u00EA]'},
        {expressaoProcurar: /i/gi, expressaoSubstituir: '[\u0069\u00ED]'},
        {expressaoProcurar: /o/gi, expressaoSubstituir: '[\u006F\u00F3\u00F4\u00F5]'},
        {expressaoProcurar: /u/gi, expressaoSubstituir: '[\u0075\u00FA]'},
    ];

    mapaAcentuacao.forEach((i) => {
        expressao = expressao.replace(i.expressaoProcurar, i.expressaoSubstituir)
    });

    return expressao;
}


const montaSugestaoComHighlight = (part, index) => {
    const classe = part.highlight ? classeHighlight : null;

    return (
        <span className={classe} key={index}>{part.text}</span>
    )
};

const getSugestoes = (value, empresas) => {
    let escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
        return [];
    }

    escapedValue = trataAcentuacao(escapedValue);

    const regex = new RegExp(`\\W*(${escapedValue})\\W*`, 'i');

    return empresas.filter(grupo => regex.test(getValorSugestao(grupo)))
};


const exibeSugestao = (sugestao, {query}) => {
    const textoSugestao = `${sugestao.nome}-${sugestao.uf}`;

    const matches = findAll({searchWords: [trataAcentuacao(query)], textToHighlight: textoSugestao});
    const parts = matches.map((parte) => {
        const {end, highlight, start} = parte;
        const text = textoSugestao.substr(start, end - start);
        return {text, highlight}
    });

    return (
        <a
            key={sugestao.id}>{parts.map(montaSugestaoComHighlight)}
        </a>
    )
};

let time = null;

export class PainelGerenciarItinerarios extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? `${props.value.nomeComUf}` : '',
            sugestoes: [],
        };
    }

    componentDidUpdate(prevProps) {
        if(this.props.value !== prevProps.value){
            this.setState({
                value: this.props.value ? `${this.props.value.nomeComUf}` : ''
            })
        }

    }


    onSuggestionsFetchRequested = ({value}) => {
        clearTimeout(time);
        time = setTimeout(() => {
            if (value.length > 3) {
                this.props.buscarCidadesPorNome(value).then((resp) => {
                    if (resp.payload.data) {
                        this.setState({
                            sugestoes: getSugestoes(value, resp.payload.data),
                        });
                    }
                })
            }
        }, 500)
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            sugestoes: [],
        });
    };

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue,
        });
    };

    onSuggestionSelected(event, {suggestion}) {
        this.props.selecionarCidade(suggestion);
        this.setState({
            value: `${suggestion.nome}-${suggestion.uf}`,
        });
        this.props.limparPassagens();
    }

    storeInputReference = (autosuggest) => {
        if (autosuggest) {
            this.input = autosuggest.input;
        }
    };

    focus() {
        this.input.focus()
    }

    render() {
        const {value} = this.state;
        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onChange: this.onChange,

        };


        return (
            <div style={{width: '100%'}}>
                {this.props.titulo ? <div className='mb-2'>
                    <span style={{fontWeight: 'bold', fontSize: 18}}>{this.props.titulo}</span>
                </div> : ''}
                <div className='form-group'>
                    <i className="material-icons nav-icon mt-2 ml-2"
                       style={{position: 'absolute'}}>{this.props.icone}</i>
                    {/* <i className="material-icons nav-icon mt-2 ml-2 d-none d-sm-nome d-md-block d-lg-block" style={{ position: 'absolute' }}>{this.props.icone}</i> */}
                    <Autosuggest suggestions={this.state.sugestoes}
                                 getSuggestionValue={getValorSugestao}
                                 renderSuggestion={exibeSugestao}
                                 onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                 onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                 onSuggestionSelected={this.onSuggestionSelected.bind(this)}
                                 inputProps={inputProps}
                                 theme={theme}
                                 ref={this.storeInputReference}/>
                </div>
            </div>
        )
    }
}


export const mapStateToProps = state => (
    {
        dataIdaSelecionada: state.venda.dataIdaSelecionada,
        dataRetornoSelecionada: state.venda.dataRetornoSelecionada,
        isAuthenticated: state.usuario.dadosAutenticacao.isAuthenticated,
        venda: state.venda,
    });



export default connect(mapStateToProps,
    {
        buscarCidadesPorNome,
        limparPassagens,
    })(reduxForm({
    form: 'formPesquisaDestino',
})(PainelGerenciarItinerarios))
