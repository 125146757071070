import axios from 'axios'
import { URL } from '../util/ambienteActions';
import {SLOW_REQUEST_CONFIG} from '../util/loadingUtil';


export const BUSCAR_CIDADES = '@cidadeActions/BUSCAR_CIDADES';
export const BUSCAR_CIDADES_ROTAS = '@cidadeActions/BUSCAR_CIDADES_ROTAS';



export function buscarCidadesPorNome (nome) {
    const url = `${URL}/public/cidades/nome/${nome.replace(/[.*+?^${};%()|[\]\\]/g, '')}`;
  return dispatch => axios.get(url).then(
      response => dispatch({
        type: BUSCAR_CIDADES,
        payload: response
      })
  );
}

export function buscarCidadeRotaPorNomeCidadeOrigem (nomeUfCidadeOrigem) {
  const url = `${URL}/trecho/public/origem/nome/${nomeUfCidadeOrigem}`;
  return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
    response => dispatch({
      type: BUSCAR_CIDADES_ROTAS,
      payload: response.data
    })
  )
}
